import { Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { LoadingSection, Stepper } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { ChooseProperty } from "./chooseProperty";
import { ChooseUnit } from "./chooseUnit";
import { InspectionItems } from "./inspectionItems";
import { Preview } from "./preview";
import { useStyles } from "./style";

export const CreateInspection = ({ t, companyId = "", onClose = () => false, reload = () => false }) => {
    const classes = useStyles()
    const alert = useContext(AlertContext)
    const [step, setStep] = useState(1)
    const [offset, setOffset] = useState(0)
    const [unitOffset,setUnitoffset]=useState(0)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        selectedProperty: {},
        selectedUnit: {},
        selectedInspectionItem: [],
        propertyData: [],
        unitData: [],
        inspectionItemData: [],
        maintenanceRequest: [],
        disable: true
    })
    const [searchText, setSearchText] = useState("")
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    const stepsNew = [
        {
            label: t("Choose Property"),
            step: 1,
            value: step,
        },
        {
            label: t("Choose Unit"),
            step: 2,
            value: step,
        },
        {
            label: t("Inspection Items"),
            step: 3,
            value: step,
        },
        {
            label: t("Preview"),
            step: 4,
            value: step,
        },
    ];
    const getPropertyList = (offset = 0, search = "", searchBool = true) => {
        const payload = {
            company_id: companyId,
            status: [true],
            offset: offset,
            limit: 10,
            search: search
        }
        NetworkCall(
            `${config.api_url}/property/getAllProperties`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                if (searchBool) {
                    setData({ ...data, propertyData: response?.data?.data?.property })
                }
                else {
                    setData({ ...data, propertyData: data?.propertyData.concat(response?.data?.data?.property) })
                }
            })
            .catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    const getUnitList = (offset = 0, search = "", searchBool = true) => {
         const payload ={
            type: [5, 1, 8],
            property_id: data?.selectedProperty?.id,
            offset: offset,
            search: search,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/queries/get_unit_by_category`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            if (searchBool) {
                setData({ ...data, unitData: response?.data?.data?.unit, disable: data?.selectedUnit?.name ? false : true })
            }
            else {
                setData({ ...data, unitData: data?.unitData.concat(response?.data?.data?.unit), disable: data?.selectedUnit?.name ? false : true })
            }

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    const getInspectionItems = (offset = 0, search = "", searchBool = true) => {

        const payload = {
            unit_id: data?.selectedUnit?.id,
            search: search,
            offset: offset,
            limit: 10,
            active: [true]
        }

        NetworkCall(
            `${config.api_url}/inspection_item_mapping/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            if (searchBool) {
                if (response?.data?.data?.length > 0) {
                    setData({ ...data, inspectionItemData: response?.data?.data, disable: data?.selectedInspectionItem?.length > 0 ? false : true })
                }
                else {
                    setData({ ...data, inspectionItemData: response?.data?.data, disable: false })
                }
            }
            else {
                setData({ ...data, inspectionItemData: data?.inspectionItemData.concat(response?.data?.data), disable: data?.selectedInspectionItem?.length > 0 ? false : true })
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    const prev = () => {
        setStep(step - 1)
        setData({ ...data, disable: false })
    }
    const next = () => {
        setStep(step + 1)
        setSearchText("")
        switchComponents(step + 1)
    }
    const switchComponents = (val) => {
        switch (val) {
            case 1:
                return getPropertyList(0, "")
            case 2:
                return getUnitList(0, "")
            case 3:
                return getInspectionItems(0, "")
            default:
                return null
        }
    }
    React.useEffect(() => {
        getPropertyList(0, "")
        if (data?.selectedProperty.length > 0) {
            getUnitList(0, "")
        }
        //eslint-disable-next-line
    }, [])
    const updateState = (k, v, k1, v1) => {
        setData({ ...data, [k]: v, [k1]: v1, disable: false })
    }
    const handleSearch = (v) => {
        // setData({ ...data, [k]: v })
        if (step === 1) {
            getPropertyList(0, v)
        }
        else if (step === 2) {
            getUnitList(0, v)
        }
        else if (step === 3) {
            getInspectionItems(0, v)
        }
        setSearchText(v)
    }
    const fetchMoreData = () => {      
        if (step === 1) {
            setOffset(offset + 10);
            getPropertyList(offset + 10, "", false);
        }
        else if (step === 2) {
            setUnitoffset(unitOffset+10)
            getUnitList(unitOffset + 10, "", false)
        }

    };
    const onCreate = () => {
        setIsDisableBtn(true)
        const inspection_item = data?.selectedInspectionItem?.map((x) => { return { "inspection_item_map": x } })
        if (data?.maintenanceRequest?.id) {
            const payload = {
                "inspection_activity": inspection_item,
                "unit_id": data?.selectedUnit?.id,
                "maintenance_id": data?.maintenanceRequest?.id,
                "request_type": "service"

            }

            NetworkCall(
                `${config.api_url}/general_inspection/create`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Service Inspection Request Created Successfully"),
                });
                reload()
                onClose()
            }).catch((error) => {
                onClose()
                setIsDisableBtn(false)
                if (error.response) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : t("Something went wrong"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }

            })
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Choose Maintenance Request",
            });
        }
    }
    const StepperContent = {
        1: <ChooseProperty t={t} updateState={updateState} propertyData={data.propertyData} selectedProperty={data?.selectedProperty} handleSearch={handleSearch} searchText={searchText} fetchMoreData={fetchMoreData} />,
        2: <ChooseUnit t={t} updateState={updateState} unitData={data.unitData} selectedUnit={data?.selectedUnit} handleSearch={handleSearch} searchText={searchText} fetchMoreData={fetchMoreData} />,
        3: <InspectionItems t={t} data={data} selectedUnit={data.selectedUnit} list={data?.inspectionItemData} selectedInspectionItem={data?.selectedInspectionItem} updateState={updateState} handleSearch={handleSearch} searchText={searchText} />,
        4: <Preview t={t} selectedUnit={data?.selectedUnit} selectedInspectionItem={data?.selectedInspectionItem} setStep={setStep} maintenanceRequest={data?.maintenanceRequest} updateState={updateState} data={data} setData={setData} />
    };
    return (
        <Box>
            <Stepper t={t} step={step} steps={stepsNew} />
            <Box height="400px">{loading ? <LoadingSection top="20vh" message={"Fetching Details"} /> : StepperContent[step]}</Box>
            <Box display="flex" justifyContent={step > 1 ? "space-between" : "end"} mt={2} p={2} className={classes.anchorBottom}>
                {step > 1 &&
                    <Button variant="outlined" onClick={prev} className={classes.previousbtn}>{t("Previous")}</Button>
                }
                <Button variant="contained" onClick={step === 4 ? onCreate : next} disabled={step === 3 ? false :isDisableBtn?true:data?.disable}>{step === 4 ? t("Create") : t("Next")}</Button>

            </Box>
        </Box>
    )
}