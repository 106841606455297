import { Box, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import ShortListIMG from "../../../assets/shortlistImg";
import { filterStyles } from "./styles";
import { withNamespaces } from "react-i18next";
const UnitCard = (props) => {
    const { t } = (props);

    const classes = filterStyles();
    return (
        <div className={classes.UnitCardroot}>
            <div className={classes.unitCardImgdiv}>
                <img src={props?.data?.url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitCardImg} />
                <>
                    {props?.data?.unit_category && <Typography className={classes.type}>{props?.data?.unit_category}</Typography>}
                    {props?.data?.unit_categoryByID?.id && <Typography className={classes.type}>{props?.data?.unit_categoryByID?.name}</Typography>}
                </>
                {
                    !props?.noBtn &&
                    <>
                        {
                            props?.delete ?
                                <IconButton className={classes.shortlistBTN} onClick={props?.onDelete} size="small">
                                    <img src="/images/delete.svg" alt="" height="10px" />
                                </IconButton> :
                                props?.data?.room_rent &&

                                <IconButton className={props?.shortlistid?.some((val) => val === props?.data?.id) ? classes.shortlistBTNactive : classes.shortlistBTN} onClick={() => props?.handleShortlist(props?.data?.id)} size="small">
                                    <ShortListIMG color={props?.shortlistid?.some((val) => val === props?.data?.id) ? "white" : "#98a0ac"} />
                                </IconButton>
                        }
                    </>
                }

                {props?.data?.pricing?.some((val) => val?.discountValue > 0) &&
                    <Typography className={classes.discountAppliedRoot}>% {t("Discount Applied")}</Typography>
                }


            </div>
            <Stack direction="row" justifyContent="space-between">
                <Typography className={classes.unitName}>{props?.data?.name}</Typography>
                <Stack direction={"row"} sx={{ direction: "ltr" }}>
                    {
                        props?.quotation ?
                            props?.data?.pricing?.some((val) => val?.discountValue > 0) ?
                                <>
                                    <Typography sx={{ marginInlineEnd: "4px" }} className={classes.discountValue}>{props?.currency ?? ""}</Typography>
                                    <Typography className={classes.discountValue}>{props?.data?.totalDiscount?.toFixed(2)}</Typography>
                                </>
                                :
                                <>
                                    <Typography sx={{ marginInlineEnd: "4px" }} className={classes.unitName}>{props?.currency ?? ""}</Typography>
                                    <Typography className={classes.unitName}>{props?.data?.room_rent?.toFixed(2) ?? 0}</Typography>
                                </>
                            : <>
                                <Typography sx={{ marginInlineEnd: "4px" }} className={classes.unitName}>{props?.currency ?? ""}</Typography>
                                <Typography className={classes.unitName}>{props?.data?.room_rent?.toFixed(2)}</Typography>
                            </>
                    }
                </Stack>
            </Stack>
            <Box height="2px" />
            <Stack direction="row" alignItems="center" >
                <Typography className={classes.build1} noWrap>{props?.data?.unit_no}</Typography>
                {
                    props?.data?.unit_no && <Box className={classes.dot} />
                }

                <Typography noWrap className={classes.build1}>{props?.data?.total_area}&nbsp;{props?.data?.uom_master?.name ?? props?.data?.uom_name}</Typography>

            </Stack >
            <Box height="4px" />
            <Grid container spacing={2}>
                {
                    props?.data?.total_bed_rooms &&
                    <Grid item xs={4}>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                    <Box display="flex" alignItems="center">
                                        <img src="/images/bed.svg" alt="" />
                                        <Tooltip title={`${props?.data?.total_bed_rooms} ${t("Bed")}`}>
                                            <Typography className={classes.build2} noWrap>{props?.data?.total_bed_rooms} {t("Bed")}</Typography>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                   <Box className={classes.dot} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }

                {
                    props?.data?.total_baths &&
                    <Grid item xs={4}>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                    <Box display="flex" alignItems="center">
                                        <img src="/images/bath.svg" alt="" />
                                        <Tooltip title={`${props?.data?.total_baths} ${t("Bath")}`}>
                                            <Typography className={classes.build2} noWrap>{props?.data?.total_baths} {t("Bath")}</Typography>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box className={classes.dot} />
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>
                }
                {
                    props?.data?.type?.name &&
                    <Grid item xs={4}>
                        <Box display="flex" alignItems="flex-end">
                            <img src="/images/Group 96257.svg" alt="" />
                            <Tooltip title={`${props?.data?.type?.name}`}>
                                <Typography className={classes.build2} noWrap>{props?.data?.type?.name} </Typography>
                            </Tooltip>
                        </Box>
                    </Grid>
                }

            </Grid>
            {
                !props?.is_unit &&
                <>
                    <Box height="12px" />
                    <Typography className={classes.viewdetail} textAlign="center"
                        onClick={() => {
                            props?.onClick(props?.data?.id)
                            if (props?.quotation) {
                                props?.onSelectedUnit(props?.data)
                            }
                        }}>
                        {props?.quotation ? t("Edit Pricing") : t("View Details")}
                    </Typography></>
            }

        </div >
    )
}
export default withNamespaces("shortListUnits")(UnitCard)
