import React from "react";
import { withNavBars } from "../../HOCs";
import ExecutedWorkOrderView from "./executedWorkOrderView";
class ExecutedWorkOrderViewParent extends React.Component {
    render() {
        return <ExecutedWorkOrderView {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ExecutedWorkOrderViewParent, props);