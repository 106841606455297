import Grid from "@mui/material/Grid";
import React, { useEffect, useMemo, useState } from "react";
import { Location, ToggleButtonComponent } from "../../../components";
import { LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import {
  CustomAsyncSelect,
  CustomDatePicker,
  LeadSelect,
  LocationTextField,
  RequiredArea,
} from "../components";
import { Purpose, SearchIcon, UnitCategory } from "../components/svg";
import {
  getDateValue,
  measurementUnits,
  returnMomentFormat,
} from "../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const Commercial = (props) => {
  const {
    data = {},
    filterData = {},
    onChangeState,
    location,
    handleLead,
    selectedLead,
    t,
    getCurrentLocation,
        purpose
  } = props;

  const current_date = new Date();
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const is_workspace = ['49', '35'].includes(moduleId)

  let min_date_duration = current_date.setFullYear(
    current_date.getFullYear() - 1
  );
  const [propertyPurpose, setPropertyPurpose] = useState("Lease");

  useEffect(() => {
    if (data?.propertyPurpose) {
      setPropertyPurpose(data.propertyPurpose);
    }
  }, [data]);

  const getCompnayDetails = () => {
      NetworkCall(
        `${config?.api_url}/queries/opportunity/get_company_details`,
        NetWorkCallMethods.post,
        {
          company_id: props?.company,
        },
        null,
        true,
        false
      )
      .then((res) => {
        onChangeState("requiredArea", {
          maxArea: "10000",
          minArea: "1",
          measurement:
            is_workspace
              ? 9
              : res?.data?.company_master[0]?.uom_master?.value,
          mesurmentLabel:
            is_workspace
              ? "Seat"
              : res?.data?.company_master[0]?.uom_master?.label,
        });
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    if (props?.company) {
      getCompnayDetails();
    }
    // eslint-disable-next-line
  }, [props?.company]);

  const getDateOutput = useMemo(() => {
    return getDateValue(data?.duration, false, filterData?.facilitesbool);
  }, [data?.duration, filterData?.facilitesbool]);

  const returnRequiredArea = useMemo(() => {
    const {
      maxArea = "",
      minArea = "",
      mesurmentLabel = "",
    } = data?.requiredArea ?? {};
    return minArea && maxArea
      ? `${minArea} - ${maxArea} ${mesurmentLabel}`
      : "";
  }, [data?.requiredArea]);


  const manualResponse = (array) =>
    array?.map((_) => {
      return {
        ..._,
        label: _?.contact?.first_name,
        value: _?.contact?.id,
      };
    });
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sm={6} lg={4}>
          <LeadSelect
            label={t("Search Opportunities")}
            placeholder={t("Search")}
            icon={<SearchIcon width={12} height={12} />}
            value={selectedLead ?? null}
            onChange={(value) => handleLead(value)}
            isPaginate
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "queries/property_search/lead/get",
                {
                    company_id: props?.company
                },
                search,
                array,
                handleLoading,
                "lead",
                {},
                manualResponse
            )
            }
            debounceTimeout={800}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={is_workspace ? 9 : 6}
          md={is_workspace ? 9 : 6}
          lg={4}
        >
          <Location
            value={location}
            handleChange={(value) => onChangeState("location", value)}
            textFieldComponent={(ref, value, handleChange) => (
              <LocationTextField
                label={t("Location")}
                inputRef={ref}
                handleChange={handleChange}
                value={value}
                placeholder={t("Select City")}
                getCurrentLocation={getCurrentLocation}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={6} lg={4}>
          {data?.propertyPurpose === "Sale" ? (
            <CustomDatePicker
              placeholder={t("Choose Handover Date")}
              label={t("Handover")}
              value={data?.handOverDate}
              data={
                data?.handOverDate?.date
                  ? returnMomentFormat(data?.handOverDate?.date)
                  : ""
              }
              onChange={(value) => onChangeState("handOverDate", value)}
              title={t("Handover Date")}
              min_date_duration={min_date_duration}
            />
          ) : (
            <CustomDatePicker
              placeholder={t("Choose Date Range")}
              label={t("Duration")}
              value={data?.duration}
              data={getDateOutput}
              onChange={(value) => onChangeState("duration", value)}
              title={t("Lease Duration")}
              showEndDate
              min_date_duration={min_date_duration}
            />
          )}
        </Grid>
        {!is_workspace && (
          <Grid item xs={3} md={3}>
            <ToggleButtonComponent
              options={[
                { label: "Lease", value: "Lease" },
                { label: "Sale", value: "Sale" },
              ]}
              value={propertyPurpose}
              onChange={(value) => {
                setPropertyPurpose(value);
                onChangeState("propertyPurpose", value);
              }}
              height="62px"
              buttonGroupStyle={{
                gap: "6px",
                marginTop: "0px",
              }}
              buttonStyle={{
                width: "48%",
                border: "1px solid transparent",
                "&&:hover": {
                  // Increase the specificity of the &:hover selector
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #5078E1", // Change the border color to blue
                  color: "#5078E1",
                },
                "&.Mui-selected": {
                  "&:hover": {
                    backgroundColor: "#5078E1",
                    border: "1px solid #5078E1",
                    color: "#FFFFFF",
                  },
                },
              }}
            />
          </Grid>
        )}

        <Grid item xs={3} md={3}>
          <CustomAsyncSelect
            placeholder={t("Select Type")}
            value={data?.propertyType ?? null}
            onChange={(value) => onChangeState("propertyType", value)}
            label={t("Property Type")}
            icon={<Purpose width={15} height={15} />}
            isPaginate
            debounceTimeout={800}
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "queries/property_group_master",
                {
                    company_id: props?.company,
                    revenue: "Residential",
                },
                search,
                array,
                handleLoading,
                "property_group_master",
                {},
                null,
                {all: true}
            )
            }
          />
        </Grid>

                <Grid item xs={3} md={3}>
                    <CustomAsyncSelect
                        placeholder={t("Select Category")}
                        value={data?.unitCategory ?? null}
                        onChange={value => onChangeState('unitCategory', value)}
                        label={t("Unit Category")}
                        icon={<UnitCategory width={15} height={15} />}
                        isPaginate={true}
                        loadOptions={(search, array, handleLoading)=> 
                            loadOptionsApis(
                                "queries/unit_category/get",
                                { type:!is_workspace ? "property_search" : "property_search_workspace" , purpose:purpose },
                                search,
                                array,
                                handleLoading,
                                "unit_category_master"
                            )
                        }
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    <RequiredArea
                        label={t("Required Area")}
                        data={data?.requiredArea}
                        onChange={value => onChangeState('requiredArea', value)}
                        options={measurementUnits}
                        value={returnRequiredArea}
                    />
                </Grid>
            </Grid >
        </div >
    )
}
