import React from "react";
import { Button, Typography, Box, Stack } from "@mui/material";
import { Skip, OwnerAssign, AgreementDeclined, AgreementApprovalIcon } from "../../../assets";
import { useStyles } from "../styles";
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const StatusComponent = ({
  data = {},
  onClick = () => false,
  title = "",
  sub = "",
  btnText = {},
  isButton = false,
  marginLeft = "0px",
  isOnboard = false,
  isStatus = false,
  t,
  onClickSkip = () => false,
  onClickDecline = () => false,
  index = 0,
  is_show = false,
  status = "",
  disableBtn = false,
  data_type = false,
  sendPreview = () => false,
  cancelDialog = () => false,
  landloardClick = () => false,
}) => {
  const classes = useStyles({ marginLeft });

  const btnComponent = {
    2: t("Assign Responsible"),
    3: t("Initiate"),
    4: t("Create"),
    5: t("Create"),
    // 3: t("Create_Request"),
    // 4: t("Send_payment"),
    // 5: t("Create_Request"),
    "agreement_approval_send": t("Complete"),
    "invoice_created": t("Send for preview"),
    "landlord_approval": t("Send for Owner Approval"),
    "landlord_approval_declined": t("Send for Owner Approval"),
    "skip_landlord_approval": t("Send for preview")
  };

  const approvalBtnFun = () => {
    if ((data_type === "invoice_created" || data_type === "skip_landlord_approval" || data_type === "agreement_approval_send") && !data?.approval?.status) {
      sendPreview("assign", data, isStatus, isOnboard, is_show, data?.approval?.status, data_type)
    }
    else if (data?.approval?.status === "Approved") {
      onClick("Complete", data, isStatus, isOnboard, is_show, data?.approval?.status, data_type)
    } else {
      onClick("Complete", data, isStatus, isOnboard, is_show, data?.approval?.status, data_type)
    }
  }


  return (
    <div className={classes.statusComponentPraeant}>
      <Box display="flex" alignItems="center">
        <Box>
          {
            data?.approval?.status === "Declined" ?
              <AgreementDeclined /> :
              data?.approval?.status === "Approved" ?
                <AgreementApprovalIcon />
                :
                <img src="images/agreementReview.svg" alt="agreementReview" />
          }
        </Box>
        <Box width={"12px"} />
        <Box textAlign="left">
          <Typography className={classes.statusComponentTitle}>
            {title}
          </Typography>
          <Box height={"4px"} />
          <Typography className={classes.statusComponentSubtitle}>
            {sub}
          </Typography>
        </Box>
      </Box>
      <Box height={"20px"} />

      <Box>
        {isButton ? (
          (data_type === "landlord_approval" || data_type === "landlord_approval_declined") ?
            
            (data_type === "landlord_approval_declined" ?
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  className={classes.next}
                  style={{ width: status !== "KYC Screening" ? "100%" : "50%" }}
                  onClick={() => cancelDialog("cancel_approval")}
                  disabled={disableBtn}
                  endIcon={<KeyboardArrowRightIcon style={{ fontSize: "14px" }} />}
                >
                  <Box display="flex" alignItems={"center"}>
                    <BlockIcon style={{ fontSize: "14px" }} />
                    <Box width={"8px"} />
                    {t("Cancel agreement")}
                  </Box>

                </Button>
              </Stack>
              :
              <Stack direction="row">
                <Button
                  variant="contained"
                  className={classes.next}
                  onClick={() => landloardClick("create", data, isStatus, isOnboard, is_show, "skip_land_loard")}
                  disabled={disableBtn}
                >
                  <Box display="flex" alignItems={"center"}>
                    <OwnerAssign />
                    <Box width={"8px"} />
                    {btnComponent[data_type]}
                  </Box>

                </Button>

                <Button
                  variant="outlined"
                  className={classes.skip}
                  onClick={() => landloardClick("skip", data, isStatus, isOnboard, is_show, "request_land_loard")}
                >
                  <Box display="flex" alignItems={"center"}>
                    <Skip />
                    <Box width={"8px"} />
                    {t("Skip_This_process")}{" "}
                  </Box>

                </Button>


              </Stack>)
          
        :
        (data_type === "invoice_created" || data_type === "skip_landlord_approval" || data_type === "agreement_approval_send") ?

        <>
          {
            (data?.approval?.status === "Declined") ?
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  className={classes.next}
                  style={{ width: status !== "KYC Screening" ? "100%" : "50%" }}
                  onClick={() => cancelDialog("cancel_approval")}
                  disabled={disableBtn}
                  endIcon={<KeyboardArrowRightIcon style={{ fontSize: "14px" }} />}
                >
                  <Box display="flex" alignItems={"center"}>
                    <BlockIcon style={{ fontSize: "14px" }} />
                    <Box width={"8px"} />
                    {t("Cancel agreement")}
                  </Box>

                </Button>
              </Stack>
              :
              <Stack direction="row">
                <Button
                  variant="contained"
                  className={classes.next}
                  style={{ width: data?.approval?.status === "Approved" ? "50%" : "100%" }}
                  onClick={approvalBtnFun}
                  disabled={disableBtn}
                >
                  <Box display="flex" alignItems={"center"}>
                    <OwnerAssign />
                    <Box width={"8px"} />
                    {btnComponent[data_type]}
                  </Box>

                </Button>

                {
                  data?.approval?.status !== "Approved" &&
                  <Button
                    variant="outlined"
                    className={classes.skip}
                    onClick={() => onClickSkip("skip", data, isStatus, isOnboard, is_show, "skip_invoice_approval")}
                  >
                    <Box display="flex" alignItems={"center"}>
                      <Skip />
                      <Box width={"8px"} />
                      {t("Skip_This_process")}{" "}
                    </Box>

                  </Button>
                }



              </Stack>
          }</>


        :
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            className={classes.next}
            style={{ width: "50%" }}
            onClick={() => onClick("Complete", data, isStatus, isOnboard, is_show)}
          >
            <Box display="flex" alignItems={"center"} >
              <OwnerAssign />
              <Box width={"8px"} />
              {t("Complete")}{" "}
            </Box>
            {/* <Box>
                <Box width={"8px"} />
                <ArrowIcon />{" "}
              </Box> */}
          </Button>
          {/* <Button
              variant="outlined"
              className={classes.decline}
              onClick={() => onClick("Incomplete", data, isStatus, isOnboard , is_show)}
            >
              <Box display="flex" alignItems={"center"}>
                <Decline />
                <Box width={"8px"} />
                {t("Decline")}{" "}
              </Box> */}
          {/* <Box>
                {" "}
                <ArrowIcon color={"#FF4B4B"} />{" "}
              </Box> */}
          {/* </Button> */}
        </Stack>
        ) : (

        <Stack direction="row">
          <Button
            variant="contained"
            className={classes.next}
            style={{ width: status !== "KYC Screening" ? "100%" : "50%" }}
            onClick={() => onClick("assign", data, isStatus, isOnboard, is_show)}
            disabled={disableBtn}
          >
            <Box display="flex" alignItems={"center"}>
              <OwnerAssign />
              <Box width={"8px"} />
              {btnComponent[index]}
            </Box>
            {/* <Box>
                <Box width={"8px"} />
                <ArrowIcon />{" "}
              </Box> */}
          </Button>
          {
            status !== "KYC Screening" &&
            <Button
              variant="outlined"
              className={classes.skip}
              onClick={() => onClickSkip("skip", data, isStatus, isOnboard, is_show)}
            >
              <Box display="flex" alignItems={"center"}>
                <Skip />
                <Box width={"8px"} />
                {t("Skip_This_process")}{" "}
              </Box>
              {/* <Box>
                {" "}
                <ArrowIcon color={"#4e5a6b"} />{" "}
              </Box> */}
            </Button>
          }

          {/* <Button
              variant="outlined"
              className={classes.decline}
              onClick={() =>
                onClickDecline("decline", data, isStatus, isOnboard , is_show)
              }
            >
              <Box display="flex" alignItems={"center"}>
                <Decline />
                <Box width={"8px"} />
                {t("Decline")}{" "}
              </Box> */}
          {/* <Box>
                {" "}
                <ArrowIcon color={"#FF4B4B"} />{" "}
              </Box> */}
          {/* </Button> */}
        </Stack>
        )}
      </Box>
    </div>
  );
};
