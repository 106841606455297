/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Drawer, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew, useWindowDimensions, } from '../../utils';

import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { withNavBars } from '../../HOCs';
import { CloseIconWithBG } from '../../assets';
import { AlertDialog, FormGenerator, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { MarketPlaceCard } from './components';
import { Scroller } from './components/scroller';
import { useStyles } from "./style";
import { Heading, Path, Type } from './utils';


const MarketPlaceView = ({ t = () => false }) => {
    const initialStateKSA = {
        company: "",
        taxpayerProvidedId: "",
        model: "PA",
        crnNumber: "",
        emailId: "",

        vatName: "",
        vatNumber: "",
        branchName: "",
        branchIndustry: "",
        city: "",
        citySubDivision: "",
        street: "",
        plotIdentification: "",
        building: "",
        postalZone: "",
        otp: "",
        isDisable: true,
        payment_id: ""
    }
    const paymentInitialState = {
        is_common: false,
        is_custom: false,
        custom_key_id: "",
        custom_secret: "",
        isDisable: false
    }

    const classes = useStyles();
    const history = useHistory()
    const size = useWindowDimensions()
    const { state } = useLocation();
    const debounce = UseDebounce();

    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [permission, setPermission] = React.useState({})
    const [tableData, setTableData] = React.useState({
        isDisable: false,
        row: [],
        totalRowsCount: 0
    })
    const [detailsData, setDetailsData] = React.useState([])
    const [popupData, setPopupData] = React.useState([])
    const [data, setData] = React.useState({ ...initialStateKSA })
    const [dialog, setDialog] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState({ table: false, form: false, open: false });
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getDetailsByID(state?.id)
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getInitialState = {
        4: initialStateKSA,
        3: paymentInitialState,

    }

    // use effect to get permission
    React.useEffect(() => {
        if (popupData?.id) {
            setData({ ...getInitialState[popupData?.drawer_type] })
        }
        // eslint-disable-next-line
    }, [popupData]);

    //update data
    const updateState = (key, value) => {
        const newData = { ...data, [key]: value };
        if (popupData?.drawer_type === 3) {
            // Check if key is 'is_custom'
            if (newData?.is_custom) {
                const isValidate = ['custom_key_id', 'custom_secret'].every(val => newData[val]?.length > 0)
                setData({ ...newData, isDisable: !isValidate });
            }
            else {
                setData({ ...newData, isDisable: false });
            }
        }
        if (popupData?.drawer_type === 4) {
            // For other keys, check if all values have length greater than 0
            const allValuesFilled = [
                "taxpayerProvidedId",
                "model",
                "crnNumber",
                "emailId",
                "vatName",
                "vatNumber",
                "branchName",
                "branchIndustry",
                "city",
                "citySubDivision",
                "street",
                "plotIdentification",
                "building",
                "postalZone",
                "otp"].every(val => newData[val]?.length > 0);
            setData({ ...newData, isDisable: !allValuesFilled });
        }
    };

    const getDetailsByID = (id) => {
        if (id) {
            setLoading(true)
            let datas = { addon_category_id: id }
            NetworkCall(
                `${config.api_url}/subscription/get_addon_category`,
                NetWorkCallMethods.post,
                datas, null, true, false
            ).then((res) => {
                setDetailsData(res?.data?.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Internal error. Please try again later."),
                });

            });
        }
    }
    const getData = (offset = 0, limit = 10, search = "") => {
        setTableData({
            ...tableData,
            isDisable: true,
        })
        let datas = {
            offset: offset,
            limit: limit,
            search: search,
            addon_id: popupData?.id,
            client: localStorage.getItem(LocalStorageKeys.clinetID)
        }
        NetworkCall(
            `${(popupData?.drawer_type === 4) ? `${config.api_url}/company/get_all_company` : `${config.payment_gateway_url}/payment_gateway_master/get_payment_config`}`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {
            setTableData({
                row: res?.data?.data?.data,
                totalRowsCount: res?.data?.data?.count,
                isDisable: false
            })
        }).catch((error) => {
            setTableData({
                ...tableData,
                row: [],
                totalRowsCount: 0,
                isDisable: false
            })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });

        });
    }

    const handleClick = (data, item) => {
        setPopupData({ ...data, drawer_type: item?.id })
        setDialog(true)
    }

    //on submit form
    const onSubmit = () => {
        if (popupData?.drawer_type === 4) {
            setData({ ...data, isDisable: true })
            let datas = {
                ...data,
                invoiceType: "Both",
                location: {
                    city: data?.city,
                    citySubDivision: data?.citySubDivision,
                    street: data?.street,
                    plotIdentification: data?.plotIdentification,
                    building: data?.building,
                    postalZone: data?.postalZone,
                    countryCode: "SA"
                },
                otp: data?.otp
            }
            NetworkCall(
                `https://dev-einvoicer-api.propertyautomate.com/api/v1/egs_unit/`,
                NetWorkCallMethods.put,
                datas, null, true, false
            ).then((res) => {
                setLimit(0)
                updateDetailsInCompany()
            }).catch((error) => {
                setData({ ...data, isDisable: false })
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Internal error. Please try again later."),
                });
            });
        } else if (popupData?.drawer_type === 3) {
            setData({ ...data, isDisable: true })
            let datas = {
                addon_id: popupData?.id,
                name: popupData?.name,
                custom_key_id: data?.custom_key_id ?? undefined,
                custom_secret: data?.custom_secret ?? undefined,
                is_custom: data?.is_custom,
                is_common: data?.is_common,
                company_id: data?.company?.id,
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                config: popupData?.configure,
                payment_id: data?.payment_id
            }
            NetworkCall(
                `${config.payment_gateway_url}/payment_gateway_master/create_payment_config`,
                NetWorkCallMethods.post,
                datas, null, true, false
            ).then((res) => {
                setDrawerOpen({ ...drawerOpen, table: true, form: false })
                setLimit(0)
                getData(0, 10, "")
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Configured Successfully"),
                })
                setData({ ...initialStateKSA, isDisable: false })
            }).catch((error) => {
                setData({ ...data, isDisable: false })
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Internal error. Please try again later."),
                });
            });
        }
        else {
            return false
        }
    }
    const updateDetailsInCompany = () => {
        let datas = {
            company_id: data?.company?.id,
            configuration_value: data?.company?.configuration_value?.length > 0 ?
                JSON.stringify([
                    ...data?.company?.configuration_value,
                    {

                        taxpayerProvidedId: data?.taxpayerProvidedId,
                        model: data?.model,
                        crnNumber: data?.crnNumber,
                        vatName: data?.vatName,
                        vatNumber: data?.vatNumber,
                        branchName: data?.branchName,
                        branchIndustry: data?.branchIndustry,
                        emailId: data?.emailId,
                        invoiceType: "Both",
                        add_on: { name: popupData?.name, id: popupData?.id, is_system_addon: popupData?.is_system_addon, is_integration: popupData?.is_integration },
                        is_configured: true,
                        location: {
                            city: data?.city,
                            citySubDivision: data?.citySubDivision,
                            street: data?.street,
                            plotIdentification: data?.plotIdentification,
                            building: data?.building,
                            postalZone: data?.postalZone,
                            countryCode: "SA"
                        },
                        otp: data?.otp

                    }
                ]
                ) : JSON.stringify([
                    {

                        taxpayerProvidedId: data?.taxpayerProvidedId,
                        model: data?.model,
                        crnNumber: data?.crnNumber,
                        vatName: data?.vatName,
                        vatNumber: data?.vatNumber,
                        branchName: data?.branchName,
                        branchIndustry: data?.branchIndustry,
                        emailId: data?.emailId,
                        invoiceType: "Both",
                        add_on: { name: popupData?.name, id: popupData?.id, is_system_addon: popupData?.is_system_addon, is_integration: popupData?.is_integration },
                        is_configured: true,
                        location: {
                            city: data?.city,
                            citySubDivision: data?.citySubDivision,
                            street: data?.street,
                            plotIdentification: data?.plotIdentification,
                            building: data?.building,
                            postalZone: data?.postalZone,
                            countryCode: "SA"
                        },
                        otp: data?.otp

                    }
                ]
                )
        }
        NetworkCall(
            `${config.api_url}/company/update_company`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: t("Configured Successfully"),
            });
            setData({ ...data, isDisable: false })
            setDrawerOpen({ table: false, form: false, open: false })
        }).catch((error) => {
            setData({ ...data, isDisable: false })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });

    }
    const handleOccupantSearch = (e) => {
        setSearchText(e);
        debounce(() => searchTableFunction(e), 800);
    }

    const searchTableFunction = (e) => {
        getData(0, limit, e);
    }

    const handleTablePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getData(offset, limit, searchText);
    }

    const handleTablePageLimit = (value) => {
        setLimit(value);
        getData(0, value, searchText);
    }
    const handleConfiguration = (value) => {
        setData({
            ...data,
            company: value,
            emailId: value?.company_address?.[0]?.email_id,
            crnNumber: value?.cr_no,
            vatNumber: value?.tax_registration_number,
            branchName: value?.branch_name,
            street: value?.company_address?.[0]?.street_1,
            city: value?.company_address?.[0]?.city,
            citySubDivision: value?.company_address?.[0]?.state,
            plotIdentification: value?.company_address?.[0]?.door_no,
            building: value?.company_address?.[0]?.street_2,
            postalZone: value?.company_address?.[0]?.zipcode
        })
        setDrawerOpen({ table: false, form: true, open: true })
    }

    //form data
    const formData = [

        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Company"),
            value: data?.company?.name,
            placeholder: t("Company"),
            onChange: (value) => updateState("company", value),
            error: data?.error?.company,
            isReadonly: true

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Taxpayer Provided Id"),
            value: data?.taxpayerProvidedId,
            placeholder: t("Taxpayer Provided Id"),
            onChange: (value) => updateState("taxpayerProvidedId", value?.target.value),
            error: data?.error?.taxpayerProvidedId,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Email Id"),
            value: data?.emailId,
            placeholder: t("Email Id"),
            onChange: (value) => updateState("emailId", value?.target.value),
            error: data?.error?.emailId,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Model"),
            value: data?.model,
            placeholder: t("Model"),
            onChange: (value) => updateState("model", value?.target.value),
            error: data?.error?.model,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Crn Number"),
            value: data?.crnNumber,
            placeholder: t("Crn Number"),
            onChange: (value) => updateState("crnNumber", value?.target.value),
            error: data?.error?.crnNumber,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Vat Name"),
            value: data?.vatName,
            placeholder: t("Vat Name"),
            onChange: (value) => updateState("vatName", value?.target.value),
            error: data?.error?.vatName,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Vat Number"),
            value: data?.vatNumber,
            placeholder: t("Vat Number"),
            onChange: (value) => updateState("vatNumber", value?.target.value),
            error: data?.error?.vatNumber,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Branch Name"),
            value: data?.branchName,
            placeholder: t("Branch Name"),
            onChange: (value) => updateState("branchName", value?.target.value),
            error: data?.error?.branchName,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Branch Industry"),
            value: data?.branchIndustry,
            placeholder: t("Branch Industry"),
            onChange: (value) => updateState("branchIndustry", value?.target.value),
            error: data?.error?.branchIndustry,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("City"),
            value: data?.city,
            placeholder: t("City"),
            onChange: (value) => updateState("city", value?.target.value),
            error: data?.error?.city,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("City Sub Division"),
            value: data?.citySubDivision,
            placeholder: t("City Sub Division"),
            onChange: (value) => updateState("citySubDivision", value?.target.value),
            error: data?.error?.citySubDivision,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Street"),
            value: data?.street,
            placeholder: t("Street"),
            onChange: (value) => updateState("street", value?.target.value),
            error: data?.error?.street,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Plot Identification"),
            value: data?.plotIdentification,
            placeholder: t("Plot Identification"),
            onChange: (value) => updateState("plotIdentification", value?.target.value),
            error: data?.error?.plotIdentification,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Building"),
            value: data?.building,
            placeholder: t("Building"),
            onChange: (value) => updateState("building", value?.target.value),
            error: data?.error?.building,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Postal Zone"),
            value: data?.postalZone,
            placeholder: t("Postal Zone"),
            onChange: (value) => updateState("postalZone", value?.target.value),
            error: data?.error?.postalZone,

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "text",
            label: t("Otp"),
            value: data?.otp,
            placeholder: t("Otp"),
            onChange: (value) => updateState("otp", value?.target.value),
            error: data?.error?.otp
        },


    ]
    const paymentGatewayformData = [

        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "toggleButton",
            label: t("Custom"),
            value: data?.is_custom,
            placeholder: t("Custom"),
            onChange: (value) => {
                console.log(value, 'ddkdk')
                updateState("is_custom", value)
            },
            error: data?.error?.is_custom,
            disabled: popupData?.is_custom ? (data?.is_common ? true : false) : true

        },
        {
            size: {
                xs: 6,
            },
            isActive: true,
            component: "toggleButton",
            label: t("Common"),
            value: data?.is_common,
            placeholder: t("Common"),
            onChange: (value) => updateState("is_common", value),
            error: data?.error?.is_common,
            disabled: popupData?.is_common ? (data?.is_custom ? true : false) : true

        },
        {
            size: {
                xs: 12,
            },
            isActive: true,
            component: "text",
            label: t("Name"),
            value: popupData?.name,
            placeholder: t("Name"),
            onChange: (value) => updateState("company", value),
            isReadonly: true

        },
        {
            size: {
                xs: 6,
            },
            isActive: data?.is_custom ? true : false,
            component: "text",
            label: t("Custom Key Id"),
            value: data?.custom_key_id,
            placeholder: t("Custom Key Id"),
            onChange: (value) => updateState("custom_key_id", value?.target.value),
            error: data?.error?.custom_key_id,

        },
        {
            size: {
                xs: 6,
            },
            isActive: data?.is_custom ? true : false,
            component: "text",
            label: t("Custom Secret"),
            value: data?.custom_secret,
            placeholder: t("Custom Secret"),
            onChange: (value) => updateState("custom_secret", value?.target.value),
            error: data?.error?.custom_secret,

        }
    ]

    const getFormData = {
        4: formData,
        3: paymentGatewayformData
    }

    const handleIcon = (type, value) => {
        if (type === "edit") {
            setData({
                ...data,
                company: value,
                emailId: value?.company_address?.[0]?.email_id,
                crnNumber: value?.cr_no,
                vatNumber: value?.tax_registration_number,
                branchName: value?.branch_name,
                street: value?.company_address?.[0]?.street_1,
                city: value?.company_address?.[0]?.city,
                citySubDivision: value?.company_address?.[0]?.state,
                plotIdentification: value?.company_address?.[0]?.door_no,
                building: value?.company_address?.[0]?.street_2,
                postalZone: value?.company_address?.[0]?.zipcode,

                name: value?.payment_config?.name,
                custom_key_id: value?.payment_config?.is_custom ? value?.payment_config?.key_id : null,
                custom_secret: value?.payment_config?.is_custom ? value?.payment_config?.secret : null,
                is_custom: value?.payment_config?.is_custom,
                is_common: value?.payment_config?.is_common,
                company_id: value?.payment_config?.company_id,
                client: value?.payment_config?.client_id,
                payment_id: value?.payment_config?.id
            })
            setDrawerOpen({ table: false, form: true, open: true })
        }

    }
    //dialog content
    const dialogContent = () => {
        return <>
            <Grid container height={`calc(100vh - 150px)`}>
                <Grid item xs={6} className={classes.verticalLine}>
                    <Box >
                        <Typography className={classes.header}>{popupData?.img}</Typography>
                    </Box>
                    <Box >
                        <Typography className={classes.header}>{popupData?.name}</Typography>
                    </Box>
                    <Box >
                        <Typography className={classes.subHeader}>{popupData?.desc}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box p={2}>
                        <Box className={classes.buyBox}>
                            <Typography className={classes.buyBoxHeader}>{popupData?.name}</Typography>
                            <Typography className={classes.buyBoxHeader2}>{popupData?.name}</Typography>
                            <Typography className={classes.buyBoxHeader3}>{popupData?.name}</Typography>
                            <br />
                            <Button variant="contained" fullWidth
                                onClick={() => {
                                    getData()
                                    setDialog(false)
                                    setDrawerOpen({ table: true, form: false, open: true })
                                }}
                                disabled={!popupData?.purchased}
                            >
                                {"Configure"}
                            </Button>
                        </Box>
                        {
                            popupData?.purchased &&
                            <Box className={classes.buyBox2}>
                                <Typography className={classes.buyBoxHeader2}>{"If You have already purchased ?"}</Typography>
                                <Typography className={classes.buyBoxHeader3}>{"Then enter the purchased reference key below"}</Typography>
                                <TextBox

                                    endAdornment={<Button variant='text' className={classes.btn}>Check</Button>}
                                />
                            </Box>
                        }

                    </Box>
                </Grid>
            </Grid>

        </>
    }
    //render
    const render = () => {
        return <>
            <Subheader
                title={detailsData?.name ?? ""}
                isReadOnly
                goBack={() => history.goBack(-1)}
                value={{}} onchange={() => false}
            />
            <Box className={classes.box2} >
                {loading ? <NewLoader /> :
                    <Grid container spacing={2} p={2}>
                        {detailsData?.addon_sub_categories?.map((item) => {
                            return <>
                                <Grid item xs={12}>

                                    <Box >
                                        <Typography className={classes.header}>{item?.name}</Typography>
                                    </Box>
                                    <Box >
                                        <Typography className={classes.subHeader}>{item?.description}</Typography>
                                    </Box>
                                    <Box position={"relative"}>
                                        <Scroller isOther={3}>
                                            <Grid container spacing={2}>
                                                {item?.add_ons?.map((i) => {
                                                    return <>
                                                        <Grid item xs={3}>
                                                            <MarketPlaceCard data={i} item={item} master={false} onClick={handleClick} />
                                                        </Grid>
                                                    </>
                                                })}
                                            </Grid>
                                        </Scroller>
                                    </Box>
                                    <Grid item xs={12} height={"6px"} bgcolor={"#E4E8EE"} marginTop={"16px"}></Grid>
                                </Grid>
                            </>
                        })
                        }

                    </Grid >
                }
                <Drawer
                    anchor={"right"}
                    open={drawerOpen?.open}
                    onClose={() => {
                        setTableData({
                            isDisable: false,
                            row: [],
                            totalRowsCount: 0
                        })
                        setDrawerOpen({ table: false, form: false, open: false })
                    }}>
                    <Stack direction="row" spacing={2} p={1.5} alignItems="center" sx={{ backgroundColor: "#F2F4F7" }}>
                        <Box onClick={() => setDrawerOpen({ table: false, form: false, open: false })} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                        <Typography className={classes.header}>{"Configuration Details"}</Typography>
                    </Stack>
                    <Divider />
                    <Box style={{ width: size?.width - 500 }} p={2}>
                        {drawerOpen?.table && <>
                            <Box sx={{ width: 340 }}>
                                {/*search */}
                                <SearchFilter label={false} placeholder={t("search")}
                                    handleChange={(value) => handleOccupantSearch(value)} value={searchText}
                                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                />
                            </Box>
                            <TableWithPagination
                                is_loading={tableData?.isDisable}
                                heading={Heading(t)}
                                rows={tableData?.row}
                                path={Path}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleConfiguration={handleConfiguration}
                                tableType="no-side"
                                dataType={Type}
                                handlePagination={handleTablePagination}
                                handleChangeLimit={handleTablePageLimit}
                                totalRowsCount={tableData?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 200px)'}
                                view={true}
                                edit={true}
                                delete={true}
                                noDataText={"No Company Found"}
                                handleIcon={handleIcon}
                            />
                        </>}

                        {drawerOpen?.form &&
                            <>
                                <Box style={{ height: size?.height - 140 }} overflow={"auto"}>
                                    <FormGenerator components={getFormData[popupData?.drawer_type]} />
                                    <br />
                                </Box>
                                <Box>
                                    <Button variant="contained" fullWidth
                                        onClick={onSubmit}
                                        disabled={popupData?.drawer_type === 3 ? (data?.isDisable ? true : ((data?.is_common || data?.is_custom) ? false : true)) : data?.isDisable}
                                    >
                                        Configure
                                    </Button>
                                </Box>
                            </>
                        }
                    </Box>
                </Drawer>
            </Box>
            <AlertDialog
                medium={true}
                open={dialog}
                onClose={() => setDialog(false)}
                header={popupData?.name}
                component={
                    <Box p={2}>
                        {dialogContent()}
                    </Box>
                }
            />

        </>
    }



    return <div >
        {accessCheckRender(render, permission)}
    </div >
}
const props = {
    boxShadow: false
}
export default withNavBars(withNamespaces("functionalLocation")(MarketPlaceView), props)
