import React from "react";
import { withNavBars } from "../../HOCs";
import BlockList from "./block";

class BlockParent extends React.Component {
    render() {
        return <BlockList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(BlockParent, props);
