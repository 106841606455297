
import React from "react";
import { AuthContext } from "./contexts";
import { getCurrentBuildNumber } from "./utils";


class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      is_version_alert_open: false,
      new_version: "",
      auth: {
        roles: null,
        device: "web",
        refresh: true,
        activeRole: null,
        first_name: null,
        last_name: null,
        profile_img: null,
        emailId: null,
        routes: [],
      },
      modules: {},
      setAuth: this.setAuth,
      setModule: this.setModule,
    };
  }

  componentDidMount() {
    this.refreshAPI();
  }

  refreshAPI = async () => {
    await Promise.all([getCurrentBuildNumber()]);
  };

  setModule = (modules) => {
    this.setState({
      ...this.state,
      modules,
    });
  };

  setAuth = (auth) => {
    this.setState({ auth });
  };

  render() {
    return (
      <>
        <AuthContext.Provider value={this.state}>
          {this.props.children}
          {/* <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            autoHideDuration={15000}
            open={this?.state?.is_version_alert_open}
            onClose={() => this?.handleVersionAlert("")}
            message={"A newer version is available."}
            action={
              <React.Fragment>
                <Button size="small" style={{ color: "white" }}
                  onClick={() => {
                    refreshCacheAndReload(this?.state?.new_version)
                    this?.handleVersionAlert("")
                  }}>
                  Update
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={() => this?.handleVersionAlert("")}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            } /> */}
        </AuthContext.Provider>
      </>
    );
  }
}

export default AppAuth;
