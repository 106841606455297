import { makeStyles } from "@mui/styles"
import { SemiBold, remCalc } from "../../utils"


export const CustomFileUploadStyle = makeStyles((theme) => ({
    button: {
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        background: theme.palette.info.light,
        height: "150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.typography.color.selected_1c,        
    },
    fileBox: {
        height: "150px",
        width: "100%",
        objectFit: "contain",
        borderRadius: "4px",
        position: "relative"
    },
    deleteBox: {
        position: "absolute",
        top: "20px",
        right: "10px",
        zIndex: 999,
        cursor: "pointer"
    },
    viewBox: {
        height: "400px",
        width: "100%",
        padding: "16px",
        borderRadius: "4px"
    }
}))