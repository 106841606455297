import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { AlertDialog } from '../../components'
import { contactsViewStyles } from "./contactsViewStyle";
import { LocalStorageKeys, stringAvatar } from '../../utils'
import { format } from "date-fns";

export const ContactsView = (props) => {
    const localStorageCompany = localStorage?.getItem?.(LocalStorageKeys?.selectedCompany);
    const currency_code = localStorageCompany ? JSON?.parse?.(localStorageCompany)?.code : "";
    const { t } = props;
    const classes = contactsViewStyles()
    const [imageViwer, setImageViwer] = React.useState(false)

    return (
        <>
            <Box className={classes.boxTab}>
                <div className={classes.card}>

                    <Grid container bgcolor="#F5F7FA">
                        <Grid item xs={12} md={12} lg={2} className={classes.imgDiv}>

                            <Avatar src={props?.data?.image_url} className={classes.unitImg}
                                {...stringAvatar(props?.data?.first_name)}
                                onClick={() => setImageViwer(true)}></Avatar>
                        </Grid>
                        <Grid item xs={12} md={12} lg={10} className={classes.imgDiv2}>
                            <Typography className={classes.title} style={{ marginBottom: '10px' }}>{t("Profile Details")}</Typography>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12} md={6} lg={3}>

                                    <Box>
                                        <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.company_name ?? "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.first_name ?? "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Job Title")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.job_title && props?.data?.job_title !== "") ? props?.data?.job_title : "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Company ID")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.employee_id && props?.data?.employee_id !== "") ? props?.data?.employee_id : '-'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Gender")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.sex_type && props?.data?.sex_type !== "") ? props?.data?.sex_type : "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Relationship")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.relationship && props?.data?.relationship !== "") ? props?.data?.relationship : "-"}</Typography>

                                    </Box>
                                </Grid>
                                {
                                    props?.data?.vendor_name?.length > 0 &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Vendor")}</Typography>
                                            <Typography className={classes.sub} noWrap>{(props?.data?.vendor_name && props?.data?.vendor_name !== "") ? props?.data?.vendor_name : "-"}</Typography>

                                        </Box>
                                    </Grid>
                                }
                                {
                                    props?.data?.service_provider?.name?.length > 0 &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Service Provider")}</Typography>
                                            <Typography className={classes.sub} noWrap>{(props?.data?.national_id && props?.data?.national_id !== "") ? props?.data?.service_provider?.name : "-"}</Typography>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Department")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.department?.length ? props?.data?.department : "-"}</Typography>
                                    </Box>
                                </Grid>
                                {props?.data?.reporting?.name &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box marginTop="4px">

                                            <Typography className={classes.heading}>{t("Reporting To")}</Typography>
                                            <Typography className={classes.sub} noWrap display={"flex"} alignItems={"center"}>
                                                <Avatar
                                                    {...stringAvatar(props?.data?.first_name)}
                                                    src={props?.data?.reporting?.name} />

                                                &nbsp;&nbsp;{(props?.data?.reporting?.name && props?.data?.reporting?.name !== "") ? props?.data?.reporting?.name : "-"}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    props?.data?.job_name &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Job Title")}</Typography>
                                            <Typography className={classes.sub} noWrap>{props?.data?.job_title}</Typography>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    props?.data?.job_name &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Jobs & Role")}</Typography>
                                            <Typography className={classes.sub} noWrap>{props?.data?.job_name}</Typography>
                                        </Box>
                                    </Grid>
                                }

                            </Grid>
                        </Grid >
                    </Grid >
                    <br />
                    <Divider />
                    <br />
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className={classes.addressRoot}>
                                    <Stack direction="row" >
                                        <img src='/images/loc.svg' alt='' />
                                        <Typography className={classes.title2}>&nbsp;{t("Address")}</Typography>
                                    </Stack>
                                    <Box height="12px" />
                                    <Typography className={classes.sub}>
                                        {props?.data?.door_no ? props?.data?.door_no + ", " : ""}
                                        {props?.data?.street_1 ? props?.data?.street_1 + ", " : ""}
                                        {props?.data?.street_2 ? props?.data?.street_2 + ", " : ""}
                                        {props?.data?.landmark ? props?.data?.landmark + ", " : ""}
                                        {props?.data?.area ? props?.data?.area + ", " : ""}
                                        {props?.data?.city ? props?.data?.city + ", " : ""}
                                        {props?.data?.state ? props?.data?.state + ", " : ""}
                                        {props?.data?.country ? props?.data?.country + ", " : "-"}
                                        {props?.data?.zipcode ?? ""}
                                    </Typography >

                                </div >
                            </Grid >
                            <Grid item md={12} lg={6}>
                                <div className={classes.addressRoot}>
                                    <Stack direction="row" >
                                        <img src='/images/loc.svg' alt='' />
                                        <Typography sx={{ marginInlineStart: "6px" }} className={classes.title}>{t("Contact & Other Information")}</Typography>
                                    </Stack>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} mt={"8px"} >
                                            <Typography className={classes.heading}>{t("Telephone")}:</Typography>
                                            <Typography className={classes.subMob} noWrap>
                                                {(props?.data?.phone && props?.data?.phone !== "") ?
                                                    ((props?.data?.phone_code ?? "") + (props?.data?.phone_code && "-") + props?.data?.phone) : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} mt={"8px"}>
                                            <Typography className={classes.heading}>{t("Mobile Number")} :</Typography>
                                            <Typography className={classes.subMob} noWrap>
                                                {(props?.data?.mobile_no && props?.data?.mobile_no !== "") ?
                                                    ((props?.data?.mobile_no_country_code ?? "") + (props?.data?.mobile_no_country_code && "-") + props?.data?.mobile_no) : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.heading}>{t("Contact")}:</Typography>
                                            <Typography className={classes.sub} noWrap>{props?.data?.preferred_mode_contact ?? '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                            <Typography className={classes.subEmail} noWrap>{props?.data?.email_id ?? '-'}</Typography>
                                        </Grid>
                                        {
                                            props?.data?.login_email &&
                                            <Grid item xs={6}>
                                                <Typography className={classes.heading}>{t("Login Email Address")} :</Typography>
                                                <Typography className={classes.subEmail} noWrap>{props?.data?.login_email ?? '-'}</Typography>
                                            </Grid>
                                        }

                                    </Grid>
                                </div>
                            </Grid>
                        </Grid >
                    </div >
                    <AlertDialog isNormal isnotTitle component={
                        <div style={{ display: 'flex' }}>
                            <img src={props?.data?.image_url} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                        </div>
                    } open={imageViwer} onClose={() => setImageViwer(false)} />
                    <Divider />
                    <div className={classes.borderRoot}>
                        <Typography className={classes.title} >{t("Lead & Opportunity Management")}</Typography>
                        <Grid container rowSpacing={1} marginTop={"4px"}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Last Revenue Target")} : </Typography>
                                <Typography className={classes.sub} noWrap>
                                    <span className={classes.matric} style={{ marginInlineEnd: "4px" }}>{props?.data?.last_revenue_target &&
                                        (currency_code ?? "")}</span>
                                    {(props?.data?.last_revenue_target && props?.data?.last_revenue_target !== "") ? props?.data?.last_revenue_target : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Current Revenue Target")} :</Typography>
                                <Typography className={classes.sub} noWrap>
                                    <span className={classes.matric} style={{ marginInlineEnd: "4px" }}>{props?.data?.current_revenue_target &&
                                        (currency_code ?? "")}</span>
                                    {(props?.data?.current_revenue_target && props?.data?.current_revenue_target !== "") ? props?.data?.current_revenue_target : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.borderRoot}>
                        <Typography className={classes.title} >{t("Social Profile")}</Typography>
                        <Grid container rowSpacing={1} marginTop={"4px"}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Whatsapp")} : </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.whatsapp && props?.data?.whatsapp !== "") ? props?.data?.whatsapp : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Facebook")} :</Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.facebook && props?.data?.facebook !== "") ? props?.data?.facebook : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Twitter")} :</Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.twitter && props?.data?.twitter !== "") ? props?.data?.twitter : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Linkedin")} : </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.linkedin && props?.data?.linkedin !== "") ? props?.data?.linkedin : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </div >


                    <div className={classes.borderRoot}>
                        <Typography className={classes.title} >{t("ID Details")}</Typography>
                        <Grid container rowSpacing={1} marginTop={"4px"}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("National ID")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.national_id ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("National ID Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.national_id_expiry ? format(new Date(props?.data?.national_id_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Passport ID")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.passport_id ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Passport ID Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.passport_expiry ? format(new Date(props?.data?.passport_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Resident ID")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.resident_id ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Resident ID Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.resident_id_expiry ? format(new Date(props?.data?.resident_id_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Visa Number")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.visa_no ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Visa Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.visa_expiry ? format(new Date(props?.data?.visa_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </div >
                </div >
            </Box >

        </>
    )
}