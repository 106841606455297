import jwt_decode from "jwt-decode";
import { Switch } from "react-router-dom";
import styled from "styled-components";
import { config } from "../config";
import { NetworkCall } from "../networkcall";
import { Routes } from "../router/routes";
import { CalendarOptions, enumLabel, enumName, LocalStorageKeys, NetWorkCallMethods, QuarterCalc, RolesData, AlertProps, SemiBold } from "../utils";
import { AccessDenied } from "../components";
import dayjs from 'dayjs';
import { NewLoader } from "../components/newLoader";
import moment from "moment-timezone";
import { MoveInIcon } from "../assets/timeOffRequest/moveInIcon";
import { GeneralIcon } from "../assets/timeOffRequest/generalIcon";
import { MoveOutIcon } from "../assets/timeOffRequest/moveOutIcon";
import { ServiceIcon } from "../assets/timeOffRequest/serviceIcon";
import { SiteVisitIcon } from "../assets/timeOffRequest/siteVisitIcon";
import { UnitReadinessIcon } from "../assets/timeOffRequest/unitReadinessIcon";
import { UnitHandOverIcon } from "../assets/timeOffRequest/unitHandOverIcon";
import Compressor from "compressorjs";
import { InputFieldIcon } from "../assets/inputFieldIcon";
import { MultiSelectIcon } from "../assets/multiSelectIcon";
import { DropDownIcon } from "../assets/dropDownIcon";
import { RatingIcon } from "../assets/ratingicon";
import { addMonths, add} from "date-fns";
import { AuthContext } from "../contexts";
import { useContext } from "react";
import { NumberFormat } from "../screens/customizeQUote/function";


export const mapResult = (map) => {
  let updateData = {
    latitude: map?.lat,
    longitude: map?.lng,
  };
  if (map) {
    let keys = [
      "doorNo",
      "addressLineOne",
      "addressLineTwo",
      "landmark",
      "area",
      "district",
      "city",
      "state",
      "country",
      "pincode",
      "zipcode",
    ];
    let findAddress = map?.result?.address_components;
    keys?.map((_) => {
      switch (_) {
        case "doorNo":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) => ["premise"]?.includes(type))
            )?.long_name ?? "";
          break;
        case "addressLineOne":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) =>
                ["establishment", "street_number"]?.includes(type)
              )
            )?.long_name ?? "";
          break;
        case "addressLineTwo":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) =>
                ["neighborhood", "route"]?.includes(type)
              )
            )?.long_name ?? "";
          break;
        case "landmark":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("landmark"))
              ?.long_name ?? "";
          break;
        case "area":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("political", "locality")
            )?.long_name ?? "";
          break;
        case "district":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_3")
            )?.long_name ?? "";
          break;
        case "city":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_3")
            )?.long_name ?? "";
          break;
        case "state":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_1")
            )?.long_name ?? "";
          break;
        case "country":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("country"))
              ?.long_name ?? "";
          break;
        case "pincode":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("postal_code"))
              ?.long_name ?? "";
          break;
        case "zipcode":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("postal_code"))
              ?.long_name ?? "";
          break;
        default:
          return null;
      }
      return _;
    });
  }

  return updateData;
};

// File Upload
export const fileUpload = async (file, extra_param, alert) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      file && formData.append("files", file);
      extra_param?.companyId && formData.append("companyId", `${extra_param?.companyId}`);
      extra_param?.type && formData.append("type", `${extra_param?.type}`);
      extra_param?.tenantId && formData.append("tenantId", `${extra_param?.tenantId}`);

      let uploaded_file_res = await NetworkCall(
        `${config.api_url}/file-upload`,
        NetWorkCallMethods.post,
        formData, null, true, false
      );

      resolve(uploaded_file_res?.data?.fileUrls)
    } catch (error) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
      reject(error);
    }
  });
};

// Single File Upload
export const singleFileUpload = async (file, extra_param, alert, allowed_image_size) => {
  return new Promise(async (resolve, reject) => {
    try {
      let uploaded_file = []
      let file_type = file?.type.split("/")?.[0]
      if (file_type === "image") {
        new Compressor(file, {
          quality: 0.8,
          async success(result) {
            let bytes = result?.size
            let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
            if (finalSize <= allowed_image_size) {
              uploaded_file = await fileUpload(result, extra_param, alert)
              resolve(uploaded_file)
            } else {
              alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: `Please upload less then ${allowed_image_size??5} MB image`,
              });
              setTimeout(() => {
                resolve(null)
              }
                , 1000)
            }
          },
          error(err) {
            alert.setSnack({
              ...alert, open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
            });
          },
        })
      } else {
        let bytes = file?.size
        let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
        if (finalSize <= allowed_image_size) {
          uploaded_file = await fileUpload(file, extra_param, alert)
          resolve(uploaded_file)
        } else {
          alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.error,
            msg: "Please upload less then 5 MB file",
          });
          
          setTimeout(() => {
            resolve(null)
          }
            , 1000)
        }
      }
    } catch (error) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    }
  });
};

// Multi File Upload
export const multiFileUpload = async (data, extra_param, alert, allowed_image_size, priority = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      let uploaded_files = []
      for (let i = 0; i < data.length; i++) {
        let uploaded_file = await singleFileUpload(data[i], extra_param, alert, allowed_image_size)
        if (uploaded_file?.[0]?.url) {
          if (!priority) {
            uploaded_files = [
              ...uploaded_files,
              {
                url: uploaded_file?.[0]?.url,
                asset_type: extra_param?.type,
                is_active: true
              }
            ]
          }
          else {
            uploaded_files = [
              ...uploaded_files,
              {
                priority: i + 1,
                url: uploaded_file?.[0]?.url,
                asset_type: extra_param?.type,
                is_active: true,
                file_meta: {
                  name: data[i]?.name?.split(".")?.[0],
                  size: formatSizeUnits(data[i]?.size ?? 0),
                  type: data[i]?.name?.split(".")?.[1],
                }
              }
            ]
          }
        }
      }
      resolve(uploaded_files)
    } catch (error) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    }
  });
};

// const images type
export const assestType = {
  Facility_Images: 1,
  General_Images: 2,
  Videos: 3,
  Documents: 4,
  Three_sixty_Images: 5,
  Thumbnails: 6,
  Floor_Plan: 7,
};
// company details
export const companyDetails = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((_) => _?.name === RolesData?.manager)
  ?.map((x) => x);

export const verifyToken = (token) => {
  if (token !== null && token !== undefined && token !== "") {
    jwt_decode(token);
  }
};
// company drop down option
export const companyIDManager = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((x) => x?.name === RolesData?.manager)
  ?.map((x) => {
    return { value: x?.company_id, label: x?.company_name };
  });
// lease manager
export const companyIDLeaseManager = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((x) => x?.name === RolesData?.leaseManager)
  ?.map((x) => {
    return { value: x?.company_id, label: x?.company_name };
  });
// security manager
export const companyIDSecurityManager = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((x) => x?.name === RolesData?.securityManager)
  ?.map((x) => {
    return { value: x?.company_id, label: x?.company_name };
  });

// export const SocketSlug = new WebSocket(
//   `${config?.websocket}${localStorage.getItem(
//     LocalStorageKeys?.slug
//   )}/${localStorage.getItem(LocalStorageKeys?.profileID)}`
// );

// get set
export const keyMapSource = ({ enumType }) => {
  return enumType
    .flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value,
      };
    })
    .reduce((old, item) => ({ ...old, ...item }), {});
};

export const startAndEndOfWeek = (date) => {
  const now = date ? new Date(date) : new Date().setHours(0, 0, 0, 0);
  const monday = new Date(now);
  monday.setDate(monday.getDate() - monday.getDay() + 1);
  const sunday = new Date(now);
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
  return {
    startDate: monday,
    endDate: sunday,
  };
};

export const startAndEndOfMonth = () => {
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { startDate: firstDay, endDate: lastDay };
};

export const getTotal = (data) => {
  // get total count
  let totalCountofParkingslot = data.reduce(function (prev, current) {
    return prev + +current.value;
  }, 0);

  return totalCountofParkingslot;
};

export const getMenuList = (_) => {
  let AccessList = []
  if (_?.length > 0) {
    let tempL1MenuList = _
    for (let i = 0; i < tempL1MenuList?.length; i++) {
      if (tempL1MenuList[i]?.active_status && tempL1MenuList[i]?.type === "menu") {
        let tempL1Menu = {
          id: tempL1MenuList[i].id,
          name: tempL1MenuList[i].name,
          nav_icon: tempL1MenuList[i].nav_icon,
          router: Routes[tempL1MenuList[i].router],
          link: tempL1MenuList[i].link?.map((_) => Routes[_]),
          is_report: tempL1MenuList[i]?.is_report ?? false,
          end_point: tempL1MenuList[i]?.end_point ?? false,
          children: []
        }
        AccessList = [...AccessList, tempL1Menu]
        if (tempL1MenuList[i]?.children?.length > 0) {
          let tempL2MenuList = tempL1MenuList[i]?.children
          for (let j = 0; j < tempL2MenuList?.length; j++) {
            if (tempL2MenuList[j]?.active_status && tempL2MenuList[j]?.type === "menu") {
              let tempL2Menu = {
                id: tempL2MenuList[j].id,
                name: tempL2MenuList[j].name,
                nav_icon: tempL2MenuList[j].nav_icon,
                router: Routes[tempL2MenuList[j].router],
                link: tempL2MenuList[j].link?.map((_) => Routes[_]),
                children: [],
                is_report: tempL2MenuList[j]?.is_report ?? false,
                end_point: tempL2MenuList[j]?.end_point ?? false

              }
              let l1LastIndex = AccessList?.length - 1
              AccessList[l1LastIndex].children =
                [...AccessList[l1LastIndex]?.children, tempL2Menu]
              if (tempL2MenuList[j]?.children?.length > 0) {
                let tempL3MenuList = tempL2MenuList[j]?.children
                for (let k = 0; k < tempL3MenuList?.length; k++) {
                  if (tempL3MenuList[k]?.active_status && tempL3MenuList[k]?.type === "menu") {
                    let tempL3Menu = {
                      id: tempL3MenuList[k].id,
                      name: tempL3MenuList[k].name,
                      router: Routes[tempL3MenuList[k].router],
                      link: tempL3MenuList[k].link?.map((_) => Routes[_]),
                      is_report: tempL3MenuList[k]?.is_report ?? false,
                      end_point: tempL3MenuList[k]?.end_point ?? false

                    }
                    let l2LastIndex = AccessList[l1LastIndex]?.children?.length - 1
                    AccessList[l1LastIndex].children[l2LastIndex].children =
                      [...AccessList[l1LastIndex]?.children[l2LastIndex]?.children, tempL3Menu]
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return AccessList
}
//IDM get access routes

// export const getAccessRoutes = (routes) => {
//   routes?.forEach(route => {
//     if (route?.active_status) {
//       access.push({ route: route?.router, permission: route?.permission })
//       if (route?.children && route?.children.length > 0) {
//         getAccessRoutes(route?.children)
//       }
//     }

//   })
//   let key = "route"
//   return [...new Map(access.map(item => [item[key], item])).values()].filter(item => item?.route)
// }
export const getAccessRoutes = (r) => {
  let access1 = [];

  const getRoutes = (routes) => {
    for (const data of routes) {
      if (data?.active_status) {
        const editableObj = {
          read: data?.permission?.read?.is_active,
          create: data?.permission?.create?.is_active,
          delete: data?.permission?.delete?.is_active,
          update: data?.permission?.update?.is_active
        };

        if (data?.router) access1.push({ route: data?.router, permission: editableObj });

        if (data?.children && data?.children.length > 0) {
          access1 = access1.concat(getAccessRoutes(data?.children));
        }
      }
    }
    // access1 = access1.concat({
    //   "route": "clientDashboard",
    //   "permission": {
    //     "read": true,
    //     "create": true,
    //     "delete": true,
    //     "update": true
    //   }
    // });
    return access1; // Return access1 after processing each level
  };

  return getRoutes(r);
};
export const clearAccess = () => true
//get page level permission
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getPageLevelPermission = (routes, routeName, permissionList) => {
  let value = permissionList.filter(
    (item) => item?.route === getKeyByValue(routes, routeName)
  );
  return value?.[0]?.permission;
};

export const getCreateRules = (property_hierarchy = "") => {
  let _ = enumName;
  return {
    block: [_.multiUnitBlockFloor, _.storageUnitStorageTypeStorageBin].includes(
      property_hierarchy
    ),
    floor: [
      _.multiUnitFloor,
      _.multiUnitBlockFloor,
      _.storageUnitStorageBin,
      _.storageUnitStorageTypeStorageBin,
    ].includes(property_hierarchy),
    unit: [
      _.singleUnit,
      _.multiUnit,
      _.multiUnitFloor,
      _.multiUnitBlockFloor,
      _.storageUnitStorageBin,
      _.storageUnitStorageTypeStorageBin,
    ].includes(property_hierarchy),
  };
};

export const getCreateUnitRule = (property_hierarchy = "", unitCount = 0) => {
  let _ = enumName
  return (
    [
      _.multiUnit, _.multiUnitFloor,
      _.multiUnitBlockFloor, _.storageUnitStorageBin,
      _.storageUnitStorageTypeStorageBin
    ].includes(property_hierarchy) ||
    ([
      _.singleUnit
    ].includes(property_hierarchy) && unitCount === 0)
  )
}

export const getToday = (date = new Date()) => {
  let today = moment(date).format("YYYY-MM-DD")
  return today
}

export const getYesterday = (date = new Date()) => {
  date.setDate(date.getDate() - 1)
  let yesterday = moment(date).format("YYYY-MM-DD")
  return yesterday
}
export const getTomorrow = (date = new Date()) => {
  date.setDate(date.getDate() + 1)
  let yesterday = moment(date).format("YYYY-MM-DD")
  return yesterday
}
export const getThisWeek = (date = new Date()) => {
  date.setDate(date.getDate() - date.getDay())
  let from = moment(date).format("YYYY-MM-DD")
  date.setDate(date.getDate() + 6)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getLastWeek = (date = new Date()) => {
  date.setDate(date.getDate() - date.getDay() - 7)
  let from = moment(date).format("YYYY-MM-DD")
  date.setDate(date.getDate() + 6)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}
export const getNextWeek = (date = new Date()) => {
  date.setDate(date.getDate() - date.getDay() + 8)
  let from = moment(date).format("YYYY-MM-DD")
  date.setDate(date.getDate() + 6)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}
export const getThisMonth = (date = new Date()) => {
  date.setDate(1)
  let from = moment(date).format("YYYY-MM-DD")
  date.setMonth(date.getMonth() + 1)
  date.setDate(date.getDate() - 1)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getLastMonth = (date = new Date()) => {
  date.setDate(1)
  date.setDate(date.getDate() - 1)
  let to = moment(date).format("YYYY-MM-DD")
  date.setDate(1)
  let from = moment(date).format("YYYY-MM-DD")
  return { from, to }
}
export const getNextMonth = (date = new Date()) => {
  const addMonth = addMonths(date, 1)
  addMonth.setDate(1)
  let from = moment(addMonth).format("YYYY-MM-DD")
  // addMonth.setDate(addMonth.getDate() - 1)
  let to = moment(new Date(addMonth.getFullYear(), addMonth.getMonth() + 1, 0)).format("YYYY-MM-DD")
  return { from, to }
}
export const getQuarter = (date = new Date(), quarter = []) => {
  const month = date.getMonth()
  let from = ""
  let to = ""
  for (let i = 0; i < quarter.length; i++) {
    if (quarter[i].months.includes(month)) {
      date.setFullYear(date.getFullYear() - quarter[i].year, quarter[i].monthStart, quarter[i].dateStart)
      from = moment(date).format("YYYY-MM-DD")
      date.setFullYear(date.getFullYear(), quarter[i].monthEnd, quarter[i].dateEnd)
      to = moment(date).format("YYYY-MM-DD")
    }
  }
  return { from, to }
}

export const getThisYear = (date = new Date()) => {
  date.setDate(1)
  date.setMonth(0)
  let from = moment(date).format("YYYY-MM-DD")
  date.setFullYear(date.getFullYear() + 1)
  date.setDate(date.getDate() - 1)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getLastYear = (date = new Date()) => {
  date.setFullYear(date.getFullYear() - 1, 0, 1)
  let from = moment(date).format("YYYY-MM-DD")
  date.setFullYear(date.getFullYear(), 11, 31)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}
export const getNextYear = (date = new Date()) => {
  date.setFullYear(date.getFullYear() + 1, 0, 1)
  let from = moment(date).format("YYYY-MM-DD")
  date.setFullYear(date.getFullYear(), 11, 31)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}
export const getCalendarOptions = () => {
  let today = getToday(new Date())
  let yesterday = getYesterday(new Date())
  let thisWeek = getThisWeek(new Date())
  let lastWeek = getLastWeek(new Date())
  let thisMonth = getThisMonth(new Date())
  let lastMonth = getLastMonth(new Date())
  let thisQuarter = getQuarter(new Date(), QuarterCalc.thisQuarter)
  let lastQuarter = getQuarter(new Date(), QuarterCalc.lastQuarter)
  let thisYear = getThisYear(new Date())
  let lastYear = getLastYear(new Date())
  let result = [
    { label: CalendarOptions.today, value: { from_date: today, to_date: today }, load: true },
    { label: CalendarOptions.yesterday, value: { from_date: yesterday, to_date: today }, load: true },
    { label: CalendarOptions.thisWeek, value: { from_date: thisWeek.from, to_date: thisWeek.to }, load: true },
    { label: CalendarOptions.lastWeek, value: { from_date: lastWeek.from, to_date: lastWeek.to }, load: true },
    { label: CalendarOptions.thisMonth, value: { from_date: thisMonth.from, to_date: thisMonth.to }, load: true },
    { label: CalendarOptions.lastMonth, value: { from_date: lastMonth.from, to_date: lastMonth.to }, load: true },
    { label: CalendarOptions.thisQuarter, value: { from_date: thisQuarter.from, to_date: thisQuarter.to }, load: true },
    { label: CalendarOptions.lastQuarter, value: { from_date: lastQuarter.from, to_date: lastQuarter.to }, load: true },
    { label: CalendarOptions.thisYear, value: { from_date: thisYear.from, to_date: thisYear.to }, load: true },
    { label: CalendarOptions.lastYear, value: { from_date: lastYear.from, to_date: lastYear.to }, load: true },
    { label: CalendarOptions.custom, value: { from_date: today, to_date: today }, load: false }
  ]
  return result
}

export const getCalendarCustomOptions = () => {
  let today = getToday(new Date())
  let tomorrow = getTomorrow(new Date())
  let nextWeek = getNextWeek(new Date())
  let nextMonth = getNextMonth(new Date())
  let nextYear = getNextYear(new Date())
  let result = [
    { label: CalendarOptions.today, value: { from_date: today, to_date: today }, load: true },
    { label: CalendarOptions.tomorrow, value: { from_date: tomorrow, to_date: tomorrow }, load: true },
    { label: CalendarOptions.nextWeek, value: { from_date: nextWeek.from, to_date: nextWeek.to }, load: true },
    { label: CalendarOptions.nextMonth, value: { from_date: nextMonth.from, to_date: nextMonth.to }, load: true },
    { label: CalendarOptions.nextYear, value: { from_date: nextYear.from, to_date: nextYear.to }, load: true },
  ]
  return result
}

/**
Get agreement request status object
*/
export const getARS = (_, type = "", view = "") => {
  switch (_) {
    case enumName.pending:
      return {
        value: _,
        label: view === "table" ? enumLabel.pendingART :
          view === "view" ? enumLabel.pendingARV : _
      }
    case enumName.approved:
      return {
        value: _,
        label: type === "termination" ? enumLabel.approvedATR :
          type === "renewal" ? enumLabel.approvedARR : _
      }
    case enumName.rejected:
      return { value: _, label: enumLabel.rejectedAR }
    case enumName.cancelled:
      return { value: _, label: enumLabel.cancelledAR }
    default:
      return {
        value: _,
        label: view === "table" ? enumLabel.pendingART :
          view === "view" ? enumLabel.pendingARV : _
      }
  }
}

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme?.palette?.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));


/**
Get company list from auth
*/
export const getCompanyOption = (backdrop, auth, alert) => {

  let access = auth?.auth?.auth?.access
  let activeRole = auth?.auth?.auth?.activeRole

  if (access && activeRole) {
    let tempCompanyList = access?.[activeRole]?.company?.map((_) => {
      let _c
      try {
        _c = {
          value: _?.value,
          label: _?.label,
          company_no: _?.company_no,
          propertyByID: _?.properties?.[0]?.id,
          currency: {
            value: _?.currency?.id,
            label: _?.currency?.name
          },
          currency_id: _.currency?.id,
          currency_symbol: _.currency?.symbol,
          code: _.currency?.code,
          property: _?.properties,
          uom: _?.uom_master ?
            { value: _?.uom_master?.id, label: _?.uom_master?.name } : "",
          data: _,
          timezone: _?.timezone,
          dial_code: _?.country?.dial_code,
          logo: _?.logo,
          address: _?.address,
          email_id: _?.email_id,
          mobile_code: _?.mobile_code,
          mobile_number: _?.mobile_number,
          tax_registration_number: _?.tax_registration_number
        }
      } catch (err) {
        alert.setSnack({
          ...alert, open: true, msg: "Some Thing Went Wrong",
          severity: AlertProps.severity.error,
        })

      }
      return _c
    })
    let selectedCompany = localStorage.getItem(LocalStorageKeys.selectedCompany)
    let localCompany
    if (selectedCompany && selectedCompany !== "undefined") {
      localCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    }
    let tempCompany = localCompany?.value ? localCompany : tempCompanyList?.[0]
    localStorage.setItem(LocalStorageKeys.selectedCompany, JSON.stringify(tempCompany))
    backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
    return {
      selected: tempCompany,
      list: tempCompanyList
    }
  } else {
    backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
    return
  }

}

export const getRoutePermission = async () => {
  let _ = await NetworkCall(
    `${config.authapi}/auth/refresh`,
    NetWorkCallMethods.get,
    null, null, true, false
  )
  return constructRoutePermission(_)
}

export const constructRoutePermission = (_) => {
  let permission = {} //read: true, create: true, delete: true, update: true
  let access = _?.data?.access
  let activeRole = Object.keys(access)?.[0]
  let structure = access?.[activeRole]?.role?.permissions?.[0]?.structure
  let routeLevelPermissions = getAccessRoutes(structure)
  let tempPermission = routeLevelPermissions?.filter(i => Routes[i?.route] === window.location.pathname)?.[0]?.permission
  if (tempPermission) {
    permission = {
      read: tempPermission?.read,
      create: tempPermission?.create,
      delete: tempPermission?.delete,
      update: tempPermission?.update,
    }
  }
  return permission
}

export const accessCheckRender = (render = () => false, routePermission, screen, loading) => {
  return (
    routePermission?.read ?
      (loading ? <NewLoader /> : render()) :
      (routePermission?.read === false ? <AccessDenied screen={screen} /> : <NewLoader />)
  )
}

export const pageName = {
  dashboard: "Dashboard"
}

export const constructRoutePermissionNew = (_) => {
  let private_route = JSON.parse(localStorage.getItem("private_route"));
  let permission = {} //read: true, create: true, delete: true, update: true
  let access = _?.auth?.auth?.access
  if (access) {
    let activeRole = Object.keys(access)?.[0]
    let structure = access?.[activeRole]?.role?.permissions?.[0]?.structure
    let routeLevelPermissions = getAccessRoutes(structure)
    let tempPermission = routeLevelPermissions?.filter(i => [window.location.pathname, private_route?.match?.path].includes(Routes[i?.route]))?.[0]?.permission
    if (tempPermission) {
      permission = {
        read: tempPermission?.read,
        create: tempPermission?.create,
        delete: tempPermission?.delete,
        update: tempPermission?.update,
      }
    }
  }
  return permission
}

export const getRoutePermissionNew = (auth) => {
  let tempPermission = null
  if (auth?.auth?.auth?.access) {
    let _ = auth?.auth?.auth
    if (_?.prevHistoryKey === window?.history?.state?.key) {
      tempPermission = constructRoutePermissionNew(auth)
    }
  }
  return tempPermission
}

export const convertDateFromTime = (t) => {
  let tArr = t.split(":")
  let cdt = new Date()
  return dayjs(new Date(cdt.getFullYear(), cdt.getMonth(), cdt.getDate(), tArr[0], tArr[1], tArr[2]))
}


/**
 * Get Custom Block Name
 * @param  property_hierarchy  Property Hierarchy in Property
 * @param  default_name  Default name to be displayed if not customisable
 */
export const getCustomBlockName = (property_hierarchy = {}, default_name = "Block") =>
  property_hierarchy?.is_customisable ? property_hierarchy?.property_hierarchy_labelByID?.[0]?.block_label : default_name

/**
 * Get Custom Floor Name
 * @param  property_hierarchy  Property Hierarchy in Property
 * @param  default_name  Default name to be displayed if not customisable
 */
export const getCustomFloorName = (property_hierarchy = {}, default_name = "Floor") =>
  property_hierarchy?.is_customisable ? property_hierarchy?.property_hierarchy_labelByID?.[0]?.floor_label : default_name

/**
 * Get Custom Floor Name
 * @param  property_hierarchy  Property Hierarchy in Property
 * @param  unitCount  No. of units
 */
export const getPropertyHierarchyUnitRule = (property_hierarchy = {}, unitCount = 0) =>
  property_hierarchy?.is_multi_unit || (!property_hierarchy?.is_multi_unit && unitCount === 0)



export const getTotalMangeInvoice = (data) => {
  // get total count
  let total = data.reduce(function (prev, current) {
    return prev + +NumberFormat(current.total_amount);
  }, 0);

  return total.toFixed(2);
};

export const constructPropertyList = (company, is_community = false) => {
  // As per CR commented

  // let allProperties = {
  //   label: "All Properties",
  //   value: company?.property?.map((x) => x?.id) ?? []
  // }
  let individualProperties;
  if (is_community) {
    let data = company?.property?.filter((x) => x?.is_community === true) ?? []

    individualProperties = data?.length > 0 ? data?.map((x) => {
      return {
        value: x.id,
        label: x.name,
      }
    }) : []
  }
  else {
    individualProperties =
      company?.property?.map((x) => {
        return {
          ...x,
          value: x.id,
          label: x.name
        }
      })
  }

  // let propertyList = [allProperties, ...individualProperties]
  let propertyList = [...individualProperties]
  return {
    list: propertyList,
    // all: allProperties
  }
}

/**
 * 
 * @param {string} time 
 * @param {int} seconds 
 * @returns HH:mm format eg., 08:59:59 -> 09:00
 */
export const addSecondFormat = (time, seconds, format) => {
  let tempDate_1 = moment(new Date()).format("YYYY-MM-DD " + time)
  let tempDate_2 = moment(new Date(tempDate_1)).add(seconds, 'seconds').format(format)
  return tempDate_2
}

export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
  else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1) { bytes = bytes + " bytes"; }
  else if (bytes === 1) { bytes = bytes + " byte"; }
  else { bytes = "0 bytes"; }
  return bytes;
}

export const getDirectRoutePermission = (_, route) => {
  let permission = {}
  let access = _?.auth?.auth?.access
  if (access) {
    let activeRole = Object.keys(access)?.[0]
    let structure = access?.[activeRole]?.role?.permissions?.[0]?.structure
    let routeLevelPermissions = getAccessRoutes(structure)
    let tempPermission = routeLevelPermissions?.filter(i => Routes[i?.route] === route)?.[0]?.permission
    if (tempPermission) {
      permission = {
        read: tempPermission?.read,
        create: tempPermission?.create,
        delete: tempPermission?.delete,
        update: tempPermission?.update,
      }
    }
  }
  return permission
}

export const checkNumber = (value) => {
  return Number.isNaN(value) ? 0 : value
}

export const showAddress = (address, connector = ",", for_empty_address = " - ") => {
  let address_key = [
    "door_no", "street_1", "street_2", "street_3",
    "landmark", "area", "city","state",
    "country", "zipcode", "latitude", "longitude"
  ]
  let address_array = []
  for (let i = 0; i < address_key.length; i++) {
    if (address[address_key[i]]?.length > 0) {
      address_array.push(address[address_key[i]])
    }
  }
  let temp_final_address = address_array.join(`${connector} `)
  let final_address = temp_final_address?.length > 0 ? temp_final_address : for_empty_address
  return final_address
}
//property options for data getting from refresh token data
export const getPropertyOptions = (company) => {
  let individualProperties =
    company?.selected?.property?.map((x) => {
      return {
        value: x.id,
        label: x.name
      }
    })
  return individualProperties
}

export const convertTimeUtcToZoneCalander = (date, format = "DD MMM YYYY", now) => {
  let company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

  if (!date) {
    return '-'
  }

  if (new Date(date).getUTCHours() <= 0) {
    let date_without_zone = new Date(date)?.toISOString()?.replace("Z", "")
    return moment(date_without_zone).format(format)
  }

  if (now) {
    if (company?.timezone) {
      return moment(date).tz(company?.timezone).fromNow();
    }
    else {
      return moment(date).tz(moment.tz.guess()).fromNow();
    }
  }
  if (company?.timezone) {
    return moment(date).tz(company?.timezone).format(format ?? "DD MMM YYYY");
  }
  else {
    return moment(new Date(date))
      .tz(moment.tz.guess())
      .format(format ?? "DD MMM YYYY")
  }


};

export const timeZoneConverter = (date, format = "DD MMM YYYY", now) => {
  // time zone for company
  let company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

  if (!date) {
    return '-'
  }

  if (new Date(date).getUTCHours() <= 0) {
    return moment(date).format(format)
  }


  if (now) {
    if (company?.timezone) {
      return moment(date).tz(company?.timezone).fromNow();
    }
    else {
      return moment(date).tz(moment.tz.guess()).fromNow();
    }
  }
  if (company?.timezone) {
    return moment(date).tz(company?.timezone).format(format ?? "DD MMM YYYY");
  }
  else {
    return moment(new Date(date))
      .tz(moment.tz.guess())
      .format(format ?? "DD MMM YYYY")
  }

};


export const currencyFormater = (currency_code, amount) => {
  const result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency_code,
    minimumFractionDigits: 0,
  }).format(amount);
  return result;
};

export const JobType = (val) => {
  switch (val) {
    case "move-in":
      return "Move In"
    case "move-out":
      return "Move Out"
    case "service":
      return "Service"
    case "site_visit":
      return "Site Visit"
    case "unit_readiness":
      return "Unit Readiness"
    case "unit_handover":
      return "Unit Handover"
    case "general":
      return "General"
    case "delivery-order":
      return "Delivery Order"
    case "maintenance":
      return "Maintenance"
    default:
      return "-"
  }
}

export const findImageSizes = (file) =>
  new Promise((resolve, reject) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = (e) => {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const convertUrlToFileObject = async (url) => {
  // Here is the code for converting "image source"(url) to "Base64".***

  let result = null;
  //let url = 'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg'
  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))


  /// Here is code for converting "Base64" to javascript "File Object".***

  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  /// Calling both function ***

  await toDataURL(url)
    .then(async (dataUrl) => {
      //console.log('Here is Base64 Url', dataUrl)
      var fileData = await dataURLtoFile(dataUrl, "imageName.jpg");
      //console.log("Here is JavaScript File Object", fileData)
      result = fileData
    })
  return result;

}

export const image_size = {
  height: 738,
  width: 1426
}

export const SwitchInspectionRoutes = (type) => {
  switch (type) {
    case "service":
      return Routes?.viewServiceInspection
    case "move-in":
      return Routes?.moveInInspectionItemDetails
    case "move-out":
      return Routes?.moveOutInspectionItemDetails
    case "general":
      return Routes?.viewGeneralInspection
    case "site_visit":
      return Routes?.viewSiteVisitDetails
    case "unit_readiness":
      return Routes?.viewUnitReadinessDetails
    case "unit_handover":
      return Routes?.unitHandOverInspectionItemDetails
    case "delivery-order":
      return Routes?.viewDeliveryOrders
    case "maintenance":
      return Routes?.maintanceRequestView
    default:
      return null
  }
}

export const SwitchInspectionRoutesName = (type) => {
  switch (type) {
    case "service":
      return "viewServiceInspection"
    case "move-in":
      return "moveInInspectionItemDetails"
    case "move-out":
      return "moveOutInspectionItemDetails"
    case "general":
      return "viewGeneralInspection"
    case "site_visit":
      return "viewSiteVisitDetails"
    case "unit_readiness":
      return "viewUnitReadinessDetails"
    case "unit_handover":
      return "unitHandOverInspectionItemDetails"
    case "delivery-order":
      return "viewDeliveryOrders"
    case "maintenance":
      return "maintanceRequestView"
    default:
      return null
  }
}


export const addOffset = (date, offset) => {
  return new Date(date.getTime() + offset * 1000);
}

export const convertUtczone = (date, zone) => {
  var utcCutoff = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss');
  return utcCutoff.clone().tz(zone);

}

// export const timeZone=(date,offset)=>{

//   let currenct_zone=(new Date().getTimezoneOffset())*60;

//   if(Math.abs(currenct_zone) !== offset){

//   }

// }

export const concat_string = (object = {}, object_key = [], connector = ", ", if_empty = " - ") => {
  let array = []
  for (let i = 0; i < object_key.length; i++) {
    if (object[object_key[i]]?.length > 0) {
      array.push(object[object_key[i]])
    }
  }
  let temp_string = array.join(`${connector} `)
  let final_string = temp_string?.length > 0 ? temp_string : if_empty
  return final_string
}

export const getEnumValueOppertunity = (data, value, options) => {
  if (data === "Monthly" && parseInt(value) < 3) {
    return options?.filter((val) => val?.value !== "Quarterly")
      .filter((val) => val?.value !== "Yearly").filter((val) => val?.value !== "Half Yearly")
  }
  else if (data === "Monthly" && parseInt(value) < 6) {
    return options?.filter((val) => val?.value !== "Yearly").filter((val) => val?.value !== "Half Yearly")
  }
  else if (data === "Monthly" && parseInt(value) < 12) {
    return options?.filter((val) => val?.value !== "Yearly")
  }
  else {
    return options
  }
}

export const RemoveZUTCDateTime = (date, format) => {
  // return val?.replace('Z', '')
  return moment(new Date(date))
    .tz(moment.tz.guess())
    .format(format ?? "DD MMM YYYY")
}
export const SwitchInspectionTypeIcons = (val) => {
  switch (val) {
    case "move-in":
      return <MoveInIcon />
    case "move-out":
      return <MoveOutIcon />
    case "general":
      return <GeneralIcon />
    case "service":
      return <ServiceIcon />
    case "site_visit":
      return <SiteVisitIcon />
    case "unit_readiness":
      return <UnitReadinessIcon />
    case "unit_handover":
      return <UnitHandOverIcon />
    default:
      return <MoveOutIcon />
  }
}
export const SwitchColorsInspection = (val) => {
  switch (val) {
    case "move-in":
      return { color: "#B3776D", backgroundColor: "#FEEAEA80" }
    case "move-out":
      return { color: "#6DAFB3", backgroundColor: "#DBF0F180" }
    case "general":
      return { color: "#B3A16D", backgroundColor: "#FFFAD880" }
    case "service":
      return { color: "#A5499A", backgroundColor: "#FEEFFC" }
    case "site_visit":
      return { color: "#7683B7", backgroundColor: "#E4E9FE80" }
    case "unit_readiness":
      return { color: "#CE6F6F", backgroundColor: "#FFD8D880" }
    case "unit_handover":
      return { color: "#78B1FE", backgroundColor: "#F1F7FF" }
    default:
      return { color: "#6DAFB3", backgroundColor: "#DBF0F180" }
  }
}
export const formComponentsList = [
  {
    id: 1,
    type: "text",
    name: "Input field",
    question: "",
    icon: <InputFieldIcon />
  },
  {
    id: 2,
    type: "radio",
    name: "Multi select",
    question: "",
    icon: <MultiSelectIcon />
  },
  {
    id: 3,
    type: "boolean",
    name: "Dropdown",
    question: "",
    icon: <DropDownIcon />,
    options: [{
      value: "",
    }],
  },
  {
    id: 4,
    type: "rating",
    question: "",
    name: "Rating range",
    icon: <RatingIcon />,
    start_range: 0,
    end_range: 10

  },

]

export const randomColor = (name) => {
  var colors = ['#5AC782', '#5078E1', '#b05f6d', '#ffc153', '#47b39d'];

  return colors[((name ? name?.length : 0) % colors.length)]
}
const alphabetColors = {
  A: '#C24B23',
  B: '#647D18',
  C: '#2C7E4E',
  D: '#3275A5',
  E: '#5E3B77',
  F: '#773B54',
  G: '#A1652A',
  H: '#366D00',
  I: '#387C65',
  J: '#55678C',
  K: '#9636A9',
  L: '#A93666',
  M: '#867339',
  N: '#497639',
  O: '#228078',
  P: '#464D9F',
  Q: '#994092',
  R: '#890A3F',
  S: '#616116',
  T: '#267D26',
  U: '#3E6C75',
  V: '#453B77',
  W: '#773B63',
  X: '#CB2C6E',
  Y: '#6F2E2E',
  Z: '#962CCB',
};

export const stringAvatar = (name, fontsize, radius = '8px !important') => {
  let capName = name ? name.trim('').toUpperCase() : null
  return {
    sx: {
      bgcolor: alphabetColors[capName?.[0]],
      fontSize: fontsize ?? "1rem",
      borderRadius: radius,
      fontFamily: SemiBold
    },
    children: capName && (capName.indexOf(' ') >= 0 ? `${capName.split(' ')[0][0]}${capName.split(' ')[1][0]}` : `${capName.split(' ')[0][0]}`),
  };
}

export const uniqueArr = (arr) => {
  return arr.filter((obj, index, self) =>
    index === self.findIndex((t) => (
      t.id === obj.id && t.name === obj.name
    ))
  );
}

export function groupByFun(list, keyGetter) {
  return list.reduce((acc, obj) => {
    const key = keyGetter(obj);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}


export const getDateFun = (type, date, value) => {
  let copyDate = new Date(JSON.parse(JSON.stringify(date)));
  switch (type) {
    case "Day":
      return add(new Date(copyDate), { days: value });
    case "Month":
      return add(new Date(copyDate), { months: value });
    case "Year":
      return add(new Date(copyDate), { years: value });
    case "Monthly":
      return add(new Date(copyDate), { months: value });
    case "Yearly":
      return add(new Date(copyDate), { years: value });
    default:
      return "not edited";
  }
};

export function timeDiffCalc(dateFuture, dateNow, day) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? days : days;
  }

  difference +=
    hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} minutes` : `${minutes} minutes`;
  if (day) {
    return days === 1 ? days ?? 0 : days;
  } else {
    return difference;
  }
}
export const FontFamilySwitch = () => {
  const auth = useContext(AuthContext);
  const language = auth?.auth?.auth?.language;
  switch (language) {
    case "en":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "zh":
      return {
        regular: "'NotoSansSC-Regular'",
        bold: "'NotoSansSC-Bold'",
        semiBold: "'NotoSansSC-SemiBold'",
        extraBold: "'NotoSansSC-ExtraBold'",
      };
    case "hi":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "es":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "fr":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "ar":
      return {
        regular: "'NotoSansArabic-Regular'",
        bold: "'NotoSansArabic-Bold'",
        semiBold: "'NotoSansArabic-SemiBold'",
        extraBold: "'NotoSansArabic-ExtraBold'",
      };
    case "ru":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "pt":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "it":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "fil":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "id":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    case "ta":
      return {
        regular: "'NotoSansTamil-Regular'",
        bold: "'NotoSansTamil-Bold'",
        semiBold: "'NotoSansTamil-SemiBold'",
        extraBold: "'NotoSansTamil-ExtraBold'",
      };
    case "te":
      return {
        regular: "'NotoSansTelugu-Regular'",
        bold: "'NotoSansTelugu-Bold'",
        semiBold: "'NotoSansTelugu-SemiBold'",
        extraBold: "'NotoSansTelugu-ExtraBold'",
      };
    case "kn":
      return {
        regular: "'NotoSansKannada-Regular'",
        bold: "'NotoSansKannada-Bold'",
        semiBold: "'NotoSansKannada-SemiBold'",
        extraBold: "'NotoSansKannada-ExtraBold'",
      };
    case "ml":
      return {
        regular: "'NotoSansMalayalam-Regular'",
        bold: "'NotoSansMalayalam-Bold'",
        semiBold: "'NotoSansMalayalam-SemiBold'",
        extraBold: "'NotoSansMalayalam-ExtraBold'",
      };
    case "de":
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
    default:
      return {
        regular: "NunitSans-regular",
        bold: "NunitSans-bold",
        semiBold: "NunitSans-semiBold",
        extraBold: "NunitSans-extraBold",
      };
  }
};

export const RetainLocalStorage = () => {
  let i18nextLng = localStorage.getItem(LocalStorageKeys?.i18nextLng);
  let version = localStorage.getItem(LocalStorageKeys?.version);
  localStorage.clear();
  localStorage.setItem(LocalStorageKeys?.i18nextLng, i18nextLng ?? "en-US");
  localStorage.setItem(LocalStorageKeys?.version, version);
}

export const getTotalArray = (data, value) => {
  // get total count
  let totalCountofParkingslot = data.reduce(function (prev, current) {
    return prev + NumberFormat(current[value]);
  }, 0);

  return !!totalCountofParkingslot ? Number(totalCountofParkingslot) : 0;
};

export const dateDiff = (d1, d2) => {
  var t2 = d2.getTime();
  var t1 = d1.getTime();
  return Math.floor((t2 - t1) / (24 * 3600 * 1000));
}

export const monthDiff = (d1, d2) => {
  var timeDiff = Math.abs(d2.getTime() - d1.getTime());
  return Math.round(timeDiff / (2e3 * 3600 * 365.25));
};

export const textReplacer = ({ text = "", condition = [] }) => {
  for (let i = 0; i < condition?.length; i++) {
    text = text?.replaceAll(condition?.[i]?.from, condition?.[i]?.to);
  }
  return text;
}


export function makeArrayUniqueByProperty(arr, property) {
  const uniqueMap = new Map();
  const uniqueArray = [];

  for (const item of arr) {
    if (!uniqueMap.has(item[property])) {
      uniqueMap.set(item[property], true);
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
}

export const getSummery = (array, t = () => false, symbol, datas) => {
  return [
    {
      label: t("Primary"),
      is_active: array?.filter((v) => v?.pricing_components?.primary)?.length > 0 ? true : false,
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => v?.pricing_components?.primary), 'amountWithoutDiscount'))?.toFixed(2)}`
    },
    {
      label: t("Secondary"),
      is_active: array?.filter((v) => !v?.pricing_components?.primary && !v?.pricing_components?.isOnetime && !v?.pricing_components?.primary && !v?.pricing_components?.is_late_fee && !v?.pricing_components?.is_wallet && !v?.pricing_components?.refundable && !v?.pricing_components?.is_commission_payment && !v?.pricing_components?.is_parking && !v?.inspection)?.length > 0 ? true : false,
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => !v?.pricing_components?.primary && !v?.pricing_components?.isOnetime && v?.componenttype !== "Inspection" && !v?.pricing_components?.is_late_fee && !v?.pricing_components?.is_wallet && !v?.pricing_components?.refundable && !v?.pricing_components?.is_commission_payment && !v?.pricing_components?.is_parking && !v?.inspection), 'amountWithoutDiscount'))?.toFixed(2)}`
    },
    {
      label: t("One Time Charges"),
      is_active: array?.filter((v) => v?.pricing_components?.isOneTime || v?.pricing_components?.isOnetime)?.length > 0 ? true : false,
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => v?.pricing_components?.isOneTime || v?.pricing_components?.isOnetime), 'amountWithoutDiscount'))?.toFixed(2)}`
    },
    // {
    //   label:t("Late Fees & Penalty"),
    //   is_active:array?.filter((v)=>v?.pricing_components?.is_late_fee)?.length > 0 ? true : false,
    //   amount:getTotalArray(array?.filter((v)=>v?.pricing_components?.is_late_fee) , 'amountWithoutDiscount')
    // },
    // {
    //   label:t( "Wallet Points"),
    //   is_active:array?.filter((v)=>v?.pricing_components?.is_wallet)?.length > 0 ? true : false,
    //   amount:getTotalArray(array?.filter((v)=>v?.pricing_components?.is_wallet) , 'amountWithoutDiscount')
    // },
    {
      label: t("Refundables"),
      is_active: array?.filter((v) => v?.pricing_components?.refundable)?.length > 0 ? true : false,
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => v?.pricing_components?.refundable), 'amountWithoutDiscount'))?.toFixed(2)}`
    },
    {
      label: t("Parking Slot"),
      is_active: array?.filter((v) => v?.pricing_components?.is_parking)?.length > 0 ? true : false,
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => v?.pricing_components?.is_parking), 'amountWithoutDiscount'))?.toFixed(2)}`
    },
    {
      label: t("Inventory Items"),
      is_active: array?.filter((v) => v?.inspection || v?.componenttype === "Inspection")?.filter((v) => !v?.is_wallet_item)?.length > 0 ? true : false,
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => v?.inspection || v?.componenttype === "Inspection")?.filter((v) => !v?.is_wallet_item), 'amountWithoutDiscount'))?.toFixed(2)}`
    },
    {
      label: t("Total Tax"),
      amount: `${symbol} ${checkNumber(getTotalArray(array?.filter((v) => !v?.is_wallet_item && !v?.pricing_components?.is_late_fee && !v?.pricing_components?.is_wallet && !v?.pricing_components?.is_commission_payment), 'fixTax'))?.toFixed(2)}`,
      is_active: true
    }
  ]
}


export const getElipses = (string, length, end) => {

  if (string?.length > length) {
    return `${string?.slice(0, end)}...`

  } else {
    return string
  }
}
export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
export function getMonths(startDate, monthCount) {
  // Copy the start date to avoid modifying the original date
  var resultDate = new Date(startDate);
  // Calculate the new month
  resultDate.setMonth(startDate.getMonth() + monthCount);
  // If the day of the month is different, set it to the last day of the new month
  if (startDate.getDate() !== resultDate.getDate()) {
    resultDate.setDate(0);
  }
  return resultDate;
}
export const getNextFrequency = (type, count,execution_date) => {
  let next_date;
  let re_date = execution_date
  if (type === "Weekly") {
    next_date = addDays(re_date, 7 * count)
  } else if (type === "Quarterly") {
    next_date = getMonths(re_date, 3 * count)
  } else if (type === "Monthly") {
    next_date = getMonths(re_date, 1 * count)
  } else if (type === "Half-Yearly") {
    next_date = getMonths(re_date, 6 * count)
  } else if (type === "Bi-Weekly") {
    next_date = addDays(re_date, 14 * count)
  } else if (type === "Bi-Monthly") {
    next_date = getMonths(re_date, 2 * count)
  } else if (type === "Daily") {
    next_date = addDays(re_date, 1 * count)
  }
  return next_date;
}

// Update device token in database
export const SetDeviceToken = (currentToken , is_delete=false) => {
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  if(!authToken){
     return true
  }

  if(localStorage.getItem(LocalStorageKeys?.deviceToken) || currentToken){
    const token = localStorage.getItem(LocalStorageKeys?.deviceToken) || currentToken
    const variables = {
      app_build:config?.app_x_build,
      token: token,
      is_delete
    };
  
    NetworkCall(
      `${config.api_url}/user-device-token/token`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        console.log("device token update")
      })
      .catch((error) => {
        console.log(error)
      });
  }
};

export const getCurrentBuildNumber = async () => {
  let version = "";
  let endpoint = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""
    }/meta.json`;

  await NetworkCall(endpoint, NetWorkCallMethods.get, null, null, false, true)
    .then((_) => {
      version = _.data.version;
      localStorage.setItem(LocalStorageKeys?.version, version);
    })
    .catch((err) => {
      console.log("err:", err);
    });

  return version;
};
