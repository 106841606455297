import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import FilterIMG from "../../../assets/filter";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, TableWithPagination, UseDebounce } from "../../../components";
import { CustomSelect } from "../../../components/filterGenerator/components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enum_types, enumSelect, NetWorkCallMethods } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { tableStyles } from "./style";
import { propertyHeading, propertyPath } from "./utils";
export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]
export const PropertyTable = (props) => {
    const defaultFilterState = {
        purpose: null,
        is_active: [true],
    };
    const { t } = (props)
    const classes = tableStyles();
    const [open, setOpen] = React.useState(false)
    const [fiterDrawer, setFiterDrawer] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const { companyId, teamId } = props
    const [property, setProperty] = React.useState({
        data: [],
        view: false,
        edit: false,
        error: {
            data: ""
        }
    })
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true);
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    const [enumValue, setEnumValue] = React.useState({
        purpose: [],
    });
    const [tableData, setTableData] = React.useState({
        data: [],
        count: 0
    })
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const debounce = UseDebounce()
    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.purpose_global_type,
        ]);
        setEnumValue({
            purpose: result?.purpose_global_type,
        });
    };
    //dialogClose
    const drawerClose = () => {
        setFiterDrawer(false)

    }
    //dialogClose

    const dialogClose = () => {
        setOpen(false)
        setProperty({
            data: [],
            error: {
                data: ""
            }
        })
    }
    //dialogOpen
    const dialogOpen = () => {
        setOpen(true)

    }
    const drawerOpen = () => {
        setFiterDrawer(true)

    }
    const changeactive = async (data, value) => {
        const payload = {
            id: data,
            update: {
                is_active: value === "Inactive" ? true : false,
            },
        }
        NetworkCall(
            `${config.api_url}/queries/team_property_access/update`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((rs) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Teams Property status changed successfully.!!!"),
                });
                getPropertyList();
            })
            .catch((er) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong. Internal Error.!!!"),
                });
            });
    };
    //handle icon
    const handleIcon = (type, data, status) => {

        if (type === "active") {
            changeactive(data.team_property_id, status)
        }
        if (type === "delete") {
            changeactive(data.team_property_id, status)
        }


    }

    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPropertyList(offset, limit, searchText)
    }
    //limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPropertyList(0, value, searchText)
    }
    //hanldesearch
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getPropertyList(0, limit, e)
    }
    //state update
    const updateState = (key, value) => {
        let error = property?.error
        error[key] = ""
        setProperty({ ...property, [key]: value, error })
    }
    //validate
    const validate = () => {
        let isValid = true;
        let error = property.error;
        //validate teamname
        if (property?.data?.length === 0) {
            isValid = false;
            error.data = t("Property is Required");
        }

        setProperty({ ...property, error });
        return isValid;
    }
    //createproperty
    const createProperty = () => {
        if (validate()) {
            let payload;
            setIsDisableBtn(true)
            if (property?.edit) {
                payload = {
                    teams_id: teamId,
                    propertyIds: property?.data?.length > 0 ? property?.data?.map((val) => val.value) : null,
                    company_id: companyId,
                    id: property?.id

                }
            } else {
                payload = {
                    teams_id: teamId,
                    propertyIds: property?.data?.length > 0 ? property?.data?.map((val) => val.value) : null,
                    company_id: companyId,
                }
            }
            NetworkCall(
                `${config.api_url}/hierarchy-team/add_property`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                getPropertyList();
                dialogClose()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${property?.id ? t("Property  Successfully Updated") : t("Property  Successfully Created")}`,
                });

            }).catch((err) => {
                dialogClose()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                });
            })
        }

    }
    //table list
    const getPropertyList = (offset = 0, limit = 10, searchText = "", loading = true) => {
        let purpose = filterData?.purpose ?? "";
        let status = filterData?.is_active?.length > 0 ? filterData?.is_active : [true]
        setLoading(loading)
        const payload = {
            company_id: companyId,
            search: searchText,
            offset: offset,
            limit: limit,
            teams_id: teamId,
            filter: purpose,
            status: status
        }
        NetworkCall(
            `${config.api_url}/hierarchy-team/get_property_list`,
            NetWorkCallMethods.post,
            payload, null, true, false)
            .then((res) => {
                //table row constructions
                const List = res?.data?.data?.property_list?.map((val, index) => {
                    let _d;
                    try {
                        _d = {
                            index: ((page - 1) * limit) + index + 1,
                            propertyName: val?.property_name ?? " - ",
                            propertyNo: val?.property_no ?? " - ",
                            propertyPurpose: val?.property_purpose ?? "-",
                            propertyGroup: val?.property_group ?? " - ",
                            id: val?.id,
                            propertyHierarchy: val?.property_hierarchy ?? "-",
                            location: val?.location ?? "-",
                            status: val?.is_active ? "Active" : "Inactive",
                            ...val

                        };
                    } catch (err) {

                    }
                    return _d;
                });
                setTableData({
                    data: List,
                    count: res?.data?.data?.count
                })
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
    }
    //initial load
    React.useEffect(() => {
        getEnum()
        getPropertyList(0, limit, "", true)
        // eslint-disable-next-line
    }, [filterData, teamId])

    //propertydialog
    const PropertyDialog = (props) => {
        return (
            <>

                {/* add member button */}
                <Grid container spacing={2}>
                    <Grid item xs={12} height={"400px"}>
                        <CustomSelect
                            label={t("Property")}
                            isPaginate
                            loadOptions={(search, array, handleLoading) =>
                                loadOptionsApis(
                                    "hierarchy-team/get_filter_property",
                                    {
                                        company_id: companyId,
                                        teams_id: teamId

                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "filter_props"
                                )

                            }
                            debounceTimeout={800}
                            value={property?.data}
                            onChange={(value) => {
                                updateState("data", value);
                            }}
                            key={props?.companyId}
                            isMulti={true}
                            error={property?.error?.data?.length > 0}
                            errorText={property?.error?.data}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        {
                            <Button variant='outlined'
                                fullWidth
                                className={classes.btn3}
                                onClick={() => setOpen(false)}
                            >
                                {t("Cancel")}
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            <Button
                                variant='contained'
                                fullWidth
                                className={classes.btn2}
                                onClick={createProperty}
                                disabled={isDisableBtn}
                            >
                                {t("Add Property")}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Box>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item xs={4}>
                        {/*search field*/}
                        <SearchFilter
                            value={searchText}
                            placeholder={t("Search for property")}
                            handleChange={(value) => handleSearch(value)}
                            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                        />
                    </Grid>

                    <Grid item xs={8} display={'flex'} justifyContent={'end'}>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}
                            justifyContent='right'
                        >

                            <IconButton
                                onClick={drawerOpen}
                                className={classes.filterimg}>
                                <Badge variant="dot" color="primary"
                                    invisible={!(filterData?.is_active?.length > 0 || filterData?.purpose?.length > 0)}>
                                    <FilterIMG color="#091b29" />
                                </Badge>
                            </IconButton>
                            {props?.permission?.create &&
                                <Button onClick={dialogOpen} variant="contained" className={classes.btn} disabled={isDisableBtn}>
                                    {t("Add Property")}
                                </Button>
                            }
                        </Stack>
                    </Grid>
                </Grid >
                {loading ? <LoadingSection /> :

                    <TableWithPagination
                        heading={propertyHeading(t)}
                        rows={tableData?.data ?? []}
                        path={propertyPath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        tableType="no-side"
                        count="2"
                        handleIcon={handleIcon}
                        dataType={[
                            { type: ["more_delete"], name: "icon" },
                            { type: ["text"], name: "propertyNo" },
                            { type: ["text"], name: "propertyName" },
                            { type: ["text"], name: "location" },
                            { type: ["text"], name: "propertyPurpose" },
                            { type: ["text"], name: "propertyGroup" },
                            { type: ["text"], name: "propertyHierarchy" },
                        ]}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={tableData?.count}
                        page={page}
                        limit={limit}
                        height={`calc(100vh - 382px)`}
                        view={props?.permission?.read}
                        edit={false}
                        delete={true}
                    />
                }
            </Box >
            <AlertDialog
                open={open}
                isNormal
                header={t("Add Property")}
                onClose={() => setOpen(false)}
                component={
                    <Box p={2}>
                        <PropertyDialog />
                    </Box>
                }
            />
            <FilterGenerator
                open={fiterDrawer}
                onClose={drawerClose}
                onApply={(value) => onApplyFilter(value)}
                defaultState={defaultFilterState}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.purpose,
                        options: enumValue?.purpose,
                        isMulti: true,
                        state_name: "purpose",
                        label: t("Purpose"),
                    },
                    {
                        component: "toggleButton",
                        value: filterData?.is_active,
                        state_name: "is_active",
                        label: t("Status"),
                        options: StatusOptionList,
                        isMulti: true
                    },

                ]} />
        </>
    );
};
