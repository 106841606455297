import React from "react";
import { LeadStylesParent } from "../../leadnew/style";
import { Box, Button, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import {
  AlertProps,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../../utils";
import { AlertContext } from "../../../contexts";
import { TimeLine } from "../../leadnew/components";
import { Bold } from "../../../utils";
import NoDataFound from "../../../assets/noData";
// custom styles
// start
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize:"0.75rem",
  fontFamily: Bold,
  color: theme.typography.color.secondary,
  marginBottom: theme.spacing(1.5),
}));
export const Command = (props) => {
  const { t , Status=true } = (props)
  // styles
  const classes = LeadStylesParent();
  // size
  const size = useWindowDimensions();
  // alert
  const alert = React.useContext(AlertContext);
  // states
  const [command, setCommand] = React.useState("");
  const [disable , setDisable] = React.useState(false)
  // get user profile name by userprofile id
  const getuserProfile = (e) => {
    e.preventDefault();
    if (command?.length > 0) {
      addCommand()
    }
  };
  // add commands
  const addCommand = () => {
    let currentData = {};
    setDisable(true)
    // existing array
    let existsData = JSON.parse(JSON.stringify(props?.viewDetails?.comments));
    if (existsData?.length > 0) {
      // new obj
      let currentData = {
        comments: command,
        created_at: new Date().toISOString(),
      };
      // add comment
      existsData.push(currentData);
    } else {
      // new obj
      currentData = {
        comments: command,
        created_at: new Date().toISOString(),
      };
    }

    const payload = {
        id: props?.viewDetails?.id,
        comments:props?.viewDetails?.comments === null ? [currentData] : existsData,
    };

    NetworkCall(
      `${config.api_url}/lead_proxy/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Comment created successfully"),
        });
        setCommand("");
        props?.viewLeadDeatils();
      })
      .catch((error) => {
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  return (
    <div>
      <div
        style={{
          height: size?.height - 350,
          textAlign: "-webkit-auto",
          overflow: "auto",
        }}
      >
        {
          props?.viewDetails?.comments?.length > 0 ?
            <>
              <CustomTypography>{t("ACTIVITY COMMENTS")}</CustomTypography>
              <TimeLine datas={props?.viewDetails?.comments} /></> : <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={{ height: size?.height - 350 }}><NoDataFound /></Box>
        }

      </div>
      {/* command */}
      {props?.permission?.update && <div className={classes.activityBorder}>
        <div className={classes.commandbox}>
          <form onSubmit={getuserProfile}>
            <input
              className={classes.commandinput}
              placeholder={t("Type Comments here")}
              value={command}
              onChange={(e) => setCommand(e.target.value)}
            />
            <Button
              className={classes.commandbtn}
              variant="contained"
              disabled={disable ? true : (Status ? command?.length > 0 ? false : true : true)}
              type="submit"
            >
              {t("Comment")}
            </Button>
          </form>
        </div>
      </div>}
    </div>
  );
};
