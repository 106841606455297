import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 160px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    box2: {
        height: 'calc(100vh - 350px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    settingRoot: {
        height: 'calc(100vh - 230px)',
        overflow: "auto",
    },
    previewRoot: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "12px"
    },
    gatWayRoot: {
        backgroundColor: "#F5F7FA",
        borderBottom: "1px solid #E4E8EE"
    },
    previewImg: {
        height: "200px",
        objectFit: "cover",
        width: "100%"
    },
    imagepreviewRoot: {
        position: "relative"
    },
    logo: {
        height: "41px",
        width: "100px",
        position: "absolute",
        top: "4px",
        left: "10px",
        objectFit: "contain"
    },
    logo1: {
        height: "41px",
        width: "100px",
        position: "absolute",
        top: "8px",
        left: "4px",
        objectFit: "contain"
    },
    labellogo: {
        color: "#98A0AC",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        marginBottom: "5px"
    },
    root: {
        padding: "12px",
        borderRadius: "4px",
        boxShadow: "0px 1px 16px #00000014",
        border: "1px solid #E4E8EE"
    },
    title: {
        fontSize: "0.875rem",
        color: "#091B29",
        fontFamily: SemiBold,
    },
    dynamicTitle: {
        fontSize: "0.875rem",
        color: "#091B29",
        fontFamily: Bold,
    }
}))