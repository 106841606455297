import React from "react"
import { withNamespaces } from "react-i18next"
import { useStyles } from "./style"
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { Badge, Box, Button, Drawer, Grid, IconButton, Stack } from "@mui/material"
import { AlertProps, NetWorkCallMethods, accessCheckRender, activeOptions, getCompanyOption, getRoutePermissionNew, resourceGroupMasterDataType, resourceGroupMasterHeading, resourceGroupMasterPath } from "../../utils"
import CreateForm from "./createForm"
import ViewForm from "./viewForm"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import moment from "moment"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"


const ResourceGroupMaster = ({ t = () => false }) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [permission, setPermission] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [isView, setIsView] = React.useState({ bool: false, data: {} })
    const [tableData, setTableData] = React.useState({
        list: [],
        count: 0
    })
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [isCreate, setIsCreate] = React.useState({ bool: false, data: {} })
    const [fiterDrawer, setIsFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        status: [true]
    })

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        let company = getCompanyOption(backdrop, auth, alert)
        if (perm) {
            setPermission(perm)
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getresourceGroupList(company?.selected?.value, 0, 10, "", filterData)
        }
        //eslint-disable-next-line
    }, [auth])

    const getresourceGroupList = (companyId, offset = 0, limit = 10, searchKey = "", filterDetails = {}) => {
        const requestPayload = {
            offset: offset ?? 0,
            limit: limit ?? 10,
            company_id: companyId,
            search: searchKey,
            status: filterDetails?.status?.length > 0 ? filterDetails?.status?.map((x) => x) : undefined,
        };
        NetworkCall(
            `${config.api_url}/resource_group_master/list`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                const result = response?.data?.data?.data?.map((x) => {
                    return {
                        ...x,
                        created_at: moment(x?.created_at).format("DD-MMM-YYYY"),
                        updated_at: moment(x?.updated_at).format("DD-MMM-YYYY")
                    }
                })
                setTableData({ list: result, count: response?.data?.data?.count })
            }).catch((err) => {
                setLoading(false)
            })
    }
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getresourceGroupList(value?.value, 0, 10, "", filterData)
    }
    const handleSearch = (value) => {
        setSearchText(value)
        getresourceGroupList(selectedCompany?.value, 0, 10, value, filterData)
    }
    const handleIcon = (type, details) => {
        if (type === "view_more") {
            setIsView({ bool: true, data: details })
        }
        else if (type === "edit") {
            setIsCreate({ bool: true, data: details, is_edit: true })
        }
        else if (type === "active") {
            handleSubmit(details)
        }
    }
    const handleSubmit = (details) => {
        const payload = {
            "id": details?.id ?? undefined,
            "company_id": selectedCompany?.value,
            "is_active": details?.is_active ? false : true,
        }
        NetworkCall(
            `${config.api_url}/resource_group_master/upsert`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: `Resource Group Master ${details?.is_active ? "In-activated" : "Activated"} Successfully`,
            })
            reload()
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some thing went Wrong")
            })
        });
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getresourceGroupList(selectedCompany?.value, offset, 10, "", filterData)

    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getresourceGroupList(selectedCompany?.value, 0, value, "", filterData)
    }
    const openDrawer = () => {
        setIsCreate({ bool: true, data: {} })
    }
    const closeDrawer = () => {
        setIsCreate({ bool: false, data: {} })
    }
    const handleEditInView = (val) => {
        setIsView({ bool: false, data: {} })
        setIsCreate({ bool: true, data: val, is_edit: true })
    }
    const handleFilter = (value) => {
        setFilterData(value)
        getresourceGroupList(selectedCompany?.value, 0, 10, "", value)
    }
    const reload = () => {
        getresourceGroupList(selectedCompany?.value, 0, 10, "", filterData)
    }
    const render = () => {
        return (
            <Box>
                <Subheader title={t("Resource Group Master")} hideBackButton={true} select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }} />
                {loading ?
                    <LoadingSection top="20vh" message={"Fetching Details"} />
                    :
                    <Box className={classes.root}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                <Box>
                                    {/*search */}
                                    <SearchFilter label={false} placeholder={t("Search")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                </Box>
                            </Grid>

                            <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                                <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        display={"flex"}
                                        alignItems="center"
                                    >
                                        <Box>
                                            <IconButton
                                                size="small"
                                                className={classes.img}
                                                onClick={() => setIsFilterDrawer(true)}
                                            >
                                                <Badge variant="dot" color="primary"
                                                    invisible={!(filterData.status?.length > 0)}>
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                        {permission?.create &&
                                            <Button variant={"contained"} onClick={() => openDrawer()}>{t("Create")}</Button>
                                        }
                                    </Stack>
                                    <Box width={"15px"} />
                                </Box>
                            </Grid>
                        </Grid>

                        <TableWithPagination
                            heading={resourceGroupMasterHeading(t)}
                            rows={tableData?.list}
                            dataType={resourceGroupMasterDataType}
                            path={resourceGroupMasterPath}
                            showpagination
                            tableType="no-side"
                            handleIcon={handleIcon}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={tableData?.count}
                            page={page}
                            limit={limit}
                            view={permission?.read && true}
                            height={`calc(100vh - 300px)`}
                            edit={permission?.update}
                            delete={true} />
                    </Box>
                }
                <FilterGenerator open={fiterDrawer} onClose={() => setIsFilterDrawer(false)}
                    onApply={(value) => handleFilter(value)}
                    is_customFilter={true}
                    clearFilter={()=>setFilterData({status: [true]})}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: activeOptions,
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                    ]} />
                <Drawer
                    anchor={"right"}
                    open={isCreate?.bool}
                    onClose={() => closeDrawer()}
                >
                    <CreateForm onClose={() => closeDrawer()} selectedCompany={selectedCompany} state={isCreate?.data} reload={reload} is_edit={isCreate?.is_edit ?? false} />
                </Drawer>
                <Drawer
                    anchor={"right"}
                    open={isView?.bool}
                    onClose={() => setIsView({ bool: false, data: {} })}
                >
                    <ViewForm onClose={() => setIsView({ bool: false, data: {} })} selectedCompany={selectedCompany} handleEditInView={handleEditInView} data={isView?.data} permission={permission} />
                </Drawer>
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withNamespaces("resourceMaster")(ResourceGroupMaster)