import React from "react";
import { withNavBars } from "../../HOCs";
import ScheduleWorkOrderView from "./scheduleWorkOrderView";
class ScheduleWorkOrderViewParent extends React.Component {
    render() {
        return <ScheduleWorkOrderView {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ScheduleWorkOrderViewParent, props);