import { Box, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { InvoiceDetailStyle } from './style'
import moment from 'moment'
import { AmenitiesBookingContext } from '../amenitiesBookingContext'
import { DeleteIcon } from '../../../assets'
import { NetworkCall } from '../../../networkcall'
import { config } from '../../../config'
import { NetWorkCallMethods } from '../../../utils'
import { HideShowTextBox } from './hideShowTextBox'

const InvoiceDetail = ({
    slots = [],
    amenityData = {},
    t = () => false,
    amounts = {},
    customHeight = false,
    member = {},
    addInvoiceLine = () => false,
    removeInvoice = () => false,
    booked = false
}) => {
    const classes = InvoiceDetailStyle({ customHeight, booked })
    const { selectedCompany, setInvoiceRow, amountCalculation } = React.useContext(AmenitiesBookingContext)

    const [isHover, setIsHover] = React.useState(false);
    const [index, setIndex] = React.useState("")
    const [loading, setLoading] = React.useState("")

    const handleMouseEnter = (index) => {
        setIsHover(true);
        setIndex(index)
    };
    const handleMouseLeave = () => {
        setIsHover(true);
        setIndex("")
    };

    // Function to load Async Select Options
    const loadOptions = async (search = "", array, type) => {
        setLoading(type)
        let result, endpoint, offset, limit, variables = 0
        limit = 10
        if (search && !Boolean(array?.length)) { offset = 0 }
        else { offset = array?.length }
        switch (type) {
            case "pricing_component":
                endpoint = "/free_text_invoice/get_free_text_item_types"
                variables = {
                    offset, limit, search,
                    company_id: selectedCompany?.value,
                    // conditions: {
                    //     is_quantity: true,
                    //     refundable: false
                    // },
                    item_type: "Item Master",

                    selected_components: slots?.filter((i) => i?.value?.extraInvoice === true)?.map((e) => e?.value?.label && e?.value?.label?.value)?.filter((d) => d !== '') ?? []
                }
                result = await networkCallBack(endpoint, variables)
                let temp_result = result?.items?.map((i) => {
                    return {
                        label: i?.name,
                        value: i?.id
                    }
                })
                return {
                    options: [...temp_result],
                    hasMore: (array?.length + temp_result?.length) < result?.count
                }
            default:
                return { options: [] }
        }
    }

    // Function for network callback for Async Select Options
    const networkCallBack = async (endpoint, variables = {}) => {
        const options = await NetworkCall(
            `${config.api_url}${endpoint}`,
            NetWorkCallMethods.post,
            variables, null, true, false
        ).then((r) => {

            let main = r?.data?.data ?? r?.data
            setLoading(null)
            return main
        }).catch(() => {
            setLoading(null)
            return null
        })
        return options
    }

    return (
        <Box className={classes.box}>
            <TableContainer className={classes.tableContainer}>
                <Table sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                    },

                    overflow: "auto"
                }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: "left" }} className={classes.tableHeadText}>{t("Title")}</TableCell>
                            {!booked && <TableCell sx={{ textAlign: "left" }} className={classes.tableHeadText}>{t("Price")}</TableCell>}
                            {!booked && <TableCell sx={{ textAlign: "left" }} className={classes.tableHeadText}>{t("QTY(Quantity)")}</TableCell>}
                            <TableCell sx={{ textAlign: "right" }} className={classes.tableHeadText}>{t("Total")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            slots?.map((e) => {
                                return (
                                    <>
                                        <TableRow sx={{ alignItems: "center" }}>
                                            <TableCell sx={{ textAlign: "left", padding: "8px 16px" }}>
                                                <HideShowTextBox
                                                    value={e?.value?.label ?? null}
                                                    labelText={
                                                        (e?.value?.extraInvoice && !booked) ? `${e?.value?.label?.label}` :
                                                            (e?.value?.extraInvoice && booked) ? `${e?.value?.label?.label}` :
                                                                `${e?.value?.check_in_time ?? moment(e?.value?.check_in_date).format("DD-MMM-YY")}
                                                                ${e?.value?.check_out_time ? ("- " + e?.value?.check_out_time ?? "") : ""}`
                                                    }
                                                    e={e}
                                                    setInvoiceRow={setInvoiceRow}
                                                    amountCalculation={amountCalculation}
                                                    booked={booked}
                                                    amenityData={amenityData}
                                                    member={member}
                                                    keyvalue="label"
                                                    type="selectbox"
                                                    loadOptions={loadOptions}
                                                    loading={loading}
                                                    selectedCompany={selectedCompany}
                                                    slots={slots} />
                                            </TableCell>
                                            {!booked &&
                                                <TableCell sx={{ textAlign: "left", padding: "8px 16px" }}>
                                                    {/* {
                                                        (e?.value?.extraInvoice && !booked) ?
                                                            <Box sx={{ width: 70 }}>
                                                                <TextBox value={e?.value?.info?.rate}
                                                                    onChange={(value) => setInvoiceRow(e, "rate", value.target.value)}
                                                                    label=""
                                                                    placeholder={""}
                                                                    height="36px"
                                                                    padding={"6px 4px 4px"}
                                                                    onBlur={() => amountCalculation(true)}
                                                                    type={"number"} />
                                                            </Box>
                                                            :
                                                            <Typography className={classes.tableBodyText}>{e?.value?.info?.rate}</Typography>
                                                    } */}
                                                    <HideShowTextBox
                                                        value={e?.value?.info?.rate}
                                                        labelText={e?.value?.info?.rate}
                                                        e={e}
                                                        setInvoiceRow={setInvoiceRow}
                                                        amountCalculation={amountCalculation}
                                                        booked={booked}
                                                        amenityData={amenityData}
                                                        member={member}
                                                        keyvalue="rate"
                                                        type="textbox" />
                                                </TableCell>
                                            }
                                            {
                                                !booked &&
                                                <TableCell sx={{ textAlign: "left", padding: "8px 16px" }}>
                                                    {/* {(e?.value?.extraInvoice && !booked) ?
                                                        <Box sx={{ width: 70 }}>
                                                            <TextBox
                                                                value={e?.value?.qty}
                                                                label={null} placeholder={""}
                                                                height="36px"
                                                                padding={"6px 4px 4px"}
                                                                onChange={(value) => setInvoiceRow(e, "qty", value.target.value)}
                                                                onBlur={() => amountCalculation(true)}
                                                                type={"number"} />
                                                        </Box>
                                                        : <Typography className={classes.tableBodyText}>
                                                            {(e?.value?.extraInvoice && booked) ? e?.value?.qty : amenityData?.is_overbooking ? member?.count : 1}
                                                        </Typography> */}
                                                    <HideShowTextBox
                                                        value={e?.value?.qty}
                                                        labelText={amenityData?.is_overbooking ? member?.count : booked ? e?.value?.qty : e?.value?.extraInvoice ? e?.value?.qty : 1}
                                                        e={e}
                                                        setInvoiceRow={setInvoiceRow}
                                                        amountCalculation={amountCalculation}
                                                        booked={booked}
                                                        amenityData={amenityData}
                                                        member={member}
                                                        keyvalue="qty"
                                                        type="textbox" />
                                                    {/* } */}
                                                </TableCell>
                                            }
                                            <TableCell sx={{ float: "right", padding: "8px 16px" }}>
                                                <Stack direction={"row"} spacing={0.5} alignItems={"center"} sx={{ width: 100 }} justifyContent={"end"}>
                                                    <Typography className={e?.value?.extraInvoice ? classes.tableTotalAmount : classes.tableBodyText} noWrap
                                                        onMouseEnter={() => !booked && handleMouseEnter(e?.index)}
                                                        onMouseLeave={() => !booked && handleMouseLeave()}>
                                                        {amenityData?.symbol} {
                                                            e?.value?.extraInvoice ? Number(Number(e?.value?.info?.rate) * Number(e?.value?.qty)).toFixed(2) :
                                                                amenityData?.is_overbooking ? Number(Number(e?.value?.info?.rate) * Number(member?.count)).toFixed(2) :
                                                                    amenityData?.period === "Hourly" ? Number(e?.value?.info?.rate).toFixed(2) : Number(amenityData?.rate).toFixed(2)}
                                                    </Typography>
                                                    {
                                                        (e?.value?.extraInvoice && isHover && index === e?.index && !booked) &&
                                                        <Box onMouseEnter={() => handleMouseEnter(e?.index)}
                                                            onMouseLeave={() => handleMouseLeave()} onClick={() => removeInvoice(e)}
                                                            sx={{ marginTop: "4px !important", cursor: "pointer" }}>
                                                            <DeleteIcon />
                                                        </Box>
                                                    }
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })
                        }
                    </TableBody>
                    {!booked && <Typography ml={2} className={classes.addText} onClick={() => {
                        addInvoiceLine(true)
                    }}>{t("Add+")}</Typography>}
                </Table>
            </TableContainer>
            <Stack sx={{ width: "100%" }}>
                {/* <Stack spacing={1.5} className={classes.invoiceBox} p={2}>

                    {
                        slots?.map((e) => {
                            return (
                                <Stack spacing={1}>
                                    <Stack direction={"row"} justifyContent={"space-between"}>

                                        <Typography className={classes.title}>
                                            {e?.value?.check_in_time ??
                                        </Typography>

                                        <Typography className={classes.content}>
                                            {amenityData?.symbol} {
                                        </Typography>
                                    </Stack>
                                    <Divider></Divider>
                                </Stack>
                            )
                        })
                    }
                </Stack> */}
                <Divider></Divider>
                <Stack spacing={1} py={1}>
                    <Stack direction={"row"} justifyContent={"space-between"} px={2}>
                        <Typography className={classes.total}>{t("VAT Amount")}</Typography>
                        <Typography className={classes.total}>{amenityData?.symbol} {amounts?.taxAmount} </Typography>
                    </Stack>
                    <Box px={2}><Divider></Divider></Box>
                    <Stack direction={"row"} justifyContent={"space-between"} px={2}>
                        <Typography className={classes.total}>{t("Total")}</Typography>
                        <Typography className={classes.total}>{amenityData?.symbol} {amounts?.totalAmount} </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box >
    )
}

export default withNamespaces("amenityBooking")(InvoiceDetail)