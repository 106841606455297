import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import { Italic, Regular, SemiBold } from '../../utils';
import { TextBox } from '../textbox';
export const btnStyles = makeStyles((theme) => ({
    input: {
        width: "83px",
        border: "none",
        outline: "none",
        fontSize:"0.875rem",

        // color: theme.typography.color.tertiary,
        fontFamily: Regular,
    },
    custom: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,

    },
    check: {
        color: "#5078E1",
        marginTop: "8px",
        marginRight: "4px",
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        fontSize: "1.8rem",

    },
    chip: {
        backgroundColor: "#5078E1",
        boxShadow: 'none',
        fontSize:"0.75rem",
        color: "white",
        borderRadius: "4px",
        fontFamily: SemiBold,
        height: "45px"
    },
    selectCycle: {
        border: "1px solid #E4E8EE",
        padding: "10px",
        borderRadius: theme.palette.borderRadius
    },
    text: {
        fontsize:"0.75rem",
        fontFamily: Italic,
        color: theme.typography.color.tertiary
    },
    selectTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular,
    },
    icon: {
        marginLeft: "15px"
    }
}))
export const CustomBtn = ({
    handleDelete = () => false,
    onChange = () => false,
    value = "",
    setShow = () => false,
    height = false,
    noOfdays = "",
    customValue = null,
    selectBillingCycle = false,
    date = "",
    onDateChange = () => false,
    onCustomChange = () => false,
    disabled = false
}) => {
    const classes = btnStyles()
    const [show1, setShow1] = React.useState((value && value !== "Custom") ? true : false)
    const [day, setDay] = React.useState(noOfdays)

    // const [date, setDate] = React.useState(new Date())

    const deleteFunction = () => {
        if (!disabled) {
            handleDelete()
            setShow1(false)
            setShow(true)
        }
    }

    return (
        show1 ?
            <Chip deleteIcon={<CloseRoundedIcon style={{ color: "white", fontSize:"1.125rem", margin: "0px 4px 0px 4px" }} />} label={`${value} Days`} className={classes.chip}

                onDelete={() => {
                    deleteFunction()
                }} /> :
            selectBillingCycle === true ?
                <Box sx={{ width: "100px" }}>
                    <TextBox
                        value={date ?? ""}
                        label=""
                        onChange={(e) => onDateChange(e.target.value)}
                        isReadonly={disabled}

                    />
                </Box>
                :
                <Box display='flex' alignItems="center" className={classes.custom}>
                    <Box marginLeft="8px" width="80px">
                        <input type="number"
                            value={day ?? ""} 
                            onChange={(e) => setDay(e.target.value)} 
                            placeholder="Days" className={classes.input} 
                            disabled={disabled} 
                            onBlur={()=>{
                                if (day > 0) {
                                    setShow1(true)
                                    onCustomChange(day)
                                }
                            }}
                            />
                    </Box>
                    <Box marginLeft="4px"
                        onClick={() => {

                            if (day > 0) {

                                setShow1(true)
                                onCustomChange(day)
                            }

                        }}>
                        <CheckBoxRoundedIcon className={classes.check} />
                    </Box>
                </Box >
    )
}