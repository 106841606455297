import { Avatar, Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold, SemiBold, stringAvatar } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rootcard: {
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: (props) => props?.padding ?? "4px",
    marginTop: (props) => props?.marginTop ?? "auto",
    position:'relative'
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize:"0.875rem",
    fontFamily: Bold,
  },
  call: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
    direction: "ltr"
  },
  mail: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
  },
  img: {
    objectFit: "cover",
    height: "40px",
    width: "40px",
    borderRadius: "4px"
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px 3px 10px",
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
    marginLeft: "8px"
  },
  editTitle:{
    color:"#5078E1",
    fontSize:"0.75rem",
    fontFamily:SemiBold,
    position:"absolute",
    top:'16px',
    right:"16px"
  },

}));

export const ContactList = (props) => {
  const classes = useStyles(props);

  return (

    <Box display="flex" className={classes.rootcard} onClick={props?.onClick}>
      <Box display={'flex'} alignItems={'center'}>
        <Avatar
          src={props.data.img}
          className={classes.img}
          {...stringAvatar(props.data.name)}
        />

      </Box>
      <Box marginTop="6px" marginInlineStart="8px">
        <Typography className={classes.name}>
          {props.data.name}
          {
            props.data.arg && <span className={classes.tagStyle} >{props.data.arg}</span>
          }

        </Typography>
        <Box height="4px" />
        <Stack direction={props.is_flex ? "column" : "row"} alignItems={"baseline"}>
          <Box>
            <Typography className={classes.call}>
              {props.data.phone}
            </Typography>
          </Box>
          {((props.data.phone && props.data.mail) && !props.is_flex) && <Box className={classes.periodStyle} />}


          <Box>
            <Typography className={classes.mail}>
              {props.data.mail}
            </Typography>
          </Box>
        </Stack>
      </Box>
      {
        props?.is_edit && 
        <Typography className={classes.editTitle} onClick={props?.onClick}>{props?.edit_title}</Typography>
      }
    </Box>
  );
};
