export const userPath = [
  "img", "userNo", "userName", "emailId", "phone", "rights", "department"
]


export const userHeading = (t) => [
  { title: " ", field: 'icon' },
  { title: t("Image"), field: 'img' },
  { title: t("User UID"), field: "userNo", },
  { title: t("User Name"), field: "userName", },
  { title: t("Department"), field: "department", },
  { title: t("Email ID"), field: "emailId", },
  { title: t("Phone"), field: 'phone', },
  { title: t("Rights"), field: "rights", },
  { title: " ", field: "is_team_lead", },

]

export const propertyPath = [
  "propertyNo", "propertyName", "location", "propertyGroup", "propertyPurpose", "propertyHierachy"
]


export const propertyHeading = (t) => [
  { title: " ", field: "icon", },
  { title: t("Property No"), field: "propertyNo", },
  { title: t("Property Name"), field: "propertyName", },
  { title: t("Location"), field: "location", },
  { title: t("Property Purpose"), field: "propertyPurpose", },
  { title: t("Type"), field: 'propertyGroup', },
  { title: t("Hierachy"), field: "propertyHierachy", },

]
