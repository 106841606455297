import React from 'react';
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { LeadStylesParent } from '../style';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { convertTimeUtcToZoneCalander } from "../../../utils";
import { PersonImage } from '../../../assets/personImage';

export const TimeLine = (props) => {
      // styles
      const classes = LeadStylesParent();
      return (
            <div>
                  <div>
                        {
                              props?.datas?.map((v, i) => {
                                    return (
                                          <Timeline style={{ padding: 0, color: "#fff", margin: 0 }} className={classes.menuItem}>
                                                <TimelineItem >
                                                      <TimelineSeparator>
                                                            <TimelineDot style={{ backgroundColor: '#fff', boxShadow: 'none', padding: 0, margin: 0 }}>
                                                                  <div className={props?.isCustomStyles ? classes.noBorder : classes.timedotborder}>
                                                                        {/* <Box className={classes.timedot} /> */}
                                                                        {props?.type === "loading"
                                                                              ? <Skeleton animation="wave" variant='circular' height={40} width={40} />
                                                                              : props?.isCustomStyles ?
                                                                                    <Box className={props?.isCustomStyles ? classes.userImage : classes.imageStyle}>
                                                                                          {(v?.image_url!==null&&v?.image_url!=="")?<Avatar src={v?.image_url} alt="user_img" className={classes.userImage}/>:<PersonImage />}
                                                                                    </Box>
                                                                                    : <Avatar src={v?.image_url ?? ""} className={props?.isCustomStyles ? classes.userImage : classes.imageStyle} />}
                                                                  </div>
                                                            </TimelineDot>
                                                            {props?.datas?.length - 1 !== i && (
                                                                  <TimelineConnector
                                                                        variant="TimelineDot"
                                                                        className={props?.isCustomStyles ? classes.dotted_line : classes.dot_line}
                                                                  />
                                                            )}
                                                      </TimelineSeparator>
                                                      <TimelineContent className={classes.timeLineMain}>
                                                            <div className={props?.isCustomStyles ? classes.commentsDiv : classes.profileroot2} style={{ padding: '12px' }}>
                                                                  {props?.type === "loading"
                                                                        ? <>
                                                                              <Skeleton animation="wave" variant='text' height={21} width={"50%"} />
                                                                              <Skeleton animation="wave" variant='text' height={18} width={"20%"} />
                                                                        </>
                                                                        : <>
                                                                              <Typography className={classes.message}>{v?.comments ?? ""}</Typography>
                                                                              <div style={{ display: 'flex', alignItems: 'center', marginTop: '1px' }}>
                                                                                    <Typography className={classes.timelinecreated}>{v?.created_by ?? ""} ,</Typography>
                                                                                    &nbsp;<Typography className={classes.timelinecreatedat}>{v?.created_at ? convertTimeUtcToZoneCalander(v?.created_at, 'yyyy-MM-DD hh:mm A') : '-'}</Typography>
                                                                                    {v?.reference_no?.length > 0 && <Typography className={classes.timelinecreated}>{`, ${v?.reference_no}` ?? ""}</Typography>}
                                                                              </div>
                                                                        </>}
                                                            </div>
                                                      </TimelineContent>
                                                </TimelineItem>
                                          </Timeline>

                                    );
                              })}
                  </div>
            </div>
      )
}
