import React from 'react'
import { Stack, Typography, Box, Divider, Grid, Avatar, Tooltip } from "@mui/material"
import { Step } from './utils'
import { ProjectStyle } from './style'
import { ProjectContext } from './projectContext'
import { TypeSelect } from './components/typeSelect'
import { DatePickerNew, Label, SelectBox, TextBox, ToggleButtonComponent } from '../../components'
import { NewFormStep } from '../../components/newFormSteps'
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions'
import ChooseProperty from '../contract/utils/chooseProperty'
import ShowMoreText from "react-show-more-text";
import PlannerCalIcon from './utils/plannerCalIcon'
import ProjectLayoutIcon from './utils/projectLayoutIcon'
import ContractLayoutIcon from './utils/contractLayoutIcon'
import PlannedHrIcon from './utils/plannedHrIcon'
import ProjectOwnerIcon from './utils/projectOwnerLayout'
import { AlertProps, SemiBold, stringAvatar } from '../../utils'
import moment from 'moment'
import TickIcon from './utils/tickIcon'
import { AlertContext } from '../../contexts'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const CreateForm = ({
    t = () => false
}) => {
    const classes = ProjectStyle()
    const {
        createData,
        setCreateData,
        updateCreateData,
        selectedCompany,
        enumValue
    } = React.useContext(ProjectContext)
    const alert = React.useContext(AlertContext)
    const viewData = [
        {
            icon: <PlannerCalIcon />,
            label: t("Planned Start To End Date"),
            value: `${moment(createData?.plannedStartDate).format("DD MMM YY")} - ${moment(createData?.plannedEndDate).format("DD MMM YY")}`,
            isActive: true
        },
        {
            icon: <ProjectLayoutIcon />,
            label: t("Parent Project"),
            value: createData?.type?.project?.label,
            isActive: createData?.type?.project?.label?.length > 0 ? true : false
        },
        {
            icon: <ContractLayoutIcon />,
            label: t("Contract"),
            value: createData?.type?.contract?.label,
            isActive: createData?.type?.contract?.label?.length > 0 ? true : false
        },
        {
            icon: <ProjectLayoutIcon />,
            label: t("Project Category"),
            value: createData?.category?.label,
            isActive: true
        },
        {
            icon: <ProjectLayoutIcon />,
            label: t("Project Sub-category"),
            value: createData?.subCategory?.label,
            isActive: true
        },
        {
            icon: <PlannedHrIcon />,
            label: t("Planned Hours"),
            value: `${createData?.plannedHour} Hrs`,
            isActive: true
        }
    ]

    const viewData1 = [
        {
            icon: <ProjectOwnerIcon />,
            id: 1,
            label: t("Project Owner")
        },
        {
            icon: <PlannedHrIcon />,
            id: 2,
            label: t("Resource Group")
        }
    ]

    const manualResponceCategory = (array) => {
        const result = array?.data?.map((e) => {
            return {
                ...e,
                label: e?.name,
                value: e?.id
            }
        })

        return result
    }

    const manualProjectResponse = (array) => {
        const result = array?.map((e) => {
            return {
                ...e,
                label: e?.first_name,
                value: e?.contact_id,
                url: e?.image_url ?? "",
                subTitle1: e?.relationship ?? "-",
                subTitle2: e?.job_title ?? "-",
                id: e?.id,
                is_active: true
            }
        })
        return result
    }

    const manualResourceResponse = (array) => {
        const result = array?.map((e) => {
            return {
                ...e,
                label: e?.label,
                value: e?.value,
                id: e?.id,
                url: e?.image_url ?? "",
                is_active: true,
                subTitle1: `${e?.resource_count} Members`,
            }
        })
        return result
    }

    const handleStartDate = (date) => {
      if (
        date > createData?.plannedEndDate &&
        createData?.plannedEndDate !== ""
      ) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("You can not choose greater then end date"),
        });
      } else {
        setCreateData({
          ...createData,
          plannedStartDate: date,
        });
      }
    };

    const handleEndDate = (date) => {
      if (date >= createData?.plannedStartDate) {
        setCreateData({
          ...createData,
          plannedEndDate: date,
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("You can not choose less then start date"),
        });
      }
    };

    const handleAddResourceGroup = (value) => {
        if (createData?.resourceGroup?.map((e) => { return e?.value })?.includes(value?.value)) {
            setCreateData({
                ...createData,
                resourceGroup: createData?.resourceGroup?.filter((i) => i?.value !== value?.value),
                deletedResource: [...createData?.deletedResource, { ...value, is_active: false }]
            })
        } else {
            updateCreateData("resourceGroup", [...createData?.resourceGroup, value])
        }

    }

    return (
        <>
            <Stack direction={"row"} spacing={"6px"} alignItems={"center"} p={2}>
                {
                    Step(t)?.map((e, i, length) => {
                        return (
                            <>
                                <Stack direction={"row"} spacing={"6px"} alignItems={"center"}>
                                    {
                                        createData?.[`step${e?.step}`] ?
                                            <TickIcon /> :
                                            <Typography className={createData?.step === i + 1 ? classes.selectedStep : classes.unselectedStep}>{e?.step}</Typography>}
                                    <Typography className={createData?.step === i + 1 ? classes.optionHeader : classes.optionSubTitle}>{e?.title}</Typography>
                                </Stack >
                                {
                                    length?.length - 1 !== i &&
                                    <Box className={classes.stepDivider}></Box>
                                }
                            </>
                        )
                    })
                }
            </Stack>
            <Box><Divider /></Box>
            <Box className={classes.createFormBody}>
                {
                    createData?.step === 1 &&
                    <Box>
                        <Box p={2}>
                            <TypeSelect />
                        </Box>

                        <Grid container spacing={3} p={2}>
                            <Grid item xs={12}>
                                <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                    <Label label={t("Display Project")} isRequired />
                                    <Tooltip title={t("The project will be show in owner app based on your choice")} placement="top" sx={{fontFamily: SemiBold}}>
                                        <InfoOutlinedIcon sx={{fontSize: "14px", color: "#4E5A6B", marginTop: "-8px"}} />
                                    </Tooltip>
                                </Stack>
                                <ToggleButtonComponent
                                    options={enumValue?.display_type}
                                    value={createData?.display_type}
                                    onChange={(value) => updateCreateData("display_type", value)}
                                    label={""}
                                    gap={8}
                                // isRequired
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    value={createData?.projectTitle}
                                    placeholder={t("Enter Project Title")}
                                    label={t("Project Title")}
                                    isrequired
                                    onChange={(e) => updateCreateData("projectTitle", e.target.value)}
                                    isError={createData?.error?.projectTitle?.length > 0}
                                    errorMessage={createData?.error?.projectTitle}
                                    maxLength={150}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    value={createData?.projectDecs}
                                    placeholder={t("Enter Project Description")}
                                    label={t("Project Description")}
                                    isrequired
                                    onChange={(e) => updateCreateData("projectDecs", e.target.value)}
                                    multiline
                                    isError={createData?.error?.projectDecs?.length > 0}
                                    errorMessage={createData?.error?.projectDecs}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <SelectBox
                                    options={[]}
                                    label={t("Category")}
                                    placeholder={t("Select Category")}
                                    onChange={(value) => {
                                        setCreateData({
                                            ...createData,
                                            category: value,
                                            subCategory: ""
                                        })
                                        // updateCreateData("category", value)
                                    }}
                                    isRequired
                                    value={createData?.category}
                                    isPaginate={true}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "project_category/list",
                                            {
                                                company_id: selectedCompany?.value,
                                                status: [true]
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResponceCategory
                                        )
                                    }
                                    isError={createData?.error?.category?.length > 0}
                                    errorMessage={createData?.error?.category}
                                    menuPlacement={"top"}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <SelectBox
                                    options={[]}
                                    label={t("Sub Category")}
                                    placeholder={t("Select Sub Category")}
                                    onChange={(value) => updateCreateData("subCategory", value)}
                                    isRequired
                                    value={createData?.subCategory}
                                    isPaginate={true}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "project_subcategory/list",
                                            {
                                                company_id: selectedCompany?.value,
                                                status: [true],
                                                category_id: createData?.category?.value
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResponceCategory
                                        )
                                    }
                                    isError={createData?.error?.subCategory?.length > 0}
                                    errorMessage={createData?.error?.subCategory}
                                    key={JSON.stringify(createData?.category)}
                                    menuPlacement={"top"}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <DatePickerNew
                                    label={t("Planned Start Date")}
                                    placeholder="DD MM YY"
                                    value={createData?.plannedStartDate}
                                    handleChange={(value) => handleStartDate(value)}
                                    isrequired
                                    isError={createData?.error?.plannedStartDate?.length > 0}
                                    errorMessage={createData?.error?.plannedStartDate}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePickerNew
                                    label={t("Planned End Date")}
                                    placeholder="DD MM YY"
                                    value={createData?.plannedEndDate}
                                    handleChange={(value) => handleEndDate(value)}
                                    isrequired
                                    isError={createData?.error?.plannedEndDate?.length > 0}
                                    errorMessage={createData?.error?.plannedEndDate}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox
                                    value={createData?.plannedHour}
                                    placeholder={t("Enter Planned Hours")}
                                    label={t("Planned Hours")}
                                    isrequired
                                    onChange={(e) => updateCreateData("plannedHour", e.target.value)}
                                    endAdornment={<Typography className={classes.hrsLabel}>Hrs</Typography>}
                                    type={"number"}
                                    isError={createData?.error?.plannedHour?.length > 0}
                                    errorMessage={createData?.error?.plannedHour}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                }
                {
                    createData?.step === 2 &&
                    <Box p={2}>
                        <NewFormStep component={
                            [
                                {
                                    type: "chooseComponent",
                                    title: t("Project Owner"),
                                    placeholder: t("Search Owner"),
                                    noSelectedTitle: t("No owner selected"),
                                    icon: <ChooseProperty />,
                                    header: "Project Owner",
                                    showCard: false,
                                    options: [],
                                    topHeader: "Choose Project Owner",
                                    onchange: (value) => updateCreateData("projectOwner", [...createData?.projectOwner, value]),
                                    value: createData?.projectOwner,
                                    onDeleteMuti: (value) => {
                                        setCreateData({
                                            ...createData,
                                            projectOwner: createData?.projectOwner?.filter((i) => i?.value !== value?.value),
                                            deletedOwner: [...createData?.deletedOwner, { ...value, is_active: false }]
                                        })
                                    },
                                    isRequired: false,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "project/owner/get",
                                            {
                                                company_id: selectedCompany?.value,
                                                relationship: ["Company Employee", "External Vendor Employee"]
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualProjectResponse
                                        ),
                                    error: createData?.error?.projectOwner?.length > 0,
                                    errorMsg: createData?.error?.projectOwner,
                                    isActive: true,
                                    isBorder: false,
                                    isMulti: true,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    customSelectedBg: "#F1F7FF",
                                    dotSubTitle: true
                                },
                                {
                                    type: "chooseComponent",
                                    placeholder: t("Search Resource Group"),
                                    noSelectedTitle: t("No Resource group selected"),
                                    header: t("Choose Resource Group"),
                                    showCard: false,
                                    options: [],
                                    onchange: (value) => handleAddResourceGroup(value),
                                    value: createData?.resourceGroup,
                                    // onDelete: (value) => updateCreateData("resourceGroup", [...createData?.resourceGroup, { ...createData?.resourceGroup?.filter((i) => i?.value !== value?.value), is_active: false }]),
                                    onDeleteMuti: (value) => {
                                        setCreateData({
                                            ...createData,
                                            resourceGroup: createData?.resourceGroup?.filter((i) => i?.value !== value?.value),
                                            deletedResource: [...createData?.deletedResource, { ...value, is_active: false }]
                                        })
                                    },
                                    isRequired: false,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "project/resource_group/get",
                                            {
                                                company_id: selectedCompany?.value,
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResourceResponse
                                        ),
                                    error: createData?.error?.resourceGroup?.length > 0,
                                    errorMsg: createData?.error?.resourceGroup,
                                    isActive: true,
                                    isBorder: false,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    isMulti: true,
                                    customSelectedBg: "#F1F7FF"
                                }
                            ]
                        } />
                    </Box>
                }
                {
                    createData?.step === 3 &&
                    <Box p={2}>
                        <Typography className={classes.overViewTitle} mb={"12px"}>{createData?.projectTitle}</Typography>
                        <ShowMoreText
                            lines={2}
                            more={t("Show More")}
                            less={t("Show Less")}
                            className={classes.optionSubTitle}
                            anchorClass={classes.seeMoreLessTextStyle}
                            expanded={false}
                            truncatedEndingComponent={"... "}
                        >
                            <Typography className={classes.optionSubTitle}
                                dangerouslySetInnerHTML={{ __html: (createData?.projectDecs).replace(/\n+/g, "<br />") ?? "" }}>
                            </Typography>
                        </ShowMoreText>

                        <Stack spacing={"20px"} mt={2}>
                            {
                                viewData?.map((data) => {
                                    return (
                                        <>
                                            {
                                                data?.isActive &&
                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                        {data?.icon}
                                                        <Typography className={classes.viewLabel}>{data?.label}</Typography>
                                                    </Stack>
                                                    <Typography className={classes.viewValue} sx={{ textAlign: "end" }}>{data?.value}</Typography>
                                                </Stack>
                                            }
                                        </>
                                    )
                                })
                            }
                        </Stack>


                        <Box mt={2}><Divider></Divider></Box>

                        <Stack spacing={"20px"} mt={2}>
                            {
                                viewData1?.map((data) => {
                                    return (
                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                {data?.icon}
                                                <Typography className={classes.viewLabel}>{data?.label}</Typography>
                                            </Stack>
                                            <Grid container spacing={1} justifyContent={"flex-end"}>
                                                {
                                                    data?.id === 1 ?
                                                        <>
                                                            {
                                                                createData?.projectOwner?.map((e) => {
                                                                    return (
                                                                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                                                                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                                                                <Avatar {...stringAvatar(e?.label, "0.75rem")} className={classes.viewAvatar} src={e?.image_url}></Avatar>
                                                                                <Tooltip title={e?.label} placement="top" arrow>
                                                                                    <Typography className={classes.viewValue}
                                                                                        sx={{
                                                                                            textAlign: "end", width: "100px",
                                                                                            whiteSpace: "nowrap", textOverflow: "ellipsis",
                                                                                            overflow: "hidden",
                                                                                            cursor: "pointer"
                                                                                        }}>{e?.label}</Typography>
                                                                                </Tooltip>
                                                                            </Stack>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                createData?.resourceGroup?.map((e) => {
                                                                    return (
                                                                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                                                                            <Tooltip title={e?.label} placement="top" arrow>
                                                                                <Typography className={classes.resourceValue} sx={{
                                                                                    width: "100px",
                                                                                    whiteSpace: "nowrap", textOverflow: "ellipsis",
                                                                                    overflow: "hidden",
                                                                                    cursor: "pointer"
                                                                                }}>{e?.label}</Typography>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                            </Grid>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>

                    </Box>
                }
            </Box>
        </>
    )
}