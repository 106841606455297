import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import styled from "@mui/material/styles/styled";
import Stack from "@mui/material/Stack";
import Header from './header';
import { Body } from './body';
import { Footer } from './footer';
import Proptypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

let drawerWidth = 400, initialAnchor = "right";

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: prop => prop !== "width"
})(({ theme, width }) => ({
    '& .MuiPaper-root': {
        width,
    },
    color: "red"
}))

 const Filter = (props) => {

    const {
        open = false,
        onClose = null,
        onApply = null,
        anchor = initialAnchor,
        buttonName = null,
        width = props?.width?props?.width:drawerWidth,
        components = [],
        clearStateonComponentChanges = true, // if true it clears state while changing components from parent
        clearFilter = () => false,
        is_customFilter = false,
        t=()=>false,
        defaultState = {}
    } = props;

    const [state, setState] = useState({
        data: {},
        components: []
    });

    const [error, setError] = useState(null);

    useEffect(() => {
        if (components?.length) {
            let updateState = { ...state?.data };
            if (clearStateonComponentChanges) {
                updateState = {};
            }
            components?.map(_ => {
                if (_?.state_name) {
                    updateState[_?.state_name] = _?.value;
                }
                if(_?.custom_date_state){
                    updateState[_?.custom_date_state] = _?.custom_date_value;
                }

                return _
            })

            setState({
                ...state,
                data: updateState,
                components
            })
        }
        // eslint-disable-next-line
    }, [components])


    const onChangeState = (key, value, componentDetails) => {

        //construct dependency state while generate json props
        let findDependency = state?.components?.find(_ => _?.state_name === componentDetails?.state_name);
        if (findDependency?.dependency && findDependency?.dependencyState?.length > 0) {
            findDependency?.dependencyState?.map(_ => {
                if (state?.data?.hasOwnProperty(_)) {
                    state.data[_] = ""
                }
                return _
            })
        }

        setState({
            ...state,
            data: {
                ...state.data, [key]: value
            }
        })
    }

    const onClearState = () => {
        if (is_customFilter) {
            clearFilter()
        }
        setState({
            ...state,
            data: defaultState 
            // {}
        })
    }

    const onCloseDrawer = () => {
        if (!is_customFilter) onClearState();
        onClose()
    }

    const onApplyFilter = () => {
        if (!validation()) {
            onClose()
            onApply(state?.data)
            if (!is_customFilter) onClearState()

        }
    }


    const validation = () => {

        const errorState = {};

        const { data, components } = state;

        components?.map(_ => {
            if (_?.required) {
                if (
                    !data?.[_?.state_name] ||
                    //to check Object
                    (data?.[_?.state_name]?.constructor === Object && !Object?.keys(data?.[_?.state_name])?.length) ||
                    //to check Date
                    (data?.[_?.state_name]?.constructor === Date && !data?.[_?.state_name]) ||
                    //to check Array
                    (data?.[_?.state_name]?.constructor === Array && !data?.[_?.state_name]?.length) ||
                    //to check string
                    (data?.[_?.state_name]?.constructor === String && !data?.[_?.state_name]?.trim()?.length) ||
                    //to check particular components
                    (_?.component === "minMaxTextFieldWithSelect" &&
                        !Boolean(data?.[_?.state_name]?.min && data?.[_?.state_name]?.max && (_?.select ? data?.[_?.state_name]?.select : false)))
                ) {
                    errorState[_?.state_name] = true;
                }
            }
            return _
        })

        setError(errorState)
        return Boolean(Object.keys(errorState)?.length);
    }

    return (
        <div>
            <StyledDrawer
                width={width}
                anchor={anchor}
                open={open}
                onClose={onCloseDrawer}>
                <Stack
                    direction={"column"}
                    height={1}
                >
                    <Header
                        onCloseDrawer={onCloseDrawer}
                        onClear={onClearState}
                        clearFilter={clearFilter}
                        t={t}
                        defaultState={defaultState}
                    />
                    <Body
                        data={state?.data}
                        error={error}
                        components={state?.components}
                        onChangeState={onChangeState}
                        t={t}
                    />
                    <Footer
                        buttonName={buttonName ?? t("Apply")}
                        buttonAction={onApplyFilter}
                        t={t}
                    />
                </Stack>
            </StyledDrawer>
        </div >
    )
}
export default withNamespaces("accounts")(Filter)



Filter.propTypes = {
    open: Proptypes.bool,
    onClose: Proptypes.func,
    onApply: Proptypes.func,
    anchor: Proptypes.string,
    buttonName: Proptypes.string,
    width: Proptypes.string,
    components: Proptypes.array,
    clearStateonComponentChanges: Proptypes.bool
}