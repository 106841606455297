export const TabTitleList = (t) => [
    {
        title: t("General Inspection Items"),
        value: '1'
    },
    {
        title: t("Template Base Items"),
        value: '2'
    },
]

export const InspectionItemsDatas = [
    {
        id: 1,
        Name: "Inspection Name Here",
        image: "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 0,
        is_active: false,
    },
    {
        id: 2,
        Name: "Inspection Name Here1",
        image:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 0,
        is_active: false
    },
];

export const templateItems = [
    {
        id: 3,
        Name: "Inspection Name Here",
        image:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [

            {
                id: 11,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 12,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 13,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
        ],
    },
    {
        id: 4,
        Name: "Inspection Name Here1",
        image:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [
            {
                id: 14,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 15,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 16,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
        ],
    },
]

export const InspectionItemMappingPath = [
    "inspection_item_mapping_reference_no",
    "inspection_item_reference_no",
    "type",
    "name",
    "category",
    "subcategory",
    "manufacturer",
    "model_number",
    "serial_number",
    "condition",
    "location",
    "cost_price",
    "status",
];

export const InspectionItemMappingHeading = (t) => [
    { title: "", field: "icon" },
    { title: t("Unit Item ID"), field: "inspection_item_mapping_reference_no" },
    { title: t("Item ID"), field: "inspection_item_reference_no" },
    { title: t("Type"), field: "type", },
    { title: t("Name"), field: "name", },
    { title: t("Category"), field: "category", },
    { title: t("Sub Category"), field: "subcategory", },
    { title: t("Manufacturer"), field: "manufacturer", },
    { title: t("Model Number"), field: "model_number", },
    { title: t("Serial Number"), field: "serial_number", },
    { title: t("Condition"), field: "condition", },
    { title: t("Location"), field: "location", },
    { title: t("Cost Price"), field: "cost_price", },
    { title: t("Status"), field: "status", },
];

export const InspectionItemMappingDataType = [
    { type: ["view_more"], name: "icon" },
    { type: ["text"], name: "inspection_item_mapping_reference_no" },
    { type: ["text"], name: "inspection_item_reference_no" },
    { type: ["text"], name: "type" },
    { type: ["long_text"], name: "name", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "category", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "subcategory", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "manufacturer", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "model_number", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "serial_number", tooltip_placement: "top", max_width: "300px" },
    { type: ["text"], name: "condition" },
    { type: ["long_text"], name: "location", tooltip_placement: "top", max_width: "300px" },
    { type: ["price"], name: "cost_price" },
    { type: ["status"], name: "status" },
];

export const constructListData = (data) => {
    let final_list = [];

    if (data && data?.length > 0) {
        final_list = data?.map?.((_) => {
            return {
                inspection_item_mapping_reference_no: _?.inspection_item_mapping_reference_no,
                inspection_item_reference_no: _?.inspection_item_reference_no,
                type: _?.type,
                name: _?.name,
                category: _?.category,
                subcategory: _?.subcategory,
                manufacturer: _?.manufaturer_name,
                model_number: _?.model_no,
                serial_number: _?.serial_number,
                condition: _?.item_condition,
                location: _?.location_name,
                cost_price: _?.cost_price,
                status: _?.is_active,
                data: _,
            }
        })
    }

    return final_list;
}

export const constructOptionResponse = (response_array = [], type) => {
    let constructed_response_array = [];

    switch (type) {
        case "manufacturer":
            constructed_response_array = response_array?.map((i) => {
                return {
                    ...i,
                    label: i?.name,
                    value: i?.id
                }
            })
            break;

        case "category":
            constructed_response_array = response_array?.map((i) => {
                return {
                    ...i,
                    label: i?.name,
                    value: i?.id
                }
            })
            break;

        default:
            break;
    }

    return constructed_response_array;
};