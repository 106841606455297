/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { config } from '../../config';
import { allowed_file_size, singleFileUpload } from '../../utils';

const VideoRecorder = ({ t, loading, handleLoading }) => {
    const [recording, setRecording] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const facingMode = useRef('environment'); // 'user' for front camera, 'environment' for rear camera
    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: facingMode.current }, audio: true })
            .then((stream) => {
                const videoElement = videoRef.current;
                videoElement.srcObject = stream;
                const track = stream.getVideoTracks()[0];
                track.applyConstraints({ advanced: [{ torch: true }] });
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;
                const videoChunks = [];
                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        videoChunks.push(event.data);
                    }
                };
                mediaRecorder.onstop = () => {
                    const videoBlob = new Blob(videoChunks, { type: 'video/webm' });
                    setVideoBlob(videoBlob);
                };
                mediaRecorder.start();
                setRecording(true);
            })
            .catch((error) => console.error('Error accessing webcam:', error));
    };
    const handleCameraSwitch = () => {
        facingMode.current = facingMode.current === 'user' ? 'environment' : 'user'; // Toggle the facingMode
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };
    const handleSend = async () => {// Create URL for the Blob
        let uploaded_file = await singleFileUpload(videoBlob, { tenantId: `${config.tenantId}` }, alert, allowed_file_size);

        console.log("uploaded_file: ", uploaded_file);

        const videoUrl = URL.createObjectURL(videoBlob);

        console.log("videoUrl: ", videoUrl);

        const downloadLink = document.createElement('a');

        downloadLink.href = videoUrl;

        downloadLink.download = 'myvideo.mp4';

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(videoUrl);

        // Here, you would send the videoBlob to the server or other recipients using an API.
        // You can use the Fetch API or any other library to perform the HTTP request.
        // For example:
        // fetch('your_api_url', {
        //   method: 'POST',
        //   body: videoBlob,
        //   headers: {
        //     'Content-Type': 'video/webm',
        //   },
        // }).then((response) => {
        //   // Handle the response from the server if needed.
        // }).catch((error) => {
        //   // Handle any errors that occurred during the HTTP request.
        // });
    };
    return (
        <div>
            <button onClick={handleCameraSwitch}>Switch Camera</button>
            {recording ? (
                <button onClick={stopRecording}>Stop Recording</button>
            ) : (
                <button onClick={startRecording}>Start Recording</button>
            )}
            <video ref={videoRef} style={{ display: 'block', width: '100%', maxWidth: '400px', maxHeight: '300px' }} muted autoPlay />
            {videoBlob && (
                <div>
                    <video controls>
                        <source src={URL.createObjectURL(videoBlob)} type="video/webm" />
                        Your browser does not support the video element.
                    </video>
                    <button onClick={handleSend}>Send Video</button>
                </div>
            )}
        </div>
    );
};

export default withNamespaces("videoRecorder")(VideoRecorder)