/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { AccordionWithTable } from '../../components';
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, Bold, ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ResidentDetails } from './residentDetails';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "hidden",
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

const ResidentTableDetails = props => {
    const { t } = (props);
    const classes = useStyles();
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const [residentDetailsData, setResidentDetailsData] = React.useState([])
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const companyId = new URLSearchParams(search).get("companyId");
    const getResidentDetailsById = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            userId: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/resident`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                setResidentDetailsData(response?.data?.data);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong. Please try again"),
                });
            });

    };

    React.useEffect(() => {
        getResidentDetailsById();
        // eslint-disable-next-line
    }, []);

    const accordionData = []

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} >
                {/* {residentDetailsData?.map((item) => ( */}
                <>
                    <ResidentDetails t={t} data={residentDetailsData[0]} />
                </>
                {/* )
                )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} >
                <div
                    style={{
                        height: size.height - 88,
                        overflow: "scroll",
                    }}
                >
                    <Grid conatiner>
                        {accordionData?.map((item) => (
                            <>
                                <Grid item xs={12} marginTop={2}>
                                    <AccordionWithTable data={item} />
                                </Grid>
                            </>
                        )
                        )}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    </div>
}
export default withNamespaces("residentTableDetails")(ResidentTableDetails)
