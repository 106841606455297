import React from 'react'
import { MemberTableHeading, ProjectedBOMDataType, ProjectedBOMHeading, ProjectedBOMPath, ProjectedBOMRow } from '../utils'
import { FreeTextInvoiceTablePath } from '../../../utils/freeTextInvoice'
import { TableWithPagination } from '../../../components'
import { ProjectContext } from '../projectContext'

export const MemberTable = ({
    handleTableIcon = () => false,
    handlePagination = () => false,
    handleChangeLimit = () => false,
    allhandleCheck = () => false,
    type = "member",
    page = "",
    limit = "",
    heading = [],
    path = [],
    dataType = [],
    row = [],
    count = 0,
    value,
    t = () => false,
    handleViewQuill = () => false
}) => {
    const {
        tableData
    } = React.useContext(ProjectContext)
    return (
        <>
            {
                type === "member" &&

                <TableWithPagination
                    heading={MemberTableHeading(t)}
                    rows={tableData?.member?.data}
                    path={FreeTextInvoiceTablePath}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handleIcon={(type, data, boolCheckBox, checkBoxId) => handleTableIcon(type, data, boolCheckBox, checkBoxId, value)}
                    onClick={() => false}
                    tableType="no-side"
                    dataType={[
                        { type: ["customCard"], name: "card" },
                        { type: ["customCheckBox"], name: "follower", id: "follower", paddingLeft: "18px" },
                        { type: ["customCheckBox"], name: "member", id: "follower", paddingLeft: "18px" },
                        { type: ["closeIcon"], name: "icon" }
                    ]}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    allhandleCheck={allhandleCheck}
                    totalRowsCount={tableData?.member?.count}
                    page={page}
                    limit={limit}
                    height={'calc(100vh - 370px)'}
                    view={true}
                    edit={true}
                    delete={false}
                    publish_invoice={true}
                    enable_double_click={true}
                />
            }
            {
                type === "nonMember" &&

                <TableWithPagination
                    heading={heading}
                    rows={row}
                    path={path}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handleIcon={(type, data, boolCheckBox, checkBoxId) => handleTableIcon(type, data, boolCheckBox, checkBoxId, value)}
                    onClick={() => false}
                    tableType="no-side"
                    dataType={dataType}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    allhandleCheck={allhandleCheck}
                    totalRowsCount={count}
                    page={page}
                    limit={limit}
                    height={'calc(100vh - 370px)'}
                    view={true}
                    edit={true}
                    delete={value === 6 ? true : false}
                    publish_invoice={true}
                    enable_double_click={true}
                    handleViewQuill={handleViewQuill}
                />
            }
            {
                type === "normalTable" &&
                <TableWithPagination
                    heading={ProjectedBOMHeading()}
                    rows={ProjectedBOMRow}
                    path={ProjectedBOMPath}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handleIcon={handleTableIcon}
                    onClick={() => false}
                    tableType="no-side"
                    dataType={ProjectedBOMDataType}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={100}
                    page={page}
                    limit={limit}
                    height={'calc(100vh - 370px)'}
                    view={true}
                    edit={true}
                    delete={false}
                    publish_invoice={true}
                    enable_double_click={true}
                />
            }
        </>
    )
}