import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  DatePickerNew,
  FormGenerator,
  PricingApprialsComponent,
  SelectBox,
  TextBox,
} from "../../../components";
import { SemiBold, enumSelect, enum_types, wrapLabelsInT } from "../../../utils";
import { JSON1 } from "../common";
import { againstCompanyStyle } from "../style";

import { BackdropContext } from "../../../contexts";
import { loadOptionsApis } from "../../createMaintenancePlanner/utils";


export const ComponentDetails = (props) => {
  const { state, updateState, t } = props;
  // state
  const [enumValue, setEnumValue] = useState({
    revenue_type: [],
    value_basis_type: [],
  });
  // context
  const backdrop = useContext(BackdropContext);
  //   classes
  const classes = againstCompanyStyle();
  // get enum
  const getEnum = async () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading...",
    });
    const result = await enumSelect([
      enum_types?.revenue_type,
      enum_types?.unit_breakup_type,
    ]);
    setEnumValue({
      revenue_type: result?.revenue_type?.filter(i => i?.value !== "Stay"),
      value_basis_type: result?.unit_breakup_type?.filter(
        (x) => x?.value !== "Rental Value"
      ),
    });
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: t("Loading..."),
    });
  };
  // useEffect
  useEffect(() => {
    getEnum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manualResponse = (array) => {
    const details = {
        data:array?.pricing_component?.map((i) => {
            return {
                ...i,
                label: i?.label,
                value: i?.value
            }
        }),
        count:array?.count
    }
    return details
};
  return (
    <div>
      <Grid px={1} container spacing={2}>
        {/* Choose Component Revenue Type */}
        <Grid item xs={6}>
          <FormGenerator t={t}
            components={[
              {
                size: {
                  lg: 12,
                },
                isActive: true,
                component: "toggle",
                label: t("Choose Component Revenue Type"),
                value: state?.revenueType,
                placeholder: "revenueType",
                onChange: (value) => updateState("revenueType", value),
                error: state?.error?.revenueType,
                isRequired: true,
                options: wrapLabelsInT(enumValue?.revenue_type, t) ?? [],
              },
            ]}
          />
        </Grid>
        {/* Component Based On */}
        <Grid item xs={6}>
          <FormGenerator t={t}
            components={[
              {
                size: {
                  lg: 12,
                },
                isActive: true,
                component: "toggle",
                label: t("Component Based On"),
                value: state?.Based_On,
                placeholder: "Based_On",
                onChange: (value) => updateState("Based_On", value),
                error: state?.error?.Based_On,
                isRequired: true,
                options: wrapLabelsInT(enumValue?.value_basis_type, t) ?? [],
              },
            ]}
          />
        </Grid>
        {/* Pricing Component Name */}
        <Grid item xs={12}>
          <SelectBox
            selectHeight="42px"
            fontFamily={SemiBold}
            color="#ffffff"
            isRequired
            label={t("Pricing Component Name")}
            placeholder={t("Select Pricing Component Name")}
            value={state?.component_name}
            onChange={(value) => {
              props?.updateState("component_name", value);
            }}
            isError={state?.error?.component_name?.length > 0}
            errorMessage={state?.error?.component_name}
            key={JSON.stringify(state?.revenueType)}
            isPaginate={state?.revenueType?.length > 0 ? true : false}
            debounceTimeout={800}
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                `queries/pricing_component/get_dropdown`,
                {
                  company_id: props?.company?.value,
                  isActive: true,
                  revenue_type: state?.revenueType,
                  primary: state?.componentType?.isPrimary,
                  is_onetime: state?.componentType?.isOneTime,
                  refundable: state?.componentType?.isRefundable,
                  is_parking: state?.componentType?.isParking,
                  is_wallet: state?.componentType?.is_wallet,
                  is_late_fee: state?.componentType?.is_late_fee,
                  is_quantity: state?.componentType?.isQuantity,
                  is_commission_payment: state?.componentType?.is_commission_payment,
                },
                search,
                array,
                handleLoading,
                "data",
                {},
                manualResponse
              )
            }
          />
        </Grid>
      </Grid>

      <Box px={1} className={classes.divider}>
        <Divider />
      </Box>

      {/* APPRAISAL VALUES */}
      <Typography className={classes.valueTitle}>{t("APPRAISAL VALUES")}</Typography>
      <Grid container spacing={2} marginTop="-12px">

        {JSON1?.map((item) => {
          return (
            <Grid item xs={6}>
              <PricingApprialsComponent
                backgroundcolor="#fff"
                component="ComponentType"
                data={item}
                marginLeft={"13px"}
                padding={"5px"}
                handleChange={(e, data) => updateState("amountType", data)}
                borderColor={
                  item?.name === state?.amountType?.name ? true : false
                }
                bordercolor={"#5078e1"}
                color="black"
                isappraisal={true}
                sub
              />
            </Grid>
          );
        })}
        {state?.amountType?.value === "Range" ? (
          <>
            {/* amount min range */}
            <Grid item xs={6}>
              <TextBox

                heights={"45px"}
                color={"#fff"}
                isrequired
                label={t("Present Min Amount Value (Range)")}
                placeholder={t("Present Min Amount Value (Range)")}
                value={state?.minAmount}
                onChange={(e) => {
                  props?.updateState("minAmount", e.target.value);
                }}
                isError={state?.error?.minAmount?.length > 0}
                errorMessage={state?.error?.minAmount}
                endAdornment={
                  <Typography className={classes.endorement}>
                    {t("UMO / Month")}
                  </Typography>
                }
              />
            </Grid >
            {/* amount max range*/}
            < Grid item xs={6} >
              <TextBox
                color={"#fff"}
                heights={"45px"}
                isrequired
                label={t("Present Max Amount Value (Range)")}
                placeholder={t("Present Max Amount Value (Range)")}
                value={state?.maxAmount}
                onChange={(e) => {
                  props?.updateState("maxAmount", e.target.value);
                }}
                isError={state?.error?.maxAmount?.length > 0}
                errorMessage={state?.error?.maxAmount}
                endAdornment={
                  <Typography className={classes.endorement}>
                    {t("UMO / Month")}
                  </Typography>
                }
              />
            </Grid >
          </>
        ) : (
          <>
            {/* Present Amount Value (Specific) */}
            <Grid item xs={6}>
              <TextBox
                color={"#fff"}
                heights={"45px"}
                isrequired
                label={t("Present Amount Value (Specific)")}
                placeholder={t("Present Amount Value (Specific)")}
                value={state?.amount}
                onChange={(e) => {
                  props?.updateState("amount", e.target.value);
                }}
                isError={state?.error?.amount?.length > 0}
                errorMessage={state?.error?.amount}
                endAdornment={
                  <Typography className={classes.endorement}>
                    {t("UMO / Month")}
                  </Typography>
                }
              />
            </Grid>
          </>
        )}
        {/* New Appraisal Amount */}
        <Grid item xs={6}>
          <TextBox
            color={"#fff"}
            heights={"45px"}
            isrequired
            label={t("New Appraisal Amount")}
            placeholder={t("New Appraisal Amount")}
            value={state?.appraisal_amount}
            onChange={(e) => {
              props?.updateState("appraisal_amount", e.target.value);
            }}
            isError={state?.error?.appraisal_amount?.length > 0}
            errorMessage={state?.error?.appraisal_amount}
            endAdornment={
              <Typography className={classes.endorement}>
                {t("UMO / Month")}
              </Typography>
            }
          />
        </Grid>
        {/* Effective Start Period */}
        <Grid item xs={6}>
          <DatePickerNew
            fontFamily={SemiBold}
            value={state?.StartDate ?? ""}
            label={t("Effective Start Period")}
            handleChange={(value) => updateState("StartDate", value)}
            isError={state?.error?.StartDate?.length > 0}
            errorMessage={state?.error?.StartDate}
            minDate={new Date()}
            placeholder={t("Effective Start Period")}
          />

          {/* <NewDatePicker
            isRequired
            value={state?.StartDate ?? ""}
            label="Effective Start Period"
            placeholder="Effective Start Period"

            onChange={(value) => {
              props?.updateState("StartDate", value);
            }}
            minDate={new Date()}
            isError={state?.error?.StartDate?.length > 0}
            errorMessage={state?.error?.StartDate}
          /> */}


        </Grid >
      </Grid >
    </div >
  );
};
