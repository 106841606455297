import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from 'react';
import { withNamespaces } from "react-i18next";
import { AlertDialog, DetailsCardCoponent, DialogBox, FormGenerator, LoadingSection, Subheader, TemplateGenerator, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall/index";
import { AlertProps, NetWorkCallMethods, timeZoneConverter, getToday } from "../../../utils";
import { ManagedInvoiceTable, PostCard } from "../components";
import { AggrementTabStyles } from "./styles";
import { BottomCard } from '../components/bottomCard'
import { NewLoader } from "../../../components/newLoader";

const initialState = {
  id: null,
  cheque_name: "",
  bank_name: "",
  error: {
    cheque_name: "",
    bank_name: "",
  }
}

const ManagedInvoice = ({ quot_id = "", agree = {}, id, t = () => false }) => {
  const classes = AggrementTabStyles();
  const alert = React.useContext(AlertContext);
  const debounce = UseDebounce();
  const [searchText, setSearchText] = React.useState("");
  const [openPost, setOpenPost] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [data, setData] = React.useState({ ...initialState });
  const [loading, setLoading] = React.useState(true)
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [manageInvoice, setManageInvoice] = React.useState(false);
  const [disable,setDisable]=React.useState(false);
  const [pdf, setPdf] = React.useState({
    bool: false,
    pdf: ""
  })
  const [template, setTemplate] = React.useState(false);


  const [list, setList] = React.useState({
    list: [],
    count: 0
  })
  const date = { from: getToday(), to: getToday() }
  const [selectedInvoices, setSelectedInvoices] = React.useState(null)
  const [checkDetails , setCheckDetails] = React.useState({
    bank_name:"",
    cheque_name:"",
    id:"",
    error:{
      bank_name:"",
      cheque_name:"",
    }
  })
  const card = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("referenceNo"),
      subtitle: `${selectedInvoices?.data?.schedule_no}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("name"),
      subtitle: `${selectedInvoices?.data?.name ?? "-"}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Description"),
      subtitle: `${selectedInvoices?.data?.description ?? "-"}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Billing Date"),
      subtitle: `${moment(selectedInvoices?.data?.due_date).format("DD MMM YY")}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Billing Amount"),
      subtitle: `${selectedInvoices?.data?.currency?.symbol} ${selectedInvoices?.data?.total ?? '-'}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Billing Period"),
      subtitle: `${selectedInvoices?.data?.payment_period ?? "-"}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "tag",
      heading: t("Posting"),
      status: `${selectedInvoices?.data?.status}`,
      color: "#FF9340",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      component: "text",
      heading: t("Invoice Date"),
      subtitle: `${moment(selectedInvoices?.data?.invoice[0]?.invoice_date).format("DD MMM YY")}`,
      isActive: selectedInvoices?.data?.invoice[0]?.invoice_date?.length > 0 ? selectedInvoices?.data?.invoice[0]?.invoice_date : false

    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      component: "text",
      heading: t("Invoice Number"),
      subtitle: `${selectedInvoices?.data?.invoice[0]?.invoice_no}`,
      isActive: selectedInvoices?.data?.invoice[0]?.invoice_no?.length > 0 ? selectedInvoices?.data?.invoice[0]?.invoice_no : false
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      component: "text",
      heading: t("Cheque Number"),
      subtitle: `${selectedInvoices?.invoice_receipt_settlement?.cheque_no}`,
      isActive: selectedInvoices?.invoice_receipt_settlement?.cheque_no?.length > 0 ? selectedInvoices?.invoice_receipt_settlement?.cheque_no : false
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      component: "text",
      heading: t("Bank Name"),
      subtitle: `${selectedInvoices?.invoice_receipt_settlement?.bank_name}`,
      isActive: selectedInvoices?.invoice_receipt_settlement?.bank_name?.length > 0 ? selectedInvoices?.invoice_receipt_settlement?.bank_name : false
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      component: "text",
      heading: t("Payment Mode"),
      subtitle: `${selectedInvoices?.invoice_receipt_settlement?.receipt?.payment_mode}`,
      isActive: selectedInvoices?.invoice_receipt_settlement?.receipt?.payment_mode?.length > 0 ? selectedInvoices?.invoice_receipt_settlement?.receipt?.payment_mode : false
    },
  ];

  const validateForm = () => {
    let isValid = true;
    let error = checkDetails.error;
    if (checkDetails?.bank_name?.length === 0) {
      isValid = false;
      error.bank_name = t("bank_name_required");
    }
    if (checkDetails?.cheque_name?.length === 0) {
      isValid = false;
      error.cheque_name = t("chque_name_required");
    }
    setCheckDetails({ ...checkDetails, error });
    return isValid;
  };
    //update state
    const updateStateCheck = (key, value) => {
      let error = checkDetails.error;
      error[key] = "";
      setCheckDetails({ ...checkDetails, [key]: value, error });
    };
  //get managed invoices
  const getManagedInvoices = (offset = 0, limit = 10, search = "") => {

    const payload = {
      agreement_id: id,
      offset: offset,
      limit: limit,
      search: search
    }


    NetworkCall(
      `${config.api_url}/quotation_payment_schedule/get_agreement_schedule`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      const datas = response?.data?.quotation_payment_schedule?.[0]?.payment_schedule_cheques?.[0];
      setList({
        list: response?.data?.quotation_payment_schedule?.map((x) => {
          return {
            ...x,
            revenue_type: x?.quotation?.revenue_type ?? "-",
            period: `${timeZoneConverter(x?.start_date)} - ${timeZoneConverter(x?.to_date)}`
          }
        }),
        count: response?.data?.count?.[0]?.count
      })
      setData({
        ...data,
        cheque_name: datas?.payment_schedule_cheques?.cheque_name ?? "",
        bank_name: datas?.payment_schedule_cheques?.bank_name ?? "",
        id: datas?.payment_schedule_cheques?.id ?? null,
      })
      getManagedInvoice(response?.data?.quotation_payment_schedule?.[0])
      setLoading(false)
      setDisable(false)

    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })


  }
  //insert cheque
  const insertCheque = () => {
    NetworkCall(
      `${config.api_url}/queries/agreement/payment_cheque`,
      NetWorkCallMethods.post,
      {
        payment_schedule: selectedInvoices?.data?.id,
        cheque_name: checkDetails?.cheque_name,
        bank_name: checkDetails?.bank_name,
      },
      null,
      true,
      false
    )
    .then((response) => {
      setOpen(false)
      getManagedInvoice(selectedInvoices?.data)
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("Cheque Created"),
      });
    }).catch((err) => {
      console.log(err)
    })
  }

  //update cheque
  const updateCheque = () => {
    NetworkCall(
      `${config.api_url}/queries/agreement/payment_cheque`,
      NetWorkCallMethods.post,
      {
        cheque_name: checkDetails?.cheque_name,
        bank_name: checkDetails?.bank_name,
        id: checkDetails?.id
      },
      null,
      true,
      false
    )
    .then((response) => {
      setOpen(false)
      getManagedInvoice({
        id:selectedInvoices?.quotation_payment_id
      })
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("Cheque Updated"),
      });
    }).catch((err) => {
      console.log(err)
    })
  }
  //sumbit form
  const onSubmit = () => {
    if (validateForm()) {
      if (checkDetails?.id) {
        updateCheque()
      } else {
        insertCheque()
      }

    } else {
      return false
    }
  }
  const formJson = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "text",
      label: t("cheque_no"),
      placeholder: t("enter_cheque_no"),
      value: checkDetails?.cheque_name,
      onChange: (value) => updateStateCheck("cheque_name", value?.target?.value),
      error: checkDetails?.error?.cheque_name,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "text",
      label: t("bank_name"),
      placeholder: t("enter_bank_name"),
      value: checkDetails?.bank_name,
      onChange: (value) => updateStateCheck("bank_name", value?.target?.value),
      error: checkDetails?.error?.bank_name,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "button",
      label: t("update_cheque_details"),
      onClick: () => onSubmit()
    },
  ]
  //initial table load
  React.useEffect(() => {
    getManagedInvoices();
    // eslint-disable-next-line
  }, [])
  //handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getManagedInvoices(offset, limit, "")
  }
  //on change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getManagedInvoices(0, value, "")
  }
  //on search
  const handleSearch = (e) => {
    setSearchText(e)
    debounce(() => searchTableFunction(e), 800)
  }
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getManagedInvoices(0, 10, e)
  }
  //post to invoice
  const postToinvoice = () => {
    setDisable(true)
    const variables = {
      "managed_invoice_ids": [selectedInvoices?.data?.id],
    }
    // console.log(variables) 
    NetworkCall(
      `${config.api_url}/invoice/convert_managed_invoice`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    ).then((res) => {
      setOpenPost(false)

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("Invoice Converted Successfully"),
      });
      getManagedInvoices();
      setDisable(false)

    }).catch((err) => {
      console.log(err)
      setDisable(false)
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Invoice Cannot be Converted"),
      });
    })
  }
  //get managed invoices
  const getManagedInvoice = (val) => {

    const payload = {
      invoice_id : val?.invoice?.[0]?.id,
       id:val?.id, 
    }


    NetworkCall(
      `${config.api_url}/quotation_payment_schedule/get_agreement_schedule_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setSelectedInvoices({
        data: response?.data?.quotation_payment_schedule?.[0],
        invoice: response?.data?.quotation_payment_schedule?.[0],
        payment_schedule_cheques: response?.data?.payment_schedule_cheques?.[0],
        invoice_receipt_settlement: response?.data?.invoice_receipt_settlement?.[0],
        quotation_payment_id:val?.id
      })
      setCheckDetails({
        bank_name:response?.data?.quotation_payment_schedule?.[0]?.payment_schedule_cheques?.bank_name,
        cheque_name:response?.data?.quotation_payment_schedule?.[0]?.payment_schedule_cheques?.cheque_name,
        id:response?.data?.quotation_payment_schedule?.[0]?.payment_schedule_cheques?.id,
        error:{
          bank_name:"",
          cheque_name:"",
        }
      })
      setLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const handleIcon = (data) => {
    getManagedInvoice(data)
  }

  //post to invoice
  const getManageInvoice = (v) => {
    setManageInvoice(!manageInvoice)
    setPdf({
      bool: false,
      data: ""
    })
    const variables = {
      "id": selectedInvoices?.data?.id,
      "quote_id": selectedInvoices?.data?.quotation_id,
      type_master_id: v?.value
    }
    // console.log(variables) 
    NetworkCall(
      `${config.api_url}/invoice/manage_invoice`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    ).then((res) => {
      setPdf({
        pdf: res?.data?.data,
        bool: true,

      })

    }).catch((err) => {
      setPdf({
        pdf: "",
        bool: false,

      })
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some thing went wrong"),
      });
    })
  }
  return (
    <Box className={classes.kycbox}>
      {
        loading ?
          <LoadingSection top="20vh" message={t("managedLoading")} />
          :

          <Grid container>
            <Grid item xs={7} borderRight="1px solid #E4E8EE">
              <ManagedInvoiceTable
                handleChangeLimit={handleChangeLimit}
                handlePagination={handlePagination}
                page={page}
                list={list}
                limit={limit}
                handleIcon={handleIcon}
                handleSearch={handleSearch}
                searchText={searchText}
                setSelectedInvoices={setSelectedInvoices}
                setData={setData}
                data={data}
                selectedInvoices={selectedInvoices?.data}
              />
            </Grid>
            <Grid item xs={5}>
              {
                selectedInvoices &&

                <Box>
                  <Subheader
                    hideBackButton={true}
                    title={selectedInvoices?.data?.schedule_no}
                    isDownload
                    onClick={() => setTemplate(true)}
                  />
                  <Box className={classes.managedInvoiceRoot}>
                    <DetailsCardCoponent
                      border={false}
                      components={card}
                      title={t("PERFORMADETAILS")}
                    />
                    <Box height="12px" />
                    {
                      agree?.payment_mode === "Cheque" &&
                      <Box>
                        {
                          selectedInvoices?.data?.payment_schedule_cheques?.cheque_name?.length > 0 && selectedInvoices?.data?.payment_schedule_cheques?.bank_name?.length > 0 ?
                            <PostCard
                              icon={<ContactMailIcon sx={{ color: "#7D534C" }} />}
                              title={selectedInvoices?.data?.payment_schedule_cheques?.cheque_name}
                              description={selectedInvoices?.data?.payment_schedule_cheques?.bank_name}
                              status={t("edit")}
                              bgColor="#FEEAEA80"
                              onClick={() => setOpen(!open)}
                            />
                            :
                            <PostCard
                              icon={<ContactMailIcon sx={{ color: "#7D534C" }} />}
                              title={t("postDatedCheque")}
                              description={t("payment_by_post_dated_cheque")}
                              status={t("update_cheque_details")}
                              bgColor="#FEEAEA80"
                              onClick={() => setOpen(!open)}
                            />
                        }



                      </Box>
                    }

                  </Box>
                  <Box p={2}>
                    <BottomCard t={t} list={list} date={date} onClick={() => setOpenPost(true)} disabled={selectedInvoices?.data?.status === "Posted"} />
                  </Box>
                </Box>
              }
            </Grid>
          </Grid>
      }
      {/*Cheque Details */}
      <AlertDialog
        onClose={() => setOpen(!open)}
        open={open}
        header={t("cheque_details_updataion")}
        medium={true}
        component={
          <Box p={2}>
            <FormGenerator t={t} components={formJson} />
          </Box>
        }
      />
      {/*post to invoice */}
      <AlertDialog
        onClose={() => setOpenPost(false)}
        open={openPost}
        header={t("Post To Invoice")}
        isNormal={true}
        component={
          <Box p={2}>
            <Typography className={classes.title}>
              {
                moment(selectedInvoices?.due_date).isAfter(new Date()) ?
                  t("cheque_sub") :
                  t("cheque_sub1")
              }

            </Typography>
            <Grid container spacing={2} marginTop="12px">
              <Grid item xs={6}>
                <Button disabled={disable} onClick={postToinvoice} className={classes.btn} variant="contained" fullWidth color="success">{t("yes")}</Button>
              </Grid>
              <Grid item xs={6}>
                <Button disabled={disable} onClick={() => setOpenPost(false)} className={classes.btn} variant="contained" fullWidth color="error">{t("no")}</Button>
              </Grid>
            </Grid>
          </Box>
        }
      />

      <DialogBox onClose={() => setManageInvoice(!manageInvoice)} handleClose={() => setManageInvoice(!manageInvoice)} open={manageInvoice}
        component={
          <div style={{ height: "100vh" }}>
            {
              pdf?.bool ?
                <iframe
                  src={`data:application/pdf;base64,${pdf?.pdf
                    }`}
                  alt="pdf"
                  width="100%"
                  style={{ height: `calc(100vh - 2px)` }}
                  title="quotation"

                /> : <NewLoader minusHeight={'2px'} />}

          </div>} />



      <TemplateGenerator t={t} name={t("Proforma Invoice Template")} open={template} onClose={() => setTemplate(false)} type={4} generate={getManageInvoice} />


    </Box >


  );
};
export default withNamespaces("agreement")(ManagedInvoice);
