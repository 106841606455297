import { Box, Stack, Typography, Divider, Grid } from '@mui/material'
import React from 'react'
import { ActualBOMPreviewStyle } from './style'
import BillingAmountIcon from '../../assets/billingamtIcon'


export const ActualBOMPreview = ({
    t = () => false,
    data = "",
    itemDetails =""
}) => {
    console.log("itemDetails", itemDetails)
    const classes = ActualBOMPreviewStyle()
    return (
        <Box p={2}>
            <Box className={classes.requestDetailsBox} alignItems={"center"}>
                <Box display="flex" justifyContent={"space-between"} alignItems="center">
                    <Typography className={classes.requestTitle}>{data?.source ?? "-"}</Typography>
                    <Typography className={classes.statusBox} style={{ backgroundColor: data?.status === "Pending" ? "#78B1FE" : data?.status === "Approved" ? "#5AC782" : "#FF4B4B" }}>{data?.status}</Typography>
                </Box>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Typography className={classes.requestNo}>{data?.request_no}</Typography>
                    <Box className={classes.requestdot}></Box>
                    <Typography className={classes.requestNo}>{data?.maintenance_request_no ?? data?.general_request_no ?? data?.agreement_no ?? "-"}</Typography>
                </Stack>
            </Box>
            {itemDetails?.items?.length > 0 &&
                <>
                    <Box mt={1} mb={1}>
                        <Typography className={classes.BillingAmountTxt}>{t("Billing Amount")}</Typography>
                    </Box>
                    <Box mt={1} className={classes.requestDetailsBox}>
                        <Box display="flex" justifyContent="space-between" alignItems={"center"}>
                            <Stack direction="row" columnGap={1} alignItems="center">
                                <BillingAmountIcon />
                                <Box>
                                    <Typography className={classes.requestTitle}>{itemDetails?.symbol} {itemDetails?.total_amount_after_tax ?? 0} </Typography>
                                    <Typography className={classes.requestNo}>{t("Final Amount")}</Typography>
                                </Box>
                            </Stack>
                            {/* <Box 
                            // onClick={() => handleIcon("info", data)}
                             style={{ cursor: "pointer" }}>
                                <Typography className={classes.viewDetailsTxt}>{"View Details"}</Typography>
                            </Box> */}
                        </Box>
                        <Box mt={1} mb={1.5}>
                            <Divider />
                        </Box>
                        <Box position={"relative"}>
                            <Box mt={1} maxHeight={"180px"} overflow={"scroll"}>
                                {itemDetails?.items?.map((val) => {
                                    return (
                                        <Grid container spacing={2} alignItems="center" mb={1}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.itemName}>{val?.inspection_item_name}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.requestNo} sx={{textTransform: "uppercase"}}>{val?.quatity} {t("Qty")}</Typography>
                                            </Grid>
                                            <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                <Typography className={classes.itemName}>{itemDetails?.symbol} {val?.line_amount}</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                                {itemDetails?.deduction?.length > 0 &&

                                    <>
                                        <Box mt={1} mb={1}>
                                            <Divider />
                                        </Box>
                                        <Box mb={1}>
                                            <Typography className={classes.BillingAmountTxt}>{t("Deduction")}</Typography>
                                        </Box>
                                        {itemDetails?.deduction?.map((x) => {
                                            return (
                                                <Grid container spacing={2} alignItems="center" mb={1}>
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.itemName}>{x?.description}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography className={classes.requestNo}>-</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                        <Typography className={classes.itemName}>{" - "} {itemDetails?.symbol} {x?.amount}</Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        <Box mt={1} mb={1}>
                                            <Divider />
                                        </Box>
                                    </>
                                }
                            </Box>

                            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                                <Typography className={classes.finalamttext}>{t("Final Amount")}</Typography>
                                <Typography className={classes.finalamttext}>{itemDetails?.symbol} {itemDetails?.total_amount_after_tax ?? 0}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    )
}