import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { CustomToggleButtonComponent, ToggleButtonComponent } from '../../../components';
import { CustomSelect } from "../../../components/filterGenerator/components/select";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { filterStyles } from "./styles";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
export const Filter = ({ updateState = () => false, onClose = () => false, applyfilter = () => false, filter = "", state = {}, onClear = () => false, type = [], company = "", t = () => false }) => {
    const classes = filterStyles();
    const size = useWindowDimensions()

    const GRACE_PEROID = [

        {
            value: 15,
            label: t("15 Days")
        },
        {
            value: 30,
            label: t("30 Days")
        },
    ]
    const FURNISH_TYPE = [
        {
            "label": t("None"),
            "value": "None"
        },
        {
            "label": t("Unfurnished"),
            "value": "Unfurnished"
        },
        {
            "label": t("Semi-Furnished"),
            "value": "Semi-Furnished"
        },
        {
            "label": t("Furnished"),
            "value": "Furnished"
        },
        {
            "label": t("Shell"),
            "value": "Shell"
        }
    ]


    const PED_ALLOWED = [
        {
            label: t("Allowed"),
            value: "Allowed"
        },
        {
            label: t("Not Allowed"),
            value: "Not Allowed"
        }
    ]
    const unitPurposeList = [
        {
            label: t("Commercial"),
            value: "Commercial",
        },
        {
            label: t("Mixed"),
            value: "Mixed",
        },
        {
            label: t("Residential"),
            value: "Residential",
        },
    ];


    return (
        <div>
            <Box display="flex">
                <Box marginRight="12px">
                    <IconButton size="small" onClick={onClose} className={classes.dialogclose}>
                        <CloseIcon size="small" style={{ fontSize: "1.25rem" }} />
                    </IconButton>
                </Box>
                <Box style={{ backgroundColor: "white" }} className={classes.root}>
                    <Box display="flex" alignItems="center" className={classes.titleBar} p={1.3}>

                        <Box flexGrow={1}>
                            <Typography className={classes.title}>{t("Filter")}</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.clear} onClick={onClear}>{t("Clear Filter")}</Typography>
                        </Box>

                    </Box>
                    <div style={{ height: size?.height - 112, overflow: "scroll" }}>

                        <Box >
                            {
                                type?.facilites &&
                                <Accordion disableGutters defaultExpanded={state?.facilites?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Property Amenities")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect

                                            placeholder={t("Property Amenities")}
                                            padding="2px"
                                            value={state?.facilites}
                                            isMulti
                                            isPaginate
                                            menuPlacement="bottom"
                                            onChange={(value) => updateState('facilites', value)}
                                            debounceTimeout={800}
                                            loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                  "queries/property_search/property_amenity",
                                                  {
                                                      company_id: company
                                                  },
                                                  search,
                                                  array,
                                                  handleLoading,
                                                  "amenities_type_master",
                                                  {},
                                              )
                                              }
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.grace &&
                                <Accordion disableGutters defaultExpanded={state?.facilitesbool ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Grace Period")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>

                                        <CustomToggleButtonComponent
                                            t={t}
                                            value={state?.facilitesbool}
                                            onChange={(e) => {
                                                updateState("facilitesbool", e)
                                            }}
                                            height="46px"
                                            options={GRACE_PEROID}
                                            isMulti
                                            custom
                                            customValue={0}
                                            handleDelete={() => updateState("facilitesbool", "")}
                                            is_filter
                                        />

                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.unittype &&
                                <Accordion disableGutters defaultExpanded={state?.unittype?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Unit Type")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect
                                            placeholder={t("Unit Type")}
                                            label={false}
                                            padding="2px"
                                            menuPlacement="auto"
                                            value={state?.unittype}
                                            isPaginate
                                            isMulti
                                            onChange={(value) => updateState('unittype', value)}
                                            debounceTimeout={800}
                                            loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                  "queries/unit_type_master",
                                                  {
                                                      company_id: company
                                                  },
                                                  search,
                                                  array,
                                                  handleLoading,
                                                  "data",
                                                  {},
                                              )
                                              }
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.unitpurpose &&
                                <Accordion disableGutters defaultExpanded={state?.unitpurpose?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Unit Type ")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect
                                            label={false}
                                            padding="2px"
                                            color="#F5F7FA"
                                            options={unitPurposeList}
                                            value={state?.unitpurpose}
                                            onChange={(value) => updateState('unitpurpose', value)}

                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.furnish &&
                                <Accordion disableGutters defaultExpanded={state?.furnish ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Furnishing")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <ToggleButtonComponent
                                            value={state?.furnish}
                                            onChange={(e) => updateState("furnish", e)}
                                            options={FURNISH_TYPE}
                                            isMulti
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.pet &&
                                <Accordion disableGutters defaultExpanded={state?.pet ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Pets Allowed")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <ToggleButtonComponent
                                            value={state?.pet}
                                            onChange={(e) => updateState("pet", e)}
                                            options={PED_ALLOWED}
                                            isMulti
                                        />
                                        <Box height="14px" />

                                    </AccordionDetails>
                                </Accordion>
                            }


                        </Box>
                    </div>
                    <Box p={1} className={classes.applyRoot}>
                        <Button onClick={applyfilter} variant="contained" className={classes.apply} fullWidth >{t("Apply")}</Button>

                    </Box>
                </Box>


            </Box>

        </div>
    )
}