import React from 'react'
import styled from '@mui/material/styles/styled';
import { withNamespaces } from 'react-i18next';
import { SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { Box, Button, Dialog, DialogContent, DialogContentText, Drawer, Grid, Stack, Typography } from '@mui/material';
import { residentOnboardingStyle } from './style';
import { getCompanyOption, getRoutePermission, NetWorkCallMethods, AlertProps, LeadheadingResident, LeadpathResident, accessCheckRender, LocalStorageKeys } from '../../utils';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Create } from './create';
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { useHistory } from "react-router-dom";
import { Routes } from '../../router/routes';
import { TenantCreate } from './tenantOnBoarding';


const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 152px)`,
    overflow: "hidden",
    margin: "14px"
}))


const ResidentOnboarding = ({ t, type }) => {
    const classes = residentOnboardingStyle()
    const history = useHistory();
    const debounce = UseDebounce();
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const is_quick_lease = ["1" , "41"].includes(moduleId)

    // useRef
    const permission = React.useRef([]);

    // --------------CONTEXT------------------
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);

    // -------------- STATE ---------------------
    const [onBoardingData, setOnBoardingData] = React.useState({
        tableRow: [],
        limit: 10,
        page: 1,
        searchText: "",
        offset: 0
    })
    const [state, setState] = React.useState({
        right: false,
        company: [],
        SelectedCompany: {},
        selected_id: null,
        lead_id: null
    });
    const [list, setList] = React.useState({
        count: null,
        list: []
    })
    const [search, setSearch] = React.useState("")
    const [deletePopup, setDeletePopup] = React.useState({
        bool: false,
        data: {}
    })


    // -------------------FUNCTION-----------
    // table handle functions
    const handleTableIcon = (types, data) => {
        if (types === 'delete') {
            // updateLead(data)
            return setDeletePopup({
                ...deletePopup,
                bool: true,
                data: data
            })
        }
        if (types === 'edit') {
            return setState({ ...state, selected_id: data?.quotation_id ?? null, lead_id: data?.id, right: true })
        }
        else {
            if (data?.quotation_id) {
                history.push({ pathname: Routes.residentOnboardingViewEdit, state: { main: data?.quotation_id, lead_id: data?.id, data: data, type: ((type === "tenant") ? true : false) , revenue_type:type } })
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Doesn't Have Any Quotation.`,
                });
            }

        }
    }

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setOnBoardingData({ ...onBoardingData, limit: e, page: 1 })
        listFunction(onBoardingData?.offset, e, state?.SelectedCompany?.value, search);
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setOnBoardingData({ ...onBoardingData, page: e })
        let offset = (e - 1) * onBoardingData?.limit;
        listFunction(offset, onBoardingData?.limit, state?.SelectedCompany?.value, search);

    }

    // drawer function

    const toggleDrawer = () => {
        setState({ ...state, right: !state?.right });
    };

    const create = () => {
        setState({ ...state, right: !state?.right, selected_id: null, lead_id: null });
    };

    // company select
    const companySelect = (e) => {
        setState({ ...state, SelectedCompany: e });
        listFunction(onBoardingData?.offset, onBoardingData?.limit, e?.value, search);

    }

    // get list
    const listFunction = (offset = 0, limit = 10, company, search = null) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...Loading",
        });
        const payload = {
            offset: offset,
            recordsPerPage: limit,
            companyId: company,
            query: search ?? undefined,
            is_active: [true],
            type: type === "leaseType" ? ["Lease"] : ["Manage"],
            onboarding_type: type === "tenant" ? "Tenant" : null

        }
        NetworkCall(
            `${config?.api_url}/get-all-leads`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                const data = res?.data?.data?.map((val, index) => {
                    return {
                        sno: (offset ?? 0) + index + 1,
                        index: offset + index + 1,
                        leadNo: val?.lead_no ?? "-",
                        date: val?.created_at,
                        statusDate: val?.updated_at,
                        leadId: val?.proxy_lead_no ?? "-",
                        description: val?.subject ?? "-",
                        purpose: val?.purpose ?? "-",
                        source: val?.lead_source ?? "-",
                        activeQuote: val?.quotation_no ?? "-",
                        value: val?.total_quote_amount ? `${val?.currency_symbol ?? ""} ${val?.total_quote_amount}` : "-",
                        leadName: val?.name ?? "-",
                        owner: val?.leadOwner ?? "-",
                        dateOfOpen: val?.openedDate,
                        dateOfClosed: val?.status === "Open" ? null : val?.updated_at,
                        status: val?.status ?? "-",
                        priority: val?.urgent ?? "-",
                        opportunity_type: val?.revenue_type ?? "-",
                        ...val
                    }
                })
                setList({
                    count: res?.data?.totalLeads,
                    data
                })

            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Some thing went wrong.`,
                });
            })
    }

    //   get permission function
    const getPermission = async () => {
        const tempPermission = await getRoutePermission();
        permission.current = tempPermission;
        if (tempPermission?.read) {
            let company = getCompanyOption(backdrop, auth, alert);
            if (company) {
                listFunction(0, 10, company?.selected?.value, null)
                setState({
                    ...state,
                    company: company?.list,
                    SelectedCompany: company?.selected,
                });
            }
        }
    };

    const searchTableFunction = (e) => {
        setSearch(e)
        debounce(() => listFunction(onBoardingData?.offset, onBoardingData?.limit, state?.SelectedCompany?.value, e), 800);

    }

    const updateLead = (data) => {
        setDeletePopup({
            data: {},
            bool: false
        })
        let datas = {
            lead_id: data?.id,
            quotation_id: data?.quotation_id,
            is_active: false
        }
        NetworkCall(
            `${config?.api_url}/resident_onboarding/update_status`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((rs) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "OnBoarding Deleted Successfully",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                listFunction(onBoardingData?.offset, onBoardingData?.limit, state?.SelectedCompany?.value, null)
            })
            .catch((er) => {
                console.log(er);
            });
    };

    const input_category_type = {
        "leaseType":is_quick_lease ? "quick_lease" : "quick_lease_workspace",
        "resident":"resident_onboard",
        "tenant":"tenant_onboard"
    }


    React.useEffect(() => {
        getPermission()
        // eslint-disable-next-line 
    }, [])


    const render = () => {
        return (
            <>
                <Box>
                    {/* sub header */}
                    <Subheader
                        title={`${t(type === "leaseType" ? "Quick Lease" : ((type === "tenant") ? "Tenant Onboarding" : "Resident Onboarding"))} (${list?.count ?? 0})`}
                        select
                        hideBackButton={true}
                        options={state?.company}
                        value={state?.SelectedCompany}
                        placeholder={t("Search")}
                        onchange={(e) => {
                            companySelect(e);
                        }}
                    />

                    {/* table component */}
                    <CustomPaper>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                {/*search field*/}
                                <SearchFilter
                                    placeholder={t("Search")}
                                    value={search}
                                    handleChange={(value) => searchTableFunction(value)}
                                    height={'40px'}
                                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                {/*Add Btn*/}
                                <Box display={"flex"} justifyContent={"end"}>
                                    <Button variant="contained" className={classes.createBtn}
                                        onClick={create}>
                                        {t("Create")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                        <TableWithPagination
                            heading={LeadheadingResident(t)}
                            rows={list?.data}
                            path={LeadpathResident}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => false}
                            tableType="no-side"
                            dataType={[
                                { type: ["more_resident_onboard"], icon: "icon" },
                                { type: ["text"], name: "leadNo" },
                                { type: ["date"], name: "date" },
                                { type: ["long_text"], name: "description" },
                                { type: ["text"], name: "purpose" },
                                // { type: ["text"], name: "opportunity_type" },
                                // { type: ["text"], name: "activeQuote" },
                                { type: ["text"], name: "value" },
                                { type: ["text"], name: "owner" },
                                { type: ["oppertunity_status"], name: "status" },
                                { type: ["date"], name: "dateOfOpen" },
                                { type: ["date"], name: "dateOfClosed" },
                            ]}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={list?.count ?? 0}
                            page={onBoardingData?.page}
                            limit={onBoardingData?.limit}
                            height={'calc(100vh - 300px)'}
                            view={true}
                            edit={true}
                            delete={true}
                        />
                    </CustomPaper>


                    {/* create section */}
                    <React.Fragment key={'right'}>
                        <Drawer
                            anchor={'right'}
                            open={state?.right}
                            onClose={toggleDrawer}
                        >
                            {
                                type === "tenant" ? <TenantCreate t={t} onClose={toggleDrawer} state={state} reload={() => listFunction(0, 10, 1, null)} company_id={state?.SelectedCompany?.value} quote_id={state?.selected_id} lead_id={state?.lead_id} btnName="" type={type} open={state?.right} input_type={input_category_type[type]}/> : 
                                <Create t={t} onClose={toggleDrawer} state={state} reload={() => listFunction(0, 10, 1, null)} company_id={state?.SelectedCompany?.value} quote_id={state?.selected_id} lead_id={state?.lead_id} btnName="" type={type} title={(type === "leaseType") ? `${t(state?.lead_id ? "Edit Quick Lease creation" : "Create Quick Lease creation")}` : null} input_type={input_category_type[type]}/>
                            }


                        </Drawer>
                    </React.Fragment>

                    {/* delete popup */}
                    <Dialog
                        open={deletePopup?.bool}
                        onClose={() => setDeletePopup({
                            ...deletePopup,
                            bool: false
                        })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.publishDialog}
                    >
                        <DialogContent sx={{ padding: 0 }}>
                            <DialogContentText sx={{ background: "transparent" }}>
                                <div style={{
                                    paddingInlineEnd: auth?.auth?.auth?.language === 'ar' ? "240px" : "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    backgroundImage: `url(${"images/announsment.svg"})`,
                                    backgroundRepeat: "no-repeat",
                                    height: 250,
                                    width: 600,
                                }}>
                                    <Stack spacing={2} alignItems={"center"}>
                                        <Typography className={classes.deletePopupTitle}>
                                            {t("Are you sure you want to delete this Contract?")}
                                        </Typography>
                                        <Stack direction={"row"}>
                                            <Button className={classes.Yes} onClick={() => updateLead(deletePopup?.data)}>{t("Yes")}</Button>
                                            <Button className={classes.No} onClick={() => setDeletePopup({
                                                ...deletePopup,
                                                bool: false
                                            })}>{t("No")}</Button>
                                        </Stack>
                                    </Stack>

                                </div>

                            </DialogContentText>
                        </DialogContent>
                    </Dialog>


                </Box>
            </>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission?.current)}
        </>
    );
}

export default withNamespaces("residentOnboarding")(ResidentOnboarding)