import React from 'react'
import { withNamespaces } from 'react-i18next';
import styled from '@mui/material/styles/styled';
import { ProjectContext } from './projectContext';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { CreateMenuList, ProjectTableHeading, ProjectTablePath, statusOption } from './utils';
import { Grid, Box, Stack, Menu, Typography, Button, IconButton, Divider, Drawer, Badge } from "@mui/material"
import { ProjectStyle } from './style';
import FilterIMG from '../../assets/filter';
import { CreateForm } from './createForm';
import { CloseIconWithBG } from '../../assets';
import { ViewForm } from './viewForm';
import { AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, getCompanyOption, getRoutePermissionNew } from '../../utils';
import { NewLoader } from '../../components/newLoader';

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 152px)`,
    overflow: "hidden",
    margin: "16px"
}))

const Project = ({ t = () => false }) => {

    const {
        createData,
        setCreateData,
        viewPage,
        setViewPage,
        validateCreateForm,
        getEnum,
        selectedCompany,
        setSelectedCompany,
        initialState,
        enumValue,
        updateCreateData,
        upsertProject,
        createForm,
        setCreateForm,
        getProjectList,
        list,
        getProjectDetail,
        filterData,
        setFilterData,
        viewData,
        loading,
        setData,
        data,
        disableBtn,
    } = React.useContext(ProjectContext)

    const classes = ProjectStyle()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")


    const open = Boolean(anchorEl);
    const debounce = UseDebounce()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
                getEnum()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getProjectList(0, 10, "", filterData, company?.selected?.value)
        }
    }

    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getProjectList(offset, limit, "", filterData)
        // getCustomerList(offset, limit, "", [], [], null)
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getProjectList(0, value, "", filterData)
        // getCustomerList(0, value, "", [], [], null)
    };

    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getProjectList(0, 10, "", filterData, value?.value)
    }

    const handleTableIcon = (type, data) => {
        if (type === "view_more" || type === "double_click") {
            getProjectDetail(data?.id, true)
        } else if (type === "edit") {
            getProjectDetail(data?.id, false)
        } else if (type === "active") {
            const payload = {
                id: data?.id,
                is_active: !data?.is_active
            }
            upsertProject(payload, true)
        }
    }

    const handleSearch = (search) => {
        setSearchText(search)
        debounce(() => getProjectList(0, 10, search, filterData), 800)
    }

    const handleNextStep = (step) => {
        if (step === 4) {
            upsertProject()
        } else {
            if (validateCreateForm()) {
                setCreateData({
                    ...createData,
                    step: step,
                    [`step${step - 1}`]: true
                })
            }
        }

    }

    const handleApplyFilter = (value) => {
        setFilterData(value)
        getProjectList(0, 10, "", value, value?.value)
    }

    const render = () => {
        return (
            <>
                <Subheader
                    title={viewPage ? viewData?.title : t("Project")}
                    hideBackButton={!viewPage}
                    select
                    isReadOnly={viewPage}
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => handleCompanyChange(e)}
                    goBack={() => {
                        setViewPage(false)
                        setData({
                            ...data,
                            selectedValue: 0
                        })
                    }}
                />

                {
                    !viewPage ?
                        <>
                            {
                                // loading ?
                                //     <CustomPaper><NewLoader minusHeight='200px' /> </CustomPaper> :
                                    <CustomPaper>

                                        <Grid container alignItems={"center"}>
                                            <Grid item xs={4}>
                                                <SearchFilter
                                                    placeholder={t("Search")}
                                                    value={searchText}
                                                    handleChange={(value) => handleSearch(value)}
                                                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                                />
                                            </Grid >
                                            <Grid item xs={8}>
                                                <Box display={"flex"} justifyContent={"end"}>
                                                    <Stack direction="row" columnGap={2}>
                                                        <IconButton
                                                            onClick={() => setFilterDrawer(true)}
                                                            className={classes.filterButton}>
                                                            {
                                                                filterData?.status?.length > 0 ?
                                                                    <Badge variant="dot" color="primary"
                                                                        invisible={false}>
                                                                        <FilterIMG color="#091b29" />
                                                                    </Badge>
                                                                    :
                                                                    <FilterIMG color="#091b29" />
                                                            }
                                                        </IconButton>

                                                        <Button
                                                            variant='contained'
                                                            className={classes.createBtn}
                                                            id="basic-button"
                                                            aria-controls={open ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={handleClick}
                                                        >
                                                            {t("Create")}
                                                        </Button>

                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                            className={classes.menuBox}
                                                        >
                                                            <Stack spacing={2} p={1}>
                                                                {
                                                                    CreateMenuList(enumValue?.project_type)?.map((e, i, length) => {
                                                                        return (
                                                                            <>
                                                                                <Stack direction={"row"} spacing={2} onClick={() => {
                                                                                    setCreateForm(true)
                                                                                    updateCreateData("menuType", e?.value)
                                                                                    handleClose()
                                                                                }}>
                                                                                    {e?.icon}
                                                                                    <Stack>
                                                                                        <Typography className={classes.menuTitle}>{e?.title}</Typography>
                                                                                        <Typography className={classes.menuSubTitle}>{e?.subTitle}</Typography>
                                                                                    </Stack>
                                                                                </Stack>
                                                                                {length?.length - 1 !== i && <Box>
                                                                                    <Divider></Divider>
                                                                                </Box>}
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </Stack>
                                                        </Menu>
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <TableWithPagination
                                            heading={ProjectTableHeading(t)}
                                            rows={list?.data}
                                            path={ProjectTablePath}
                                            showpagination={true}
                                            showpdfbtn={false}
                                            showexcelbtn={false}
                                            showSearch={false}
                                            handleIcon={handleTableIcon}
                                            onClick={() => false}
                                            tableType="no-side"
                                            dataType={[
                                                { type: ["text"], name: "project_id" },
                                                { type: ["text"], name: "project_type" },
                                                { type: ["description"], name: "project_title" },
                                                { type: ["text"], name: "project_relation" },
                                                { type: ["date"], name: "start_date" },
                                                { type: ["date"], name: "end_date" },
                                                { type: ["text"], name: "planned_hrs" },
                                                { type: ["text"], name: "actual_hrs" },
                                                { type: ["description"], name: "project_owner" },
                                                { type: ["status"], name: "status" },
                                                { type: ["more_all"], name: "icon" },
                                            ]}
                                            handlePagination={handlePagination}
                                            handleChangeLimit={handleChangeLimit}
                                            totalRowsCount={list?.count}
                                            page={page}
                                            limit={limit}
                                            height={'calc(100vh - 300px)'}
                                            view={true}
                                            edit={true}
                                            delete={true}
                                            enable_double_click={true}
                                        />

                                        <React.Fragment key={'right'}>
                                            <Drawer
                                                anchor={'right'}
                                                open={createForm}
                                                // onClose={() => {
                                                //     setCreateData({ ...initialState })
                                                //     setCreateForm(false)
                                                // }}
                                                className={classes.drawer}
                                            >
                                                <Box>
                                                    <Stack direction={"row"} spacing={2} alignItems={"center"} className={classes.createHead}>
                                                        <Box
                                                            onClick={() => {
                                                                setCreateData({ ...initialState })
                                                                setCreateForm(false)
                                                            }}>
                                                            <CloseIconWithBG />
                                                        </Box>
                                                        <Typography className={classes.overViewTitle}>{t("Create New Project")}</Typography>
                                                    </Stack>
                                                    <Box className={classes.createBody}>
                                                        <CreateForm t={t} />
                                                    </Box>
                                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} className={classes.createFooter}>
                                                        {
                                                            createData?.step === 1 ? <Box></Box> :
                                                                <Button variant="outlined" className={classes.prevBtn}
                                                                    disabled={disableBtn}
                                                                    onClick={() => setCreateData({
                                                                        ...createData,
                                                                        step: createData?.step - 1
                                                                    })}>{t("Previous")}</Button>
                                                        }
                                                        <Button variant="contained" className={classes.nextBtn}
                                                            disabled={disableBtn}
                                                            onClick={() => handleNextStep(createData?.step + 1)}>{createData?.step === 3 ? (createData?.edit ? t("Update") : t("Create")) : t("Next")}</Button>
                                                    </Stack>
                                                </Box>

                                            </Drawer>
                                        </React.Fragment>
                                    </CustomPaper>
                            }
                        </>
                        :
                        <>
                            {
                                loading ?
                                    <CustomPaper><NewLoader minusHeight='200px' /> </CustomPaper>
                                    :
                                    <ViewForm t={t} />
                            }
                        </>
                }

                <FilterGenerator
                    open={filterDrawer}
                    defaultState={{ status: [true] }}
                    onClose={() => setFilterDrawer(false)}
                    onApply={(value) => handleApplyFilter(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: statusOption(),
                            isMulti: true,
                            state_name: "status",
                            label: t("Status")
                        },
                    ]} />
            </>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}

export default withNamespaces("project")(Project); 