import React from "react";
import { withNavBars } from "../../HOCs";
import SchedulePlanView from "./planView"
class SchedulePlanViewParent extends React.Component {
    render() {
        return <SchedulePlanView {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SchedulePlanViewParent, props);