export const Routes = {
  propertycreation: "/propertycreation",
  propertyview: "/propertyview",
  blockView: "/blockview",
  home: "/",
  login: "/login",
  otp: "/otp",
  forgotPassword: "/forgotpassword",
  properties: "/properties",
  addProperty: "/addproperty",
  addBlock: "/addblock",
  addFloor: "/addfloor",
  addUnit: "/addunit",
  createdFloor: "/createdfloor",
  propertyDetails: "/propertydetails",
  blockDetails: "/blockdetails",
  propertyFinder: "/propertyfinder",
  propertyFinderDetails: "/propertyfinderdetails",
  shortListedUnits: "/shortlistedunits",
  createQuotation: "/createquotation",
  leads: "/leads",
  leadsDetails: "/leadsdetails",
  businessMaster: "/businessmaster",
  servicesMaster: "/servicesmaster",
  quotation: "/quotation",
  quotation_view: "/quotationDetails",
  resetPassword: "/resetpassword",
  unitTable: "/unittable",
  unitTableDetails: "/unittabledetails",
  upload: "/upload",
  agreementUnitAccess: "/agreementUnitAccess",
  request: "/request",
  residentTable: "/residenttable",
  residentTableDetails: "/residenttabledetails",
  ownerTable: "/ownertable",
  ownerTableDetails: "/ownertabledetails",
  announcement: "/announcement",
  createannounsment: "/createannouncement",
  block: "/block",
  floor: "/floor",
  unit: "/unit",
  unitDetails: "/unitDetails",
  propertyResult: "/result",
  floorDetails: "/floorDetails",
  residentTableDetail: "/residentdetails",
  ownerTableDetail: "/ownerdetails",
  unitrentallbreakup: "/unitrentallbreakup",
  unitrentallbreakupcreate: "/unitrentallbreakupcreate",
  unitrentallbreakupuntit: "/unitrentallbreakupuntit",
  shortlisted: "/shortlistedunits",
  shortlistLead: "/shortlistLead",
  utilities: "/utilities",
  quotationsList: "/quotationsList",
  utilitiesMapping: "/utilitiesMapping",
  utilitiesMappingPropertyView: "/utilitiesMapping/propertyView",
  utilitiesMappingUnitView: "/utilitiesMapping/unitView",
  amenities: "/amenities",
  amenitiesMapping: "/amenitiesMapping",
  amenitiesMappingPropertyView: "/amenitiesMapping/propertyView",
  amenitiesMappingUnitView: "/amenitiesMapping/unitView",
  dasdboard: "/dashboard",
  clientDashboard: "/clientDashboard",
  salesDashboard: "/salesDashboard",
  WorkspaceManagerDashboard: "/workspaceManagerDashboard",
  inspectionDashboard: "/inspectionDashboard",
  operationsDashboard: "/operationsDashboard",
  facilityDashboard: "/facilityDashboard",
  financeDashboard: "/financeDashboard",
  //community manager
  communitycard: "/communitycard",
  managementcommittee: "/managementcommittee",
  managementcommitteeView: "/managementcommitteeView",
  master: "/masters",
  communication: "/communication",
  configuration: "/configuration",
  configuration_details: "/configuration/:id",
  configuration_add: "/configuration/add",
  //security manager
  masterData: "/masterdata",
  parkingSlot: "/parkingSlot",
  parkingSlotMaster: "/parkingSlotMaster",
  visitorAndWorkedGatePass: "/visitorAndWorkedGatePass",
  deliveryOrderCollection: "/deliveryOrderCollection",
  domesticHelpRequest: "/domesticHelpRequest",
  vendorEntry: "/vendorEntry",
  serviceProvider: "/serviceProvider",
  sample1: "/sample1",
  sample2: "/sample2",
  sample3: "/sample3",
  createBlockNew: "/createBlockNew",
  createFloorNew: "/createFloorNew",
  createUnitNew: "/createUnitNew",
  companyMaster: "/masters/business_master/Company",
  pricingComponent: "/pricing_component",
  propertyType: "/masters/property_master/property_type",
  blockType: "/masters/property_master/block_tType",
  unitType: "/masters/property_master/unit_type",
  taxGroup: "/masters/business_master/tax_group",
  taxItem: "/masters/business_master/tax_item",
  masters: "/masters/:type/:title",
  propertyMaster: "/propertymaster",
  companyCreation: "/company_creation",
  editcompany: "/company/:id",

  sample4: "/sample4",
  configure: "/configure",
  pricingTable: "/pricingTable",
  pricingTablePropertyView: "/pricingTable/propertyView",
  pricingTableUnitView: "/pricingTable/unitView",
  pricingTableAll: "/pricingTable/all",

  vendorListing: "/vendors",
  vendorCreation: "/vendor_creation",
  editVendor: "/vendor/:id",

  salesagent: "/salesagent",
  salesview: "/salesview",
  salesagentlist: "/salesagentlist",
  countryMaster: "/masters/business_master/country",
  currencyMaster: "/masters/business_master/currency",
  generalCategory: "/masters/service_master/general",
  generalSubCategory: "/masters/service_master/general_sub_category",
  maintenanceCategory: "/masters/service_master/maintenance",
  maintenanceSubCategory: "/masters/service_master/maintenance_sub_category",
  activityCategory: "/activity_category",
  activitySubCategory: "/activity_sub_category",
  leadnew: "/leadnew",
  contactsList: "/contactsList",
  contactView: "/contactView",
  createContact: "/createContact",
  aggreements: "/aggreements",
  agreementView: "/aggreementView",
  companyselect: "/companyselect",
  welcome: "/welcome",
  setpassword: "/setpassword",
  dashboardSample: "/dashboardSample",
  jobs: "/jobs",
  department: "/department",
  parkingArea: "/parkingArea",
  accessGates: "/accessGates",
  accessGatesPropertyView: "/accessGates/propertyView",
  unit_vacancy: "/unit_vacancy",
  vacancy_details_id: "/vacancy_details/:id",
  vacancy_details: "/vacancy_details",

  leasemanagerdashboard: "/leasemanagerdashboard",
  securitydashboard: "/securitydashboard",
  serviceProviderListing: "/serviceProviderListing",
  serviceProviderView: "/serviceProviderView",
  serviceProviderCreateEdit: "/serviceProviderCreateEdit",
  createOpportunity: "/create_opportunity",
  profession: "/profession",
  emergencyContact: "/emergencyContact",
  domesticHelperList: "/domesticHelperList",
  domesticHelperView: "/domesticHelperView",
  domesticHelperCreateEdit: "/domesticHelperCreateEdit",
  accounts: "/accounts",
  createAccount: "/create_account",
  accountDetails: "/account_details",
  agreementTermination: "/agreementTermination",
  userManagement: "/userManagement",
  invoice: "/invoice",
  invoiceView: "/invoiceView",
  clientSettings: "/clientSettings",
  propertydashboard: "/propertydashboard",
  leadview: "/leadview",
  shortTermPricing: "/shortTermPricing",
  shortTermPricingPropertyView: "/shortTermPricing/propertyView",
  shortTermPricingUnitView: "/shortTermPricing/unitView",
  deliveryMilestone: "/deliveryMilestone",
  deliveryMilestoneTemplate: "/deliveryMilestoneTemplate",
  createDeliveryMilestoneTemplate: "/createDeliveryMilestoneTemplate",
  communityDashboard: "/communityDashboard",
  dbTask: "/dbTask",
  mapPropertyDetails: "/mapPropertyDetails",
  mapUnitDetails: "/mapUnitDetails",
  propspect: "/propspect",
  profileView: "/profileView",
  profileEdit: "/profileEdit",
  agreementRenewal: "/agreementRenewal",
  maintancerequest: "/maintenanceRequest",
  createmaintanceRequest: "/createmaintanceRequest",
  generalRequest: "/generalRequest",
  creategeneralrequest: "/creategeneralrequest",
  unitVacancyPropertyList: "/unitVacancy/propertyList",
  unitVacancyAll: "/unitVacancy/all",
  unitVacancyPropertyView: "/unitVacancy/propertyView",
  receipt: "/receipt",
  createReceipt: "/createReceipt",
  receiptView: "/receiptView",
  kyclist: "/kyclist",
  pricingAppraisal: "/pricingAppraisal",

  // INSPECTION
  //move-in
  moveInInspectionOrders: "/moveInInspectionOrders",
  moveInInspectionDetails: "/moveInInspectionDetails",
  moveInInspectionItemDetails: "/moveInInspectionItemDetails",
  //move-out
  moveOutInspectionOrders: "/moveoutInspectionOrders",
  moveOutInspectionDetails: "/moveOutInspectionDetails",
  moveOutInspectionItemDetails: "/moveOutInspectionItemDetails",
  //unit-handOver
  unitHandOverInspectionOrders: "/unitHandOverInspectionOrders",
  unitHandOverInspectionDetails: "/inspectionDetails",
  unitHandOverInspectionItemDetails: "/inspectionItemDetails",
  //others
  inspectionItems: "/inspectionItems",
  insItemPropertyDetails: "/insItemPropertyDetails",
  inspectionMapping: "/inspectionMapping",
  inventoryMaster: "/inventoryMaster",
  resourceMaster: "/resourceMaster",
  inspectionMaster: "/inspectionMaster",
  resourceBoard: "/resourceBoard",
  scheduleBoard: "/scheduleBoard",
  employeesBooking: "/employeesBooking",
  rentalIndex: "/rentalIndex",
  vehicleMaster: "/vehicleMaster",
  workingHoursMaster: "/workingHoursMaster",
  toolMaster: "/toolMaster",
  alreadypaid: "/alreadypaid",
  reservation: "/reservation",
  reservationDetails: "/reservationDetails",
  amenitiesBooking: "/amenitiesBooking",
  manageInvoice: "/manageInvoice",
  domesticContactsList: "/domesticContactsList",
  statementOfAccount: "/statementOfAccount",
  customerAccountList: "/customerAccountList",
  vendorAccountCreate: "/vendorAccountCreate",
  vendorAccountList: "/vendorAccountList",
  reports: "/reports",
  report: "/report",
  fileManager: "/fileManager",
  ownerAccountCreate: "/ownerAccountCreate",
  ownerAccountList: "/ownerAccountList",
  managementCommitteRoleMaster: "/managementCommitteRoleMaster",
  vendorAccountDetail: "/vendorAccountDetail",
  ownerAccountDetails: "/ownerAccountDetails",
  customerAccountDetails: "/customerAccountDetails",
  parkingBooking: "/parkingBooking",
  parkingGroupingMaster: "/parkingGroupingMaster",
  announcement_details: "/announcement_details",
  maintanceRequestView: "/maintanceRequestView",
  inviteTrigger: "/inviteTrigger",
  freeTextInvoice: "/freeTextInvoice",
  createInvoiceFreeText: "/createInvoiceFreeText",
  teams: "/teams",
  timeOffRequest: "/timeOffRequest",
  resourceBookingDetails: "/resourceBookingDetails",
  serviceInspection: "/service_inspection",
  viewServiceInspection: "/view_service_inspection",
  settlements: "/settlements",
  generalRequestView: "/generalRequestView",
  kycDetails: "/kycDetails",
  kycDetailView: "/kycDetailView",
  siteVisit: "/siteVisit",
  viewSiteVisitDetails: "/viewSiteVisitDetails",
  generalInspection: "/general_inspection",
  viewGeneralInspection: "/view_general_inspection",
  unitReadiness: "/unitReadiness",
  viewUnitReadinessDetails: "/viewUnitReadinessDetails",
  timeOffResources: "/timeOffRequestDetails",
  inspectionFeedbackCategory: "/inspectionFeedBackCategory",
  inspectionFeedbackSubcategory: "/inspectionFeedbackSubcategory",
  activityBoard: "/activityBoard",
  resetyourpassword: "/resetyourpassword",
  correspondences: "/correspondences",
  create_correspondences: "/create_correspondences",
  edit_correspondences: "/edit_correspondences",
  contract: "/contract",
  contractCreateEdit: "/contractCreateEdit",
  shortlistedoppertunityunits: "/shortlistedoppertunityunits",
  publicListing: "/publicListing",
  utilityReadings: "/utilityReadings",
  deliveryOrders: "/deliveryOrders",
  viewDeliveryOrders: "/viewDeliveryOrders",
  customerFeedBack: "/customerFeedBack",
  createOppertunityQuotation: "/createOppertunityQuotation",
  oppertunityQuotation: "/oppertunityQuotation",
  amenityMappingNew: "/amenityMappingNew",
  checkAvailability: "/checkAvailability",
  propertyBoard: "/propertyBoard",
  creditNote: "/creditNote",
  debitNote: "/debitNote",
  formList: "/formList",
  formBuilder: "/formBuilder",
  unitBlocking: "/unitBlocking",
  shareUnit: "/shareUnit",
  serviceHubDashboard: "/serviceHubDashboard",
  residentOnboarding: "/residentOnboarding",
  residentOnboardingViewEdit: "/residentOnboardingViewEdit",
  kyc_document_type: "/kyc_document_type",
  correspondence_type: "/correspondence_type",
  amenity_category: "/amenity_category",
  marketingConfiguration: "/marketingConfiguration",
  leave_master: "/leave_master",
  import: "/import",
  import_file_upload: "/import_file_upload",
  listingDashBoard: "/listingDashBoard",
  contractDashboard: "/contractDashboard",
  backup_restore: "/backup_restore",
  finalSettlement: "/finalSettlement",
  finalSettlementView: "/finalSettlementView",
  welcome_banner: "/welcome_panner",
  dashboard_banner: "/dashboard_banner",
  community_banner: "/community_banner",
  logo_apperence: "/logo_apperence",
  subscribe: "/subscribe",
  correspondencesView: "/correspondencesView",
  amenity_board: "/amenity_board",
  restoreErrorHandling: "/restoring_inProgress",
  costingApprovalRequests: "/costingApprovalRequests",
  contract360: "/contract360",
  announcementBoard: "/announcementBoard",
  ExploreTemplate: "/explore",
  item_category_master: "/item_category_master",
  manufacturer_master: "/manufacturer_master",
  unit_item_location_master: "/unit_item_location_master",
  item_sub_category_master: "/item_sub_category_master",
  occupants: "/occupants",
  checkListGroup: "/checkListGroup",
  checkListGroupView: "/checkListGroupView",
  checkListItemView: "/checkListItemView",
  myOrg: "/myOrg",
  managementRoleMaster: "/managementRoleMaster",
  functionalLocation: "/functionalLocation",
  functionalHeirarchy: "/functionalHeirarchy",
  internal_lead_source: "/internal_lead_source",
  tenantOnboarding: "/tenantOnboarding",
  dutyRoster: "/dutyRoster",
  customQuote: "/customQuote",
  selfCheckInEntries: "/selfCheckInEntries",
  ledgerMaster: "/ledgerMaster",
  subLedgerMaster: "/subLedgerMaster",
  COAMaster: "/COAMaster",
  amenityDashboard: "/amenityDashboard",
  utilityDashboard: "/utilityDashboard",
  resourceManagerDashboard: "/resourceManagerDashboard",
  customer_group_master: "/customer_group_master",
  vendor_group_master: "/vendor_group_master",
  expense_group_master: "/expense_group_master",
  quickLeaseOnboarding: "/quickLeaseOnboarding",
  fixedMaintenancePlanner: "/fixedMaintenancePlanner",
  createMaintenancePlanner: "/createMaintenancePlanner",
  viewMaintenancePlanner: "/viewMaintenancePlanner",
  dynamicMaintenancePlanner: "/dynamicMaintenancePlanner",
  utilities_category: "/utilities_category",
  customerAccountCreate: "/customerAccountCreate",
  cashBankAccountList: "/cashBankAccountList",
  cashBankAccountCreate: "/cashBankAccountCreate",
  cashBankAccountDetails: "/cashBankAccountDetails",
  marketPlace: "/marketPlace",
  marketPlaceView: "/marketPlaceView",
  surveyForm: "/surveyForm",
  createSurveyForm: "/createSurveyForm",
  schedulePlanView: "/schedulePlanView",
  scheduledWorkOrderView: "/scheduledWorkOrderView",
  executedWorkOrderView: "/executedWorkOrderView",
  executedJobsView: "/executedJobsView",
  project: "/project",
  schedulePlan: "/schedulePlan",
  scheduledWorkOrder: "/scheduledWorkOrder",
  executedWorkOrder: "/executedWorkOrder",
  executedJobs: "/executedJobs",
  resourceGroupMaster: "/resourceGroupMaster",
  projectCategory: "/projectCategory",
  videoRecorder: "/videoRecorder",
  paymentDashboard: "/paymentDashboard",
};

export const BackendRoutes = {
  working_hours_master_list: "/working_hours_master/list",
  correspondences_list: "/correspondences/list",
  correspondences_delete: "/correspondences/delete",
  correspondences_publish: "/correspondences/publish",
  agreement_unit_info_agreement_unit_for_property:
    "/agreement-unit-info/agreement_unit_for_property",
  correspondences_create: "/correspondences/create",
  correspondences_update: "/correspondences/update",
  account_statistics: "/account/statistics",
  listing_property_listing: "/listing/property_listing",
  listing_unit_listing: "/listing/unit_listing",
  revoke_unit: "/revoke/unit",
  listing_mapping_get_user_accessed_property:
    "/listing_mapping/get_user_accessed_property",
  sync_global_property_type: "/sync/global_property_type",
  listing_mapping_update_property_sync: "/listing_mapping/update_property_sync",
  listing_mapping_get_units: "/listing_mapping/get_units",
  listing_mapping_update_unit_sync: "/listing_mapping/update_unit_sync",
  sync_global_unit_type: "/sync/global_unit_type",
  contact_agreement_contact: "/contact/agreement_contact",
  block: "/block",
  floor: "/floor",
  property_get_all_properties: "/property_board/getAllProperties",
  property_board: "/property_board",
  property_board_unit_details: "/property_board/unit_details",
  property_board_property_details: "/property_board/property_details",
  unit_upsert: "/unit/upsert",
  domestic_helper_domestic_helper_entries:
    "/domestic_helper/domestic_helper_entries",
  masters_listing_get: "/masters_listing/get",
  masters_upsert_identification_master: "/masters_upsert/identification_master",
  masters_upsert_correspondences_type_master:
    "/masters_upsert/correspondences_type_master",
  masters_upsert_amenity_category: "/masters_upsert/amenity_category",
  masters_upsert_leave_master: "/masters_upsert/leave_master",
  import_get_categories: "/import/get_categories",
  import_get_sub_categories: "import/get_sub_categories",
  import_get_import_list: "/import/get_import_list",
  import_upload_file: "/import/upload_file",
  import_upsert: "/import/upsert",
  system_backup_list: "/system/backup_list",
  system_upsert: "/system/create_backup",
  system_restore_backup: "/system/restore_backup",
  sales_dashboard: "/sales_dashboard",
  sales_dashboard_proxy_lead: "/sales_dashboard/proxy_lead",
  sales_dashboard_oppertunity: "/sales_dashboard/oppertunity",
  sales_dashboard_oppertunity_revenue: "/sales_dashboard/oppertunity_revenue",
  sales_dashboard_perform_team_by_person:
    "/sales_dashboard/perform_team_by_person",
  sales_dashboard_perform_by_team: "/sales_dashboard/perform_by_team",
  workspace_dashboard: "/workspace_dashboard",
  workspace_dashboard_proxy_lead: "/workspace_dashboard/proxy_lead",
  workspace_dashboard_oppertunity: "/workspace_dashboard/oppertunity",
  workspace_dashboard_oppertunity_revenue: "/workspace_dashboard/oppertunity_revenue",
  workspace_dashboard_perform_team_by_person: "/workspace_dashboard/perform_team_by_person",
  workspace_dashboard_perform_by_team: "/workspace_dashboard/perform_by_team",
  dashboard_security_stats: "/dashboard/security/stats",
  security_manager: "/security_manager",
  security_manager_people_visited: "/security_manager/people_visited",
  security_manager_entries_check_in_and_out:
    "/security_manager/entries_check_in_and_out",
  security_manager_on_duty_info: "/security_manager/on_duty_info",
  security_manager_entries_for_all: "/security_manager/entries_for_all",
  subscription_plan_usage: "/subscription/plan_usage",
  subscription_modules: "/subscription/modules",
  subscription_get_all_plans: "/subscription/get_all_plans",
  get_facility: "/listing_mapping/get_facility",
  listing_mapping_update_facility_sync: "/listing_mapping/update_facility_sync",
  listing_facility_listing: "/listing_mapping/facility_listing",
  listing_facility_revoke: "/listing_mapping/facility_revoke",
  contract_dashboard: "/contract_dashboard",
  contract_dashboard_schedule_vs_invoice_vs_receipt:
    "/contract_dashboard/schedule_vs_invoice_vs_receipt",
  subscription_basic_info: "/subscription/basic_info",
  upload_customer: "/subscription/update_info",
  reports_get_module_reports: "/reports/get_module_reports",
  reports_get_report: "/reports/get_report",
  sync_global_amenity_category: "/sync/global_amenity_category",
  domestic_helper_update_domestic_helper_status:
    "/domestic_helper/update_domestic_helper_status",
  inspection_item_category_get: "/inspection_item_category/get",
  masters_upsert_item_category: "/masters_upsert/item_category",
  manufacturer_master_get: "/manufacturer_master/get",
  masters_upsert_manufacturer: "/masters_upsert/manufacturer",
  inspection_item_location_master_get: "/inspection_item_location_master/get",
  masters_upsert_item_location: "/masters_upsert/item_location",
  inspection_item_subcategory_get: "/inspection_item_subcategory/get",
  masters_upsert_item_sub_category: "/masters_upsert/item_sub_category",
  occupants_get: "/occupants/get",
  unit_get_list: "/unit/get_list",
  login_domain_initial_render: "/login_domain/initial_render",
  client_terms_and_conditions: "/client/terms_and_conditions",
  auth_forgotpassword: "/auth/forgotpassword",
  login_domain_domain_list: "/login_domain/domain_list",
  inspection_items_getAll: "/inspection_items/getAll",
  inspection_item_mapping_getAll: "/inspection_item_mapping/getAll",
  internal_lead_source_get_all: "/internal_lead_source/get_all",
  internal_lead_source_upsert: "/internal_lead_source/upsert",
  lead_proxy_upsert: "/lead_proxy/upsert",
  client_get_client_configuration: "/client/get_client_configuration",
  client_update_client: "/client/update_client",
  function_location_list: "/function_location/list",
  request_outsource_work_update: "/request/outsource_work_update",
  request_template: "/request/template",
  file_upload_delete: "/file-upload/delete",
  resource_dashboard_getCount: "/resource_dashboard/getCount",
  resource_dashboard_get_resource: "/resource_dashboard/get_resource",
  inspection_manager_get_resource_jobs: "/inspection_manager/get_resource_jobs",
  inspection_manager_time_off_data: "/inspection_manager/time_off_data",
  inspection_manager_get_resource_vs_jobs:
    "/inspection_manager/get_resource_vs_jobs",
  inspection_manager_actual_vs_other_vs_projected:
    "/inspection_manager/actual_vs_other_vs_projected",
  property_manager: "/property_manager",
  property_manager_purpose_area_statistics:
    "/property_manager/purpose_area_statistics",
  property_manager_occpancy_vs_property:
    "/property_manager/occpancy_vs_property",
  client_get_app_configuration: "/client/get_app_configuration",
  property_board_getPropertyHierarchyLabel:
    "/property_board/getPropertyHierarchyLabel",
  customer_group_master_list: "/customer_group_master/list",
  customer_group_master_upsert: "/customer_group_master/upsert",
  customer_group_master_get_id: "/customer_group_master/get_id",
  vendor_group_master_list: "/vendor_group_master/list",
  vendor_group_master_upsert: "/vendor_group_master/upsert",
  vendor_group_master_get_id: "/vendor_group_master/get_id",
  expense_group_master_list: "/expense_group_master/list",
  expense_group_master_upsert: "/expense_group_master/upsert",
  expense_group_master_get_id: "/expense_group_master/get_id",
  client_manager: "/client_manager",
  utilities_category_list: "/utilities/category-list",
  utilities_upsert_category: "/utilities/upsert-category",
  utilities_utility_master_list: "/utilities/utility_master_list",
  service_provider_list: "/service-provider/list",
  uom_master_list: "/uom_master/list",
  vat_group_list: "/vat-group/list",
  utilities_upsert: "/utilities/upsert",
  subscription_subscribe_modules: "/subscription/subscribe_modules",
  sales_dashboard_lead_owner_group: "/sales_dashboard/lead_owner_group",
  sales_dashboard_quotations_owner_group: "/sales_dashboard/quotations_owner_group",
  workspace_dashboard_lead_owner_group: "/workspace_dashboard/lead_owner_group",
  workspace_dashboard_quotations_owner_group: "/workspace_dashboard/quotations_owner_group",
  property_manager_unit_category_dashboard: "/property_manager/unit_category_dashboard",
  finance_dashboard_get_invoice_type: "/finance_dashboard/get_invoice_type",
  inspection_item_category: "/inspection_item_category",
  free_text_invoice_get_free_text_item_types:
    "/free_text_invoice/get_free_text_item_types",
  utilities_utility_mapping_list: "/utilities/utility_mapping_list",
  unit_utils_upsert: "/unit-utils/upsert",
  queries_user_profile_get: "/queries/user_profile/get",
  queries_company_master_get: "/queries/company_master/get",
  queries_pricing_component_get: "/queries/pricing_component/get",
  queries_pricing_component_upsert: "/queries/pricing_component/upsert",
  queries_pricing_component_get_tax_group:
    "/queries/pricing_component/get_tax_group",
  queries_property_by_company: "/queries/property/by_company",
  queries_property_type_get: "/queries/property_type/get",
  queries_pricing_table_get: "/queries/pricing_table/get",
  queries_property_get: "/queries/property/get",
  queries_unit_category_get: "/queries/unit_category/get",
  queries_unit_type_get: "/queries/unit_type/get",
  queries_unit_get: "/queries/unit/get",
  queries_pricing_table_upsert: "/queries/pricing_table/upsert",
  proxy_lead_units_get_proxy_lead_units_by_proxy_lead_id:
    "/proxy_lead_units/get_proxy_lead_units_by_proxy_lead_id",
  lead_proxy_get_third_party: "/lead_proxy/get/third_party",
  proxy_lead_checklist_get_proxy_lead_checklist_by_proxy_lead_id:
    "/proxy_lead_checklist/get_proxy_lead_checklist_by_proxy_lead_id",
  proxy_lead_activities_get_proxy_lead_activities_by_proxy_lead_id:
    "/proxy_lead_activities/get_proxy_lead_activities_by_proxy_lead_id",
  user_profile_link_microsoft_account: "/user-profile/link_microsoft_account",
  user_profile_upsert: "/user-profile/upsert",
  user_profile_get_microsoft_account_details:
    "/user-profile/get_microsoft_account_details",
  user_profile_link_google_account: "/user-profile/link_google_account",
  user_profile_get_google_account_details:
    "/user-profile/get_google_account_details",
  lead_proxy_get_comments: "/lead_proxy/get_comments",
  lead_proxy_upsert_comments: "/lead_proxy/upsert_comments",
  proxy_lead_checklist_upsert_proxy_lead_checklist:
    "/proxy_lead_checklist/upsert_proxy_lead_checklist",
  opportunity_get_comments: "/opportunity/get_comments",
  opportunity_upsert_comments: "/opportunity/upsert_comments",
  app_build: "/app_build",
  contact_agreement_contact_info: "/contact/agreement_contact_info"
}
