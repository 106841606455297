import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Subheader } from "../../components";
import { useStyles } from "./style";
import { AuthContext, BackdropContext } from "../../contexts";
import ImageViewer from "react-simple-image-viewer";
import {
  NetWorkCallMethods,
  accessCheckRender,
  getRoutePermissionNew,
  useWindowDimensions,
} from "../../utils";
import { Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { returnValue } from "../companyCreation/utils";
import { TitleDesc } from "../companyCreation/components";
import { withNamespaces } from "react-i18next";

const ServiceProviderView = (props) => {
  // const modules = { toolbar: false }
  const { t } = props;
  const history = useHistory();
  const size = useWindowDimensions();
  const classes = useStyles(size);
  const search = useLocation().search;
  const serviceProviderID = props?.id
    ? props?.id
    : new URLSearchParams(search).get("serviceProviderID");

  // useContext
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);

  // useState
  const [data, setData] = React.useState({});
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");
  const [permission, setPermission] = React.useState({});

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getInitialData();
      }
    }

    // eslint-disable-next-line
  }, [auth]);

  React.useEffect(() => {
    if (props?.data) {
      getServiceProvider();
    }
    // eslint-disable-next-line
  }, [props?.data]);

  // Function to open ImageViewer
  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  // Function to close ImageViewer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  // Function to get service provider details
  const getServiceProvider = () => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });

    const payload = { id: props?.data?.id }

    NetworkCall(
      `${config.api_url}/queries/contact/service_provider/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setData(res?.data?.data?.service_providers_master?.[0]);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInitialData = () => {
    if (serviceProviderID) {
      getServiceProvider();
    }
    //  else { history.goBack(-1) }
  };

  // Google Map
  // const GoogleMap = React.useCallback(() => {
  //     return (<MyGoogleMap
  //         lat={data?.service_providers_addressByID?.[0]?.latitude}
  //         lng={data?.service_providers_addressByID?.[0]?.longitude}
  //         center={{
  //             lat: data?.service_providers_addressByID?.[0]?.latitude,
  //             lng: data?.service_providers_addressByID?.[0]?.longitude
  //         }}
  //         zoom={13}
  //         readonly />)
  // }, [data])

  // Function to handle edit button
  const handleEdit = () => {
    history.push({
      pathname: Routes.serviceProviderCreateEdit,
      state: {
        main: {
          company: data?.companyByID,
          companyID: data?.companyByID?.id,
          serviceProviderID: serviceProviderID,
        },
      },
    });
  };

  const render = () => {
    return (
      <>
        <Subheader
          title={props?.hideSubheader ? "" : t("Service Provider")}
          goBack={() => history.goBack(-1)}
          hideBackButton={props?.hideSubheader ? true : false}
          isEdit={permission.update}
          handleEdit={handleEdit}
        />
        <Grid container className={classes.content}>
          <Grid container className={classes.profileDetailCard}>
            <Grid item md={2} lg={2} className={classes.profileImageDiv}>
              <img
                src={data?.image_url ?? "/images/imagesproperty.svg"}
                alt=""
                className={classes.image}
                onClick={() => openImageViewer([data?.image_url])}
              />
              {isViewerOpen && (
                <ImageViewer
                  src={currentImage}
                  currentIndex={0}
                  onClose={closeImageViewer}
                  disableScroll={false}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                  }}
                  closeOnClickOutside={true}
                />
              )}
            </Grid>
            <Grid item md={9} lg={10} className={classes.profileDetailDiv}>
              <Typography mb={2} className={classes.title}>
                {t("Profile Details")}
              </Typography>
              <Grid container>
                {/* <Grid item md={12} lg={12}> */}
                {/* <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px"> */}
                <Grid item xs={6} md={6} lg={3}>
                  <Typography className={classes.heading}>
                    {t("Company Name")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.companyByID?.name ?? "-"}
                  </Typography>
                </Grid>
                <Grid xs={6} md={6} lg={3}>
                  <Typography className={classes.heading}>
                    {t("Service Provider Category")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.profession_masterByID?.name ?? "-"}
                  </Typography>
                </Grid>
                <Grid xs={6} md={6} lg={3}>
                  <Typography className={classes.heading}>
                    {t("Service Provider Name")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.name ?? "-"}
                  </Typography>
                </Grid>
                <Grid xs={6} md={6} lg={3}>
                  <Typography className={classes.heading}>
                    {t("Service Provider Code")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.service_provider_code ?? "-"}
                  </Typography>
                </Grid>
                {/* </Box> */}
                {/* </Grid> */}
              </Grid>
              <Box marginTop="10px">
                <Typography className={classes.heading}>
                  {t("Description")}
                </Typography>
                {data?.description && (
                  <TitleDesc
                    title={"Description"}
                    dangerouslySetInnerHTML={returnValue(
                      data?.description,
                      "-"
                    )}
                  />
                )}
              </Box>
            </Grid>
            <Grid item md={0} lg={4.5} />
          </Grid>
          <Grid container className={classes.card}>
            <Grid container spacing={2}>
              {/* <Grid item xs={5}>
                    <div className={classes.mapDiv}>
                        <GoogleMap />
                    </div>
                </Grid> */}
              {/* when map component is needed plz use below commented grid item */}
              {/* <Grid item xs={3}> */}
              <Grid item xs={6}>
                <div className={classes.contactDiv}>
                  <Stack direction="row">
                    <img src="/images/loc.svg" alt="" />
                    <Typography className={classes.title}>
                      &nbsp;{t("Address")}
                    </Typography>
                  </Stack>
                  <Box height="12px" />
                  {data?.service_providers_addressByID?.[0]?.door_no === "" &&
                  data?.service_providers_addressByID?.[0]?.street_1 === "" &&
                  data?.service_providers_addressByID?.[0]?.street_2 === "" &&
                  data?.service_providers_addressByID?.[0]?.landmark === "" &&
                  data?.service_providers_addressByID?.[0]?.area === "" &&
                  data?.service_providers_addressByID?.[0]?.city === "" &&
                  data?.service_providers_addressByID?.[0]?.state === "" &&
                  data?.service_providers_addressByID?.[0]?.country === "" &&
                  data?.service_providers_addressByID?.[0]?.pincode === null ? (
                    <Typography className={classes.sub}>-</Typography>
                  ) : (
                    <Typography className={classes.sub}>
                      {data?.service_providers_addressByID?.[0]?.door_no +
                        (data?.service_providers_addressByID?.[0]?.door_no
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.street_1 +
                        (data?.service_providers_addressByID?.[0]?.street_1
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.street_2 +
                        (data?.service_providers_addressByID?.[0]?.street_2
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.landmark +
                        (data?.service_providers_addressByID?.[0]?.landmark
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.area +
                        (data?.service_providers_addressByID?.[0]?.area
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.city +
                        (data?.service_providers_addressByID?.[0]?.city
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.state +
                        (data?.service_providers_addressByID?.[0]?.state
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.country +
                        (data?.service_providers_addressByID?.[0]?.country
                          ?.length > 0
                          ? ","
                          : "") ?? ""}
                      {data?.service_providers_addressByID?.[0]?.pincode ?? ""}
                    </Typography>
                  )}

                  <Box height="16px" />
                  {/* <Stack direction="row" >
                                    <Typography className={classes.heading}>Latitude : </Typography>
                                    <Typography className={classes.sub1}>
                                        &nbsp;{data?.service_providers_addressByID?.[0]?.latitude}
                                    </Typography>
                                </Stack>
                                <Box height="12px" />
                                <Stack direction="row" >
                                    <Typography className={classes.heading}>Longitude : </Typography>
                                    <Typography className={classes.sub1}>
                                        &nbsp;{data?.service_providers_addressByID?.[0]?.longitude}
                                    </Typography>
                                </Stack> */}
                </div>
              </Grid>
              {/* when map component is needed plz use below commented grid item */}
              {/* <Grid item xs={4}> */}
              <Grid item xs={6}>
                <div className={classes.contactDiv}>
                  <Stack direction="row">
                    <img src="/images/Group 7015.svg" alt="" />
                    <Typography className={classes.title}>
                      &nbsp;{t("Contact & Other Information")}
                    </Typography>
                  </Stack>
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <Box marginTop="18px">
                        <Typography className={classes.heading}>
                          {t("Primary Mobile")} :{" "}
                        </Typography>
                        <Typography className={classes.subMob}>
                          {data?.service_providers_addressByID?.[0]?.mobile_no
                            ? data?.service_providers_addressByID?.[0]
                              ?.mobile_code +
                            (data?.service_providers_addressByID?.[0]
                              ?.mobile_code
                              ? " "
                              : "") +
                            data?.service_providers_addressByID?.[0]
                              ?.mobile_no
                            : ""}
                        </Typography>
                      </Box>
                      <Box marginTop="12px">
                        <Typography className={classes.heading}>
                          {t("Email Address")} :{" "}
                        </Typography>
                        <Typography className={classes.sub}>
                          {data?.service_providers_addressByID?.[0]?.email_id
                            ?.length > 0
                            ? data?.service_providers_addressByID?.[0]?.email_id
                            : "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box marginTop="18px">
                        <Typography className={classes.heading}>
                          {t("Primary Telephone")} :{" "}
                        </Typography>
                        <Typography className={classes.subMob}>
                          {data?.service_providers_addressByID?.[0]
                            ?.telephone_no
                            ? data?.service_providers_addressByID?.[0]
                              ?.telephone_code +
                            (data?.service_providers_addressByID?.[0]
                              ?.telephone_code
                              ? " "
                              : "") +
                            data?.service_providers_addressByID?.[0]
                              ?.telephone_no
                            : ""}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.card}>
            <Typography className={classes.title}>
              {t("Banking Basic Details")}
            </Typography>
            <Grid item md={12}>
              <Grid container marginTop={"8px"}>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading}>
                    {t("Bank Name")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.bank_name ? data?.bank_name : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading}>
                    {t("Branch Name")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.branch_name ? data?.branch_name : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading}>
                    {t("Currency")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.currency_masterByID?.code
                      ? data?.currency_masterByID?.code
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Bank Account Type")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.account_type ? data?.account_type : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Account Number")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.account_no ? data?.account_no : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Bank Routing Type")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.routing_type ? data?.routing_type : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container marginTop={"8px"}>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Bank Routing Code")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.routing_code ? data?.routing_code : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Preferred Cash Collection Office")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.cash_collection_office
                      ? data?.cash_collection_office
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Cheque Name")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.cheque_name ? data?.cheque_name : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Address Line 1")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.address_1 ? data?.address_1 : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Address Line 2")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.address_2 ? data?.address_2 : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("City")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.bank_city ? data?.bank_city : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container marginTop={"8px"}>
                <Grid item xs={2} md={4} lg={4}>
                  <Typography className={classes.heading} marginTop={"16px"}>
                    {t("Country")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.bank_country ? data?.bank_country : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.addressDiv2}>
            <Grid item md={12}>
              <Grid container>
                <Grid item xs={2} md={3} lg={3}>
                  <Typography className={classes.heading}>
                    {t("Company Registration")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.company_registration
                      ? data?.company_registration
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={3} lg={3}>
                  <Typography className={classes.heading}>
                    {t("Company Registration Number")}
                  </Typography>
                  <Typography className={classes.sub}>
                    {data?.company_registration_no
                      ? data?.company_registration_no
                      : "-"}
                  </Typography>
                </Grid>
                {/* <Grid item xs={2}>
                                <Typography className={classes.heading}>Facebook</Typography>
                                <Typography className={classes.sub}>{data?.facebook ? data?.facebook : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Twitter</Typography>
                                <Typography className={classes.sub}>{data?.twitter ? data?.twitter : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Linkedin</Typography>
                                <Typography className={classes.sub}>{data?.linkedin ? data?.linkedin : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Whatsapp</Typography>
                                <Typography className={classes.sub}>{data?.whatsapp ? data?.whatsapp : "-"}</Typography>
                            </Grid> */}
              </Grid>
              {/* <Grid container marginTop={"8px"}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>No. Of Visits/Entries For the Month</Typography>
                                <Typography className={classes.sub}>
                                    {data?.swift_in_out_logByID?.[0]?.count ? data?.swift_in_out_logByID?.[0]?.count : "-"}
                                </Typography>
                            </Grid>
                        </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};
export default withNamespaces("serviceProviderView")(ServiceProviderView);
