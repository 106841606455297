import { Avatar, Stack, Typography } from "@mui/material"
import { concat_string, stringAvatar, timeZoneConverter } from "../../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const ContractCard = ({
    classes = {},
    data = {},
    onClick = () => false,
}) => {
    return <Stack direction={"row"} columnGap={"16px"} p={"8px"}
        justifyContent={"space-between"} alignItems={"center"}
        sx={{ cursor: "pointer" }} onClick={() => onClick(data)}>
        <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}>
            <Avatar className={classes.optionAvatar}
                {...stringAvatar(data?.contract_no)}></Avatar>
            <Stack>
                <Stack direction={"row"} columnGap={"8px"} divider={<Typography className={classes.optionSubTitle}>{", "}</Typography>}>
                    <Typography className={classes.optionHeader}>
                        {data?.contract_no ?? "-"}
                    </Typography>
                    <Typography className={classes.optionSubTitle}>
                        {concat_string(
                            {
                                start_date: data?.start_date ? timeZoneConverter(data?.start_date) : "",
                                end_date: data?.end_date ? timeZoneConverter(data?.end_date) : "",
                            },
                            ["start_date", "end_date"], " - ", "-")}
                    </Typography>
                </Stack>
                <Stack direction={"row"} columnGap={"8px"} divider={<Typography className={classes.optionSubTitle}>{", "}</Typography>}>
                    <Typography className={classes.optionSubTitle}>
                        {concat_string(
                            {
                                period_value: data?.period_value?.toString(),
                                period: data?.period,
                            },
                            ["period_value", "period"], " ", "-")}
                    </Typography>
                    <Typography className={classes.optionSubTitle}>
                        {concat_string(
                            {
                                vendor_no: data?.vendor?.vendor_no,
                                name: data?.vendor?.name,
                            },
                            ["vendor_no", "name"], "-", "-")}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        {data?.isSelected
            ? <CheckCircleIcon htmlColor="#5078E1" />
            : <CheckCircleOutlineIcon htmlColor="#98A0AC" />}
    </Stack>
}