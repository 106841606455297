import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Italic, Regular, SemiBold, remCalc } from "../../utils";
export const SubscriptionsUseStyles = makeStyles((theme) => ({
    body: {
        backgroundColor: theme.palette.background.tertiary1,
        borderRadius: theme.palette.borderRadius,
    },
    tab_bar_div: {
        padding: "0px 20px 0px 20px"
    },
}));

export const PlanAndSubscriptionsUseStyles = makeStyles((theme) => ({
    tab_content_div: {
        padding: "20px",
        backgroundColor: theme.palette.background.secondary,
        height: ({ size }) => (size?.width <= 899) && (size?.height - (64 + 54 + 44)),
        overflow: ({ size }) => (size?.width <= 899) && "overlay",
    },
    current_plan_div: {
        padding: "16px",
        backgroundColor: theme.palette.background.tertiary1,
        height: ({ size }) => (size?.width > 899) && (size?.height - (64 + 54 + 44 + 20 + 20)),
        overflow: ({ size }) => (size?.width > 899) && "overlay",
    },
    available_plan_div: {
        padding: "16px",
        backgroundColor: theme.palette.background.tertiary1,
        height: ({ size }) => (size?.width > 899) && (size?.height - (64 + 54 + 44 + 20 + 20)),
        overflow: ({ size }) => (size?.width > 899) && "overlay",
    },
    title_1: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
    },
    current_plan_basic_details_div: {
        borderRadius: "8px",
        padding: "18px",
        backgroundColor: "#EDE4FE80",
        marginBottom: "20px",
    },
    plan_name_text: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: theme.palette.secondary.main,
    },
    valid_till_text: {
        fontSize: "0.75rem",
        fontFamily: Italic,
        color: theme.typography.color.tertiary,
    },
    current_plan_storage_usage_div: {
        borderRadius: "8px",
        padding: "18px",
        backgroundColor: theme.palette.background.secondary,
        marginBottom: "12px",
    },
    storage_info_text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
    },
    storage_detail_text: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    storage_metric_text: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        color: theme.typography.color.tertiary,
    },
    plan_marketplace_usage_div: {
        cursor: "pointer",
    },
    plan_usage_value_text: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    plan_usage_metric_text: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary,
    },
    plan_usage_info_text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
    },
    plan_usage_divider: {
        backgroundColor: "#F0F0F0"
    },
    plan_module_usage_div: {
        position: "relative",
        cursor: "pointer",
    },
    plan_module_usage_tag: {
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.error.light,
        padding: "2px 8px",
    },
    plan_module_usage_tag_text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.palette.error.main,
    },
    plan_upgrade_text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.selected_1,
    },
    available_plan_table_container: {
        maxHeight: ({ size }) => (size?.height - (64 + 54 + 44 + 20 + 20 + 16 + 16)),
        overflow: "overlay",
    },
    table_header_sticky_cell_0: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        border: "none",
        backgroundColor: "white",
        padding: "12px",
        minWidth: "250px",
        verticalAlign: "top",
    },
    table_header_cell_remaining: {
        border: "none",
        backgroundColor: "white",
        padding: "12px",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "150px",
        verticalAlign: "top",
    },
    table_row_sticky_cell_0: {
        position: "sticky",
        left: 0,
        zIndex: 2,
        border: "none",
        padding: "12px",
        backgroundColor: theme.palette.background.secondary,
    },
    table_row_cell_remaining: {
        border: "none",
        padding: "12px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.secondary,
    },
    table_header_sticky_cell_0_name: {
        fontSize: "1.125rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
        textAlign: "start",
    },
    table_header_sticky_cell_0_description: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.tertiary,
        textAlign: "start",
    },
    table_header_cell_remaining_name: {
        fontSize: "1.125rem",
        fontFamily: ExtraBold,
        textAlign: "center",
    },
    table_header_cell_remaining_price: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.secondary,
        textAlign: "center",
    },
    table_header_cell_remaining_description: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.tertiary,
        textAlign: "center",
    },
    contact_sales_button: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        padding: "7px 24px",
    },
    contact_sales_text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.white,
        textAlign: "center",
    },
    current_plan_text: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
        textAlign: "center",
    },
    table_row_sticky_cell_0_name: {
        fontSize: "0.875rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
        textAlign: "start",
    },
    table_row_cell_remaining_box: {
        display: "flex",
        width: "150px",
        alignItems: "center",
        justifyContent: "center",
    },
    module_not_available_place_holder: {
        height: "2px",
        width: "12px",
        backgroundColor: theme.palette.background.primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export const BasicInfoUseStyles = makeStyles((theme) => ({
    tab_content_div: {
        padding: "20px",
        backgroundColor: theme.palette.background.secondary,
        height: ({ size }) => (size?.width <= 899) && (size?.height - (64 + 54 + 44)),
        overflow: ({ size }) => (size?.width <= 899) && "overlay",
    },
}));

export const ConfigurationUseStyles = makeStyles((theme) => ({
    tab_content_div: {
        padding: "20px",
        backgroundColor: theme.palette.background.secondary,
        height: ({ size }) => size?.height - (64 + 54 + 44),
        overflow: "hidden",
    },
    tab_content_inner_div: {
        height: ({ size }) => size?.height - (64 + 54 + 44 + 40),
        overflow: "overlay",
        backgroundColor: theme.palette.background.tertiary1,
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        padding: "24px",
    },
    exploreMetaForm: {
        backgroundColor: theme.palette.background.tertiary1,
    },
    selectedButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    unSelectedButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: "#5078E1",
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        border: "1px solid #5078E1",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white ",
        },
    },
    exploreMetaFormCancelButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: "#5078E1",
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        border: "1px solid #5078E1",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white ",
        },
    },
    exploreMetaFormSaveButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    selected_image_section_div: {
        paddingTop: "16px",
        borderTop: "1px solid #E4E8EE",
    },
    selected_image_div: {
        height: "68px",
        width: "68px",
        padding: "4px",
        boxShadow: "0px 3px 6px #00000014",
    },
    selected_image: {
        height: "60px",
        width: "60px",
        objectFit: "contain",
    },
    item_card_label: {
        fontFamily: Bold,
        fontSize: remCalc(14),
        color: theme.typography.color.secondary,
    },
    delete_button: {
        cursor: "pointer",
    },
}));