import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  DeleviryCard, DialogBox, LoadingSection, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox
} from "../../../components";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, constructPropertyList, getCompanyOption, NetWorkCallMethods, SemiBold, timeZoneConverter } from "../../../utils";
import {
  CollectedHeading,
  CollectedPath,
  CollectedType, ReceivedAtGateHeading,
  ReceivedAtGatePath,
  ReceivedAtGateType, YetToReceiveHeading,
  YetToReceivePath,
  YetToReceiveType
} from "../../../utils/securityManager/deliveryOrderCollectionDataPoints";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  innerWindow: {
    borderRadius: theme.palette.borderRadius,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    // padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "14px"
  },
  searchFilter: {
    padding: "16px 0px 0px 0px",
    width: "30%",
  },
  table: {
    padding: "0px 16px 0px 16px",
  },
  dropdown: {
    padding: "24px",
  },
  button: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  noData: {
    button: {
      fontSize: "0.875rem",
      fontFamily: SemiBold,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const DeliveryOrderCollection = (props) => {
  const { t } = props
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext)
  const [selectedProperty, setSelectedProperty] = React.useState({})
  const [propertyData, setPropertyData] = React.useState([])

  const [companyList, setCompanyList] = React.useState([])
  const [selectedCompany, setSelectedCompany] = React.useState({})
  const [initialLoad, setInitialLoad] = React.useState(false)
  const [status, setStatus] = React.useState([]);

  const [searchText, setSearchText] = React.useState("");

  const [gatePassDetails, setGatePassDetails] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [loading1, setLoading1] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);
  //yet to recive popup
  const [open, setOpen] = React.useState(false);

  const [tab, setTab] = React.useState({
    offset: 0,
    page: 1,
    limit: 10
  })

  // dialog open
  const handleClickOpen = () => {
    setOpen(!open);
  };

  //recive at popup
  const [open1, setOpen1] = React.useState(false);

  // dialog open
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };

  const [yetState, setYetState] = React.useState({
    gate: "",
    des: "",
    error: {
      gate: "",
      des: "",
    },
  });
  const backdrop = React.useContext(BackdropContext);
  const [selectGate, setSelectGate] = React.useState([]);
  const updateState = (key, value) => {
    let error = yetState.error;
    error[key] = "";
    setYetState({ ...yetState, [key]: value, error });
  };

  const [yetToReceiveData, setYetToReceiveData] = React.useState({
    heading: YetToReceiveHeading(t),
    row: [],
    path: YetToReceivePath,
    type: YetToReceiveType,
  });
  const [receivedAtGateData, setReceivedAtGateData] = React.useState({
    heading: ReceivedAtGateHeading(t),
    row: [],
    path: ReceivedAtGatePath,
    type: ReceivedAtGateType,
  });
  const [collectedData, setCollectedData] = React.useState({
    heading: CollectedHeading(t),
    row: [],
    path: CollectedPath,
    type: CollectedType,
  });

  const handleChange = (event, newValue) => {
    // <Tab label="Yet To Receive" />
    // <Tab label="Received At Gate" />
    // <Tab label="Collected" />
    setValue(newValue);
    if (newValue === 0) {
      setStatus(["Yet To Receive"]);
      setTab({ ...tab, page: 1, offset: 0 })
    }
    if (newValue === 1) {
      setStatus(["Received At Gate"]);
      setTab({ ...tab, page: 1, offset: 0 })
    }
    if (newValue === 2) {
      setStatus(["Collected"]);
      setTab({ ...tab, page: 1, offset: 0 })
    }
  };

  const handlePagination = (value) => {
    const offset = (value - 1) * tab.limit;
    setTab({ ...tab, page: value, offset: offset })
  }

  const handleChangeLimit = (value) => {
    setTab({ ...tab, limit: value, page: 1, offset: 0 })
  }


  const getGate = (v) => {
    const payload = {
      property_id: v,
    };
    NetworkCall(
      `${config.api_url}/queries/access_gates_master/by_property_id`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let gates = response.data.data.access_gates_master?.map(((gate) => {
          return {
            ...gate,
            label: gate?.label + " - " + gate?.name
          }
        }))
        setSelectGate(gates ?? []);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  const getGatePassDetails = (data) => {
    const payload = {
      tenantId: `${config.tenantId}`,
      request_id: data?.id,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setGatePassDetails(response?.data?.data?.[0]);
        getGate(response?.data?.data?.[0].property_id);

        let temp = {
          gate: "",
          des: "",
          error: {
            gate: "",
            des: "",
          },
        };
        setYetState(temp);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const handleApprovedTableRowView = (type, data) => {
    handleClickOpen();
    getGatePassDetails(data);
  };
  const handleApprovedTableRowView1 = (type, data) => {
    handleClickOpen1();
    getGatePassDetails(data);
  };

  const isIamValide = () => {
    let isValid = true;
    let error = yetState.error;
    //Checking name
    if (yetState.gate.length === 0) {
      isValid = false;
      error.gate = "Gate is Required";
    }
    if (yetState.des.length === 0) {
      isValid = false;
      error.des = "Remarks is Required";
    }
    setYetState({ ...yetState, error });
    return isValid;
  };

  const sumbit = () => {

    if (isIamValide()) {
      const payload = {
        id: gatePassDetails?.id,
        update: {
          remarks: yetState.des,
          collected_access_gate: yetState.gate.value,
          updated_at: new Date().toISOString(),
          updated_by: localStorage.getItem("profileID"),
          delivery_status: "Received At Gate",
          received_date: new Date().toISOString(),
          delivery_collected_by: localStorage.getItem("profileID"),
        }
      };
      NetworkCall(
        `${config.api_url}/queries/security_request/updateByID`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          Recievedata();
          handleClickOpen();
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
        });
    }
  };

  const sumbit1 = () => {
    if (isIamValide()) {
      const payload = {
        id: gatePassDetails?.id,
        update: {
          collected_remarks: yetState.des,
          collected_access_gate: yetState.gate.value,
          updated_at: new Date().toISOString(),
          updated_by: localStorage.getItem("profileID"),
          delivery_status: "Collected",
          delivery_collected_date: new Date().toISOString(),
          delivery_collected_by: localStorage.getItem("profileID"),
        }
      };
      NetworkCall(
        `${config.api_url}/queries/security_request/updateByID`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          Recievedata();
          handleClickOpen1();
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          })
        });
    }
  };


  // Function to show logo or initial
  const returnValue = (value = "", delimiter = "") => value ? value : delimiter

  const Recievedata = () => {
    if (!status?.length > 0) {
      return false
    }
    setInitialLoad(true)
    if (propertyData !== null && propertyData?.length > 0) {
      const payload = {
        tenantId: `${config.tenantId}`,
        request_type: ["DCR"],
        status: status,
        offset: tab.offset,
        limit: tab.limit,
        property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
        reference_id: searchText?.length === 0 ? undefined : searchText.toUpperCase(),
      };
      NetworkCall(
        `${config.api_url}/security/visitors`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          if (status?.[0] === "Yet To Receive") {
            let tempYetToReceive = response?.data?.data?.map((item, index) => {
              let _item = {
                id: item?.id,
                requestor_image: {
                  logo: returnValue(item?.requestor_image, "-"),
                  name: item?.name ? returnValue(item?.name?.charAt(0)) : "-"
                },
                reference_id: item?.request_no ? item?.request_no : "-",
                name: item?.name ? item?.name : "-",
                unit_id: (item?.unit?.[0]?.name ? (item?.unit?.[0]?.name + (item?.unit?.[0]?.unit_no ? ", " : "")) : "") + (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                entry_gate: item?.gate ? item?.gate : "-",
                expected_date_time: item?.request_from
                  ? timeZoneConverter(item?.request_from, "DD MMM YY,hh:mm")
                  : "-",
                notes: item?.description ? item?.description : "-",
                data: item
              };
              return _item;
            });
            setYetToReceiveData({ ...yetToReceiveData, row: tempYetToReceive, totalRowsCount: response?.data?.totalRecords });
            setLoading(false);
          }
          if (status?.[0] === "Received At Gate") {
            let tempReceivedAtGate = response?.data?.data?.map((item, index) => {
              let _item = {
                id: item?.id,
                delivery_item_image: {
                  logo: returnValue(item?.delivery_item_image, "-"),
                  name: item?.description ? returnValue(item?.description?.charAt(0)) : "-"
                },
                delivery_person_image: {
                  logo: returnValue(item?.delivery_person_image, "-"),
                  name: item?.description ? returnValue(item?.description?.charAt(0)) : "-"
                },
                date: item?.request_on
                  ? timeZoneConverter(item?.request_on, "DD MMM YY,hh:mm")
                  : "-",
                reference_id: item?.request_no ? item?.request_no : "-",
                name: item?.name ? item?.name : "-",
                unit_id: (item?.unit?.[0]?.name ? (item?.unit?.[0]?.name + (item?.unit?.[0]?.unit_no ? ", " : "")) : "") + (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                received_gate: item?.collected_access_gate ? item?.collected_access_gate : "-",
                received_date_time: item?.updated_at
                  ? timeZoneConverter(item?.updated_at, "DD MMM YY,hh:mm")
                  : "-",
                registered: "-",
                received_at_gate_remarks: item?.remarks ?? "-",
                data: item
              };
              return _item;
            });
            setReceivedAtGateData({
              ...receivedAtGateData,
              row: tempReceivedAtGate,
              totalRowsCount: response?.data?.totalRecords
            });
            setLoading1(false);
          }
          if (status?.[0] === "Collected") {
            let tempCollected = response?.data?.data?.map((item, index) => {
              let _item = {
                id: "",
                collected_person_image: {
                  logo: returnValue(item?.collected_person_image, "-"),
                  name: item?.description ? returnValue(item?.description?.charAt(0)) : "-"
                },
                date: item?.request_on
                  ? timeZoneConverter(item?.request_on, "DD MMM YY,hh:mm")
                  : "-",
                reference_id: item?.request_no ? item?.request_no : "-",
                collected_by_name: item?.requested_by ? item?.requested_by : "-",
                unit_id: (item?.unit?.[0]?.name ? (item?.unit?.[0]?.name + (item?.unit?.[0]?.unit_no ? ", " : "")) : "") + (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                received_gate: item?.collected_access_gate ? item?.collected_access_gate : "-",
                received_date_time: item?.delivery_collected_date
                  ? timeZoneConverter(item?.delivery_collected_date, "DD MMM YY,hh:mm")
                  : "-",
                collected_by: "Requestor",
                received_at_gate_remarks: item?.remarks ?? "-",
                collected_remarks: item?.collected_remarks ?? "-",
                data: item
              };
              return _item;
            });
            setCollectedData({ ...collectedData, row: tempCollected, totalRowsCount: response?.data?.totalRecords });
            setLoading2(false);
          }
          setInitialLoad(false)

        })
        .catch((error) => {
          setInitialLoad(false)

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          })
        });
    } else if (selectedCompany?.property?.length === 0) {
      if (status?.[0] === "Yet To Receive") {
        setYetToReceiveData({ ...yetToReceiveData, row: [], totalRowsCount: 0 });
        setLoading(false);
      }
      if (status?.[0] === "Received At Gate") {
        setReceivedAtGateData({ ...receivedAtGateData, row: [], totalRowsCount: 0 });
        setLoading1(false);
      }
      if (status?.[0] === "Collected") {
        setCollectedData({ ...collectedData, row: [], totalRowsCount: 0 });
        setLoading2(false);
      }
      setInitialLoad(false)

    }
  };
  React.useEffect(() => {
    Recievedata();
    // eslint-disable-next-line
  }, [selectedCompany, status, searchText, selectedProperty]);

  // useEffect to get company list for company switcher when loading the screen
  React.useEffect(() => {
    let company = getCompanyOption(backdrop, auth, alert)
    if (company) {
      setCompanyList(company?.list)
      setSelectedCompany(company?.selected)
      getPropertyList(company?.selected)
    }
    // eslint-disable-next-line
  }, [auth])

  // Function to change the company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value)
    getPropertyList(value)
  }

  // Function to change property list
  const getPropertyList = (company) => {
    let property = constructPropertyList(company)
    setPropertyData(property?.list);
    setSelectedProperty(property?.list[0])
  }

  //handlePropertyChange
  const onPropertyChange = (val) => {
    setSelectedProperty(val)
  }


  return (
    <div className={classes.root}>
      <Subheader hideBackButton={true}
        title={t("Delivery Collection")} select
        options={companyList} value={selectedCompany}
        goBack={() => history.push(Routes.home)}
        onchange={(e) => handleCompanyChange(e)}
        onPropertyChange={(e) => {
          onPropertyChange(e)
        }}
        propertyValue={selectedProperty}
        selectProperty
        propertyOptions={propertyData}
      />
      <Grid direction={"column"} className={classes.innerWindow}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "0px 0px 0px 8px" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t("Yet To Receive")} />
              <Tab sx={{ marginInlineStart: "16px" }} label={t("Received At Gate")} />
              <Tab label={t("Collected")} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <DialogBox
              open={open}
              maxWidth="sm"
              handleClickOpen={handleClickOpen}
              handleClose={handleClickOpen}
              onClose={handleClickOpen}
              header={t("Update Delivery Status")}
              component={
                <>
                  <DeleviryCard data={gatePassDetails} />

                  <div className={classes.dropdown}>
                    <SelectBox
                      isRequired
                      label={t("Gate Number")}
                      placeholder={t("Select Gate")}
                      value={yetState?.gate}
                      options={selectGate}
                      onChange={(value) => updateState("gate", value)}
                      isError={yetState?.error?.gate?.length > 0}
                      errorMessage={yetState?.error?.gate}
                    />
                    <div style={{ marginTop: "10px" }}>
                      <TextBox
                        isrequired
                        label={t("Remarks")}
                        placeholder={t("Type Remark")}
                        value={yetState?.des}
                        onChange={(e) => updateState("des", e.target.value)}
                        multiline={1}
                        isError={yetState?.error?.des?.length > 0}
                        errorMessage={yetState?.error?.des}
                      />
                    </div>
                    {/* button */}
                    <div style={{ width: "100%", marginTop: "80px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                        onClick={sumbit}
                        className={classes.button}
                      >
                        {t("Mark As Received")}
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
            {loading ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} className={classes.table}>
                  <div className={classes.searchFilter}>
                    <SearchFilter
                      placeholder={t("Search By Reference ID")}
                      value={searchText}
                      handleChange={(value) => setSearchText(value)}
                      customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                    />
                  </div>
                  {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                    yetToReceiveData.row.length > 0 ? (
                      <TableWithPagination
                        heading={yetToReceiveData.heading}
                        rows={yetToReceiveData.row}
                        path={yetToReceiveData.path}
                        showpagination={
                          yetToReceiveData.row.length > 0 ? true : false
                        }
                        count="2"
                        onClick={() => false}
                        showpdfbtn={false}
                        // showpagination={true}
                        showexcelbtn={false}
                        showSearch={false}
                        dataType={yetToReceiveData.type}
                        handleIcon={handleApprovedTableRowView}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={yetToReceiveData?.totalRowsCount}
                        page={tab.page}
                        limit={tab.limit}
                        height={'calc(100vh - 330px)'}
                        tableType="no-side"
                        view={true}
                        edit={true}
                        delete={true} />
                    ) : (
                      <>
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                          <Typography className={classes.noData}>
                            {t("No Data Found")}
                          </Typography>
                        </div>
                      </>
                    )}
                </Grid>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DialogBox
              open={open1}
              handleClickOpen={handleClickOpen1}
              handleClose={handleClickOpen1}
              onClose={handleClickOpen1}
              header={t("Update Delivery Status")}
              maxWidth="sm"
              component={
                <>
                  <DeleviryCard data={gatePassDetails} />

                  <div className={classes.dropdown}>
                    <SelectBox
                      isRequired
                      label={t("Gate Number")}
                      placeholder={t("Select Gate")}
                      value={yetState?.gate}
                      options={selectGate}
                      onChange={(value) => updateState("gate", value)}
                      isError={yetState?.error?.gate?.length > 0}
                      errorMessage={yetState?.error?.gate}
                    />
                    <div style={{ marginTop: "10px" }}>
                      <TextBox
                        isrequired
                        label={t("Remarks")}
                        placeholder={t("Type Remark")}
                        value={yetState?.des}
                        onChange={(e) => updateState("des", e.target.value)}
                        multiline={1}
                        isError={yetState?.error?.des?.length > 0}
                        errorMessage={yetState?.error?.des}
                      />
                    </div>
                    {/* button */}
                    <div style={{ width: "100%", marginTop: "80px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                        onClick={sumbit1}
                        className={classes.button}
                      >
                        {t("Mark As Collected")}
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
            {loading1 ? (
              <Box sx={{ padding: "20px" }}>

              </Box>
            ) : (
              <>
                <Grid item xs={12} className={classes.table}>
                  <div className={classes.searchFilter}>
                    <SearchFilter
                      placeholder={t("Search By Reference ID")}
                      value={searchText}
                      handleChange={(value) => setSearchText(value)}
                      customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                    />
                  </div>
                  {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                    receivedAtGateData.row.length > 0 ? (
                      <TableWithPagination
                        heading={receivedAtGateData.heading}
                        rows={receivedAtGateData.row}
                        path={receivedAtGateData.path}
                        showpagination={
                          receivedAtGateData.row.length > 0 ? true : false
                        }
                        count="2"
                        onClick={() => false}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        dataType={receivedAtGateData.type}
                        handleIcon={handleApprovedTableRowView1}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={receivedAtGateData?.totalRowsCount}
                        page={tab.page}
                        limit={tab.limit}
                        height={'calc(100vh - 330px)'}
                        tableType="no-side"
                        view={true}
                        edit={true}
                        delete={true} />
                    ) : (
                      <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <Typography className={classes.noData}>
                          {t("No Data Found")}
                        </Typography>
                      </div>
                    )}
                </Grid>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {loading2 ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} className={classes.table}>
                  <div className={classes.searchFilter}>
                    <SearchFilter
                      placeholder={t("Search By Reference ID")}
                      value={searchText}
                      handleChange={(value) => setSearchText(value)}
                      customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                    />
                  </div>
                  {initialLoad ? <LoadingSection message={"feteching data..."} /> :


                    collectedData.row.length > 0 ? (
                      <TableWithPagination
                        heading={collectedData.heading}
                        rows={collectedData.row}
                        path={collectedData.path}
                        showpagination={
                          collectedData.row.length > 0 ? true : false
                        }
                        count="2"
                        onClick={() => false}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        dataType={collectedData.type}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={collectedData?.totalRowsCount}
                        page={tab.page}
                        limit={tab.limit}
                        height={'calc(100vh - 330px)'}
                        tableType="no-side"
                        view={true}
                        edit={true}
                        delete={true} />
                    ) : (
                      <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <Typography className={classes.noData}>
                          {t("No Data Found")}
                        </Typography>
                      </div>
                    )

                  }
                </Grid>
              </>
            )}
          </TabPanel>
        </Box>
      </Grid>
    </div>
  );
};
export default withNamespaces("vendorCreation")(DeliveryOrderCollection)
