import { Box, Popover, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import makeStyles from "@mui/styles/makeStyles";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";
import React from "react";
import { CalenderIcon } from "../../assets";
import {
  DatePickerNew,
  DatePickerTimeNew,
  Label,
  NewDatePicker,
  TextBox,
} from "../../components";
import {
  Bold,
  Regular,
  SemiBold,
  timeZoneConverter,
  useWindowDimensions,
} from "../../utils";
import {
  CustomSelect,
  MinMaxTextFieldWithSelect,
  ToggleButton,
} from "./components";

const Div = styled("div")(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflow: "auto",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginBlock: theme.spacing(1),

  borderColor: "#E4E8EE",
  width: "100%",
}));
const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiMenu-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
    "& .MuiPopover-paper": {
      borderRadius: theme.palette.borderRadius,
      boxShadow: "0px 8px 24px #0717411F",
      padding: "0px !important",
    },
  },
}));

export const Body = (props) => {
  const {
    data = {},
    error = {},
    components = [],
    onChangeState = null,
    t = () => false,
  } = props;

  const size = useWindowDimensions();
  const classes = useStyles();

  const [calenderOpen, setCalendarOpen] = React.useState(false);
  const openClosePopOver = () => {
    setCalendarOpen(!calenderOpen);
  };
  const [calenderOpen1, setCalendarOpen1] = React.useState(false);
  const openClosePopOver1 = () => {
    setCalendarOpen1(!calenderOpen1);
  };

  const onCalendarPickerChange = (val, range) => {
    let value = {
      label: "Custom",
      value: {
        from_date: range?.startDate,
        to_date: range?.endDate,
      },
      load: true,
    };
    setCalendarOpen1(!calenderOpen1);
    onChangeState(val?.state_name, value, val);
  };

  const switchComponents = (val) => {
    switch (val.component) {
      case "select":
        return (
          <>
                    <CustomSelect
            label={val?.label ?? ""}
            options={val?.options?.length ? val?.options : []}
            isMulti={val?.isMulti}
            value={data?.[val?.state_name] ?? null}
            onChange={(value) =>{
              onChangeState(val?.state_name, value, val)
            }}
            error={error?.[val?.state_name] ?? false}
            errorText={t("This field is required")}
            required={val?.required ?? false}
            isPaginate={val?.isPaginate}
            debounceTimeout={val?.debounceTimeout}
            loadOptions={val?.loadOptions}
            loading={val?.loading}
            labelSize={val?.labelSize ?? ""}
            labelColor={val?.labelColor ?? ""}
            fontFamily={val?.fontFamily}
            placeholder={val?.placeholder ?? ""}
            key={val?.key}

          />
     {data?.[val?.state_name]?.label === "Custom" && (
                <Box
                  style={{
                    backgroundColor: "#FFFFFF",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 999,
                    cursor: "pointer",
                    padding:"10px",
                    marginTop:"5px",
                    border:"1px solid #E4E8EE",
                    borderRadius:'4px'
                  }}
                  onClick={openClosePopOver1}>
                  <CalenderIcon />
                  <Typography style={{ marginLeft: "6px", fontSize:"0.875rem", fontFamily: SemiBold, color: "#333333" }}>
                    {`${data?.[val?.state_name]?.value?.from_date ? moment(data?.[val?.state_name]?.value?.from_date).format("YYYY-MM-DD") :  ""} 
                    ${(data?.[val?.state_name]?.value?.from_date && data?.[val?.state_name]?.value?.to_date) ? "to" : "Select Date"}
                     ${data?.[val?.state_name]?.value?.to_date ?  moment(data?.[val?.state_name]?.value?.to_date).format("YYYY-MM-DD") :  ""}`}
                  </Typography>
                </Box>
              )}

<Popover
className={classes.menu}
        open={calenderOpen1}
        onClose={openClosePopOver1}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 130, left: 1000 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DateRangePicker
          open={calenderOpen1}
          toggle={openClosePopOver1}
          onChange={(value) => onCalendarPickerChange(val, value)}
          initialDateRange={{
            startDate: data?.[val?.state_name]?.value?.from_date 
              ? new Date(data?.[val?.state_name]?.value?.from_date )
              : new Date(),
            endDate: data?.[val?.state_name]?.value?.to_date 
              ? new Date(data?.[val?.state_name]?.value?.to_date )
              : new Date(),
          }}
          definedRanges={[]}
        />
      </Popover>
          </>
        );
      case "toggleButton":
        return (
          <ToggleButton
            options={val?.options?.length ? val?.options : []}
            value={data?.[val?.state_name]}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            isMulti={val?.isMulti}
            fullWidth={val?.fullWidth}
            buttonStyle={{
              borderColor: "#E4E8EE",
              borderRadius: 1,
              backgroundColor: "#fff",
              ...val?.buttonStyle,
            }}
            buttonGroupStyle={{ ...val?.buttonGroupStyle }}
            label={val?.label ?? ""}
            error={error?.[val?.state_name] ?? false}
            errorText={t("This field is required")}
            required={val?.required ?? false}
            labelColor={val?.labelColor ?? ""}
            labelSize={val?.labelSize ?? ""}
            fontFamily={val?.fontFamily ?? ""}

          />
        );
      case "minMaxTextFieldWithSelect":
        return (
          <MinMaxTextFieldWithSelect
            value={data?.[val?.state_name] ?? null}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            label={val?.label ?? ""}
            error={error?.[val?.state_name] ?? false}
            errorText={t("This field is required")}
            required={val?.required ?? false}
            placeholders={val?.placeholders ?? null}
            endAdornmentLabels={val?.endAdornmentLabels ?? null}
            options={val?.options}
            select={val?.select}
          />
        );
      case "minAndmaxDate":
        return (
          <>
            <Label label={val?.minMaxlabel} isRequired={val?.required} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NewDatePicker
                  isRequired={val?.isRequired}
                  disableFuture={val?.disableFuture}
                  disablePast={val?.disablePast}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.startLabel}
                  placeholder={val?.placeholder?.startLabel}
                  value={data?.[val?.state_name]?.["startDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        startDate: value,
                      },
                      val
                    )
                  }
                  labelColor={val?.labelColor ?? ""}
                  labelSize={val?.labelSize ?? ""}
                  fontFamily={val?.fontFamily ?? ""}
                  height={val?.height}
                  newDate={val?.newDate}
                />
              </Grid>
              <Grid item xs={6}>
                <NewDatePicker
                  isRequired={val?.isRequired}
                  disableFuture={val?.disableFuture}
                  disablePast={val?.disablePast}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.endLabel}
                  placeholder={val?.placeholder?.endLabel}
                  value={data?.[val?.state_name]?.["endDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        endDate: value,
                      },
                      val
                    )
                  }
                  labelColor={val?.labelColor ?? ""}
                  labelSize={val?.labelSize ?? ""}
                  fontFamily={val?.fontFamily ?? ""}
                  height={val?.height}
                  newDate={val?.newDate}
                />
              </Grid>
            </Grid>
          </>
        );
      case "date":
        return (
          <>
            <Label style={{ fontFamily: SemiBold, fontSize: "1rem", marginBottom: "6px" }} label={val?.label} isRequired={val?.required} />
            <DatePickerNew
              isRequired={val?.isRequired}
              disableFuture={val?.disableFuture}
              disablePast={val?.disablePast}
              minDate={val?.minDate}
              maxDate={val?.maxDate}
              label={val?.label?.startLabel}
              placeholder={val?.placeholder}
              value={data?.[val?.state_name] ?? null}
              handleChange={(value) =>
                onChangeState(val?.state_name, value, val)
              }
            />
          </>
        );
      case "date_range":
        return (
          <Grid container>
            <Label label={val?.label} isRequired={val?.required} style={{ fontFamily: SemiBold, fontSize: "1rem", marginBottom: "6px" }} />
            <Grid container spacing={"16px"}>
              <Grid item xs={6}>
                <Label label={t("From")} textAlign={"left"} />
                <Box
                  style={{
                    border: "1px solid #e4e8ee",
                    borderRadius: "4px",
                    height: "45px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}
                >
                  <Typography style={{ fontSize: !data?.[val?.state_name]?.startDate ? "0.875rem" : "1rem", color: !data?.[val?.state_name]?.startDate ? "#98A0AC" : "#333333", fontFamily: !data?.[val?.state_name]?.startDate ? Regular : SemiBold }}>
                    {data?.[val?.state_name]?.startDate ? timeZoneConverter(data?.[val?.state_name]?.startDate) : val?.startPlaceholder ?? "From"}
                  </Typography>
                  <img src={"/images/icons8-calendar (5).svg"} alt="" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Label label={val?.toLabel ? val?.toLabel : t("Till")} textAlign={"left"} />
                <Box
                  style={{
                    border: "1px solid #e4e8ee",
                    borderRadius: "4px",
                    height: "45px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}
                >
                  <Typography style={{ fontSize: !data?.[val?.state_name]?.endDate ? "0.875rem" : "1rem", color: !data?.[val?.state_name]?.endDate ? "#98A0AC" : "#333333", fontFamily: !data?.[val?.state_name]?.endDate ? Regular : SemiBold }}>
                    {data?.[val?.state_name]?.endDate ? timeZoneConverter(data?.[val?.state_name]?.endDate) : val?.endPlaceholder ?? "Till"}
                  </Typography>
                  <img src={"/images/icons8-calendar (5).svg"} alt="" />
                </Box>
              </Grid>
            </Grid>
            <Popover
              open={calenderOpen}
              onClose={openClosePopOver}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 250, left: size?.width }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <DateRangePicker
                open={calenderOpen}
                toggle={openClosePopOver}
                onChange={(range) => {
                  onChangeState(val?.state_name, range, val);
                  openClosePopOver();
                }}
                initialDateRange={data?.[val?.state_name]}
                definedRanges={[]}
              />
            </Popover>
          </Grid>
        );
      case "amount_minAndmax":
        return (
          <Grid container>
            <Label label={val?.label} isRequired={val?.required} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  label={t("From")}
                  type={"number"}
                  value={data?.[val?.state_name]?.["fromAmount"]}
                  endAdornment={val?.amount_symbol}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        fromAmount: value.target.value,
                      },
                      val
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextBox
                  label={t("To")}
                  type={"number"}
                  value={data?.[val?.state_name]?.["toAmount"]}
                  endAdornment={val?.amount_symbol}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        toAmount: value.target.value,
                      },
                      val
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        );
      case "select_box":
        return (
          <>
            {val?.is_active && (
              <CustomSelect
                label={val?.label ?? ""}
                options={val?.options?.length ? val?.options : []}
                isMulti={val?.isMulti}
                value={val.value}
                onChange={val?.onChange}
                error={val?.error?.length > 0}
                errorText={val?.error}
                required={val?.isReadonly ?? false}
                isPaginate={val?.isPaginate}
                loadOptions={val?.loadOptions}
                loading={val?.loading}
                debounceTimeout={800}
                key={val?.key}
                labelSize={val?.labelSize ?? ""}
                labelColor={val?.labelColor ?? ""}
                fontFamily={val?.fontFamily}
              />
            )}
          </>
        );
      case "dateTimePickerWithRange":
        return (
          <>
            <Label label={val?.title} isRequired={val?.required} style={{fontFamily:Bold,fontSize:"0.875rem",color:"#091B29"}}/>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePickerTimeNew
                  isRequired={val?.isRequired}
                  isTime={val?.isNot ? false : true}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.startLabel}
                  placeholder={val?.placeholder?.startLabel}
                  value={data?.[val?.state_name]?.["startDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        startDate: value,
                      },
                      val
                    )
                  }
                  border={val?.border}
                  is_popover={val?.is_popover}

                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTimeNew
                  isRequired={val?.isRequired}
                  isTime={val?.isNot ? false : true}
                  minDate={data?.[val?.state_name]?.["startDate"]}
                  maxDate={val?.maxDate}
                  label={val?.label?.endLabel}
                  placeholder={val?.placeholder?.endLabel}
                  value={data?.[val?.state_name]?.["endDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        endDate: value,
                      },
                      val
                    )
                  }
                  border={val?.border}
                  is_popover={val?.is_popover}

                />
              </Grid>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Div>
      {
        <Grid container>
          {components?.length &&
            components.map((_) => {
              return (
                <>
                  <Grid item xs={12}>
                    {switchComponents(_)}
                  </Grid>
                  {_?.is_divider ? "" : <CustomDivider />}
                </>
              );
            })}
        </Grid>
      }
    </Div>
  );
};
