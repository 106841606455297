import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from 'react-router-dom';
import "../../../App.css";
import { AlertDialog, MapFieldsOnly, MobileNumberInputComponent, SearchFilter, SelectBox, TextBox } from "../../../components";
import { CustomSelect } from '../../../components/filterGenerator/components/select';
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { Bold, enum_types, enumSelect, NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { AddMemberCard, Card, Card1 } from '../saleagent/addmembercard';
import { useStylesCreation } from "./style";

// custom styles
// start
const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize: "0.75rem",
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))

const textBoxStyle = {
      padding: "8px 14px",
      borderRadius: 8,
      height: 47
}

// end

// Reporting Details card
const list = [
      {
            name: "Reporting Details"
      }
]
// dummy datas
// start


// end

export const Details = (props) => {
      const { mapLoad } = props;
      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const { state } = useLocation();
      const size = useWindowDimensions();
      const classes = useStylesCreation();

    // eslint-disable-next-line
      const [loading, setLoading] = React.useState(false)
      const [open, setOpen] = React.useState(false)
      const [userDetails, setUserDetails] = React.useState([])
      const [selectedID, setSelectedID] = React.useState(null)
      const [deleteUser, setdeleteUser] = React.useState(false)
      const [offset, setOffset] = React.useState(0)
      const [searchdata, setSearchdata] = React.useState("")
      const [imageViwer, setImageViwer] = React.useState(false)
      const [enumValue, setEnumValue] = React.useState({
            replationShip: [],
            method: []
      })
      // apolo clint

      // drop down option


      // get user profiles in pop up
      const getUserProfile = (v, offset, search, k) => {
            // backdrop.setBackDrop({
            //       ...backdrop,
            //       open: true,
            //       message: "Loading",
            // });
            const payload = {
                  company_id: state?.main?.companyID?.value,
                  roles_id: v?.value,
                  offset: offset,
                  limit: 10,
                  search: search ?? "",

            }
            NetworkCall(
                  `${config.api_url}/queries/client_user_roles/get`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false).then((res) => {
                        if (k === 'search') {
                              setUserDetails(res?.data?.data?.client_user_roles)
                        }
                        else {
                              setUserDetails(userDetails?.concat(res?.data?.data?.client_user_roles));
                        }

                        // backdrop.setBackDrop({
                        //       ...backdrop,
                        //       open: false,
                        //       message: "",
                        // });
                  }).catch(() => {

                  })
      }
      // update state of selet job
      const updateState = (k, v) => {
            props?.updateState(k, v);
            if (k === 'job') {
                  getUserProfile(v, 0, "", "search")
            }
      }
      // selected users
      const selectUser = (x) => {
            setSelectedID(x)
      }
      // delete member
      const deleteMember = () => {
            props?.updateState('member', {})
            setSelectedID(null)
            setOpen(!open)
      }
      // close Drawer
      const closeDrawer = (v, k) => {
            if (k === 'delete') {
                  setdeleteUser(true)
                  setOpen(!open)
            }
            else if (k === 'add') {
                  setdeleteUser(false)
                  setOpen(!open)
                  props?.updateState('member', {})
            }
            else if (k === 'close') {
                  props?.updateState('member', {})
                  setSelectedID(null)
                  setOpen(!open)
            }
            else if (k === 'deleteclose') {
                  setOpen(!open)
            }
            else {
                  props?.updateState('member', selectedID)
                  setOpen(!open)
            }

      }
      // infinity scroll
      const fetchMoreData = () => {
            setOffset(offset + 10);
            getUserProfile(props?.data?.job, offset + 10, "", "normal");
      };
      // const search user data
      const handleSearch = (v) => {
            setSearchdata(v)
            getUserProfile(props?.data?.job, 0, v, "search")
      }
      const getEnum = async () => {
            const result = await enumSelect([enum_types.relationship_type, enum_types.preferred_mode_contact_type])
            setEnumValue({
                  replationShip: result?.relationship_type,
                  method: result?.preferred_mode_contact_type
            })
      }
      React.useEffect(() => {
            getEnum()
      }, [])
      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }

      const manualPropertyResponse = (array) => {
            return array?.property
      }

      const manualRolesResponse = (array) =>{
            return array?.roles
      }

      return (
            <div>
                  <Grid container spacing={3}>
                        {/* img upload  */}
                        <Grid item xs={2}>
                              <Box className={classes.imagebox}>
                                    <CustomTypography>
                                          PROFILE PICTURE
                                    </CustomTypography>
                                    <Avatar src={props?.data?.img?.length > 0 ? props?.data?.img : "/images/citynew.svg"} style={{ margin: '0 auto' }} className={props?.data?.img?.length > 0 ? classes.avatar : classes.avatar1} onClick={() => setImageViwer(true)} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained' style={{ marginTop: '10px' }}>    Upload image</Box> <input type='file' style={{ display: 'none' }} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null;

                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    </div>
                                    {props?.data?.img?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>Remove Image</Typography> : props?.data?.error?.img?.length > 0 ? (
                                          <span style={{ fontSize: "0.75rem", color: "red" }}>
                                                Profile Image is required
                                          </span>
                                    ) : <span style={{ fontSize: "0.75rem" }}>
                                          <Box height={"18px"} />
                                    </span>}

                              </Box>
                        </Grid>

                        {/* profile details */}
                        <Grid item xs={10}>
                              <Box className={classes.imagebox1}>
                                    <CustomTypography>
                                          PROPERTY DETAILS
                                    </CustomTypography>
                                    <Grid container spacing={3}>
                                          {/* name */}
                                          <Grid item xs={4}>
                                                <TextBox
                                                      {...textBoxStyle}
                                                      isrequired
                                                      label="Name"
                                                      placeholder="Name"
                                                      value={props?.data?.name ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("name", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.name?.length > 0}
                                                      errorMessage={props?.data?.error?.name} />
                                          </Grid>
                                          <Grid item xs={4}>
                                                {/* Company Identification Number */}
                                                <TextBox
                                                      {...textBoxStyle}
                                                      isrequired
                                                      label="Company Identification Number"
                                                      placeholder="Company Identification Number"
                                                      value={props?.data?.identitynumber ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("identitynumber", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.identitynumber?.length > 0}
                                                      errorMessage={props?.data?.error?.identitynumber} />
                                          </Grid>
                                          <Grid item xs={4}>
                                                {/* Company Identification Number" */}
                                                <SelectBox
                                                      isRequired
                                                      label=" Relationship"
                                                      placeholder="Select  Relationship"
                                                      options={enumValue?.replationShip}
                                                      value={props?.data?.relationship ?? ""}
                                                      onChange={(value) => {
                                                            props?.updateState("relationship", value);
                                                      }}
                                                      isError={props?.data?.error?.relationship?.length > 0}
                                                      errorMessage={props?.data?.error?.relationship} />

                                          </Grid>
                                          <Grid item xs={4}>
                                                {/* Company Identification Number" */}
                                                <CustomSelect
                                                      color={'#98A0AC'}
                                                      fontSize={12}
                                                      required
                                                      fontWeight={400}
                                                      isMulti
                                                      label="Properties"
                                                      placeholder="Select Properties"
                                                      loading={loading === "properties"}
                                                      isPaginate
                                                      debounceTimeout={800}
                                                      loadOptions={(search, array, handleLoading) =>
                                                            loadOptionsApis(
                                                                  "queries/property/list",
                                                                  {
                                                                        company_id: state?.main?.companyID?.value,
                                                                        "orderByKey": "name",
                                                                        "orderByValue": "asc"
                                                                  },
                                                                  search,
                                                                  array,
                                                                  handleLoading,
                                                                  "data",
                                                                  {},
                                                                  manualPropertyResponse
                                                            )}
                                                      value={props?.data?.properties ?? ""}
                                                      onChange={(value) => {
                                                            props?.updateState("properties", value);
                                                      }}
                                                      isDeletedValue
                                                      deletedValue={(value, updateval) => props?.deletedValue(value, updateval)}
                                                />
                                                {props?.data?.error?.properties?.length > 0 && (
                                                      <Typography variant={"caption"} color={"error"}>
                                                            {props?.data?.error?.properties}
                                                      </Typography>
                                                )}

                                          </Grid>
                                    </Grid>
                              </Box>
                        </Grid>
                  </Grid>


                  {/* address details */}
                  <div className='map-wrapper'>
                        <Box className={classes.imagebox1} style={{ marginTop: '24px', position: 'relative' }}>
                              <CustomTypography>Address</CustomTypography>
                              {/* map component */}
                              {
                                    props?.load && <MapFieldsOnly
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                // Door Number
                                                {
                                                      label: "Door Number",
                                                      component: "TextField",
                                                      value: props?.data?.doorno,
                                                      state_name: 'doorno',
                                                      isrequired: false,
                                                      placeholder: "Enter Door Number",
                                                      error: props?.data?.error?.doorno,
                                                      errorMessage: props?.data?.error?.doorno?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // Address Line 1
                                                {
                                                      label: "Address Line 1",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: true,
                                                      placeholder: "Enter Address Line 1",
                                                      errorMessage: props?.data?.error?.addressLineOne,
                                                      error: props?.data?.error?.addressLineOne?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // Address Line 2
                                                {
                                                      label: "Address Line 2",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 2",
                                                      errorMessage: props?.data?.error?.addressLineTwo,
                                                      error: props?.data?.error?.addressLineTwo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // Landmark
                                                {
                                                      label: "Landmark",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineThree,
                                                      state_name: 'addressLineThree',
                                                      isrequired: false,
                                                      placeholder: "Enter Landmark",
                                                      errorMessage: props?.data?.error?.addressLineThree,
                                                      error: props?.data?.error?.addressLineThree?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // City
                                                {
                                                      label: "City",
                                                      component: "TextField",
                                                      value: props?.data?.district,
                                                      state_name: 'district',
                                                      isrequired: true,
                                                      placeholder: "Enter City",
                                                      errorMessage: props?.data?.error?.district,
                                                      error: props?.data?.error?.district?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // State
                                                {
                                                      label: "State",
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: true,
                                                      placeholder: "Enter State",
                                                      errorMessage: props?.data?.error?.state,
                                                      error: props?.data?.error?.state?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // Country
                                                {
                                                      label: "Country",
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: true,
                                                      placeholder: "Enter Country",
                                                      errorMessage: props?.data?.error?.country,
                                                      error: props?.data?.error?.country?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                // Pincode
                                                {
                                                      label: "Pincode",
                                                      component: "TextField",
                                                      state_name: 'pincode',
                                                      value: props?.data?.pincode,
                                                      isrequired: true,
                                                      placeholder: "Enter Pincode",
                                                      errorMessage: props?.data?.error?.pincode,
                                                      error: props?.data?.error?.pincode?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }

                        </Box>
                  </div>
                  {/* Contact & Other Information */}
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <CustomTypography>Contact & Other Information</CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} md={4} lg={4}>
                                    {/* Preferred Method Of Contact */}
                                    <SelectBox
                                          isRequired
                                          label="Preferred Method Of Contact"
                                          placeholder="Select Preferred Method Of Contact"
                                          options={enumValue?.method}
                                          value={props?.data?.contact ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("contact", value);
                                          }}
                                          isError={props?.data?.error?.contact?.length > 0}
                                          errorMessage={props?.data?.error?.contact} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    {/* Primary Telephone */}
                                    <MobileNumberInputComponent
                                          label={"Primary Telephone"}
                                          placeholder={"Primary Telephone"}
                                          value={props?.data?.primarytelephone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("primarytelephone", value);
                                          }}
                                          isError={props?.data?.error?.primarytelephone?.length > 0}
                                          errorMessage={props?.data?.error?.primarytelephone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    {/* Primary Mobile */}
                                    <MobileNumberInputComponent
                                          label={"Primary Mobile"}
                                          placeholder={"Primary Mobile"}
                                          value={props?.data?.primarymobile}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("primarymobile", value);
                                          }}
                                          isError={props?.data?.error?.primarymobile?.length > 0}
                                          errorMessage={props?.data?.error?.primarymobile}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    {/* Primary Email Address */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Primary Email Address"
                                          placeholder="Primary Email Address"
                                          value={props?.data?.email ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("email", value?.target?.value);
                                          }}
                                          isError={props?.data?.error?.email?.length > 0}
                                          errorMessage={props?.data?.error?.email} />
                              </Grid>

                              <Grid item xs={12} md={4} lg={4}>
                                    {/* Emergency Contact Relationship */}
                                    <MobileNumberInputComponent
                                          label={"Emergency Contact Relationship"}
                                          placeholder={"Emergency Contact Relationship"}
                                          value={props?.data?.emergencyrelationship}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("emergencyrelationship", value);
                                          }}
                                          isError={props?.data?.error?.emergencyrelationship?.length > 0}
                                          errorMessage={props?.data?.error?.emergencyrelationship}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                        </Grid>
                  </Box>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        {/* Social Profile */}
                        <CustomTypography>Social Profile</CustomTypography>
                        <Grid container spacing={3}>

                              <Grid item xs={12} md={3} lg={2.5}>
                                    {/* Facebook */}
                                    <TextBox
                                          label="Facebook"
                                          placeholder="Enter Facebook"
                                          value={props?.data?.Facebook ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("Facebook", e.target.value);
                                          }}
                                    />

                              </Grid>
                              <Grid item xs={12} md={3} lg={2.5}>
                                    {/* Twitter */}
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Twitter"
                                          placeholder="Enter Twitter"
                                          value={props?.data?.twitter ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("twitter", e.target.value);
                                          }}
                                    />

                              </Grid>
                              <Grid item xs={12} md={3} lg={2.5}>
                                    {/* Linkdedin */}
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Linkdedin"
                                          placeholder="Enter Linkdedin"
                                          value={props?.data?.linkdedin ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("linkdedin", e.target.value);
                                          }}
                                    />

                              </Grid>

                        </Grid>
                  </Box>

                  <div style={{ marginTop: '24px' }}>
                        {/* add member card */}
                        <AddMemberCard isNormal data={list} closeDrawer={closeDrawer} member={props?.data?.member} deleteUser={deleteUser} />

                        {/* add member dialog */}
                        <AlertDialog
                              isNormal
                              header={deleteUser === true ? 'Delete Member' : 'Add Member'}
                              onClose={() => closeDrawer({}, deleteUser === true ? 'deleteclose' : 'close')}
                              open={open}
                              component={
                                    <>
                                          {
                                                deleteUser === false &&
                                                <div style={{ padding: "24px", }}>
                                                      <Grid container>
                                                            <Grid item xs={12}>
                                                                  {/* Job */}
                                                                  <SelectBox
                                                                        isRequired
                                                                        label="Job"
                                                                        placeholder="Select Job"
                                                                        value={props?.data?.job ?? ""}
                                                                        onChange={(value) => {
                                                                              updateState("job", value);
                                                                        }}
                                                                        loading={loading === "job"}
                                                                        isPaginate
                                                                        debounceTimeout={800}
                                                                        loadOptions={(search, array, handleLoading) =>
                                                                              loadOptionsApis(
                                                                                    "queries/roles/list",
                                                                                    {
                                                                                          "orderByKey": "name",
                                                                                          "orderByValue": "asc"
                                                                                    },
                                                                                    search,
                                                                                    array,
                                                                                    handleLoading,
                                                                                    "data",
                                                                                    {},
                                                                                    manualRolesResponse
                                                                              )}
                                                                        isError={props?.data?.error?.job?.length > 0}
                                                                        errorMessage={props?.data?.error?.job} />
                                                            </Grid>
                                                      </Grid>
                                                </div>
                                          }

                                          <div style={{
                                                textAlign: "center",
                                                padding: "24px",
                                          }}>


                                                <div id={'infinity'}>
                                                      {/* search box */}

                                                      {
                                                            deleteUser === false &&
                                                            <div style={{ marginBottom: '24px' }}>
                                                                  <SearchFilter
                                                                        value={searchdata}
                                                                        handleChange={(value) => handleSearch(value)}
                                                                        placeholder="Search Member Details"
                                                                  />
                                                            </div>
                                                      }


                                                      <InfiniteScroll
                                                            dataLength={userDetails?.length ?? ""}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            height={size?.height - 500}
                                                      >
                                                            {
                                                                  deleteUser === true ? <Card1 member={props?.data?.member} deleteMember={deleteMember} /> : <Card data={userDetails} selectUser={selectUser} select={selectedID} />
                                                            }

                                                      </InfiniteScroll>



                                                </div>
                                                {/* add member button */}
                                                {
                                                      deleteUser === false && <Button variant='contained' className={classes.buttoncard} onClick={closeDrawer}> Add Member</Button>
                                                }
                                          </div>
                                    </>
                              }
                        />
                  </div>

                  {/* image viewer */}
                  <AlertDialog isNormal isnotTitle component={
                        <div style={{ display: 'flex' }}>
                              <img src={props?.data?.img} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                        </div>
                  } open={imageViwer} onClose={() => setImageViwer(false)} />
            </div>
      )
}
