import { Box, Divider, Stack } from "@mui/material";
import { useContext } from "react";
import { LineIndicatorTab, LoadingSection, Subheader } from "../../components";
import { accessCheckRender } from "../../utils";
import { SubscriptionsContext } from "./subscriptionsContext";

const Subscriptions = () => {
    const {
        t,
        permission,
        loading,
        subscriptionClasses: classes,
        tab_paths,
        tab_data,
        selected_main_tab_state,
        handleMainTabChange,
        renderTab,
        isScreenLoading,
        size,
    } = useContext(SubscriptionsContext);

    const render = () => {
        return <>
            <Subheader
                hideBackButton
                title={t("Subscriptions")} />
            <Stack className={classes.body} direction={"column"}>
                <Stack direction={"column"} divider={<Divider />}>
                    <Box className={classes.tab_bar_div}>
                        <LineIndicatorTab
                            t={t}
                            path={tab_paths}
                            data={tab_data}
                            selected={selected_main_tab_state}
                            onClick={handleMainTabChange} />
                    </Box>
                </Stack>
                {isScreenLoading
                    ? <Box height={size?.height - (64 + 54 + 44)}>
                        <LoadingSection screen />
                    </Box>
                    : renderTab(selected_main_tab_state)}
            </Stack>
        </>
    }

    return (<>{accessCheckRender(render, permission, "", loading)}</>)
}

export default Subscriptions;
