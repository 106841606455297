import { EditOutlined } from "@mui/icons-material"
import { Box, Button, Divider, Drawer, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { CloseIconWithBG } from "../../../assets"
import { DatePickerNew, SelectBox, TextBox } from "../../../components"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods } from "../../../utils"
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { Steps } from "../../residentOnboarding/steps"
import BillingContactIcon from "../../residentOnboarding/utils/billingContactIcon"
import { useStyles } from "./style"
import { BillingTypeOptions, CustomLoadOptionsApis, InventoryData, ProcurementData } from "./utils"
export const ProgressDetails = ({ t = () => false, state = {}, val = {}, data = {}, setData = () => false, updateState = () => false, companyId = "", addressing_type_options = [] }) => {
    const classes = useStyles()
    const handleResource = (value) => {
        updateState("resource_group", value)
    }
    const manualResponse = (array) => {
        const details = {
            data: array?.data?.map((i) => {
                return {
                    ...i,
                    label: `${i?.title} - ${i?.project_no} (${moment(i?.planned_start_date).format("DD MMM YYYY")} - ${moment(i?.planned_end_date).format("DD MMM YYYY")})`,
                    value: i?.id
                }
            }),
            count: array?.count
        }
        return details
    }
    const contractmanualResponse = (array) => array?.map(i => {
        return {
            ...i,
            label: `${i?.label} (${moment(i?.start_date).format("DD MMM YYYY")} - ${moment(i?.end_date).format("DD MMM YYYY")})`,
            value: i?.value,
        }
    });

    return (
        <Box>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Addressing Type")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <SelectBox
                            value={data?.addressing_type}
                            label=""
                            placeholder={t("Select Addressing Type")}
                            onChange={(value) => updateState("addressing_type", value)}
                            options={addressing_type_options}
                            selectHeight={"auto"}
                            isReadOnly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                        />
                        {/* <Typography className={classes.serviceLevelValues}>{val?.project?.project_no ?? "-"}</Typography> */}
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Planning")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Start Date")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <DatePickerNew
                            label={""}
                            placeholder={val?.placeholder}
                            handleChange={(value) => updateState("planned_start_date", value)}
                            value={data?.planned_start_date}
                            noBorder
                            height="24px"
                            padding="0px"
                            // fontFamily={ExtraBold}
                            // fontSize={remCalc(16)}
                            isReadonly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("End Date")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <DatePickerNew
                            label={""}
                            placeholder={val?.placeholder}
                            handleChange={(value) => updateState("planned_close_date", value)}
                            value={data?.planned_close_date}
                            noBorder
                            height="24px"
                            padding="0px"
                            isReadonly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                        // fontFamily={ExtraBold}
                        // fontSize={remCalc(16)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Actual")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3", justifyContent: "center" }} >
                    <Box p={1.5} >
                        <Typography className={classes.serviceLevelTitle}>{t("Start Date")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <DatePickerNew
                            label={""}
                            placeholder={val?.placeholder}
                            handleChange={(value) => updateState("actual_start_date", value)}
                            value={data?.actual_start_date}
                            noBorder
                            height="24px"
                            // height="30px"
                            padding="0px"
                            // fontFamily={ExtraBold}
                            // fontSize={remCalc(16)}
                            isReadonly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3", justifyContent: "center" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("End Date")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{data?.actual_close_date !== null ? moment(data?.actual_close_date).format("DD MMM YYYY") : "-"}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Last Visit")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Visit Date")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{val?.last_visited_at !== null ? moment(val?.last_visited_at).format("DD MMM YYYY") : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Visited By")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{val?.last_visited_by ?? "-"}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Closure")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Closed On")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{val?.closed_at !== null ? moment(val?.closed_at).format("DD MMM YYYY") : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Closed By")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{val?.closed_by ?? "-"}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            {/* <Grid container alignItems="center">
                <Grid item xs={6}>
                    <Box p={2}>
                        <Typography className={classes.serviceLevelTitle}>{t("Vendor")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ borderLeft: "1px solid #E4E8EE" }}>
                    <Box p={2}>
                        <Typography className={classes.serviceLevelValues}>{val?.vendor?.name ?? "-"}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider /> */}
              <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Agreement Number")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9.5} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{val?.agreement_no!==null?`${val?.agreement_no} (${val?.revenue_type==="Lease"?t("Lease Start Date"):val?.revenue_type==="Manage"?"Manage Start Date":"Contract start Date"}: ${val?.revenue_type==="Sale"?moment(val?.contract_start_date).format("DD MMM YYYY"):moment(val?.lease_start_date).format("DD MMM YYYY")} , ${val?.revenue_type==="Lease"?t("Lease End Date"):val?.revenue_type==="Manage"?"Manage End Date":"Handover Date"}: ${val?.revenue_type!=="Sale"?moment(val?.lease_end_date).format("DD MMM YYYY"):moment(val?.lease_start_date).format("DD MMM YYYY")})`:"-"}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Service Contract Id")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9.5} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <SelectBox
                            value={data?.contract}
                            label=""
                            placeholder={t("Select Service Contract")}
                            selectHeight={"auto"}
                            onChange={(value) => updateState("contract", value)}
                            loadOptions={(search, array, handleLoading) => loadOptionsApis(
                                "contract/get_all_contract",
                                {
                                    company_id: companyId,
                                    contract_type: [3],
                                    // account_id:data?.sla_account_id?.value,
                                    // start_date:details?.agreement_type === "Sale Agreement" ? timeZoneConverter(details?.lease_start_date , "YYYY-MM-DD") : "",
                                    // end_date:details?.agreement_type === "Sale Agreement" ?  timeZoneConverter(details?.lease_end_date , "YYYY-MM-DD") : ""
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                contractmanualResponse,
                                // { all: true }
                            )}
                            // isMulti={true}
                            isPaginate={true}
                            isClearable={true}
                            isReadOnly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                        />
                        {/* <Typography className={classes.serviceLevelValues}>{val?.project?.project_no ?? "-"}</Typography> */}
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Project")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9.5} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <SelectBox
                            value={data?.project}
                            label=""
                            placeholder={t("Select Project")}
                            selectHeight={"auto"}
                            onChange={(value) => updateState("project", value)}
                            loadOptions={(search, array, handleLoading) => CustomLoadOptionsApis(
                                "project/drop_down",
                                {
                                    company_id: companyId
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualResponse,
                                // { all: true }
                            )}
                            // isMulti={true}
                            isPaginate={true}
                            isClearable={true}
                            isReadOnly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                        />
                        {/* <Typography className={classes.serviceLevelValues}>{val?.project?.project_no ?? "-"}</Typography> */}
                    </Box>
                </Grid>
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems={"center"}>
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Resource Group")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9.5} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        {state?.main?.request === "Maintenance" ?
                            <SelectBox
                                value={data?.resource_group}
                                label=""
                                placeholder={t("Select Resource Group")}
                                // selectHeight={"40px"}
                                selectHeight={"auto"}
                                onChange={(value) => handleResource(value)}
                                loadOptions={(search, array, handleLoading) => CustomLoadOptionsApis(
                                    "resource_group_master/drop_down",
                                    {
                                        company_id: companyId
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    false,
                                    // manualResponse,
                                    // { all: true }
                                )}
                                isMulti={true}
                                isPaginate={true}
                                isReadOnly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                            />
                            :
                            <Typography className={classes.serviceLevelValues}>{data?.resource_group ?? "-"}</Typography>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export const ProcurementDetails = ({ t = () => false, state = {}, val = {}, data = {}, setData = () => false, updateState = () => false, companyId = "", addressing_type_options = [] }) => {
    const classes = useStyles()
    return (
        <Box>
            {ProcurementData(val)?.map((x, index) => {
                return (
                    <>
                        <Grid container alignItems="center">
                            <Grid item xs={2.5}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelTitle}>{t(x?.label)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelTitle}>{t(x?.label1)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelValues}>{x?.value1 ?? "-"}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelTitle}>{t(x?.label2)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelValues}>{x?.value2 ?? "-"}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {ProcurementData(val)?.length - 1 !== index &&
                            <Divider className={classes.dividerBGcolor}/>
                        }
                    </>
                )
            })}
        </Box>
    )
}

export const InventoryDetails = ({ t = () => false, state = {}, val = {}, data = {}, setData = () => false, updateState = () => false, companyId = "", addressing_type_options = [] }) => {
    const classes = useStyles()
    const [isBilling, setIsBilling] = React.useState({ bool: false })
    const [billingData, setBillingData] = React.useState({
        type: "service_contract",
        billing: ""
    })
    React.useEffect(() => {
        if (val?.billing_account?.account_no?.length > 0) {
            setBillingData({ type: val?.account_type, billing: { ...val?.billing_account, label: val?.billing_account?.name, value: val?.billing_account?.id } })
        }
        else if (billingData?.type === "customer" || billingData?.type === "service_contract") {
            getBillingOptions(billingData?.type)
        }
        //eslint-disable-next-line
    }, [])
    const getBillingOptions = (type) => {
        const payload = {
            company_id: state?.main?.company,
            "account_type": type,
            type: state?.main?.request,
            request_id: state?.main?.value
        }

        NetworkCall(
            `${config.api_url}/request/get_account`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.map((x) => {
                return {
                    ...x,
                    label: x?.name,
                    value: x?.id
                }
            })
            setBillingData({ ...billingData, type: type, billing: result?.[0] })

        }).catch((err) => {
            console.log(err)
        })
    }
    const updateStateNew = (k, v) => {
        if (k === "type" && (v === "service_contract" || v === "customer")) {
            getBillingOptions(v)
        }
        else if (k === "type" && (v !== "service_contract" || v !== "customer")) {
            setBillingData({ ...billingData, [k]: v, billing: "" })
        }
        else {
            setBillingData({ ...billingData, [k]: v })
        }
    }
    const manualBillingResponse = (array) => {

        const menuList = array?.map((e) => {
            return {
                ...e,
                label: e?.name,
                value: e?.id,
            }
        })
        return menuList
    };
    const onSaveBillingAccount = () => {
        updateState("account_type", billingData?.type, "billing_account", { ...billingData?.billing, label: `${billingData?.billing?.account_no} - ${billingData?.billing?.label}` })
        setIsBilling({ bool: false })
    }
    return (
        <Box>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Billing Account")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9.5} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <TextBox
                            value={data?.billing_account?.label}
                            label=""
                            placeholder={t("Select Billing Account")}
                            // selectHeight={"auto"}
                            onChange={(value) => updateState("billing_account", value)}
                            // loadOptions={(search, array, handleLoading, primary) => {
                            //     return loadOptionsApis(
                            //         "request/get_account",
                            //         {
                            //             company_id: companyId,
                            //             "account_type": data?.type,
                            //             type: state?.main?.request,
                            //             request_id: state?.main?.value
                            //         },
                            //         search,
                            //         array,
                            //         handleLoading,
                            //         "data",
                            //         {},
                            //         manualBillingResponse
                            //     )
                            // }}
                            // isMulti={true}
                            // isPaginate={true}
                            textColor="#091B29"
                            endAdornment={(val?.status !== "Closed" && val?.status !== "Cancelled" && val?.status !== "Rejected") &&
                                <Box p={1.5} sx={{ cursor: "pointer" }} onClick={() => setIsBilling({ bool: true })}>
                                    <EditOutlined color="primary" sx={{ fontSize: "18px" }} />
                                </Box>
                            }
                            isReadonly={true}
                        />
                        {/* <Typography className={classes.serviceLevelValues}>{val?.project?.project_no ?? "-"}</Typography> */}
                    </Box>
                </Grid>
                {/* {(val?.status !== "Closed" && val?.status !== "Cancelled" && val?.status !== "Rejected") &&
                    <Grid item xs={1}>
                        <Box p={1.5} sx={{ cursor: "pointer" }} onClick={() => setIsBilling({ bool: true })}>
                            <EditOutlined color="primary" sx={{ fontSize: "18px" }} />
                        </Box>
                    </Grid>
                } */}
            </Grid>
            <Divider className={classes.dividerBGcolor}/>
            {InventoryData(val)?.map((x, index) => {
                return (
                    <>
                        <Grid container alignItems="center">
                            <Grid item xs={2.5}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelTitle}>{t(x?.label)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelTitle}>{t(x?.label1)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelValues}>{x?.value1 ?? "-"}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelTitle}>{t(x?.label2)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2.75} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                <Box p={1.5}>
                                    <Typography className={classes.serviceLevelValues}>{x?.value2 ?? "-"}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {InventoryData(val)?.length - 1 !== index &&
                            <Divider className={classes.dividerBGcolor}/>
                        }
                    </>
                )
            })}
            <Divider className={classes.dividerBGcolor}/>
            <Grid container alignItems="center">
                <Grid item xs={2.5}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Comparison")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Projected")}</Typography>
                    </Box>
                    <Divider orientation="horizontal" className={classes.dividerBGcolor}/>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{state?.main?.companyData?.currency_symbol} {val?.projected_amount ?? 0}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Consumed")}</Typography>
                    </Box>
                    <Divider orientation="horizontal" className={classes.dividerBGcolor}/>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{state?.main?.companyData?.currency_symbol} {val?.consumed_amount ?? 0}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3.5} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelTitle}>{t("Billing")}</Typography>
                    </Box>
                    <Divider orientation="horizontal" className={classes.dividerBGcolor}/>
                    <Box p={1.5}>
                        <Typography className={classes.serviceLevelValues}>{state?.main?.companyData?.currency_symbol} {val?.billing_amount ?? 0}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Drawer
                anchor={"right"}
                open={isBilling?.bool}
                onClose={() => setIsBilling({ bool: false })}>
                <Box width="500px">
                    <Stack direction="row" spacing={1} alignItems={"center"} p={1.5}>
                        <Box sx={{ cursor: "pointer" }} onClick={() => setIsBilling({ isBilling: false })}><CloseIconWithBG /></Box>
                        <Typography>{t("Choose Billing Account")}</Typography>
                    </Stack>
                    <Divider />
                    <Box p={1.5} height="570px">
                        <Steps
                            t={t}
                            component={[
                                {
                                    type: "toggleButton",
                                    option: BillingTypeOptions(t),
                                    value: billingData?.type,
                                    handleChange: (value) => updateStateNew("type", value),
                                    header: t("Billing Type"),
                                    // error: dataNew?.error?.purpose?.length > 0,
                                    // errorMsg: dataNew?.error?.purpose,
                                    isActive: true,
                                    isrequired: true,
                                    // isReadOnly: is_read_only,

                                },
                                {
                                    type: "chooseComponent",
                                    header: t("Choose the Billing Account"),
                                    title: t('Choose Billing Account'),
                                    noSelectedTitle: t("No account selected to display"),
                                    icon: <BillingContactIcon />,
                                    options: [],
                                    handleRemove: () => updateStateNew("billing", null),
                                    onchange: (value) => updateStateNew("billing", value),
                                    value: billingData?.billing,
                                    onDelete: () => updateStateNew("billing", null),
                                    key: JSON.stringify(billingData?.type),
                                    loadOptions: (billingData?.type === "service_contract" || billingData?.type === "customer") ? null : (search, array, handleLoading, primary) => {
                                        return loadOptionsApis(
                                            "request/get_account",
                                            {
                                                company_id: state?.main?.company,
                                                "account_type": billingData?.type,
                                                type: state?.main?.request,
                                                request_id: state?.main?.value
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualBillingResponse
                                        )
                                    },
                                    isActive: true,
                                    isRequired: false,
                                    // menuPlacement: dataNew?.is_exist ? 'bottom' : 'top',
                                    isReadOnly: (billingData?.type === "service_contract" || billingData?.type === "customer"),
                                    border: "none",
                                    boldHeader: true,
                                    isBorder: true,
                                    showRemove: false,
                                    is_toggle: false,
                                    // state1: state1,
                                    // setState1: (e) => setState1(e ?? false),
                                    t

                                },
                            ]}
                        />
                    </Box>
                    <Box p={1.5}>
                        <Button variant="contained" fullWidth onClick={() => onSaveBillingAccount()}>{t("Update")}</Button>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    )
}
