/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, Bold, ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import { OwnerDetails } from './ownerDetails';


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "hidden"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

export const OwnerTableDetails = props => {

    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [ownerDetailsData, setOwnerDetailsData] = React.useState([])
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const companyId = new URLSearchParams(search).get("companyId");
    const getOwnerDetailsId = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            userId: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/owner`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setOwnerDetailsData(response?.data?.data);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong please try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            });

    };

    React.useEffect(() => {
        getOwnerDetailsId();
        // eslint-disable-next-line
    }, []);

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} >

                <>
                    <OwnerDetails data={ownerDetailsData[0]} />
                </>

            </Grid>
        </Grid>

    </div>
}