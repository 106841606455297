import { Box, Typography } from "@mui/material";
import React from "react";
import { Account as AccountIMG } from "../../assets";
import { SearchFilter } from "../../components";
import { AccountList } from "../../screens/quotationDetails/components/accountList";
import { NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { useStyles } from "./styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

export const AccountListComponet = ({
  company_id = "",
  selectedAcc = "",
  chooseAccount = () => false,
  t,
}) => {
  const classes = useStyles();
  const [account, setAccout] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [offset, setOffset] = React.useState(0);

  const size = useWindowDimensions();

  const getAccount = (searchText, offset, filter) => {
    setSearch(searchText);
    NetworkCall(
      `${config.api_url}/account/get_all`,
      NetWorkCallMethods.post,
      {
        company_id: company_id,
        search: searchText,
        offset: offset ?? 0,
        limit: 10,
      },
      null,
      true,
      false
    )
      .then((response) => {
        setAccout(
          filter ? account.concat(response.data.data) : response.data.data
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(true);
  };
  const choose = (val) => {
    chooseAccount(val);
    setAnchorEl(false);
  };

  // infinity scroll
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getAccount(search, offset, true);
  };

  React.useEffect(() => {
    getAccount("");
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box className={classes.parent}>
        <Box onClick={handleClick} aria-describedby={id}>
          <SearchFilter
            value={search}
            handleChange={(e) => getAccount(e)}
            placeholder={t("Search Existing Account")}
          />
        </Box>

        {anchorEl && (
          <div className={classes.accountListRoot}>
            <InfiniteScroll
              dataLength={account?.length ?? ""}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 400}
            >
              <Box className={classes.accountListRoot}>
                {account?.map((val) => {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      p={1}
                      onClick={() => choose(val)}
                      style={{
                        backgroundColor:
                          val?.id === selectedAcc?.id ? "#ccdaffc4" : "#fff",
                      }}
                    >
                      <Box className={classes.AccountImg1}>
                        <AccountIMG color="#98A0AC" />
                      </Box>
                      <Box flexGrow={1} marginInlineStart="8px">
                        <Typography className={classes.accountNamelist}>
                          {val?.name}
                        </Typography>
                        <Typography className={classes.accountNo}>
                          {val?.account_no}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </InfiniteScroll>
          </div>
        )}
      </Box>

      <Box height="12px" />

      {!anchorEl && selectedAcc?.id && (
        <AccountList
          iconColor="#5AC782"
          color="#EEF9EE"
          disable
          value={selectedAcc}
          t={t}
        />
      )}
    </Box>
  );
};
