/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Backdrop, Box,
  Button, CircularProgress, Grid,
  IconButton,
  Typography
} from "@mui/material";
import Tab from "@mui/material/Tab";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  DrawerComponent, SearchFilter,
  Subheader,
  TableWithPagination
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, Bold, ExtraBold, NetWorkCallMethods, getCompanyOption } from "../../utils";
import { announsment, announsmentRow } from "../../utils/tableData";
import { FilterComponent } from "./filter";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    // height: `calc(100vh - 88px)`,
    margin: '16px'

  },

  Apply: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
    marginRight: "10px",
  },
  draft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#4E5A6B",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginLeft: "10px",
    fontSize:"0.75rem",
  },
  draft1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#5078E1",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginLeft: "10px",
    fontSize:"0.75rem",
  },
  header: {
    border: "1px solid #E4E8EE",
    fontSize:"1rem",
    fontFamily: ExtraBold,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px",
    backgroundColor:"#F2F4F7"
  },
  closeDialog: {
    "& .MuiPaper-root": {
      maxWidth: "400px !important",
      width: "100% !important",
      borderRadius: "12px",
    },
  },
  drawerwidth: {
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "60px",
    padding: "16px 16px 16px",
    justifyContent: "left",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
    "& .MuiTypography-root": {
      fontSize:"1rem",
      fontWeight: "bold",
      fontFamily: Bold,
    },
  },
  closeIcondrawermap: {
    right: "400px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize:"0.875rem",
    },
  },
  createnewsection: {
    padding: "10px 15px 65px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
  },
  btnparent: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    padding: "10px 16px",
    backgroundColor: "#fff",
    borderTop: "1px solid #00000012",
    "& .MuiButton-root": {
      width: "100%",
    },
  },
  clearall: {
    backgroundColor: "#fff",
    border: "1px solid #E4E8EE",
    color: "#091B29",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #E4E8EE",
    },
  },
}));

export const Announsment = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const [value, setValue] = React.useState("1");
  const [number, setNumber] = React.useState(false);
  const [filter, setFilter] = React.useState(false);
  const auth = React.useContext(AuthContext);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const initial1 = {
    propperty: "",
    company: selectedCompany,
    catagory: "",
    error: {
      propperty: "",
      company: "",
      catagory: "",
    },
  };
  const [filterdata, setFilterdata] = React.useState(initial1);
  const [list, setList] = React.useState([]);
  const [list1, setList1] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [page1, setPage1] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [limit1, setLimit1] = React.useState(10);
  const [total, setTotal] = React.useState("");
  const [total1, setTotal1] = React.useState("");
  const [loading, setLoading] = React.useState(null);
  const backdrop = React.useContext(BackdropContext);
  const [searchdata, setsearchdata] = React.useState(null);
  const [searchdata1, setsearchdata1] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const [filterTrue, setfilterTrue] = React.useState(false);
  const [editData, setEditdata] = React.useState({});
  const [loader, setLoder] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyList, setCompanyList] = React.useState([])

  const apply = (v) => {
    if (v === "published") {
      if (filterdata?.company?.length === 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select company",
        });

      } else if (filterdata?.propperty?.length === 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select property",
        });

      }
      else if (filterdata?.catagory?.length === 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select catagory",
        });

      } else {
        getTable(0, 10, searchdata, "filter", selectedCompany);
      }

    }
    if (v === "Draft") {
      getTable1(0, 10, searchdata, "filter", selectedCompany);
    }
  };

  const handleClicktool = (event, value) => {

    setRoles(value?.role_id);
    setAnchorEl(event.currentTarget);
  };

  const handleClosetool = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const idtool = open ? "simple-popover" : undefined;

  const handleChange = (event, newValue) => {
    setNumber(false);
    setValue(newValue);
    if (newValue === "2") {
      setNumber(true);
    }
  };
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    if (filterTrue === true) {
      getTable(offset, limit, searchdata, "filter");
    } else {
      getTable(offset, limit, searchdata, "key");
    }
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    if (filterTrue === true) {
      getTable(0, value, searchdata, "filter");
    } else {
      getTable(0, value, searchdata, "key");
    }
  };

  const handlePagination1 = (value) => {
    setPage1(value);
    let offset = (value - 1) * limit;
    if (filterTrue === true) {
      getTable1(offset, limit1, searchdata1, "filter");
    } else {
      getTable1(offset, limit1, searchdata1, "key");
    }
  };

  const handleChangeLimit1 = (value) => {
    setLimit1(value);
    setPage1(1);
    if (filterTrue === true) {
      getTable1(0, value, searchdata1, "filter");
    } else {
      getTable1(0, value, searchdata1, "key");
    }
  };
  const OpenDrawer = () => {
    setFilter(!filter);
  };
  const create = () => {
    history.push({
      pathname: "/createannounsment",
      state: {
        main: {
          selectedCompany: selectedCompany,
        },
      },
    });
  };
  const upDateFilter = (key, value) => {
    let error = filterdata.error;
    error[key] = "";
    setFilterdata({ ...filterdata, [key]: value, error });
  };

  const loadOptionsfilter = async (search = "", array, type) => {
    setLoading(type);
    let result,
      query,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }
    let companyID = [filterdata?.company?.value];

    switch (type) {
      default:
        return { options: [] };
    }
  };
  const networkCallBack = async (query, variable = {}) => {   
    return false;
  };
  const getTable = (offset, limit, value, key, company) => {
    setLoder(true)
    if (key === "filter") {
      setsearchdata(value);

      const requestPayload = {
        tenantId: `${config.tenantId}`,
        company_id: [company?.value],
        type: ["Announcement"],
        property_id:
          filterdata?.propperty?.map((x) => x?.value)?.length > 0
            ? filterdata?.propperty?.map((x) => x?.value)
            : undefined,
        is_draft: false,
        category:
          filterdata?.catagory?.map((x) => x?.value).length > 0
            ? filterdata?.catagory?.map((x) => x?.value)
            : undefined,
        query: value?.length > 0 ? value : undefined,
        offset: offset,
        limit: limit,
      };
      NetworkCall(
        `${config.api_url}/notifications/list`,
        NetWorkCallMethods.post,
        requestPayload,
        null,
        true,
        false
      )
        .then((res) => {
          setFilter(false);
          setfilterTrue(true);
          setTotal(res?.data?.data?.totalRecords);
          let arry = [];
          if (
            res?.data?.data?.response &&
            res?.data?.data?.response.length > 0
          ) {
            res?.data?.data?.response?.map((val, index) => {
              let obj = {
                sno: offset + index + 1,
                index: offset + index + 1,
                Category: val?.category ?? "-",
                Announcement_Title: val?.title ?? "-",
                Assigned_Property: val?.property_name ?? "-",
                Assigned_Roles: val?.role_id?.length ?? "-",
                Roles: val?.name ?? "-",
                Created_By: val?.first_name ?? "-",
                icon: "viewdelete",
                ...val,
              };

              arry.push(obj);
            });
          }
          setList(arry);
          setFilter(false);
          setLoder(false)
          backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        })
        .catch((error) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.warning,
            msg: "Some Thing Went Wrong",
          });

          setLoder(false)
        });
    } else {
      setsearchdata(value);

      const requestPayload = {
        company_id: [company?.value ?? ""],
        type: ["Announcement"],
        is_draft: false,
        content_type: [
          "Security",
          "Maintenance",
          "Events",
          "General",
          "Finance",
          "Home services",
          "Feature Services",
          "Featured Products",
        ],
        query: value?.length > 0 ? value : undefined,
        offset: offset,
        limit: limit,
      };
      NetworkCall(
        `${config.api_url}/notifications/list`,
        NetWorkCallMethods.post,
        requestPayload,
        null,
        true,
        false
      )
        .then((res) => {
          setTotal(res?.data?.data?.totalRecords);
          let arry = [];
          if (
            res?.data?.data?.response &&
            res?.data?.data?.response.length > 0
          ) {
            res?.data?.data?.response?.map((val, index) => {
              let obj = {
                sno: offset + index + 1,
                index: offset + index + 1,
                Category: val?.category ?? "-",
                Announcement_Title: val?.title ?? "-",
                Assigned_Property: val?.property_name ?? "-",
                Assigned_Roles: val?.role_id?.length ?? "-",
                Roles: val?.name ?? "-",
                Created_By: val?.first_name ?? "-",
                icon: "deleteIcon",
                ...val,
              };

              arry.push(obj);
            });
          }
          setList(arry);
          setLoder(false)
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong. Please try again",
          });

          setLoder(false)

        });
    }
  };
  const getTable1 = (offset, limit, value, key, company) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    setLoder(true)
    if (key === "filter") {

      setsearchdata1(value);

      const requestPayload = {
        tenantId: `${config.tenantId}`,
        company_id:
          filterdata?.company?.value.length > 0
            ? [filterdata?.company?.value]
            : undefined,
        type: ["Announcement"],
        property_id:
          filterdata?.propperty?.map((x) => x?.value)?.length > 0
            ? filterdata?.propperty?.map((x) => x?.value)
            : undefined,
        is_draft: false,
        category:
          filterdata?.catagory?.map((x) => x?.value).length > 0
            ? filterdata?.catagory?.map((x) => x?.value)
            : undefined,
        query: value?.length > 0 ? value : undefined,
        offset: offset,
        limit: limit,
      };
      NetworkCall(
        `${config.api_url}/notifications/list`,
        NetWorkCallMethods.post,
        requestPayload,
        null,
        true,
        false
      )
        .then((res) => {
          setTotal1(res?.data?.data?.totalRecords);
          let arry = [];
          if (
            res?.data?.data?.response &&
            res?.data?.data?.response.length > 0
          ) {
            res?.data?.data?.response?.map((val, index) => {
              let obj = {
                sno: offset + index + 1,
                index: offset + index + 1,
                Category: val?.category ?? "-",
                Announcement_Title: val?.title ?? "-",
                Assigned_Property: val?.property_name ?? "-",
                Assigned_Roles: val?.role_id?.length ?? "-",
                Created_By: val?.first_name ?? "-",
                icon: "more_2",
                ...val,
              };

              arry.push(obj);
            });
          }
          setList1(arry);
          setLoder(false)
          backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        })
        .catch((error) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong. Please try again",
          });

          setLoder(false)
        });
    } else {
      setsearchdata1(value);
      setLoder(true)
      const requestPayload = {
        tenantId: `${config.tenantId}`,
        company_id: [company?.value ?? ""],
        type: ["Announcement"],
        is_draft: true,
        content_type: [
          "Security",
          "Maintenance",
          "Events",
          "General",
          "Finance",
          "Home services",
          "Feature Services",
          "Featured Products",
        ],
        query: value,
        offset: offset,
        limit: limit,
      };
      NetworkCall(
        `${config.api_url}/notifications/list`,
        NetWorkCallMethods.post,
        requestPayload,
        null,
        true,
        false
      )
        .then((res) => {
          setTotal1(res?.data?.data?.totalRecords);
          let arry = [];
          if (
            res?.data?.data?.response &&
            res?.data?.data?.response.length > 0
          ) {
            res?.data?.data?.response?.map((val, index) => {
              let obj = {
                sno: offset + index + 1,
                index: offset + index + 1,
                Category: val?.category ?? "-",
                Announcement_Title: val?.title ?? "-",
                Assigned_Property: val?.property_name ?? "-",
                Assigned_Roles: val?.role_id?.length ?? "-",
                Created_By: val?.first_name ?? "-",
                icon: "viewdelete",
                ...val,
              };

              arry.push(obj);
            });
          }
          setList1(arry);
          setLoder(false)
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((error) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong. Please try again",
          });

          setLoder(false)
        });
    }
  };
  const handleIcon = (key, v) => {
    return false
  };

  const handleIcon1 = (key, v) => {
    if (key === "edit") {
      setEditdata(v);
      history.push({
        pathname: "/createannounsment",
        state: {
          main: {
            editData: v,
            isEdit: true,
          },
        },
      });
    }
  };

  const getList = (company) => {
    getTable(0, 10, searchdata, "key", company);
    getTable1(0, 10, searchdata1, "key", company);
    setFilterdata({ ...filterdata, propperty: "", company: company });
  }
  React.useEffect(() => {
    if (localStorage.getItem("authToken")) {
      let company = getCompanyOption(backdrop, auth, alert)
      if (company) {
        setCompanyList(company?.list)
        setSelectedCompany(company?.selected)
        getList(company?.selected)
      }
    } else {
      // history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <>
      {/* sub nav bar */}
      <Subheader hideBackButton={true} title={`announcement`} select options={companyList ?? []} value={selectedCompany}
        onchange={(e) => {
          setSelectedCompany(e)
          getList(e);
        }

        } />
      <div className={classes.root}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Published" value="1" />
                <Tab
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Draft
                      <span className={number ? classes.draft1 : classes.draft}>
                        {total1}
                      </span>
                    </span>
                  }
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              {/* published */}
              <Grid container className={classes.content} spacing={2}>
                <Grid item xs={4}>
                  <SearchFilter
                    placeholder="Search Assigned Property"
                    value={searchdata}
                    handleChange={(value) => getTable(0, 10, value, "filter", selectedCompany)}
                  />
                </Grid>

                <Grid item xs={8} textAlign={"right"}>
                  <Box display={"flex"} sx={{ float: "right" }}>
                    <Box>
                      <IconButton
                        size="small"
                        className={classes.img}
                        onClick={OpenDrawer}
                      >
                        <img src="/images/filter.svg" alt="filter" />
                      </IconButton>
                    </Box>
                    <Button className={classes.Apply} onClick={create}>
                      Create Announcement
                    </Button>
                    <Box></Box>
                  </Box>
                </Grid>

                {/* Main tableData */}
                <Grid item xs={12} className={classes.tableMain}>
                  <TableWithPagination
                    heading={announsment}
                    rows={list}
                    path={announsmentRow}
                    showpagination={true}
                    onClick={() => false}
                    showpdfbtn={false}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    page={page}
                    totalRowsCount={total}
                    limit={limit}
                    id={idtool}
                    open={open}
                    anchorEl={anchorEl}
                    roles={roles}
                    handleClose={handleClosetool}
                    handleHover={handleClicktool}
                    handleIcon={handleIcon}
                    showexcelbtn={false}
                    showSearch={false}
                    dataType={[
                      { type: ["text"], name: "sno" },
                      { type: ["text"], name: "Category" },
                      { type: ["text"], name: "Announcement_Title" },
                      { type: ["text"], name: "Assigned_Property" },
                      { type: ["tooltip"], name: "Assigned_Roles" },
                      { type: ["text"], name: "Created_By" },
                      { type: ["icon"], icon: "icon" },
                    ]}
                    height={'calc(100vh - 380px)'}
                    view={true}
                    edit={true}
                    delete={true} />
                </Grid>
                {/* end Main tableData */}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              {/* draft */}
              <Grid container className={classes.content} spacing={2}>
                <Grid item xs={4}>
                  <SearchFilter
                    placeholder="Search Assigned Property"
                    value={searchdata1}
                    handleChange={(value) => getTable1(0, 10, value, "filter", selectedCompany)}
                  />
                </Grid>

                <Grid item xs={8} textAlign={"right"}>
                  <Box display={"flex"} sx={{ float: "right" }}>
                    <Box>
                      <IconButton
                        size="small"
                        className={classes.img}
                        onClick={OpenDrawer}
                      >
                        <img src="/images/filter.svg" alt="filter" />
                      </IconButton>
                    </Box>
                    <Button className={classes.Apply} onClick={create}>
                      Create Announcement
                    </Button>
                    <Box></Box>
                  </Box>
                </Grid>

                {/* Main tableData */}
                <Grid item xs={12} className={classes.tableMain}>
                  <TableWithPagination
                    heading={announsment}
                    rows={list1}
                    path={announsmentRow}
                    showpagination={true}
                    onClick={() => false}
                    showpdfbtn={false}
                    handlePagination={handlePagination1}
                    handleChangeLimit={handleChangeLimit1}
                    page={page1}
                    totalRowsCount={total1}
                    limit={limit1}
                    id={idtool}
                    open={open}
                    anchorEl={anchorEl}
                    roles={roles}
                    handleClose={handleClosetool}
                    handleHover={handleClicktool}
                    handleIcon={handleIcon1}
                    showexcelbtn={false}
                    showSearch={false}
                    dataType={[
                      { type: ["increment"], name: "sno" },
                      { type: ["text"], name: "Category" },
                      { type: ["text"], name: "Announcement_Title" },
                      { type: ["text"], name: "Assigned_Property" },
                      { type: ["tooltip"], name: "Assigned_Roles" },
                      { type: ["text"], name: "Created_By" },
                      { type: ["more_2"], icon: "icon" },
                    ]}
                    height={'calc(100vh - 380px)'}
                    view={true}
                    edit={true}
                    delete={true} />
                </Grid>
                {/* end Main tableData */}
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
        {/* filter */}
        <DrawerComponent
          open={filter}
          onClose={OpenDrawer}
          toggleDrawer={OpenDrawer}
          component={
            <>
              <div className={classes.drawerwidth}>
                <Box className={classes.Header}>
                  <Box className={classes.closeIcondrawermap}>
                    <Typography variant="h6">
                      <img
                        src="\images\closebutton.svg"
                        alt="close"
                        onClick={OpenDrawer}
                      />
                    </Typography>
                  </Box>
                  <Typography variant="h6" className={classes.title} noWrap>
                    Filter
                  </Typography>
                </Box>
                <div className={classes.createnewsection}>
                  <FilterComponent
                    filterdata={filterdata}
                    upDateFilter={upDateFilter}
                    loadOptionsfilter={loadOptionsfilter}
                    loading={loading}
                  />
                </div>
                <Box className={classes.btnparent}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="outlined"
                        // onClick={clearAll}
                        className={classes.clearall}
                      >
                        Clear All
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => apply(number ? "Draft" : "published")}
                        // onClick={() => getTable(0, 10, searchdata, "filter")}
                        className={classes.Apply}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};
