import * as React from "react"
const ProjectedHourIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <defs>
      <radialGradient
        id="b"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0.497}
        cy={0.012}
        r={1.114}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fafafb" />
        <stop offset={1} stopColor="#c8cdd1" />
      </radialGradient>
      <linearGradient
        id="a"
        x1={0.146}
        x2={0.854}
        y1={0.146}
        y2={0.854}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#889097" />
        <stop offset={1} stopColor="#64717c" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0.449}
        x2={0.557}
        y1={0.012}
        y2={1.052}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c8cdd1" />
        <stop offset={1} stopColor="#a6aaad" />
      </linearGradient>
    </defs>
    <g data-name="Group 123275" transform="translate(-4 -4)">
      <circle
        cx={9}
        cy={9}
        r={9}
        fill="url(#a)"
        data-name="Ellipse 131192"
        transform="translate(4 4)"
      />
      <circle
        cx={8.325}
        cy={8.325}
        r={8.325}
        fill="url(#b)"
        data-name="Ellipse 131193"
        transform="translate(4.675 4.675)"
      />
      <circle
        cx={7.65}
        cy={7.65}
        r={7.65}
        fill="url(#c)"
        data-name="Ellipse 131194"
        transform="translate(5.35 5.35)"
      />
      <path
        fill="url(#d)"
        d="M16.2 9.45a.45.45 0 1 1-.45-.45.45.45 0 0 1 .45.45Zm-.45 12.15a.45.45 0 1 0 .45.45.45.45 0 0 0-.45-.45Zm6.3-6.3a.45.45 0 1 0 .45.45.45.45 0 0 0-.45-.45Zm-12.6 0a.45.45 0 1 0 .45.45.45.45 0 0 0-.45-.45Z"
        data-name="Path 102300"
        transform="translate(-2.75 -2.75)"
      />
      <path
        fill="#d83b01"
        d="M13 17.838a.338.338 0 0 1-.337-.337v-4.5a.337.337 0 0 1 .675 0v4.5a.338.338 0 0 1-.338.337Z"
        data-name="Path 102301"
      />
      <path
        fill="#45494d"
        d="M13 13.338a.338.338 0 0 1-.239-.576l3.818-3.818a.338.338 0 1 1 .477.477l-3.819 3.818a.335.335 0 0 1-.237.099Z"
        data-name="Path 102302"
      />
      <path
        fill="#45494d"
        d="M13 13.562a.56.56 0 0 1-.243-.055l-3.5-1.681a.562.562 0 0 1 .486-1.014l3.5 1.681a.563.563 0 0 1-.243 1.07Z"
        data-name="Path 102303"
      />
      <circle
        cx={0.9}
        cy={0.9}
        r={0.9}
        fill="#1e2021"
        data-name="Ellipse 131195"
        transform="translate(12.1 12.1)"
      />
    </g>
  </svg>
)
export default ProjectedHourIcon
