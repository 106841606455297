import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";

export const loadOptionsApis = async (
    endpoint,
    queryVariables,
    search = "",
    array = [],
    handleLoading = null,
    extractLabel,
    mappingVariable = {},
    manualResponse,
) => {


    let result,
        start = 0;

    if (search && !Boolean(array?.length)) {
        start = 0;
    } else {
        start = array?.length;
    }
    result = await networkCallBackApi(endpoint, { ...queryVariables, search }, handleLoading, start);

    //Select options should be label,value pairs like ({label:'',value:''})
    if (mappingVariable?.label || mappingVariable?.value) {
        result[extractLabel] = result?.[extractLabel]?.map((_) => ({
            label: _?.[mappingVariable?.label ?? "label"],
            value: _?.[mappingVariable?.value ?? "value"],
        }));
    }

    //manually construct array
    if (manualResponse) {
        result[extractLabel] = manualResponse(result[extractLabel]);
    }
    return {
        options: [...result?.[extractLabel]],
        hasMore:
            array?.length + result?.[extractLabel]?.length < (result?.count?.[0]?.count??result?.count),
    };
};

const networkCallBackApi = async (endpoint, variable = {}, handleLoading, start) => {

    let payload = {
        ...variable,
        start, length: 10,
        offset: start, limit: 10,
    };

    const options = await NetworkCall(
        `${config.api_url}/${endpoint}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
            let main = response.data;
            handleLoading && handleLoading();
            return main;
        })
        .catch((error) => {
            handleLoading && handleLoading();
            return null;
        });

    return options;
};
