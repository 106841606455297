import * as React from "react"
const InactiveInternalProjectIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={26} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.997}
        x2={-0.015}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0068b8" />
        <stop offset={1} stopColor="#004d94" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.014}
        x2={1.024}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#28afea" />
        <stop offset={1} stopColor="#0b88da" />
      </linearGradient>
    </defs>
    <g data-name="Group 121811">
      <g
        data-name="Group 118072"
        style={{
          mixBlendMode: "darken",
          isolation: "isolate",
        }}
        transform="translate(-120 -299)"
      >
        <g data-name="icons8-project (4)">
          <path
            fill="url(#a)"
            d="M4 10h22.8a1.2 1.2 0 0 1 1.2 1.2v15.6a1.2 1.2 0 0 1-1.2 1.2H8.2A4.2 4.2 0 0 1 4 23.8Z"
            data-name="Path 102132"
            transform="translate(116.5 294.6)"
          />
          <path
            fill="url(#b)"
            d="M8.2 17.2h1.2a1.2 1.2 0 0 0 1.2-1.2V5.2A1.2 1.2 0 0 0 9.4 4H8.2A4.2 4.2 0 0 0 4 8.2v13.2a4.2 4.2 0 0 1 4.2-4.2Z"
            data-name="Path 102133"
            transform="translate(116.5 297)"
          />
          <path
            fill="#35c1f1"
            d="M129.2 317.2h11.4a.3.3 0 0 1 .3.3v.6a.3.3 0 0 1-.3.3h-11.4a.3.3 0 0 1-.3-.3v-.6a.3.3 0 0 1 .3-.3Z"
            data-name="Path 102134"
          />
          <path
            fill="#35c1f1"
            d="m129.712 315.012-.424-.424a.3.3 0 0 1 0-.424l3.9-3.9a.3.3 0 0 1 .424 0l2.188 2.187 3.176-3.176.848.848-3.812 3.812a.3.3 0 0 1-.424 0l-2.188-2.187-3.263 3.263a.3.3 0 0 1-.425.001Z"
            data-name="Path 102135"
          />
          <path
            fill="#35c1f1"
            d="m137.817 308.712 2.576 2.576a.3.3 0 0 0 .512-.212V308.5a.3.3 0 0 0-.3-.3h-2.581a.3.3 0 0 0-.207.512Z"
            data-name="Path 102136"
          />
        </g>
        <rect
          width={25}
          height={26}
          fill="#fff"
          data-name="Rectangle 58616"
          rx={1}
          style={{
            mixBlendMode: "hue",
            isolation: "isolate",
          }}
          transform="translate(120 299)"
        />
      </g>
    </g>
  </svg>
)
export default InactiveInternalProjectIcon
