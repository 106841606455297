import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";
import { NetWorkCallMethods, enumSelect, enum_types } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
const RenewalInformation = ({ details = {}, reload = () => false, t }) => {
    const [enumValue, setEnum] = React.useState({
        auto_renewal_escalation: []
    })
    //get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types?.auto_renewal_escalation])
        setEnum({
            auto_renewal_escalation: result?.auto_renewal_escalation
        })
    }
    //initial load
    React.useEffect(() => {
        getEnum()
    }, [])
    //onchange Renewal
    const onChangeRenewal = (value) => {
        const payload = {
            id: details?.id,
            update: {
                auto_renewal_escalation: value?.value
            }
        }
        NetworkCall(
            `${config.api_url}/queries/quotation/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "select",
            heading: t("renewalType"),
            value: {
                label: details?.auto_renewal_escalation,
                value: details?.auto_renewal_escalation
            },
            options: enumValue?.auto_renewal_escalation,
            onChange: (value) => onChangeRenewal(value)
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "select",
            heading: t("utilityInvoicing"),
            subtitle: "12-03-22"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "select",
            heading: t("amenityInvoice"),
            subtitle: "Lease"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "select",
            heading: t("serviceMarketplaceInvoice"),
            subtitle: "Manage"
        }
    ]

    return (
        <Box p={2}>
            <DetailsCardCoponent components={card} />
        </Box>
    )
}
export default withNamespaces("agreement")(RenewalInformation); 