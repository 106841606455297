import { Avatar, Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from "../../assets/closeIcon";
import FilterIMG from "../../assets/filter";
import InfoIcon from "../../assets/infoIcon";
import RightCheveronIcon from "../../assets/rightCheveronIcon";
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { AddQuestionsComponent as AddQuestions, ComponentTypeOptions, DisableFormQuestionSwitchListCategory, FormQuestionSwitchList1, FormQuestionSwitchList2, FormQuestionSwitchList2Category, PricingComponentHeading, PricingComponentPath, PricingComponentType } from "../../utils/pricingComponent";
import { LightTooltip, useStyles } from "./style";
import { withNamespaces } from "react-i18next";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { BackendRoutes } from "../../router/routes";

const PricingComponent = ({
    t
}) => {
    const defaultFilterState = { status: [true] };

    const defaultAddNewState = {
        formType: "question",
        question: "",
        category: "",
        info: "",
        primaryColor: "",
        secondaryColor: "",
        id: "",
        initial_name: "",
        name: "",
        services: "",
        initialRevenueType: { value: "Lease", label: "Lease" },
        revenueType: { value: "Lease", label: "Lease" },
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        hsn_sac_code: null,
        tax_group: null,
        is_wallet: false,
        is_late_fee: false,
        error: {
            name: "",
            services: "",
        }
    }
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const [pricingComponentList, setPricingComponentList] = React.useState({});
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    const [filterDrawer, setFilterDrawer] = React.useState(null);
    const [addNewDialog, setAddNewDialog] = React.useState(null);
    const [addNewState, setAddNewState] = React.useState({ ...defaultAddNewState });
    const debounce = UseDebounce();
    const [buttonDisable, setButtonDisable] = React.useState(false);
    const [option, set_option] = React.useState({
        all_revenue_type: [],
        wallet_revenue_type: [],
        component_type: ComponentTypeOptions,
        status: [
            {
              label: t("Active"),
              value: true,
            },
            {
              label: t("Inactive"),
              value: false,
            },
          ],
    })
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getEnum()
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function for updating addNewState
    const updateAddNewState = (k, v) => {
        let error = addNewState?.error;
        error[k] = "";
        if (k === "services") {
            setAddNewState({ ...addNewState, [k]: v, name: v?.label, error });
        }
        else if (k === "isTaxable" && v === false) {
            setAddNewState({ ...addNewState, [k]: v, tax_group: null, hsn_sac_code: null, error });
        }
        else if (k === "revenueType" && addNewState?.name?.length > 0) {
            setAddNewState({ ...addNewState, [k]: v, error: { ...error, name: "" } });
        }
        else {
            setAddNewState({ ...addNewState, [k]: v, error });
        }
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true;
        let error = addNewState.error;
        if (addNewState?.name?.length === 0) { isValid = false; error.name = t("Name is Required"); }
        if (addNewState?.question === AddQuestions[4].question && addNewState?.services?.length === 0) {
            isValid = false; error.services = t("Service is Required");
        }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        setAddNewState({ ...addNewState, error });
        return isValid;
    };

    // Changing Page Number
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPricingComponent(offset, limit, searchText)
    };

    // Changing Page Number
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPricingComponent(0, value, searchText)
    };

    // Function for changing Status of Pricing Component
    const updateStatusPricingComponent = async (data, value) => {
        let payload = {
            id: data,
            is_active: value === "Inactive" ? true : false,
            updated_by: localStorage.getItem(LocalStorageKeys.profileID),
            updated_at: new Date().toISOString()
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.queries_pricing_component_upsert}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: t("Pricing Component Status Updated Successfully"),
            });
            getPricingComponent()
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        });
    };

    // Function for deleting Pricing Component
    const deletePricingComponent = async (data) => {
        let payload = {
            id: data,
            is_active: false,
            is_delete: true,
            updated_by: localStorage.getItem(LocalStorageKeys.profileID),
            updated_at: new Date().toISOString()
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.queries_pricing_component_upsert}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: t("Pricing Component Deleted Successfully"),
            });
            getPricingComponent()
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        })
    };

    // Changing Status of Pricing Component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    };

    // Search function for table
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1); }
        getPricingComponent(0, limit, e)
    };

    // More Icon function in table
    const handleIcon = (type, data, status) => {
        setButtonDisable(false);

        let question = data?.data?.is_onetime ? AddQuestions?.[2]?.question :
            data?.data?.refundable ? AddQuestions?.[3]?.question :
                data?.data?.is_quantity ? AddQuestions?.[4]?.question :
                    data?.data?.is_parking ? AddQuestions?.[5]?.question :
                        data?.data?.is_wallet ? AddQuestions?.[6]?.question :
                            data?.data?.is_late_fee ? AddQuestions?.[7]?.question :
                                data?.data?.is_commission_payment ? AddQuestions?.[8]?.question :
                                    data?.data?.primary ? AddQuestions?.[0]?.question :
                                        AddQuestions?.[1]?.question;

        let category = data?.data?.is_onetime ? AddQuestions?.[2]?.category :
            data?.data?.refundable ? AddQuestions?.[3]?.category :
                data?.data?.is_quantity ? AddQuestions?.[4]?.category :
                    data?.data?.is_parking ? AddQuestions?.[5]?.category :
                        data?.data?.is_wallet ? AddQuestions?.[6]?.category :
                            data?.data?.is_late_fee ? AddQuestions?.[7]?.category :
                                data?.data?.is_commission_payment ? AddQuestions?.[8]?.category :
                                    data?.data?.primary ? AddQuestions?.[0]?.category :
                                        AddQuestions?.[1]?.category;

        let info = data?.data?.is_onetime ? AddQuestions?.[2]?.info :
            data?.data?.refundable ? AddQuestions?.[3]?.info :
                data?.data?.is_quantity ? AddQuestions?.[4]?.info :
                    data?.data?.is_parking ? AddQuestions?.[5]?.info :
                        data?.data?.is_wallet ? AddQuestions?.[6]?.info :
                            data?.data?.is_late_fee ? AddQuestions?.[7]?.info :
                                data?.data?.is_commission_payment ? AddQuestions?.[8]?.info :
                                    data?.data?.primary ? AddQuestions?.[0]?.info :
                                        AddQuestions?.[1]?.info;

        let primaryColor = data?.data?.is_onetime ? AddQuestions?.[2]?.primaryColor :
            data?.data?.refundable ? AddQuestions?.[3]?.primaryColor :
                data?.data?.is_quantity ? AddQuestions?.[4]?.primaryColor :
                    data?.data?.is_parking ? AddQuestions?.[5]?.primaryColor :
                        data?.data?.is_wallet ? AddQuestions?.[6]?.primaryColor :
                            data?.data?.is_late_fee ? AddQuestions?.[7]?.primaryColor :
                                data?.data?.is_commission_payment ? AddQuestions?.[8]?.primaryColor :
                                    data?.data?.primary ? AddQuestions?.[0]?.primaryColor :
                                        AddQuestions?.[1]?.primaryColor;

        let secondaryColor = data?.data?.is_onetime ? AddQuestions?.[2]?.secondaryColor :
            data?.data?.refundable ? AddQuestions?.[3]?.secondaryColor :
                data?.data?.is_quantity ? AddQuestions?.[4]?.secondaryColor :
                    data?.data?.is_parking ? AddQuestions?.[5]?.secondaryColor :
                        data?.data?.is_wallet ? AddQuestions?.[6]?.secondaryColor :
                            data?.data?.is_late_fee ? AddQuestions?.[7]?.secondaryColor :
                                data?.data?.is_commission_payment ? AddQuestions?.[8]?.secondaryColor :
                                    data?.data?.primary ? AddQuestions?.[0]?.secondaryColor :
                                        AddQuestions?.[1]?.secondaryColor;

        const tempAddNewState = {
            formType: type,
            question: question,
            category: category,
            info: info,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            id: data?.id,
            initial_name: data?.data?.name ?? "",
            name: data?.data?.name ?? "",
            services: data?.data?.inspection_itemsByID?.value ? data?.data?.inspection_itemsByID : "",
            initialRevenueType: (data?.data?.revenue_type && data?.data?.revenue_type !== "") ?
                { value: data?.data?.revenue_type, label: data?.data?.revenue_type } :
                { value: "Lease", label: "Lease" },
            revenueType: (data?.data?.revenue_type && data?.data?.revenue_type !== "") ?
                { value: data?.data?.revenue_type, label: data?.data?.revenue_type } :
                { value: "Lease", label: "Lease" },
            isPrimary: data?.data?.primary ?? false,
            isOneTime: data?.data?.is_onetime ?? false,
            isRefundable: data?.data?.refundable ?? false,
            isQuantity: data?.data?.is_quantity ?? false,
            isParking: data?.data?.is_parking ?? false,
            isTaxable: data?.data?.taxtable ?? false,
            isOverride: data?.data?.is_override ?? false,
            isReserve: data?.data?.is_reserve_quality ?? false,
            isSecurity: data?.data?.is_security ?? false,
            hsn_sac_code: data?.data?.hsn_sac_code ?? null,
            tax_group: data?.data?.vat_group_master,
            is_wallet: data?.data?.is_wallet ?? false,
            is_late_fee: data?.data?.is_late_fee ?? false,
            is_commission_payment: data?.data?.is_commission_payment ?? false,
            error: defaultAddNewState?.error
        };

        if (type === "edit") {
            setAddNewState({ ...tempAddNewState });
            addNewDialogopen();
        }
        else if (type === "view") {
            setAddNewState({ ...tempAddNewState });
            addNewDialogopen();
        }
        else if (type === "active") { updateStatusPricingComponent(data.id, status); }
        else if (type === "delete") { deletePricingComponent(data.id); }
    };

    // Pricing Component Row Data
    // eslint-disable-next-line
    const PricingComponentRow = React.useCallback(pricingComponentList?.data?.map((val, index) => {
        let categoryType = val.is_onetime ? AddQuestions?.[2]?.categoryType :
            val.refundable ? AddQuestions?.[3]?.categoryType :
                val.is_quantity ? AddQuestions?.[4]?.categoryType :
                    val.is_parking ? AddQuestions?.[5]?.categoryType :
                        val.is_wallet ? AddQuestions?.[6]?.categoryType :
                            val.is_late_fee ? AddQuestions?.[7]?.categoryType :
                                val.is_commission_payment ? AddQuestions?.[8]?.categoryType :
                                    val.primary ? AddQuestions?.[0]?.categoryType :
                                        AddQuestions?.[1]?.categoryType;
        let _d;
        try {
            _d = {
                id: val?.id,
                company_name: val?.companyByID?.name,
                pricing_component: categoryType,
                revenue_type: val?.revenue_type,
                name: val?.name,
                taxes: val?.taxtable ? "Yes" : "No",
                priceOverride: val?.is_override ? "Yes" : "No",
                autoReservation: val?.is_reserve_quality ? "Yes" : "No",
                autoSecurityDeposit: val?.is_security ? "Yes" : "No",
                status: val?.is_active ? "Active" : "Inactive",
                data: val
            };
        } catch (err) { }
        return _d;
    }), [pricingComponentList]);

    const handleIsFilterApplied = () => {
        let isFilterApplied = (filterData?.status && filterData?.status?.length > 0)
            || (filterData?.revenue_type && filterData?.revenue_type?.length > 0)
            || (filterData?.component_type && filterData?.component_type?.filter_values);

        return isFilterApplied;
    }

    // Function to get Pricing Component data from Graphql
    const getPricingComponent = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading...", });
        let company_id = selectedCompany?.value;
        let status = (filterData?.status && filterData?.status?.length > 0) ? filterData?.status : [true, false];
        let revenue_type = (filterData?.revenue_type && filterData?.revenue_type?.length > 0) ? filterData?.revenue_type?.map?.(_ => _?.value) : [];
        const payload = {
            company_id, offset, limit, search,
            is_active: status,
            revenue_type: revenue_type,
            ...filterData?.component_type?.filter_values
        };
        NetworkCall(
            `${config.api_url}${BackendRoutes?.queries_pricing_component_get}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            setPricingComponentList({
                data: response?.data?.data?.pricing_component,
                totalRowsCount: response?.data?.data?.count?.[0]?.count,
            });
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch(() => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        });
    };
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type])
        set_option({
            ...option,
            all_revenue_type: result?.revenue_type?.filter(i => !["Stay"]?.includes?.(i?.value)),
            wallet_revenue_type: result?.revenue_type?.filter(i => !["Stay", "Sale"]?.includes?.(i?.value)),

        })
    }
    // useEffect for the screen with company and filter data as dependency
    React.useEffect(() => {
        if (selectedCompany?.value) {
            getPricingComponent();
        }
        // eslint-disable-next-line
    }, [selectedCompany, filterData]);

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    };

    // Function to open filter drawer
    const filterDraweropen = () => {
        setFilterDrawer(true);
    };

    // Function to close filter drawer
    const filterDrawerclose = () => {
        setFilterDrawer(false);
    };

    // Function to open filter drawer
    const addNewDialogopen = () => {
        setAddNewDialog(true);
    };

    // Function to close filter drawer
    const addNewDialogclose = () => {
        setAddNewDialog(false);
    };

    // Function for Add New Button
    const handleAddNewButton = () => {
        setButtonDisable(false);
        setAddNewState({ ...defaultAddNewState });
        addNewDialogopen();
    };

    // Function to apply filter
    const onApplyFilter = (value) => {
        setFilterData(value);
    };

    // Function for clicking question while adding
    const handleQuestionCard = (value) => {
        setAddNewState({
            ...addNewState,
            formType: "add",
            question: value?.question,
            category: value?.category,
            info: value?.info,
            primaryColor: value?.primaryColor,
            secondaryColor: value?.secondaryColor,
            isPrimary: value?.isPrimary,
            isOneTime: value?.isOneTime,
            isRefundable: value?.isRefundable,
            isQuantity: value?.isQuantity,
            isParking: value?.isParking,
            isTaxable: value?.isTaxable,
            isOverride: value?.isOverride,
            isReserve: value?.isReserve,
            isSecurity: value?.isSecurity,
            id: "",
            name: "",
            revenueType: { value: "Lease", label: "Lease" },
            is_wallet: value?.is_wallet,
            is_late_fee: value?.is_late_fee,
            is_commission_payment: value?.is_commission_payment,
            error: defaultAddNewState?.error,
        })
    };

    // Function to save Pricing Table
    const handleSavePricingComponent = async () => {
        setButtonDisable(true);
        if (validate()) {
            if (addNewState?.name !== addNewState?.initial_name ||
                addNewState?.revenueType?.value !== addNewState?.initialRevenueType?.value) {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.info,
                    msg: t("Checking Name For Duplication"),
                });
                const type = getType(addNewState);
                const response_message = await getPricingComponentCheck(addNewState?.name, type);
                if (response_message?.length > 0) {
                    setAddNewState({
                        ...addNewState,
                        error: {
                            ...addNewState?.error,
                            name: response_message,
                        }
                    });
                    alert.setSnack({
                        ...alert, open: false, msg: "",
                        severity: AlertProps.severity.info,
                    });
                    setButtonDisable(false);
                } else {
                    savePricingComponent();
                }
            } else {
                savePricingComponent();
            }
        } else {
            setButtonDisable(false);
        }
    }

    const savePricingComponent = () => {
        let currentDate = new Date().toISOString();
        let profileID = localStorage.getItem(LocalStorageKeys.profileID);

        let payload = {
            company_id: selectedCompany?.value,
            primary: addNewState?.isPrimary,
            is_onetime: addNewState?.isOneTime,
            refundable: addNewState?.isRefundable,
            is_quantity: addNewState?.isQuantity,
            is_parking: addNewState?.isParking,
            name: addNewState?.name,
            revenue_type: addNewState?.revenueType?.value,
            taxtable: addNewState?.isTaxable,
            is_override: addNewState?.isOverride,
            is_reserve_quality: addNewState?.isReserve,
            is_security: addNewState?.isSecurity,
            inspection_items_id: addNewState?.services?.id,
            updated_at: currentDate,
            updated_by: profileID,
            client: localStorage.getItem(LocalStorageKeys.clinetID),
            hsn_sac_code: addNewState?.hsn_sac_code ?? null,
            tax_group: addNewState?.tax_group?.value ?? null,
            is_wallet: addNewState?.is_wallet ?? null,
            is_late_fee: addNewState?.is_late_fee ?? null,
            is_commission_payment: addNewState?.is_commission_payment ?? null,
        }

        if (addNewState?.formType === "add") {
            payload.is_active = true;
            payload.is_delete = false;
            payload.created_at = currentDate;
            payload.created_by = profileID;
        }

        if (addNewState?.formType === "edit") {
            payload.id = addNewState?.id;
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.queries_pricing_component_upsert}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: `${addNewState?.formType === "add" ? t("Pricing Component Status Added Successfully") : t("Pricing Component Status Updated Successfully")}`,
            });
            addNewDialogclose();
            getPricingComponent()
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
            setButtonDisable(false);
        });
    }

    const getPricingComponentCheck = async (search = "", typeValue) => {
        let company_id = selectedCompany?.value;
        const payload = {
            company_id, search, is_active: [true],
            revenue_type: [addNewState?.revenueType?.value],
            ...typeValue,
            api_purpose: "check_for_name_duplication"
        };
        const response = await NetworkCall(
            `${config.api_url}${BackendRoutes?.queries_pricing_component_get}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).catch((err) => {
            console.log(err);
            return t("Error while checking for duplication");
        });

        if (response?.data?.data?.count?.[0]?.count > 0) {
            return t("Pricing Component Already Exists");
        } else {
            return "";
        }
    };

    const getType = (val) => {
        return {

            primary: val?.isPrimary,
            is_onetime: val?.isOneTime,
            refundable: val?.isRefundable,
            is_quantity: val?.isQuantity,
            is_parking: val?.isParking,
            is_wallet: val?.is_wallet,
            is_late_fee: val?.is_late_fee,
            is_commission_payment: val?.is_commission_payment,
        }
    }

    const render = () => {
        return <>
            {/* Header */}
            <Subheader hideBackButton={true} title={t("Pricing Component")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />

            {/* Content */}
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={2}>

                    {/* Search TextBox */}
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder={t("Search Pricing Component")} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                    </Grid>

                    {/* Filter and Add New Button */}
                    <Grid item xs={8} textAlign={"right"}>
                        <Box display={"flex"} sx={{ justifyContent: "end" }}>
                            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                {handleIsFilterApplied() ?
                                    (<IconButton onClick={filterDraweropen} className={classes.filterButton}>
                                        <Badge variant="dot" color="primary">
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>) :
                                    (<IconButton onClick={filterDraweropen} className={classes.filterButton}>
                                        <FilterIMG color="#091b29" />
                                    </IconButton>)}
                                {permission?.create &&
                                    <Button variant="contained" className={classes.addNewButton}
                                        onClick={handleAddNewButton}>
                                        {t("Add New")}
                                    </Button>}
                            </Stack>
                        </Box>
                    </Grid>

                    {/* Pricing Component Table */}
                    <Grid item xs={12} marginTop="-14px">
                        <TableWithPagination
                            heading={PricingComponentHeading(t)}
                            rows={PricingComponentRow}
                            path={PricingComponentPath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={PricingComponentType}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={pricingComponentList?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Grid>
                </Grid>

                {/* Filter Drawer */}
                <FilterGenerator
                    open={filterDrawer}
                    onClose={filterDrawerclose}
                    defaultState={defaultFilterState}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: option?.status,
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                        {
                            component: "select",
                            value: filterData?.component_type,
                            options: option?.component_type,
                            state_name: "component_type",
                            label: t("Component Type"),
                            placeholder: t("Select Component Type"),
                        },
                        {
                            component: "select",
                            value: filterData?.revenue_type,
                            options: option?.all_revenue_type,
                            isMulti: true,
                            state_name: "revenue_type",
                            label: t("Revenue Type"),
                            placeholder: t("Select Revenue Type"),
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)} />

                {/* Add Edit View Drawer */}
                <Dialog
                    open={addNewDialog}
                    onClose={addNewDialogclose}
                    className={classes.addNewDialog}>
                    <div className={classes.addNewDialogHeader}>
                        <Typography className={classes.addNewDialogHeaderTitle}>{t("Pricing Component")}</Typography>
                        <IconButton className={classes.addNewDialogHeaderCloseButton} onClick={addNewDialogclose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {addNewState?.formType === "question" ? <div className={classes.addNewDialogQuestionBody}>
                        {AddQuestions?.filter((x) => x?.is_active !== false)?.map((item, index, array) => {
                            return <>
                                <div className={classes.addQuestionCard} onClick={() => { handleQuestionCard(item) }}
                                    style={{ backgroundColor: item?.secondaryColor }}>
                                    <Box className={classes.addQuestionContent1}>
                                        <Avatar className={classes.addQuestionSerialAvatar} style={{ backgroundColor: item?.primaryColor }}>
                                            <Typography className={classes.addQuestionSerialText} >
                                                {item?.serial}
                                            </Typography>
                                        </Avatar>
                                        <Typography className={classes.addNewDialogQuestionText} style={{ color: item?.primaryColor }}>
                                            {t(item?.question)}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.addQuestionContent2}>
                                        <LightTooltip title={t(item?.info)} placement="top">
                                            <Box className={classes.addNewDialogInfoIcon1}>
                                                <InfoIcon />
                                            </Box>
                                        </LightTooltip>
                                        <RightCheveronIcon fill={item?.primaryColor} />
                                    </Box>
                                </div>
                                {(index !== array?.length - 1) && <Box height={"12px"} />}
                            </>
                        })}
                    </div> : <>
                        <div className={classes.addNewDialogFormBody}>
                            <div className={classes.addNewDialogFormContent1}>
                                <div className={classes.addQuestionCard}
                                    style={{ backgroundColor: addNewState?.secondaryColor, cursor: "auto" }}>
                                    <Box className={classes.addQuestionContent1}>
                                        <Typography className={classes.addNewDialogQuestionText} style={{ color: addNewState?.primaryColor }}>
                                            {t(addNewState?.category)}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.addQuestionContent2}>
                                        <LightTooltip title={t(addNewState?.info)} placement="top">
                                            <Box className={classes.addNewDialogInfoIcon2}>
                                                <InfoIcon />
                                            </Box>
                                        </LightTooltip>
                                    </Box>
                                </div>
                                <Box height={"24px"} />
                                <Grid container justifyContent={"space-between"} spacing={"16px"}>
                                    <Grid item xs={12} md={6}>
                                        <TextBox
                                            isRequired
                                            isReadonly={addNewState?.formType === "view"}
                                            color={"white"}
                                            label={t("Pricing Component Name")}
                                            placeholder={t("Enter Name")}
                                            value={addNewState?.name ?? ""}
                                            onChange={(e) => updateAddNewState("name", e.target.value)}
                                            isError={addNewState?.error?.name?.length > 0}
                                            errorMessage={addNewState?.error?.name} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className={classes.addNewDialogFormFieldLabel} noWrap>{t("What Is The Revenue Type?")}</Typography>
                                        <Grid container rowGap={"8px"} columnGap={"8px"}>
                                            {option?.[
                                                [
                                                    AddQuestions?.[6]?.category
                                                ]?.includes?.(addNewState?.category)
                                                    ? "wallet_revenue_type"
                                                    : "all_revenue_type"
                                            ]?.map((value) => {
                                                return <Button className={value?.value === addNewState?.revenueType?.value ?
                                                    classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}
                                                    onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                        updateAddNewState("revenueType", value) : false}>
                                                    <Typography className={value?.value === addNewState?.revenueType?.value ?
                                                        classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                        {t(value?.label)}</Typography>
                                                </Button>
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            {DisableFormQuestionSwitchListCategory?.includes(addNewState?.category)
                                ? <></>
                                : <div className={classes.addNewDialogFormContent2}>
                                    {(FormQuestionSwitchList2Category.includes(addNewState?.category) ?
                                        FormQuestionSwitchList2 :
                                        FormQuestionSwitchList1)?.map((item, index, array) => {
                                            return <>
                                                <div className={addNewState?.[item?.state] ?
                                                    classes.addNewDialogCustomSwitchOn : classes.addNewDialogCustomSwitchOff}
                                                >
                                                    <Box display={"flex"} justifyContent={"space-between"} onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                        updateAddNewState(item?.state, !addNewState?.[item?.state]) : false}>
                                                        <Box className={classes.addNewDialogCustomSwitchContent1}>
                                                            <Typography className={classes.addNewDialogCustomSwitchText}>
                                                                {t(item?.question)}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={classes.addNewDialogCustomSwitchContent2}>
                                                            {addNewState?.[item?.state] ?
                                                                <div className={classes.switchContainerOn}>
                                                                    <div className={classes.switchToggleOn} />
                                                                </div> :
                                                                <div className={classes.switchContainerOff}>
                                                                    <div className={classes.switchToggleOff} />
                                                                </div>}
                                                        </Box>
                                                    </Box>
                                                    {item?.question === 'Enable Tax?' && addNewState?.isTaxable &&

                                                        <>
                                                            <Box className={classes.addAdditionalFields} mt={1.5} display={"flex"} justifyContent={"space-between"} alignItems="center">
                                                                <Typography className={classes.addNewDialogCustomSwitchText}>{t("HSN/SAC Code")}</Typography>
                                                                <Box width="150px"><TextBox placeholder={""} label={""} value={addNewState?.hsn_sac_code} onChange={(e) => updateAddNewState("hsn_sac_code", e?.target?.value)} color="white" border="none" textBoxHeight="8px" isReadonly={addNewState?.formType === "view"} /></Box>
                                                            </Box>
                                                            <Box className={classes.addAdditionalFields} mt={1.5} display={"flex"} justifyContent={"space-between"} alignItems="center">
                                                                <Typography className={classes.addNewDialogCustomSwitchText}>{t("Tax Group")}</Typography>
                                                                <Box width="150px">
                                                                    <SelectBox
                                                                        label={""}
                                                                        placeholder={""}
                                                                        isReadOnly={addNewState?.formType === "view"}
                                                                        value={addNewState?.tax_group}
                                                                        onChange={(value) => updateAddNewState("tax_group", value)}
                                                                        // loading={asyncOptionloader === "inspection_items"}
                                                                        isPaginate
                                                                        noBorder
                                                                        selectHeight={"38px"}
                                                                        color="white"
                                                                        debounceTimeout={800}
                                                                        loadOptions={(search, array, handleLoading) =>
                                                                            loadOptionsApis(
                                                                                BackendRoutes?.queries_pricing_component_get_tax_group?.slice(1),
                                                                                { country_id: selectedCompany?.data?.country?.id },
                                                                                search, array, handleLoading, "vat_group_master",
                                                                            )} />
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    }
                                                </div>
                                                {(index !== array?.length - 1) && <Box height={"12px"} />}
                                            </>
                                        })}
                                </div>
                            }
                        </div>
                        {permission.update && <div className={addNewState?.formType === "add" ?
                            classes.addNewDialogButtonContentAdd : classes.addNewDialogButtonContentViewEdit}>
                            {addNewState?.formType === "add" ?
                                <><Button className={classes.addNewDialogBackButton}
                                    onClick={() => updateAddNewState("formType", "question")}>
                                    <Typography className={classes.addNewDialogBackButtonText}>{t("Back")}</Typography>
                                </Button>
                                    <Button className={classes.addNewDialogAddButton} disabled={buttonDisable}
                                        onClick={handleSavePricingComponent}>
                                        <Typography className={classes.addNewDialogAddButtonText}>{t("Create Pricing Component")}</Typography>
                                    </Button></> :
                                <><Button className={classes.addNewDialogViewEditButton} fullWidth disabled={buttonDisable}
                                    onClick={() => addNewState?.formType === "view" ? updateAddNewState("formType", "edit") : handleSavePricingComponent()}>
                                    <Typography className={classes.addNewDialogViewEditButtonText}>
                                        {addNewState?.formType === "view" ? t("Edit") : t("Save")}
                                    </Typography>
                                </Button></>}
                        </div>}
                    </>}
                </Dialog>
            </div>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div >
}
export default withNamespaces("pricingComponent")(PricingComponent)
