import moment from "moment"

export const returnSteps = (step, type) => {
    //new lead
    let stepsNew = [
        {
            label: ("Opportunity Details"),
            step: 1,
            value: step
        },
        {
            label: ("Preview and Create Lead"),
            step: 2,
            value: step
        },
        {
            label: ("Quotation Details"),
            step: 3,
            value: step
        },
        {
            label: ("Preview and Submit"),
            step: 4,
            value: step
        },
    ]
    let ExisitNew = [
        {
            label: ("Quotation Details"),
            step: 1,
            value: step
        },
        {
            label: ("Preview and Submit"),
            step: 2,
            value: step
        },
    ]
    if(type==="existing lead"){
       return ExisitNew
    }else{
        return stepsNew
    }
}



 export const retutnMappedValues=({
    is_lead,
    state,
    company
  })=>{
    let data;
    if (is_lead) {
        data={
          renewal:state?.duration?.lead?.renewal ?? false,
          allow_extension:state?.duration?.lead?.allow_extension ?? false,
          company_id:company?.id,
          account:state?.account ?? null,
          is_existing:state?.is_exist || state?.data?.is_existing,
          contact_id:state?.contact?.value ?? null,
          primary:state?.primary || state?.data?.primary,
          contact:state?.data?.contact ?? null,
          normal:{
            ...state?.data?.lead_details?.contact_account,
            label:state?.data?.lead_details?.contact_account?.first_name
          },
          broker:state?.data?.lead_details?.broker,
          agent:state?.data?.lead_details?.broker_agent ? {
            ...state?.data?.lead_details?.broker_agent,
            label:state?.data?.lead_details?.broker_agent?.name,
            id:state?.data?.lead_details?.broker_agent?.value
          }:"",
          internal_lead_source: state?.duration?.lead?.internal?.id ? {
            value:state?.duration?.lead?.internal?.id,
            label: state?.duration?.lead?.internal?.name
          } : "",
            purpose: {
                label: state?.duration?.lead?.unit_usage,
                value: state?.duration?.lead?.unit_usage
              },
              source: {
                label: state?.duration?.lead?.lead_source,
                value: state?.duration?.lead?.lead_source
              },
              revenue_type: {
                label: state?.duration?.lead?.revenue_type,
                value: state?.duration?.lead?.revenue_type
              },
              duration: {
                value: state?.duration?.lead?.values || state?.duration?.lead?.value,
                select: state?.duration?.lead?.lease_period || state?.duration?.lead?.lease_period,
              },
              quotation_revenue_based_lease: state?.duration?.lead?.revenue_based_lease,
              type: state?.type,
              lease_start_date: state?.duration?.lead?.lease_start_date,
              lease_end_date: state?.duration?.lead?.lease_end_date,
              priority: state?.duration?.lead?.urgent,
              quotation_lease_duration: {
                value: state?.duration?.lead?.values || state?.duration?.lead?.value,
                select: state?.duration?.lead?.lease_period || state?.duration?.lead?.lease_period,
              },
              lead_details: state?.duration?.lead,
              units: state?.unit,
              company: state?.companyID?.value || state?.companyID,
              searchdata: state?.data,
              lead_id: state?.duration?.lead?.id,
              name: state?.duration?.lead?.contact?.first_name,
              mobile: {
                mobile: state?.duration?.lead?.contact?.mobile_no,
                mobile_code: state?.duration?.lead?.contact?.mobile_no_country_code ?? "",
              },
              email_id: state?.duration?.lead?.contact?.email,
              image_url: {
                src: state?.duration?.lead?.contact?.get_assets_url_id
              },
              quotation_billing_start_date: state?.duration?.lead?.lease_start_date ? new Date(state?.duration?.lead?.lease_start_date) : null,
              quotation_payment_peroid: state?.data?.revenue_type === "Sale" ?  {
                value: state?.duration?.lead?.milestone_templateByID?.id ?? state?.duration?.lead?.payment_period,
                label: state?.duration?.lead?.milestone_templateByID?.name ?? state?.duration?.lead?.payment_period,
                template_type: state?.duration?.lead?.milestone_templateByID?.template_type ?? state?.duration?.lead?.payment_period
    
              }  : {
                value: state?.duration?.lead?.payment_period,
                label: state?.duration?.lead?.payment_period
              },
              quotation_payment_option: {
                value: state?.duration?.lead?.payment_mode,
                label: state?.duration?.lead?.payment_mode
              },
              quotation_grace_period: state?.duration?.lead?.grace_period_value,
              apply_grace_peroid: state?.duration?.lead?.apply_grace_period,
              quotation_billing_cycle: state?.duration?.lead?.bill_generation,
              bill_cycle_date: (state?.duration?.lead?.billing_cycle_date === "As per the Agreement Date") ? state?.duration?.lead?.billing_cycle_date : null,
              quotation_billing_cycle_date: (state?.duration?.lead?.billing_cycle_date === "As per the Agreement Date") ? state?.duration?.lead?.billing_cycle_date : null,
              quotation_renewal_based_on: {
                value: state?.duration?.lead?.auto_renewal_escalation,
                label: state?.duration?.lead?.auto_renewal_escalation
              },
              quotation_auto_renewal: state?.duration?.lead?.auto_renewal,
              quotation_escalculation_percent: state?.duration?.lead?.increment_escalation,
              quotation_include_market_place: state?.duration?.lead?.include_market,
              quotation_handover_date: state?.duration?.lead?.occpation_date ? new Date(state?.duration?.lead?.occpation_date) : null,
              quotation_contract_start_date: state?.duration?.lead?.contract_start_date ? new Date(state?.duration?.lead?.contract_start_date) : null,
              quotation_custom_date: state?.duration?.lead?.billing_day,
              grace_peroid: state?.duration?.lead?.grace_period_value,
             billing_start_date: state?.duration?.lead?.lease_start_date ? new Date(state?.duration?.lead?.lease_start_date) : null,
              shortlist_convert: true,
              custom_date: state?.duration?.lead?.billing_day ?? "",
              payment_period: state?.data?.revenue_type === "Sale" ? { value: state?.duration?.lead?.payment_period } : {
                value: state?.duration?.lead?.payment_period ?? "",
                label: state?.duration?.lead?.payment_period ?? ""
              },
              bill_cycle_method: state?.duration?.lead?.bill_generation,
              earlist_occupation_date: state?.duration?.lead?.occpation_date ? new Date(state?.duration?.lead?.occpation_date) : null,
              contract_start_date: state?.duration?.lead?.contract_start_date ?? null,
              mileStoneName: state?.duration?.lead?.milestone_templateByID?.name ?? state?.duration?.lead?.payment_period,
              payment_period_value: {
                value: state?.duration?.lead?.milestone_templateByID?.id ?? state?.duration?.lead?.payment_period,
                label: state?.duration?.lead?.milestone_templateByID?.name ?? state?.duration?.lead?.payment_period,
                template_type: state?.duration?.lead?.milestone_templateByID?.template_type ?? state?.duration?.lead?.payment_period
    
              },
              payment_option: {
                value: state?.duration?.lead?.payment_mode,
                label: state?.duration?.lead?.payment_mode,
              },
              renewal_based_on: {
                value: state?.duration?.lead?.auto_renewal_escalation,
                label: state?.duration?.lead?.auto_renewal_escalation
              },
              esclation_percentage: state?.duration?.lead?.increment_escalation !== null ? JSON.stringify(state?.duration?.lead?.increment_escalation) : null,
              milestoneTemplate:"",
              typeValue:"",
              reload:false,
              isValid:true,
              mailstoneLists:[]
        }

      } else {
        data={
          renewal:company?.renewal ?? false,
          allow_extension:company?.allow_extension ?? false,
          contact:state?.data?.contact,
          company_id:company?.id,
          is_existing:state?.is_exist || state?.data?.is_existing,
          contact_id:state?.contact?.value,
          primary:state?.primary || state?.data?.primary,

            custom_date: company?.billing_day ?? state?.data?.duration?.fromDate?.getDate() > 28 ? 1 : null,
            bill_cycle_method: company?.billing_cycle,
            bill_cycle_date:state?.data?.duration?.fromDate?.getDate() > 28 ? null : company?.billing_cycle_date,
            purpose: {
              label: state?.data?.property_purpose,
              value: state?.data?.property_purpose
            },
            revenue_type: {
              label: state?.data?.revenue_type,
              value: state?.data?.revenue_type
            },
            payment_period: state?.data?.revenue_type === "Sale" ? "" : {
              value: company?.payment_period,
              label: company?.payment_period,
            },
            type: state?.type,
            units: state?.unit,
            company: state?.companyID?.value,
            searchdata: state?.data,
            lease_start_date: state?.data?.duration?.fromDate,
            grace_peroid: state?.data?.stateFilter?.facilitesbool ?? null,
            billing_start_date:state?.data?.revenue_type === "Sale"?state?.duration?.handOverDate?.date: moment(state?.data?.duration?.fromDate).add(state?.data?.stateFilter?.facilitesbool ?? 0, 'days')?._d,
            lease_end_date: moment(state?.data?.duration?.toDate).subtract(1, 'days'),
            duration: {
              value: state?.duration?.duration?.input,
              select: state?.duration?.duration?.period === "Month" ? "Monthly" : "Yearly"
            },
            quotation_lease_duration: {
              value: state?.duration?.duration?.input,
              select: state?.duration?.duration?.period === "Month" ? "Monthly" : "Yearly"
            },
            required_area: {
              min: "",
              max: "",
              select: {
                value: company?.uom,
                label: company?.uom
              },
            },
            budget: {
              min: "",
              max: "",
              select: {
                value: company?.currencyByID?.id,
                label: company?.currencyByID?.symbol
              },
            },
            earlist_occupation_date: state?.duration?.handOverDate?.date,
            contract_start_date: new Date(),
            milestoneTemplate:"",
            typeValue:"",
            reload:false,
            isValid:true,
            mailstoneLists:[],
            name: `${state?.contact?.first_name ?? ""} ${state?.contact?.last_name ?? ""}`,
            email_id: state?.contact?.email_id,
            mobile: {
              mobile: state?.contact?.mobile_no ?? null,
              mobile_code: state?.contact?.mobile_no_country_code ?? null,
            },
            alter_mobile: {
              mobile:( state?.contact?.alternative_mobile && state?.contact?.alternative_mobile !== "undefined") ? state?.contact?.alternative_mobile : null,
              mobile_code: ( state?.contact?.alternative_mobile_country_code && state?.contact?.alternative_mobile_country_code !== "undefined") ? state?.contact?.alternative_mobile_country_code : null,
            },
            telephone: {
              mobile:( state?.contact?.phone && state?.contact?.phone !== "undefined") ?  state?.contact?.phone : null,
              mobile_code: ( state?.contact?.phone_code && state?.contact?.phone_code !== "undefined") ? state?.contact?.phone_code : null,
            },
            fax: {
              mobile: ( state?.contact?.phone && state?.contact?.fax_no !== "undefined") ? state?.contact?.fax_no : null,
              mobile_code:  ( state?.contact?.phone_code && state?.contact?.phone_code !== "undefined") ? state?.contact?.phone_code : null,
            },
            image_url: state?.contact?.image_url?.src ?? "",
            existing: true,
            address: {
              street_1: state?.contact?.street_1 ?? "",
              street_2: state?.contact?.street_2 ?? "",
              street_3: state?.contact?.street_3 ?? "",
              city: state?.contact?.city ?? "",
              district: state?.contact?.district ?? "",
              state: state?.contact?.state ?? "",
      
              zipcode: state?.contact?.zipcode ?? "",
            },
        }
      }

      return data
  }