
import React from 'react';
import { withNamespaces } from "react-i18next";
import { MiniPropertyDetail } from '../../../components';


const PropertyDetailsComp = ({ t, title = "", data = [] }) => {


    return (

        <MiniPropertyDetail
            logo={data?.logo??data?.image}
            property_no={data?.property_no}
            company_name={data?.company?.name}
            property_name={data?.name}
            address={data?.address}
            t={t}
        />

    )
}

export default withNamespaces("inspection")(PropertyDetailsComp)