import React from 'react'
import { ProjectStyle } from '../style'
import { Stack, Typography, Box, Divider, Grid, Avatar, Tooltip } from "@mui/material"
import ShowMoreText from "react-show-more-text";
import PlannerCalIcon from '../utils/plannerCalIcon';
import ProjectLayoutIcon from '../utils/projectLayoutIcon';
import ContractLayoutIcon from '../utils/contractLayoutIcon';
import PlannedHrIcon from '../utils/plannedHrIcon';
import ProjectOwnerIcon from '../utils/projectOwnerLayout';
import { stringAvatar } from '../../../utils';
import moment from 'moment';

export const ViewDetail = ({
    data = {},
    t = () => false
}) => {
    const classes = ProjectStyle()

    const viewData = [
        {
            icon: <ProjectLayoutIcon />,
            label: t("Display Type"),
            value: data?.display_type,
            isActive: true
        },
        {
            icon: <PlannerCalIcon />,
            label: t("Planned Start To End Date"),
            value: `${moment(data?.planned_start_date).format("DD MMM YY")} - ${moment(data?.planned_end_date).format("DD MMM YY")}`,
            isActive: true
        },
        {
            icon: <ProjectLayoutIcon />,
            label: t("Parent Project"),
            value: data?.project_members?.first_name,
            isActive: data?.project_members?.first_name?.length > 0 ? true : false
        },
        {
            icon: <ContractLayoutIcon />,
            label: t("Contract"),
            value: data?.contract?.contract_no,
            isActive: data?.contract?.contract_no?.length > 0 ? true : false
        },
        {
            icon: <ProjectLayoutIcon />,
            label: t("Project Category"),
            value: data?.category?.name,
            isActive: true
        },
        {
            icon: <ProjectLayoutIcon />,
            label: t("Project Sub-category"),
            value: data?.sub_category?.name,
            isActive: true
        },
        {
            icon: <PlannedHrIcon />,
            label: t("Planned Hours"),
            value: `${data?.planned_hours} Hrs`,
            isActive: true
        }
    ]
    const viewData1 = [
        {
            icon: <ProjectOwnerIcon />,
            id: 1,
            label: t("Project Owner"),
            value: data?.project_members
        },
        {
            icon: <PlannedHrIcon />,
            id: 2,
            label: t("Resource Group"),
            value: data?.project_resource_group
        }
    ]

    return (
        <>

            <Box p={2}>
                <Typography className={classes.overViewTitle} mb={"12px"}>{data?.title}</Typography>
                <ShowMoreText
                    lines={2}
                    more={t("Show More")}
                    less={t("Show Less")}
                    className={classes.optionSubTitle}
                    anchorClass={classes.seeMoreLessTextStyle}
                    expanded={false}
                    truncatedEndingComponent={"... "}
                >
                    <Typography className={classes.optionSubTitle}
                        dangerouslySetInnerHTML={{ __html: (data?.description).replace(/\n+/g, "<br />") ?? "" }}>
                    </Typography>
                </ShowMoreText>

                <Stack spacing={"20px"} mt={2}>
                    {
                        viewData?.map((data) => {
                            return (
                                <>
                                    {
                                        data?.isActive &&
                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                                {data?.icon}
                                                <Typography className={classes.viewLabel}>{data?.label}</Typography>
                                            </Stack>
                                            <Typography className={classes.viewValue} sx={{ textAlign: "end" }}>{data?.value}</Typography>
                                        </Stack>
                                    }
                                </>
                            )
                        })
                    }
                </Stack>

                <Box mt={2}><Divider></Divider></Box>

                <Stack spacing={"20px"} mt={2}>
                    {
                        viewData1?.map((data) => {
                            return (
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                        {data?.icon}
                                        <Typography className={classes.viewLabel}>{data?.label}</Typography>
                                    </Stack>
                                    {/* {
                                        data?.id === 1 ?
                                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                                <Avatar {...stringAvatar("A")} className={classes.viewAvatar}></Avatar>
                                                <Typography className={classes.viewValue} sx={{ textAlign: "end" }}>{"Aravid"}</Typography>
                                            </Stack> :
                                            <Typography className={classes.resourceValue}>15 Developer</Typography>
                                    } */}
                                    <Grid container spacing={1} justifyContent={"flex-end"}>
                                        {
                                            data?.id === 1 ?
                                                <>
                                                    {
                                                        data?.value?.map((e) => {
                                                            return (
                                                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                                                                    <Stack direction="row" columnGap={1} alignItems={"center"}>
                                                                        <Avatar {...stringAvatar(e?.label, "0.75rem")} className={classes.viewAvatar} src={e?.image_url}></Avatar>
                                                                        <Tooltip title={e?.label} placement="top" arrow>
                                                                            <Typography className={classes.viewValue}
                                                                                sx={{
                                                                                    textAlign: "end", width: "100px",
                                                                                    whiteSpace: "nowrap", textOverflow: "ellipsis",
                                                                                    overflow: "hidden",
                                                                                    cursor: "pointer"
                                                                                }}>{e?.first_name}</Typography>
                                                                        </Tooltip>
                                                                    </Stack>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        data?.value?.map((e) => {
                                                            return (
                                                                <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                                                                    <Tooltip title={e?.name} placement="top" arrow>
                                                                        <Typography className={classes.resourceValue} sx={{
                                                                            width: "100px",
                                                                            whiteSpace: "nowrap", textOverflow: "ellipsis",
                                                                            overflow: "hidden",
                                                                            cursor: "pointer"
                                                                        }}>{e?.name}</Typography>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </>
                                        }
                                    </Grid>
                                </Stack>
                            )
                        })
                    }
                </Stack>

            </Box>
        </>
    )
}