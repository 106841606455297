import dotenv from "dotenv";
import { initializeApp } from "firebase/app";
import "firebase/messaging";
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FirebaseContext } from "./contexts";
import { withAllContexts } from "./HOCs";
import { LocalStorageKeys, SetDeviceToken } from "./utils";
import { Backdrop } from "@mui/material";
dotenv.config();

class AppFireBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      isTokenFound: false,
      messaging: null,
      open: false,
      count: 0,
    };
  }

  componentDidMount() {
    try {
      this.setState({ open: true });
      const urlParams = new URLSearchParams(window.location.search);
      const deviceToken = urlParams.get("token");
      if (deviceToken?.length) {
        localStorage.setItem(LocalStorageKeys.deviceToken, deviceToken);
        this.setState({ open: false });
      } else {
        this.firebaseInitialization();
      }
    } catch (err) {
      this.setState({ open: false });
      console.log(err, "Notification:");
    }
  }

  firebaseInitialization = () => {
    try {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      };

      const intializedApp = initializeApp(firebaseConfig);

      this.checkNotificationPermission(intializedApp);
    } catch (error) {
      this.setState({ open: false });
      console.log("Firebase already registered: ", error);
    }
  };

  checkNotificationPermission = async (intializedApp) => {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      this.setState({ open: false });
    } else if (Notification.permission === "granted") {
      this.getToken(intializedApp);
    } else if (Notification.permission !== "denied") {
      let permission = await Notification.requestPermission();
      if (permission === "granted") {
        return this.getToken(intializedApp);
      }
      this.setState({ open: false });
    } else if (Notification.permission === "denied") {
      if (localStorage.getItem(LocalStorageKeys.deviceToken)) {
        SetDeviceToken(null, true);
      }
      localStorage.removeItem(LocalStorageKeys.deviceToken);
      this.setState({ open: false });
    }
  };

  getToken = async (initializedApp) => {
    let messaging;
    try {
      messaging = getMessaging(initializedApp);
      let currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
      });
      console.log("Current token retrieved:", currentToken);

      if (currentToken !== this.state.token) {
        this.setState(
          {
            token: currentToken,
            isTokenFound: currentToken ? true : false,
            messaging: messaging,
            open: false,
          },
          () => {
            SetDeviceToken(currentToken, false);
            localStorage.setItem(LocalStorageKeys.deviceToken, currentToken);
            this.receiveForeGroundNotifications(messaging);
            this.setState({ open: false });
          }
        );
      }
      this.setState({ open: false });
    } catch (error) {
      console.error("Error while retrieving token:", error);
      if (error.code === "messaging/permission-blocked") {
        console.log(
          "Notification permissions are blocked. Please enable notifications."
        );
      } else if (error.code === "messaging/permission-default") {
        console.log(
          "Notification permission was not granted. Requesting permission..."
        );
        if (this.state.count === 0) {
          this.setState({ count: 1 });
          this.checkNotificationPermission(initializedApp);
        }
      } else if (error.code === "messaging/invalid-vapid-key") {
        console.log("Invalid VAPID key. Please check your VAPID key.");
      } else {
        console.log("Unexpected error occurred:", error);
      }
      this.setState({ open: false });
    }
  };

  receiveForeGroundNotifications = (messaging) => {
    onMessage(messaging, (payload) => {
      toast.info(payload?.data, {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        onClick: () => {
          window.location.replace("/" + payload?.data ?? "");
        },
      });
    });
  };

  deleteLocalToken = () => {
    deleteToken(this.state.messaging)
      .then((isFullFilled) => {
        if (isFullFilled) {
          console.log("Token Deleted...!");
          localStorage.removeItem(LocalStorageKeys.deviceToken);
        }
      })
      .catch((err) => {
        console.log("Error while deleting token", err);
      });
  };

  render() {
    return (
      <FirebaseContext.Provider
        value={{
          ...this.state,
          getToken: this.getToken,
          requestPermission: this.checkNotificationPermission,
          deleteToken: this.deleteLocalToken,
        }}
      >
        <Backdrop
          sx={{
            background: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.state.open}
        >
          <img src="/images/loader.gif" alt="loader" />
        </Backdrop>
        {this.props.children}
        <ToastContainer
          position="bottom-right"
          autoClose={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
      </FirebaseContext.Provider>
    );
  }
}

export default withAllContexts(AppFireBase);
