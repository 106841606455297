import * as React from "react"
const PlannedHrIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#a4b2c1"
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 1.2A6.8 6.8 0 1 1 1.2 8 6.791 6.791 0 0 1 8 1.2Zm-.209 1.991A.6.6 0 0 0 7.2 3.8v4.8a.6.6 0 0 0 .6.6H11A.6.6 0 1 0 11 8H8.4V3.8a.6.6 0 0 0-.609-.609Z"
      data-name="icons8-time (8)"
    />
  </svg>
)
export default PlannedHrIcon
