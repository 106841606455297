import React from "react"

export const ResourceGroupAsset = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_118359" data-name="Group 118359" transform="translate(9507.5 -4368)">
                <g id="Rectangle_58700" data-name="Rectangle 58700" transform="translate(-9507.5 4368)" fill="#896db3" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-user" d="M14.857,4a4.15,4.15,0,0,0-4.286,4,4.15,4.15,0,0,0,4.286,4,4.15,4.15,0,0,0,4.286-4A4.15,4.15,0,0,0,14.857,4Zm5.152,9.6H9.705A1.653,1.653,0,0,0,8,15.192V15.8a3.671,3.671,0,0,0,2.274,3.225A10.233,10.233,0,0,0,14.857,20c3.3,0,6.857-1.314,6.857-4.2v-.608A1.653,1.653,0,0,0,20.009,13.6Z" transform="translate(-9511 4368.5)" fill="#896db3" stroke="#896db3" stroke-width="0.5" />
            </g>
        </svg>

    )
}