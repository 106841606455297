import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, Button, Stack, Tab, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchFilter, UseDebounce } from '../../components';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { NetWorkCallMethods, useWindowDimensions } from '../../utils';
import { CustomerVendorCard, PricingComponentCard, UnitCard } from './components';
import { CustomerPopupStyle } from './style';

const CustomerPopup = (
    {
        t = () => false,
        selectCustomer = () => false,
        type = "",
        agreementDetail = {},
        btntext = "",
        unitDetail = {},
        placeholder = "",
        selectedCompany = {},
        invoiceData = {}
    }) => {
    const debounce = UseDebounce()
    const classes = CustomerPopupStyle()

    const size = useWindowDimensions();
    const [searchText, setSearchText] = React.useState("")
    const [value, setValue] = React.useState(type);
    const [listData, setListData] = React.useState({
        list: [],
        offset: 0,
        searchText: "",
        selectedList: {},
        selected: false
    })

    React.useEffect(() => {
        setValue(type)
        type === "Unit" || type === "Pricing" ?
            invoiceData?.isShowContract ?
                getContractUnitList(0, 10, true, "")
                :
                getUnitDetails(0, 10, true, "", agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
            :
            getCustomerList(0, 10, true, "", value)
        // eslint-disable-next-line
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        type === "Unit" || type === "Pricing" ?
            invoiceData?.isShowContract ?
                getContractUnitList(0, 10, true, "")
                :
                getUnitDetails(0, 10, true, "", agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
            :
            getCustomerList(0, 10, true, "", newValue)
    };

    const loadmore = () => {
        setListData({ ...listData, offset: listData?.offset + 10 });
        let off = listData?.offset + 10
        type === "Unit" || type === "Pricing" ?
            invoiceData?.isShowContract ?
                getContractUnitList(off, 10, false, "")
                :
                getUnitDetails(off, 10, false, "", agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
            :
            getCustomerList(off, 10, false, "", value)
    }

    const searchHandle = (text) => {
        setSearchText(text)
        debounce(() =>
            type === "Unit" || type === "Pricing" ?
                invoiceData?.isShowContract ?
                    getContractUnitList(0, 10, true, text)
                    :
                    getUnitDetails(0, 10, true, text, agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
                :
                getCustomerList(0, 10, true, text, value)
            , 800)
    }

    const getCustomerList = (offset, limit, searchBool, searchText, selectedType) => {
        const payload = {
            company_id: selectedCompany?.value,
            type: [selectedType],
            offset: offset,
            limit: limit,
            search: searchText
        };
        NetworkCall(
            `${config.api_url}/account/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (searchBool) {
                    setListData({
                        ...listData,
                        list: response?.data?.data?.list ?? [],
                        searchText: searchText
                    })
                }
                else {
                    setListData({
                        ...listData,
                        list: listData?.list.concat(...response?.data?.data?.list)
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const getUnitDetails = (offset, limit, searchBool, searchText, agreement_id) => {
        const payload = {
            "agreement_id": type === "Unit" ? undefined : agreement_id,
            company_id: selectedCompany?.value ?? undefined,
            "search": searchText,
            "offset": offset,
            "limit": limit
        }
        NetworkCall(
            type === "Unit" ? `${config.api_url}/queries/get_unit_details/by_agreement` : `${config.api_url}/queries//pricing_component/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (searchBool) {
                setListData({
                    ...listData,
                    list: type === "Unit" ? response?.data?.data?.agreement_units : response?.data?.data?.pricing_component ?? [],
                    searchText: searchText
                })
            }
            else {
                setListData({
                    ...listData,
                    list: type === "Unit" ? listData?.list.concat(...response?.data?.data?.agreement_units) : listData?.list.concat(...response?.data?.data?.pricing_component)
                })
            }
        })
            .catch((err) => {
                console.log(err);
            });
    };

    const getContractUnitList = (offset, limit, searchBool, searchText) => {
        const payload = {
            contract_id: invoiceData?.contractNo?.contract_id,
            offset: offset,
            limit: limit,
            search: searchText
        };
        NetworkCall(
            `${config.api_url}/free_text_invoice/contract_units`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (searchBool) {
                    setListData({
                        ...listData,
                        list: response?.data?.data?.agreement_units ?? [],
                        searchText: searchText
                    })
                }
                else {
                    setListData({
                        ...listData,
                        list: listData?.list.concat(...response?.data?.data?.agreement_units)
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    return (
        <Box p={type === "Vendor" ? "0px 24px 24px 24px" : 3}>
            {type === "Vendor" &&
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", justifyContent: "space-between" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" className={classes.tabs}>
                                <Tab label={t("Vendors")} value="Vendor" />
                                <Tab label={t("ServiceProvider")} value="Service Provider" />
                                <Tab label={t("PropertyOwner")} value="Property Owner" />
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            }
            <Stack spacing={2} pt={type === "Vendor" ? 2 : 0} pb={1}>
                {type === "Pricing" && <Typography className={classes.pricingLabel}>{t("Selectthebilllinepricecomponent")}</Typography>}
                <SearchFilter value={searchText}
                    placeholder={placeholder}
                    handleChange={(value) => searchHandle(value)} />
            </Stack>
            <Box className={classes.infinityBox}>
                <InfiniteScroll
                    dataLength={10}
                    next={loadmore}
                    hasMore={true}
                    height={size?.height - 320}
                >
                    {
                        listData?.list?.map((c) => {
                            const datalist = {
                                id: c?.id,
                                name: c?.name,
                                content: c?.account_no + ", " + c?.mobile_no_country_code + " " + c?.mobile_no,
                                logo: c?.logo
                            }
                            const unitDatalist = c?.unit !== null ? {
                                id: c?.unit?.id,
                                name: c?.unit?.name,
                                content: c?.unit?.property?.name,
                                logo: c?.unit?.logo
                            } : {}
                            const pricingCompList = {
                                id: c?.id,
                                name: c?.name,
                                content: c?.revenue_type
                            }
                            return (
                                type === "Pricing" ?
                                    <Box m={1}>
                                        <PricingComponentCard data={pricingCompList} selectedList={listData?.selectedList} click={() => setListData({ ...listData, selectedList: c, selected: true })} />
                                        {/* {<Divider></Divider>} */}
                                        {/* length.length - 1 !== index &&  */}
                                    </Box>
                                    :
                                    type === "Unit" ?
                                        <UnitCard data={unitDatalist} selectedList={listData?.selectedList?.unit} click={() => setListData({ ...listData, selectedList: c, selected: true })} />
                                        :
                                        <CustomerVendorCard data={datalist} selectedList={listData?.selectedList} click={() => setListData({ ...listData, selectedList: c, selected: true })} />
                            )
                        })
                    }
                </InfiniteScroll>
            </Box>
            <Button variant='contained' fullWidth className={classes.addbtn} onClick={() => selectCustomer(listData?.selectedList, type)}
                disabled={!listData?.selected} >{btntext}</Button>
        </Box >
    )
}

export default withNamespaces("freeTextInvoice")(CustomerPopup)