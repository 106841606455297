import { Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import React, { useContext, useEffect, useState } from 'react';
import { SelectBox, TextBox, ToggleButtonComponent } from '../../../components';
import { AlertContext, BackdropContext } from '../../../contexts';
import { AlertProps, Bold, enum_types, wrapLabelsInT } from '../../../utils';
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { CustomPaper } from '../../companyCreation/components';
import { networkCallback } from '../../companyCreation/utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1)
}));

const Label = styled(Typography)(({ theme }) => ({
    color: theme.typography.color.tertiary,
    fontSize: "0.75rem",
    marginBottom: "10px"
}));


const buttonStyle = {
    borderColor: "#E4E8EE",
    borderRadius: "4px",
    backgroundColor: "#fff",
}


export const PaymentAndTaxDetails = (props) => {

    const {
        data = {},
        updateState = null,
        error = {},
        t
    } = props;

    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext);
    const [loading, setLoading] = useState(false);
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        getBankAccountType()
        // eslint-disable-next-line
    }, [])

    const getBankAccountType = async () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: t("Fetching Bank Account Type")
        })

        let result = await networkCallback({ enumName: ["bank_account_type"] }, "enum");
        if (!result) {
            return []
        }
        setBankAccountTypeOptions(result?.bank_account_type ?? []);
        backdrop.setBackDrop({
            ...backdrop,
            open: false,
        })
    }

    const loadOptionData = async (search, array, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.company_registration, enum_types.rera_type, enum_types.tax_code, enum_types.bank_routing_type]
        if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const {
        bankingBasicDetails = null,
        tax = null
    } = data;

    const manualCurrencyResponse = (array) => {
        return array?.currency_master ?? []
    }

    const manualVatGroupResponse = (array) => {
        return array?.vat_group_master ?? []
    }

    return (
        <div>
            <CustomPaper marginBottom={theme.spacing(3)}>
                <CustomTypography>{t("TAX")}</CustomTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            // isRequired={true}
                            label={t("Taxation System")}
                            placeholder={t("Select Taxation System")}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.tax_code)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.tax_code}
                            isSearchable={false}
                            value={tax?.taxCode ?? null}
                            onChange={(value) => updateState('tax', 'taxCode', value)}
                            isError={error?.tax?.taxCode?.error ?? false}
                            errorMessage={error?.tax?.taxCode?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            // isRequired={true}
                            label={t("Tax Group")}
                            placeholder={t("Select Tax Group")}
                            // options={[]}
                            loading={loading === "taxGroup"}
                            loadOptions={
                                (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "queries/vat_group_master/list",
                                        {},
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualVatGroupResponse
                                    )
                            }

                            isPaginate
                            debounceTimeout={800}
                            value={tax?.taxGroup ?? null}
                            onChange={(value) => updateState('tax', 'taxGroup', value)}
                            isError={error?.tax?.taxGroup?.error ?? false}
                            errorMessage={error?.tax?.taxGroup?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Tax Registration Number")}
                            placeholder={t("Enter Tax Registration Number")}
                            value={tax?.taxRegistrationNumber ?? ''}
                            onChange={(e) => updateState('tax', 'taxRegistrationNumber', e.target.value)}
                            isError={error?.tax?.taxRegistrationNumber?.error ?? false}
                            errorMessage={error?.tax?.taxRegistrationNumber?.errorMsg ?? ''}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
            <CustomPaper>
                <CustomTypography>{t("Banking Basic Details")}</CustomTypography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Bank Name")}
                            placeholder={t("Enter Bank Name")}
                            value={bankingBasicDetails?.bankName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "bankName", e.target.value)}
                            isError={error?.bankingBasicDetails?.bankName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Branch Name")}
                            placeholder={t("Enter Branch Name")}
                            value={bankingBasicDetails?.branchName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "branchName", e.target.value)}
                            isError={error?.bankingBasicDetails?.branchName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.branchName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            // isRequired={true}
                            label={t("Currency")}
                            placeholder={t("Select Currency")}
                            // options={[]}
                            loading={loading === "currency"}
                            loadOptions={
                                (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "queries/currency_master/list",
                                        {},
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualCurrencyResponse
                                    )
                            }
                            isPaginate
                            debounceTimeout={800}
                            value={bankingBasicDetails?.currency ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'currency', value)}
                            isError={error?.bankingBasicDetails?.currency?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.currency?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Label
                            variant="body1"
                            color="textsecondary"
                            align="start"
                            gutterBottom
                        // noWrap
                        >
                            {t("Bank Account Type")}
                            {/* <Typography component="span" color={"error"} sx={{ ml: 1 / 2, fontSize:"0.75rem" }}>
                                *
                            </Typography> */}
                        </Label>
                        <ToggleButtonComponent
                            options={wrapLabelsInT(bankAccountTypeOptions, t)}
                            value={bankingBasicDetails?.bankAccountType ?? []}
                            onChange={(value) => updateState('bankingBasicDetails', 'bankAccountType', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.bankingBasicDetails?.bankAccountType?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankAccountType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Account Number")}
                            placeholder={t("Enter Account Number")}
                            value={bankingBasicDetails?.accountNumber ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "accountNumber", e.target.value)}
                            isError={error?.bankingBasicDetails?.accountNumber?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.accountNumber?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            // isRequired={true}
                            label={t("Bank Routing Type")}
                            placeholder={t("Select Bank Routing Type")}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.bank_routing_type)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.bank_routing_type}
                            isSearchable={false}
                            value={bankingBasicDetails?.bankRoutingType ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'bankRoutingType', value)}
                            isError={error?.bankingBasicDetails?.bankRoutingType?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankRoutingType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Bank Routing Code")}
                            placeholder={t("Enter Bank Routing Code")}
                            value={bankingBasicDetails?.bankRoutingCode ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "bankRoutingCode", e.target.value)}
                            isError={error?.bankingBasicDetails?.bankRoutingCode?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankRoutingCode?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Cheque Name")}
                            placeholder={t("Enter Cheque Name")}
                            value={bankingBasicDetails?.chequeName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "chequeName", e.target.value)}
                            isError={error?.bankingBasicDetails?.chequeName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.chequeName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Address Line 1")}
                            placeholder={t("Enter Address Line 1")}
                            value={bankingBasicDetails?.addressLineOne ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "addressLineOne", e.target.value)}
                            isError={error?.bankingBasicDetails?.addressLineOne?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.addressLineOne?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Address Line 2")}
                            placeholder={t("Enter Address Line 2")}
                            value={bankingBasicDetails?.addressLineTwo ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "addressLineTwo", e.target.value)}
                            isError={error?.bankingBasicDetails?.addressLineTwo?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.addressLineTwo?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("City")}
                            placeholder={t("Enter City")}
                            options={[]}
                            value={bankingBasicDetails?.city ?? null}
                            onChange={(e) => updateState('bankingBasicDetails', 'city', e.target.value)}
                            isError={error?.bankingBasicDetails?.city?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.city?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // isrequired
                            label={t("Country")}
                            placeholder={t("Enter Country")}
                            options={[]}
                            value={bankingBasicDetails?.country ?? null}
                            onChange={(e) => updateState('bankingBasicDetails', 'country', e.target.value)}
                            isError={error?.bankingBasicDetails?.country?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.country?.errorMsg ?? ''}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
        </div>
    )
}