
// import { networkCallback } from "../utils";
import { loadOptionsApis } from "./asyncPaginateLoadOptions";
// import { enum_types } from './constants';




const companyIDs = JSON.parse(localStorage.getItem('role'))?.filter(_ => _?.name === 'Manager')?.map(_ => _?.company_id);


// export const companySelect = () => {
//   const auth = React.useContext(AuthContext);
//   return {
//     auth: auth,
//   };
// };

// As per CR Commented
// const loadOptionData = async (search, array, handleLoading, type) => {

//   let result;
//   let enumTypes = [enum_types.purpose_global_type]

//   if (enumTypes?.includes(type)) {
//     result = await networkCallback({ enumName: [type] }, "enum");
//     if (!result) {
//       return {
//         options: [],
//         hasMore: false
//       }
//     }
//     handleLoading(null)
//     return {
//       options: result?.[type] ?? [],
//       hasMore: false
//     }

//   }
//   else {
//     handleLoading(null)
//     return {
//       options: [],
//       hasMore: false
//     }
//   }
// }
export const Propertylist = [
  {
    name: "Property Type",
  },
  {
    name: "Pricing Component",
  },
  {
    name: "Unit Rental Breakup",
  },
  {
    name: "Block Type",
  },
  // {
  //   name: "Unit Purpose",
  // },
  {
    name: "Unit Type",
  },
  {
    name: "Ameneties",
  },
  // {
  //   name: "Move-in Move-out reference",
  // }
];

export const PropertyPath = [
  "company_name",
  // "purpose",
  "group_name",
  "status",
  "icon"
];

export const PropertyHeading = (t = () => false) => {
  return [
    { title: "", field: 'icon' },
    // { title: "Company Name", field: "company_name", type: "numeric" },
    // { title: "Purpose", field: "purpose" },
    { title: t("Property Type"), field: "group_name" },
    { title: t("Status"), field: 'status', },
  ]
};
export const PropertyType = [
  { type: ["more"], icon: "icon" },
  // { type: ["object"], name: "company_name" },
  // { type: ["object"], name: "purpose" },
  { type: ["text"], name: "group_name", width: "250px" },
  { type: ["status"], name: "status" },
];
const manualResponseProperty = (array) => {
  let result = array?.property?.map((val) => {
    return {
    ...val
    }
  })
  return result;
}
const manualResponseUnit = (array) => {
  let result = array?.unit?.map((val) => {
    return {
    ...val,
    label:val?.name,
    value:val?.id
    }
  })
  return result;
}
export const Propertydrawer = (company, t) => ([
  {
    componet_type: "asyncSelect",
    label: t("Company Name"),
    state_name: "company_name",
    value: company,
    required: true,
    readOnly: true,
    preserve: true

  },

  {
    componet_type: "text",
    label: t("Property Type"),
    state_name: "group_name",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  }
])

export const BlockPath = ["company_name", "name", "status", "icon"];

export const BlockHeading = (t) => [
  { title: "", field: 'icon' },
  // { title: "Company Name", field: "company_name", type: "numeric" },
  { title: t("Block Type"), field: "name" },
  { title: t("Status"), field: 'status', },
];
export const BlockType = [
  { type: ["more"], icon: "icon" },
  // { type: ["object"], name: "company_name" },
  { type: ["text"], name: "name", width: "250px" },
  { type: ["status"], name: "status" },
];
export const Blockdrawer = (company, t = () => false) => ([
  {
    componet_type: "asyncSelect",
    label: t("Company Name"),
    state_name: "company_name",
    value: company,
    required: true,
    readOnly: true,
    preserve: true
  },
  {
    componet_type: "text",
    label: t("Block Type"),
    state_name: "name",
    value: "",
    required: true
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
]);


export const AmenitiesPath = ["company_name", "name", "settings"];
export const manualResponseTax = (array) => {
  return array?.vat_group_master?.map((i) => {
    return {
      ...i,
      label: i?.label,
      value: i?.value
    }
  })
}
export const AmenitiesHeading = (t) => [
  { title: "", field: 'icon' },
  { title: t("Company"), field: "company_name", type: "numeric" },
  { title: t("Ameneties"), field: "name" },
  { title: t("Settings"), field: "settings" },
  { title: t("Status"), field: 'status', },

];
export const Amenitiestype = [
  { type: ["more"], icon: "icon" },
  { type: ["object"], name: "company_name" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "settings" },
  { type: ["status"], name: "status" },

];
export const Amenitiesdrawer = (t) => [
  {
    componet_type: "asyncSelect",
    label: t("Company"),
    state_name: "company_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>loadOptionsApis(
      "queries/company_master/list",
      {
        is_dropdown:true
      },
      search,
      array,
      handleLoading,
      "company_master",
      {},
      null
    ),
    debounceTimeout: 800
  },
  {
    componet_type: "text",
    label: t("Ameneties"),
    state_name: 'name',
    value: "",
    required: true
  },
  {
    componet_type: "select",
    label: t("Settings"),
    state_name: "settings",
    value: "",
    required: true,
    options: [
      { label: "Global", value: "Global" },
      { label: "Company Based", value: "Company Based" }
    ]
  },
  {
    componet_type: "text",
    label: t("Description"),
    state_name: "description",
    value: "",
    required: true,
    multiline: true
  },
  {
    componet_type: "text",
    label: t("Notes"),
    state_name: "notes",
    value: "",
    required: true
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];


export const UnitTypePath = ["company_name", "name", "status", "icon"];

export const UnitTypeHeading = (t = () => false) => [
  { title: "", field: 'icon' },
  // { title: "Company Name", field: "company_name", type: "numeric" },
  // { title: "Purpose", field: "purpose" },
  { title: t("Unit Type"), field: "name" },
  { title: t("Status"), field: 'status', },
];
export const UnitTypetype = [
  { type: ["more"], icon: "icon" },
  // { type: ["object"], name: "company_name" },
  // { type: ["object"], name: "purpose" },
  { type: ["text"], name: "name", width: "250px" },
  { type: ["status"], name: "status" },
];
export const UnitTypedrawer = (company, t) => ([
  {
    componet_type: "asyncSelect",
    label: t("Company Name"),
    state_name: "company_name",
    value: company,
    required: true,
    readOnly: true,
    preserve: true

  },
  {
    componet_type: "text",
    label: t("Unit Type"),
    state_name: "name",
    value: "",
    required: true
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
]);

export const RentalPath = [
  "company_name",
  "revenue_type",
  "name",
  "primary",
  "refundable",
  "is_onetime",
  "is_reserve_quality",
  "taxable",
  "is_parking",
  "is_quantity",
  "is_override",
  "is_short_term",
  "payment_period",
  "status",
  "icon"];

export const Rentalheading = (t = () => false) => [
  { title: "", field: 'icon' },
  { title: t("Company Name"), field: "company_name" },
  { title: t("Revenue Type"), field: "revenue_type" },
  { title: t("Pricing Component Name"), field: "name" },
  { title: t("Is Primary"), field: "primary" },
  { title: t("Payment Period"), field: "payment_period" },
  { title: t("Status"), field: 'status', },
];

export const RentalType = [
  { type: ["more"], icon: "icon" },
  { type: ["object"], name: "company_name" },
  { type: ["text"], name: "revenue_type" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "primary" },
  { type: ["text"], name: "payment_period" },
  { type: ["status"], name: "status" },
];

export const Rentaldrawer = (t = () => false) => [
  {
    componet_type: "asyncSelect",
    label: t("Company Name"),
    state_name: "company_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) => loadOptionsApis(
      "queries/company_master/list",
      {
        is_dropdown:true
      },
      search,
      array,
      handleLoading,
      "company_master",
      {},
      null,
    ),
    debounceTimeout: 800
  },
  {
    componet_type: "select",
    label: t("Revenue Type"),
    state_name: "revenue_type",
    value: "",
    required: true,
    options: [
      { label: t("Lease"), value: "Lease" },
      { label: t("Sale"), value: "Sale" },
      { label: t("Maintenance"), value: "Maintenance" }
    ]
  },
  {
    componet_type: "text",
    label: t("Pricing Component Name"),
    required: true,
    state_name: "name",
    value: "",
  },
  {
    componet_type: "switch",
    label: t("Is Primary"),
    state_name: "primary",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Refundable"),
    state_name: "refundable",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is One Time"),
    state_name: "is_onetime",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Reserve Quality"),
    state_name: "is_reserve_quality",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Taxable"),
    state_name: "taxtable",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Parking"),
    state_name: "is_parking",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Quantity"),
    state_name: "is_quantity",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Price Override Eligible"),
    state_name: "is_override",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "switch",
    label: t("Is Short Term"),
    state_name: "is_short_term",
    value: "",
    required: false,
    valueOption: { 'Yes': true, 'No': false }
  },
  {
    componet_type: "select",
    label: t("Payment"),
    state_name: "payment_period",
    value: "",
    required: true,
    options: [
      { label: t("Daily"), value: "Daily" },
      { label: t("Weekly"), value: "Weekly" },
      { label: t("Monthly"), value: "Monthly" },
      { label: t("Quarterly"), value: "Quarterly" },
      { label: t("Yearly"), value: "Yearly" },
      { label: t("Lump-Sum"), value: "Lump-Sum" },
      { label: t("Sale Contract"), value: "Sale Contract" },
    ]
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },

]

export const UnitPath = [
  "sno",
  "unit_name",
  'component_type',
  "period_type",
  "currency",
  "value",
  "value_type",
  "display",
  "tax_group",
];

export const UnitHeading = [
  { title: "", field: "icon" },
  { title: "Sno", field: "index", type: "numeric" },
  { title: "Unit name", field: "unit_name", type: "numeric" },
  { title: "Component Type", field: "component_type" },
  { title: "Period Type", field: "period_type" },
  { title: "Currency", field: "currency" },
  { title: "Value", field: "value" },
  { title: "Value type", field: "value_type" },
  { title: "Display % ", field: "display" },
  { title: "Tax Group  ", field: "tax_group" },
];

export const UnitType = [
  { type: ["more"], icon: "icon" },
  { type: ["text"], name: "index" },
  { type: ["number"], name: "unit_name" },
  { type: ["object"], name: "component_type" },
  { type: ["text"], name: "period_type" },
  { type: ["object"], name: "currency" },
  { type: ["text"], name: "value" },
  { type: ["text"], name: "value_type" },
  { type: ["text"], name: "display" },
  { type: ["object"], name: "tax_group" },
  { type: ["status"], name: "status" },
];

// const manualResponse = (array) => array?.map(_=>({label:_?.rental_breakup_master?.label,value:_?.rental_breakup_master?.value}));

export const Unitdrawer = [

  {
    componet_type: "asyncSelect",
    label: "Property name",
    state_name: "property_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) => loadOptionsApis(
      "queries/property/list",
      {
          company_id: companyIDs
      },
      search,
      array,
      handleLoading,
      "data",
      {},
      manualResponseProperty
  ),
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['block_name', 'floor_name', 'unit_name']
  },
  {
    componet_type: "asyncSelect",
    label: "Block Name",
    state_name: "block_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "property_name",
    loadOptions: (search, array, handleLoading, state) => {
      if (state?.states?.property_name?.value) {
        return loadOptionsApis(
          "queries/block/dropdown",
          { is_active: [true], property_id: state?.states?.property_name?.value },
          search,
          array,
          handleLoading,
          "data",
          {},
        )
      }
      handleLoading()
      return { options: [] }
    },
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['floor_name', 'unit_name']
  },
  {
    componet_type: "asyncSelect",
    label: "Floor name",
    state_name: "floor_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "block_name",
    // loadOptions: (search, array, handleLoading, state) => {
    //   if (state?.states?.block_name?.value) {
    //     return loadOptions(
    //       search,
    //       array,
    //       handleLoading,
    //       GET_FLOOR_NAME,
    //       'floor',
    //       { isActive: true, block_id: state?.states?.block_name?.value },
    //     )
    //   }
    //   handleLoading()
    //   return { options: [] }
    // },
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['unit_name']
  },
  {
    componet_type: "asyncSelect",
    label: "Unit name",
    state_name: "unit_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "floor_name",
    loadOptions: (search, array, handleLoading, state) => {
      if (state?.states?.property_name?.value && state?.states?.block_name?.value && state?.states?.floor_name?.value) {
        return loadOptionsApis(
          "/queries/unit/list",
          {
            status: [true],
            property_id: state?.states?.property_name?.value,
            block_id: state?.states?.block_name?.value,
            floor_id: state?.states?.floor_name?.value
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponseUnit
        )
      }
      handleLoading()
      return { options: [] }
    },
    debounceTimeout: 800,
  },
  {
    componet_type: "asyncSelect",
    label: "Component type",
    state_name: "component_type",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    // loadOptions: (search, array, handleLoading, state) => loadOptions(
    //   search,
    //   array,
    //   handleLoading,
    //   GET_COMPONENT_NAME_DROPDOWN,
    //   'rental_breakup_master',
    //   { isActive: true, },
    //   {},
    //   {},
    // ),
    debounceTimeout: 800,
    // manualResponse
  },
  {
    componet_type: "period",
    label: "Period type",
    state_name: "period_type",
    value: "",
    required: true,
  },
  {
    componet_type: "currency",
    label: "Value",
    state_name: "value",
    value: {
      currency: "",
      currency_code: ""
    },
    required: true,
  },
  {
    componet_type: "select",
    label: "Value Type",
    state_name: "value_type",
    value: "",
    required: true,
    options: [
      { label: "Amount", value: "amount" },
      { label: "Percentage", value: "percentage" },
      { label: "Per UOM", value: "per UOM" },
    ]
  },
  {
    componet_type: "text",
    label: "Display %",
    state_name: "display",
    value: "",
    required: true,
  },
  {
    componet_type: "asyncSelect",
    label: "Tax Group",
    state_name: "tax_group",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "block_name",
    loadOptions: (search, array, handleLoading, state) => loadOptionsApis(
      "queries/vat_group_master/list",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
      manualResponseTax
    ),
  },
  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },

];

export const UnitPurposePath = ["sno", "type"];

export const UnitPurposeHeading = [
  { title: "", field: 'icon' },
  { title: "Sno", field: "sno", type: "numeric" },
  { title: "Type", field: "type" },
  { title: "Status", field: 'status', },

];
export const UnitPurposetype = [
  { type: ["more"], icon: "icon" },
  { type: ["increment"], name: "sno" },
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },

];
export const UnitPurposedrawer = [
  {
    componet_type: "text",
    label: "Type",
    state_name: "type",
    value: "",
    required: true
  },
  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// export const MoveInPath = ["sno", "moveIn","refId"];

// export const MoveInRow = [
//   {
//     sno: "",
//     moveIn: "MoveIn Type",
//     refId:"MoveIn",
//     icon: "editviewtoggle",
//   },

// ];
// export const MoveInHeading = [
//   { title: "Sno", field: "sno", type: "numeric" },
//   { title: "Movein-Moveout", field: "moveIn" },
//   { title: "Reference Id", field: "refId" },
//   { title: "", field: "icon" },
// ];
// export const MoveIntype = [
//   { type: ["increment"], name: "sno" },
//   { type: ["text"], name: "moveIn" },
//   { type: ["text"], name: "refId" },
//   { type: ["icon"], icon: "icon",is_active:"is_active" },
// ];
// export const MoveIndrawer=[
//   {
//     type:"dropdown",
//     title:"Movein-Moveout",
//     options:[
//       {label:"One",value:"1"},
//       {label:"Two",value:"1"},
//       {label:"Three",value:"1"},
//     ]
//   },
//   {
//     type:"text",
//     title:"Reference Id",
//   },
//   {
//     type:"switchbox",
//     title:"Status"
//   },
// ];

export const drawerFilter = [
  {
    componet_type: "Reqirement",
    label: "Name",
    state_name: 'name',
    value: "",
    required: true
  },
  {
    componet_type: "text",
    label: "Description",
    state_name: "description",
    value: "",
    required: true,
    multiline: true
  },
  {
    componet_type: "text",
    label: "Notes",
    state_name: "notes",
    value: "",
    required: true
  },

]