import { Box, Stack } from '@mui/material'
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { withNamespaces } from "react-i18next"
import { Subheader } from '../../components'
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { accessCheckRender, Events, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import { ScheduleCalendarHeader } from './components/calHeader'
import DayCalendar from './components/dailyCalendar'
import { MonthlyCalendar } from './components/monthlyCalendar'
import { WeeklyCalendar } from './components/weeklyCalendar'
import { useStyles } from './styles'
const ScheduleBoard = (props) => {
    const { loading, handleLoading, t } = props;
    const classes = useStyles()
    const [companyList, setCompanyList] = React.useState([]);
    const [resourceList, setResourceList] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState({});
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const auth = React.useContext(AuthContext);
    const [permissions, setPermission] = React.useState({})
    const backdrop = React.useContext(BackdropContext)
    const [type, setType] = useState("Weekly")
    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })
    const [month, setMonth] = React.useState(new Date())
    const [days, setDays] = React.useState({ Date: new Date() })
    const [list, setList] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [propertyData, setPropertyData] = React.useState([])
    const [propertyValue, setPropertyValue] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        job_type: [],
        resource: []
    })
    let startDate = weekdates.startDate
    const endDate = weekdates.endDate

    //month
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const monthstartDate = startOfWeek(monthStart);
    const monthendDate = endOfWeek(monthEnd);

    let day = monthstartDate;
    let finaldays = []
    while (day <= monthendDate) {
        for (let i = 0; i < 7; i++) {
            day = addDays(day, 1);
            finaldays.push(day)
        }
    }

    var dates = []
    while (startDate <= endDate) {
        dates.push(startDate)
        startDate = addDays(startDate, 1)
    }
    const getList = (type, propertyId, start_date, end_date, company, search, reload, filteredData = {}) => {
        setLoader(true)
        const payload = {
            company_id: company,
            property_id: propertyId,
            resource_type: type?.value,
            from: start_date,
            to: end_date,
            batchDuration: 4,
            job_type: filteredData?.job_type?.map((x) => { return x?.value }) ?? [],
            resource_id: filteredData?.resource?.map((x) => { return x?.value }) ?? []
        }

        NetworkCall(
            `${config.api_url}/schedule_board/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoader(false)
            let myObject = res.data.data
            let result = []
            for (var key in myObject) {
                if (myObject.hasOwnProperty(key)) {
                    result.push({
                        date: key,
                        schedule: myObject[key]
                    })
                }
            }

            setList(result)
            handleLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
    }

    const getResourceMasterList = (company, propertyId) => {
        const payload={
            "offfset":0,
            "limit":10,
            "search":"",
            "status":[true]
            }
        NetworkCall(
            `${config.api_url}/queries/resource_type_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
                setResourceList(res.data.data.resource_type_master)
                getList(res.data.data.resource_type_master?.filter(i => i?.resource_code === "RTM-5")?.[0], propertyId.value, weekdates?.startDate, weekdates?.endDate, company, "", false, filterData)
                setSelectedType(res.data.data.resource_type_master?.filter(i => i?.resource_code === "RTM-5")?.[0])
            })
    }
    const getPropertyDropdown = (company, property = []) => {
        let allProperties = [{ value: null, label: "All Properties" }]
        const payload = {
            company_id: company,
            id: property
        }
        NetworkCall(
            `${config.api_url}/queries/property/dropdown`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setPropertyData(allProperties.concat(res.data.property))
            setPropertyValue(allProperties?.[0])
            getResourceMasterList(company, allProperties?.[0])

        })
    }
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                getPropertyDropdown(company?.selected?.value, company?.selected?.property?.map(i => i?.id))
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
            }
        }

        // eslint-disable-next-line
    }, [auth])
    const monthNext = () => {
        setMonth(addMonths(month, 1))
    }

    const monthPrev = () => {
        setMonth(subMonths(month, 1))
    }
    const dayNext = () => {
        setDays({ Date: addDays(days.Date, 1) })
    }

    const dayPrev = () => {
        setDays({ Date: subDays(days.Date, 1) })
    }
    const next = () => {
        setWeekdates({
            startDate: addDays(weekdates.endDate, 1), endDate: addDays(weekdates.endDate, 7)
        })
        getList(selectedType, propertyValue.value, addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7), selectedCompany?.value, "", false, filterData)
    }

    const prev = () => {
        setWeekdates({
            startDate: subDays(weekdates.startDate, 7), endDate: subDays(weekdates.startDate, 1)
        })
        getList(selectedType, propertyValue.value, subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1), selectedCompany?.value, "", false, filterData)
    }
    //onchange company
    const onChangeCompany = (val) => {
        setSelectedCompany(val)
        getPropertyDropdown(val.value, val?.property?.map(i => i?.id))
        getList(selectedType, propertyValue.value, new Date(), addDays(new Date(), 6), val?.value, "", true)
        // setOffset(0)
    }
    //on change type
    const onChangeType = (val) => {
        setSelectedType(val)
        getList(val, propertyValue.value, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, "", true, filterData)
        // setOffset(0)
    }
    //handlePropertyChange
    const onPropertyChange = (val) => {
        setPropertyValue(val)
        getList(selectedType, val.value, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, "", true, filterData)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
        getList(selectedType, propertyValue.value, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, "", true, value)
    }

    const render = () => {
        return (
            <Box>
                <Subheader hideBackButton title={t("Schedule Board")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    propertyValue={propertyValue}
                    onchange={(e) => {
                        onChangeCompany(e)
                    }}
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    selectProperty
                    propertyOptions={propertyData}
                />
                <Stack spacing={2} className={classes.root}>

                    <Box>
                        <ScheduleCalendarHeader
                            state={type === "Monthly" ? month : type === "Daily" ? days : weekdates}
                            prev={type === "Monthly" ? monthPrev : type === "Daily" ? dayPrev : prev}
                            next={type === "Monthly" ? monthNext : type === "Daily" ? dayNext : next}
                            resource={resourceList}
                            selectedType={selectedType}
                            setSelectedType={onChangeType}
                            type={type}
                            setType={setType}
                            companyId={selectedCompany?.value}
                            t={t}
                            filterData={filterData}
                            onApplyFilter={onApplyFilter}
                        /></Box>
                    {
                        loader ?
                            <NewLoader minusHeight="158px" />
                            :
                            <Box>
                                {type === "Weekly" ?
                                    <WeeklyCalendar t={t} dates={dates} weekdatas={list} companyId={selectedCompany.value} propertyId={propertyValue?.value} resourceType={selectedType?.value} />
                                    : type === "Daily" ?
                                        <DayCalendar t={t} list={list} />
                                        : <MonthlyCalendar t={t} dates={finaldays} month={month} events={Events} />
                                }
                            </Box>
                    }
                </Stack>
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions, "", loading)}

        </Box>
    )
}
export default withNamespaces("scheduleBoard")(ScheduleBoard)
