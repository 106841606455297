import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { AlertDialog, Editor } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
    AlertProps,
    LocalStorageKeys,
    NetWorkCallMethods,
    SwitchInspectionRoutes,
    accessCheckRender,
    getRoutePermissionNew
} from "../../utils";
import { maintanceDynamicTabs } from '../../utils/requestTableData';
import { CloseRequest } from '../maintenancerequest/components/closerequest';
import { Tab } from './components';
import { Costing, MoveTo, ServiceLevelAgreement, RequestView as View } from './steps';
import Invoice from "./steps/invoice";
import { ProposedBOM } from "./steps/proposedBOM";
import SummaryTable from "./steps/summaryTable";
import { useStyles } from './style';

const RequestView = (props) => {
    const { loading, handleLoading, t } = props;
    const { state } = useLocation();
    // context
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    // classes
    const classes = useStyles()
    // history
    const history = useHistory();
    // state
    const [showMore, setShowMore] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [offset, setOffset] = React.useState(0)
    const [serviceLevelData, setServiceLevelData] = React.useState([])
    const [is_resident, setIsResident] = React.useState(false)
    const [value, setValue] = useState({
        tabValue: 0,
        assignModal: false,
        closeRequest: false
    })
    const [request, setRequest] = React.useState({
        isbool: false,
        closeRequestType: ""
    })
    const [datas, setDatas] = useState({
        data: {},
        imagesListForView: [],
        is_show: false,
        moveTo: true,
        showServiceCreate: false,
        showComplete: false,
        request_type: "service",
        delivery_scope: "",
        showScopeOfWork: false,
        assets: [],
        is_edit_work: false
    });
    const [load, setLoad] = useState(false)
    const [notes, setNotes] = useState("")
    const [permission, setPermission] = React.useState({})
    const [serviceDetails, setServiceDetails] = React.useState([])
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [tabOptions, setTabOptions] = React.useState(maintanceDynamicTabs(t, value?.tabValue, classes, state?.main?.request === "Maintenance" ? true : false ?? []))
    // functions
    const updateState = (key, value) => {
        setValue({ ...value, [key]: value })
        if (value === 1) {
            setDatas({ ...datas, request_type: "service" })
            setSearchText("")
            getServiceDetails("service", datas?.service_inspection?.[0]?.id)
        }
        if (value === 2) {
            setDatas({ ...datas, request_type: "general" })
            setSearchText("")
            getServiceDetails("general", datas?.general_inspection?.[0]?.id)
        }
        if (value === 3) {
            setSearchText("")
            setDatas({ ...datas, request_type: "delivery-order" })
            getServiceDetails("delivery-order", datas?.delivery_inspection?.[0]?.id)
        }
    }
    const handleClose = () => {
        setRequest({ ...request, isbool: false })
    }
    const reload = () => {
        getDetails(false);
        getServiceLevelAgreementDetails()
    }
    const getServiceLevelAgreementDetails = () => {
        const payload = {
            "request_id": state?.main?.value,
            "type": state?.main?.request,
        }

        NetworkCall(
            `${config.api_url}/request/service_level_agreement`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setServiceLevelData(response?.data?.data)
            // console.log(response?.data?.data?.[0]?.costing?.length>0)
            // setCostingData(response?.data?.data?.[0]?.costing?.length > 0 ? addId(response?.data?.data?.[0]?.costing) : [])

        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    // get details
    const getDetails = (initial = true) => {
        const payload = {
            id: state?.main?.value,
            type: state?.main?.request === "Maintenance" ? "Maintenance" : "General"
        }
        NetworkCall(
            `${config.api_url}/request/view`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const tempImageList = response?.data?.data?.table?.[0]?.images?.map(
                ({ url }) => url
            );
            setDatas({
                ...datas,
                data: response?.data?.data ?? {},
                imagesListForView: tempImageList,
                is_show: true,
                showServiceCreate: false,
                service_inspection: response?.data?.data?.table?.[0]?.service_inspection ?? [],
                delivery_inspection: response?.data?.data?.table?.[0]?.delivery_inspection ?? [],
                general_inspection: response?.data?.data?.table?.[0]?.general_inspection ?? [],
                selected_service_inspection: response?.data?.data?.table?.[0]?.service_inspection?.[0],
                selected_delivery_inspection: response?.data?.data?.table?.[0]?.delivery_inspection?.[0],
                selected_general_inspection: response?.data?.data?.table?.[0]?.general_inspection?.[0],
                assets: response?.data?.data?.table?.[0]?.third_party_attachment,
                company_id: state?.main?.company
            });
            if (response?.data?.data?.table?.[0]?.service_inspection?.length > 0) {
                DynamicTabs([1, 4])
            }
            if (response?.data?.data?.table?.[0]?.general_inspection?.length > 0) {
                DynamicTabs([2, 4])
            }
            if (response?.data?.data?.table?.[0]?.delivery_inspection?.length > 0) {
                DynamicTabs([3, 4])
            }
            if (state?.main?.request === "Maintenance" && (response?.data?.data?.table?.[0]?.costing_status === "Approved" || response?.data?.data?.table?.[0]?.initial_costing_status === "Approved" || response?.data?.data?.table?.[0]?.type !== null)) {
                DynamicTabs([5])
            }
            if (response?.data?.data?.table?.[0]?.is_proposed_bom) {
                DynamicTabs([8])
            }
            if (response?.data?.data?.table?.[0]?.is_invoice) {
                DynamicTabs([7])
            }
            handleLoading(false)
        })
            .catch((err) => {
                handleLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong please try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    };
    const noteChange = (value) => {
        setNotes(value)
    }
    const getAllRequest = (type) => {
        getMultipleRequestDetails(type)
        setShowMore(true)
    }
    const closeRequestDialog = (type) => {
        if (type === "Resolve" || type === "Cancel" || type === "Reopen") {
            setRequest({ ...request, isbool: true, closeRequestType: type })
        }
        else {
            setValue({ ...value, closeRequest: !value?.closeRequest })
        }
    }
    const handleSearch = (val, type) => {
        setSearchText(val)
        getMultipleRequestDetails(type, val)
    }
    const DynamicTabs = (val) => {
        const result = tabOptions.map((x) => {
            if (val.includes(x.value)) {
                x.is_active = true
                return x
            }
            else {
                return x
            }

        })
        setTabOptions(result)
    }
    const getMultipleRequestDetails = (type, searchText = "", offset = 0, scroll = false) => {
        const payload = {
            search: searchText,
            maintenance_id: state?.main?.maintenance_id ?? undefined,
            general_request_id: state?.main?.general_request_id ?? undefined,
            request_type: type,
            offset: offset,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/general_inspection/get_maintatnce_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (type === "service") {
                setDatas({ ...datas, multipleServiceData: datas?.multipleServiceData?.concat(response?.data?.data?.data), showServiceCreate: false })
                if (!scroll) {
                    if (response?.data?.data?.data?.length > 1) {
                        getServiceDetails(type, datas?.selected_service_inspection?.id, response?.data?.data?.data)
                    }
                    else {
                        getServiceDetails(type, response?.data?.data?.data?.[0]?.id, response?.data?.data?.data)
                    }
                }
            }
            else if (type === "general") {
                setDatas({ ...datas, multipleGeneralData: datas?.multipleGeneralData?.concat(response?.data?.data?.data), showServiceCreate: false })
                if (!scroll) {
                    if (response?.data?.data?.data?.length > 1) {
                        getServiceDetails(type, datas?.selected_general_inspection?.id, response?.data?.data?.data)
                    }
                    else {
                        getServiceDetails(type, response?.data?.data?.data?.[0]?.id, response?.data?.data?.data)
                    }
                }
            }
            else {
                setDatas({ ...datas, multipleDeliveryOrderData: datas?.multipleDeliveryOrderData?.concat(response?.data?.data?.data), showServiceCreate: false })
                if (!scroll) {
                    if (response?.data?.data?.data?.length > 1) {
                        getServiceDetails(type, datas?.selected_delivery_inspection?.id, response?.data?.data?.data)
                    }
                    else {
                        getServiceDetails(type, response?.data?.data?.data?.[0]?.id, response?.data?.data?.data)
                    }
                }

            }
        })
            .catch(function (error) {
            })
    }
    const onChangeRequest = (reqType) => {
        let agreement_inspection_id = reqType === "service" ? datas?.selected_service_inspection?.id : reqType === "general" ? datas?.selected_general_inspection?.id : datas?.selected_delivery_inspection?.id
        getServiceDetails(reqType, agreement_inspection_id)
        setShowMore(false)
    }
    const goToRoutes = (title, type) => {
        if (title === "Detail") {
            const RoutePath = SwitchInspectionRoutes(type)
            const data = type === "service" ? datas?.selected_service_inspection : type === "general" ? datas?.selected_general_inspection : datas?.selected_delivery_inspection
            history.push({
                pathname: RoutePath,
                state: {
                    request_id: data?.request_id,
                    reference_id: data?.reference_id,
                    id: type === "service" ? datas?.selected_service_inspection?.id : type === "general" ? datas?.selected_general_inspection?.id : datas?.selected_delivery_inspection?.id,
                    agreement_inspection_id: type === "service" ? datas?.selected_service_inspection?.id : type === "general" ? datas?.selected_general_inspection?.id : datas?.selected_delivery_inspection?.id,
                    maintenance_company_id: state?.main?.company,
                }
            })
        }
    }

    const onCloseModal = (reqType) => {
        setSearchText("")
        setShowMore(false)
    }
    const getServiceDetails = (type, agreement_inspection_id, requests = []) => {
        const payload = {
            // "maintanance_request_id": state?.main?.value,
            maintanance_request_id: state?.main?.maintenance_id ?? undefined,
            general_request_id: state?.main?.general_request_id ?? undefined,
            "type": type,
            "agreement_inspection_id": agreement_inspection_id
        }
        NetworkCall(
            `${config.api_url}/general_inspection/get_request_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setServiceDetails(response?.data?.data)
            if (type === "service") {
                DynamicTabs([1, 4])
                setDatas({ ...datas, selected_service_inspection: response?.data?.data?.inspection, showServiceCreate: false, multipleServiceData: requests, service_inspection: [response?.data?.data?.inspection] })
            }
            else if (type === "general") {
                DynamicTabs([2, 4])
                setDatas({ ...datas, selected_general_inspection: response?.data?.data?.inspection, showServiceCreate: false, multipleGeneralData: requests, general_inspection: [response?.data?.data?.inspection] })
            }
            else {
                DynamicTabs([3, 4])
                setDatas({ ...datas, selected_delivery_inspection: response?.data?.data?.inspection, showServiceCreate: false, multipleDeliveryOrderData: requests, delivery_inspection: [response?.data?.data?.inspection] })
            }

            // getMultipleRequestDetails(type)
        })
            .catch(function (error) {
            })
    }
    const fetchMoreData = (reqType) => {
        getMultipleRequestDetails(reqType, "", offset + 10, true)
        setOffset(offset + 10)
    }
    const MoveToService = () => {
        setIsDisableBtn(true)
        const payload = {
            maintanance_request_id: state?.main?.maintenance_id ?? undefined,
            general_request_id: state?.main?.general_request_id ?? undefined,
            "request_type": datas?.request_type,
            "scope_of_work": datas?.request_type === "delivery-order" ? datas?.delivery_scope : undefined
        }
        const BOMPayload = {
            id: state?.main?.maintenance_id ?? undefined,
            is_proposed_bom: true,
            type: state?.main?.request === "Maintenance" ? "Maintenance" : "General"
        }
        NetworkCall(
            (datas?.request_type === "service" || datas?.request_type === "general") ? `${config.api_url}/general_inspection/convert_service_inspection` : datas?.request_type === "delivery-order" ? `${config.api_url}/general_inspection/convert_delivery_order_inspection` : datas?.request_type === "Projected BOM" ? `${config.api_url}/request/update_request_date` : "",
            NetWorkCallMethods.post,
            datas?.request_type === "Projected BOM" ? BOMPayload : payload,
            null,
            true,
            false
        ).then((response) => {
            setIsDisableBtn(false)
            setDatas({ ...datas, showServiceCreate: false, MoveTo: false, showComplete: true, delivery_scope: "" })
            if (datas?.request_type === "Projected BOM") {
                getDetails()
            }
            else {
                getMultipleRequestDetails(datas?.request_type)
            }
        })
            .catch(function (error) {
                setIsDisableBtn(false)
                setDatas({ ...datas, showServiceCreate: false, MoveTo: false, delivery_scope: "" })
                if (error.response) {

                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }

            });
    }
    const onMenuClick = (value) => {

        if (value === "service" || value === "general") {
            setDatas({ ...datas, showServiceCreate: true, request_type: value })
        }
        else if (value === "delivery-order") {
            setDatas({ ...datas, showScopeOfWork: true, request_type: value, delivery_scope: "" })
        }
        else if (value === "Projected BOM") {
            setDatas({ ...datas, showServiceCreate: true, request_type: value })
        }
        else if (value === "work-order") {
            if (datas?.data?.table?.[0]?.work_order_status?.value !== "closed") {
                setDatas({ ...datas, is_edit_work: true })
            }
        }
    }
    const closeShowCreateModal = () => {
        setDatas({ ...datas, showServiceCreate: false, delivery_scope: "" })
    }
    const reloadSLA = () => {
        getServiceLevelAgreementDetails()
        // getDetails(false);
    }
    const handleNotes = (value) => {
        setIsResident(value)
    }
    const notesUpdate = () => {
        try {
            let currentStatus = datas?.data?.table?.[0]?.current_status?.[0]
            // let status_array = datas?.data?.table?.[0]?.status?.reverse();

            // let initial_notes = status_array;

            // let status_type = status_array?.some(x => x?.status === "Closed") ? "Closed" : "Open";

            // let status_id = status_array?.find(x => x?.status === status_type)?.status_id;

            // if (status_type === "Closed") {
            //     let status_index = status_array?.findIndex(x => x?.status === status_type);
            //     initial_notes = status_array?.slice(status_index);
            // }

            const tokenData = jwt_decode(localStorage.getItem(LocalStorageKeys.authToken));

            const result = { notes: notes, created_at: new Date(), created_by: tokenData?.username, is_resident: is_resident };
            const variables = { id: currentStatus?.id, payload: { notes: (currentStatus?.notes !== null && currentStatus?.notes?.length > 0) ? [...currentStatus?.notes, result] : [result] } }
            NetworkCall(
                state?.main?.request === "Maintenance" ? `${config.api_url}/queries/maintenance_status/update` : `${config.api_url}/queries/general_status/update`,
                NetWorkCallMethods.post,
                variables,
                null,
                true,
                false
            ).then((response) => {
                dialogClose();
                reload();
                setNotes("")
                alert.setSnack({
                    ...alert, open: true, msg: "Note added SuccessFully",
                    severity: AlertProps.severity.success,
                });
            }).catch((err) => {
                setLoad(true);
                alert.setSnack({
                    ...alert, open: true, msg: "Something went wrong",
                    severity: AlertProps.severity.error,
                });
            })
        } catch (error) {
            alert.setSnack({
                ...alert, open: true, msg: "Something went wrong",
                severity: AlertProps.severity.error,
            });
        }

    }
    const dialogClose = () => {
        setLoad(!load)
    }
    // components
    const com = {
        0: <View datas={datas} state={state} setDatas={setDatas} t={t} notesUpdate={notesUpdate} dialogClose={dialogClose} load={load} noteChange={noteChange} notes={notes} type={datas?.data?.images?.length > 0 ? true : false} request={state?.main?.request === "Maintenance" ? true : false} handleTabs={(val) => DynamicTabs([val])} setValue={setValue} value={value} reload={reload} thirdPartyAssets={datas?.assets ?? []} handleNotes={handleNotes} is_resident={is_resident} />,
        1: <MoveTo datas={datas} setDatas={setDatas} t={t} getAllRequest={getAllRequest} serviceDetails={serviceDetails} showMore={showMore} setShowMore={setShowMore} searchText={searchText} setSearchText={setSearchText} request_type="service" onChangeRequest={onChangeRequest} handleSearch={handleSearch} onCloseModal={onCloseModal} fetchMoreData={fetchMoreData} goToRoutes={goToRoutes} />,
        2: <MoveTo datas={datas} setDatas={setDatas} t={t} getAllRequest={getAllRequest} serviceDetails={serviceDetails} showMore={showMore} setShowMore={setShowMore} searchText={searchText} setSearchText={setSearchText} request_type="general" onChangeRequest={onChangeRequest} handleSearch={handleSearch} onCloseModal={onCloseModal} fetchMoreData={fetchMoreData} goToRoutes={goToRoutes} />,
        3: <MoveTo datas={datas} setDatas={setDatas} t={t} getAllRequest={getAllRequest} serviceDetails={serviceDetails} showMore={showMore} setShowMore={setShowMore} searchText={searchText} setSearchText={setSearchText} request_type="delivery-order" onChangeRequest={onChangeRequest} handleSearch={handleSearch} onCloseModal={onCloseModal} fetchMoreData={fetchMoreData} goToRoutes={goToRoutes} />,
        4: <SummaryTable companyId={state?.main?.company} companyData={state?.main?.companyData} maintenanceRequestId={state?.main?.maintenance_id} generalRequestId={state?.main?.general_request_id} />,
        5: <Costing state={state} t={t} serviceLevelData={serviceLevelData} reloadSLA={reloadSLA} />,
        6: <ServiceLevelAgreement val={serviceLevelData} state={state} t={t} reload={reloadSLA} />,
        7: <Invoice val={serviceLevelData} state={state} t={t} />,
        8: <ProposedBOM state={state} t={t} />
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getDetails();
                getServiceLevelAgreementDetails()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, state?.main?.value]);
    const closeMaintenanceRequest = () => {
        const payload = {
            req_type: state?.main?.request === "Maintenance" ? 2 : 1,
            status: request?.closeRequestType === "Resolve" ? "Closed" : request?.closeRequestType === "Cancel" ? "Cancelled" : "Reopened",
            request_id: state?.main?.value
        }
        NetworkCall(
            `${config.api_url}/request/close_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                reload()
                setRequest({ ...request, isbool: false })
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: request?.closeRequestType === "Resolve" ? t("Request Closed Successfully") : request?.closeRequestType === "Cancel" ? t("Request Cancelled Successfully") : request?.closeRequestType === "Reopen" ? t("Request Reopened Successfully") : t("Request Updated Successfully"),
                });
            })
            .catch((error) => {
                setRequest({ ...request, isbool: false })
                if (error.response) {

                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message?.msg).length !== 0 ? error.response.data.error.message?.msg : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }
            });
    }
    const handleTabChange = (e) => {
        if (e === 6) {
            reloadSLA()
        }
        maintanceDynamicTabs(t, e, classes, state?.main?.request === "Maintenance" ? true : false ?? [])
        updateState('tabValue', e)
    }
    const render = () => {
        return (
            <div>
                {/* sub nav bar */}

                <div>
                    {/* tab component */}
                    <Tab
                        options={tabOptions.filter((x) => { return x.is_active })}
                        details={datas}
                        value={value?.tabValue} onClick={(e) => handleTabChange(e)} t={t} onAssign={() => handleClose()} onCloseRequest={closeRequestDialog} onMenuClick={onMenuClick} is_closeBtn={!datas?.data?.table?.[0]?.status?.some(x => x?.status === "Closed")} requestName={state?.main?.request} is_proposed_bom={datas?.data?.table?.[0]?.is_proposed_bom} />
                    {/* components */}
                    {
                        datas?.is_show && <div className={value?.tabValue === 0 ? classes.root1 : classes.root1}>
                            {com[value?.tabValue]}
                        </div>
                    }

                    {/* close request */}
                    <Dialog open={value?.closeRequest} className={classes.closeDialog}>
                        <CloseRequest
                            value={state?.main?.value}
                            request={state?.main?.request}
                            setRequestclose={() => closeRequestDialog()}
                            id={state?.main?.value}
                            reload={reload}
                            t={t}
                        />
                    </Dialog>

                    <AlertDialog open={request?.isbool}
                        onClose={() => setRequest({ ...request, isbool: false })}
                        isnotTitle={true}
                        medium
                        component={
                            <Box className={classes.reqblock} p={1} alignItems="center">
                                <Box display={"flex"} justifyContent="end" onClick={() => setRequest({ ...request, isbool: false })}>
                                    <Close className={classes.closeicon} />
                                </Box>
                                <Grid container mt={5}>

                                    {
                                        auth?.auth?.auth?.language === "ar" ? " " : <Grid item xs={4}></Grid>
                                    }
                                    <Grid item xs={8}>
                                        <Typography className={classes.q1hdr}>
                                            {`${t("Are you sure you want to")} ${request?.closeRequestType === "Resolve" ? t("resolve") : request?.closeRequestType === "Cancel" ? t("Cancel") : "Reopen"}  ${t("request")}`}?
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography className={classes.q1subhdr}>
                                                {t("The Process Cannot be reverted once done")}
                                            </Typography>
                                        </Box>
                                        <Stack mt={5} ml={2} direction="row" >
                                            <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => closeMaintenanceRequest()} disabled={isDisableBtn}>{t("Yes")}</Button>
                                            <Button variant="outlined" fullWidth className={classes.nobtn} onClick={() => setRequest({ ...request, isbool: false })}>{t("No")}</Button>

                                        </Stack>
                                    </Grid>
                                </Grid >
                            </Box >
                        }
                    />
                    <AlertDialog open={datas?.showServiceCreate}
                        onClose={() => closeShowCreateModal()}
                        isnotTitle={true}
                        medium
                        component={
                            <Box className={classes.reqblock} p={1} alignItems="center">
                                <Box display={"flex"} justifyContent="end" onClick={closeShowCreateModal}>
                                    <Close className={classes.closeicon} />
                                </Box>
                                <Grid container mt={5}>

                                    {
                                        auth?.auth?.auth?.language === "ar" ? " " : <Grid item xs={4}></Grid>
                                    }
                                    <Grid item xs={8}>
                                        <Typography className={classes.q1hdr}>
                                            {datas?.request_type === "Projected BOM" ? t("Are you sure you want to create Projected BOM") : `${t("Are you sure you want to create")} ${datas?.request_type === "service" ? t("Service Inspection") : datas?.request_type === "delivery-order" ? t("Delivery Work") : datas?.request_type === "general" ? t("General Inspection") : ""}  ${t("order request")}`}?
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography className={classes.q1subhdr}>
                                                {t("The Process Cannot be reverted once done")}
                                            </Typography>
                                        </Box>
                                        <Stack mt={5} ml={2} direction="row" >
                                            <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => MoveToService()} disabled={isDisableBtn}>{t("Yes")}</Button>
                                            <Button variant="outlined" fullWidth className={classes.nobtn} onClick={closeShowCreateModal}>{t("No")}</Button>

                                        </Stack>
                                    </Grid>
                                </Grid >
                            </Box >
                        }
                    />
                    <AlertDialog open={datas?.showComplete}
                        onClose={() => setDatas({ ...datas, showComplete: false })}
                        isnotTitle={true}
                        medium
                        component={
                            < Box className={classes.showComplete} p={1} alignItems="center" >
                                <Box display={"flex"} justifyContent="end" onClick={() => setDatas({ ...datas, showComplete: false })}>
                                    <Close className={classes.closeicon} />
                                </Box>
                                <Grid container mt={6}>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={8}>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                            <Typography className={classes.q1hdr}>
                                                {datas?.request_type === "Projected BOM" ? t("Projected BOM Created Successfully") : `${datas?.request_type === "service" ? t("Service Inspection") : datas?.request_type === "delivery-order" ? t("Delivery Work") : datas?.request_type === "general" ? t("General Inspection") : ""} ${t("Order Request created SuccessFully")}`}
                                            </Typography>
                                        </Box>
                                        <Box mt={3} ml={2} display="flex" justifyContent={"center"} alignItems="center">
                                            <Button variant="contained" className={classes.yesbtn} onClick={() => setDatas({ ...datas, showComplete: false })} sx={{ width: "100px" }}>{t("Okay")}</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box >
                        }
                    />
                    < AlertDialog
                        header={t("Delivery Scope of Work")}
                        open={datas?.showScopeOfWork}
                        onClose={() => setDatas({ ...datas, showScopeOfWork: false, delivery_scope: "" })}
                        medium
                        component={
                            < Box p={2} >
                                <Box>
                                    <Editor
                                        label=""
                                        handleChange={(e) => {
                                            // updateState("scopeOfWork", e)
                                            setDatas({ ...datas, delivery_scope: e })
                                        }}
                                        value={datas?.delivery_scope}
                                        height={"150px"}
                                    />
                                </Box>
                                <Stack mt={8} alignItems="end" direction="row" justifyContent="end">
                                    <Button variant="outlined" onClick={() => setDatas({ ...datas, showScopeOfWork: false, delivery_scope: "" })} className={classes.cancelbtn}>{t("Cancel")}</Button>
                                    <Button variant="contained" sx={{ marginInlineStart: '16px' }} disabled={datas?.delivery_scope?.length > 0 ? false : true} onClick={() => setDatas({ ...datas, showServiceCreate: true, showScopeOfWork: false })}>{t("Next")}</Button>
                                </Stack>
                            </Box >
                        }
                    />
                </div >

            </div >
        )
    }


    return (
        <>
            {accessCheckRender(render, permission, "", loading)}
        </>
    );
}
export default withNamespaces("request")(RequestView);