import { Box, Button, Stack, Typography } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useStyle } from "./style";
import { HoleNumber, addNewObj, calculateDaysBetweenDates, convertDaysToMonth, filterValues, header, headerFun, percentageOfAmounts, showMessage, tableRowValidation } from "./function";
import { UnitCard } from "./components/unitCard";
import { useContext, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AlertProps, checkNumber, enumName, enumSelect, enum_types } from "../../utils";
import { TemplateComponent } from "./components/templateGenerator";
import { DialogBox } from "../../components";
import { AlertContext, BackdropContext } from "../../contexts";


const CustomizeQuote = ({ t = () => false, goBack = () => false, data = {}, is_search = false, onSumbit = () => false, details = {}, quote_details = {} , company_id=null }) => {
    const classes = useStyle()
    const language = localStorage.getItem("i18nextLng")
    // state
    const [state, setState] = useState(data ?? [])
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialog, setDialog] = useState(false)
    const [primary , setPrimary]=useState(null)
    const [totalValue, setTotalValue] = useState({
        totalWithoutOne: 0,
        totalAmount: 0,
        totalrefundableTax: 0,
        totalTax: 0,
        totalDiscount: 0,
        total_quote_amount:0
    })
    const [enumValue, setEnumValue] = useState({
        revenue_type: [],
        unit_breakup_type: [],
        primary_unit_breakup_type: [],
        secondary_unit_breakup_type: [],
        one_time_unit_breakup_type: [],
        refundable_unit_breakup_type: [],
        parking_unit_breakup_type: [],
        pricing_period_type: [],
        item_based_pricing_period_type: [],
    });

    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext)


    const updateState = (numb, item, e, index1, i) => {
        let grace_period = details?.grace_period ? details?.grace_period : 0
        var Difference_In_Days =
            (details?.lease_start_date && details?.lease_end_date) ? calculateDaysBetweenDates(new Date(details?.lease_start_date), new Date(details?.lease_end_date)) -
                grace_period : 1;

        let days = convertDaysToMonth(Difference_In_Days)


        let pricing = state
        if(e === "discountValue" &&  numb > 100  && item?.discountType?.value === "%"){
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Percentage is must be smaller than 100"),
            });
        }
        if (e === "componentType" && numb?.value === "primary" && pricing?.flatMap((x) => x?.pricing)?.some((e) => e.componentType?.value === "primary")) {
           return setPrimary({
                 componenttype:item?.componenttype,
                 revenue_type:details?.revenue_type,
                 e,
                 componentType: numb,
                 is_wallet_item: numb?.value === "is_wallet_item" ? true : false,
                 i,
                 pricing,
                 bool:true,
                 index1:index1
            })
        }
        if (e === "componentvalues" && item?.valueBasisType === "Percentage" && numb > 100) {
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Percentage is must be smaller than 100"),
            });
        }
        let tempTableRowData = pricing?.map((x, indexunit) => {
            if (indexunit === i) {
                return {
                    ...x,
                    pricing: x?.pricing?.map((obj, index) => {
                        let totalBeforeDiscount = 0
                        let tempTaxableAmount = 0
                        let tempTaxes = 0
                        let tempSubtotal = 0
                        let tempTaxableAmountValue = 0
                        let amountWithQty = 0
                        let taxes = 0
                        let i = ((e === "pricing_components") ? numb?.vat?.vatitem?.map((a) => a?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0) ?? 0 : ((e === "vatGroup") ? numb?.vat_group_item?.map((a) => a?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0) ?? 0 : item?.vatGroup?.vatitem?.map((a) => a?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0) ?? 0))
                        let value = numb
                        let amount = (e === "componentvalues") ? numb : item?.componentvalues
                        let quantity = (e === 'quantity') ? numb > 1 ? numb : 1 : item?.quantity

                        amountWithQty = checkNumber(item?.quantity > 1 ? item?.quantity : 1) * checkNumber(item?.componentvalues)

                        if(e === "pricing_components" && numb?.sale_value){
                            amountWithQty = checkNumber(numb?.sale_value) * checkNumber(item?.quantity > 1 ? item?.quantity : 1)
                        }
                        if (e === 'componentvalues') {
                            amountWithQty = checkNumber(numb) * checkNumber(item?.quantity > 1 ? item?.quantity : 1)
                        }
                        if (e === 'quantity') {
                            amountWithQty = checkNumber(value > 1 ? value : 1) * checkNumber(item?.componentvalues)
                            tempTaxableAmountValue = checkNumber(value > 1 ? value : 1) * checkNumber(item?.taxAmountValue)
                        }

                        // ------------------- based on calculation -------------------------
                        totalBeforeDiscount = amountWithQty
                        let based_on = (e === "valueBasisType") ? value : item?.valueBasisType
                        // if (e !== "discountType") {
                            if (based_on === "Percentage") {
                                if (item?.pricing_components?.refundable) {
                                    let rentalPricing = x?.pricing?.filter((pri) => pri?.pricing_components?.isSecurity)?.map((comp) => {
                                        let rentalResult = 0;
                                        if (comp?.valueBasisType === "Amount") {
                                            rentalResult = comp?.componentvalues;
                                        }
                                        else if (comp?.valueBasisType === "Percentage") {
                                            let primary_value = x?.pricing?.filter((pri) => pri?.pricing_components?.primary)?.map((c) => {
                                                if (c?.valueBasisType === "Amount") {
                                                    return {
                                                        value: c?.componentvalues
                                                    }
                                                }
                                                else {
                                                    return {
                                                        value: x.totalArea * c?.componentvalues
                                                    }
                                                }
                                            })?.map((v) => v?.value).reduce((a, b) => a + b, 0)

                                            if (!item?.pricing_components?.refundable) {
                                                rentalResult = primary_value * (comp?.componentvalues / 100);
                                            }
                                        }
                                        else if (comp?.valueBasisType?.includes("Per UOM")) {
                                            rentalResult = x?.totalArea * comp?.componentvalues;
                                        }

                                        return rentalResult
                                    }).reduce((a, b) => a + b, 0)

                                    totalBeforeDiscount = (rentalPricing / 100) * amount
                                }
                                else {
                                    let percentageValue = x?.pricing
                                        .filter((pri) => pri?.pricing_components?.primary)
                                        .map((comp) => {
                                            let rentalResult = 0;
                                            let basic = 0;
                                            if (comp?.valueBasisType === "Amount") {
                                                basic = comp?.componentvalues;
                                                rentalResult = comp?.componentvalues;
                                            }
                                            else if (comp?.valueBasisType === "Percentage") {
                                                rentalResult = basic * (comp?.componentvalues / 100);
                                            } else if (comp?.valueBasisType?.includes("Per UOM")) {
                                                if (comp?.pricing_components?.primary) {
                                                    basic = x.totalArea * comp?.componentvalues;
                                                }

                                                rentalResult = x.totalArea * comp?.componentvalues;
                                            }
                                            return rentalResult
                                        })
                                        .reduce((a, b) => a + b, 0);

                                    let montlyTotalAmount;

                                    if (item?.pricing_components?.isOnetime) {
                                        montlyTotalAmount = Math.round(
                                            percentageValue *
                                            convertDaysToMonth(Difference_In_Days)
                                        );
                                    }

                                    totalBeforeDiscount = (item?.pricing_components?.isOnetime ? montlyTotalAmount : percentageValue) * (amount / 100);
                                }
                            }
                            else if (based_on?.includes("Per UOM")) {
                                totalBeforeDiscount = x.totalArea * amountWithQty
                            }
                            else if (based_on === "Rental Value") {

                                let rentalPricing = x?.pricing?.filter((item) => item?.pricing_components?.isSecurity)?.map((comp) => {
                                    let rentalResult = 0;

                                    if (comp?.valueBasisType === "Amount") {
                                        rentalResult = comp?.componentvalues;
                                    }
                                    else if (comp?.valueBasisType === "Percentage") {
                                        let primary_value = x?.pricing?.filter((pri) => pri?.pricing_components?.primary)?.map((c) => {
                                            if (c?.valueBasisType === "Amount") {
                                                return {
                                                    value: c?.componentvalues
                                                }
                                            }
                                            else {
                                                return {
                                                    value: x.totalArea * c?.componentvalues
                                                }
                                            }
                                        }).map((v) => v?.value).reduce((a, b) => a + b, 0);

                                        if (!comp?.refundable) {
                                            rentalResult = primary_value * (comp?.componentvalues / 100);
                                        }
                                    }
                                    else if (comp?.valueBasisType?.includes("Per UOM")) {
                                        rentalResult = comp?.totalArea * comp?.componentvalues;
                                    }
                                    return rentalResult
                                }).reduce((a, b) => a + b, 0);
                                totalBeforeDiscount = (rentalPricing * quantity);
                            }
                        // }

                        let payment = (e === "paymentPeriod") ? value : item?.paymentPeriod

                        if (payment === "Monthly" && details?.revenue_type?.value !== "Sale" && !item?.pricing_components?.isOnetime && !item?.pricing_components?.refundable) {
                            totalBeforeDiscount = Number(totalBeforeDiscount) * days ?? 1
                        }
                        let discountPercentage = item?.discountType?.value === "%" ? item?.discountValue : percentageOfAmounts(item?.discountValue,totalBeforeDiscount)
                        if(e === "discountValue"){
                            discountPercentage = item?.discountType?.value === "%" ? numb : percentageOfAmounts(numb,totalBeforeDiscount)
                        }
                        if(e === "discountType"){
                            discountPercentage = numb?.value === "%" ? item?.discountValue : percentageOfAmounts(item?.discountValue,totalBeforeDiscount)
                        }
                        taxes = totalBeforeDiscount * (checkNumber(i) / 100)

                        if (index === index1) {
                            switch (e) {
                                case "pricing_components":
                                if (totalBeforeDiscount >= (discountPercentage ?? 0)) {

                                    tempTaxableAmount =  totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100)
                                    tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)
                                    tempSubtotal = tempTaxableAmount + tempTaxes
                                    
                                    if (!numb?.taxtable) {
                                        
                                        return {
                                            ...obj,
                                            refundable: numb?.refundable ?? false,
                                            isOnetime: numb?.isOnetime ?? false,
                                            taxable: numb?.taxtable ?? false,
                                            isQuantity: numb?.isQuantity ?? false,
                                            pricing_components: numb,
                                            taxAmountValue: 0.00,
                                            subtotal: 0.00,
                                            vatGroup: numb?.taxtable ? numb?.vat : null,
                                            primary: numb?.primary ?? false,
                                            isChargeable: numb?.primary ?? false,
                                            isReserveQuantity: numb?.primary ?? false,
                                            isSecurity: numb?.primary ?? false,
                                            beforeTaxAmount: 0.00,
                                            beforeTax:0.00,
                                            discountError: "",
                                            amountWithoutDiscount: totalBeforeDiscount,
                                            valueBasisType: "",
                                            paymentPeriod: details?.revenue_type?.value === "Sale" ? item?.paymentPeriod : "",
                                            quantity: 1,
                                            discountValue: 0,
                                            componentvalues: numb?.sale_value ?? 0.00,
                                            error: {
                                                ...obj?.error,
                                                pricing_components: ""
                                            },                                            
                                            before_tax: 0.00,
                                            fixTax: 0.00     ,
                                            tax: 0.00,
                                            taxPercentage:0,
                                            discountPercentage
                                    };
                                    } else {
                                        return {
                                            ...obj,
                                            refundable: numb?.refundable ?? false,
                                            isOnetime: numb?.isOnetime ?? false,
                                            taxable: numb?.taxtable ?? false,
                                            isQuantity: numb?.isQuantity ?? false,
                                            pricing_components: numb,
                                            vatGroup: numb?.taxtable ? numb?.vat : null,
                                            primary: numb?.primary ?? false,
                                            isChargeable: numb?.primary ?? false,
                                            isReserveQuantity: numb?.primary ?? false,
                                            isSecurity: numb?.primary ?? false,
                                            discountError: "",
                                            amountWithoutDiscount: totalBeforeDiscount,
                                            valueBasisType: "",
                                            paymentPeriod: details?.revenue_type?.value === "Sale" ? item?.paymentPeriod : "",
                                            quantity: 1,
                                            discountValue: 0,
                                            componentvalues: numb?.sale_value ?? 0.00,
                                            error: {
                                                ...obj?.error,
                                                pricing_components: ""
                                            },
                                            beforeTaxAmount: tempTaxes?.toFixed(2),
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            subtotal: tempSubtotal?.toFixed(2),
                                            before_tax:taxes?.toFixed(2),
                                            fixTax: taxes?.toFixed(2),
                                            taxAmountValue: taxes?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            taxPercentage:i,
                                            discountPercentage
                              
                                        };
                                    }
                                }
                                else {
                                    tempTaxes = totalBeforeDiscount * (checkNumber(i) / 100)
                                    tempSubtotal = tempTaxableAmount + tempTaxes

                                    return {
                                        ...obj,
                                        refundable: numb?.refundable ?? false,
                                        isOnetime: numb?.isOnetime ?? false,
                                        taxable: numb?.taxtable ?? false,
                                        isQuantity: numb?.isQuantity ?? false,
                                        pricing_components: numb,
                                        vatGroup: numb?.taxtable ? numb?.vat : null,
                                        primary: numb?.primary ?? false,
                                        isChargeable: numb?.primary ?? false,
                                        isReserveQuantity: numb?.primary ?? false,
                                        isSecurity: numb?.primary ?? false,
                                        valueBasisType: "",
                                        paymentPeriod: details?.revenue_type?.value === "Sale" ? item?.paymentPeriod : "",
                                        quantity: 1,
                                        discountValue: 0,
                                        componentvalues: numb?.sale_value ?? 0.00,
                                        error: {
                                            ...obj?.error,
                                            pricing_components: ""
                                        },
                                        tax: tempTaxes?.toFixed(2),
                                        discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                        amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                        before_tax: taxes?.toFixed(2),
                                        fixTax: taxes?.toFixed(2),
                                        taxAmountValue: taxes?.toFixed(2),
                                        beforeTaxAmount: tempTaxes?.toFixed(2),
                                        subtotal: tempSubtotal?.toFixed(2),
                                        taxPercentage:i,
                                        beforeTax: tempTaxableAmount?.toFixed(2),
                                        discountPercentage
                                        
                                        
                                                                     };
                                }
                                case "vatGroup":
                                    if (totalBeforeDiscount >= (discountPercentage ?? 0)) {
                                        tempTaxableAmount =  totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100)
                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)
                                        tempSubtotal = tempTaxableAmount + tempTaxes

                                        return {
                                            ...obj,
                                            vatGroup: numb,
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            subtotal: tempSubtotal?.toFixed(2),
                                            amountWithoutDiscount: totalBeforeDiscount,
                                            taxPercentage:i,
                                            discountPercentage,
                                            before_tax:taxes?.toFixed(2),
                                            fixTax: taxes?.toFixed(2),
                                            taxAmountValue: taxes?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            error: {
                                                ...obj?.error,
                                                taxGroup: ""
                                            }
                                        }
                                    } else {
                                        return {
                                            ...obj,
                                            vatGroup: numb,
                                            discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                            error: {
                                                ...obj?.error,
                                                vatGroup: ""
                                            }
                                        };
                                    }
                                case "discountType":
                                    if (totalBeforeDiscount >= discountPercentage) {
                                        tempTaxableAmount = totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100)
                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)
                                        tempSubtotal = tempTaxableAmount + tempTaxes
                                        return {
                                            ...obj,
                                            discountType: numb,
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            subtotal: tempSubtotal?.toFixed(2),
                                            amountWithoutDiscount: totalBeforeDiscount,
                                            before_tax: tempTaxes?.toFixed(2),
                                            fixTax: taxes,
                                            taxAmountValue: taxes,
                                            beforeTaxAmount: tempTaxes?.toFixed(2),
                                            discountPercentage,
                                            taxPercentage:i,
                                            error: {
                                                ...obj?.error,
                                                discountType: ""
                                            }
                                        }
                                    } else {
                                        return {
                                            ...obj,
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            subtotal: tempSubtotal?.toFixed(2),
                                            amountWithoutDiscount: totalBeforeDiscount,
                                            before_tax: tempTaxes?.toFixed(2),
                                            fixTax: taxes,
                                            taxAmountValue: taxes,
                                            beforeTaxAmount: tempTaxes?.toFixed(2),
                                            discountPercentage,
                                            taxPercentage:i,
                                            discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                            error: {
                                                ...obj?.error,
                                                discountType: ""
                                            }
                                        };
                                    }
                                case "quantity":
                                    if (totalBeforeDiscount >= discountPercentage) {
                                        tempTaxableAmount =  totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100) 
                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)
                                        tempSubtotal = tempTaxableAmount + tempTaxes
                                        if (!obj?.taxable) {
                                            return {
                                                ...obj,
                                                [e]: value,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: 0.00,
                                                subtotal: tempTaxableAmount?.toFixed(2),
                                                discountError: "",
                                                taxAmountValue: 0.00,
                                                taxPercentage:i,
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                discountPercentage,
                                                fixTax: 0.00,
                                                error: {
                                                    ...obj?.error,
                                                    quantity: ""
                                                }
                                            };
                                        } else {
                                            return {
                                                ...obj,
                                                [e]: value,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: tempTaxes?.toFixed(2),
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountError: "",
                                                taxAmountValue: tempTaxableAmountValue,
                                                taxPercentage:i,
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                discountPercentage,
                                                fixTax: taxes,
                                                error: {
                                                    ...obj?.error,
                                                    quantity: ""
                                                }
                                            };
                                        }
                                    } else {
                                        return {
                                            ...obj,
                                            taxAmountValue: tempTaxableAmountValue,
                                            amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                            [e]: value,
                                            taxPercentage:i,
                                            discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                            discountPercentage,
                                            error: {
                                                ...obj?.error,
                                                quantity: ""
                                            }
                                        };
                                    }
                                case "componentvalues":
                                    if (totalBeforeDiscount >= (discountPercentage ?? 0)) {

                                        tempTaxableAmount =  totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100) 

                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                                        tempSubtotal = tempTaxableAmount + tempTaxes
                                        if (!obj?.taxable) {

                                            return {
                                                ...obj,
                                                taxPercentage:i,
                                                beforeTaxAmount: 0.00,
                                                componentvalues: !!numb ? Number(numb) : numb,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: 0.00,
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountError: "",
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                before_tax: 0.00,
                                                fixTax: 0.00,
                                                taxAmountValue: 0.00,
                                                discountPercentage,
                                                error: {
                                                    ...obj?.error,
                                                    componentvalues: ""
                                                }
                                            };
                                        } else {
                                            return {
                                                ...obj,
                                                taxPercentage:i,
                                                beforeTaxAmount: tempTaxableAmountValue,
                                                componentvalues: !!numb ? Number(numb) : numb,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: tempTaxes?.toFixed(2),
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountError: "",
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                before_tax: taxes,
                                                fixTax: taxes,
                                                taxAmountValue: taxes,
                                                discountPercentage,
                                                error: {
                                                    ...obj?.error,
                                                    componentvalues: ""
                                                }
                                            };
                                        }
                                    }
                                    else {
                                        return {
                                            ...obj,
                                            taxPercentage:i,
                                            beforeTaxAmount: tempTaxableAmountValue,
                                            componentvalues: !!numb ? Number(numb) : numb,
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            subtotal: tempSubtotal?.toFixed(2),
                                            discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                            amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                            before_tax: taxes,
                                            fixTax: taxes,
                                            taxAmountValue: taxes,
                                            discountPercentage,
                                            error: {
                                                ...obj?.error,
                                                componentvalues: ""
                                            }
                                        };
                                    }
                                case "discountValue":
                                    if (discountPercentage <= totalBeforeDiscount) {
                                        tempTaxableAmount = totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100) 
                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)
                                        tempSubtotal = tempTaxableAmount + tempTaxes

                                        if (!obj?.taxable) {

                                            return {
                                                ...obj,
                                                taxPercentage:i,
                                                discountValue: value,
                                                beforeTax: tempTaxableAmount.toFixed(2),
                                                tax: tempTaxes.toFixed(2),
                                                subtotal: tempSubtotal.toFixed(2),
                                                discountError: "",
                                                discountPercentage,
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                error: {
                                                    ...obj?.error,
                                                    discountValue: ""
                                                }
                                            };
                                        } else {
                                            return {
                                                ...obj,
                                                taxPercentage:i,
                                                discountValue: value,
                                                beforeTax: tempTaxableAmount.toFixed(2),
                                                tax: tempTaxes.toFixed(2),
                                                subtotal: tempSubtotal.toFixed(2),
                                                discountError: "",
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                discountPercentage,
                                                error: {
                                                    ...obj?.error,
                                                    discountValue: ""
                                                }
                                            };
                                        }
                                    }
                                    else {

                                        return {
                                            ...obj,
                                            taxPercentage:i,
                                            discountValue: value,
                                            beforeTax: 0.00,
                                            tax: 0.00,
                                            subtotal: 0.00,
                                            amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                            discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                            discountPercentage,
                                            error: {
                                                ...obj?.error,
                                                discountValue: ""
                                            }
                                        };
                                    }
                                case "valueBasisType":
                                    if (totalBeforeDiscount >= (discountPercentage ?? 0)) {

                                        tempTaxableAmount =  totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100)

                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                                        tempSubtotal = tempTaxableAmount + tempTaxes

                                        if (!obj?.taxable) {

                                            return {
                                                ...obj,
                                                [e]: numb,
                                                taxPercentage:i,
                                                beforeTaxAmount: 0.00,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: 0.00,
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountError: "",
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                discountPercentage,
                                                fixTax: 0.00,
                                                error: {
                                                    ...obj?.error,
                                                    valueBasisType: ""
                                                }
                                            };
                                        } else {
                                            return {
                                                ...obj,
                                                [e]: numb,
                                                taxPercentage:i,
                                                beforeTaxAmount: tempTaxableAmountValue,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: tempTaxes?.toFixed(2),
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountError: "",
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                discountPercentage,
                                                fixTax: taxes,
                                                error: {
                                                    ...obj?.error,
                                                    valueBasisType: ""
                                                }
                                            };
                                        }
                                    }
                                    else {
                                        return {
                                            ...obj,
                                            [e]: numb,
                                            taxPercentage:i,
                                            beforeTaxAmount: tempTaxableAmountValue,
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            subtotal: tempSubtotal?.toFixed(2),
                                            amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                            discountPercentage,
                                            fixTax: taxes,
                                            error: {
                                                ...obj?.error,
                                                valueBasisType: ""
                                            }
                                        };
                                    }
                                case "paymentPeriod":
                                    if (totalBeforeDiscount >= (discountPercentage ?? 0)) {

                                        tempTaxableAmount = totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(discountPercentage)) / 100) 

                                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                                        tempSubtotal = tempTaxableAmount + tempTaxes

                                        if (!obj?.taxable) {

                                            return {
                                                ...obj,
                                                [e]: numb,
                                                taxPercentage:i,
                                                beforeTaxAmount: 0.00,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: 0.00,
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountError: "",
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                discountPercentage,
                                                fixTax: 0.00,
                                                error: {
                                                    ...obj?.error,
                                                    paymentPeriod: ""
                                                }
                                            };
                                        } else {
                                            return {
                                                ...obj,
                                                [e]: numb,
                                                taxPercentage:i,
                                                beforeTaxAmount: tempTaxableAmountValue,
                                                beforeTax: tempTaxableAmount?.toFixed(2),
                                                tax: tempTaxes?.toFixed(2),
                                                subtotal: tempSubtotal?.toFixed(2),
                                                discountPercentage,
                                                discountError: "",
                                                fixTax: taxes,
                                                amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                                error: {
                                                    ...obj?.error,
                                                    paymentPeriod: ""
                                                }
                                            };
                                        }
                                    }
                                    else {
                                        return {
                                            ...obj,
                                            taxPercentage:i,
                                            [e]: numb,
                                            beforeTaxAmount: tempTaxableAmountValue,
                                            beforeTax: tempTaxableAmount?.toFixed(2),
                                            tax: tempTaxes?.toFixed(2),
                                            discountPercentage,
                                            subtotal: tempSubtotal?.toFixed(2),
                                            fixTax: taxes,
                                            amountWithoutDiscount: !!totalBeforeDiscount ? Number(totalBeforeDiscount?.toFixed(2)) : 0,
                                            error: {
                                                ...obj?.error,
                                                paymentPeriod: ""
                                            }
                                        };
                                    }
                                case "componentType":
                                    return {
                                        ...obj,
                                        [e]: numb,
                                        taxPercentage:0,
                                        beforeTaxAmount: "",
                                        beforeTax: "",
                                        tax: "",
                                        subtotal: "",
                                        discountError: "",
                                        amountWithoutDiscount: "",
                                        pricing_components: "",
                                        valueBasisType: "",
                                        quantity: 1,
                                        discountValue: "",
                                        vatGroup: "",
                                        componentvalues: 0,
                                        discountPercentage,
                                        paymentPeriod: details?.revenue_type?.value === "Sale" ? item?.paymentPeriod : "",
                                        error: {
                                            ...obj?.error,
                                            componentType: ""
                                        }
                                    };
                                    case "uom_type":
                                        return {
                                            ...obj,
                                            [e]: numb,
                                            error: {
                                                ...obj?.error,
                                                uom_type: ""
                                            }
                                        };
    
                                default:
                                    return { ...obj, [e]: numb };
                            }
                        }
                        return obj
                    })
                }
            }
            else {
                return {
                    ...x
                }
            }
        })
        const current_data = tempTableRowData?.find((z,ind)=>ind === i)
        if(item?.componentType?.value === "primary" && current_data?.pricing?.length > 1){
            return setPrimary({
                bool:true,
                i,
                index1,
                tempTableRowData,
                type:"onchange"
            })
        }
        setState([...tempTableRowData])
    }
    const addNew = (i, e, revenue_type, type) => {
        let all = [...state];
        all[i] = {
            ...all[i],
            pricing: [...all[i]?.pricing, addNewObj(e, revenue_type, type)],
            is_open: false
        };
        setState([...all]);
    }
    const handleAddMenuClose = (i, value, v) => {
        let all = [...state];
        all[i] = {
            ...all[i],
            is_open: value,
        };
        setState([...all]);
        if (value) {
            return setAnchorEl(v?.currentTarget)
        }
        setAnchorEl(false)
    }
    const deleteComponent = (d, p, v) => {
        if (v?.pricing_components?.primary) {
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Primary Component is Required",
            });
        }

        let all = [...state];
        all[d] = {
            ...all[d],
            pricing: all[d]?.pricing?.filter((x, i) => i !== p)
        };
        setState([...all]);
    }
    const create = () => {
        if (!state?.flatMap((v) => v?.pricing)?.filter((val) => val?.is_active)?.length) {
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Add atleast one component",
            });
        }
        if (!tableRowValidation(state)) {

            const totalrefundableTax = state
            ?.map((i) => i?.pricing)
            .flat()
            ?.filter(item => !filterValues.includes(item?.componentType?.value))
            ?.filter((x) => x?.is_active)
            ?.filter((i) => i?.refundable === true)
            .map((item) => Number(item?.amountWithoutDiscount))
            .reduce((a, b) => a + b, 0);


            const totalAmount = state
                ?.map((i) => i?.pricing)
                .flat()
                ?.filter(item => !filterValues.includes(item?.componentType?.value))
                ?.filter((x) => x?.is_active)
                .map((item) => Number(item?.amountWithoutDiscount))
                .reduce((a, b) => a + b, 0);

            const totalTax = state
                ?.map((i) => i?.pricing)
                .flat()
                ?.filter(item => !filterValues.includes(item?.componentType?.value))
                ?.filter((x) => x?.is_active)
                .map((item) => Number(item?.fixTax))
                .reduce((a, b) => a + b, 0);


            let totalTaxwithDiscount = state
            ?.map((i) => i?.pricing)
            .flat()
            ?.filter(item => !filterValues.includes(item?.componentType?.value))
            ?.filter((x) => x?.is_active)
            .map((item) => Number(item?.tax))
            .reduce((a, b) => a + b, 0);

            let totalAmountwithDiscount = state
            ?.map((i) => i?.pricing)
            .flat()
            ?.filter(item => !filterValues.includes(item?.componentType?.value))
            ?.filter((x) => x?.is_active)
            .map((item) => Number(item?.beforeTax))
            .reduce((a, b) => a + b, 0);
            
            const total_discount_amount = (totalAmount+totalTax)-(totalAmountwithDiscount+totalTaxwithDiscount)

            const total_one = state?.flatMap(i => i?.pricing)
                ?.filter(j => j?.refundable === false)
                ?.filter(item => !filterValues.includes(item?.componentType?.value))?.filter((x) => x?.is_active)?.filter(k => k?.isOnetime === false)?.map(i => {
                    return Number(i?.subtotal)
                })?.reduce((a, b) => a + b, 0)

            setTotalValue({
                totalWithoutOne: HoleNumber(total_one),
                totalAmount: HoleNumber(totalAmount),
                totalrefundableTax: HoleNumber(totalrefundableTax),
                totalTax: HoleNumber(totalTax),
                totalDiscount: HoleNumber(total_discount_amount),
                total_quote_amount:Number(((totalAmount + totalTax) - total_discount_amount)?.toFixed(2))
            })

            if (quote_details?.quott?.revenue_type === "Sale") {
                setDialog(true)
            }
            else {
                onSumbit(state)
            }
        }
        else {
            showMessage(state, setState)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill Mandatory Fields"),
            });
        }


    }

    //get Enum
    const getEnum = async () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        const result = await enumSelect([
            enum_types.revenue_type,
            enum_types.unit_breakup_type,
            enum_types.pricing_period_type,
            enum_types.utility_period_type
        ]);
        let primary_unit_breakup_type = [],
            secondary_unit_breakup_type = [],
            one_time_unit_breakup_type = [],
            parking_unit_breakup_type = [],
            refundable_unit_breakup_type = [],
            item_based_pricing_period_type = [];
        for (let i = 0; i < result.unit_breakup_type.length; i++) {
            if (
                result.unit_breakup_type[i].value !== enumName.rentalValue &&
                result.unit_breakup_type[i].value !== enumName.percentage
            ) {
                primary_unit_breakup_type = [
                    ...primary_unit_breakup_type,
                    result.unit_breakup_type[i],
                ];
            }
            if (result.unit_breakup_type[i].value !== enumName.rentalValue) {
                secondary_unit_breakup_type = [
                    ...secondary_unit_breakup_type,
                    result.unit_breakup_type[i],
                ];
                one_time_unit_breakup_type = [
                    ...one_time_unit_breakup_type,
                    result.unit_breakup_type[i],
                ];
                parking_unit_breakup_type = [
                    ...parking_unit_breakup_type,
                    result.unit_breakup_type[i],
                ];
            }
            if (result.unit_breakup_type[i].value !== enumName.perUOM) {
                refundable_unit_breakup_type = [
                    ...refundable_unit_breakup_type,
                    result.unit_breakup_type[i],
                ];
            }
        }
        for (let i = 0; i < result.pricing_period_type.length; i++) {
            if (result.pricing_period_type[i].value !== enumName.daily) {
                item_based_pricing_period_type = [
                    ...item_based_pricing_period_type,
                    result.pricing_period_type[i],
                ];
            }
        }
        setEnumValue({
            revenue_type: result?.revenue_type?.filter(i => i?.value !== "Stay"),
            unit_breakup_type: result?.unit_breakup_type,
            primary_unit_breakup_type: primary_unit_breakup_type,
            secondary_unit_breakup_type: secondary_unit_breakup_type,
            one_time_unit_breakup_type: one_time_unit_breakup_type,
            refundable_unit_breakup_type: refundable_unit_breakup_type,
            parking_unit_breakup_type: parking_unit_breakup_type,
            pricing_period_type: result?.pricing_period_type,
            item_based_pricing_period_type: item_based_pricing_period_type,
            utility_period_type:result?.utility_period_type
        });
        backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: t("...Sending"),
        });
    };
    const cancel = ()=>{
        const d = primary?.i;
        const p = primary?.index1;

        let all = [...state];
        all[d] = {
            ...all[d],
            pricing: all[d]?.pricing?.filter((x, i) => i !== p)
        };
        setState([...all]);
        setPrimary(null)
    }
    const conform=()=>{
        const i = primary?.i
        const obj = {...addNewObj(primary?.componenttype , primary?.revenue_type , primary?.e),componentType: primary?.componentType, is_wallet_item:primary?.is_wallet_item }
            let all = [...primary?.pricing];
            all[i] = {
                ...all[i],
                pricing: [{...obj}],
            };
            setPrimary(null)
            return setState([...all])
    }
    const conform_sumbit=()=>{
        const tempTableRowData = primary?.tempTableRowData
        const index1 = primary?.index1
        const i = primary?.i

        // eslint-disable-next-line array-callback-return
        const rowData = tempTableRowData?.map((v , indexunit)=>{
            if (indexunit === i) {
            return {
                ...v,
                pricing:v?.pricing?.filter((x , ind)=>ind === index1)
            }
         }
        })
        setPrimary(null)
        return setState([...rowData])
    }
    const cancel_sumbit = ()=>{
        setPrimary(null)
    }
    useEffect(() => {
        getEnum()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box>
            {/* header */}
            <Box style={{ backgroundColor: "#fff", padding: "9px 9px", display: "flex", alignItems: "center", justifyContent: 'space-between', marginTop: "-4px" }}>
                <Stack direction={'row'} gap={1} alignItems="center">
                    <Box className={classes.iconbtn} onClick={goBack}>
                        {language === "ar" ?
                            <ArrowForwardIosIcon className={classes.iconArabic} htmlColor="black" /> :
                            <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                        }
                    </Box>
                    {/* subheader title */}
                    <Box>
                        <Typography className={classes.customTitle}>{t("Customize Quotation")}</Typography>
                        <Stack direction={'row'} gap={1} alignItems="center">
                            {
                                headerFun(details)?.filter((v) => v?.is_active)?.map((x, i) => {
                                    return (
                                        <Stack direction={'row'} gap={1} alignItems="center">
                                            <Typography className={x?.is_grid ? classes.customSubTitle1 : classes.customSubTitle}>{x?.name}</Typography>
                                            {i !== (headerFun(details)?.filter((v) => v?.is_active)?.length - 1) && <Box style={{ backgroundColor: "#CED3DD", borderRadius: '50%', padding: '4px' }} />}
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                    </Box>
                </Stack>

                <Button variant="contained" className={classes.submit} onClick={() => create()}>
                    {t("Update / Create")}
                </Button>

            </Box>


            <Stack direction="column" spacing={2} p={2} style={{ height: `calc(100dvh - 155px)`, overflow: "auto" }}>
                {
                    state?.map((x, i) => {
                        return <UnitCard enumValue={enumValue} data={x} header={header(t, classes, details?.revenue_type)} updateState={(e, index, l, o) => updateState(e, index, l, o, i)} t={t} handleCloseNewLine={(e, r, t) => addNew(i, e, details?.revenue_type, t)} anchorEl={anchorEl} handleAddMenuClose={(e, v) => handleAddMenuClose(i, e, v)} deleteComponent={(e, v) => deleteComponent(i, e, v)} revenue_type={details?.revenue_type} company_id={company_id}/>
                    })
                }
            </Stack>

            <DialogBox
                open={dialog}
                onClose={() => setDialog(false)}
                handleClose={() => setDialog(false)}
                maxWidth="sm"
                background
                header={t("Milestone Configuration")}
                component={<TemplateComponent quote_details={quote_details?.quott} t={t} onSumbit={(e) => {
                    setDialog(false)
                    onSumbit(state, e)
                }} total={totalValue?.totalWithoutOne} totalValue={totalValue} total_quote_amount={totalValue?.total_quote_amount ?? 0} />}
            />

<DialogBox
                open={primary?.bool}
                onClose={() => cancel()}
                handleClose={() => cancel()}
                maxWidth="sm"
                background
                isnotTitle
                component={<Box className={language === "ar" ? classes.proceedDialogArabic : classes.proceedDialog}>
                   <Box style={{ marginInlineEnd: "8px" }}>
                   <Typography className={classes.convert}>{primary?.type === "onchange" ? t("Are sure you want to change primary value?") : t("Are sure you want to change primary?")}</Typography>
                    <Typography className={classes.convertProcess}>{t("The process cannot be reverted once done")}</Typography>
                        <Box display="flex" alignItems="center" className={classes.marginTop}>
                            <Button className={classes.yes} onClick={primary?.type === "onchange" ? conform_sumbit : conform}>
                                {t("Yes")}
                            </Button>
                            <Button className={classes.No} onClick={primary?.type === "onchange" ? cancel_sumbit : cancel}>
                                {t("No")}
                            </Button>
                        </Box>
                   </Box>
                </Box>}
            />
        </Box>
    )
}
export default withNamespaces("quotationsList")(CustomizeQuote)
