import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme, backgroundColor, props) => ({
    root: {
        padding: "16px",
        // border: (props) => props?.border ? "none" : "1px solid #E4E8EE",
        // borderRadius: "4px",
        border: "1px solid #E4E8EE",

        borderRadius: "4px",
        backgroundColor: "#fff"
    },
    heading: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary

    },
    sub: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary,
        marginTop: "6px"
    },
    subNo: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
        marginTop: "6px",
        textAlign: '-webkit-match-parent',
        direction: "ltr"
    },
    status: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        padding: "2px 10px",
        borderRadius: "2px",
        display: "inline-block",
        marginTop: "6px",
        marginLeft: "-8px"
    },
    btn: {
        padding: "0px",
        boxShadow: "none",
        marginTop: "6px",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    btn1: {
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    sub1: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary,
    },
    dot:{
        backgroundColor:"#E4E8EE",
        borderRadius:"50%",
        width:"10px",
        height:"10px"
    },
    summarytitle:{
        color: "#4E5A6B",
        fontSize:"0.875rem",
        fontFamily: Bold,
    },
    title: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#4E5A6B",
    },
    primary_button: {
        padding: "12px",
        boxShadow: "none",
        marginTop: "6px",
    },
    seeMoreLessTextStyle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    remark: {
        fontSize:"0.875rem",
        wordBreak: "break-all",
        color: theme.typography.color.primary,
        fontFamily: SemiBold

    }
}));