import {
    GeneralPath,
    GeneralType,
    Generaldrawer,
    Generalheading,
    MaintenancePath,
    MaintenanceType,
    Maintenancedrawer,
    Maintenanceheading,
    SubGeneralPath,
    SubGeneralType,
    SubGeneraldrawer,
    SubGeneralheading,
    SubMaintenancePath,
    SubMaintenanceType,
    SubMaintenancedrawer,
    SubMaintenanceheading
} from '.';



export const serviceActions = (type, offset, limit, search, isActive = true,t) => {
    switch (type) {
        case 'General':
            return {
                endpoint: "/queries/general_category_master/list",
                resfunc: (array) => {
                    let arr = array?.general_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.index = offset + index + 1;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: Generalheading(t),
                    path: GeneralPath,
                    type: GeneralType,
                    drawer: Generaldrawer(t)

                }

            }
        case 'General Sub-Category':
            return {
                endpoint: "/queries/general_sub_category_master/list",
                resfunc: (array) => {
                    let arr = array?.general_sub_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.generalcategory = {
                            label: data?.general_category_master?.type,
                            value: data?.general_category_master?.id
                        }
                        mainData.valueID = data?.general_category_master?.id;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.index = offset + index + 1;
                        mainData.is_external = data?.is_external;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: SubGeneralheading(t),
                    path: SubGeneralPath,
                    type: SubGeneralType,
                    drawer: SubGeneraldrawer(t)
                }
            }
        case 'Maintenance':
            return {
                endpoint: "/queries/maintenance_category_master/list",
                resfunc: (array) => {
                    let arr = array?.maintenance_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.index = offset + index + 1;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: Maintenanceheading(t),
                    path: MaintenancePath,
                    type: MaintenanceType,
                    drawer: Maintenancedrawer(t)
                }
            }
        case 'Maintenance Sub-Category':
            return {
                endpoint: "/queries/maintenance_sub_category_master/list",
                resfunc: (array) => {
                    let arr = array?.maintenance_sub_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.maintenancecategory = {
                            label: data?.maintenance_category_master?.type,
                            value: data?.maintenance_category_master?.id
                        }
                        mainData.valueID = data?.maintenance_category_master?.id;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.icon = "editviewtoggle";
                        mainData.index = offset + index + 1;
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: SubMaintenanceheading(t),
                    path: SubMaintenancePath,
                    type: SubMaintenanceType,
                    drawer: SubMaintenancedrawer(t)
                }
            }
        default:
            return ''
    }
}


export const serviceAddActions = {
    'General': {
        endpoint: "/queries/general_category_master/upsert",
    },
    'General Sub-Category': {
        endpoint: "/queries/general_sub_category_master/upsert",
    },
    'Maintenance': {
        endpoint: "/queries/maintenance_category_master/upsert",
    },
    'Maintenance Sub-Category': {
        endpoint: "/queries/maintenance_sub_category_master/upsert",
    }
}

export const serviceEditActions = {
    'General': {
        endpoint: "/queries/general_category_master/upsert",
    },
    'General Sub-Category': {
        endpoint: "/queries/general_sub_category_master/upsert",
    },
    'Maintenance': {
        endpoint: "/queries/maintenance_category_master/upsert",
    },
    'Maintenance Sub-Category': {
        endpoint: "/queries/maintenance_sub_category_master/upsert",
    }
}
