import { Box, Button, Skeleton, Stack } from "@mui/material";
import React from "react";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, SemiBold, useWindowDimensions } from "../../../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card } from "../../leadnew/addmembercard";
import makeStyles from "@mui/styles/makeStyles";
import { SearchFilter, SelectBox } from "../../../components";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";

const LeadStylesParent = makeStyles((theme) => ({
    bottombtn: {
        padding: "11px 16px",
        backgroundColor: "#fff",
        // boxShadow: "0px 0px 16px #00000014",
        display: "flex",
        justifyContent: "space-between",
        // border: "1px solid #E4E8EE",
        position: "sticky",
        bottom: "0px",
        width: "100%",
        zIndex: 2,
    },
    Cancel: {
        backgroundColor: "#FFFFFF ",
        color: "#091B29",
        fontSize:"0.875rem",
        fontWeight: 600,
        fontFamily: SemiBold,
        border: "1px solid #E4E8EE",
        "&:hover": {
          backgroundColor: "#FFFFFF ",
        },
    },
    next: {
        marginLeft: "10px",
        fontFamily: SemiBold,
        color: "#fff",
        backgroundColor: "#5078E1",
        "&:hover": {
          backgroundColor: "#5078E1",
        },
      },
}))

export const TransferOppertunity = ({ t, oppertunity_id = "", onClose = () => false, company_id, user_id, onTransferClose = () => false, type, team , setReload=()=>false , reload}) => {
    const classes = LeadStylesParent()
  const [data, setData] = React.useState({ id:user_id });
  const [loading, setLoading] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const [userDetails, setUserDetails] = React.useState([]);
  const [searchdata, setSearchdata] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const size = useWindowDimensions();
  const [disable, setDisable] = React.useState(false);
  const [teams , setTeams] = React.useState(team)

  //on submit
  const onSubmit = () => {
    setDisable(true)
        NetworkCall(
            `${config.api_url}/queries/opportunity/update`,
            NetWorkCallMethods.post,
            {
                id: oppertunity_id,
                lead_owner: data?.id
            },
            null,
            true,
            false
          )
        .then((response) => {
          setDisable(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Oppertunity Transfer Successfully"),
            });
          onClose()
          reload()

        }).catch((err) => {
            console.log(err)
            setDisable(false)
        })
}
    const getUserProfile = (offset , search, is_load , team) => {
        if (!is_load) {
            setLoading(true);
      }
    NetworkCall(
      `${config.api_url}/lead/transfer_owners`,
      NetWorkCallMethods.post,
      {
        start: offset,
        length: 10,
        search: search ?? "",
        team: type === "against_team" ? team : undefined,
        module_id:localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
      },
      null,
      true,
      false
    )
      .then((res) => {
        const user = res?.data?.data?.map((x) => {
          return {
            ...x,
            is_active: (data?.id || user_id) === x?.id ? true : false,
          };
        });
        if (is_load) {
          setUserDetails(userDetails?.concat(user));
        } else {
          setUserDetails(user);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleSearchTransfer = (v) => {
    setSearchdata(v);
    getUserProfile(0, v , false , teams?.value);
  };
  // infinity scroll transfer lead
  const fetchMoreDataTransfer = () => {
    setOffset(offset + 10);
    getUserProfile(offset + 10, searchdata, true, teams?.value);
  };
    React.useEffect(() => {
      if (type === "against_team") {
        if (team?.value) getUserProfile(0, "", false, team?.value);
      } else {
        getUserProfile(0);
      }
      setTeams(team)
    }, [type]);
  return (
    <div id={"infinity"}>
      {/* search box */}
      {
                  type === "against_team" &&
                  <div style={{padding:"16px"}}>
                      <SelectBox
                        alignItem="start"
                      isRequired
                      label={t("Team")}
                      placeholder={t("Select Team")}
                      value={teams}
                      onChange={(value) => {
                        getUserProfile(0 , "" , false , value?.value)
                        setTeams(value);
                      }}
                      isPaginate
                      debounceTimeout={800}
                      loadOptions={(search, array , handleLoading) =>
                        loadOptionsApis(
                          "hierarchy-team/get_all_team",
                          {
                            // company_id: 1,
                            module:localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                        )
                      }
                      isReadOnly={team?.value}
                    />
                  </div>
                }

      <div style={{ padding: "24px 16px 16px 16px" }}>
        <SearchFilter
          value={searchdata}
          handleChange={(value) => handleSearchTransfer(value)}
          placeholder={t("Search Member Details")}
        />
      </div>
      <div style={{ padding: "0px 16px 16px" }}>
        {loading ? (
          <Stack spacing={0.5} height={size?.height - 400} overflow={"auto"}>
            {Array.from(Array(8).keys())?.map(() => {
              return <Skeleton width={"100%"} height={"150px"} />;
            })}
          </Stack>
        ) : (
          <InfiniteScroll
            dataLength={userDetails?.length ?? ""}
            next={fetchMoreDataTransfer}
            hasMore={true}
            height={size?.height - 400}
          >
            <Card
              data={userDetails}
              selectUser={(c)=>setData(c)}
              select={data}
              t={t}
            />
          </InfiniteScroll>
        )}
      </div>
      {/* button */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button className={classes.Cancel} onClick={onTransferClose}>
            {t("Cancel")}
          </Button>
        </Box>
        <Box>
          <Button
            className={classes.next}
            onClick={() => onSubmit()}
            disabled={(!data?.id || disable)}
          >
            {t("Transfer Lead")}
          </Button>{" "}
        </Box>
      </Box>
    </div>
  );
}