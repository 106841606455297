export const PreviewTabData = ["Drafting", "Preview"]

export const initial_data = {
    id: "",
    title: "",
    level: "",
    property: "",
    agreement_unit: "",
    contact: "",
    correspondences_type: "",
    date: new Date(),
    acknowledge_before: new Date(),
    reference_letter: "",
    subject: "",
    priority: "low",
    notes: "",
    attachment:[],
    signature: [],
    error: {
        property: "",
        agreement_unit: "",
        contact: "",
        correspondences_type: "",
        date: "",
        acknowledge_before: "",
        subject: "",
        priority: "",
        notes: ""
    }
}

export const AgreementHeading = (t) => {
    return [
        { title: t("Agreement No"), field: "agreement_no" },
        { title: t("Unit"), field: "unit" },
        { title: t("Revenue Type"), field: "revenue_type" },
        { title: t("Start Date"), field: "start_date" },
        { title: t("End Date"), field: "end_date" },
    ];
}

export const AgreementPath = [
    "agreement_no",
    "unit",
    "revenue_type",
    "start_date",
    "end_date",
]

export const AgreementDataType = [
    { type: ["text"], name: "agreement_no" },
    { type: ["long_text"], name: "unit" },
    { type: ["text"], name: "revenue_type" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
]

export const ContractHeading = (t) => {
    return [
        { title: t("Contract No"), field: "contract_no" },
        { title: t("Account No"), field: "account_no" },
        { title: t("Start Date"), field: "start_date" },
        { title: t("End Date"), field: "end_date" },
    ];
}

export const ContractPath = [
    "contract_no",
    "account_no",
    "start_date",
    "end_date",
]

export const ContractDataType = [
    { type: ["text"], name: "contract_no" },
    { type: ["text"], name: "account_no" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
]

export const RoleHeading = (t) => {
    return [
        { title: t("Role Name"), field: "role_name" },
    ];
}

export const RolePath = [
    "role_name",
]

export const RoleDataType = [
    { type: ["text"], name: "role_name" },
]

export const ModuleHeading = (t) => {
    return [
        { title: t("Module Name"), field: "module_name" },
    ];
}

export const ModulePath = [
    "module_name",
]

export const ModuleDataType = [
    { type: ["text"], name: "module_name" },
]