import { Skeleton, Stack } from "@mui/material"

export const Simmer = ({
    classes = {},
}) => {
    return <Stack direction={"row"} columnGap={"16px"}
        p={"8px"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack direction={"row"} columnGap={"8px"} alignItems={"center"} width={"93%"}>
            <Skeleton animation="wave" variant='rounded' height={32} width={32} />
            <Stack width={"91%"}>
                <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}
                    >
                    <Skeleton animation="wave" variant='text' width={"80%"} />
                </Stack>
                <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}
                    >
                    <Skeleton animation="wave" variant='text' width={"30%"} />
                    <Skeleton animation="wave" variant='rounded' height={10} width={10} borderRadius={'50%'} />
                    <Skeleton animation="wave" variant='text' width={"30%"} />
                </Stack>
            </Stack>
        </Stack>
    </Stack>
}