import { makeStyles } from "@mui/styles";
import { SemiBold , Bold, Regular, remCalc, ExtraBold } from "../../utils";

export const useStyle = makeStyles((theme) => ({
    drawerBox: {
        // width: '456px',
        position: "relative"
    },
    header: {
        width: "100%"
    },
    body: {
        height: "calc(100vh - 191px)",
        overflow: "auto"
    },
    body1:{
        height: "calc(100vh - 211px)",
        overflow: "auto"
    },
    body2:{
        height: "calc(100vh - 230px)",
        overflow: "auto"
    },
    body3:{
        height: "calc(100vh - 200px)",
        overflow: "auto"
    },
    drawerCloseBtn: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: Bold,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: "transparent",
        },
        width:"100%"
    },
    next: {
        background: theme.palette.border.primary_2,
        color: "#fff",
        fontSize:"0.875rem",
        fontFamily: Bold,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: theme.palette.border.primary_2,
        },
        width:"100%"
  
    },
    card:{
        borderRadius:'8px',
        border:'1px solid #E4E8EE',
        boxShadow:'0px 1px 12px #0000001A',
        backgroundColor:'#FFFFFF',
        padding:16
    },
    cardTitle:{
        color:"#091B29",
        fontSize:"1rem",
        fontFamily:SemiBold
    },
    cardSubTitle:{
        color:"#4E5A6B",
        fontSize:"0.75rem",
        fontFamily:SemiBold
    },
    dot:{
        backgroundColor:"#4E5A6B",
        padding:"3px",
        borderRadius:'50%'
    },
    cardParent:{
        backgroundColor:'#F2F4F7',
        padding:'16px'
    },
    name:{
        color:"#4E5A6B",
        fontSize:"0.875rem",
        fontFamily:Regular
    },
    details:{
        color:"#091B29",
        fontSize:"0.875rem",
        fontFamily:SemiBold
    },
    orderTitle:{
        color:"#4E5A6B",
        fontSize:"0.75rem",
        fontFamily:SemiBold  ,
        margin:"16px 0px"
    },
    acceptText:{
        color:"#4E5A6B",
        fontSize:"0.672rem",
        fontFamily:SemiBold
    },
    footerRoot:{
        borderTop:"1px solid #F2F4F7",
        padding:"16px"
    },
    details1:{
        padding:"4px",
        borderRadius:4,
        backgroundColor:"#E4E8EE",
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:"0.875rem"
    },
    createNew:{
        color:"#5078E1",
        fontSize:"0.75rem",
        fontFamily:SemiBold,
        cursor:"pointer"   
    },
    bottom:{
        backgroundColor:"#FF9340",
        fontSize:remCalc(12),
        color:"#fff",
        fontFamily:SemiBold,
        textAlign:"center",
        width:"100%",
        padding:"5px"
    },
    waringText:{
        fontSize:remCalc(12),
        color:"#091B29",
        fontFamily:SemiBold,
        display:"flex",
        gap:"5px",
        marginTop:"10px"
    },
    waringBoldText:{
        fontSize:remCalc(12),
        color:"#091B29",
        fontFamily:`${ExtraBold} !important`,
    },
    waringDes:{
        fontSize:remCalc(12),
        color:"#4E5A6B",
        fontFamily:SemiBold,
    },
    bottomRoot:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    }
}))