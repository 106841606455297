import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { basicSettingState, returnSaveData } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { useStyles } from "../styles";



export const ResidentSetting = ({ t = () => false,selected = {}, settings = {}, onSubmitSettings = () => false, isDisableBtn = false }) => {
    const classes = useStyles();
    const [data, setData] = React.useState({...basicSettingState})
    //update data
    const updateState = (selected_val, value) => {
        const result = data?.client_controls?.map((x) => {
            return {
                ...x,
                details: x?.details?.map((e) => {
                    return {
                        ...e,
                        is_active: selected_val?.id === e.id ? value?.is_active : e?.is_active
                    }
                })
            }
        })
        setData({...data, client_controls: result,setting_id:settings?.setting_id })
    }
    //on submit form
    const onSubmit = () => {
        const payload = returnSaveData(data)
        onSubmitSettings(payload)

    }
    // //initial load
    React.useEffect(() => {
        setData({...data,client_controls:settings?.client_controls,setting_id:settings?.setting_id})
        // eslint-disable-next-line
    }, [settings])
    return (
        <Box>
            {/*setting save */}
            <SettingSave title={selected?.label} subTitle={selected?.subtitle} btn={true} onSave={onSubmit} isDisableBtn={isDisableBtn} t={t} />
            <Box className={classes.settingRoot}>
                {/*"Enable Reservation Feature"*/}

                {data?.client_controls?.map((x) => {
                    return (
                        <Box p={2}>
                            <Typography className={classes.dynamicTitle}>{x?.header}</Typography>
                            <Grid container spacing={2}>

                                {x?.details?.map((val) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                            <Box mt={1.5}>
                                                <CompanySettingsList
                                                    title={val?.title}
                                                    onChange={(value) => updateState(val, value)}
                                                    value={{ is_active: val?.is_active }}
                                                    toggle={true}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                })}

                            </Grid>

                        </Box>
                    )
                })}



                {/*Enable Auto Deposite Amount Calculation Feature*/}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Directory")}
                        onChange={(value) => updateState("neigbours", value)}
                        value={data?.neigbours}
                        toggle={true}
                    />

                </Box> */}
                {/*Enable Auto Reservation Amount Calculation Feature*/}

                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("My Contacts")}
                        onChange={(value) => updateState("contact", value)}
                        value={data?.contact}
                        toggle={true}
                    />
                </Box> */}

                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Survey"
                        onChange={(value) => updateState("survey", value)}
                        value={data?.survey}
                        toggle={true}
                    />

                </Box> */}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Walk-in Tracking"
                        onChange={(value) => updateState("walkin", value)}
                        value={data?.walkin}
                        toggle={true}
                    />
                </Box> */}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Parking Alarm Notification"

                        onChange={(value) => updateState("parking", value)}
                        value={data?.parking}
                        toggle={true}
                    />
                </Box> */}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Amenity Booking")}
                        onChange={(value) => updateState("booking", value)}
                        value={data?.booking}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Delivery Collection")}
                        onChange={(value) => updateState("deleivery", value)}
                        value={data?.deleivery}
                        toggle={true}
                    />
                </Box> */}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Polls"

                        onChange={(value) => updateState("polls", value)}
                        value={data?.polls}
                        toggle={true}
                    />
                </Box> */}
            </Box>
        </Box>
    )
}