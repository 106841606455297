/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew } from '../../utils';
import { ParkingAreaHeading, ParkingAreaPath, ParkingAreaType, StatusOptionList } from '../../utils/parkingArea/parkingAreaListUtils';
import { parkingAreaStyles } from "./style";

const ParkingArea = ({
    t
}) => {
    const defaultFilterState = { status: [true] };

    const defaultAddParkingAreaState = {
        formType: "add",
        id: "",
        property: "",
        propertyName: "",
        vehicleParkingArea: "",
        parkingAreaType: "",
        description: "",
        status: true,
        delete: false,
        error: {
            property: "",
            vehicleParkingArea: ""
        }
    }
    const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined

    const classes = parkingAreaStyles()
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [parkingAreaList, setParkingAreaList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [addParkingAreaDialogOpen, setAddParkingAreaDialogOpen] = React.useState(false)
    const [addParkingAreaState, setAddParkingAreaState] = React.useState({ ...defaultAddParkingAreaState })
    const [loading, setLoading] = React.useState("")
    const [enumValue, setEnumValue] = React.useState({ parkingAreaType: [] })
    const [permission, setPermission] = React.useState({})
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // useEffect to get Parking Area list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getParkingArea() }
    }, [selectedCompany, filterData])

    // Function to get Parking Area list based on the input data
    const getParkingArea = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        const payload = {
            "status": (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status ,
            "company_id": selectedCompany?.value,
            "search": search,
            "offset": offset,
            "limit": limit
        }
        NetworkCall(
            `${config.api_url}/queries/parking_area/dropdown`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setParkingAreaList({
                data: r?.data?.data?.parking_area,
                totalRowsCount: r?.data?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    const ParkingAreaRows = React.useCallback(parkingAreaList?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                property: _?.propertyByID ?? "",
                propertyName: _?.propertyByID?.label ?? "",
                vehicleParkingArea: _?.vehicle_parking_area ?? "",
                description: _?.description !== "" ? _?.description ?? "-" : "-",

                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [parkingAreaList])

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.parking_area_type])
        setEnumValue({ parkingAreaType: result?.parking_area_type })
    }

    // useEffect to load the Enum value while initial load
    React.useEffect(() => {
        getEnum()
    }, [])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in Parking Area list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getParkingArea(0, limit, e)
    }

    // Function to open add Parking Area form
    const handleAddParkingArea = () => {
        setAddParkingAreaState({ ...defaultAddParkingAreaState })
        setAddParkingAreaDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddParkingAreaState = {
            formType: type,
            id: tempData?.id ?? "",
            property: tempData?.propertyByID ?? "",
            vehicleParkingArea: tempData?.vehicle_parking_area ?? "",
            parkingAreaType: tempData?.parking_area_type ? { value: tempData?.parking_area_type, label: tempData?.parking_area_type } : "",
            description: tempData?.description ?? "",
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddParkingAreaState?.error
        }
        setAddParkingAreaState({ ...tempAddParkingAreaState })
        if (type === "edit" || type === "view") { setAddParkingAreaDialogOpen(true) }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddParkingAreaState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getParkingArea(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getParkingArea(0, value, searchText)
    }

    // Function to update addParkingAreaState
    const updateAddParkingAreaDialogState = (k, v) => {
        let error = addParkingAreaState?.error
        error[k] = ""
        setAddParkingAreaState({ ...addParkingAreaState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addParkingAreaState.error
        if (addParkingAreaState?.property?.length === 0) {
            isValid = false; error.property = t("Property is Required")
        }
        if (addParkingAreaState?.vehicleParkingArea?.length === 0) {
            isValid = false; error.vehicleParkingArea = t("Vehicle Parking Area is Required")
        }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddParkingAreaState({ ...addParkingAreaState, error })
        return isValid
    }

    // Function to create a Parking Area
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            const currentDate = new Date().toISOString()
            setIsDisableBtn(true)

            const payload = {
                id:data?.id??undefined,
                company_id: selectedCompany?.value ?? undefined,
                property_id: data?.property?.value ?? undefined,
                description: data?.description ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                vehicle_parking_area: data?.vehicleParkingArea ?? undefined,
                parking_area_type: data?.parkingAreaType?.value ?? undefined,
                updated_at: currentDate ?? undefined,
                client :clientID
            }

            if (data?.formType === "add") {
               
                payload.created_at = currentDate ?? undefined
            }
           await NetworkCall(
                `${config.api_url}/queries/parking_area/upsert`,
                NetWorkCallMethods.post,
                {
                    payload: [payload]
                },
                null,
                true,
                false
            ).then((r) => {
                setAddParkingAreaState({ ...defaultAddParkingAreaState })
                getParkingArea()
                setAddParkingAreaDialogOpen(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `Parking Area ${data?.id ? (data?.formType === "delete" ? `Deleted` : `Updated`) : `Created`} Successfully.!!!`,
                })
                setIsDisableBtn(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
                setIsDisableBtn(false)
            })

        } else { return false }
    }

    // Function to load Async Select Options
    const loadOptions = async (search = "", array, type) => {
        setLoading(type)
        let result, offset, limit, companyID
        limit = 10
        companyID = selectedCompany?.value
        if (search && !Boolean(array?.length)) { offset = 0 }
        else { offset = array?.length }

        switch (type) {
            case "property":
                result = await NetworkCall(
                    `${config.api_url}/queries/property/dropdown`,
                    NetWorkCallMethods.post,
                    {
                        "company_id": companyID,
                        "offset": offset,
                        "limit": limit,
                        "search": search
                    },
                    null,
                    true,
                    false
                )
                return {
                    options: [...result?.property],
                    hasMore: (array?.length + result?.property?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={t("Parking Area")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder={t("Search Parking Area")}
                            handleChange={(value) => handleSearch(value)}
                            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}>
                                <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData.status?.length > 0)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                                {permission?.create &&
                                    <Button variant="contained" className={classes.button}
                                        onClick={handleAddParkingArea}>
                                        {t("Add A Parking Area")}
                                    </Button>}
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <TableWithPagination
                            heading={ParkingAreaHeading(t)}
                            rows={ParkingAreaRows}
                            path={ParkingAreaPath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={ParkingAreaType}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={parkingAreaList?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => setFilterData(value)}
                    defaultState={defaultFilterState}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [{ label: t("Active"), value: true }, { label: t("Inactive"), value: false }],
                            isMulti: true,
                            state_name: "status",
                            label: t("Status")
                        },
                    ]} />
                <Dialog
                    className={classes.addParkingAreaDialog}
                    open={addParkingAreaDialogOpen}
                    onClose={() => setAddParkingAreaDialogOpen(false)}>
                    <div className={classes.addParkingAreaDialogHeader}>
                        <Typography className={classes.addParkingAreaDialogHeaderTitle}>
                            {addParkingAreaState?.formType === "add" ? t("Add Parking Area") :
                                addParkingAreaState?.formType === "edit" ? t("Edit Parking Area") :
                                    addParkingAreaState?.formType === "view" ? t("View Parking Area") : t("Add Parking Area")}
                        </Typography>
                        <IconButton onClick={() => setAddParkingAreaDialogOpen(false)}
                            className={classes.addParkingAreaDialogCloseButton}>
                            <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                        </IconButton>
                    </div>
                    <div className={classes.addParkingAreaDialogBody}>
                        <SelectBox
                            key={selectedCompany?.value}
                            isRequired
                            isReadOnly={
                                (addParkingAreaState?.formType === "add" ||
                                    addParkingAreaState?.formType === "edit") ?
                                    false : true}
                            label={t("Property Name")}
                            placeholder={t("Select Property")}
                            value={addParkingAreaState?.property}
                            onChange={(value) => updateAddParkingAreaDialogState("property", value)}
                            loading={loading === "property"}
                            isPaginate
                            debounceTimeout={800}
                            loadOptions={(search, array) => loadOptions(search, array, 'property')}
                            isError={addParkingAreaState?.error?.property?.length > 0}
                            errorMessage={addParkingAreaState?.error?.property} />
                        <Box height={16} />
                        <TextBox
                            isrequired
                            isReadonly={addParkingAreaState?.formType === "view"}
                            label={t("Parking Area Name")}
                            placeholder={t("Enter Name")}
                            value={addParkingAreaState?.vehicleParkingArea ?? ""}
                            onChange={(e) => updateAddParkingAreaDialogState("vehicleParkingArea", e.target.value)}
                            isError={addParkingAreaState?.error?.vehicleParkingArea?.length > 0}
                            errorMessage={addParkingAreaState?.error?.vehicleParkingArea} />
                        <Box height={16} />
                        <SelectBox
                            isReadOnly={
                                (addParkingAreaState?.formType === "add" ||
                                    addParkingAreaState?.formType === "edit") ?
                                    false : true}
                            label={t("Parking Area Type")}
                            placeholder={t("Select Type")}
                            options={enumValue?.parkingAreaType}
                            value={addParkingAreaState?.parkingAreaType}
                            onChange={(value) => updateAddParkingAreaDialogState("parkingAreaType", value)} />
                        <Box height={16} />
                        <TextBox
                            isReadonly={addParkingAreaState?.formType === "view"}
                            label={t("Description")}
                            placeholder={t("Enter Description")}
                            multiline
                            value={addParkingAreaState?.description ?? ""}
                            onChange={(e) => updateAddParkingAreaDialogState("description", e.target.value)} />
                        <Box height={16} pb={7} />
                        <Typography className={classes.addParkingAreaDialogFieldLabel} noWrap>{t("Status")}</Typography>
                        <div className={classes.addParkingAreaDialogButtonContainer}>
                            {StatusOptionList.map((_) => {
                                return <Button className={_?.value === addParkingAreaState?.status ?
                                    classes.addParkingAreaDialogButtonSelected :
                                    classes.addParkingAreaDialogButtonUnSelected}
                                    onClick={() => (addParkingAreaState?.formType === "add" ||
                                        addParkingAreaState?.formType === "edit") ?
                                        updateAddParkingAreaDialogState("status", _?.value) : false}>
                                    {t(_?.label)}
                                </Button>
                            })}
                        </div>
                    </div>
                    {(addParkingAreaState?.formType === "add" || addParkingAreaState?.formType === "edit") &&
                        <div className={classes.addParkingAreaDialogFooter}>
                            {addParkingAreaState?.formType === "edit" && <><Button fullWidth
                                className={classes.addParkingAreaDialogFooterCloseButton}
                                onClick={() => setAddParkingAreaDialogOpen(false)}>
                                {t("Cancel")}
                            </Button></>}
                            <Button variant="contained" fullWidth
                                className={classes.addParkingAreaDialogFooterButton}
                                onClick={() => handleCreateEdit(addParkingAreaState)}
                                disabled={isDisableBtn}>
                                {addParkingAreaState?.formType === "add" ? t("Create") : t("Save")}
                            </Button>
                        </div>}
                </Dialog>
            </div>
        </>
    }

    return <div >
        {accessCheckRender(render, permission)}
    </div >
}
export default withNamespaces("parkingArea")(ParkingArea);
