import { Box } from "@mui/material"
import React from "react"
import { TableWithPagination } from "../../components"
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { NetWorkCallMethods } from "../../utils"
import moment from "moment"
import { Routes } from "../../router/routes"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const CommonTable = ({ t = () => false, heading = [], path = {}, dataType = [], viewMore = false, infoType = "", details = [], tabValue = "", tableData = [], setTableData = () => false, loading = false, setLoading = () => false, planner_type = "Fixed",currency_symbol="" }) => {
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const history = useHistory()
    React.useEffect(() => {
        if (viewMore) {
            getDetails(0, 10, infoType)
        }
        //eslint-disable-next-line
    }, [])
    const getDetails = (offset = 0, limit = 10, details) => {
        setLoading(true)
        const payload = {
            "type": details?.type,
            "id": details?.id,
            start: offset,
            length: limit,
            "is_runned": details?.type === "upcomming" ? [false] : details?.type === "executed" ? [true] : undefined
        }

        NetworkCall(
            `${config.api_url}/preventive_plan/get_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            const result = response?.data?.data?.map((x) => {
                return {
                    ...x,
                    name: x.first_name ?? x.name ?? x?.item_name,
                    job: x.job_name,
                    department: x.department_name,
                    image: x.image,
                    job_name: x?.role,
                    reference_no: x?.item_reference_no,
                    item_category: x?.category_name,
                    item_subcategory: x?.subcategory_name,
                    eta: x?.eta > 0 ? `${x?.eta} ${x?.eta > 1 ? "hrs" : "hr"}` : 0,
                    execution: moment(x?.execution).format("DD MMM YYYY"),
                    is_active: true
                }
            })
            setTableData({ row: result, count: response?.data?.count })
        }).catch((err) => {
            setLoading(false)
        })
    }
    const handleTablePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getDetails(offset, 10, infoType)
    }
    const handleTablePageLimit = (value) => {
        setLimit(value);
        setPage(1);
        getDetails(0, limit, infoType)
    }
    const handleIcon = (type, data) => {
        if (infoType?.type === "executed") {
            history.push({
                pathname: Routes?.viewServiceInspection,
                state: {
                    request_id: data?.request_id,
                    reference_id: data?.job_referenece_no,
                    unit_id: data?.unit_id,
                    id: data?.id,
                    agreement_inspection_id: data?.id,
                    status: data?.status,
                    request_type: "service",
                    currency_code: currency_symbol
                }
            })
        }
    }
    return (
        <Box p={2}>
            {loading ? <NewLoader /> :
                <TableWithPagination
                    heading={heading}
                    rows={tableData?.row}
                    path={path}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    onClick={() => console.log("")}
                    tableType="no-side"
                    dataType={dataType}
                    handlePagination={handleTablePagination}
                    handleChangeLimit={handleTablePageLimit}
                    handleIcon={handleIcon}
                    totalRowsCount={tableData?.count}
                    page={page}
                    limit={limit}
                    height={viewMore ? 'calc(100vh - 180px)' : (planner_type === "Fixed" ? 'calc(100vh - 275px)' : 'calc(100vh - 260px)')}
                    view={true}
                    edit={false}
                    delete={true} />
            }
        </Box>
    )
}