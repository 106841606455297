import React from 'react'
import styled from '@mui/material/styles/styled';
import { UnitAccountCard } from '../components/unitAccountCard';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PortalUserIcon from '../utils/portalUserIcon';
import { AccessRightsStyles } from './styles';
import OnBoardingIcon from '../utils/onBoardingIcon';
import LoginAccessIcon from '../utils/loginAccessIcon';
import { AddAccount } from '../components/addAccount';
import { AlertDialog, FormGenerator, SelectBox } from '../../../components';
import { AccountList } from '../components/accountList';
import { NetworkCall } from '../../../networkcall';
import { LocalStorageKeys, NetWorkCallMethods } from '../../../utils';
import { config } from '../../../config';
import { levelOption } from '../utils/accessRight';
import { NewLoader } from '../../../components/newLoader';

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: 16,
    margin: "0px",
    height: "calc(100vh - 200px)",
    overflow: "auto"
}))

const AccessRights = ({
    id = "",
    t = () => false,
    details
}) => {
    const classes = AccessRightsStyles()
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    const [openAccount, setOpenAccount] = React.useState(false)
    const [accessRightData, setAccessRightData] = React.useState({
        type: "",
        selectedUnitIndex: 0,
        loading: true
    })

    const selectedAccountData = {
        "portal": accessRightData?.portal_user_contact,
        "onboarding": accessRightData?.onboard_incharge_contact
    }

    React.useEffect(() => {
        getAccessRights()
        // eslint-disable-next-line
    }, [])

    const getAccessRights = () => {
        const payload = {
            agreement_id: id,
        }

        NetworkCall(
            `${config.api_url}/access-control/get_access_rights_data`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setAccessRightData({ ...accessRightData, ...res?.data, loading: false })
        }).catch((err) => {
        })
    }


    const upsertAccessRightData = (type = "", data) => {
        setAccessRightData({
            ...accessRightData,
            loading: true
        })
        let payload = {}

        switch (type) {
            case "portal":
                payload = {
                    portal_user: data?.id,
                    agreement_id: id,
                    access_rights: {
                        agreement_units: accessRightData?.agreement_units
                    },
                }
                break;
            case "onboarding":
                payload = {
                    onboard_incharge: data?.id,
                    agreement_id: id,
                    access_rights: {
                        agreement_units: accessRightData?.agreement_units
                    },
                }
                break;
            default:
                payload = {
                    access_rights: {
                        agreement_units: data
                    },
                    agreement_id: id
                }
                break;
        }
        NetworkCall(
            `${config.api_url}/access-control/upsert_access_rights_data`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            getAccessRights()
        }).catch((err) => {
        })
    }

    const handleOpenAccount = (type, index) => {
        setOpenAccount(true)
        setAccessRightData({ ...accessRightData, type: type, selectedUnitIndex: index })
    }

    const handleCloseAccount = () => {
        setOpenAccount(false)
        setAccessRightData({ ...accessRightData, type: "", selectedUnitIndex: 0 })
    }

    const handleAdd = (data) => {
        setOpenAccount(false)
        if (accessRightData?.type === "unit") {
            handleChangeAccessUser("unit", accessRightData?.selectedUnitIndex, data)
        } else {
            upsertAccessRightData(accessRightData?.type, data)
        }
    }

    const handleChangeAccessUser = (type = "", i, value) => {
        if (type === "access") {
            accessRightData.agreement_units[i].agreement_unit_users[0].access = value?.value
        } else if (type === "unit") {
            accessRightData.agreement_units[i].agreement_unit_users[0].contact = value
        }
        else if (type === "unit_handover_date") {
            accessRightData.agreement_units[i].unit_handover_date = value
        }
        upsertAccessRightData("", accessRightData?.agreement_units)
    }

    return (
        <>
            <CustomPaper>
                {accessRightData?.loading ?
                    <NewLoader minusHeight='300px'></NewLoader> :
                    <Box>
                        <Grid container >
                            <Grid item xs={12}>
                                <Stack>
                                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                        <PortalUserIcon />
                                        <Typography className={classes.headTitle}>{t("Administrator")}</Typography>
                                    </Stack>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={8} p={2}>
                                        <Grid container columnSpacing={2} className={classes.gridContainer}>
                                            <Grid item xs={3} className={classes.leftItem}>
                                                <UnitAccountCard type={"portal"} title={t("Select User")} />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridItem}>
                                                <AddAccount
                                                    t={t}
                                                    hide={accessRightData?.portal_user_contact}
                                                    type='portal'
                                                    openClick={handleOpenAccount}
                                                    title={accessRightData?.portal_user_contact?.first_name}
                                                    subTitle={`${accessRightData?.portal_user_contact?.mobile_no_country_code ?? ""} ${accessRightData?.portal_user_contact?.mobile_no ?? "-"}, ${accessRightData?.portal_user_contact?.email_id ?? "-"}`}
                                                    image_url={accessRightData?.portal_user_contact?.image_url}
                                                    edit={accessRightData?.agreement_status !== "Live"}
                                                    data={accessRightData?.onboard_incharge_contact}
                                                />
                                            </Grid>
                                            <Grid item xs={3} className={classes.rightItem}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* second row */}
                            <Grid item xs={12}>
                                <Stack>
                                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                        <OnBoardingIcon />
                                        <Typography className={classes.headTitle}>{t("Onboarding in charge")}</Typography>
                                    </Stack>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={8} p={2}>
                                        <Grid container columnSpacing={2} className={classes.gridContainer}>
                                            <Grid item xs={3} className={classes.leftItem}>
                                                <UnitAccountCard type={"onboarding"} title={t("Select User")} />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridItem}>
                                                <AddAccount
                                                    t={t}
                                                    hide={accessRightData?.onboard_incharge_contact}
                                                    type='onboarding'
                                                    openClick={handleOpenAccount}
                                                    title={accessRightData?.onboard_incharge_contact?.first_name}
                                                    subTitle={`${accessRightData?.onboard_incharge_contact?.mobile_no_country_code ?? ""} ${accessRightData?.onboard_incharge_contact?.mobile_no ?? "-"}, ${accessRightData?.onboard_incharge_contact?.email_id ?? "-"}`}
                                                    image_url={accessRightData?.onboard_incharge_contact?.image_url}
                                                    edit={accessRightData?.agreement_status !== "Live"}
                                                    data={accessRightData?.onboard_incharge_contact} />
                                            </Grid>
                                            <Grid item xs={3} className={classes.rightItem}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Third row */}
                            <Grid item xs={12}>
                                <Stack>
                                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                        <LoginAccessIcon />
                                        <Typography className={classes.headTitle}>{t("Login Access users")}</Typography>
                                    </Stack>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={details?.agreement_type === "Sale Agreement" ? 12 : 8} pt={"12px"}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography className={classes.unitSubTitle}>{t("UNIT DETAILS")}</Typography>
                                            </Grid>
                                            <Grid item xs={details?.agreement_type === "Sale Agreement" ? 4 : 6}>
                                                <Typography className={classes.unitSubTitle}>{t("ACCESS PERSON")}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography className={classes.unitSubTitle}>{t("ACCESS TYPE")}</Typography>
                                            </Grid>
                                            {
                                                details?.agreement_type === "Sale Agreement" && 
                                                <Grid item xs={2}>
                                                <Typography className={classes.unitSubTitle}>{t("HANDOVER DATE")}</Typography>
                                            </Grid>
                                            }
                                          
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={details?.agreement_type === "Sale Agreement" ? 12 : 8} p={"8px 16px 16px 0px"}>
                                        <Grid container className={classes.gridContainer}>
                                            {
                                                accessRightData?.agreement_units?.map((data, i) => {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={3} className={(i === 0 || accessRightData?.thridList?.length - 1 === i) ? classes.leftItem : classes.gridItem}>
                                                                    <UnitAccountCard
                                                                        title={data?.unit?.name ? `${data?.unit?.unit_name}(${data?.unit?.unit_no})` : data?.unit?.unit_no}
                                                                        subTitle={`${data?.unit?.address?.city}, ${data?.unit?.address?.state}`}
                                                                        image_url={data?.unit?.logo}
                                                                        left />
                                                                </Grid>
                                                                <Grid item xs={details?.agreement_type === "Sale Agreement" ? 4 : 6} className={classes.gridItem}>
                                                                    <AddAccount
                                                                        t={t}
                                                                        hide={data?.agreement_unit_users}
                                                                        type='unit'
                                                                        openClick={handleOpenAccount}
                                                                        title={data?.agreement_unit_users?.[0]?.contact?.first_name ?? data?.agreement_unit_users?.[0]?.contact?.name}
                                                                        subTitle={`${data?.agreement_unit_users?.[0]?.contact?.mobile_no_country_code ?? ""} ${data?.agreement_unit_users?.[0]?.contact?.mobile_no ?? "-"}, ${data?.agreement_unit_users?.[0]?.contact?.email_id ?? "-"}`}
                                                                        image_url={data?.agreement_unit_users?.[0]?.contact?.image_url ?? data?.agreement_unit_users?.[0]?.contact?.logo}
                                                                        edit={accessRightData?.agreement_status !== "Live"}
                                                                        data={data?.agreement_unit_users?.[0]?.contact}
                                                                        index={i} />
                                                                </Grid>
                                                                <Grid item xs={3} className={(i === 0 || accessRightData?.thridList?.length - 1 === i) ? classes.rightItem : classes.gridItem}>
                                                                    <SelectBox
                                                                        options={levelOption(t)}
                                                                        borderRadius={true}
                                                                        noBorder={false}
                                                                        placeholder={t("Select Level")}
                                                                        label={false}
                                                                        value={{
                                                                            label: t(data?.agreement_unit_users?.[0]?.access),
                                                                            value: data?.agreement_unit_users?.[0]?.access
                                                                        }}
                                                                        selectHeight={"40px"}
                                                                        onChange={(value) => {
                                                                            handleChangeAccessUser("access", i, value)
                                                                        }}
                                                                        isReadOnly={accessRightData?.agreement_status === "Live"}
                                                                    />
                                                                </Grid>
                                                                {
                                                                    details?.agreement_type === "Sale Agreement" &&
                                                                    <Grid item xs={2} className={(i === 0 || accessRightData?.thridList?.length - 1 === i) ? classes.rightItem : classes.gridItem}>
                                                                        <FormGenerator components={[
                                                                            {
                                                                                isActive: true,
                                                                                component: "date",
                                                                                value: !!data?.unit_handover_date ? new Date(data?.unit_handover_date) : null,
                                                                                placeholder: t("Handover Date"),
                                                                                onChange: (e) => handleChangeAccessUser("unit_handover_date", i, e),
                                                                                size: {
                                                                                    xs: 12,
                                                                                    sm: 12,
                                                                                    md: 12,
                                                                                    lg: 12,
                                                                                },
                                                                            }]}
                                                                        />
                                                                    </Grid>
                                                                }
                                                               
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    }
            </CustomPaper >

            <AlertDialog
                open={openAccount}
                header={t("Add Contact")}
                isNormal={true}
                onClose={() => handleCloseAccount()}
                component={
                    <Box p={2}>
                        <AccountList
                            selectedCompany={selectedCompany}
                            handleAdd={handleAdd}
                            selected={selectedAccountData[accessRightData?.type]} />
                    </Box>
                }
            />
        </>
    )
}

export default AccessRights