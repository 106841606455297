import * as React from "react"
const ContractLayoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    data-name="Group 121844"
    {...props}
  >
    <path
      fill="#a4b2c1"
      d="M3.4 0A2.409 2.409 0 0 0 1 2.4V3a.6.6 0 0 0 .6.6h1v10.6A1.809 1.809 0 0 0 4.4 16h3.8v-1.2H4.4a.591.591 0 0 1-.6-.6V3.6h7.4a.6.6 0 0 0 .6-.6v-.6a1.2 1.2 0 1 1 2.4 0v11.8a.591.591 0 0 1-.6.6H13V16h.6a1.809 1.809 0 0 0 1.8-1.8V2.4A2.409 2.409 0 0 0 13 0Zm0 1.2h7.571a2.324 2.324 0 0 0-.371 1.2H2.2a1.191 1.191 0 0 1 1.2-1.2Zm2.2 4a.6.6 0 1 0 0 1.2h6.8a.6.6 0 1 0 0-1.2Zm0 2a.6.6 0 1 0 0 1.2h2.8a.6.6 0 1 0 0-1.2Zm5 1.6A2.8 2.8 0 0 0 9 13.892V15.4a.6.6 0 0 0 .9.521l.7-.4.7.4a.6.6 0 0 0 .9-.521v-1.508A2.8 2.8 0 0 0 10.6 8.8Zm0 1.2A1.6 1.6 0 1 1 9 11.6a1.591 1.591 0 0 1 1.6-1.6Z"
      data-name="icons8-diploma (2)"
    />
  </svg>
)
export default ContractLayoutIcon
