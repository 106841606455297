import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, ClickAwayListener, Grow, MenuList, Popper, Stack, Typography } from '@mui/material';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { NetWorkCallMethods } from '../../utils';
import { UseDebounce } from '../customHooks';
import { TextBox } from '../textbox';
import { CustomSelectBoxStyle } from './style';

export const CustomSelectBox = ({
    value = {},
    onChange = () => { },
    customOptionComponent,
    menuOptionWidth,
    menuOptionPadding,
    networkCallData = {},
    menuOptionsHeight = "100px",
    isReadOnly = false,
    placeholder = "",
    options = [],
    key = {},
    height = "100px",
    disableColor=false,
    customStyles = ""
}) => {
    const classes = CustomSelectBoxStyle({ ...customStyles })
    const anchorRef = React.useRef(null);
    const debounce = UseDebounce()
    const [open, setOpen] = React.useState(false)
    const [offset, setOffset] = React.useState(0)
    const [searchText, setSearchText] = React.useState("")
    const [list, setList] = React.useState([])
    const [initial, setInitial] = React.useState(true)
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [upDownData, setUpDownData] = React.useState({
        upDownValue: "",
        isEntered: false
    })
    const [newkey, setNewKey] = React.useState("")
    React.useEffect(() => {
        setSearchText(value?.label ?? "")
        executeScroll()
        // eslint-disable-next-line
    }, [selectedIndex, value])


    const handleClick = () => {
        setOpen(!open);
        if (options?.length > 0) {
            setList(options)
        } else {
            if (key !== newkey) {
                getLoadOptions(0, 10, true, "")
                setNewKey(key)
            } else {
                if (list?.length === 0) {
                    setNewKey(key)
                    getLoadOptions(0, 10, true, "")
                }
            }
        }

    };

    const handleSelect = (value) => {
        onChange(value)
        setOpen(!open)
        setSearchText(value?.label)
        setOffset(offset)
        setUpDownData({ ...upDownData, upDownValue: "", isEntered: true })
        setSelectedIndex(0)
    }

    const fetchMoreData = () => {
        if (options?.length === 0) {
            setOffset(offset + 10, false)
            if (initial) {
                getLoadOptions(offset + 10, 10, false, "")
            } else {
                getLoadOptions(offset + 10, 10, false)
            }
        }
    }

    const getLoadOptions = (offset, limit, bool, search = searchText) => {
        const payload = {
            ...networkCallData?.payload,
            search: search,
            offset: offset,
            limit: limit
        }
        NetworkCall(
            `${config.api_url}/${networkCallData?.path}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const resData = res?.data?.[networkCallData?.mappingVariable]
                if (res?.data?.[networkCallData?.mappingVariable]?.length === 0) {
                    setOffset(offset - 10)
                }
                const constructedData = networkCallData?.manualResponse ? networkCallData?.manualResponse(resData) :
                    resData?.map((i) => {
                        return {
                            label: i?.[networkCallData?.label],
                            value: i?.[networkCallData?.value],
                            ...i
                        }
                    })
                if (bool) {
                    setList(constructedData)
                } else {
                    setList([...list, ...constructedData])
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const handleSearch = (searchText) => {
        setInitial(false)
        setSearchText(searchText)
        setOpen(true)
        debounce(() => {
            getLoadOptions(0, 10, true, searchText)
            setOffset(0)
        }, 800)
    }

    const handleOnClose = () => {
        if (searchText === "") {
            setSearchText(value?.label ?? "")
        }
        setOpen(false)
        if (upDownData?.isEntered) {
            setSelectedIndex(selectedIndex)
        } else {
            setSelectedIndex(0)
            setUpDownData({
                ...upDownData,
                upDownValue: "",
                isEntered: false
            })
        }
    }


    const executeScroll = () => {
        const section = document.getElementById(`menuList${selectedIndex}`);
        if (section) {
            section?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: "center" });
        }
    }

    const handleScrollDown = (e) => {
        if (e?.key === "ArrowDown" && list?.length > 0) {
            if (selectedIndex === 0) {
                setUpDownData({ ...upDownData, upDownValue: list?.[0] })
                setSelectedIndex(1)
            } else {
                if (list?.length > selectedIndex + 1) {
                    setUpDownData({ ...upDownData, upDownValue: list?.[selectedIndex + 1] })
                    setSelectedIndex(selectedIndex + 1)
                }
            }
        }
    }

    const handleScrollUp = (e) => {
        if (e?.key === "ArrowUp" && list?.length > 0) {
            if (selectedIndex - 1 >= 0) {
                setUpDownData({ ...upDownData, upDownValue: list?.[selectedIndex - 1] })
                setSelectedIndex(selectedIndex - 1)
            }
        }
    }

    const handleEnterValue = () => {
        onChange(list?.[selectedIndex])
        setOpen(!open)
        setSearchText(list?.[selectedIndex]?.label)
        setOffset(offset)
        setUpDownData({ ...upDownData, isEntered: true })
    }
    return (
        <>
            <Stack className={classes.selectBox} alignItems={"center"}
                justifyContent={"space-between"} p={"8px 0px"} direction={"row"}
                onClick={() => !isReadOnly && handleClick()}
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                ref={anchorRef}>
                <TextBox
                    height={36}
                    padding={"6px 8px"}
                    placeholder={placeholder}
                    border={"none"}
                    value={searchText ?? ""}
                    label={""}
                    onChange={(val) => handleSearch(val?.target?.value)}
                    autocomplete={"off"}
                    isReadonly={isReadOnly}
                    handleKeydown={(e) => handleScrollDown(e)}
                    handleKeyUp={(e) => handleScrollUp(e)}
                    handleEnter={(e) => handleEnterValue(e)}
                    textColor={customStyles?.textColor ?? "#091B29"}
                    disableColor={disableColor}
                    boldText={customStyles?.boldText} />
                {open ? <KeyboardArrowUpIcon sx={{ color: customStyles?.textColor ?? "#ccc", marginRight: "8px" }} /> : <KeyboardArrowDownIcon sx={{ color: customStyles?.textColor ?? "#ccc", marginRight: "8px" }} />}
                {/* <TextBox
                    height={36}
                    padding={"6px 8px"}
                    placeholder={placeholder}
                    border={"none"}
                    value={searchText ?? ""}
                    label={""}
                    onChange={(value) => {
                        handleSearch(value?.target?.value)
                    }}
                    autocomplete={"off"}
                    isReadonly={isReadOnly}
                    handleKeydown={(e) => handleScrollDown(e)}
                    handleKeyUp={(e) => handleScrollUp(e)}
                    handleEnter={(e) => handleEnterValue(e)} />
                {open ? <KeyboardArrowUpIcon sx={{ color: "#ccc" }} /> : <KeyboardArrowDownIcon sx={{ color: "#ccc" }} />} */}
            </Stack>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                style={{
                    position: "relative",
                    zIndex: 9,
                    width: menuOptionWidth ?? anchorRef?.current?.offsetWidth,
                    marginTop: "8px"
                }}
                disablePortal
                modifiers={{
                    offset: {
                        enabled: true,
                        offset: '0, 30'
                    }
                }}
            >
                {({ TransitionProps, placement }) => (
                    <>
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: '8px'
                            }}
                        // style={{
                        //     marginBottom: placement === 'top-start' ? "15px" : "0px",
                        //     marginTop: placement === 'bottom-start' ? "15px" : "0px",
                        //     transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                        //     padding: "0px 0px 0px 8px"
                        // }}
                        >
                            <Box sx={{
                                background: "#fff",
                                boxShadow: "0px 0px 16px #00000014",
                                width: `${menuOptionWidth ?? anchorRef?.current?.offsetWidth} !important`
                            }}>
                                <ClickAwayListener onClickAway={() => handleOnClose()}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        style={{ padding: menuOptionPadding ?? "8px 8px" }}
                                    >
                                        {
                                            list?.length === 0 ?
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 50 }}>
                                                    <Typography className={classes.noOptions}>No Options</Typography>
                                                </Box> :
                                                <>
                                                    {
                                                        options?.length > 0 ?
                                                            <Box sx={{ height: height ?? 100, maxHeight: menuOptionsHeight ?? 100, overflow: "auto" }}>
                                                                {
                                                                    options?.map((e, i, data) => {
                                                                        return (
                                                                            <Box onClick={() => handleSelect(e)} id={`menuList${i}`}>
                                                                                {
                                                                                    <Typography p={0.5} className={e?.value === upDownData.upDownValue?.value ? classes.hoverlist : e?.value === value?.value ? classes.selectedListText : classes.listText}>{e?.label}</Typography>
                                                                                }
                                                                            </Box>
                                                                        )
                                                                    })
                                                                }
                                                            </Box>
                                                            :
                                                            <InfiniteScroll
                                                                dataLength={list?.length ?? 10}
                                                                next={fetchMoreData}
                                                                hasMore={true}
                                                                height={(list?.length > 3 ? menuOptionsHeight : "auto") ?? 100}
                                                            >
                                                                {
                                                                    list?.map((e, i, data) => {
                                                                        return (
                                                                            <Box onClick={() => handleSelect(e)} id={`menuList${i}`}>
                                                                                {
                                                                                    customOptionComponent ?
                                                                                        customOptionComponent({ data: e, index: i, isSelected: e?.value === value?.value }) :
                                                                                        <>
                                                                                            <Typography p={0.5} className={e?.value === upDownData.upDownValue?.value ? classes.hoverlist : e?.value === value?.value ? classes.selectedListText : classes.listText}>{e?.label}</Typography>
                                                                                            {/* {data?.length - 1 !== i && <Divider></Divider>} */}
                                                                                        </>
                                                                                }
                                                                            </Box>
                                                                        )
                                                                    })
                                                                }
                                                            </InfiniteScroll>
                                                    }
                                                </>
                                        }
                                    </MenuList>

                                </ClickAwayListener>
                            </Box>
                        </Grow>
                    </>
                )}
            </Popper>
        </>
    )
}