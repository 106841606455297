import React from "react";
import { SearchAndFilter } from "./index";
import {
  getpaymentDashboardHeading,
  paymentDashboardDataType,
  paymentDashboardPath,
} from "../../../utils/paymentDashboard";
import { Box } from "@mui/material";
import { TableWithPagination } from "../../../components";
import { withNamespaces } from "react-i18next";

const PaymenTable = ({
  handleSearch = () => false,
  searchText = "",
  list = [],
  handlePagination = () => false,
  handleChangeLimit = () => false,
  page = "",
  limit = "",
  handleIcon = () => false,
  value = {},
  openfilter = () => false,
  filterData = {},
  count = 0,
  t,
}) => {
  const paymentHeader = getpaymentDashboardHeading(t);
  return (
    <Box p={{ xs: "12px", md: "16px" }} height="100vh">
      {/*search and filter */}
      <SearchAndFilter
        selected={value}
        openfilter={openfilter}
        handleSearch={handleSearch}
        searchText={searchText}
        filterData={filterData}
      />
      {/*table */}
      <TableWithPagination
        heading={paymentHeader}
        rows={list}
        path={paymentDashboardPath}
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        tableType="no-side"
        showpagination={true}
        handleIcon={handleIcon}
        dataType={paymentDashboardDataType}
        handlePagination={handlePagination}
        handleChangeLimit={handleChangeLimit}
        totalRowsCount={count}
        page={page}
        limit={limit}
        height={`calc(100vh - 130px)`}
        mdHeight={`calc(85vh - 130px) !important`}
      />
    </Box>
  );
};

export default withNamespaces("paymentDashboard")(PaymenTable);
