export const JobsPath = ["reference_no", "item", "name", "is_active"]

export const JobsType = [
    { type: ["text"], name: "reference_no" },
    { type: ["text"], name: "item" },
    { type: ["assetName"], name: "name" },
    { type: ["status"], name: "is_active" },
    { type: ["more_4"], icon: "icon" },
]

export const StatusOptionList = (t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]

export const ItemOptionList = (t) => [
    { label: t('Yes'), value: true },
    { label: t('No'), value: false },
]

export const initialState = {
    name: "",
    cost_price:null,
    sale_price:null,
    parentItem:"",
    item: false,
    item_type: "",
    item_category: "",
    item_subcategory: "",
    manufacturer_name: "",
    status: true,
    isEdit: false,
    isView:false,
    is_consumable:false,
    is_billing:false,
    is_Taxable:false,
    hsn_sac_code:null,
    tax_group:"",
    pantry_billing:false,
    is_FixedAsset:false,
    id: null,
    uom:"",
    model_no:"",
    error: {
        name: "",
        item_type: "",
        cost_price:"",
        sale_price:"",
        item: "",
        model_no:"",
        item_category: "",
        item_subcategory: "",
        manufacturer_name: "",
        status: "",
        parentItem:"",
        uom:"",
    }
}
export const ItemConditionInitialState = {
    title: "",
    status: true,
    name: "",
    color: "",
    type: "",
    inspectionType: "",
    error: {
        title: "",
        status: "",
        name: "",
        color: "",
        type: "",
        inspectionType: ""
    }

}

export const getInspectionMasterHeading = (t) => {

    const heading = [
        { title: t("itemId"), field: "reference_no" },
        { title: t("itemType"), field: "item" },
        { title: t("itemName"), field: "name", width: "200px" },
        // { title: t("inventoryItems"), field: "is_inventory" },
        { title: t("status"), field: "is_active", width: "600px" },
        { title: "", field: "", },
    ]

    return heading
}

export const inspectionMappingState ={
    isEdit:false,
    isView:false,
    modalNumber: null,
    manfacturerName: null,
    serialNumber: null,
    itemCondition: "",
    purchasePrice: "",
    last_service_date: "",
    last_service_reference: "",
    location:"",
    error: {
      modalNumber: "",
      manfacturerName: "",
      serialNumber: "",
      itemCondition: "",
      purchasePrice: "",
      last_service_date: "",
      last_service_reference: "",
      location:"",
    }
}

export const InspectionItemsPath = ["reference_no", "item", "name", "category", "subcategory", "manufacturer","model_no","period_type","is_billing", "is_taxable","is_fixed_asset", "tax_group", "costing_value", "sale_value", "is_active"]

export const InspectionItemsType = [
    { type: ["more_4"], icon: "icon" },
    { type: ["text"], name: "reference_no" },
    { type: ["text"], name: "item" },
    { type: ["long_text"], name: "name", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "category", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "subcategory", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "manufacturer", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "model_no", tooltip_placement: "top", max_width: "300px" },
    { type: ["text"], name: "period_type" },
    { type: ["yes_no"], name: "is_billing" },
    { type: ["yes_no"], name: "is_taxable" },
    { type: ["yes_no"], name: "is_fixed_asset" },
    { type: ["text"], name: "tax_group" },
    { type: ["price"], name: "costing_value" },
    { type: ["price"], name: "sale_value" },
    { type: ["status"], name: "is_active" },
]

export const InspectionItemsHeading = ({ t = () => false }) => {
    const heading = [
        { title: "", field: "", },
        { title: t("Item ID"), field: "reference_no", },
        { title: t("Type"), field: "item", },
        { title: t("Name"), field: "name", },
        { title: t("Category"), field: "category", },
        { title: t("Sub Category"), field: "subcategory", },
        { title: t("Manufacturer"), field: "manufacturer", },
        { title: t("Model Number"), field: "model_no", },
        { title: t("UOM"), field: "period_type", },
        { title: t("Billing"), field: "is_billing", },
        { title: t("Taxable"), field: "is_taxable", },
        { title: t("Fixed Asset"), field: "is_fixed_asset", },
        { title: t("Tax Group"), field: "tax_group", },
        { title: t("Cost Price"), field: "costing_value", },
        { title: t("Sale Price"), field: "sale_value", },
        { title: t("status"), field: "is_active", },
    ]

    return heading;
}