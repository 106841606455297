import { Search } from "@mui/icons-material";
import { Box, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardView, CompactView, ListView, SearchUnit } from "../../../assets";
import NoDataFound from "../../../assets/noData";
import { LoadingSection, Subheader, TextBox, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AssetMasterType, NetWorkCallMethods } from "../../../utils";
import CompactViewCard from "./compactViewCard";
import ListViewCard from "./listView";
import { UnitSingleDetails } from "./singleUnitDetail";
import { filterStyles } from "./styles";
import UnitCard from "./unitCard";

export const ViewAllDetails = (props) => {
    const { t } = (props)
    const classes = filterStyles();
    const [open, setOpen] = React.useState(false)
    const [details, setDetails] = React.useState({
        property: {},
        assets: {},
        asset: []
    })
    const [selectedUnits, setSelectedunit] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [offset, setOffset] = React.useState(0);
    const [unit, setUnit] = React.useState([])
    const [selectedView, setSelectedView] = React.useState(1)
    const [search, setSearch] = React.useState("");
    const [is_search, setIssearch] = React.useState(false)
    const debounce = UseDebounce();
    const openDetails = (value) => {
        setOpen(true)
        setSelectedunit(value)
    }
    const getUnitDetails = (offset, searchValue, isInfinity) => {
        if (!isInfinity) {
            setIssearch(true)
        }
        const payload = {
            masterType: AssetMasterType.image,
            id: props?.selectedProperty?.id,
            offset: offset,
            limit: 12,
            unit_id: props?.selectedProperty?.units?.map((val) => val?.id),
            search: searchValue ?? ""
        }
        NetworkCall(
            `${config.api_url}/queries/property_search/property/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (!isInfinity) {
                setIssearch(false)
            }
            setDetails({
                property: res.data?.data?.property?.[0],
                assets: res.data?.data?.assets,
                asset: res.data?.data?.asset
            })
            if (isInfinity) {
                setUnit(unit.concat(res.data?.data?.property?.[0]?.unit))
            } else {
                setUnit(res.data?.data?.property?.[0]?.unit)
            }

            setLoading(false)
        })
    }
    React.useEffect(() => {
        if (props?.selectedProperty?.id) {
            getUnitDetails(0)
        }

        // eslint-disable-next-line
    }, [])
    const handleShortlist = (id) => {
        if (props?.shortlistid?.some((val) => val === id)) {
            props?.setShortlistId(props?.shortlistid.filter((val) => val !== id));
        } else {
            props?.setShortlistId(props?.shortlistid.concat(id))
        }
    }
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getUnitDetails(offset + 10, search, true);
    }
    const returnViews = () => {
        return [
            {
                id: 1,
                icon: <CardView color={selectedView === 1 ? "#5078E1" : null} />,
                title: "Card View",
            },
            {
                id: 2,
                icon: <ListView color={selectedView === 2 ? "#5078E1" : null} />,
                title: "List View"
            },
            {
                id: 3,
                icon: <CompactView color={selectedView === 3 ? "#5078E1" : null} />,
                title: "Compact View"
            }
        ]
    }

    const onSearch = (val) => {
        setSearch(val)
        debounce(() => getUnitDetails(0, val), 800)
    }


    return (
        <>
            <Subheader
                title={details?.property?.name ?? ""}
                isClose
                close={props?.onClose}
                hideBackButton={true}
            />
            {
                loading ? <LoadingSection /> :
                    <>
                        <Box p={2} overflow="hidden" >
                            <Box display="flex" alignItems="center" marginBottom={"12px"}>
                                <Box flexGrow={1}>
                                    <Typography className={classes.detailTitle}>{details?.property?.unitCount?.[0]?.count_id} {t("Vacant Units")}</Typography>
                                </Box>
                                <Box>
                                    <Stack flexDirection="row">
                                        {
                                            returnViews()?.map((val) => {
                                                return <IconButton onClick={() => setSelectedView(val?.id)}>{val?.icon}</IconButton>
                                            })
                                        }

                                    </Stack>
                                </Box>
                            </Box>
                            <Grid container>
                                <Grid item xs={12} md={8} lg={3}>
                                    <TextBox
                                        startAdornment={<Search style={{ color: "gray", fontSize: "20px" }} />}
                                        color="#F5F7FA"
                                        label={null}
                                        borderRadius="8px"
                                        placeholder={"Search"}
                                        value={search}
                                        onChange={(e) => onSearch(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <InfiniteScroll
                                dataLength={details?.property?.unit}
                                next={fetchMoreData}
                                hasMore={true}
                                height={`calc(100vh - 230px)`}
                            >
                                <div className={classes.Detailroot1}>

                                    <Box height="14px" />
                                    {
                                        is_search ?
                                            <center>
                                                <SearchUnit />
                                            </center>
                                            :
                                            unit?.length > 0 ?
                                                <Grid container spacing={1.3}>

                                                    {
                                                        unit?.map((val) => {
                                                            return (
                                                                <Grid item xs={selectedView === 1 ? 2 : 4}>
                                                                    {selectedView === 1 &&
                                                                        <UnitCard
                                                                            type={val?.unit_purpose}
                                                                            currency={val?.currencyByID?.symbol}
                                                                            shortlistid={props?.shortlistid}
                                                                            data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails} />
                                                                    }


                                                                    {selectedView === 2 &&
                                                                        <ListViewCard
                                                                            type={val?.unit_purpose}
                                                                            currency={val?.currencyByID?.symbol}
                                                                            shortlistid={props?.shortlistid}
                                                                            data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails}
                                                                            t={t} />}

                                                                    {selectedView === 3 &&
                                                                        <CompactViewCard
                                                                            type={val?.unit_purpose}
                                                                            currency={val?.currencyByID?.symbol}
                                                                            shortlistid={props?.shortlistid}
                                                                            data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails}
                                                                            t={t} />
                                                                    }
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                :
                                                <center>
                                                    <NoDataFound />
                                                </center>
                                    }
                                </div>
                            </InfiniteScroll>

                        </Box>

                        <Dialog maxWidth="xs" className={classes.dialog} fullWidth open={open} onClose={() => setOpen(false)}>
                            <UnitSingleDetails
                                revenue_type={props?.revenue_type}
                                grace={props?.grace}
                                startDate={props?.startDate}
                                endDate={props?.endDate}
                                t={t}
                                shortlistid={props?.shortlistid} handleShortlist={handleShortlist} selectedUnits={selectedUnits} onClose={() => setOpen(false)} />
                        </Dialog>
                    </>


            }
            {props?.btn}

        </>
    )
}