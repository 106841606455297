/* eslint-disable react-hooks/exhaustive-deps */
import {
    Avatar, Box, Button, Dialog, Divider, Grid, IconButton, InputAdornment, Stack, Typography
} from '@mui/material';
import React from 'react';
import CloseIcon from "../../../../../assets/closeIcon";
import InfoIcon from "../../../../../assets/infoIcon";
import RightCheveronIcon from "../../../../../assets/rightCheveronIcon";
import { SearchFilter, SelectBox, TableWithPagination, TextBox, UseDebounce } from '../../../../../components';
import { enum_types, enumName, enumSelect } from '../../../../../utils';
import { loadOptionsApis } from "../../../../../utils/asyncPaginateLoadOptions";
import {
    AddQuestions, ChargeableList, PaymentPeriodList, RevenueTypeList,
    ShortTermPricingHeading, ShortTermPricingPath, ShortTermPricingType,
    ValueBasisList1
} from "../../../../../utils/shortTermPricing";
import { LightTooltip, useStyles } from "./style";

export const BreakupList = (props) => {
    const defaultAddNewState = {
        formType: "question",
        question: "",
        category: "",
        categoryQuestionSet: {
            isPrimary: false,
            isOneTime: false,
            isRefundable: false,
            isQuantity: false,
            isParking: false,
        },
        info: "",
        primaryColor: "",
        secondaryColor: "",
        id: "",
        revenueType: RevenueTypeList[0],
        pricingComponent: "",
        taxGroup: "",
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        value: "",
        currency: "",
        currencyCode: "",
        uom: "",
        paymentPeriod: PaymentPeriodList[0],
        quantity: "",
        companyID: "",
        propertyID: "",
        unitID: "",
        countryID: "",
        isChargeable: false,
        error: {
            pricingComponent: "",
            taxGroup: "",
            value: "",
            quantity: "",
        }
    }
    const classes = useStyles();
    // eslint-disable-next-line
    const [shortTermPricingList, setShortTermPricingList] = React.useState({ data: [], count: 0 })
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [addNewDialog, setAddNewDialog] = React.useState(null);
    const [addNewState, setAddNewState] = React.useState({ ...defaultAddNewState });
    // eslint-disable-next-line
    const [loading, setLoading] = React.useState(false);
    const debounce = UseDebounce();
    const [enumValue, setEnumValue] = React.useState({
        revenue_type: [],
        unit_breakup_type: [],
        primary_unit_breakup_type: [],
        secondary_unit_breakup_type: [],
        refundable_unit_breakup_type: [],
        parking_unit_breakup_type: [],
        pricing_period_type: [],
        item_based_pricing_period_type: []
    })

    // Function for updating addNewState
    const updateAddNewState = (k, v) => {
        let error = addNewState?.error;
        error[k] = "";
        if (k === "revenueType") {
            setAddNewState({ ...addNewState, [k]: v, pricingComponent: "", error });
        } else if (k === "valueBasis") {
            setAddNewState({ ...addNewState, [k]: v, value: "", quantity: "", error });
        } else {
            setAddNewState({ ...addNewState, [k]: v, error });
        }
    }

    // Function for updating addNewState
    // const validate = () => {
    //     let isValid = true;
    //     let error = addNewState.error;
    //     if (addNewState?.pricingComponent?.length === 0) {
    //         isValid = false; error.pricingComponent = "Pricing Component is Required";
    //     }
    //     if (addNewState?.pricingComponent?.taxtable && addNewState?.taxGroup?.length === 0) { isValid = false; error.taxGroup = "Tax Group is Required"; }
    //     if (!(addNewState?.category === "Refundable Price Component" &&
    //         addNewState?.valueBasis?.value === "Rental Value") &&
    //         addNewState?.value?.length === 0) {
    //         isValid = false;
    //         error.value = (addNewState?.category === "Item Based Component" ? "Price" :
    //             addNewState?.category === "One Time Charges" ? "Amount" : "Value") + " is Required";
    //     }
    //     if ((addNewState?.category === "Item Based Component" ||
    //         (addNewState?.category === "Refundable Price Component" &&
    //             addNewState?.valueBasis?.value === "Rental Value")) &&
    //         addNewState?.quantity?.length === 0) { isValid = false; error.quantity = "Quantity is Required"; }
    //     if (!isValid) {
    //         alert.setSnack({
    //             ...alert, open: true,
    //             severity: AlertProps.severity.error,
    //             msg: "Please fill all mandatory field",
    //         });
    //     }
    //     setAddNewState({ ...addNewState, error });
    //     return isValid;
    // };

    // Changing Page Number
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getShortTermPricingList(offset, limit, searchText)
    };

    // Changing Page Number
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getShortTermPricingList(0, value, searchText)
    };

    // Function for deleting Pricing Component
    const deleteShortTermPricing = async () => {
        return false
    };

    // Changing Status of Short Term Pricing
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    };

    // Search function for table
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1); }
        getShortTermPricingList(0, limit, e)
    };

    // More Icon function in table
    const handleIcon = (type, data) => {

        let question = data?.data?.is_onetime ? AddQuestions?.[2]?.question :
            data?.data?.refundable ? AddQuestions?.[3]?.question :
                data?.data?.is_quantity ? AddQuestions?.[4]?.question :
                    data?.data?.is_parking ? AddQuestions?.[5]?.question :
                        data?.data?.primary ? AddQuestions?.[0]?.question :
                            AddQuestions?.[1]?.question;

        let category = data?.data?.is_onetime ? AddQuestions?.[2]?.category :
            data?.data?.refundable ? AddQuestions?.[3]?.category :
                data?.data?.is_quantity ? AddQuestions?.[4]?.category :
                    data?.data?.is_parking ? AddQuestions?.[5]?.category :
                        data?.data?.primary ? AddQuestions?.[0]?.category :
                            AddQuestions?.[1]?.category;

        let info = data?.data?.is_onetime ? AddQuestions?.[2]?.info :
            data?.data?.refundable ? AddQuestions?.[3]?.info :
                data?.data?.is_quantity ? AddQuestions?.[4]?.info :
                    data?.data?.is_parking ? AddQuestions?.[5]?.info :
                        data?.data?.primary ? AddQuestions?.[0]?.info :
                            AddQuestions?.[1]?.info;

        let primaryColor = data?.data?.is_onetime ? AddQuestions?.[2]?.primaryColor :
            data?.data?.refundable ? AddQuestions?.[3]?.primaryColor :
                data?.data?.is_quantity ? AddQuestions?.[4]?.primaryColor :
                    data?.data?.is_parking ? AddQuestions?.[5]?.primaryColor :
                        data?.data?.primary ? AddQuestions?.[0]?.primaryColor :
                            AddQuestions?.[1]?.primaryColor;

        let secondaryColor = data?.data?.is_onetime ? AddQuestions?.[2]?.secondaryColor :
            data?.data?.refundable ? AddQuestions?.[3]?.secondaryColor :
                data?.data?.is_quantity ? AddQuestions?.[4]?.secondaryColor :
                    data?.data?.is_parking ? AddQuestions?.[5]?.secondaryColor :
                        data?.data?.primary ? AddQuestions?.[0]?.secondaryColor :
                            AddQuestions?.[1]?.secondaryColor;

        let isChargeable = data?.data?.is_onetime ? true :
            data?.data?.is_parking ? true :
                (data?.data?.primary && data?.data?.is_onetime &&
                    data?.data?.refundable && data?.data?.is_quantity &&
                    data?.data?.is_parking) ? true : false;

        const tempAddNewState = {
            formType: type,
            question: question,
            category: category,
            info: info,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            id: data?.id ?? "",
            revenueType: data?.data?.revenue_type ?
                { value: data?.data?.revenue_type, label: data?.data?.revenue_type } :
                RevenueTypeList[0],
            pricingComponent: data?.data?.rental_breakupByID ?? "",
            taxGroup: data?.data?.vat_groupByID ?? "",
            chargeable: { value: data?.data?.is_chargeable, label: data?.data?.is_chargeable ? "Yes" : "No" },
            valueBasis: data?.data?.value_basis_type ? {
                value: data?.data?.value_basis_type,
                label: data?.data?.value_basis_type === "Amount" ? "Fixed Amount" :
                    data?.data?.value_basis_type === "Percentage" ? "Percentage" :
                        data?.data?.value_basis_type === "Per UOM" ? "UOM" :
                            data?.data?.value_basis_type === "Rental Value" ? "Rental Value" : data?.data?.value_basis_type
            } : ValueBasisList1[0],
            value: data?.data?.value ?? "",
            currency: data?.data?.currencyByID ?? "",
            currencyCode: data?.data?.currencyByID?.code ?? "",
            uom: data?.data?.uom ?? "",
            paymentPeriod: data?.data?.payment_period ? {
                value: data?.data?.payment_period,
                label: data?.data?.payment_period
            } : PaymentPeriodList[0],
            quantity: data?.data?.quantity ?? "",
            companyID: data?.data?.company_id ?? "",
            propertyID: data?.data?.property_id ?? "",
            unitID: data?.data?.unit_id ?? "",
            countryID: data?.data?.companyByID?.country_id ?? "",
            categoryQuestionSet: {
                isPrimary: data?.data?.primary ?? false,
                isOneTime: data?.data?.is_onetime ?? false,
                isRefundable: data?.data?.refundable ?? false,
                isQuantity: data?.data?.quantity ?? false,
                isParking: data?.data?.is_parking ?? false,
            },
            isChargeable: isChargeable,
            error: defaultAddNewState?.error,
        };

        if (type === "edit") {
            setAddNewState({ ...tempAddNewState });
            addNewDialogopen();
        }
        else if (type === "view") {
            setAddNewState({ ...tempAddNewState });
            addNewDialogopen();
        }
        else if (type === "delete") { deleteShortTermPricing(data.id, data?.data?.unit_id) }
    };

    // Function to get Short Term Pricing data from Graphql
    const getShortTermPricingList = (offset = 0, limit = 10, search = "") => {        
        return false
    }
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type, enum_types.unit_breakup_type, enum_types.pricing_period_type])
        let primary_unit_breakup_type = [], secondary_unit_breakup_type = [];
        let parking_unit_breakup_type = [], refundable_unit_breakup_type = [];
        let item_based_pricing_period_type = [];
        for (let i = 0; i < result.unit_breakup_type.length; i++) {
            if (result.unit_breakup_type[i].value !== enumName.rentalValue &&
                result.unit_breakup_type[i].value !== enumName.percentage) {
                primary_unit_breakup_type = [...primary_unit_breakup_type, result.unit_breakup_type[i]]
            }
            if (result.unit_breakup_type[i].value !== enumName.rentalValue) {
                secondary_unit_breakup_type = [...secondary_unit_breakup_type, result.unit_breakup_type[i]]
                parking_unit_breakup_type = [...parking_unit_breakup_type, result.unit_breakup_type[i]]
            }
            if (result.unit_breakup_type[i].value !== enumName.perUOM) {
                refundable_unit_breakup_type = [...refundable_unit_breakup_type, result.unit_breakup_type[i]]
            }
        }
        for (let i = 0; i < result.pricing_period_type.length; i++) {
            if (result.pricing_period_type[i].value !== enumName.monthly) {
                item_based_pricing_period_type = [...item_based_pricing_period_type, result.pricing_period_type[i]]
            }
        }
        setEnumValue({
            revenue_type: result?.revenue_type,
            unit_breakup_type: result?.unit_breakup_type,
            primary_unit_breakup_type: primary_unit_breakup_type,
            secondary_unit_breakup_type: secondary_unit_breakup_type,
            refundable_unit_breakup_type: refundable_unit_breakup_type,
            parking_unit_breakup_type: parking_unit_breakup_type,
            pricing_period_type: result?.pricing_period_type,
            item_based_pricing_period_type: item_based_pricing_period_type,
        })
        setAddNewState({
            ...addNewState, paymentPeriod: result?.pricing_period_type[0]
        })
    }
    // useEffect for the screen with company and filter data as dependency
    React.useEffect(() => {
        if (props?.permission?.read) {
            if (props?.data?.id) {
                getShortTermPricingList();
                getEnum()
            }
        }
    }, [props?.data?.id, props?.permission]);

    // Function to open filter drawer
    const addNewDialogopen = () => {
        setAddNewDialog(true);
    };

    // Function to close filter drawer
    const addNewDialogclose = () => {
        setAddNewDialog(false);
    };

    // Function for Add New Button
    const handleAddNewButton = () => {
        setAddNewState({ ...defaultAddNewState });
        addNewDialogopen();
    };

    // Function for clicking question while adding
    const handleQuestionCard = (value) => {
        setAddNewState({
            ...addNewState,
            formType: "add",
            question: value?.question,
            category: value?.category,
            info: value?.info,
            primaryColor: value?.primaryColor,
            secondaryColor: value?.secondaryColor,
            revenueType: props?.data?.revenue_type ?
                { value: props?.data?.revenue_type, label: props?.data?.revenue_type } :
                RevenueTypeList[0],
            pricingComponent: "",
            taxGroup: "",
            chargeable: value?.chargeable,
            valueBasis: value?.valueBasis,
            value: "",
            currency: props?.data?.property?.company?.currencyByID,
            currencyCode: props?.data?.property?.company?.currencyByID?.code,
            uom: props?.data?.area_metric,
            paymentPeriod: (props?.data?.revenue_type === enumName.sale &&
                value?.categoryType === AddQuestions[0].categoryType) ?
                PaymentPeriodList[2] : value?.paymentPeriod,
            quantity: "",
            companyID: props?.data?.property?.company?.id,
            propertyID: props?.data?.property?.id,
            unitID: props?.data?.id,
            countryID: props?.data?.property?.company?.country_id,
            categoryQuestionSet: {
                isPrimary: value?.isPrimary,
                isOneTime: value?.isOneTime,
                isRefundable: value?.isRefundable,
                isQuantity: value?.isQuantity,
                isParking: value?.isParking,
            },
            isChargeable: value?.isChargeable,
            id: "",
            error: defaultAddNewState?.error,
        })
    };

    // Function to save Short Term Pricing
    const saveShortTermPricing = () => {
        return false
    };

    const manualPricingComponentResponse = (array) => {
        return array?.pricing_component
    }

    const manualVatGroupResponse = (array) => {
        return array?.vat_group_master
    }

    return <div>

        {/* Content */}
        <div className={classes.root}>
            <Grid container className={classes.content} spacing={2}>

                {/* Search TextBox */}
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder="Search Pricing Table" handleChange={(value) => handleSearch(value)} />
                </Grid>

                {/* Add New Button */}
                <Grid item xs={8} textAlign={"right"}>
                    <Box display={"flex"} sx={{ float: "right" }}>
                        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                            {props?.permission?.create && <Button variant="contained" className={classes.addNewButton} onClick={handleAddNewButton}>
                                {"Add New"}
                            </Button>}
                        </Stack>
                    </Box>
                </Grid>

                {/* Short Term Pricing Table */}
                <Grid item xs={12} marginTop="-14px">
                    <TableWithPagination
                        heading={ShortTermPricingHeading}
                        rows={shortTermPricingList?.data}
                        path={ShortTermPricingPath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleIcon}
                        onClick={() => console.log("")}
                        tableType="no-side"
                        dataType={ShortTermPricingType}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={shortTermPricingList?.count}
                        page={page}
                        limit={limit}
                        height={'calc(100vh - 502px)'}
                        view={props?.permission?.read}
                        edit={props?.permission?.update}
                        delete={props?.permission?.delete} />
                </Grid>
            </Grid>

            {/* Add Edit View Drawer */}
            <Dialog
                open={addNewDialog}
                onClose={addNewDialogclose}
                className={classes.addNewDialog}>
                <div className={classes.addNewDialogHeader}>
                    <Typography className={classes.addNewDialogHeaderTitle}>Short Term Pricing</Typography>
                    <IconButton className={classes.addNewDialogHeaderCloseButton} onClick={addNewDialogclose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {addNewState?.formType === "question" ? <div className={classes.addNewDialogQuestionBody}>
                    {AddQuestions?.map((item, index, array) => {
                        return <>
                            <div className={classes.addQuestionCard1} onClick={() => { handleQuestionCard(item) }}
                                style={{ backgroundColor: item?.secondaryColor }}>
                                <Box className={classes.addQuestionContent1}>
                                    <Avatar className={classes.addQuestionSerialAvatar} style={{ backgroundColor: item?.primaryColor }}>
                                        <Typography className={classes.addQuestionSerialText} >
                                            {item?.serial}
                                        </Typography>
                                    </Avatar>
                                    <Typography className={classes.addNewDialogQuestionText} style={{ color: item?.primaryColor }}>
                                        {item?.question}
                                    </Typography>
                                </Box>
                                <Box className={classes.addQuestionContent2}>
                                    <LightTooltip title={item?.info} placement="top">
                                        <Box className={classes.addNewDialogInfoIcon1}>
                                            <InfoIcon />
                                        </Box>
                                    </LightTooltip>
                                    <RightCheveronIcon fill={item?.primaryColor} />
                                </Box>
                            </div>
                            {(index !== array?.length - 1) && <Box height={"12px"} />}
                        </>
                    })}
                </div> : <>
                    <div className={classes.addNewDialogFormBody}>
                        <div className={classes.addNewDialogFormContent}>
                            <div className={classes.addQuestionCard2}
                                style={{ backgroundColor: addNewState?.secondaryColor }}>
                                <Box className={classes.addQuestionContent1}>
                                    <Typography className={classes.addNewDialogQuestionText} style={{ color: addNewState?.primaryColor }}>
                                        {addNewState?.category}
                                    </Typography>
                                </Box>
                                <Box className={classes.addQuestionContent2}>
                                    <LightTooltip title={addNewState?.info} placement="top">
                                        <Box className={classes.addNewDialogInfoIcon2}>
                                            <InfoIcon />
                                        </Box>
                                    </LightTooltip>
                                </Box>
                            </div>
                            <Box height={"24px"} />
                            <Grid container justifyContent={"space-between"} spacing={"16px"}>
                                <Grid item xs={12} md={6}>
                                    <Typography className={classes.addNewDialogFormFieldLabel} noWrap>Revenue Type</Typography>
                                    <Grid container rowGap={"8px"} columnGap={"8px"}>
                                        {enumValue?.revenue_type?.map((value) => {
                                            return <Button className={value?.value === addNewState?.revenueType?.value ?
                                                classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}>
                                                <Typography className={value?.value === addNewState?.revenueType?.value ?
                                                    classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                    {value?.label}</Typography>
                                            </Button>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectBox
                                        key={addNewState?.revenueType?.value}
                                        isRequired
                                        isReadOnly={(addNewState?.formType === "add" || addNewState?.formType === "edit") ? false : true}
                                        color={addNewState?.revenueType ? "none" : "#F2F4F7"}
                                        label="Pricing Component"
                                        placeholder="Select Component"
                                        value={addNewState?.pricingComponent}
                                        onChange={(value) => updateAddNewState("pricingComponent", value)}
                                        loading={loading === "pricing_component"}
                                        isPaginate
                                        debounceTimeout={800}
                                        loadOptions={
                                            (search, array, handleLoading) =>
                                                loadOptionsApis(
                                                    "queries/pricing_component/get_dropdown",
                                                    {
                                                        company_id: addNewState?.companyID,
                                                        country_id: addNewState?.countryID,
                                                        revenue_type: addNewState?.revenueType?.value,
                                                        primary: addNewState?.categoryQuestionSet?.isPrimary,
                                                        is_onetime: addNewState?.categoryQuestionSet?.isOneTime,
                                                        refundable: addNewState?.categoryQuestionSet?.isRefundable,
                                                        is_quantity: addNewState?.categoryQuestionSet?.isQuantity,
                                                        is_parking: addNewState?.categoryQuestionSet?.isParking,
                                                        is_wallet: addNewState?.categoryQuestionSet?.is_wallet,
                                                        is_late_fee: addNewState?.categoryQuestionSet?.is_late_fee,
                                                        is_commission_payment: addNewState?.categoryQuestionSet?.is_commission_payment,
                                                    },
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    "data",
                                                    {},
                                                    manualPricingComponentResponse
                                                )
                                        }
                                        isError={addNewState?.error?.pricingComponent?.length > 0}
                                        errorMessage={addNewState?.error?.pricingComponent} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectBox
                                        isRequired={addNewState?.pricingComponent?.taxtable ? true : false}
                                        isReadOnly={((addNewState?.formType === "add" || addNewState?.formType === "edit") &&
                                            addNewState?.pricingComponent?.taxtable) ? false : true}
                                        label="Tax Group For Pricing Component"
                                        placeholder={addNewState?.pricingComponent?.taxtable ? "Select Group" : "No Tax Applicable"}
                                        value={addNewState?.taxGroup}
                                        onChange={(value) => updateAddNewState("taxGroup", value)}
                                        loading={loading === "vat_group_master"}
                                        isPaginate
                                        debounceTimeout={800}
                                        loadOptions={
                                            (search, array, handleLoading) =>
                                                loadOptionsApis(
                                                    "queries/pricing_component/get_dropdown",
                                                    { country_id: addNewState?.countryID },
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    "data",
                                                    {},
                                                    manualVatGroupResponse
                                                )
                                        }
                                        isError={addNewState?.error?.taxGroup?.length > 0}
                                        errorMessage={addNewState?.error?.taxGroup} />
                                </Grid>
                                {addNewState?.isChargeable &&
                                    <Grid item xs={12} md={6}>
                                        <Typography className={classes.addNewDialogFormFieldLabel} noWrap>Chargeable</Typography>
                                        <Grid container rowGap={"8px"} columnGap={"8px"}>
                                            {ChargeableList.map((value) => {
                                                return <Button className={value?.value === addNewState?.chargeable?.value ?
                                                    classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}
                                                    onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                        updateAddNewState("chargeable", value) : false}>
                                                    <Typography className={value?.value === addNewState?.chargeable?.value ?
                                                        classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                        {value?.label}</Typography>
                                                </Button>
                                            })}
                                        </Grid>
                                    </Grid>}
                                {(addNewState?.category === "Primary Pricing Component" ||
                                    addNewState?.category === "Secondary Components" ||
                                    addNewState?.category === "Parking Slot Component") &&
                                    <><Grid item xs={12}>
                                        <Typography className={classes.addNewDialogFormFieldLabel} noWrap>Component Based On</Typography>
                                        <Grid container rowGap={"8px"} columnGap={"8px"}>
                                            {(addNewState?.category === "Primary Pricing Component" ?
                                                enumValue?.primary_unit_breakup_type :
                                                addNewState?.category === "Secondary Components" ?
                                                    enumValue?.secondary_unit_breakup_type :
                                                    addNewState?.category === "Parking Slot Component" ?
                                                        enumValue?.parking_unit_breakup_type :
                                                        enumValue?.unit_breakup_type)?.map((value) => {
                                                            return <Button className={value?.value === addNewState?.valueBasis?.value ?
                                                                classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}
                                                                onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                                    updateAddNewState("valueBasis", value) : false}>
                                                                <Typography className={value?.value === addNewState?.valueBasis?.value ?
                                                                    classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                                    {value?.label}</Typography>
                                                            </Button>
                                                        })}
                                        </Grid>
                                    </Grid>
                                        <Grid item xs={12}>
                                            <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                    if (e.key === 'e') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                isrequired
                                                isReadonly={(addNewState?.formType === "add" || addNewState?.formType === "edit") ? false : true}
                                                label={addNewState?.valueBasis?.label + " Value"}
                                                placeholder="Enter Value"
                                                value={addNewState?.value ?? ""}
                                                onChange={(e) => updateAddNewState("value", e.target.value)}
                                                endAdornment={<InputAdornment position="end">
                                                    {addNewState?.valueBasis?.value === "Amount" ?
                                                        (addNewState?.currencyCode + " / " + addNewState?.paymentPeriod?.label) :
                                                        addNewState?.valueBasis?.value === "Percentage" ?
                                                            ("% / " + addNewState?.paymentPeriod?.label) :
                                                            addNewState?.valueBasis?.value === "Per UOM" ?
                                                                (addNewState?.uom + " / " + addNewState?.paymentPeriod?.label) : ""}
                                                </InputAdornment>}
                                                isError={addNewState?.error?.value?.length > 0}
                                                errorMessage={addNewState?.error?.value} />
                                        </Grid></>}
                                {addNewState?.category === "One Time Charges" &&
                                    <Grid item xs={12}>
                                        <TextBox
                                            type='number'
                                            onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            isrequired
                                            isReadonly={(addNewState?.formType === "add" || addNewState?.formType === "edit") ? false : true}
                                            label={"One Time Charges Amount"}
                                            placeholder="Enter Amount"
                                            value={addNewState?.value ?? ""}
                                            onChange={(e) => updateAddNewState("value", e.target.value)}
                                            endAdornment={<InputAdornment position="end">{addNewState?.currencyCode}</InputAdornment>}
                                            isError={addNewState?.error?.value?.length > 0}
                                            errorMessage={addNewState?.error?.value} />
                                    </Grid>}
                                {addNewState?.category === "Refundable Price Component" &&
                                    <><Grid item xs={12}>
                                        <Typography className={classes.addNewDialogFormFieldLabel} noWrap>Component Based On</Typography>
                                        <Grid container rowGap={"8px"} columnGap={"8px"}>
                                            {enumValue?.refundable_unit_breakup_type?.map((value) => {
                                                return <Button className={value?.value === addNewState?.valueBasis?.value ?
                                                    classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}
                                                    onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                        updateAddNewState("valueBasis", value) : false}>
                                                    <Typography className={value?.value === addNewState?.valueBasis?.value ?
                                                        classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                        {value?.label}</Typography>
                                                </Button>
                                            })}
                                        </Grid>
                                    </Grid>
                                        <Grid item xs={12}>
                                            <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                    if (e.key === 'e') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                isrequired
                                                isReadonly={(addNewState?.formType === "add" || addNewState?.formType === "edit") ? false : true}
                                                label={addNewState?.valueBasis?.value === "Rental Value" ?
                                                    "Quantity" : (addNewState?.valueBasis?.label + " Value")}
                                                placeholder={"Enter " +
                                                    (addNewState?.valueBasis?.value === "Rental Value" ? "Quanity" : "Value")}
                                                value={(addNewState?.valueBasis?.value === "Rental Value" ?
                                                    addNewState?.quantity : addNewState?.value) ?? ""}
                                                onChange={(e) => updateAddNewState(
                                                    addNewState?.valueBasis?.value === "Rental Value" ?
                                                        "quantity" : "value", e.target.value)}
                                                endAdornment={<InputAdornment position="end">
                                                    {addNewState?.valueBasis?.value === "Amount" ?
                                                        (addNewState?.currencyCode + " / " + addNewState?.paymentPeriod?.label) :
                                                        addNewState?.valueBasis?.value === "Per UOM" ?
                                                            (addNewState?.uom + " / " + addNewState?.paymentPeriod?.label) :
                                                            addNewState?.valueBasis?.value === "Percentage" ?
                                                                ("% of " + addNewState?.paymentPeriod?.label) :
                                                                addNewState?.valueBasis?.value === "Rental Value" ?
                                                                    "Qty" : ""}
                                                </InputAdornment>}
                                                isError={addNewState?.valueBasis?.value === "Rental Value" ?
                                                    addNewState?.error?.quantity?.length > 0 : addNewState?.error?.value?.length > 0}
                                                errorMessage={addNewState?.valueBasis?.value === "Rental Value" ?
                                                    addNewState?.error?.quantity : addNewState?.error?.value} />
                                        </Grid></>}
                                {addNewState?.category === "Item Based Component" &&
                                    <><Grid item xs={12}>
                                        <Typography className={classes.addNewDialogFormFieldLabel} noWrap>Pricing Based On</Typography>
                                        <Grid container rowGap={"8px"} columnGap={"8px"}>
                                            {enumValue?.item_based_pricing_period_type?.map((value) => {
                                                return <Button className={value?.value === addNewState?.paymentPeriod?.value ?
                                                    classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}
                                                    onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                        updateAddNewState("paymentPeriod", value) : false}>
                                                    <Typography className={value?.value === addNewState?.paymentPeriod?.value ?
                                                        classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                        {value?.label}</Typography>
                                                </Button>
                                            })}
                                        </Grid>
                                    </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                    if (e.key === 'e') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                isrequired
                                                isReadonly={(addNewState?.formType === "add" || addNewState?.formType === "edit") ? false : true}
                                                label={"Item Unit Price"}
                                                placeholder="Enter Price"
                                                value={addNewState?.value ?? ""}
                                                onChange={(e) => updateAddNewState("value", e.target.value)}
                                                endAdornment={<InputAdornment position="end">{addNewState?.currencyCode}</InputAdornment>}
                                                isError={addNewState?.error?.value?.length > 0}
                                                errorMessage={addNewState?.error?.value} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                    if (e.key === 'e') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                isrequired
                                                isReadonly={(addNewState?.formType === "add" || addNewState?.formType === "edit") ? false : true}
                                                label={"Quantity"}
                                                placeholder="Enter Quanity"
                                                value={addNewState?.quantity ?? ""}
                                                onChange={(e) => updateAddNewState("quantity", e.target.value)}
                                                endAdornment={<InputAdornment position="end">{"Qty"}</InputAdornment>}
                                                isError={addNewState?.error?.quantity?.length > 0}
                                                errorMessage={addNewState?.error?.quantity} />
                                        </Grid></>}
                            </Grid>
                        </div>
                    </div>
                    {props?.permission?.update && <div className={addNewState?.formType === "add" ?
                        classes.addNewDialogButtonContentAdd : classes.addNewDialogButtonContentViewEdit}>
                        {addNewState?.formType === "add" ?
                            <><Button className={classes.addNewDialogBackButton}
                                onClick={() => updateAddNewState("formType", "question")}>
                                <Typography className={classes.addNewDialogBackButtonText}>Back</Typography>
                            </Button>
                                <Button className={classes.addNewDialogAddButton}
                                    onClick={saveShortTermPricing}>
                                    <Typography className={classes.addNewDialogAddButtonText}>Create Short Term Pricing</Typography>
                                </Button></> :
                            <><Button className={classes.addNewDialogViewEditButton} fullWidth
                                onClick={() => addNewState?.formType === "view" ? updateAddNewState("formType", "edit") : saveShortTermPricing()}>
                                <Typography className={classes.addNewDialogViewEditButtonText}>
                                    {addNewState?.formType === "view" ? "Edit" : "Save"}
                                </Typography>
                            </Button></>}
                    </div>}
                </>}
            </Dialog>
        </div>
    </div >
}