/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FilterIMG from "../../assets/filter";
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, getCompanyOption, NetWorkCallMethods } from '../../utils';
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { PricingTablePropertyheading, PricingtablePropertypath } from '../../utils/tableData';
import { vacancyStyles } from './styles';


const UnitVacancy = ({ t }) => {
    const defaultFilterState = { status: [true] };
    const history = useHistory();
    const classes = vacancyStyles();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const [drawer, setDrawer] = React.useState(null);
    const [propertyList, setPropertyList] = React.useState({});
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    // const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const auth = React.useContext(AuthContext);

    //debounce
    const debounce = UseDebounce();
    //pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPropertyByCompanyID(offset, limit, searchText)
    }
    //change limit page
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPropertyByCompanyID(0, value, searchText)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getPropertyByCompanyID(0, limit, e)
    }
    //icon click function
    const handleIcon = (type, data, status) => {
        if (type === "view") {
            history.push(Routes.vacancy_details + "?propertyID=" + data?.id)
        }
    }
    //drawer open
    const draweropen = () => {
        setDrawer(true);
    };
    //drawer close
    const drawerclose = () => {
        setDrawer(false);
    };
    //table row
    const propertyRow = React.useCallback(propertyList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                propertyNo: val?.property_no,
                propertyName: val?.name,
                companyName: val?.company?.name,
                location: val?.address?.city ? val?.address?.city : val?.address?.district,
                blocks: val?.block.length,
                floors: val?.floor.length,
                units: val?.unit.length,
                propertyType: val?.property_group_master?.[0]?.group_name,
                status: val?.is_active ? "Active" : "Inactive",
                icon: "editview",
                companyID: val?.company?.id,
                id: val?.id,
            };

        } catch (err) {

        }
        return _d;
    }), [propertyList]);
    //property lisiting
    const getPropertyByCompanyID = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });

        let companyID = [selectedCompany?.value];
        let propertyGroudID = filterData.property_type?.length ? filterData?.property_type?.map(({ value }) => value) : [];
        let is_active = filterData?.status ?? [];

        const payload = {
            offset:offset,
            limit:limit,
            company_id:companyID,
            is_active:is_active,
            search:search,
            property_group_id:propertyGroudID
        };
        NetworkCall(
            `${config.api_url}/queries/property/by_company`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setPropertyList({
                    data: response?.data?.data?.property,
                    totalRowsCount: response?.data?.data?.count?.[0]?.count,
                });
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch(() => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    };

    React.useEffect(() => {
        if (selectedCompany?.value) {
            getPropertyByCompanyID();
        }
    }, [selectedCompany, filterData]);

    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth]);
    //company change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }
    //filter apply
    const onApplyFilter = (value) => {
        setFilterData(value)
    }

    return <div >
        <Subheader hideBackButton={true} title={t("Unit Vacancy")} select options={companyList} value={selectedCompany}
            goBack={() => { history.push(Routes.home) }}
            onchange={(e) => { handleCompanyChange(e) }} />
        <div className={classes.root}>
            {propertyRow?.length < 0 ?
                <>
                    <Grid container className={classes.content} >
                        <Grid item xs={12}>
                            <img src='/images/propertybg.svg' alt='pp' />
                            <Typography className={classes.text}>
                                {t("No Property Found")}
                            </Typography>
                            <br />
                        </Grid>
                    </Grid>
                </> :
                <>
                    <Grid container className={classes.content} spacing={2}>
                        <Grid item xs={4}>
                            <SearchFilter value={searchText} placeholder={t("Search Properties")} handleChange={(value) => handleSearch(value)} />
                        </Grid>
                        <Grid item xs={8} textAlign={"right"}>
                            <Box
                                display={"flex"}
                                sx={{ float: "right" }}>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    spacing={2}>
                                    {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                        <IconButton onClick={draweropen} className={classes.img}>
                                            <Badge variant="dot" color="primary">
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>

                                    ) : (
                                        <IconButton onClick={draweropen} className={classes.img}>
                                            <FilterIMG color="#091b29" />
                                        </IconButton>)}

                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} marginTop="-14px">
                            <TableWithPagination
                                heading={PricingTablePropertyheading(t)}
                                rows={propertyRow}
                                path={PricingtablePropertypath}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                onClick={() => console.log("")}
                                tableType="no-side"
                                handleIcon={handleIcon}
                                dataType={[
                                    { type: ["text"], name: "propertyNo" },
                                    { type: ["text"], name: "propertyName" },
                                    { type: ["text"], name: "companyName" },
                                    { type: ["description"], name: "location" },
                                    { type: ["text"], name: "units" },
                                    { type: ["propertyType"], name: "propertyType" },
                                    { type: ["status"], name: "status" },
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={propertyList?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Grid>
                    </Grid>
                </>}
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={drawerclose}
                    defaultState={defaultFilterState}
                    components={[
                        {
                            component: "select",
                            value: filterData?.property_type,
                            options: [],
                            isMulti: true,
                            label: t("Property Type"),
                            placeholder: t("Select Property Type"),
                            state_name: "property_type",
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                  "queries/property_type/get",
                                  {
                                    "company_id": selectedCompany?.value,
                                    "is_active": [true]
                                  },
                                  search,
                                  array,
                                  handleLoading,
                                  "property_group_master",
                                  {},
                                  
                                ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: t('Active'), value: true },
                                { label: t('Inactive'), value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                            // required:true
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
        </div>
    </div>
}
export default withNamespaces("unitVacancyPropertyList")(UnitVacancy);
