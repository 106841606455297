import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, SearchFilter, TableWithPagination, UseDebounce } from "../../../components";
import { Routes } from "../../../router/routes";
import { BlockHeadingViewNew, BlockPathView } from "../../../utils/block";
import makeStyles from '@mui/styles/makeStyles';
import { getCustomBlockName, NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",


    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem"
    },
    loading: {
        marginTop: "20%"
    }
}))
export const Blocks = (props) => {
    const {t}=props;
    const classes = useStyles();
    const history = useHistory()
    const search = useLocation().search;
    const [loading, setLoading] = React.useState(true)
    const [blocklist, setBlockList] = React.useState({
        count: 0,
        data: [],
        property: {}
    })
    const [searchText, setSearchText] = React.useState("");
    const propertyId = new URLSearchParams(search).get("propertyID");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const debounce = UseDebounce();

    const getblocklist = (propertyId, active, searchText, offSet, limits) => {
        const payload = {
            property_id: propertyId,
            is_active: active,
            search: searchText,
            offset: offSet,
            limit: limits,
        };

        NetworkCall(
            `${config?.api_url}/queries/block/by_property`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {

            const list = res?.data?.data?.block?.map((val) => {

                let _d;
                try {
                    _d = {
                        blockNo: val?.block_no,
                        blockName: val?.name,
                        description: val?.description,
                        propertyID: val?.property_id,
                        propertyName: val?.property?.name,
                        companyID: val?.property?.company?.id,
                        companyname: val?.property?.company?.name,
                        location: val?.property?.address?.city ? val?.property?.address?.city : val?.property?.address?.district,
                        floors: val?.floor?.length,
                        units: val?.unit?.length,
                        id: val?.id,
                        image: val?.logo,
                    };
                } catch (err) {

                }
                return _d;
            })
            setBlockList({
                count: res?.data?.data?.count[0]?.count,
                data: list,
                property: res?.data?.data?.property?.[0]
            })
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    React.useEffect(() => {
        getblocklist(propertyId, [true], searchText, 0, limit)
        // eslint-disable-next-line
    }, [])
    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push(Routes.blockView + "?companyID=" + data?.companyID + "&blockID=" + data?.id + "&name=" + data?.blockName)
        }
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getblocklist(propertyId, [true], e, 0, limit)
    }

    const handleOnClickAddPropertyButton = () => {
        history.push({ pathname: Routes.createBlockNew, state: { main: { propertyID: propertyId } } })
    }
    const onUnitClick = (value, type) => {
        if (type === "floor") {
            history.push(Routes.floor + "?blockID=" + value?.id)
        } else if (type === "unit") {
            history.push(Routes.unit + "?blockID=" + value?.id)
        }
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getblocklist(propertyId, [true], searchText, offset, limit)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getblocklist(propertyId, [true], searchText, 0, value)
    }

    return (
        <>
            {
                loading ?
                    <LoadingSection bottom={"45vh"}
                        message={"Loading " + getCustomBlockName(blocklist?.property?.property_hierarchyByID) + " Listing..."} />
                    :
                    <div className={classes.root}>
                        <Box p={2} m={2} className={classes.table}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText}
                                        placeholder={"Search " + getCustomBlockName(blocklist?.property?.property_hierarchyByID)}
                                        handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >
                                            {blocklist?.property?.property_hierarchyByID?.is_block &&
                                                <Button variant="contained" className={classes.btn} onClick={handleOnClickAddPropertyButton}>
                                                    {"Add " + getCustomBlockName(blocklist?.property?.property_hierarchyByID)}
                                                </Button>}
                                        </Stack>
                                    </Box>
                                </Grid>

                            </Grid>
                            <TableWithPagination
                                heading={BlockHeadingViewNew(blocklist?.property?.property_hierarchyByID,t)}
                                rows={blocklist?.data}
                                path={BlockPathView}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                onUnitClick={onUnitClick}
                                dataType={[
                                    { type: ["avatarmanagement"], name: "image" },
                                    { type: ["text"], name: "blockNo" },
                                    { type: ["text"], name: "blockName" },
                                    { type: ["quill"], name: "description" },
                                    { type: ["floor"], name: "floors" },
                                    { type: ["unit"], name: "units" },

                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={blocklist?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box>
                    </div>
            }
        </>
    );
};
