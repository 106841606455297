import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { InspectionUnitDataType, InspectionUnitHeading, InspectionUnitPath, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew, useWindowDimensions } from "../../utils";
import PropertyDetailsCard from "./components/propertyDetailsCard";
import InspectionMappingTable from "./components/table";
import { InspectionMappingStyles } from "./styles";

const MapPropertyDetails = ({
    t
}) => {
    const classes = InspectionMappingStyles();
    const size = useWindowDimensions()
    const history = useHistory();
    const { state } = useLocation();
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext);
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(true)
    const [page, setPage] = React.useState(1);
    const [permissions, setPermission] = React.useState({})
    const [limit, setLimit] = React.useState(10);
    const [itemList, setItemList] = React.useState({
        details: {},
        list: [],
        count: 0,
    })

    //get list
    const getPropertyDetails = (searchText, offset, limits) => {
        setLoading(true)
        const payload = {
            "id": state?.id,
            "search": searchText,
            "offset": offset,
            "limit": limits
        }
        NetworkCall(
            `${config.api_url}/queries/property/unit_info`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const data = response?.data?.data?.property?.[0]

            setItemList({
                details: data,
                list: data?.unit?.map((val) => {
                    return {
                        ...val,
                        unitCategory: val?.unitCategory?.name,
                        propertyName: data?.propertyName
                    }
                }),
                count: data.count?.[0]?.count
            })
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })


    }

    //initial load
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getPropertyDetails("", 0, 10)
            }
        }

        // eslint-disable-next-line
    }, [auth])
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPropertyDetails("", offset, limit)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPropertyDetails("", 0, value)
    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getPropertyDetails(e, 0, limit)
    }


    const handleIcon = (data) => {
        history.push({
            pathname: Routes.mapUnitDetails,
            state: {
                id: data?.id,
                property_id: state?.id,
                data: data,
                propertyName: itemList?.details?.propertyName,
                company:state?.company
            }
        })
    }

    const render = () => {
        return (
            <Box>
                <Subheader goBack={() => history.goBack()} title={itemList?.details?.propertyName} />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "16px" }}>
                            <PropertyDetailsCard title={t("propertyDetails")}
                                data={itemList?.details}
                                t={t}

                            />
                            <Box className={`${classes.root}`}>

                                <InspectionMappingTable
                                    placeholder={t("searchUnits")}
                                    onClick={handleIcon}
                                    heading={InspectionUnitHeading(t)}
                                    path={InspectionUnitPath}
                                    dataType={InspectionUnitDataType}
                                    searchText={searchText}
                                    handleSearch={handleSearch}
                                    handleChangeLimit={handleChangeLimit}
                                    handlePagination={handlePagination}
                                    page={page}
                                    list={itemList}
                                    limit={limit}
                                    height={`calc(100vh - 432px)`}
                                />
                            </Box>
                        </div>
                }

            </Box>
        )
    }

    return (
        <>
            {accessCheckRender(render, permissions)}
        </>
    )
}
const props = {
    boxShadow: false
}

export default withNamespaces("inspection")(withNavBars(MapPropertyDetails, props))
