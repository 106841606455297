import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, SearchFilter, TableWithPagination } from "../../../components";
import { UnitheadingViewNew, UnitpathView } from "../../../utils/block";
import makeStyles from '@mui/styles/makeStyles';
import { Routes } from "../../../router/routes";
import { getPropertyHierarchyUnitRule, NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        // height: `calc(100vh - 147px)`,
        overflow: "hidden",


    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem"
    },
    loading: {
        marginTop: "20%"
    }
}))
export const Units = (props) => {
    const { t } = (props);
    const classes = useStyles();
    const search = useLocation().search;
    const history = useHistory()
    const [unitlist, setUnitList] = React.useState({
        data: [],
        count: 0
    })
    const [searchText, setSearchText] = React.useState("");
    const floorID = new URLSearchParams(search).get("floorID");
    const propertyID = new URLSearchParams(search).get("propertyID");
    const blockID = new URLSearchParams(search).get("blockID");
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true)
    const [limit, setLimit] = React.useState(10);

    const getunitlist = (id, active, searchText, offSet, limits) => {
        const payload = {
            property_id: propertyID, block_id: blockID, floor_id: floorID,
            search: searchText, status: active,
            offset: offSet, limit: limits,
        };

        NetworkCall(
            `${config?.api_url}/queries/unit/list`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => {

            const list = res?.data?.data?.unit?.map((val) => {
                let _d;
                try {
                    _d = {
                        id: val?.id,
                        unitNo: val?.unit_no,
                        unitName: val?.name,
                        unitcategory: val?.category?.name,
                        revenuetype: val?.revenue_type,
                        propertyName: val?.property?.name,
                        unitpurpose: val?.unit_purpose,
                        description: val?.description,
                        image: val?.logo,
                        blockName: val?.block?.name,
                        floorName: val?.floor?.name,
                        totalarea: `${val?.total_area}  ${val?.uomByID?.name ?? ""}`,
                        category: val?.unit_categoryByID?.name ?? "-",
                        companyName: val?.property?.company?.name,
                        location: val?.property?.address?.area ? val?.property?.address?.area : " - ",
                        status: val?.is_active ? "Active" : "Inactive",
                    };
                } catch (err) {

                }
                return _d;
            })
            if (propertyID) {
                setUnitList({
                    count: res?.data?.data?.count,
                    data: list,
                    property: res?.data?.data?.property[0]
                });
            } else if (blockID) {
                setUnitList({
                    count: res?.data?.data?.count,
                    data: list,
                    property: res?.data?.data?.block[0]?.property
                });
            } else {
                setUnitList({
                    count: res?.data?.data?.count,
                    data: list,
                    property: res?.data?.data?.floor[0]?.property
                });
            }
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    React.useEffect(() => {
        getunitlist(propertyID ? propertyID : blockID ? blockID : floorID, [true], "", 0, limit)
        // eslint-disable-next-line
    }, [])
    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push(`/unitDetails?id=${data?.id}`);
        }
    }
    const handleSearch = (e) => {
        setSearchText(e)
        getunitlist(propertyID ? propertyID : blockID ? blockID : floorID, [true], searchText, 0, limit)
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getunitlist(propertyID ? propertyID : blockID ? blockID : floorID, [true], searchText, offset, limit)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getunitlist(propertyID ? propertyID : blockID ? blockID : floorID, [true], searchText, 0, value)
    }
    const handleOnClickAddPropertyButton = () => {
        history.push({
            pathname: Routes.createUnitNew,
            state: {
                main: {
                    floorID,
                    blockID,
                    propertyID
                }
            }
        })
    }
    return (
        <>
            {
                loading ? <LoadingSection bottom={"45vh"} message={t("Loading Unit Listing...")} /> :

                    <div className={classes.root}>

                        <Box p={2} className={classes.table} m={2}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText} placeholder={t("Search Unit")} handleChange={(value) => handleSearch(value)} />
                                </Grid >
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >
                                            {
                                                getPropertyHierarchyUnitRule(
                                                    unitlist?.property?.property_hierarchyByID,
                                                    unitlist?.count
                                                ) &&
                                                <Button variant="contained" className={classes.btn} onClick={handleOnClickAddPropertyButton}>
                                                    {t("Add Unit")}
                                                </Button >
                                            }
                                        </Stack >
                                    </Box >
                                </Grid >
                            </Grid >
                            <TableWithPagination
                                heading={UnitheadingViewNew(unitlist?.property?.property_hierarchyByID, t)}
                                rows={unitlist?.data}
                                path={UnitpathView}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                dataType={[
                                    { type: ["avatarmanagement"], name: "image" },
                                    { type: ["text"], name: "unitName" },
                                    { type: ["quill"], name: "description" },
                                    { type: ["text"], name: "blockName" },
                                    { type: ["text"], name: "floorName" },
                                    { type: ["text"], name: "unitNo" },
                                    { type: ["text"], name: "category" },
                                    { type: ["text"], name: "totalarea" },
                                    { type: ["status"], name: "status" },
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={unitlist?.count}
                                page={page}
                                limit={limit}
                                height={`calc(100vh - 300px)`}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box >
                    </div >
            }
        </>
    );
};
