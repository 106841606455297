
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Divider, Grid, Popover, Stack, Tab, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { TransferLead } from '../../assets';
import ShortListIMG from '../../assets/shortlistImg';
import { AlertDialog, Subheader } from "../../components";
import { AlertContext, AuthContext } from '../../contexts';
import { BackendRoutes, Routes } from '../../router/routes';
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew, useWindowDimensions } from '../../utils';
import { returnShortlistGoData } from '../../utils/oppertunityUtils';
import { CloseOppertunity, TransferOppertunity } from "./component";
import { useStyles } from "./styles";
import { Activity, Attachments, Details, Quotation, Todo } from "./tab";
import { withNamespaces } from 'react-i18next';
import { ActivityComments } from '../leadnew/components/activityComments';
import { QuickViewDrawerStyles } from '../leadnew/components/style';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
const OppertunityView = ({
    t
}) => {
    const comment_limit = 10;
    const size = useWindowDimensions();
    const classes = useStyles()
    const activityCommentsClasses = QuickViewDrawerStyles({ size });
    const history = useHistory()
    const { state } = useLocation();
    const oppertunity_id = state?.id
    const proxyLeadID = state?.opportunity?.proxy_lead_id;
    const [details, setDetails] = React.useState({})
    const [openTransfer, setOpenTransfer] = React.useState(false)
    const [openClose, setOpenClose] = React.useState(false)
    const [value, setValue] = React.useState(state?.activeTab??"1")
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const [shortListedCount, setShortListedCount] = React.useState("")
    const [comment_state, set_comment_state] = useState({ rows: [], total_rows_count: 0, });
    const [is_initial_comment_loading, set_is_initial_comment_loading] = useState(true);
    const [is_add_comment_loading, set_is_add_comment_loading] = useState(false);
    const [add_comment_state, set_add_comment_state] = useState("");
    const addCommentRef = useRef(null);
    const [comment_offset, set_comment_offset] = useState(0);
    const alert = useContext(AlertContext);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [isTeam, setIsTeam] = useState({
        data:false,
        loading:false,
        type:""
      });

    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'simple-popover' : undefined;
    const [reload, setReload] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.only("xs"))

    const handleClose1 = () => {
        setAnchorEl1(null);
      };
    

    const loader = {
        initial: {
            true: () => set_is_initial_comment_loading(true),
            false: () => set_is_initial_comment_loading(false),
        },
        addingComment: {
            true: () => set_is_add_comment_loading(true),
            false: () => {
                set_is_add_comment_loading(false);
                set_add_comment_state("");
            },
        },
    }

    useEffect(() => {
        if (addCommentRef?.current &&
            value === "6" &&
            is_add_comment_loading) {
            addCommentRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_add_comment_loading, addCommentRef])

    const getInitialData = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getCommentsData({}),
                ]);
            } catch (error) {
                reject(error);
            }
        })
    }

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (oppertunity_id && oppertunity_id?.length > 0) {
                getInitialData();
                getOppertunityDetails()
            }
        }
        // eslint-disable-next-line
    }, [auth, oppertunity_id]);

    //onChange tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === "6") {
            const offset = 0;
            set_comment_offset(offset);
            getCommentsData({ offset });
        }
    };
    //go shortlist
    const goShortlisted = () => {
        history.push({
            pathname: Routes.shortlistedoppertunityunits,
            state: returnShortlistGoData(details)
        })
    }
    //close oppertunity
    const onCloseoppertunity = () => {
        setOpenClose(false)
        history.push(Routes.leads)
    }
    //close transfer
    const onCloseTransferoppertunity = () => {
        setOpenTransfer(false)
    }
    const isRead = (details?.status === 3 || details?.status === 1) ? true : false

    const getCommentsData = ({
        offset = comment_offset,
        limit = comment_limit,
        oppertunityID = oppertunity_id,
        proxy_lead_id = proxyLeadID,
        load_type = "initial",
    }) => {
        loader?.[load_type]?.true();

        let payload = { offset, limit, id: oppertunityID, proxy_lead_id };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.opportunity_get_comments}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_row = [];

            if (load_type === "loadMore") {
                temp_row = response?.data?.data?.list
                    ? [
                        ...comment_state?.rows,
                        ...response?.data?.data?.list,
                    ]
                    : comment_state?.rows;
            } else {
                temp_row = response?.data?.data?.list ?? [];
            }

            set_comment_state({
                rows: temp_row,
                total_rows_count: response?.data?.data?.total ?? 0,
            })

            loader?.[load_type]?.false();
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })

            loader?.[load_type]?.false();
        });
    }

    const fetchMoreComments = () => {
        if (comment_state?.rows?.length < comment_state?.total_rows_count) {
            const offset = comment_offset + comment_limit;
            set_comment_offset(offset);
            getCommentsData({ offset, load_type: "loadMore" });
        }
    }

    const onCommentSubmit = (e) => {
        e.preventDefault();
        addComment();
    }

    const addComment = async () => {
        loader?.addingComment?.true();

        let payload = {
            id: oppertunity_id,
            comment: add_comment_state,
            created_at: new Date().toISOString(),
        };

        await NetworkCall(
            `${config?.api_url}${BackendRoutes?.opportunity_upsert_comments}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })
        });

        const offset = 0;
        set_comment_offset(offset);

        getCommentsData({ offset, load_type: "addingComment" });
    }


    const getTeams = (event) => {
        setIsTeam({
         ...isTeam,
         loading: false,
         data: state?.team,
         type: state?.team
           ? "against_team"
           : "to_an_individual",
        });
        setAnchorEl1(event);
    };
    
    const statusType1 = {
        "to_an_individual": "To an Individual",
        "against_team": "Against Team"
    }
    
    const clickType = (x) => {
        handleClose1();
        setIsTeam({
          ...isTeam,
          type: x,
        })
        setOpenTransfer(true)
    }
    
    const getOppertunityDetails = async() => {
        const data = await NetworkCall(
            `${config?.api_url}/queries/get_lead_details`,
            NetWorkCallMethods.post,
            {
              id: oppertunity_id,
            },
            null,
            true,
            false
          )
          const units = data?.data?.data?.unit?.map((c)=>{
            return {
                id:c?.id,
                unit_id:c?.unitByID?.id
            }
        })
          if (data?.data?.data?.[0]?.contact_account?.id) {
            const payload = {
                account_id: data?.data?.data?.[0]?.contact_account?.id,
                email: data?.data?.data?.[0]?.contact_account?.email_id,
                is_existing: true,
                company_id: data?.data?.data?.[0]?.contact_account?.company_id
            };

            await NetworkCall(
                `${config?.api_url}/contact/check_primary`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    setDetails({ ...data?.data?.data,shortlisted:units, is_existing: res?.data?.data?.is_existing, primary: res?.data?.data?.primary , lead_details:data?.data?.data })
                    let count = units?.filter((v, i, a) => a.findIndex(v2 => (v2.unit_id === v.unit_id)) === i)
                    setShortListedCount(count?.length)
                    setLoading(false)

                })
                .catch((err) => {
                    console.log(err, 'err')
                });
        } else {
            setDetails({...data?.data?.data ,shortlisted:units, lead_details:data?.data?.data})
            // remove duplicate unit from shortlisited array for show the count for shortlisted button
            let count = units?.filter((v, i, a) => a.findIndex(v2 => (v2.unit_id === v.unit_id)) === i)
            setShortListedCount(count?.length)
            // end
            setLoading(false)
        }
    }


    const render = () => {
        return <>
            <Subheader title={t("View Opportunity")} goBack={() => history.goBack()} />
            <Box className={classes.root}>
                <TabContext value={value}>
                    <TabList onChange={handleChange} variant='scrollable'>
                        <Tab label={t("Opportunity")} value="1" />
                        <Tab label={t("Activity")} value="2" />
                        <Tab label={t("To do List")} value="3" />
                        <Tab label={t("Quotation")} value="4" />
                        <Tab label={t("Attachments")} value="5" />
                        <Tab label={t("Comments")} value="6" />
                    </TabList>
                    <Divider />
                    <TabPanel value="1" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Details t={t} permission={permission} oppertunity_id={oppertunity_id} setDetails={setDetails} details={details} disabled={isRead} setShortListedCount={setShortListedCount} reload={reload} loading={loading} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Activity t={t} permission={permission} oppertunity_id={oppertunity_id} selectedCompany={state?.value} disabled={isRead} details={details} />
                        </Box >
                    </TabPanel >
                    <TabPanel value="3" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Todo t={t} permission={permission} oppertunity_id={oppertunity_id} disabled={isRead} />
                        </Box >
                    </TabPanel >
                    <TabPanel value="4" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Quotation t={t} permission={permission} oppertunity_id={oppertunity_id} />
                        </Box >
                    </TabPanel >
                    <TabPanel value="5" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Attachments t={t} id={state?.id}/>
                        </Box >
                    </TabPanel >
                    <TabPanel value="6" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            {ActivityComments({
                                t, classes: activityCommentsClasses, size,
                                miniLeadCardHeight: 147,
                                comments: comment_state,
                                is_initial_comment_loading,
                                is_add_comment_loading,
                                fetchMoreComments,
                                is_add_comment_enabled: state?.opportunity?.is_activity_comment_enabled,
                                add_comment_state,
                                set_add_comment_state,
                                onCommentSubmit,
                                addCommentRef,
                            })}
                        </Box >
                    </TabPanel>
                </TabContext >
            </Box >
            {/*transfer oppertunity */}
            < AlertDialog
                onClose={() => setOpenTransfer(false)}
                open={openTransfer}
                header={t("Transfer Oppertunity")}
                component={<TransferOppertunity t={t} oppertunity_id={oppertunity_id} onClose={onCloseTransferoppertunity} company_id={state?.company?.value} onTransferClose={() => setOpenTransfer(false)} user_id={details?.lead_owner} team={state?.team} type={isTeam?.type} setReload={setReload} reload={getOppertunityDetails} />}
                isNormal={true}
            />

            {/*close oppertunity */}
            < AlertDialog
                onClose={() => setOpenClose(false)}
                open={openClose}
                header={t("Close Oppertunity")}
                component={<CloseOppertunity t={t} oppertunity_id={oppertunity_id} onClose={onCloseoppertunity} />}
                isNormal={true}
            />


            <Grid container className={classes.btnRoot} justifyContent="space-between" spacing={isXs && 1}>
                <Grid item xs={12} sm={6}>
                    <Stack direction="row" >
                        {permission?.update &&
                            <Button startIcon={<TransferLead style={{marginInlineEnd:"8px"}} />} className={classes.iconBtn} variant='contained' onClick={(e) => getTeams(e?.currentTarget)} disabled={isRead}> {t("Transfer Opportunity")}</Button>}
                        {permission?.create &&
                            <Button sx={{marginInlineStart:"16px"}} startIcon={<ShortListIMG style={{marginInlineEnd:"8px"}} color="#8887D4" />} onClick={goShortlisted} disabled={isRead ? true : details?.shortlisted?.length > 0 ? false : true} className={classes.iconBtn} variant='contained'>&nbsp;&nbsp;{shortListedCount} {t("Units Shortlisted")}</Button>}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={2} justifyContent={!isXs && "flex-end"}>
                        {permission?.update &&
                            <Button className={classes.closeBtn} variant='outlined' fullWidth={isXs} onClick={() => setOpenClose(true)} disabled={isRead}>{t("Close The Opportunity")}</Button>}
                        {/* <Button className={classes.requsetBtn} variant='contained'>Request For Reserve</Button> */}
                    </Stack>
                </Grid>
            </Grid>

            
            <Popover
          id={id1}
          open={open1}
          anchorEl={anchorEl1}
          onClose={handleClose1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.menuList}
        >
          {["to_an_individual", "against_team"]?.map((x, index) => {
            return (
              <Typography
                className={classes.menuItem1}
                onClick={() => clickType(x)}
              >
                {statusType1[x]}
              </Typography>
            );
          })}
        </Popover>


        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withNamespaces("leads")(OppertunityView);
