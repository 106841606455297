import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Box, Stack, Tooltip } from "@mui/material"
import { ProjectContext } from '../projectContext'
import { CustomSelectBox } from '../../../components/customSelectBox'
import { SpecificationTableStyle } from './style'
import { DeleteIcon } from '../../../assets'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const SpecificationTable = ({
    t = () => false
}) => {
    const header = [t("Item Type"), t("Item Category"), t("Sub-Category"), t("Specification Title"), ""]
    const {
        specificationTable,
        addSpecificationTable,
        enumValue,
        deleteSpecificationTableRow,
        updateSpecificationTable,
        setData,
        data
    } = React.useContext(ProjectContext)

    const classes = SpecificationTableStyle()

    const subCategoryManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    };

    // const itemMasterManualResponse = (array) => {
    //     const details = array?.map((i) => {
    //         return {
    //             label: i?.name,
    //             value: i?.id,
    //             ...i
    //         }
    //     })
    //     return details
    // };


    return (
        <Box p={2}>
            <TableContainer className={classes.table}>
                <Table>
                    <TableHead className={classes.head}>
                        <TableRow>
                            {
                                header?.map((e) => {
                                    return (
                                        <TableCell className={classes.headText}>{e}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            specificationTable?.map((e, i) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.bodyCell}>
                                            <CustomSelectBox
                                                value={e?.itemType}
                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                options={enumValue?.inspection_item_type}
                                                onChange={(val) => {
                                                    updateSpecificationTable("itemType", val, e)
                                                }}
                                                placeholder={t("Select Item Type")}
                                            // isReadOnly={stateData?.type === "view"}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <CustomSelectBox
                                                value={e?.itemCategory}
                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                onChange={(val) => {
                                                    updateSpecificationTable("itemCategory", val, e)
                                                }}
                                                placeholder={t("Select Item Category")}
                                                isReadOnly={specificationTable?.[i]?.itemType === ""}
                                                networkCallData={
                                                    {
                                                        path: "inspection_item_category",
                                                        payload: {
                                                            item_type: [specificationTable?.[i]?.itemType?.value]
                                                        },
                                                        mappingVariable: "list",
                                                    }
                                                }
                                                key={JSON.stringify(specificationTable?.[i]?.itemType)}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Stack direction="row" alignItems={"center"} columnGap={"4px"}>
                                                <Box sx={{ minWidth: "150px" }}>
                                                    <CustomSelectBox
                                                        value={e?.subCategory}
                                                        menuOptionPadding={"0px 0px 8px 0px"}
                                                        onChange={(val) => {
                                                            updateSpecificationTable("subCategory", val, e)
                                                        }}
                                                        placeholder={t("Select Sub Category")}
                                                        // isReadOnly={stateData?.type === "view"}
                                                        networkCallData={
                                                            {
                                                                path: "inspection_item_subcategory/get",
                                                                payload: {
                                                                    item_type: [specificationTable?.[i]?.itemType?.value],
                                                                    is_active: [true]
                                                                },
                                                                mappingVariable: "data",
                                                                manualResponse: subCategoryManualResponse
                                                            }
                                                        }
                                                        key={JSON.stringify(specificationTable?.[i]?.itemType)}
                                                    />
                                                </Box>

                                                {
                                                    (e?.subCategoryError?.length > 0) &&
                                                    <Tooltip arrow title={e?.subCategoryError ?? ""} placement="top">
                                                        <Box m="4px 0px 0px 4px">
                                                            <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                        </Box>
                                                    </Tooltip>
                                                }
                                            </Stack>
                                        </TableCell>
                                        {/* <TableCell className={classes.bodyCell}>
                                            <Stack direction="row" alignItems={"center"} columnGap={"4px"}>
                                                <Box sx={{ minWidth: "150px" }}>
                                                    <CustomSelectBox
                                                        value={e?.itemMaster}
                                                        menuOptionPadding={"0px 0px 8px 0px"}
                                                        onChange={(val) => {
                                                            updateSpecificationTable("itemMaster", val, e)
                                                        }}
                                                        placeholder={t("Select Item Master")}
                                                        // isReadOnly={stateData?.type === "view"}
                                                        networkCallData={
                                                            {
                                                                path: "free_text_invoice/get_free_text_item_types",
                                                                payload: {
                                                                    company_id: selectedCompany?.value,
                                                                    item_type: "Item Master",
                                                                    item_master_type: specificationTable?.[i]?.itemType?.value,
                                                                    item_master_category: specificationTable?.[i]?.itemCategory?.value,
                                                                    subcategory_id: specificationTable?.[i]?.subCategory?.value,
                                                                    component_type: "item_based"
                                                                },
                                                                mappingVariable: "items",
                                                                manualResponse: itemMasterManualResponse
                                                            }
                                                        }
                                                        key={JSON.stringify(specificationTable?.[i]?.itemType || specificationTable?.[i]?.itemCategory)}
                                                    />
                                                </Box>

                                            </Stack>
                                        </TableCell> */}

                                        <TableCell className={classes.bodyCell}>
                                            <Typography className={classes.viewTitle}
                                                onClick={() => e?.subCategory?.specification && setData({
                                                    ...data,
                                                    viewTitle: true,
                                                    selectedSpecTitle: e?.subCategory?.specification ? JSON.parse(e?.subCategory?.specification) : ""
                                                })}
                                            >{e?.subCategory?.specification ? t("View Title") : "-"}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Box onClick={() => deleteSpecificationTableRow(e)} sx={{ cursor: "pointer" }}><DeleteIcon /></Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography className={classes.viewText} mt={"12px"} onClick={() => addSpecificationTable()}>{t("Add Specification")}</Typography>
        </Box>
    )
}
