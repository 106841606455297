import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "13px",
        height: '40px',
        width: "40px"
    },
    createTitle: {
        fontSize: remCalc(16),
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    resourceCountBox: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F6F1FE",
        padding: "8px"
    },
    resourcetext: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.primary,
        marginTop: "4px !important"
    },
    resourceCount: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary,
        marginTop: "4px !important"
    },
    optionHeader: {
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    resourceGrpName: {
        fontSize: remCalc(16),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    statusBox: {
        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    statusText: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: "#FFFFFF"
    },
    detailsBox: {
        backgroundColor: "#F5F7FA",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius
    },
    job_count: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    job_hdr: {
        fontSize: remCalc(14),
        color: theme.typography.color.secondary,
        fontFamily: Regular,
    },
    rolebox: {
        backgroundColor: "#F5F7FA",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
        padding: "4px 8px"
    },
    roleText: {
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    menuItem: {
        padding: "0px 8px !important",
        backgroundColor: "#FFFFFF !important",
        "&:hover": {
            backgroundColor: "#FFFFFF !important",
            boxShadow:"none"
        }
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: "4px",
            backgroundColor: "white",
        },
    },
    memberhdr: {
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    memberBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
        border: "1px solid #E4E8EE",
        padding: "8px"
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center"
    },
    resourceName: {
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    jobName: {
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: Regular,
    },
    dot: {
        width: "6px",
        height: "6px",
        backgroundColor: "#98A0AC",
        borderRadius: "50%"
    },
    resourceImg: {
        borderRadius: theme.palette.borderRadius,
        width: "32px",
        height: "32px"
    },
    selectResourcetxt: {
        textTransform: "uppercase",
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    selectionBox: {
        backgroundColor: "#F5F7FA",
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "8px"
    },
    tabSwitchBlock: {
        backgroundColor: "#E4E8EE",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    tabSelecttxt: {
        fontSize: remCalc(12),
        textAlign: "center",
        fontFamily: SemiBold,
    },
    selectedTabBox:{
        boxShadow:"0px 0px 16px #00000014",
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#FFFFFF",
        padding:"4px"
    },
    unSelectedTabBox:{
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#E4E8EE",
        padding:"4px"
    }
}))