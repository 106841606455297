import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Avatar, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { MobileNumberInputComponent, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { componantsStyles } from "./styles";
export const AccountForm = ({ onClose = () => false, disableAcc = false, updateStateAcc = () => false, onSubmitAccount = () => false, account = {} }) => {
    const classes = componantsStyles()
    const size = useWindowDimensions()
    const [loading, setLoading] = React.useState(false)
    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result

        switch (type) {

            case 'currency':

                result = await networkCallBack('queries/currency_master/list',{});
                return {
                    options: [...result?.currency_master],
                    hasMore: (array?.length + result?.currency_master?.length) < (result?.count[0]?.count??result?.count)
                }
            case 'vat_group':
                result = await networkCallBack('queries/vat_group_master/list',{});
                return {
                    options: [...result?.vat_group_master],
                    hasMore: (array?.length + result?.vat_group_master?.length) < (result?.count[0]?.count??result?.count)
                }

            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (endpoint, payload = {}) => {


        const options = await NetworkCall(
            `${config.api_url}/${endpoint}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch(() => {
                setLoading(null);

                return null
            });

        return options
    }

    return (
        <div>
            {/*Dialog heading*/}
            <Box display="flex" alignItems="center" p={1} className={classes.titleroot}>
                <Box flexGrow={1}>
                    <Typography className={classes.titleText}>Create New Account</Typography>
                </Box>
                <Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box p={2} height={size?.height - 256} overflow="auto">
                <center>

                    {
                        account?.url ?
                            <div className={classes.imgDiv}>
                                <img src={URL.createObjectURL(account?.url?.[0])} alt="" style={{ objectFit: "cover", height: "94px", width: "94px", borderRadius: "50%" }} />
                                <IconButton onClick={() => updateStateAcc("url", "")} className={classes.close} size="small">
                                    <CloseIcon style={{ fontSize:"1.125rem", color: "white" }} />
                                </IconButton>
                            </div>
                            :
                            <IconButton className={classes.profileimg}>
                                <PersonOutlineIcon className={classes.profileicon} />
                                <label htmlFor="uplodebtn">
                                    <Avatar
                                        sx={{ width: 24, height: 24 }}
                                        className={classes.profileimgs}
                                        type="file"
                                    >
                                        <img
                                            src="/images/craeateprofilequtation.svg"
                                            alt="-"
                                            className={classes.profileicons}
                                        />
                                    </Avatar>
                                </label>
                                <input
                                    type="file"
                                    name="img"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="uplodebtn"
                                    onChange={(value) => {
                                        updateStateAcc("url", value.target.files)
                                    }

                                    }
                                />
                            </IconButton>
                    }
                </center>
                <Box height="12px" />
                <TextBox
                    value={account?.name}
                    onChange={(e) => updateStateAcc("name", e.target.value)}
                    label="Account Name"
                    placeholder="Enter Account Name"
                    isError={account?.error?.name?.length > 0}
                    errorMessage={account?.error?.name}
                    isrequired
                />
                <Box height="12px" />
                <MobileNumberInputComponent
                    label="Mobile Number"
                    xs={3.3}
                    value={account?.mobile}
                    handleChange={(value) => updateStateAcc("mobile", value)}
                    isError={account?.error?.mobile?.length > 0}
                    errorMessage={account?.error?.mobile}
                    isRequired
                />
                <Box height="12px" />
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <TextBox
                            value={account?.email}
                            onChange={(e) => updateStateAcc("email", e.target.value)}
                            label="Email ID" placeholder="Enter Email ID"
                            isError={account?.error?.email?.length > 0}
                            errorMessage={account?.error?.email}
                            isrequired
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectBox
                            isReadOnly
                            label="Currency"
                            value={account?.currency}
                            onChange={(e) => updateStateAcc("currency", e)}
                            isError={account?.error?.currency?.length > 0}
                            errorMessage={account?.error?.currency}
                            isRequired
                            isPaginate
                            loadOptions={(search, array) => loadOptions(search, array, 'currency')}
                            loading={loading === "currency"}
                            debounceTimeout={800}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectBox
                            isReadOnly
                            label="Tax Group"
                            value={account?.tax}
                            onChange={(e) => updateStateAcc("tax", e)}
                            isError={account?.error?.tax?.length > 0}
                            errorMessage={account?.error?.tax}
                            isRequired
                            isPaginate
                            loadOptions={(search, array) => loadOptions(search, array, 'vat_group')}
                            loading={loading === "vat_group"}
                            debounceTimeout={800}
                        />
                    </Grid>
                </Grid>

            </Box>
            <Box p={2}>
                <Button disabled={disableAcc} fullWidth onClick={onSubmitAccount} className={classes.btn} variant="contained">Create New Account</Button>
            </Box>
        </div>
    )
}