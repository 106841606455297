import React from "react";
import { withNavBars } from "../../HOCs";
import WorkspaceManagerDashboardComponent from "./dashboard";
const WorkspaceManagerDashboardParent = (props) => {
    return <WorkspaceManagerDashboardComponent {...props} />;
};

const EnhancedWorkspaceManagerDashboardParent = withNavBars(
    WorkspaceManagerDashboardParent
);

const WorkspaceManagerDashboard = (props) => {

    return <EnhancedWorkspaceManagerDashboardParent {...props} boxShadow={false} />;
};

export default WorkspaceManagerDashboard;