import { Avatar, Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material"
import moment from "moment"
import React from "react"
import { ContractIcon } from "../../../assets/contractIcon"
import NoDataFound from "../../../assets/noData"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods, stringAvatar, useWindowDimensions } from "../../../utils"
import BillingContactIcon from "../../residentOnboarding/utils/billingContactIcon"
import { ViewContractIcon } from "../assets/viewContractIcon"
import { useStyles } from "../style"
import { ChooseCard } from "./chooseCard"
import { loadOptionsApis } from "../utils"

export const ContractDetails = ({ state = {}, t = () => false, data = {}, isView = false, updateState = () => false,setLoading=()=>false }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const matches = useMediaQuery('(min-width:900px)')
    const getContractDetails = (val) => {
        const payload = {
            company_id: state?.company?.value,
            account_id: val?.value
        }
        NetworkCall(
            `${config.api_url}/preventive_plan/contract_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.data?.map((x) => {
                return {
                    ...x,
                    scope: x?.scope?.map((e) => {
                        return {
                            ...e,
                            is_selected: data?.selected_properties?.map((i) => {
                                if (i?.id === e?.property?.id) {
                                    return {
                                        ...i,
                                        is_selected: true
                                    }
                                }
                                else {
                                    return i
                                }
                            })
                        }
                    })
                }
            })
            updateState("contract_details", data?.selected_properties?.length > 0 ? { data: result } : response?.data?.data, "contract_account", val)
        }).catch((err) => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        if (data?.contract_account?.value) {
            getContractDetails(data?.contract_account)
        }
        //eslint-disable-next-line
    }, [data?.contract_account?.value])
    const manualResponse = (array) => {
        const details = {
            data:array?.data?.map((i) => {
                return {
                    ...i,
                    label: i?.label,
                    value: i?.value
                }
            }),
            count:array?.count
        }
        return details
    };
    const handleChange = (value) => {
        // updateState("contract_account", value)
        getContractDetails(value)
    }
    return (
        <Box>
            <Box sx={{padding:"16px 16px 0px 16px"}}>
                <Typography className={classes.planTitle}>{t("Plan Based On")}</Typography>
            </Box>
            <Box sx={{ height: !matches ? "auto" : (isView ? size?.height - 260 : size?.height - 350), overflow: "scroll", padding: matches ? "0px 16px 0px 16px" : "16px", borderTop: !matches ? "1px solid #E4E8EE" : "none" }} >
                <Box className={classes.contractDetailsCard} mt={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ContractIcon />
                        <Typography className={classes.title}>{t("Associate Account ,Contract & Projects")}</Typography>
                    </Stack>
                    {isView ?
                        <>
                            {data?.contract_details?.account?.label?.length > 0 ?
                                <Box className={classes.viewContractCard} mt={2}>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <BillingContactIcon />
                                            <Box>
                                                <Typography className={classes.resourceTitle}>{t("Contract Account & Contract")}</Typography>
                                                <Typography className={classes.examples}>{t("Account and contract details")}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Box mt={1} className={classes.viewAccountDetails}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Avatar src={data?.contract_details?.account?.logo} variant="square" sx={{ backgroundColor: "#6DAFB3", borderRadius: "4px", width: "36px", height: "36px" }}   {...stringAvatar(data?.contract_details?.account?.label)} />
                                            <Box>
                                                <Typography className={classes.title}>{data?.contract_details?.account?.label}</Typography>
                                                <Typography className={classes.examples}>{data?.contract_details?.account?.account_no}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Box mt={1} className={classes.viewContractDetails}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box className={classes.viewcontractIcon} display={"flex"} justifyContent={"center"} alignItems="center">
                                                <ViewContractIcon />
                                            </Box>
                                            <Box>
                                                <Typography className={classes.title}>{data?.contract_details?.contract_no}</Typography>
                                                <Typography className={classes.examples}>{`Contract period from ${moment(data?.contract_details?.start_date).format("DD MMM YYYY")} to ${moment(data?.contract_details?.end_date).format("DD MMM YYYY")}`}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Box mt={2} mb={2}>
                                        <Divider />
                                    </Box>
                                    {data?.selected_properties?.length > 0 && data?.selected_properties?.map((x) => {
                                        return (
                                            <>
                                                <Typography className={classes.title}>{t("Associated Properties")}
                                                </Typography>
                                                <Box mt={2}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar src={x?.logo} variant="square" sx={{ backgroundColor: "#6DAFB3", borderRadius: "4px", width: "36px", height: "36px" }} />
                                                        <Box>
                                                            <Typography className={classes.title}>{x?.name}</Typography>
                                                            {(x?.address?.city !== null || x?.city !== null) && (x?.address?.city?.length > 0 || x?.city?.length > 0) && (x?.address?.city !== "" || x?.city !== "") &&
                                                                <Typography className={classes.examples}>{x?.address?.city ?? x?.city},{x?.address?.country ?? x?.country}</Typography>
                                                            }
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </>
                                        )
                                    })}
                                </Box>
                                :
                                <Box display="flex" justifyContent={"center"}>
                                    <NoDataFound />
                                </Box>
                            }
                        </>
                        :
                        <>
                            <Box mt={1}>
                                <ChooseCard
                                    title={t('Contract Account')}
                                    subTitle={t('Choose Contract account')}
                                    noSelectedTitle={t("No Account selected to display")}
                                    icon={<BillingContactIcon />}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            `queries/account`,
                                            {
                                                "company_id": state?.company?.value,
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResponse

                                        )}
                                    onchange={(value) => handleChange(value)}
                                    topHeader={t("Choose The Contract Account")}
                                    isActive={true}
                                    isBorder={true}
                                    menuPlacement={"auto"}
                                    onDelete={() => updateState("contract_details", [], "contract_account", null, "selected_properties", [])}
                                    value={data?.contract_account}
                                    data={data}
                                    updateState={updateState}
                                />
                            </Box>
                            {/* if multi contract */}
                            {/* <Box mt={1}>
                                <ChooseCard
                                    title='Contract'
                                    subTitle='Choose Active contracts from vendor account'
                                    noSelectedTitle="No Account selected to display"
                                    icon={<BillingContactIcon />}
                                    options={[]}
                                    topHeader="Choose The Contract"
                                    isActive={true}
                                    isBorder={true}
                                    menuPlacement={"auto"}
                                    value={data?.contract_account}
                                />
                            </Box> */}
                        </>
                    }

                </Box>
            </Box>
        </Box>
    )
}