import React from 'react'
import { Label, SelectBox } from '../../../components'
import { Box, Stack, Typography } from "@mui/material"
import { TypeSelectList } from '../utils'
import { ProjectStyle } from '../style'
import { ProjectContext } from '../projectContext'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import moment from 'moment'

export const TypeSelect = () => {
    const classes = ProjectStyle()
    const {
        createData,
        setCreateData,
        selectedCompany,
        updateCreateData,
        enumValue
    } = React.useContext(ProjectContext)

    const [selected, setSelected] = React.useState(1)

    const handleChangeTab = (value) => {
        setSelected(value?.id)
        setCreateData({
            ...createData,
            type: {
                ...createData?.type,
                selected: value?.value,
                project: "",
                contractAccount: "",
                contract: "",
                error: {
                    project: "",
                    contractAccount: "",
                    contract: "",
                }
            }
        })
    }

    const updateState = (key, value) => {
        setCreateData({
            ...createData,
            type: {
                ...createData?.type,
                [key]: value,
                error: {
                    ...createData?.type?.error,
                    [key]: ""
                }
            }
        })
    }

    const manualResponceProject = (array) => {
        const result = array?.data?.map((e) => {
            return {
                ...e,
                label: e?.title,
                value: e?.id
            }
        })
        return result
    }

    const manualResponceContractAccount = (array) => {
        const result = array?.map((e) => {
            return {
                ...e,
                label: e?.name,
                value: e?.id
            }
        })
        return result
    }

    const manualResponceContract = (array) => {
        const result = array?.data?.map((e) => {
            return {
                ...e,
                label: e?.contract_no,
                value: e?.id,
            }
        })
        return result
    }

    React.useEffect(() => {
        setSelected(createData?.type?.selected === "project" ? 1 : createData?.type?.selected === "contract" ? 2 : 3)
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <Label isRequired label={"Type"} />

            <Box className={classes.typeBox}>
                {
                    TypeSelectList(enumValue?.project_ref_type)?.map((e, i) => {
                        return (
                            <Box
                                sx={{ borderBottom: (i === 0 || i === 1) && "1px solid #CED3DD" }}
                                className={selected === e?.id && classes.selectedBg}
                                p={2}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"} onClick={() => handleChangeTab(e)}>
                                    {selected === e?.id ? e?.activeIcon : e?.inactiveIcon}
                                    <Stack spacing={"4px"}>
                                        <Typography className={classes.typeTitle}>{e?.title}</Typography>
                                        <Typography className={classes.menuSubTitle}>{e?.subTitle}</Typography>
                                    </Stack>
                                </Stack>

                                {
                                    (selected === e?.id && selected !== 3) &&
                                    <Stack spacing={2} mt={2}>
                                        <SelectBox
                                            value={selected === 1 ? createData?.type?.project : createData?.type?.contractAccount}
                                            noSearch={false}
                                            color={"#fff"}
                                            placeholder="Search"
                                            label={selected === 1 ? '' : "Contract Account"}
                                            customOptionComponent={(props) => {
                                                return (
                                                    <Stack direction={"row"} spacing={2} m={1} p={1} alignItems={"center"} className={classes.selectParent}
                                                        style={{
                                                            border: (selected === 1 ? createData?.type?.project?.value === props?.data?.value : createData?.type?.contractAccount?.value === props?.data?.value) ? "1px solid #5078E1" : "1px solid #E4E8EE",
                                                            backgroundColor: (selected === 1 ? createData?.type?.project?.value === props?.data?.value : createData?.type?.contractAccount?.value === props?.data?.value) ? "#F1F7FF" : "#fff",
                                                            borderRadius: "4px"
                                                        }}>
                                                        <Stack>
                                                            <Typography className={classes.optionHeader}>{props?.data?.label} {selected === 1 ? `(${props?.data?.project_no})` : `(${props?.data?.account_no})`}</Typography>
                                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                <Typography className={classes.optionSubTitle}>{selected === 1 ? `${moment(props?.data?.planned_start_date).format("DD MMM YY")} - ${moment(props?.data?.planned_end_date).format("DD MMM YY")}` : props?.data?.email_id ?? ""}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                )
                                            }}
                                            isPaginate={true}
                                            onChange={(value) => updateCreateData(selected === 1 ? "project" : "contractAccount", value, true)}
                                            loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                    selected === 1 ? "project/list" : "queries/opportunity/contact_account",
                                                    {
                                                        company_id: selectedCompany?.value,
                                                        status: selected === 1 ? [true] : undefined
                                                    },
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    "data",
                                                    {},
                                                    selected === 1 ? manualResponceProject : manualResponceContractAccount
                                                )
                                            }
                                            isError={selected === 1 ? createData?.type?.error?.project?.length > 0 : createData?.type?.error?.contractAccount?.length > 0}
                                            errorMessage={selected === 1 ? createData?.type?.error?.project : createData?.type?.error?.contractAccount}
                                        />
                                        <>
                                            {
                                                selected === 2 &&
                                                <SelectBox
                                                    value={createData?.type?.contract}
                                                    isPaginate={true}
                                                    noSearch={false}
                                                    color={"#fff"}
                                                    placeholder="Search"
                                                    label='Contract'
                                                    customOptionComponent={(props) => {
                                                        return (
                                                            <Stack direction={"row"} spacing={2} m={1} p={1} alignItems={"center"} className={classes.selectParent}
                                                                style={{
                                                                    border: (props?.isSelected) ? "1px solid #5078E1" : "1px solid #E4E8EE",
                                                                    backgroundColor: (props?.isSelected) ? "#F1F7FF" : "#fff"
                                                                }}>
                                                                <Stack>
                                                                    <Typography className={classes.optionHeader}>{props?.data?.label}</Typography>
                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                        <Typography className={classes.optionSubTitle}>{`${props?.data?.start_date ? moment(props?.data?.start_date).format("DD MMM YY") : ""} - ${props?.data?.end_date ? moment(props?.data?.end_date).format("DD MMM YY") : ""}`}</Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            </Stack>
                                                        )
                                                    }}
                                                    onChange={(value) => updateState("contract", value, true)}
                                                    loadOptions={(search, array, handleLoading) =>
                                                        loadOptionsApis(
                                                            "project/contract_by_account",
                                                            {
                                                                account_id: [createData?.type?.contractAccount?.value]
                                                            },
                                                            search,
                                                            array,
                                                            handleLoading,
                                                            "data",
                                                            {},
                                                            manualResponceContract
                                                        )
                                                    }
                                                    key={JSON.stringify(createData?.type?.contractAccount)}
                                                    isReadOnly={createData?.type?.contractAccount === ""}
                                                    isError={createData?.type?.error?.contract?.length > 0}
                                                    errorMessage={createData?.type?.error?.contract}
                                                />
                                            }
                                        </>
                                    </Stack>
                                }
                            </Box>
                        )
                    })
                }

            </Box>

        </>
    )
}