import Grid from "@mui/material/Grid";
import React from 'react';
import { SelectBox, TextBox } from '..';

export const MapFieldsOnly = (props) => {

    const {
        fields = [],
        onChangeFields = null,
        loadOptions = false,
        loading = false,
        breakpoints = {},
        isPaginate = false,
        debounceTimeout = false
    } = props;

    const switchComponents = (val) => {
        switch (val?.component) {
            case 'TextField':
                return (
                    <TextBox
                        isrequired={val?.isrequired ?? false}
                        label={val?.label ?? ''}
                        placeholder={val?.placeholder ?? ''}
                        value={val?.value ?? ""}
                        onChange={(e) => onChangeFields(val?.state_name, e.target.value)}
                        isError={val?.error ?? false}
                        errorMessage={val?.errorMessage ?? ''}
                    />
                )
            case 'Select':
                return (
                    <SelectBox
                        isRequired={val?.isrequired ?? false}
                        label={val?.label ?? ''}
                        placeholder={val?.placeholder ?? ''}
                        options={val?.options ?? []}
                        value={val?.value ?? null}
                        onChange={(value) => onChangeFields(val?.state_name, value)}
                        isError={val?.error ?? false}
                        errorMessage={val?.errorMessage ?? ''}
                        loadOptions={loadOptions}
                        loading={loading}
                        debounceTimeout={debounceTimeout}
                        isPaginate={isPaginate}

                    />
                )
                case 'SelectBox':
                    return (
                        <SelectBox
                                                            label={val?.label}
                                                            placeholder={val?.placeholder}
                                                            value={val?.value ?? null}
                                                            isPaginate
                                                            debounceTimeout={800}
                                                            loading={val?.loading2 === "country"}
                                                            loadOptions={val?.loadOptions}
                                                            onChange={(value) => onChangeFields(val?.state_name, value)}
                                                            />
                    )
            default:
                return null
        }
    }
    return (
        <div>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid
                    item
                    xs={breakpoints?.components?.xs ? breakpoints?.components?.xs : 12}
                    sm={breakpoints?.components?.sm ? breakpoints?.components?.sm : 12}
                    md={breakpoints?.components?.md ? breakpoints?.components?.md : 12}
                >
                    <Grid container rowSpacing={1} columnSpacing={3}>
                        {
                            fields?.length && fields?.map(_ => (
                                <Grid item xs={12} sm={_?.breakpoints?.sm ?? 12} md={_?.breakpoints?.md ?? 4} lg={_?.breakpoints?.lg ?? 2} >
                                    {switchComponents(_)}
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid >
            </Grid >
        </div >
    )
}