import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InfoIcon from "../../../assets/infoIcon";
import { ProgressTextFeild, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  NetWorkCallMethods,
  enumName,
  enumSelect,
  enum_types,
  max,
  min,
  rec,
} from "../../../utils";
import { ChargeableList } from "../../../utils/pricingComponent";
import { LightTooltip, useStylesForm } from "../style";

export const Form = (props) => {
  const { t } = (props)
  // styles
  const classes = useStylesForm();
  const alert = useContext(AlertContext);
  //   states
  const [enumValue, setEnumValue] = useState({
    revenue_type: [],
    unit_breakup_type: [],
    primary_unit_breakup_type: [],
    secondary_unit_breakup_type: [],
    refundable_unit_breakup_type: [],
    parking_unit_breakup_type: [],
    pricing_period_type: [],
    item_based_pricing_period_type: [],
  });
  const [loading, setLoading] = useState(false);
  const [addNewState, setAddNewState] = useState({
    ...props?.details,
  });
  const [disable, setDisable] = useState(addNewState?.is_read ? true : false)
  const [isDisableBtn,setIsDisableBtn]=React.useState(false)
  //   ------------------------------------functions------------------------------------------
  // Function for updating addNewState
  const updateAddNewState = (k, v) => {
    let error = addNewState?.error;
    error[k] = "";
    if (k === "revenueType") {
      setAddNewState({ ...addNewState, [k]: v, pricingComponent: "", error });
    } else if (k === "valueBasis") {
      setAddNewState({
        ...addNewState,
        [k]: v,
        value: "",
        quantity: "",
        error,
      });
    } else {
      setAddNewState({ ...addNewState, [k]: v, error });
    }
  };
  // Function to load Async Select Options
  const loadOptions = async (search = "", array, type) => {
    setLoading(type);
    let result,
      offset,
      companyID,
      countryID,
      revenueType,
      categoryQuestionSet = 0;
    companyID = props?.company?.value;
    countryID = 77;
    revenueType = addNewState?.revenueType?.value;
    categoryQuestionSet = addNewState?.categoryQuestionSet;
    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "pricing_component":
        const payload = {
          search: search,
          is_active: true,
          revenue_type: revenueType,
          primary: categoryQuestionSet?.isPrimary,
          is_onetime: categoryQuestionSet?.isOneTime,
          refundable: categoryQuestionSet?.isRefundable,
          is_parking: categoryQuestionSet?.isParking,
          is_wallet: categoryQuestionSet?.is_wallet,
          is_late_fee: categoryQuestionSet?.is_late_fee,
          is_quantity: categoryQuestionSet?.isQuantity,
          is_commission_payment: categoryQuestionSet?.is_commission_payment,
          company_id: companyID,
          offset: offset,
          limit: 10
        }
        result = await networkCallBack('queries/pricing_component/get_dropdown', payload);
        return {
          options: [...result?.pricing_component],
          hasMore:
            array?.length + result?.pricing_component?.length <
            result?.count[0]?.count,
        };
      case "vat_group_master":
        const variables = {
          country_id: countryID,
          offset: offset,
          limit: 10,
          search: search
        }
        result = await networkCallBack('queries/pricing_component/get_tax_group', variables);
        return {
          options: [...result?.vat_group_master],
          hasMore:
            array?.length + result?.vat_group_master?.length <
            result?.count[0]?.count,
        };;
      default:
        return { options: [] };
    }
  };
  // Function for network callback for Async Select Options
  const networkCallBack = async (endPoint, payload = {}) => {

    const options = await NetworkCall(
      `${config.api_url}/${endPoint}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main;
      })
      .catch(() => {
        setLoading(null);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        return null;
      });

    return options;
  };
  //get Enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.revenue_type,
      enum_types.unit_breakup_type,
      enum_types.pricing_period_type,
    ]);
    let primary_unit_breakup_type = [],
      secondary_unit_breakup_type = [];
    let parking_unit_breakup_type = [],
      refundable_unit_breakup_type = [];
    let item_based_pricing_period_type = [];
    for (let i = 0; i < result.unit_breakup_type.length; i++) {
      if (
        result.unit_breakup_type[i].value !== enumName.rentalValue &&
        result.unit_breakup_type[i].value !== enumName.percentage
      ) {
        primary_unit_breakup_type = [
          ...primary_unit_breakup_type,
          result.unit_breakup_type[i],
        ];
      }
      if (result.unit_breakup_type[i].value !== enumName.rentalValue) {
        secondary_unit_breakup_type = [
          ...secondary_unit_breakup_type,
          result.unit_breakup_type[i],
        ];
        parking_unit_breakup_type = [
          ...parking_unit_breakup_type,
          result.unit_breakup_type[i],
        ];
      }
      if (result.unit_breakup_type[i].value !== enumName.perUOM) {
        refundable_unit_breakup_type = [
          ...refundable_unit_breakup_type,
          result.unit_breakup_type[i],
        ];
      }
    }
    for (let i = 0; i < result.pricing_period_type.length; i++) {
      if (result.pricing_period_type[i].value !== enumName.daily) {
        item_based_pricing_period_type = [
          ...item_based_pricing_period_type,
          result.pricing_period_type[i],
        ];
      }
    }
    setEnumValue({
      revenue_type: result?.revenue_type,
      unit_breakup_type: result?.unit_breakup_type,
      primary_unit_breakup_type: primary_unit_breakup_type,
      secondary_unit_breakup_type: secondary_unit_breakup_type,
      refundable_unit_breakup_type: refundable_unit_breakup_type,
      parking_unit_breakup_type: parking_unit_breakup_type,
      pricing_period_type: result?.pricing_period_type,
      item_based_pricing_period_type: item_based_pricing_period_type,
    });
    setAddNewState({
      ...addNewState,
      paymentPeriod: result?.pricing_period_type[0],
    });
  };
  //   useEffect
  useEffect(() => {
    if (props?.permission?.read) {
      getEnum();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data?.id, props?.permission]);

  // upsert rera
  const upsertRera = () => {
    setIsDisableBtn(true)
    const datas = {
      pricing_table_id: props?.details?.id ?? undefined,
      min: addNewState?.minValue ?? undefined,
      max: addNewState?.maxValue ?? undefined,
      recommended_value: addNewState?.recommanded ?? undefined,
    };
    if (addNewState?.reraID) {
      datas["id"] = addNewState?.reraID;
    }
    NetworkCall(
      `${config.api_url}/rental_index/upsert`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        props?.getListFunction();
        props?.onClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `${addNewState?.reraID ? t("Updated SuccessFully") : t("Created SuccessFully")}`,
        });
        setIsDisableBtn(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
        setIsDisableBtn(false)
      });
  };

  // edit
  const editFunction = () => {

    setDisable(false)
  }


  return (
    <div className={classes.addNewDialogFormContent}>
      <div
        className={classes.popUpParent}
        style={{ height: props?.size, }}
      >
        <div
          className={classes.addQuestionCard2}
          style={{ backgroundColor: addNewState?.secondaryColor }}
        >
          <Box className={classes.addQuestionContent1}>
            <Typography
              className={classes.addNewDialogQuestionText}
              style={{ color: addNewState?.primaryColor }}
            >
              {t(addNewState?.category)}
            </Typography>
          </Box>
          <Box className={classes.addQuestionContent2}>
            <LightTooltip title={t(addNewState?.info)} placement="top">
              <Box className={classes.addNewDialogInfoIcon2}>
                <InfoIcon />
              </Box>
            </LightTooltip>
          </Box>
        </div>
        <Box height={"24px"} />
        <Grid container justifyContent={"space-between"} spacing={"16px"}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ProgressTextFeild
                  label={t("Maximum")}
                  color="error"
                  startAdornment={props?.company?.currency_symbol ?? ""}
                  value={addNewState?.maxValue ?? ""}
                  onChange={(e) =>
                    updateAddNewState("maxValue", e.target.value)
                  }
                  bottomLabel={
                    addNewState?.valueBasis?.value === "Amount"
                      ? t("amount/Monthly")
                      : `${props?.company?.uom?.label}/Monthly`
                  }
                  progressValue={
                    addNewState?.maxValue !== ""
                      ? max(
                        addNewState?.value,
                        addNewState?.valueBasis?.value === "Amount"
                          ? addNewState?.maxValue
                          : addNewState?.maxValue * addNewState?.total_area
                      )

                      : 0
                  }
                  isReadonly={disable}
                />
              </Grid>
              <Grid item xs={4}>
                <ProgressTextFeild
                  label={t("Recommended")}
                  color="success"
                  startAdornment={props?.company?.currency_symbol ?? ""}
                  value={addNewState?.recommanded ?? ""}
                  onChange={(e) =>
                    updateAddNewState("recommanded", e.target.value)
                  }
                  bottomLabel={
                    addNewState?.valueBasis?.value === "Amount"
                      ? t("amount/Monthly")
                      : `${props?.company?.uom?.label}/Monthly`
                  }
                  progressValue={
                    addNewState?.maxValue > addNewState?.recommanded &&
                      addNewState?.recommanded !== ""
                      ? rec(
                        addNewState?.maxValue,
                        addNewState?.valueBasis?.value === "Amount"
                          ? addNewState?.recommanded
                          : addNewState?.recommanded * addNewState?.total_area
                      )

                      : 0
                  }
                  isReadonly={disable}
                />
              </Grid>
              <Grid item xs={4}>
                <ProgressTextFeild
                  label={t("Minimum")}
                  color="warning"
                  startAdornment={props?.company?.currency_symbol ?? ""}
                  value={addNewState?.minValue ?? ""}
                  onChange={(e) =>
                    updateAddNewState("minValue", e.target.value)
                  }
                  bottomLabel={
                    addNewState?.valueBasis?.value === "Amount"
                      ? t("amount/Monthly")
                      : `${props?.company?.uom?.label}/Monthly`
                  }
                  progressValue={
                    addNewState?.maxValue > addNewState?.minValue &&
                      addNewState?.minValue !== ""
                      ? min(
                        addNewState?.maxValue,
                        addNewState?.valueBasis?.value === "Amount"
                          ? addNewState?.minValue
                          : addNewState?.minValue * addNewState?.total_area
                      )

                      : 0
                  }
                  isReadonly={disable}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.addNewDialogFormFieldLabel} noWrap>
              {t("Revenue Type")}
            </Typography>
            <Grid container rowGap={"8px"} columnGap={"8px"}>
              {enumValue?.revenue_type?.map((value) => {
                return (
                  <Button
                    className={
                      value?.value === addNewState?.revenueType?.value
                        ? classes.addNewDialogFormButtonSelected
                        : classes.addNewDialogFormButtonUnSelected
                    }
                  >
                    <Typography
                      className={
                        value?.value === addNewState?.revenueType?.value
                          ? classes.addNewDialogFormButtonTextSelected
                          : classes.addNewDialogFormButtonTextUnSelected
                      }
                    >
                      {t(value?.label)}
                    </Typography>
                  </Button>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectBox
              key={addNewState?.revenueType?.value}
              isRequired
              color={"#FFF"}
              label={t("Pricing Component")}
              placeholder={t("Select Component")}
              value={addNewState?.pricingComponent}
              onChange={(value) => updateAddNewState("pricingComponent", value)}
              loading={loading === "pricing_component"}
              isPaginate
              isReadOnly={true}
              debounceTimeout={800}
              loadOptions={(search, array) =>
                loadOptions(search, array, "pricing_component")
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectBox
              color={"#FFF"}
              isRequired={
                addNewState?.pricingComponent?.taxtable ? true : false
              }
              label={t("Tax Group For Pricing Component")}
              placeholder={
                addNewState?.pricingComponent?.taxtable
                  ? t("Select Group")
                  : t("No Tax Applicable")
              }
              isReadOnly={true}
              value={addNewState?.taxGroup}
              onChange={(value) => updateAddNewState("taxGroup", value)}
              loading={loading === "vat_group_master"}
              isPaginate
              debounceTimeout={800}
              loadOptions={(search, array) =>
                loadOptions(search, array, "vat_group_master")
              }
            />
          </Grid>
          {(addNewState?.category === "Primary Pricing Component" ||
            addNewState?.category === "Secondary Components" ||
            addNewState?.category === "Parking Slot Component") && (
              <>
                <Grid item xs={6}>
                  <Typography
                    className={classes.addNewDialogFormFieldLabel}
                    noWrap
                  >
                    {t("Component Based On")}
                  </Typography>
                  <Grid container rowGap={"8px"} columnGap={"8px"}>
                    {(addNewState?.category === "Primary Pricing Component"
                      ? enumValue?.primary_unit_breakup_type
                      : addNewState?.category === "Secondary Components"
                        ? enumValue?.secondary_unit_breakup_type
                        : addNewState?.category === "Parking Slot Component"
                          ? enumValue?.parking_unit_breakup_type
                          : enumValue?.unit_breakup_type
                    )?.map((value) => {
                      return (
                        <Button
                          className={
                            value?.value === addNewState?.valueBasis?.value
                              ? classes.addNewDialogFormButtonSelected
                              : classes.addNewDialogFormButtonUnSelected
                          }
                        // onClick={() => updateAddNewState("valueBasis", value)}
                        >
                          <Typography
                            className={
                              value?.value === addNewState?.valueBasis?.value
                                ? classes.addNewDialogFormButtonTextSelected
                                : classes.addNewDialogFormButtonTextUnSelected
                            }
                          >
                            {t(value?.label)}
                          </Typography>
                        </Button>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    }}
                    isrequired
                    isReadonly={true}
                    label={t(addNewState?.valueBasis?.label) + t("Value")}
                    placeholder={t("Enter Value")}
                    value={addNewState?.value ?? ""}
                    onChange={(e) => updateAddNewState("value", e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        {addNewState?.valueBasis?.value === "Amount"
                          ? addNewState?.currencyCode +
                          " / " +
                          addNewState?.paymentPeriod?.label
                          : addNewState?.valueBasis?.value === "Percentage"
                            ? "% / " + addNewState?.paymentPeriod?.label
                            : addNewState?.valueBasis?.value === "Per UOM"
                              ? addNewState?.uom +
                              " / " +
                              addNewState?.paymentPeriod?.label
                              : ""}
                      </InputAdornment>
                    }
                  />
                </Grid>
              </>
            )}
          {addNewState?.category === "Refundable Price Component" && (
            <>
              <Grid item xs={6}>
                <Typography
                  className={classes.addNewDialogFormFieldLabel}
                  noWrap
                >
                  {t("Component Based On")}
                </Typography>
                <Grid container rowGap={"8px"} columnGap={"8px"}>
                  {enumValue?.refundable_unit_breakup_type?.map((value) => {
                    return (
                      <Button
                        className={
                          value?.value === addNewState?.valueBasis?.value
                            ? classes.addNewDialogFormButtonSelected
                            : classes.addNewDialogFormButtonUnSelected
                        }
                      // onClick={() => updateAddNewState("valueBasis", value)}
                      >
                        <Typography
                          className={
                            value?.value === addNewState?.valueBasis?.value
                              ? classes.addNewDialogFormButtonTextSelected
                              : classes.addNewDialogFormButtonTextUnSelected
                          }
                        >
                          {t(value?.label)}
                        </Typography>
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  type="number"
                  onKeyPress={(e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  isrequired
                  label={
                    addNewState?.valueBasis?.value === "Rental Value"
                      ? t("Quantity")
                      : t(addNewState?.valueBasis?.label) + t("Value")
                  }
                  placeholder={
                    t("Enter") +
                    (addNewState?.valueBasis?.value === "Rental Value"
                      ? t("Quanity")
                      : t("Value"))
                  }
                  value={
                    (addNewState?.valueBasis?.value === "Rental Value"
                      ? addNewState?.quantity
                      : addNewState?.value) ?? ""
                  }
                  onChange={(e) =>
                    updateAddNewState(
                      addNewState?.valueBasis?.value === "Rental Value"
                        ? "quantity"
                        : "value",
                      e.target.value
                    )
                  }
                  isReadOnly={true}
                  endAdornment={
                    <InputAdornment position="end">
                      {addNewState?.valueBasis?.value === "Amount"
                        ? addNewState?.currencyCode +
                        " / " +
                        addNewState?.paymentPeriod?.label
                        : addNewState?.valueBasis?.value === "Per UOM"
                          ? addNewState?.uom +
                          " / " +
                          addNewState?.paymentPeriod?.label
                          : addNewState?.valueBasis?.value === "Percentage"
                            ? "% of " + addNewState?.paymentPeriod?.label
                            : addNewState?.valueBasis?.value === "Rental Value"
                              ? "Qty"
                              : ""}

                    </InputAdornment>
                  }
                />
              </Grid>
            </>
          )}
          {addNewState?.isChargeable && (
            <Grid item xs={12} md={6}>
              <Typography className={classes.addNewDialogFormFieldLabel} noWrap>
                {t("Chargeable")}
              </Typography>
              <Grid container rowGap={"8px"} columnGap={"8px"}>
                {ChargeableList.map((value) => {
                  return (
                    <Button
                      className={
                        value?.value === addNewState?.chargeable?.value
                          ? classes.addNewDialogFormButtonSelected
                          : classes.addNewDialogFormButtonUnSelected
                      }
                    // onClick={() => updateAddNewState("chargeable", value)}
                    >
                      <Typography
                        className={
                          value?.value === addNewState?.chargeable?.value
                            ? classes.addNewDialogFormButtonTextSelected
                            : classes.addNewDialogFormButtonTextUnSelected
                        }
                      >
                        {t(value?.label)}
                      </Typography>
                    </Button>
                  );
                })}
              </Grid>
            </Grid>
          )}

          {addNewState?.category === "One Time Charges" && (
            <Grid item xs={6}>
              <TextBox
                type="number"
                onKeyPress={(e) => {
                  if (e.key === "e") {
                    e.preventDefault();
                  }
                }}
                isReadOnly={true}
                isrequired
                label={t("One Time Charges Amount")}
                placeholder={t("Enter Amount")}
                value={addNewState?.value ?? ""}
                onChange={(e) => updateAddNewState("value", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {addNewState?.currencyCode}
                  </InputAdornment>
                }
              />
            </Grid>
          )}
          {addNewState?.category === "Item Based Component" && (
            <>
              <Grid item xs={12}>
                <Typography
                  className={classes.addNewDialogFormFieldLabel}
                  noWrap
                >
                  {t("Pricing Based On")}
                </Typography>
                <Grid container rowGap={"8px"} columnGap={"8px"}>
                  {enumValue?.item_based_pricing_period_type?.map((value) => {
                    return (
                      <Button
                        className={
                          value?.value === addNewState?.paymentPeriod?.value
                            ? classes.addNewDialogFormButtonSelected
                            : classes.addNewDialogFormButtonUnSelected
                        }
                      // onClick={() => updateAddNewState("paymentPeriod", value)}
                      >
                        <Typography
                          className={
                            value?.value === addNewState?.paymentPeriod?.value
                              ? classes.addNewDialogFormButtonTextSelected
                              : classes.addNewDialogFormButtonTextUnSelected
                          }
                        >
                          {t(value?.label)}
                        </Typography>
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextBox
                  type="number"
                  onKeyPress={(e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  isrequired
                  label={t("Item Unit Price")}
                  placeholder={t("Enter Price")}
                  value={addNewState?.value ?? ""}
                  isReadOnly={true}
                  onChange={(e) => updateAddNewState("value", e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      {addNewState?.currencyCode}
                    </InputAdornment>
                  }
                />
              </Grid >

              <Grid item xs={12} md={6}>
                <TextBox
                  type="number"
                  onKeyPress={(e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  isrequired
                  label={t("Quantity")}
                  isReadOnly={true}
                  placeholder={t("Enter Quanity")}
                  value={addNewState?.quantity ?? ""}
                  onChange={(e) =>
                    updateAddNewState("quantity", e.target.value)
                  }
                  endAdornment={
                    <InputAdornment position="end">{t("Qty")}</InputAdornment>
                  }
                />
              </Grid>
            </>
          )}
        </Grid >
      </div >
      <Box heigth={"10px"} />
      <Button
        className={classes.next}
        onClick={addNewState?.is_read && disable ? editFunction : upsertRera}
        disabled={isDisableBtn}
      >
        {addNewState?.reraID?.length > 0 ? (disable ? t("Edit") : t("Save")) : t("Save")}
      </Button>
    </div >
  );
};
