import { Avatar, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { LeadPreviewStyles as useStyles } from "./styles";
import { LocalStorageKeys, stringAvatar } from "../../../utils";
import { format } from "date-fns";
export const LeadPreview = () => {
  const classes = useStyles();
  const { dataNew, t = () => false } = React.useContext(CreateQuotationListContext);
  const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" className={classes.card}>
        <Grid item xs={12} md={6} lg={1.5} className={classes.rightBorder}>
          <div className={classes.imgdiv}>
            <Avatar
              src={dataNew?.image_url?.length > 0 ? dataNew?.image_url : null}
              className={classes.image}
              {...stringAvatar(dataNew?.name, 30 , "50%")}
            />
            <div className={classes.prority}>
              <center>
                <span className={classes.proritydata}>
                  {dataNew.priority}

                </span>
              </center>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={10.5} p={1}>
          <Typography className={classes.title}>
            {t("OPPORTUNITY DETAILS")}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>{t("Contact Name")}</Typography>
              <Typography className={classes.sub}> {dataNew.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>{t("Mobile")}</Typography>
              <Typography className={classes.sub}>
                {dataNew.mobile?.mobile_code}&nbsp; {dataNew.mobile?.mobile ?? '-'}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Alternative Mobile</Typography>
              <Typography className={classes.sub}>
                {dataNew.alter_mobile?.mobile_code}&nbsp; {dataNew.alter_mobile?.mobile ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Telephone</Typography>
              <Typography className={classes.sub}>
                {dataNew.telephone?.mobile_code}&nbsp; {dataNew.telephone?.mobile ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Fax</Typography>
              <Typography className={classes.sub}>
                {dataNew.fax?.mobile_code}&nbsp; {dataNew.fax?.mobile ?? "-"}
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>{t("Email Id")}</Typography>
              <Typography className={classes.sub}>
                {dataNew?.email_id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {dataNew?.revenue_type?.value !== "Sale" &&
        <>
          <Grid container className={classes.card} marginTop="12px" p={1}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{dataNew?.revenue_type?.value} Duration</Typography>
                <Typography className={classes.sub}>{dataNew?.duration?.value} {dataNew?.duration?.select}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{dataNew?.revenue_type?.value} Start And End Date</Typography>
                <Typography className={classes.sub}>{moment(dataNew?.lease_start_date).format("DD MMM YY")} to {moment(dataNew?.lease_end_date).format("DD MMM YY")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Source Type")}</Typography>
                <Typography className={classes.sub}>{dataNew?.source?.label ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Priority")}</Typography>
                <Typography className={classes.sub}>{dataNew?.priority ?? "-"}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Billing Cycle Method")}</Typography>
                <Typography className={classes.sub}>{dataNew?.bill_cycle_method ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Billing Cycle Date")}</Typography>
                <Typography className={classes.sub}>{dataNew?.bill_cycle_date === "As per the Agreement Date" ? "As per the Agreement Date" : dataNew?.custom_date}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Grace Period")}</Typography>
                <Typography className={classes.sub}>{dataNew?.grace_peroid ?? "-"} {t("Days")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Apply Grace Period")}</Typography>
                <Typography className={classes.sub}>{dataNew?.apply_grace_peroid ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Payment Period")}</Typography>
                <Typography className={classes.sub}>{dataNew?.payment_period?.value ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Prefered Payment Options")}</Typography>
                <Typography className={classes.sub}>{dataNew?.payment_option?.label ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Renewal Based On")}</Typography>
                <Typography className={classes.sub}>{dataNew?.renewal_based_on?.label ?? "-"}</Typography>
              </Grid>
              {
                dataNew?.renewal_based_on?.label === "Increment %" &&
                <Grid item xs={3}>
                  <Typography className={classes.heading}>{t("Increment Percentage")}</Typography>
                  <Typography className={classes.sub}>{`${dataNew?.esclation_percentage ?? 0}%`}</Typography>
                </Grid>
              }
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Auto-renewal")}</Typography>
                <Typography className={classes.sub}>{dataNew?.auto_renewal ? "Yes" : "No"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>Agreement Lock In Period</Typography>
                <Typography className={classes.sub}>{dataNew?.agreement_lock ? "Yes" : "No"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Wallet Credits Carry Over")}</Typography>
                <Typography className={classes.sub}>{dataNew?.wallet_carry_over ? "Yes" : "No"}</Typography>
              </Grid>
            </Grid>
          </Grid >

          <Grid container className={classes.card} marginTop="12px" p={1}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Company Name")}</Typography>
                <Typography className={classes.sub}>{dataNew?.company_name?.length ? dataNew?.company_name : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("City")}</Typography>
                <Typography className={classes.sub}>{dataNew?.city?.length ? dataNew?.city : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Country")}</Typography>
                <Typography className={classes.sub}>{dataNew?.country?.label ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Budget Amount")}</Typography>
                <Typography className={classes.sub}>{dataNew?.budget_amount?.length ? `${selectedCompany?.currency_symbol}${dataNew?.budget_amount}` : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Probability")}</Typography>
                <Typography className={classes.sub}>{dataNew?.probability?.length ? `${dataNew?.probability}%` : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Confirm Interest")}</Typography>
                <Typography className={classes.sub}>{dataNew?.confirm_interest ? "Yes" : "No"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Expected Close Period")}</Typography>
                <Typography className={classes.sub}>{dataNew?.expected_close_period ? format(new Date(dataNew?.expected_close_period), "dd MMM yy") : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Monthly Salary")}</Typography>
                <Typography className={classes.sub}>{dataNew?.monthly_salary ? `${selectedCompany?.currency_symbol}${dataNew?.monthly_salary}` : "-"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      {dataNew?.revenue_type?.value === "Sale" &&
        <>
          <Grid container className={classes.card} marginTop="12px" p={1}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Earliest Occupation Date")}</Typography>
                <Typography className={classes.sub}>{moment(dataNew?.earlist_occupation_date).format("DD MMM YY")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Contract Start Date")}</Typography>
                <Typography className={classes.sub}>{moment(dataNew?.contract_start_date).format("DD MMM YY")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Source Type")}</Typography>
                <Typography className={classes.sub}>{dataNew?.source?.label ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Priority")}</Typography>
                <Typography className={classes.sub}>{dataNew?.priority ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Payment Period")}</Typography>
                <Typography className={classes.sub}>{dataNew?.payment_period?.value ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Payment Options")}</Typography>
                <Typography className={classes.sub}>{dataNew?.payment_option?.value ?? "-"}</Typography>
              </Grid>
              {dataNew?.payment_period?.value === "Milestone Based" &&
                <Grid item xs={3}>

                  <Typography className={classes.heading}>{t("Delivery Mailstone Template")}</Typography>
                  <Typography className={classes.sub}>{dataNew?.mileStoneName}</Typography>

                </Grid>
              }
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Wallet Credits Carry Over")}</Typography>
                <Typography className={classes.sub}>{dataNew?.wallet_carry_over ? "Yes" : "No"}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.card} marginTop="12px" p={1}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Company Name")}</Typography>
                <Typography className={classes.sub}>{dataNew?.company_name?.length ? dataNew?.company_name : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("City")}</Typography>
                <Typography className={classes.sub}>{dataNew?.city?.length ? dataNew?.city : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Country")}</Typography>
                <Typography className={classes.sub}>{dataNew?.country?.label ?? "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Budget Amount")}</Typography>
                <Typography className={classes.sub}>{dataNew?.budget_amount?.length ? `${selectedCompany?.currency_symbol}${dataNew?.budget_amount}` : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Probability")}</Typography>
                <Typography className={classes.sub}>{dataNew?.probability?.length ? `${dataNew?.probability}%` : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Confirm Interest")}</Typography>
                <Typography className={classes.sub}>{dataNew?.confirm_interest ? "Yes" : "No"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Expected Close Period")}</Typography>
                <Typography className={classes.sub}>{dataNew?.expected_close_period ? format(new Date(dataNew?.expected_close_period), "dd MMM yy") : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.heading}>{t("Monthly Salary")}</Typography>
                <Typography className={classes.sub}>{dataNew?.monthly_salary ? `${selectedCompany?.currency_symbol}${dataNew?.monthly_salary}` : "-"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      }

    </div >
  );
};
