import { Badge, Box, Drawer, Grid, IconButton, Stack } from "@mui/material"
import moment from "moment"
import React from "react"
import { withNamespaces } from "react-i18next"
import { useHistory } from "react-router"
import FilterIMG from "../../assets/filter"
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { AlertProps, CostingApprovalDataType, CostingApprovalHeading, CostingApprovalPath, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { filterOptions } from "./components/common"
import { PreviewScreen } from "./components/preview"
import { CostingApprovalStyles } from "./style"

const CostingApproval = ({ t, handleLoading }) => {
    const classes = CostingApprovalStyles()
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [permission, setPermission] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("")
    const [viewData, setViewData] = React.useState([])
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        requestType: [filterOptions?.requestType?.[0]]
    })
    const [approvalStatus, setApprovalStatus] = React.useState("")
    const history = useHistory()
    const [list, setList] = React.useState({
        data: [],
        count: 0

    })
    const [itemDetails, setItemDetails] = React.useState([])
    const [timelineData, setTimelineData] = React.useState([])
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getCostingRequestList(0, 10, company?.selected?.value, "", filterData)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    //get costing list
    const getCostingRequestList = (offset = 0, limit = 10, company_id, search = "", filterData) => {
        const payload = {
            "type": filterData?.requestType?.map?.(i => i?.value)?.includes("All") ? undefined : filterData?.requestType?.map?.(i => i?.value),
            "company_id": company_id,
            "offset": offset,
            "limit": limit,
            "search": search,
            bom_type:"Actual"
        };
        NetworkCall(
            `${config.api_url}/request_costing/get_costing_status_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setLoading(false)
                const result = res?.data?.data?.data?.map((val, i) => {
                    return {
                        ...val,
                        unit_name: val?.source === "Maintenance" ? val?.maintenance_unit_name : val?.source === "General" ? val?.general_unit_name : val?.agreement_unit_name,
                        requested_on: moment(val?.requested_on).format("DD MMM YYYY ,hh:mm a"),
                        updated_on: moment(val?.updated_at).format("DD MMM YYYY , hh:mm a"),
                        comments: val?.comments !== "" ? val?.comments ?? "-" : "-",
                        request_id: val?.maintenance_request_no ?? val?.general_request_no ?? val?.agreement_no ?? "-",
                        requested_by: val?.requestedBy?.requested_by_first_name
                    }
                })
                setList({ data: result, count: res?.data?.data?.count })
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            });
    }

    //get timeline details
    const getTimelineFlow = (data) => {
        const payload = {
            id: data?.costing_request_id
        }

        NetworkCall(
            `${config.api_url}/request_costing/time_line`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setTimelineData(response?.data?.data)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //get Costing Item details
    const getCostingDetails = (data) => {
        const payload = {
            "request_id": data?.maintenance_request_id ?? data?.general_request_id,
            "type": data?.source,
            "bom_type":"Actual"
        }

        NetworkCall(
            `${config.api_url}/request_costing/get_costing`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setItemDetails(response?.data?.data?.[0]?.costing?.[0])

        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //get Final Settlement Details
    const getFinalSettlementDetails = (data) => {
        const payload = {
            "agreement_id": data?.agreement_id,
            "offset": 0,
            "limit": 10,
            "type": "AgreementInfo"
        }

        NetworkCall(
            `${config.api_url}/final_settlement/agreement_info`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setViewData({ ...response?.data?.data, source: data?.source, id: data?.agreement_id, ...data })
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getCostingRequestList(0, 10, value?.value, "", filterData)
    }
    //handle search
    const handleSearch = (value) => {
        setSearchText(value)
        getCostingRequestList(0, 10, selectedCompany?.value, value, filterData)
    }
    //handle Pagination
    const handlePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getCostingRequestList(offset, limit, selectedCompany?.value, "", filterData)
    }

    const handleChangeLimit = (value) => {
        setLimit(value)
        setPage(1)
        getCostingRequestList(0, value, selectedCompany?.value, "", filterData)
    }
    const handleIcon = (type, data) => {
        if (type === "view") {
            setOpenDrawer(true)
            if (data?.source !== "Final Settlement") {
                getCostingDetails(data)
                setViewData(data)
            }
            else {
                getFinalSettlementDetails(data)
            }
            setApprovalStatus(data?.status)
            getTimelineFlow(data)
        }
        else {
            if (data?.source !== "Final Settlement") {
                history.push({
                    pathname: Routes.maintanceRequestView,
                    state: {
                        main: {
                            value: data?.source === "Maintenance" ? data?.maintenance_request_id : data?.general_request_id,
                            maintenance_id: data?.source === "Maintenance" ? data?.maintenance_request_id : data?.general_request_id,
                            type: data?.source,
                            request: data?.source,
                            company: selectedCompany?.value,
                            companyData: selectedCompany
                        },
                    },
                });
            }
            else {
                history.push({
                    pathname: Routes.finalSettlementView,
                    state: {
                        data: data,
                        selectedCompany: selectedCompany
                    }
                })
            }
        }

    }
    const closeDrawer = () => {
        setOpenDrawer(false)
    }

    //Funtion to apply filter
    const applyFilter = (filter_data) => {
        setPage(1)
        setFilterData(filter_data)
        getCostingRequestList(0, limit, selectedCompany?.value, "", filter_data)

    }
    const reload = () => {
        getCostingRequestList(0, 10, selectedCompany?.value, "", filterData)
    }
    const render = () => {
        return (
            <Box>
                <Subheader hideBackButton={true} title={t("Requests For Approval")}
                    select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
                />
                <Box p={2}>
                    {
                        loading ?
                            <LoadingSection top="20vh" message={t("loading")} /> :
                            <Box className={classes.root}>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <SearchFilter value={searchText} placeholder={t("Search Request")}
                                            handleChange={(value) => handleSearch(value)} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box display={"flex"} justifyContent={"end"} onClick={() => setFilterDrawer(true)}>
                                            <Stack direction="row">

                                                <IconButton
                                                    className={classes.filterButton}>
                                                    <Badge variant="dot" color="primary">

                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableWithPagination
                                            heading={CostingApprovalHeading(t)}
                                            rows={list?.data}
                                            path={CostingApprovalPath}
                                            showpagination={true}
                                            showpdfbtn={false}
                                            showexcelbtn={false}
                                            showSearch={false}
                                            handleIcon={handleIcon}
                                            tableType="no-side"
                                            dataType={CostingApprovalDataType}
                                            handlePagination={handlePagination}
                                            handleChangeLimit={handleChangeLimit}
                                            totalRowsCount={list?.count}
                                            page={page}
                                            limit={limit}
                                            height={'calc(100vh - 300px)'}
                                            view={permission.read}
                                            edit={permission.update}
                                            delete={permission.delete} />
                                    </Grid>
                                </Grid>
                            </Box>
                    }
                </Box>
                <Drawer
                    anchor={"right"}
                    open={openDrawer}
                    onClose={() => closeDrawer()}
                >
                    <PreviewScreen t={t} setCloseDrawer={setOpenDrawer} data={viewData} reload={reload} approvalStatus={approvalStatus} timelineData={timelineData} itemDetails={itemDetails} handleIcon={handleIcon} />
                </Drawer>
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => applyFilter(value)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.requestType,
                            options: filterOptions?.requestType,
                            isMulti: true,
                            state_name: "requestType",
                            label: t("Type"),
                        },
                    ]} />
            </Box>
        )
    }
    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default withNamespaces("costing")(CostingApproval) 