import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { CustomAsyncWithIcons, Location, PopoverDatePicker, PopoverRanger, SinglePopoverDatePicker } from '../../../components';
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { Routes } from "../../../router/routes";
import { AlertProps } from "../../../utils";
import { propertyPurposeListCommercial } from "../../../utils/propertySearchpoints";
import { ClearFilter } from "../component/clearFilter";
import { Filter } from "../component/filter";
import { finderStyles } from "../style";
export const Commercial = (props) => {
    const classes = finderStyles()
    const history = useHistory()
    var date = moment().toDate();
    const customStyles = {
        control: {

            borderRight: '1px solid #ececec',
            borderRadius: 0,
            padding: "4px 10px",
            backgroundColor: 'none',
            border: 0
        }
    }
    const filterType = {
        amenities: "amenities",
        facilites: "facilites",
        grace: "grace",
        unittype: true,
        budget: "budget"

    }
    const initialState = {
        city: props?.data?.city ?? "",
        purpose: props?.data?.purpose ?? propertyPurposeListCommercial[0],
        type: props?.data?.type ?? {
            value: null,
            label: "All"
        },
        category: props?.data?.category ?? {
            label: "All",
            value: null,
        },
        duration: props?.data?.duration ?? 12,
        durationday: props?.data?.durationday ?? new Date(date),
        durationtype: props?.data?.durationtype ?? {
            type: "Month",
            date: new Date(date.setMonth(date.getMonth() + 12))
        },
        range1: props?.data?.range1 ?? "",
        range2: props?.data?.range2 ?? "",
        rangetype: props?.data?.rangetype ?? "",
        handover: props?.data?.handover ?? ""
    }
    const initialStatefilter = {
        propertyType: props?.filterdata?.propertyType ?? "",
        range1: props?.filterdata?.range1 ?? "",
        range2: props?.filterdata?.range2 ?? "",
        rangetype: props?.filterdata?.rangetype ?? "",
        budget1: props?.filterdata?.budget1 ?? "",
        budget2: props?.filterdata?.budget2 ?? "",
        budgettype: props?.filterdata?.budgettype ?? "",
        aminities: props?.filterdata?.aminities ?? "",
        facilitesbool: props?.filterdata?.facilitesbool ?? "",
        facilites: props?.filterdata?.facilites ?? "",
        facilitestype: props?.filterdata?.facilitestype ?? "",
        pet: props?.filterdata?.pet ?? "",
        unittype: props?.filterdata?.unittype ?? ""
    }
    const [drawer, setDrawer] = React.useState(false)
    const [state, setState] = useState({ ...initialState })
    const alert = React.useContext(AlertContext);
    const [category, setCategory] = React.useState(props?.category ?? propertyPurposeListCommercial[0]?.unitCategory)
    const [location, setLocation] = React.useState(props?.location ?? {})
    const [statefilter, setStateFilter] = React.useState({ ...initialStatefilter })
    const [filter, setFilter] = React.useState(props?.open ?? false)
    const [filters, setFilters] = React.useState(false)
    const didMount = React.useRef(false);
    const detectLocation = () => {
        if (navigator.geolocation && (!props?.value?.longitude)) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${config.googleMapApiKey}`)
                    .then(data => data.json())
                    .then(({ results }) => {
                        if (Array.isArray(results)) {
                            const [info] = results;
                            let city = info?.address_components?.find(comp => comp?.types?.includes("locality"));
                            let location = info?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_2"));
                            let _obj = {};
                            _obj["address"] = `${city?.long_name}, ${location?.long_name}`;
                            _obj["latitude"] = lat;
                            _obj["longitude"] = long;
                            _obj["city"] = city?.long_name;
                            _obj["location"] = location?.long_name;
                            setLocation(_obj)

                        }
                    }).catch(console.error);
            });
        }
    };
    React.useEffect(() => {
        if (window.location.pathname === "/result") {

        } else {
            detectLocation()
        }
        // eslint-disable-next-line
    }, [])
    const updateState = (key, value) => {
        if (key === "purpose") {
            setState({ ...state, purpose: value, category: "" });
        } else {
            setState({ ...state, [key]: value });
        }
    };
    const updateStateFilter = (key, value) => {
        setStateFilter({ ...statefilter, [key]: value });
    };
    
    const goResult = () => {
        if (location?.length === 0 || state?.purpose?.length === 0 || state?.type?.length === 0 || state?.category?.length === 0 || state?.durationday?.length === 0 || state?.durationtype?.date?.length === 0 || state?.durationday?.length === 0 || state?.range1?.length === 0 || state?.range2?.length === 0 || state?.rangetype?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill All Fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        } else {
            if (window.location.pathname === "/result") {
                let tempEndDate = new Date(JSON.parse(JSON.stringify(state?.durationtype?.date)))
                const payload = {
                    tenantId: `${config.tenantId}`,
                    "search_location": {
                        "lat": location?.latitude,
                        "long": location?.longitude,
                        "distance": 10
                    },
                    "property_purpose": "Commercial",
                    "revenue_type": state?.purpose?.label?.length > 0 ? state?.purpose?.value : null,
                    "property_type": state?.type?.value ?? null,
                    "unit_category": state?.category?.value ?? null,
                    "unit_type": statefilter?.unittype?.length > 0 ? statefilter?.unittype?.map((val) => val?.value) : null,
                    "requiredBeds": null,
                    "requiredBaths": null,
                    "duration": {
                        "fromDate": state?.durationday ?? null,
                        "toDate": statefilter?.facilitesbool ? new Date(tempEndDate.setDate(tempEndDate.getDate() + parseInt(statefilter?.facilitesbool))) : state?.durationtype?.date ?? null,
                    },
                    "areaRequirements": {
                        "minArea": parseInt(state?.range1),
                        "maxArea": parseInt(state?.range2),
                        "areaUnit": state?.rangetype
                    },
                    "furnishing": null,
                    "isPetAllowed": null,
                    "budget": {
                        minCost: statefilter?.budget1 ? parseInt(statefilter?.budget1) : null,
                        maxCost: statefilter?.budget2 ? parseInt(statefilter?.budget2) : null,
                        currency: statefilter?.budgettype?.value ? statefilter?.budgettype?.value : null,
                    },
                    "hand_over_date": state?.handover ?? null,
                    "facilities": statefilter?.facilites?.length > 0 ? statefilter?.facilites?.map((val) => val?.value) : null,
                    "amenities": statefilter?.aminities?.length > 0 ? statefilter?.aminities?.map((val) => val?.value) : null,

                }
                props?.reload(payload)
                setFilters(false)
            }
            else {
                history.push({
                    pathname: Routes.propertyResult,
                    state: {
                        purpose: "Commercial",
                        type: "commercial",
                        value: state,
                        filter: statefilter,
                        open: filter,
                        category: category,
                        location: location
                    }
                })
            }
        }


    }
    const onClear = () => {
        setStateFilter(initialStatefilter)
        setDrawer(false)
        setFilter(false)
    }
    const clear = (value) => {
        if (value === "range") {
            setStateFilter({ ...statefilter, range1: "", range2: "", rangetype: "" });
            setFilters(true)
        } else if (value === "budget") {
            setStateFilter({ ...statefilter, budget1: "", budget2: "", budgettype: "" });
            setFilters(true)
        } else {
            setStateFilter({ ...statefilter, [value]: "" });
            setFilters(true)
        }
    }
    React.useEffect(() => {
        // Return early, if this is the first render:
        if (!didMount.current) {
            return didMount.current = true;
        }
        // Paste code to be executed on subsequent renders:
        if (window.location.pathname === "/result") {
            if (filters) {
                goResult()
            }

        } else {
            return false
        }
        // eslint-disable-next-line
    }, [filters]);
    return (
        <div>
            {
                window.location.pathname === '/propertyfinder' &&
                <>
                    {
                        statefilter?.propertyType?.label || statefilter?.range1 || statefilter?.range2 || statefilter?.rangetype || statefilter?.budget1 || statefilter?.budget2 || statefilter?.budgettype || statefilter?.aminities?.label || statefilter?.facilites || statefilter?.facilitestype
                            ?
                            <Typography className={classes.clearAll} onClick={onClear}>Clear All</Typography>
                            : ""
                    }</>
            }
            <Grid container>
                <Grid item xs={11.7}>
                    <Grid container alignItems="center" className={classes.selectorRoot}>
                        <Grid item xs={1.8} className={classes.selector}>
                            <Location
                                value={location}
                                handleChange={(value) => setLocation(value)}
                                placeholder="Enter City"
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.purpose}
                                placeholder="Property Purpose"
                                onChange={(value) => {
                                    updateState('purpose', value)
                                    setCategory(value?.unitCategory)
                                }}
                                options={propertyPurposeListCommercial}
                                styles={{ ...customStyles }}
                                icon="/images/propertypurpose.svg"

                            />
                        </Grid>
                        <Grid item xs={1.9} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.category}
                                icon="/images/unitcatergry.svg"
                                placeholder="Unit Category"
                                onChange={(value) => updateState('category', value)}
                                options={category}
                                styles={{ ...customStyles }}
                            />

                        </Grid>
                        <Grid item xs={1.9} className={classes.selector}>
                            {
                                state?.purpose?.label === "Buy" ?
                                    <SinglePopoverDatePicker
                                        startIcon="/images/date.svg"
                                        placeholder="Enter Handover Date"
                                        updateState={updateState}
                                        handover={state?.handover}
                                    /> :
                                    <PopoverDatePicker
                                        startIcon="/images/date.svg"
                                        placeholder="Enter Duration"
                                        gracePeriod={statefilter?.facilitesbool}
                                        updateState={updateState}
                                        duration={state?.duration}
                                        day={state?.durationday}
                                        type={state?.durationtype}
                                    />
                            }

                        </Grid>
                        <Grid item xs={2.1} className={classes.selector}>
                            <PopoverRanger
                                placeholder='Required Area'
                                startIcon="/images/range.svg"
                                updateState={updateState}
                                range1={state?.range1}
                                range2={state?.range2}
                                rangetype={state?.rangetype}
                            />
                        </Grid>

                        <Grid item xs={0.5} className={classes.search} onClick={goResult}>

                            <SearchIcon style={{ color: "white", fontSize: "24px" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0.3} >
                    <Box display="flex" alignItems="center" marginTop="16px" marginLeft="8px">
                        <Box>
                            <IconButton size="small" onClick={() => setDrawer(true)}>
                                <img src="/images/filterWhite.svg" alt="" />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <ClearFilter filter={filter} state={statefilter} clear={clear} />
            <Drawer anchor='right' open={drawer} onClose={() => setDrawer(false)} className={classes.filterDialog}>
                <Filter filter="Residential" onClear={onClear} type={filterType} state={statefilter} updateState={updateStateFilter} onClose={() => {
                    if (window.location.pathname === "/result") {

                    } else {
                        setFilter(false)
                    }
                    setDrawer(false)
                }}
                    applyfilter={() => {
                        setDrawer(false)
                        setFilter(true)
                        if (window.location.pathname === "/result") {
                            goResult()
                        }
                    }}
                />

            </Drawer>
        </div>
    )
}