import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import ImageResize from "quill-image-resize-module-react";
import React from "react";
import ReactQuill, { Quill } from "react-quill";
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import "react-quill/dist/quill.snow.css";
import { Regular, SemiBold } from "../../utils";

import { AuthContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,

    marginBottom: "5px"

  },
  text: {
    '& .ql-toolbar': {
      borderRadius: "4px 4px 0px 0px",
      border: "1px solid #E2E2E2"
    },
    '& .ql-container': {
      height: (props) => props?.height ?? "44px",
      maxHeight: (props) => (props?.emoji ? "100%" : (props?.height ?? "44px")),
      overflow: (props) => (props?.emoji ? "" : "auto"),
      fontSize: (props) => props?.labelSize ?? "0.875rem",
      borderRadius: "0px 0px 4px 4px",
      fontFamily: Regular,
      border: "1px solid #E2E2E2"
    },
    "& .ql-formats": {
      marginRight: "10px !important"
    }

  },

  testrtl: {
    '& .ql-toolbar': {
      borderRadius: "4px 4px 0px 0px",
      border: "1px solid #E2E2E2"
    },
    '& .ql-container': {
      height: (props) => props?.height ?? "44px",
      maxHeight: (props) => (props?.emoji ? "100%" : (props?.height ?? "44px")),
      overflow: (props) => (props?.emoji ? "" : "auto"),
      fontSize: (props) => props?.labelSize ?? "0.875rem",
      borderRadius: "0px 0px 4px 4px",
      fontFamily: Regular,
      border: "1px solid #E2E2E2"
    },
    "& .ql-formats": {
      marginRight: "10px !important"
    },
    "& .ql-editor": {
      textAlign: 'right'
    },
    "& .ql-picker.ql-header": {
      direction: "ltr"
    }
  },

  textbox: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
}));


export const Editor = (props) => {
  Quill.register("modules/imageResize", ImageResize);
  Quill.register(
    {
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
      "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
      "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
    },
    true
  );

  const classes = useStyles(props);

  const auth = React.useContext(AuthContext)

  const getLabel = (props) => {
    return (
      <Typography variant="body1" className={classes.Label} noWrap>
        {props.label}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <div>
          <ReactQuill
            className={auth?.auth?.auth?.language === "ar" ? classes.testrtl : classes.text}
            value={props?.value ?? ''}
            modules={props?.toolbar ?? {

              toolbar: {
                container: [
                  [{ header: [1, 2, false] }],
                  [{ color: [] }, { background: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ align: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  props?.hideimage ? [] : ["image"],
                  props?.emoji && ["emoji"],
                  ["code-block"],
                ],
              },
              "emoji-toolbar": true,
              // "emoji-textarea": true,
              "emoji-shortname": true,
            }} maxHeight

            theme="snow"
            onChange={(value, a, b, c) => {
              if (b === "user") {
                props?.handleChange(value, a, b, c)
              }
            }}
            id={props?.id}
            readOnly={props?.isReadonly ?? false}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

Editor.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  maxHeight: PropTypes.string

  //onChange: PropTypes.func,
};
Editor.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
