import { useContext, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getCalendarOptions, getCompanyOption, getRoutePermissionNew, pageName, teamHead, teamPath, teamPerformHead, teamPerformPath } from "../../utils";
import { useStyles } from "./style";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Subheader from "../../components/subheader/subheader";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes } from "../../router/routes";
import { constructResponseGetLeadFunnelStats, constructResponseGetLeadSourceStats, constructResponseGetLeadStatusOwnerStats, constructResponseGetOpportunityFunnelStats, constructResponseGetOpportunityOwnerStats, constructResponseGetOpportunityRevenueStats, constructResponseGetOpportunitySourceStats, constructResponseGetStatData } from "./utils/helperFunctions";
import {constructResponseGetStatData as propertyResponseGetStatData} from "../propertyManagerDashboardNew/utils/helperFunctions";
import { CardDetails } from "../propertymanagerdashboard/components";
import Graphcomponent from "../propertymanagerdashboard/components/graphcomponent";
import { DialogDrawer, TableWithPagination } from "../../components";
import { TeamRevenueListDataType, TeamRevenueListHeading, TeamRevenueListPath, scaleLine } from "./utils/constant";
import moment from "moment-timezone";
import { useHistory } from "react-router";
import { constructPerformanceTeamsByMemberData, constructPerformanceTeamsData } from "../leaseandsalesmanagerdashboard/common";
import { HandOverDateComponent } from "../propertymanagerdashboard/handoverDate";

const SalesManagerDashboard = ({
  loading = true,
  handleLoading = () => false,
  t = () => false,
}) => {
  const module_id = localStorage?.getItem?.(LocalStorageKeys?.activeRoleModuleId);
  const table_limit = 10;
  const graph_offset = 0;
  const graph_page = 1;
  const graph_limit = 4;

  const classes = useStyles();
  const auth = useContext(AuthContext);
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const history = useHistory();

  const [is_initial_load, set_is_initial_load] = useState(true);
  const [permission_state, set_permission_state] = useState("");
  const [calendar_options, set_calendar_options] = useState([]);
  const [selected_calendar, set_selected_calendar] = useState("");
  const [selected_company, set_selected_company] = useState("");
  const [company_list, set_company_list] = useState([]);
  const [selected_team, set_selected_team] = useState("");
  const [stat_data, set_stat_data] = useState("");
  const [stat_data_property, set_stat_data_property] = useState("");
  const [lead_count_state, set_lead_count_state] = useState({});
  const [lead_funnel_state, set_lead_funnel_state] = useState([]);
  const [opportunity_state, set_opportunity_state] = useState({});
  const [opportunity_funnel_state, set_opportunity_funnel_state] = useState([]);
  const [opportunity_revenue_state, set_opportunity_revenue_state] = useState({});
  const [is_team_revenue_list_loading, set_is_team_revenue_list_loading] = useState(true);
  const [zoom_graph_title, set_zoom_graph_title] = useState("");
  const [is_zoom, set_is_zoom] = useState(false);
  const [table_offset, set_table_offset] = useState({ by_member: 0, by_team: 0, })
  const [teams_list, set_teams_list] = useState([])
  const [performance_teams_by_member_state, set_performance_teams_by_member_state] = useState([]);
  const [performance_teams_state, set_performance_teams_state] = useState([]);
  const [graph_option_state, set_graph_option_state] = useState({
    lead_status_owner: { offset: graph_offset, page: graph_page, limit: graph_limit, },
    opportunity_owner: { offset: graph_offset, page: graph_page, limit: graph_limit, },
  });
  const [lead_status_owner_state, set_lead_status_owner_state] = useState({ total: 0, data: [] });
  const [opportunity_owner_state, set_opportunity_owner_state] = useState({ total: 0, data: [] });

  useEffect(() => {
    const permission = getRoutePermissionNew(auth);
    if (permission) {
      set_permission_state(permission);
      if (permission?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          let tempCalendarOptions = getCalendarOptions();
          set_calendar_options(tempCalendarOptions);
          set_selected_calendar(tempCalendarOptions?.[6]);
          set_company_list(company?.list);
          set_selected_company(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (is_initial_load && selected_company?.value && selected_calendar?.value) {
      handleLoading(false);
      if (selected_team?.value) {
        set_is_initial_load(false);
        getInitialData({ company: selected_company?.selected, calendar: selected_calendar?.value, team: selected_team });
      }
    }
    // eslint-disable-next-line
  }, [selected_company, selected_calendar, selected_team]);

  const handleCalendarChange = (_) => {
    if (_?.load) {
      set_selected_calendar(_);
      getInitialData({ calendar: _?.value });
    }
  }

  const handleCompanyChange = (_) => {
    set_selected_company(_);
  }

  const handleTeamsChange = (_) => {
    set_selected_team(_);
    if (!is_initial_load) {
      getInitialData({ team: _ });
    }
  }

  const getInitialData = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await Promise.all([
          getStatData({ company, calendar, team }),
          getPropertyStatData({ company }),
          getLeadSourceStats({ company, calendar, team }),
          getLeadFunnelStats({ company, calendar, team }),
          getOpportunitySourceStats({ company, calendar, team }),
          getOpportunityFunnelStats({ company, calendar, team }),
          getOpportunityRevenueStats({ company, calendar, team }),
          getPerformanceTeamsByMemberStats(table_offset?.by_member, table_limit, team),
          getPerformanceTeamsStats(table_offset?.by_team, table_limit, team),
          getLeadStatusOwnerStats({ company, calendar, team }),
          getOpportunityOwnerStats({ company, calendar, team }),
        ]);
      } catch (error) {
        reject(error);
      }
    })
  }

  const getStatData = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    set_is_team_revenue_list_loading(true);

    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    let payload = {
      company_id: company?.value,
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      team_id,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes?.sales_dashboard}`,
      NetWorkCallMethods?.post, payload, null, true, false
    ).then((res) => {
      const temp_stat_data = constructResponseGetStatData({ data: res?.data?.data, t, company });
      set_stat_data(temp_stat_data);
      set_is_team_revenue_list_loading(false);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps?.severity?.error,
      });
    });
  }

  const getPropertyStatData = ({ company = selected_company }) => {
    let payload = { company_id: company?.value };

    NetworkCall(
      `${config?.api_url}${BackendRoutes?.property_manager}`,
      NetWorkCallMethods?.post, payload, null, true, false
    ).then((res) => {
      const temp_stat_data = propertyResponseGetStatData({ data: res?.data?.data, t });
      set_stat_data_property(temp_stat_data);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps?.severity?.error,
      });
    });
  }

  const getLeadSourceStats = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "",
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      type: "source",
      team_id,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_proxy_lead}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetLeadSourceStats(res?.data?.data);
      set_lead_count_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const getLeadFunnelStats = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "",
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      type: "status",
      team_id,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_proxy_lead}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetLeadFunnelStats({ data: res?.data?.data, t });
      set_lead_funnel_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const getOpportunitySourceStats = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "",
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      type: "source",
      team_id,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_oppertunity}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetOpportunitySourceStats(res?.data?.data);
      set_opportunity_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const getOpportunityFunnelStats = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "",
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      type: "status",
      team_id,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_oppertunity}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetOpportunityFunnelStats({ data: res?.data?.data, t, company });
      set_opportunity_funnel_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const getOpportunityRevenueStats = ({ company = selected_company, calendar = selected_calendar?.value, team = selected_team }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "", type: "Revenue",
      year: `${parseInt(moment(calendar?.from_date).format("YYYY"))}`,
      team_id,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_oppertunity_revenue}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetOpportunityRevenueStats(res?.data?.data, t, company);
      set_opportunity_revenue_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const getLeadStatusOwnerStats = ({
    company = selected_company,
    calendar = selected_calendar?.value,
    team = selected_team,
    offset = graph_option_state?.lead_status_owner?.offset,
    limit = graph_option_state?.lead_status_owner?.limit,
  }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "",
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      type: "source", team_id,
      offset, limit,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_lead_owner_group}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetLeadStatusOwnerStats({ data: res?.data?.data, t, company });
      set_lead_status_owner_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const handleLeadStatusOwnerPagination = (value) => {
    set_graph_option_state({
      ...graph_option_state,
      lead_status_owner: {
        ...graph_option_state?.lead_status_owner,
        page: value,
      },
    });
    let offset = (value - 1) * graph_option_state?.lead_status_owner?.limit;
    getLeadStatusOwnerStats({ offset });
  };

  const getOpportunityOwnerStats = ({
    company = selected_company,
    calendar = selected_calendar?.value,
    team = selected_team,
    offset = graph_option_state?.opportunity_owner?.offset,
    limit = graph_option_state?.opportunity_owner?.limit,
  }) => {
    let team_id = team?.value !== "noteams" ? team?.value : undefined;

    const payload = {
      company_id: company?.value ?? "",
      from_date: calendar?.from_date,
      to_date: calendar?.to_date,
      type: "source", team_id,
      offset, limit,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.sales_dashboard_quotations_owner_group}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetOpportunityOwnerStats({ data: res?.data?.data, t, company });
      set_opportunity_owner_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  const handleOpportunityOwnerPagination = (value) => {
    set_graph_option_state({
      ...graph_option_state,
      opportunity_owner: {
        ...graph_option_state?.opportunity_owner,
        page: value,
      },
    });
    let offset = (value - 1) * graph_option_state?.opportunity_owner?.limit;
    getOpportunityOwnerStats({ offset });
  };

  const zoomGraphFunction = (e) => {
    set_zoom_graph_title(e);
    set_is_zoom(true);
  };

  const handleCloseModule = () => {
    set_is_zoom(false);
  };

  const getTeamsOption = (list) => {
    set_teams_list(list)
  }

  const handleTeamChange = (val) => {
    set_selected_team(val)
    set_table_offset({ by_member: 0, by_team: 0 })
    getPerformanceTeamsByMemberStats(0, table_limit, val, false)
    getPerformanceTeamsStats(0, table_limit, val, false)
  }

  const fetchMorePerformanceTeamsByMemberStats = () => {
    let offset = table_offset?.by_member + table_limit
    set_table_offset({ ...table_offset, by_member: offset })
    getPerformanceTeamsByMemberStats(offset, table_limit, selected_team, true)
  }

  const fetchMorePerformanceTeamsStats = () => {
    let offset = table_offset?.by_team + table_limit
    set_table_offset({ ...table_offset, by_team: offset })
    getPerformanceTeamsStats(offset, table_limit, selected_team, true)
  }

  const getPerformanceTeamsByMemberStats = (offset, limit, teams, more = false) => {
    let team_id = teams?.value;
    if (team_id !== "noteams") {
      const payload = {
        company_id: selected_company?.value ?? "",
        from_date: `${moment(selected_calendar?.value?.from_date).format("YYYY-MM-DD")}`,
        to_date: `${moment(selected_calendar?.value?.to_date).format("YYYY-MM-DD")}`,
        start: offset, length: limit, team_id
      };
      NetworkCall(
        `${config.api_url}${BackendRoutes?.sales_dashboard_perform_team_by_person}`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((res) => {
        const result = constructPerformanceTeamsByMemberData(res?.data?.data);
        set_performance_teams_by_member_state(
          more ? [...performance_teams_by_member_state, ...result] : result
        )

      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
    }
  };

  const getPerformanceTeamsStats = (offset, limit, teams, more = false) => {
    let team_id = teams?.value;
    if (team_id !== "noteams") {
      const payload = {
        company_id: selected_company?.value ?? "",
        from_date: `${moment(selected_calendar?.value?.from_date).format("YYYY-MM-DD")}`,
        to_date: `${moment(selected_calendar?.value?.to_date).format("YYYY-MM-DD")}`,
        start: offset, length: limit, team_id
      };
      NetworkCall(
        `${config.api_url}${BackendRoutes?.sales_dashboard_perform_by_team}`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((res) => {
        const result = constructPerformanceTeamsData(res?.data?.data);
        set_performance_teams_state(
          more ? [...performance_teams_state, ...result] : result
        );
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
    }
  };

  const newData = stat_data?.lead_count?.reduce((acc, item) => {
    if (item?.sub !== "Unit Blocking Request") {
      acc.push(item);
    }
    return acc;
  }, []);

  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          title={t("Sales Manager Dashboard")}
          hideBackButton
          calanderselect
          calendarOptions={calendar_options}
          calanderValue={selected_calendar}
          onChangeCalendar={(e) => handleCalendarChange(e)}
          select
          options={company_list}
          value={selected_company}
          onchange={(e) => handleCompanyChange(e)}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={selected_company?.value}
          moduleId={module_id}
          selectedTeams={selected_team}
          getTeamsOption={getTeamsOption}
        />
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {newData?.map((_, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={2.4}
                      onClick={() =>
                        _?.route ? history.push(_?.route) : false
                      }
                    >
                      <CardDetails data={_} padding={16} />
                    </Grid>
                  );
                })}
                {stat_data.revenue_summary?.map((item, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2.4}
                    onClick={() =>
                      item?.route ? history.push(item?.route) : false
                    }
                  >
                    <CardDetails data={item} padding={16} />
                  </Grid>
                ))}
                {/* <Grid item xs={12} sm={12} md={7.2}>
                  <Stack className={classes?.widget_section} direction={"column"}>
                    <Typography className={classes.widget_header} mb={1}>
                      {t("Revenue Summary")}
                    </Typography>
                    <Grid container spacing={2}>
                      {stat_data?.revenue_summary?.map(val => {
                        console.log(val);
                        return (
                          <Grid item xs={12} sm={6} md={3}>
                            <Box className={classes.widget_internal_card_1}>
                              <Box textAlign={"center"}>
                                <Typography className={classes.widget_internal_card_1_text_2}>{val?.label}</Typography>
                                <Typography className={classes.widget_internal_card_1_text_1}>{val?.count}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Graphcomponent
                padding={"10px 16px 16px"}
                margin={"30px"}
                height={350}
                t={t}
                graphTitle={t("Lead Source")}
                chartHeader={t("Lead Source")}
                graphData={lead_count_state?.data ?? []}
                is_pie_new
                isShowLegend={true}
                onZoomClick={zoomGraphFunction}
                textMargin={"0px 0px 0px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                relativeGrid={{ sm: "inherit" }}
                gradiantStyle={
                  {
                    // margin: '0px 0px 0px',
                    // maxHeight: "70px",
                    // display: "flex",
                    // alignItems: "center",
                    // top: { lg: "auto", md: 'auto', sm: "auto", },
                    // height: { md: "400px", },
                    // height1: { md: "auto !important", },
                    // bottom: { md: "16px", sm: "0px", },
                    // relative: { md: "absolute !important", },
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Graphcomponent
                padding={"10px 16px 16px"}
                height={350}
                t={t}
                graphTitle={t("Opportunity")}
                chartHeader={t("Opportunity")}
                graphData={opportunity_state?.data ?? []}
                is_semi_circle_new
                isTotal
                isShowLegend={true}
                isSale
                margin={"40px"}
                innerRadius={70}
                total={opportunity_state?.total ?? 0}
                onZoomClick={zoomGraphFunction}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                relativeGrid={{ sm: "inherit" }}
                gradiantStyle={{
                  margin: "10px 0px 0px",
                  maxHeight: "70px",
                  display: "flex",
                  alignItems: "center",
                  top: { lg: "auto", md: "auto", sm: "auto" },
                  height: { md: "400px" },
                  height1: { md: "auto !important" },
                  bottom: { md: "16px", sm: "0px" },
                  relative: { md: "absolute !important" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack className={classes?.widget_section} direction={"column"}>
                <Typography className={classes.widget_header} mb={1}>
                  {t("Team Revenue")}
                </Typography>
                <TableWithPagination
                  padding={"10px 16px 16px"}
                  height={350}
                  t={t}
                  is_loading={is_team_revenue_list_loading}
                  heading={TeamRevenueListHeading({ t })}
                  rows={stat_data?.team_revenue}
                  path={TeamRevenueListPath}
                  dataType={TeamRevenueListDataType}
                  handleIcon={() => {}}
                  tableType="no-side"
                  view={permission_state?.read}
                  edit={permission_state?.update}
                  delete={permission_state?.delete}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Graphcomponent
                padding={"10px 16px 16px"}
                margin={"0px"}
                maxHeight={"80%"}
                // height={500}
                t={t}
                graphTitle={t("Lead Funnel")}
                chartheader={t("Lead Funnel")}
                graphData={lead_funnel_state ?? []}
                is_funnel_graph_reverse
                funnel_graph_height={400}
                funnel_graph_gap_height={2}
                is_funnel_graph_new
                isSale
                isShowLegend={false}
                onZoomClick={zoomGraphFunction}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                relativeGrid={{ sm: "inherit" }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {
                <Graphcomponent
                  padding={"10px 16px 16px"}
                  margin={"0px"}
                  height={"100%"}
                  maxHeight={"80%"}
                  t={t}
                  graphTitle={t("Opportunity Funnel")}
                  chartheader={t("Opportunity Funnel")}
                  graphData={opportunity_funnel_state ?? []}
                  is_funnel_graph_reverse
                  isShowLegend={false}
                  funnel_graph_height={400}
                  funnel_graph_width={300}
                  funnel_graph_gap_height={2}
                  funnel_graph_tooltip_type="type_1"
                  is_funnel_graph_new
                  isSale
                  onZoomClick={zoomGraphFunction}
                  textMargin={"0px 0px 16px"}
                  divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  relativeGrid={{ sm: "inherit" }}
                />
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <Graphcomponent
                padding={"0px 16px"}
                height={400}
                t={t}
                graphTitle={t("Quotation")}
                chartHeader={t("Quotation")}
                isShowLegend={true}
                graphData={stat_data?.quotation_funnel_graph_data?.data ?? []}
                is_pie_new
                isSale
                onZoomClick={zoomGraphFunction}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                relativeGrid={{ sm: "inherit" }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Graphcomponent
                padding={"10px 16px 16px"}
                margin={"0px"}
                chartHeight={450}
                t={t}
                graphTitle={t("Opportunity Revenue")}
                chartHeader={t("Opportunity Revenue")}
                graphData={opportunity_revenue_state ?? []}
                line_new
                isShowLegend={false}
                isNoLegend
                gheight={300}
                scale={scaleLine}
                onZoomClick={zoomGraphFunction}
                justifyContent={"start"}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  maxHeight: "70px",
                  top: { lg: "auto", md: "7px", sm: "3px" },
                  height: { sm: "63px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Graphcomponent
                t={t}
                title={t("KYC Information")}
                header={t("KYC Information")}
                isKyc
                data={stat_data?.kyc_type}
                margin={"20px"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Graphcomponent
                padding={"0px 16px"}
                indexAxis={"y"}
                t={t}
                graphTitle={t("Opportunity Status by User")}
                chartheader={t("Opportunity Status by User")}
                is_pagination={
                  lead_status_owner_state?.total >
                  graph_option_state?.lead_status_owner?.limit
                }
                graph_data_total={lead_status_owner_state?.total}
                graph_data_limit={graph_option_state?.lead_status_owner?.limit}
                graph_data_page={graph_option_state?.lead_status_owner?.page}
                handle_graph_pagination={handleLeadStatusOwnerPagination}
                graphData={lead_status_owner_state?.data ?? []}
                is_bar_new
                isShowLegend={true} 
                chartHeight={"360px"}
                isNoLegend
                disableNoData
                gheight={300}
                height={400}
                margin={"20px"}
                scale={scaleLine}
                onZoomClick={zoomGraphFunction}
                justifyContent={"start"}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  maxHeight: "70px",
                  top: { lg: "auto", md: "7px", sm: "3px" },
                  height: { sm: "63px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Graphcomponent
                indexAxis={"y"}
                t={t}
                padding={"0px 16px"}
                graphTitle={t("Quotation Status by User")}
                chartheader={t("Quotation Status by User")}
                is_pagination={
                  opportunity_owner_state?.total >
                  graph_option_state?.opportunity_owner?.limit
                }
                graph_data_total={opportunity_owner_state?.total}
                graph_data_limit={graph_option_state?.opportunity_owner?.limit}
                graph_data_page={graph_option_state?.opportunity_owner?.page}
                handle_graph_pagination={handleOpportunityOwnerPagination}
                graphData={opportunity_owner_state?.data ?? []}
                is_bar_new
                isShowLegend={true} 
                chartHeight={"360px"}
                isNoLegend
                disableNoData
                gheight={300}
                height={400}
                margin={"20px"}
                scale={scaleLine}
                onZoomClick={zoomGraphFunction}
                justifyContent={"start"}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  maxHeight: "70px",
                  top: { lg: "auto", md: "7px", sm: "3px" },
                  height: { sm: "63px" },
                }}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                {stat_data_property?.count_5?.map((_) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ maxWidth: "100%", margin: "16px 0px 0px 16px" }}
                    >
                      <CardDetails data={_} padding={16} />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} style={{padding: "16px 0px 0px 16px"}}>
                <HandOverDateComponent
                  t={t}
                  selectedcompany={selected_company}
                  selectedTeams={""}
                />
              </Grid>
            </Grid>
            {teams_list?.length > 0 && (
              <Grid container spacing={2} mt={0.3}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Graphcomponent
                    title={t("Performance By Team Members")}
                    header={t("Performance By Team Members")}
                    isTableWithInfinity
                    tableData={performance_teams_by_member_state ?? []}
                    heading={teamPerformHead(t)}
                    path={teamPerformPath}
                    select
                    options={teams_list}
                    selectValue={selected_team}
                    handleChange={handleTeamChange}
                    fetchMoreData={fetchMorePerformanceTeamsByMemberStats}
                    dataType={[
                      { type: ["avatarmanagement"], name: "Image" },
                      { type: ["text"], name: "rep" },
                      { type: ["text"], name: "opportunities" },
                      { type: ["text"], name: "won" },
                      { type: ["text"], name: "lost" },
                      { type: ["text"], name: "Amount" },
                    ]}
                    tabelheight={296}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <Graphcomponent
                    t={t}
                    title={t("Performance By Team")}
                    header={t("Performance By Team")}
                    isTableWithInfinity
                    tableData={performance_teams_state ?? []}
                    heading={teamHead(t)}
                    path={teamPath}
                    handleChange={handleTeamChange}
                    fetchMoreData={fetchMorePerformanceTeamsStats}
                    dataType={[
                      { type: ["text"], name: "name" },
                      { type: ["text"], name: "opportunities" },
                      { type: ["text"], name: "won" },
                      { type: ["text"], name: "lost" },
                      { type: ["text"], name: "Amount" },
                    ]}
                    tabelheight={296}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <DialogDrawer
          header={t(zoom_graph_title)}
          maxWidth={"md"}
          handleClose={handleCloseModule}
          open={is_zoom}
          height={"100%"}
          fullScreen={true}
          borderRadius={"12px"}
          padding={() => {
            let padding = "0px";
            const zoom_graph_title_list = [
              t("Lead Funnel"),
              t("Opportunity Funnel"),
              t("Quotation Funnel"),
            ];
            if (zoom_graph_title_list.includes(zoom_graph_title)) {
              padding = "50px";
            }
            return padding;
          }}
          onClose={handleCloseModule}
          component={
            <div style={{ height: "100%" }}>
              {zoom_graph_title === t("Lead Source") && (
                <Graphcomponent
                  padding={"10px 16px 16px"}
                  margin={"0px"}
                  isShowLegend={true}
                  graphData={lead_count_state?.data ?? []}
                  is_pie_new
                  textMargin={"0px 0px 0px"}
                  divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  relativeGrid={{ sm: "inherit" }}
                  chartHeight={"100%"}
                  gradiantStyle={{
                    margin: "10px 10px 0px",
                    maxHeight: "70px",
                    display: "flex",
                    alignItems: "center",
                    top: { lg: "auto", md: "auto", sm: "auto" },
                    height: { md: "400px" },
                    height1: { md: "auto !important" },
                    bottom: { md: "16px", sm: "0px" },
                    relative: { md: "absolute !important" },
                  }}
                />
              )}
              {zoom_graph_title === t("Opportunity") && (
                <Graphcomponent
                  padding={"10px 16px 16px"}
                  margin={"0px"}
                  graphData={opportunity_state?.data ?? []}
                  is_semi_circle_new
                  isSale
                  isShowLegend={true}
                  chartHeight={"100%"}
                  innerRadius={70}
                  total={opportunity_state?.total ?? 0}
                  textMargin={"0px 0px 16px"}
                  divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  relativeGrid={{ sm: "inherit" }}
                  gradiantStyle={{
                    margin: "10px 0px 0px",
                    maxHeight: "70px",
                    display: "flex",
                    alignItems: "center",
                    top: { lg: "auto", md: "auto", sm: "auto" },
                    height: { md: "400px" },
                    height1: { md: "auto !important" },
                    bottom: { md: "16px", sm: "0px" },
                    relative: { md: "absolute !important" },
                  }}
                />
              )}
              {zoom_graph_title === "Opportunity Reveune" && (
                <Graphcomponent
                  margin={"0px"}
                  graphData={opportunity_revenue_state ?? []}
                  line_new
                  isNoLegend
                  scale={scaleLine}
                  justifyContent={"start"}
                  isShowLegend={true}
                />
              )}
              {zoom_graph_title === "Opportunity Status by User" && (
                <Graphcomponent
                  indexAxis={"y"}
                  is_popup_pagination={
                    lead_status_owner_state?.total >
                    graph_option_state?.lead_status_owner?.limit
                  }
                  graph_data_total={lead_status_owner_state?.total}
                  graph_data_limit={
                    graph_option_state?.lead_status_owner?.limit
                  }
                  graph_data_page={graph_option_state?.lead_status_owner?.page}
                  handle_graph_pagination={handleLeadStatusOwnerPagination}
                  graphData={lead_status_owner_state?.data ?? []}
                  is_bar_new
                  isNoLegend
                  chartHeight={"100%"}
                  isShowLegend={true}
                  disableNoData
                  margin={"0px"}
                  scale={scaleLine}
                  minWidth={false}
                  gheight={"500px"}
                  gwidth={"400px"}
                  is_popUp
                  justifyContent={"start"}
                  padding={"0px 16px 16px"}
                  divider1={{ xs: 12, sm: 4, md: 4, lg: 4 }}
                  divider={{ xs: 12, sm: 8, md: 8, lg: 8 }}
                  gradiantStyle={{
                    margin: "15px 0px 0px",
                    grid_template: "100%",
                    display: "flex",
                    alignItems: "center",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "16px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
              {zoom_graph_title === "Quotation Status by User" && (
                <Graphcomponent
                  indexAxis={"y"}
                  is_popup_pagination={
                    opportunity_owner_state?.total >
                    graph_option_state?.opportunity_owner?.limit
                  }
                  graph_data_total={opportunity_owner_state?.total}
                  graph_data_limit={
                    graph_option_state?.opportunity_owner?.limit
                  }
                  graph_data_page={graph_option_state?.opportunity_owner?.page}
                  handle_graph_pagination={handleOpportunityOwnerPagination}
                  graphData={opportunity_owner_state?.data ?? []}
                  is_bar_new
                  isShowLegend={true}
                  isNoLegend
                  disableNoData
                  chartHeight={"100%"}
                  margin={"0px"}
                  scale={scaleLine}
                  minWidth={false}
                  gheight={"500px"}
                  gwidth={"400px"}
                  is_popUp
                  justifyContent={"start"}
                  padding={"0px 16px 16px"}
                  divider1={{ xs: 12, sm: 4, md: 4, lg: 4 }}
                  divider={{ xs: 12, sm: 8, md: 8, lg: 8 }}
                  gradiantStyle={{
                    margin: "15px 0px 0px",
                    grid_template: "100%",
                    display: "flex",
                    alignItems: "center",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "16px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
              {zoom_graph_title === t("Lead Funnel") && (
                <Graphcomponent
                  margin={"0px"}
                  graphData={lead_funnel_state ?? []}
                  is_funnel_graph_reverse
                  funnel_graph_height={450}
                  funnel_graph_width={400}
                  funnel_graph_gap_height={2}
                  is_funnel_graph_new
                  isSale
                  isShowLegend={true}
                  textMargin={"0px 0px 16px"}
                  divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  relativeGrid={{ sm: "inherit" }}
                  gradiantStyle={{
                    margin: "10px 0px 0px",
                    maxHeight: "70px",
                    display: "flex",
                    alignItems: "center",
                    top: { lg: "auto", md: "auto", sm: "auto" },
                    height: { md: "400px" },
                    height1: { md: "auto !important" },
                    bottom: { md: "16px", sm: "0px" },
                    relative: { md: "absolute !important" },
                  }}
                />
              )}
              {zoom_graph_title === t("Opportunity Funnel") && (
                <Graphcomponent
                  margin={"0px"}
                  graphData={opportunity_funnel_state ?? []}
                  is_funnel_graph_reverse
                  funnel_graph_height={450}
                  funnel_graph_width={400}
                  funnel_graph_gap_height={2}
                  funnel_graph_tooltip_type="type_1"
                  is_funnel_graph_new
                  isSale
                  isShowLegend={true}
                  textMargin={"0px 0px 16px"}
                  divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  relativeGrid={{ sm: "inherit" }}
                  gradiantStyle={{
                    margin: "10px 0px 0px",
                    maxHeight: "70px",
                    display: "flex",
                    alignItems: "center",
                    top: { lg: "auto", md: "auto", sm: "auto" },
                    height: { md: "400px" },
                    height1: { md: "auto !important" },
                    bottom: { md: "16px", sm: "0px" },
                    relative: { md: "absolute !important" },
                  }}
                />
              )}
              {zoom_graph_title === t("Quotation") && (
                <Graphcomponent
                  margin={"0px"}
                  graphData={stat_data?.quotation_funnel_graph_data?.data ?? []}
                  is_pie_new
                  isSale
                  isShowLegend={true}
                  textMargin={"0px 0px 16px"}
                  divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  relativeGrid={{ sm: "inherit" }}
                  chartHeight={"100%"}
                  gradiantStyle={{
                    margin: "10px 0px 0px",
                    maxHeight: "70px",
                    display: "flex",
                    alignItems: "center",
                    top: { lg: "auto", md: "auto", sm: "auto" },
                    height: { md: "400px" },
                    height1: { md: "auto !important" },
                    bottom: { md: "16px", sm: "0px" },
                    relative: { md: "absolute !important" },
                  }}
                />
              )}
              {zoom_graph_title === t("Letter Type") && (
                <Graphcomponent
                  graphData={stat_data?.letter_type_graph_data?.data ?? []}
                  isPie
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  is_popUp
                  innerRadius={100}
                  centerTop="44%"
                  isTotal
                  total={stat_data?.letter_type_graph_data?.total ?? 0}
                  justifyContent={"start"}
                  divider1={{ xs: 12, sm: 5, md: 5, lg: 5 }}
                  divider={{ xs: 12, sm: 7, md: 7, lg: 7 }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "-8px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
            </div>
          }
        />
      </>
    );
  };
  return (
    <div>
      {accessCheckRender(render, permission_state, pageName.dashboard, loading)}
    </div>
  );
};
export default withNamespaces("salesManagerDashboard")(SalesManagerDashboard);
