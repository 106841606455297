import { enumSelect, NetWorkCallMethods } from ".";
import { config } from "../config";
import { NetworkCall } from "../networkcall";


export const
  loadOptions = async (
    search = "",
    array = [],
    handleLoading = null,
    queryFn,
    extractLabel,
    functionParams = {},
    mappingVariable = {},
    queryVariables = {},
    manualResponse,
    company_id = "",
    appraisal = false,
    booking = false
  ) => {
    let result,
      query,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else if (functionParams?.all) {
      offset = (offset === 0 && array?.length > 0) ? (array?.length - 1) : array?.length;
    } else {
      offset = array?.length;
    }
    query = queryFn(offset, 10, search, { ...functionParams }, company_id).loc.source.body;
    result = await networkCallBack(query, queryVariables, handleLoading, { ...functionParams });

    //Select options should be label,value pairs like ({label:'',value:''})
    if (mappingVariable?.label || mappingVariable?.value) {
      if (appraisal) {
        result[extractLabel] = result?.[extractLabel]?.map((_) => ({
          label: _?.[mappingVariable?.label ?? "label"],
          value: _?.[mappingVariable?.value ?? "value"],
          unit_count: appraisal
            ? _?.[mappingVariable?.unit_count ?? "unit_count"]
            : undefined,
          address: appraisal
            ? _?.[mappingVariable?.address ?? "address"]
            : undefined,
        }));
      }
      else if (booking) {
        result[extractLabel] = result?.[extractLabel]?.map((_) => ({
          label: _?.[mappingVariable?.label ?? "label"],
          value: _?.[mappingVariable?.value ?? "value"],
          is_booking: _?.[mappingVariable?.is_booking ?? "is_booking"]
        }));
      }
      else if (mappingVariable?.agreement) {
        const data = result?.[extractLabel]?.map((_) => ({
          label: `${_?.account?.name} - ${_?.account?.account_no}`,
          value: _?.id,
        }));

        result[extractLabel] = [...new Set(data)]
      }
      else if (mappingVariable?.contact) {
        const data = result?.[extractLabel]?.map((_) => ({
          ..._
        }));

        result[extractLabel] = [...new Set(data)]
      }
      else if (mappingVariable?.primary) {
        result[extractLabel] = result?.[extractLabel]?.map((_) => ({
          label: `${_?.[mappingVariable?.label ?? "label"]} (${_?.value1})`,
          value: _?.[mappingVariable?.value ?? "value"],
        }));
      }
      else {
        result[extractLabel] = result?.[extractLabel]?.map((_) => ({
          label: _?.[mappingVariable?.label ?? "label"],
          value: _?.[mappingVariable?.value ?? "value"],
        }));
      }
    }

    //manually construct array
    if (manualResponse) {
      result[extractLabel] = manualResponse(result[extractLabel]);
    }

    return {
      options: functionParams?.all ?
        (offset === 0 ? [{ value: functionParams?.all_value ?? null, label: functionParams?.all_label ?? "All" }, ...result?.[extractLabel]] :
          [...result?.[extractLabel]]) : [...result?.[extractLabel]],
      hasMore:
        array?.length + result?.[extractLabel]?.length < result?.count[0]?.count,
    };
  };

const networkCallBack = async (query, variable = {}, handleLoading, functionParams = {}) => {
  let payload = {
    query,
    variable,
  };

  const options = await NetworkCall(
    `${functionParams?.url && functionParams?.api_url}`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      let main = response.data.data;
      handleLoading && handleLoading();
      return main;
    })
    .catch((error) => {
      handleLoading && handleLoading();
      return null;
    });

  return options;
};



export const loadOptionsApis = async (
  endpoint,
  queryVariables,
  search = "",
  array = [],
  handleLoading = null,
  extractLabel,
  mappingVariable = {},
  manualResponse,
  functionParams = {},
  isPayment
) => {
  let result,
    offset = 0;

  if (search && !Boolean(array?.length)) {
    offset = 0;
  } else {
    offset = array?.length;
  }
  if (endpoint === "/enum") {
    result = await enumSelect(queryVariables);
  } else {
    result = await networkCallBackApi(endpoint, { ...queryVariables, search }, handleLoading, offset);
  }
  //Select options should be label,value pairs like ({label:'',value:''})
  if (mappingVariable?.label || mappingVariable?.value) {
    if (mappingVariable?.agreement) {
      result[extractLabel] = result?.data?.map((_) => ({
        label: `${_?.label}`,
        value: _?.value,
      }));

    }
    else if (mappingVariable?.unit) {
      result[extractLabel] = result?.data?.map((_) => ({
        label: `${_?.unit_name}`,
        value: _?.id,
        url: _?.logo,
        label1: _?.unit_no,
        ..._
      }));

    }
    else if (mappingVariable?.timezone) {
      result[extractLabel] = result?.data?.map((_) => ({
        label: `${_?.label} ${_?.gmt_string_offset}`,
        value: _?.value,
        url: _?.gmt_offset,
        label1: _?.country_name,
        value1: _?.label
      }));
    }
    else if (mappingVariable?.extra_invoice) {
      result[extractLabel] = result?.data?.map((_) => ({
        label: `${_?.name}`,
        value: _?.id,
        ..._
      }));
    }
    else if (mappingVariable?.isProperty) {
      result[extractLabel] = result?.data?.map((_) => ({
        label: `${_?.name}`,
        value: _?.id,
        url: _?.logo,
        label1: _?.total_area,
        uom_master: _?.uom,
        property_no: _?.property_no,
        ..._
      }));
    }
    else if (mappingVariable?.isUnit) {
      result[extractLabel] = result?.data?.map((_) => ({
        label: `${_?.name}`,
        value: _?.id,
        url: _?.logo,
        label1: _?.unit_no,
        unit_no: _?.unit_no,
        ..._
      }));
    }
    else if(mappingVariable?.is_all){

    }
    else {
      result[extractLabel] = result?.[extractLabel]?.map((_) => ({
        label: _?.[mappingVariable?.label ?? "label"],
        value: _?.[mappingVariable?.value ?? "value"],
      }));
    }
  }

  //manually construct array
  if (manualResponse) {
    const { type = "", manualResponseMethod = "" } = functionParams;
    if (manualResponseMethod === "without_extract_label") {
      result[extractLabel] = manualResponse(result, type);
    } else {
      result[extractLabel] = manualResponse(result[extractLabel], type);
    }
  }
  return {
    options: functionParams?.all ?
      (offset === 0 ?
        [{ value: functionParams?.all_value ?? null, label: functionParams?.all_label ?? "All" }, ...result?.[extractLabel]] :
        [...result?.[extractLabel]])
      : [...result?.[extractLabel]],
    hasMore:isPayment?true: mappingVariable?.extra_invoice ?
      array?.length + result?.data?.[extractLabel]?.length < result?.data?.count :
      array?.length + result?.[extractLabel]?.length < result?.count ?? result?.data?.count,
  };
};

const networkCallBackApi = async (endpoint, variable = {}, handleLoading, offset) => {

  let payload = {
    ...variable,
    offset,
    limit: 10
  };

  const options = await NetworkCall(
    `${config?.api_url}/${endpoint}`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      let main = response.data;
      handleLoading && handleLoading();
      return main;
    })
    .catch((error) => {
      handleLoading && handleLoading();
      return null;
    });

  return options;
};

