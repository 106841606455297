import React, { useState } from "react";
import { useStyles } from "../style";
import { Box, Button, Grid } from "@mui/material";
import { NewDatePicker, TextBox } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods, AlertProps } from "../../../utils";
import { AlertContext } from "../../../contexts";

export const CloseRequest = (props) => {
  const { t } = (props)
  const classes = useStyles();
  const {
    request,
    setRequestclose,
    id,
    reload
  } = props;
  const alert = React.useContext(AlertContext);
  // state
  const [closedata, setclosedata] = useState({
    date: new Date(),
    remarks: "",
    error: {
      date: "",
      remarks: "",
    },
  });
  // state update
  const updateState = (key, value) => {
    let error = closedata.error;
    error[key] = "";
    setclosedata({ ...closedata, [key]: value, error });
  };
  // close validation
  const isIamValideTostep = () => {
    let isValid = true;
    let error = closedata.error;
    if (closedata?.date?.length === 0) {
      isValid = false;
      error.date = t("Problem Since is Required");
    }
    if (closedata?.remarks?.length === 0) {
      isValid = false;
      error.remarks = t("Problem Title is Required");
    }
    if (closedata?.date?.length === 0 || closedata?.remarks?.length === 0) {

    }
    setclosedata({ ...closedata, error });

    return isValid;
  };
  // cancel request function
  const cancelPass = () => {

    if (isIamValideTostep()) {
      

      const payload = {
        req_type: request === "General" ? 1 : 2,
        status: "Closed",
        request_id: id
      }
      NetworkCall(
        `${config.api_url}/request/close_request`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          reload()
          setRequestclose()
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Request closed successfully.",
          });
          setclosedata({
            date: "",
            remarks: "",
            error: {
              date: "",
              remarks: "",
            },
          });
        })
        .catch((error) => {
          if (error.response) {

            // Request made and server responded
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: Object.keys(error.response.data.error.message?.msg).length !== 0 ? error.response.data.error.message?.msg : "Something went wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });

        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong please try again",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
        });
    }
  };
  // close dialog
  const closeDialog = () => {
    setRequestclose();
  };
  return (
    <>
      <Box className={classes.header}>
        <Box>{t("Close Request")}</Box>
        <Box>
          <img
            src="/images/close.svg"
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={closeDialog}
          />
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.closeMain}>
            <div className={classes.dropdown}>
              <NewDatePicker
                required
                // maxDate={new Date(createdDate)}
                maxDate={new Date()}
                value={closedata?.date ?? ""}
                label={t("Problem Since")}
                placeholder={t("Problem Since")}
                onChange={(value) => {
                  updateState("date", value);
                }}
                isError={closedata?.error?.date?.length > 0}
                errorMessage={closedata?.error?.date}
              />
            </div>
            <div className={classes.dropdown}>
              <TextBox
                isrequired
                label={t("Problem Title")}
                placeholder={t("Select Problem Title")}
                value={closedata?.remarks}
                multiline={true}
                onChange={(e) => {
                  updateState("remarks", e.target.value);
                }}
                isError={closedata?.error?.remarks?.length > 0}
                errorMessage={closedata?.error?.remarks}
              />
            </div>

            <Button
              variant="contained"
              style={{ width: "100%", marginTop: "30px" }}
              onClick={cancelPass}
            >
              {t("close")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
