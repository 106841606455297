import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { CreateLeadContext } from "../../screens/leads/leadcontext";
import { SelectBox } from "../select";
const useStyles = makeStyles((theme) => ({
  Label: {
    color: "#4E5A6B",
    fontSize:"0.75rem",
    marginTop: "10px",
  },
  Requirements: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",

    fontSize:"0.875rem",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Filter: {
    marginBottom: "20px",
  },
}));

export const FilterComponent = () => {
  const classes = useStyles();
  const {
    filterData,
    upDateFilter,
  } = React.useContext(CreateLeadContext);

  return (
    <div>
      {/*Priority Type */}
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Priority Type
      </Typography>
      <Grid container spacing={1} className={classes.Filter}>

        <Grid item xs={4}>
          <Typography
            onClick={() => {
              upDateFilter("priority", "high");
            }}
            className={
              filterData.priority?.includes("high")
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            High
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            onClick={() => {
              upDateFilter("priority", "medium");
            }}
            className={
              filterData.priority?.includes("medium")
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Medium
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            onClick={() => {
              upDateFilter("priority", "low");
            }}
            className={
              filterData.priority?.includes("low")
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Low
          </Typography>
        </Grid>
      </Grid>
      {filterData?.error?.priority?.length > 0 && (
        <span style={{ fontSize:"0.75rem", color: "red" }}>
          Priority Type  is required
        </span>
      )}

      <Divider />
      {/* Status */}
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Status
      </Typography>
      <Grid container spacing={1} className={classes.Filter}>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              upDateFilter("Status", 2);
            }}
            className={
              filterData?.Status?.includes(2)
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Open
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              upDateFilter("Status", 1);
            }}
            className={
              filterData?.Status?.includes(1)
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Closed
          </Typography>
        </Grid>
      </Grid>
      {filterData?.error?.Status?.length > 0 && (
        <span style={{ fontSize:"0.75rem", color: "red" }}>
          Status is required
        </span>
      )}
      {/* Lead Owner*/}
      <Divider />
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Contact Name
      </Typography>
      <SelectBox
        label=""
        isPaginate
        debounceTimeout={800}
        value={filterData?.contactName}
        onChange={(value) => {
          upDateFilter("contactName", value);
        }}
        //key={JSON.stringify(filterData?.comapnyname)}
        isMulti={true}
        isError={filterData?.error?.contactName?.length > 0}
        errorMessage={filterData?.error?.contactName}
      />

      {/* Lead Name */}
      <Divider style={{ margin: "20px 0px" }} />
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Lead Owner
      </Typography>
      <SelectBox
        label=" "
        isPaginate
        debounceTimeout={800}
        value={filterData?.leadOwner}
        key={JSON.stringify(filterData?.comapnyname)}
        onChange={(value) => {
          upDateFilter("leadOwner", value);
        }}
        isMulti={true}
        isError={filterData?.error?.leadOwner?.length > 0}
        errorMessage={filterData?.error?.leadOwner}
      />
    </div>
  );
};
