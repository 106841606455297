import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius
    },
    tabRoot: {
        height: `calc(100vh - 269px)`,
        overflow: "auto",
        padding: "20px",
        position: "relative",
        [theme.breakpoints.only("xs")]: {
            height: `calc(100vh - 300px)`,
        },
        [theme.breakpoints.only("sm")]: {
            height: `calc(100vh - 235px)`,
        },
    },
    speedDial: {
        padding: "12px",
        backgroundColor: "#5078E1",
        color: "white",
        position: "absolute",
        bottom: 50,
        right: 10,
        "&:hover": {
            backgroundColor: "#5078E1",
        },
    },
    speedDialArabic: {
        padding: "12px",
        backgroundColor: "#5078E1",
        color: "white",
        position: "absolute",
        bottom: 50,
        left: 10,
        "&:hover": {
            backgroundColor: "#5078E1",
        },
    },
    btnRoot: {
        padding: "8px 20px",
        backgroundColor: "white"
    },
    iconBtn: {
        padding: "8px 24px",
        border: "1px solid #E4E8EE",
        boxShadow: "none",
        backgroundColor: "white",
        color: "#091B29",
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    closeBtn: {
        padding: "8px 24px",
        boxShadow: "none",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    requsetBtn: {
        padding: "8px 24px",
        boxShadow: "none",
    },
    options: {
        color: "#091B29",
        fontSize:"0.875rem",
        fontFamily: Bold,
      },
      leadtransfersbox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        cursor: "pointer",
        backgroundColor: "#fff",
        margin: "10px 0px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
    },
    menuList: {
        "& .MuiPopover-paper": {
          boxShadow: "0px 0px 6px #0717411F",
          borderRadius: theme.palette.borderRadius,
          backgroundColor: "white",
        },
      },
      menuItem1: {
        borderBottom: "1px solid #E4E8EE",
        margin: "0px 4px",
        fontSize: "0.875rem",
        color: "#071741",
        fontFamily: `${SemiBold}`,
        padding:"12px",
        cursor:"pointer",
        "&:last-child": {
          border: 0,
        },
      },
}));