import { Avatar, Box, Divider, Grid, Stack, Typography, Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import { BookingResidentPopupStyle } from './style'
import EditIconSvg from '../../assets/editIcon'
import moment from 'moment'
import { InvoiceDetail } from './components'
import { UnitListCard } from './components/unitListCard'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { NetWorkCallMethods, AlertProps, timeZoneConverter, stringAvatar, LocalStorageKeys } from '../../utils'
import { AlertContext } from '../../contexts'
// import GreenCalendarSvg from '../../assets/calendarGreen'
import { withNamespaces } from 'react-i18next'
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions'
import styled from '@mui/material/styles/styled';
import { AmenitiesBookingContext } from './amenitiesBookingContext'
import { SelectBox } from '../../components'
import AccountCard from './components/accountCard'
import BookingSuccess from './bookingSuccess'
import BookedIcon from './utils/bookedIcon'
import { UnitAndContact } from './components/unitAndContact'

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 155px)`,
    overflow: "auto",
    margin: "16px",
    position: "relative"
}))


const BookingRecidentPopup = (
    {
        getAmenityBookingList = () => false,
        closeCheckAvailability = () => false,
        closeBookingResidentPopup = () => false,
        amenityData = {},
        bookingDate = "",
        slots = [],
        selectedCompany = {},
        selectedProperty = {},
        t = () => false
    }
) => {
    const classes = BookingResidentPopupStyle()
    // const [openSearch, setOpenSearch] = React.useState(true)
    const [addUnit, setAddUnit] = React.useState(false)
    // const [offset, setOffset] = React.useState(0)
    const [confirmation, setComfirmation] = React.useState(false)
    const [splited, setSplited] = React.useState({})

    // const [amounts, setAmounts] = React.useState({
    //     totalAmount: 0,
    //     taxAmount: 0,
    //     invoiceAmount: 0
    // })


    const [result, setResult] = React.useState({
        normalSlot: [],
        peakSlot: [],
        normalAmount: 0,
        peakAmount: 0
    })

    const alert = React.useContext(AlertContext)

    const {
        slotType,
        contactDetail,
        setStep,
        bookWalkin,
        data,
        setData,
        booked,
        selectedUnit,
        setSelectedUnit,
        contactValidate,
        selectedExistingContact,
        setSelectedExistingContact,
        member,
        addInvoiceLine,
        amounts,
        setBooked,
        amountCalculation,
        removeInvoice
    } = React.useContext(AmenitiesBookingContext)

    const manualResponse = (array) => {
        const details = array?.agreementUnits?.map((i) => {
            return {
                ...i,
                label: i?.unit_name,
                value: i?.agreement_unit_id
            }
        })
        return details
    };

    // const existingContactManualResponse = (array) => {
    //     const details = array?.map((i) => {
    //         return {
    //             ...i,
    //             label: i?.first_name,
    //             value: i?.id
    //         }
    //     })
    //     return details
    // }


    const setUnit = (e) => {
        setSelectedUnit(e)
        // setOpenSearch(false)
        setAddUnit(e === null ? false : true)
    }

    // const setExistingContact = (e) => {
    //     setSelectedExistingContact(e)
    //     setContactDetail({
    //         ...contactDetail,
    //         name: "",
    //         mobileNo: "",
    //         mailId: ""
    //     })
    //     // setOpenSearch(false)
    // }

    const bookingSlots = () => {
        const normalSlot = slots?.filter((i) => i?.value?.extraInvoice === false)
        let peakSlot = []
        let peakAmount = 0
        let nonpeakSlot = []
        let nonpeakAmount = 0

        // eslint-disable-next-line
        normalSlot?.map((slot) => {

            if (slot?.value?.info?.is_peak) {
                peakSlot.push([
                    slot?.value?.check_in_value,
                    slot?.value?.check_out_value
                ])
                peakAmount = slot?.value?.info?.rate ?? 0
            } else {
                nonpeakSlot.push([
                    slot?.value?.check_in_value,
                    slot?.value?.check_out_value
                ])
                nonpeakAmount = slot?.value?.info?.rate ?? 0
            }
            // return true
        })
        setResult({
            ...result,
            peakSlot: peakSlot ?? [],
            peakAmount: peakAmount,
            normalSlot: nonpeakSlot ?? [],
            normalAmount: nonpeakAmount
        })

    }

    React.useEffect(() => {
        amountCalculation()
        bookingSlots()
        splitSlot()
        // eslint-disable-next-line
    }, [])


    const handleUnselectUnit = (existing) => {
        if (existing) {
            setSelectedExistingContact("")
            setAddUnit(false)
        } else {
            setSelectedUnit("")
            setAddUnit(false)
        }
    }
    // Paynow function (book amenities)
    const bookAmenities = () => {
        let status = true
        if (selectedExistingContact?.length === 0 && bookWalkin) {
            status = contactValidate()
        }
        const pricingComponent = slots?.filter((i) => i?.value?.label === '')?.length > 0 ? false : true

        if (status && pricingComponent) {
            // const reConstructedSlot = slots?.sort((a, b) => a.index - b.index).map(obj => obj.value);
            const userProfileId = localStorage.getItem(LocalStorageKeys.profileID)
            // added invoice rows
            const extraInvoices = slots?.filter((i) => i?.value?.extraInvoice === true)
            const constructedInvoice = extraInvoices?.map((e) => {
                return {
                    name: e?.value?.label?.label,
                    id: e?.value?.label?.value,
                    quantity: Number(e?.value?.qty),
                    rate: Number(e?.value?.info?.rate)
                }
            })

            const payload = {
                property_id: selectedProperty?.value,
                property_facility_id: amenityData?.facility_id,
                period: amenityData?.period,
                start_date: amenityData?.period === "Daily" ?
                    moment(splited?.normalSlot?.[0]?.value?.check_in_date).format("YYYY-MM-DD") + " 00:00:00" :
                    `${moment(data?.selectedDate).format("YYYY-MM-DD")} ${splited?.normalSlot?.[0]?.value?.check_in_value}`,
                end_date: amenityData?.period === "Daily" ?
                    moment(splited?.normalSlot?.[splited?.normalSlot?.length - 1]?.value?.check_out_date).format("YYYY-MM-DD") + " 23:59:59" :
                    `${moment(data?.selectedDate).format("YYYY-MM-DD")} ${splited?.normalSlot?.[splited?.normalSlot?.length - 1]?.value?.check_out_value}`,

                booked_slots: [],
                rate: amenityData?.rate,
                // total_rate: amenityData?.rate * bookingSlots.length,
                no_of_slots: slots.length ?? null,
                total_days: amenityData?.period === "Hourly" ? null : splited?.normalSlot?.length,

                agreement_unit_id: selectedUnit?.agreement_unit_id ?? undefined,
                currency: amenityData?.symbol,
                paid_on: new Date(),
                paid_amount: Number(amounts?.totalAmount),
                company_id: selectedCompany?.value,
                booked_slots_info: amenityData?.period === "Hourly" ? [
                    {
                        is_peak: false,
                        rate: result?.normalAmount,
                        slots: result?.normalSlot
                    },
                    {
                        is_peak: true,
                        rate: result?.peakAmount,
                        slots: result?.peakSlot
                    }
                ] : [],
                contact_id: selectedExistingContact?.id ?? undefined,
                new_contact_params: (bookWalkin && selectedExistingContact?.length === 0) ? {
                    first_name: contactDetail?.name,
                    email_id: contactDetail?.mailId,
                    mobile_no_country_code: contactDetail?.mobileNo?.mobile_code,
                    mobile_no: contactDetail?.mobileNo?.mobile,
                    is_active: true,
                    created_by: userProfileId,
                    updated_by: userProfileId,
                    created_at: new Date(),
                    updated_at: new Date()
                } : undefined,
                account_id: contactDetail?.account?.id ?? undefined,
                overbooking_count: amenityData?.is_overbooking ? Number(member?.count) : 1,
                pricing_components: constructedInvoice ?? []
            }
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/book_amenity`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    setBooked(true)
                    getAmenityBookingList()
                    closeBookingResidentPopup()
                    closeCheckAvailability()
                    setComfirmation(!confirmation)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t("BookingDoneSuccessfully"),
                    });
                })
                .catch((error) => {
                    if (error?.response?.status === 406) {
                        // Request made and server responded
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.info,
                            msg: error?.response?.data?.message ?? "Try Again",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });
                    } else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: AlertProps.message.some_thing_went_wrong,
                        });
                    }
                });
        }
        else {
            setComfirmation(!confirmation)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: !pricingComponent ? t("Please select item in invoice detail") : t("Please fill all mandatory fields"),

            });
        }
    };

    const bookSlot = () => {
        bookAmenities()
    }

    const splitSlot = () => {
        const normalSlot = slots?.filter((i) => i?.value?.extraInvoice === false)
        setSplited({
            normalSlot: normalSlot
        })
    }
    return (
        <>
            <CustomPaper>
                <Grid container>
                    <Grid item sm={12} xs={12} md={12} lg={12} sx={{ height: booked ? "calc(100vh - 140px)" : "calc(100vh - 200px)", overflow: "auto" }}>
                        <Grid container sx={{ height: booked ? "calc(100vh - 160px)" : "100%" }}>
                            <Grid item md={booked ? 5 : 6} lg={3.5} sm={12} xs={12} className={classes.grid_border} p={1}>
                                <Box p={1}>
                                    <Stack spacing={1} mt={1}>
                                        <Typography className={classes.title}>{t("AMENITYDETAILS")}</Typography>
                                        <Stack className={classes.border} direction={"row"} p={2} spacing={1.25}>
                                            <Avatar src={amenityData?.assets?.[0]}></Avatar>
                                            <Stack>
                                                <Typography className={classes.name}>{amenityData?.description}</Typography>
                                                <Typography className={classes.content}> {selectedProperty?.label} </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Stack spacing={1} mt={1.5}>
                                        <Typography className={classes.title}>{t("BOOKINGDETAILS")}</Typography>
                                        <Stack className={classes.border} p={2} spacing={2}>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Stack direction={"row"} spacing={1.25} flexGrow={1}>
                                                    <BookedIcon />
                                                    <Stack>
                                                        <Typography className={classes.name}> {timeZoneConverter(bookingDate)}</Typography>
                                                        <Typography className={classes.content}>{t("Bookingdate")}</Typography>
                                                    </Stack>
                                                </Stack>
                                                {
                                                    !booked &&
                                                    <Box onClick={() => {
                                                        setStep("step2")
                                                        setData({ ...data, is_back: true })
                                                    }} className={classes.editIcon}>
                                                        <EditIconSvg />
                                                    </Box>
                                                }
                                            </Stack>
                                            <Divider></Divider>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Stack direction={"row"} spacing={1.25} flexGrow={1}>
                                                    <Avatar src={amenityData?.assets?.[0]}></Avatar>
                                                    <Stack>
                                                        <Stack direction={"row"} spacing={2}>
                                                            {
                                                                splited?.normalSlot?.[0]?.value?.check_in_date ?
                                                                    <Typography className={classes.name}>
                                                                        {moment(splited?.normalSlot?.[0]?.value?.check_in_date).format("DD MMM YY")} {(splited?.normalSlot?.[0]?.value?.check_out_date < splited?.normalSlot?.[splited?.normalSlot?.length - 1]?.value?.check_out_date) && "-"} {(splited?.normalSlot?.[0]?.value?.check_out_date < splited?.normalSlot?.[splited?.normalSlot?.length - 1]?.value?.check_out_date)  && moment(splited?.normalSlot?.[splited?.normalSlot?.length - 1]?.value?.check_out_date).format("DD MMM YY")}
                                                                    </Typography>
                                                                    :
                                                                    <Typography className={classes.name}>
                                                                        {moment(splited?.normalSlot?.[0]?.value?.check_in_time, "HH:mm").format("hh:mm A")} - {moment(splited?.normalSlot?.[splited?.normalSlot?.length - 1]?.value?.check_out_time, "HH:mm").format("hh:mm A")}
                                                                    </Typography>
                                                            }
                                                            <Typography className={classes.slotLabel}>{slotType}</Typography>
                                                        </Stack>
                                                        <Typography className={classes.content}>{amenityData?.description}</Typography>
                                                    </Stack>
                                                </Stack>
                                                {
                                                    !booked &&
                                                    <Box onClick={() => {
                                                        setStep("step2")
                                                        setData({ ...data, is_back: true })
                                                    }} className={classes.editIcon}>
                                                        <EditIconSvg />
                                                    </Box>
                                                }
                                            </Stack>
                                            {(selectedUnit || selectedExistingContact) && <Divider></Divider>}
                                            {
                                                (selectedUnit || selectedExistingContact) &&
                                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Stack direction={"row"} spacing={1.25} flexGrow={1}>
                                                        <Avatar src={selectedUnit?.unit_logo ?? selectedExistingContact?.image_url} {...stringAvatar(selectedUnit?.unit_name ?? selectedExistingContact?.first_name)}></Avatar>
                                                        <Stack>
                                                            <Typography className={classes.name}> {selectedUnit?.unit_name ?? selectedExistingContact?.first_name ?? "-"} </Typography>
                                                            <Typography className={classes.content}>{selectedUnit?.property_name ?? selectedExistingContact?.customer_id ?? "-"}, {selectedUnit?.amenity_user ?? selectedExistingContact?.relationship ?? "-"}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                    {/* {
                                                        !booked && &&
                                                        <Box className={classes.editIcon} onClick={() => setAddUnit(!addUnit)}>
                                                            <EditIconSvg />
                                                        </Box>
                                                    } */}
                                                </Stack>
                                            }

                                        </Stack>
                                    </Stack>
                                </Box>
                            </Grid>
                            {
                                !booked &&
                                <Grid item md={6} lg={3.5} sm={12} xs={12} className={classes.grid_border} p={2}>
                                    <Box p={1}>
                                        <Typography className={classes.title} pb={1}>{t("CHOOSE RESIDENT UNIT")}</Typography>
                                        {
                                            !bookWalkin ?
                                                <Box sx={{ height: "calc(100vh - 300px)", overflow: "auto" }}>
                                                    <SelectBox
                                                        loadOptions={(search, array, handleLoading) =>
                                                            loadOptionsApis(
                                                                "amenities_booking_v2/get_agreementunits_and_units",
                                                                {
                                                                    property_id: selectedProperty?.value,
                                                                    date: moment(new Date()).format("YYYY-MM-DD"),
                                                                },
                                                                search,
                                                                array,
                                                                handleLoading,
                                                                "data",
                                                                {},
                                                                manualResponse
                                                            )

                                                        }
                                                        isPaginate={true}
                                                        borderRadius={false}
                                                        noBorder={false}
                                                        placeholder={t("Search Unit/Seat")}
                                                        label={false}
                                                        value={selectedUnit}
                                                        onChange={(value) => {
                                                            setUnit(value)
                                                        }}
                                                        customOptionComponent={(props) => {
                                                            return (
                                                                <Stack direction={"row"} spacing={2} p={1} alignItems={"center"} className={classes.selectParent}
                                                                    style={{
                                                                        border: (props?.isSelected) ? "1px solid #5078E1" : "1px solid #E4E8EE",
                                                                        backgroundColor: (props?.isSelected) ? "#F1F7FF" : "#fff"
                                                                    }}>
                                                                    <Avatar className={classes.optionAvatar}
                                                                        src={props?.data?.unit_logo}
                                                                        {...stringAvatar(props?.data?.unit_name)}></Avatar>
                                                                    <Stack>
                                                                        <Typography className={classes.optionHeader}>{props?.data?.unit_name}</Typography>
                                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                            <Typography className={classes.optionSubTitle}>{props?.data?.property_name}</Typography>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Stack>
                                                            )
                                                        }}
                                                    />

                                                    {
                                                        (
                                                            // selectedExistingContact?.id
                                                            selectedExistingContact?.id || selectedUnit?.agreement_unit_id) &&
                                                        !bookWalkin &&
                                                        <UnitListCard
                                                            data={selectedUnit}
                                                            removeUnit={handleUnselectUnit}
                                                            close={true}
                                                            existing={bookWalkin} />
                                                    }
                                                </Box> :
                                                <Box sx={{ height: "calc(100vh - 300px)", overflow: "auto" }}>
                                                    <UnitAndContact addUnit={addUnit} setAddUnit={setAddUnit} t={t} />
                                                </Box>
                                        }
                                    </Box>
                                </Grid>
                            }
                            {
                                !booked &&
                                <Grid item md={12} lg={5} sm={12} xs={12} className={classes.grid_border} p={2}>
                                    <Box p={1}>
                                        <Typography className={classes.title} pb={1}>{t("PAYMENT & INVOICE DETAILS")}</Typography>
                                        <Box sx={{ height: "calc(100vh - 300px)", overflow: "auto" }}>
                                            {
                                                bookWalkin &&
                                                <Box pb={2}>
                                                    <AccountCard selectedCompany={selectedCompany} />
                                                </Box>
                                            }
                                            <Box>
                                                <InvoiceDetail
                                                    slots={slots}
                                                    amenityData={amenityData}
                                                    amounts={amounts}
                                                    member={member}
                                                    addInvoiceLine={addInvoiceLine}
                                                    removeInvoice={removeInvoice}
                                                    customHeight={bookWalkin} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                            {
                                booked &&
                                <Grid item md={7} lg={8} sm={12} xs={12}>
                                    <center>
                                        <BookingSuccess slots={slots} amenityData={amenityData} amounts={amounts} member={member} />
                                    </center>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {
                        !booked && <Grid item sm={12} xs={12} md={12} lg={12}>

                            <Stack p={2} direction={"row"} justifyContent={"space-between"} className={classes.btnBox}>
                                <Button className={classes.backBtn} onClick={() => {
                                    setStep("step2")
                                    setData({ ...data, is_back: true })
                                }}>{t("Back")}</Button>
                                <Stack direction={"row"} spacing={1} justifyContent={"right"}>
                                    <Button variant="outlined" disableElevation className={classes.walkinBtn}
                                        disabled={true
                                            // bookWalkin ? (!contactDetail?.account?.id) : !selectedUnit?.unit_id
                                        }
                                    // onClick={() => setBooked(true)}
                                    >{t("Settle offline")}</Button>
                                    <Button variant="contained"
                                        disableElevation
                                        className={classes.bookBtn}
                                        disabled={bookWalkin ? (!contactDetail?.account?.id) : !selectedUnit?.unit_id}
                                        onClick={() => setComfirmation(!confirmation)}
                                    >{t("Book Now")}</Button>
                                </Stack>
                            </Stack>


                        </Grid>
                    }
                </Grid>

                <Dialog
                    open={confirmation}
                    onClose={() => setComfirmation(!confirmation)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.publishDialog}
                >
                    <DialogContent sx={{ padding: 0 }}>
                        <DialogContentText sx={{ background: "transparent" }}>
                            <div style={{
                                paddingRight: "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundImage: `url(${"images/success.svg"})`,
                                backgroundRepeat: "no-repeat",
                                height: 250,
                                width: 600
                            }}>
                                {/* <Stack spacing={2} alignItems={"center"}>

                                <Typography className={classes.publishPopupTitle}>{t("InvoicePublishedSuccessfully")}</Typography>
                                <Box className={classes.publishPopupBtn}
                                    onClick={closePublish}>
                                    <Typography className={classes.publishbtnText}>{t("Okay")}</Typography>
                                </Box>
                            </Stack> */}

                                <div className={classes.proceedDialog}>
                                    <Stack spacing={3}>
                                        <Typography className={classes.convert}>
                                            {t("Areyousureyouwanttoraiseamanagedinvoice")}
                                        </Typography>
                                        <Box display="flex" alignItems="center" className={classes.marginTop}>
                                            <Button className={classes.yes} onClick={() => bookSlot()}>
                                                {t("Yes")}
                                            </Button>
                                            <Button className={classes.No} onClick={() => setComfirmation(!confirmation)}>
                                                {t("No")}
                                            </Button>
                                        </Box>
                                    </Stack>
                                </div>

                            </div >

                        </DialogContentText >
                    </DialogContent >
                </Dialog >
            </CustomPaper >

        </>

    )
}

export default withNamespaces("amenityBooking")(BookingRecidentPopup)
