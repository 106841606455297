import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Tab from '@mui/material/Tab';
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import NoInspectionData from '../../../assets/NoInspectionData';
import { CartItemCard, GeneralCard, LoadingSection, SearchFilter } from "../../../components";
import { config } from '../../../config';
import { AlertContext } from "../../../contexts";
import { NetworkCall } from '../../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../../utils';
import { TabTitleList } from "../../../utils/inspectionItemMapping";
import BulkTemplate from './bulkTemplate';
import { InspectionMappingComponentStyles } from "./styles";


const AddInspectionItem = ({ t, details = {}, onClose = () => false, reload = () => false, property_id = "" }) => {
    const classes = InspectionMappingComponentStyles()
    const [value, setValue] = useState('1');
    const [inspectionItems, setInspectionItems] = React.useState([]);
    const [bulkItems, setBulkItems] = React.useState([]);
    const [templateView, setTemplateView] = useState(false)
    const [BulkData, setBulkData] = useState(false);
    const [offset, setOffset] = useState(0);
    const [offsett, setOffsett] = useState(0);
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState({
        bulkItem: [],
        generalItem: []
    })
    const [searchTemp, setSearchTemp] = React.useState("")
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    //get inspection items
    const getInspectionItems = (search, offset, showMore = false, isSearch = false) => {
        const payload = {
            company_id: details?.company_id,
            search: search,
            offset: offset,
            "items": ["Inventory", "Vehicles", "Tools"],
            limit: 10,
        }
        NetworkCall(
            `${config.api_url}/inspection_items/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                const data = response.data.data?.map((val) => {
                    return {
                        id: val?.id,
                        Name: val?.name,
                        image: "/images/Group 98362.svg",
                        count: 0,
                        is_active: false,
                        name: val?.name
                    }
                })
                if (!isSearch) {
                    let result = inspectionItems.concat(data)?.map((x) => {
                        let data = selectedItem?.generalItem?.filter((val) => { return x?.id === val.id })
                        if (data?.length > 0) {
                            return data?.[0]
                        }
                        else {
                            return x
                        }

                    })
                    setInspectionItems(showMore ? result : data)
                }
                else {
                    let result = data?.map((x) => {
                        let searchData = selectedItem?.generalItem?.filter((val) => { return x?.id === val.id })
                        if (searchData?.length > 0) {
                            return searchData?.[0]
                        }
                        else {
                            return x
                        }

                    })
                    setInspectionItems(result)
                }

                setLoading(false)

            }).catch((err) => {
                console.log(err)

            })

    }
    //get template
    const getTemplates = (search, offset, reload) => {
        const payload = {
            client: localStorage.getItem(LocalStorageKeys.clinetID),
            property_id: property_id,
            search: search,
            offset: offset,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/queries/inspection_template/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {

            const data = response.data.data.inspection_template?.map((val) => {
                return {
                    ...val,
                    totalItems: val?.count?.[0]?.count_id,
                    is_active: false,
                    count: 0,
                    sub_items: val?.sub_items?.map((i) => {
                        return {
                            ...i,
                            name: i?.inspection_items.is_inventory ? i?.inspection_items?.inventory?.name : i?.inspection_items?.name,
                            Name: i?.inspection_items.is_inventory ? i?.inspection_items?.inventory?.name : i?.inspection_items?.name,
                            image: "/images/Group 98362.svg",
                            count: i?.count ? parseInt(i?.count) : 0,
                            is_active: true,
                            tempName: val?.Name
                        }
                    })
                }
            })

            setBulkItems(reload ? data : bulkItems.concat(data));
            setLoading(false)

        }).catch((err) => {
            console.log(err)


        })
    }
    //initial load
    React.useEffect(() => {
        getInspectionItems("", 0, false)
        getTemplates("", 0, true)
        // eslint-disable-next-line
    }, [])
    //To view Bulk Template
    const handletemplateView = (item) => {
        setBulkData(item)
        setTemplateView(true)
    }
    //HandleChane for tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //onclick inspection item
    const changeInspactionItems = (data, bool) => {
        const edited = inspectionItems.map((val) =>
            val.id === data?.id
                ? {
                    ...val,
                    is_active: bool,
                    open: bool ? true : false,
                    count: bool ? 1 : 0
                }
                : val
        );
        const generalData = {
            ...data,
            is_active: bool,
            open: bool ? true : false,
            count: bool ? 1 : 0
        }
        setInspectionItems(edited)
        setSelectedItem({ ...selectedItem, generalItem: [...selectedItem?.generalItem, generalData] })
    }
    //add inspectionitem
    const addInspectionItem = (data, type) => {

        const edited = inspectionItems.map((val) => {
            let count = type ? val?.count + 1 : val?.count - 1;

            let selected = val.id === data?.id
                ? {
                    ...val,
                    count: count > 0 ? count : 0,
                    is_active: count > 0 ? true : false,
                    open: count > 0 ? true : false,
                }
                : val

            return selected;
        });
        const generalItems = selectedItem?.generalItem?.filter((x) => { return x?.is_active })?.map((val) => {
            let count = type ? val?.count + 1 : val?.count - 1;
            let selected = val.id === data?.id
                ? {
                    ...val,
                    count: count > 0 ? count : 0,
                    is_active: count > 0 ? true : false,
                    open: count > 0 ? true : false,
                }
                : val
            return selected;
        })
        setInspectionItems(edited)
        setSelectedItem({ ...selectedItem, generalItem: generalItems })
    }
    // remove Item 
    const removeInspectionItems = (data, bool) => {
        const edited = inspectionItems.map((val) =>
            val.id === data?.id
                ? {
                    ...val,
                    is_active: bool,
                    open: bool ? true : false,
                    count: bool ? 1 : 0
                }
                : val
        );
        setInspectionItems(edited)
        const result = selectedItem?.generalItem.map((val) =>
            val.id === data?.id
                ? {
                    ...val,
                    is_active: bool,
                    open: bool ? true : false,
                    count: bool ? 1 : 0
                }
                : val
        );
        setSelectedItem({ ...selectedItem, generalItem: result })
    }
    //update subitems
    const updateSubItemsStatus = (val, bool, id) => {
        return val.map((i) => {

            let selected = i.id === id
                ? {
                    ...i,
                    is_active: bool,
                }
                : i
            return selected;
        })

    }
    //removetemplate
    const removeTemplate = (data, bool) => {
        const edited = bulkItems.map((val) => {
            return {
                ...val,
                sub_items: updateSubItemsStatus(val?.sub_items, bool, data?.id)
            }
        });

        setBulkItems(edited)
        setSelectedItem({ ...selectedItem, bulkItem: edited })
    }
    //onclick inspection template
    const changeTemplate = (data, bool) => {
        const edited = bulkItems.map((val) =>
            val.id === data?.id
                ? {
                    ...val,
                    is_active: bool,
                    sub_items: val?.sub_items?.map((val) => {
                        return {
                            ...val,
                            is_active: true
                        }
                    })
                }
                : val
        );
        setBulkItems(edited)
        setSelectedItem({ ...selectedItem, bulkItem: edited })
        setTemplateView(false)
    }
    //update subitems
    const updateSubItems = (val, type, id) => {
        return val.map((i) => {
            let count = type ? i?.count + 1 : i?.count - 1;
            let selected = i.id === id
                ? {
                    ...i,
                    count: count > 0 ? count : 0,
                    is_active: count > 0 ? true : false,
                    open: count > 0 ? true : false,
                }
                : i
            return selected;
        })
    }
    //add inspectionitem
    const addTemplet = (data, type) => {

        const edited = bulkItems.map((val) => {
            return {
                ...val,
                sub_items: updateSubItems(val.sub_items, type, data?.id)
            }
        });

        setBulkItems(edited)
        setSelectedItem({ ...selectedItem, bulkItem: edited })

    }
    //more
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getInspectionItems("", offset + 10, true);
    }
    //more template
    const fetchMoreDataTemplate = () => {
        setOffsett(offsett + 10);
        getTemplates("", offsett + 10, true);
    }
    //delete all
    const deleteAll = () => {
        getInspectionItems("", 0, false)
        getTemplates("", 0, true)
        setSelectedItem({ bulkItem: [], generalItem: [] })
    }

    //onSubmit
    const onSubmit = () => {

        const inspection_items = selectedItem?.generalItem.filter((val) => val?.is_active).map((val) => {
            return {
                name: val?.name,
                inspection_item: val?.id,
                count: val?.count
            }
        });

        const template = selectedItem?.bulkItem?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items)).filter((val) => val?.is_active).map((r) => {
            return {
                name: r?.Name,
                inspection_item: r?.inspection_items?.id,
                count: r?.count
            }
        })

        setIsDisableBtn(true)
        const payload = {
            company_id: details?.company_id,
            unit_id: details?.unit_id,
            property_id: details?.property_id,
            offset: offset,
            inspection_items: [...inspection_items, ...template],
            limit: 10,
            "size_type": "instruction_item_mapping_size"
        }
        NetworkCall(
            `${config.api_url}/inspection_item_mapping/add`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Item Successfully Added"),
                });
                onClose();
                reload()
            }).catch((err) => {
                if (err?.response?.status === 406)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Your  Inspection  limit has been hit! Please upgrade your plan!"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Some Thing Went Wrong"),

                    });
                }
                setIsDisableBtn(false)
                console.log(err)
            })
    }



    return (
        <Box>
            {
                loading ?
                    <LoadingSection top="30vh" bottom='30vh' message={"Fetching ..."} />
                    :
                    <Grid container>
                        <Grid item xs={12} md={6} className={`${classes.inspectionItemList}`}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            {TabTitleList(t)?.map((x) => {
                                                return (
                                                    <Tab style={{ marginInlineStart: "16px" }} label={x.title} value={x.value} className={`${classes.tabTitle}`} />
                                                )
                                            })}

                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" className={classes.tab}>
                                        <Box p={1}>
                                            <SearchFilter
                                                value={search}
                                                handleChange={(val) => {
                                                    setSearch(val)
                                                    setOffset(0)
                                                    getInspectionItems(val, 0, false, true)
                                                }}
                                                placeholder={t("searchInspectionItem")} />
                                        </Box>
                                        <InfiniteScroll
                                            dataLength={inspectionItems?.length ?? ""}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            height={380}
                                        >
                                            <Box p={1}>
                                                {inspectionItems.map((val) => {
                                                    return (
                                                        <GeneralCard
                                                            list={val}
                                                            buttonText={t("Add")}
                                                            handleChange={() => changeInspactionItems(val, true)}
                                                            increaseQty={() => addInspectionItem(val, true)}
                                                            decreaseQty={() => addInspectionItem(val, false)}
                                                            component={"increment"}
                                                        />

                                                    )

                                                })}
                                            </Box>
                                        </InfiniteScroll>

                                    </TabPanel>
                                    <TabPanel value="2" className={classes.tab}>
                                        <Box p={1}>
                                            <SearchFilter
                                                value={searchTemp}
                                                handleChange={(val) => {
                                                    setSearchTemp(val)
                                                    getTemplates(val, 0, true)
                                                }}
                                                placeholder={t("searchTemplateBaseItem")} />
                                        </Box>
                                        <InfiniteScroll
                                            dataLength={bulkItems?.length ?? ""}
                                            next={fetchMoreDataTemplate}
                                            hasMore={true}
                                            height={380}
                                        >
                                            <Box p={1}>
                                                {bulkItems.map((item) => {
                                                    return (
                                                        <GeneralCard
                                                            list={item}
                                                            handleChange={() => changeTemplate(item)}
                                                            increaseQty={() => addTemplet(item)}
                                                            decreaseQty={() => addTemplet(item)}
                                                            view={true}
                                                            totalItems={item?.totalItems}
                                                            buttonText={t("Remove")}
                                                            type="template"
                                                            hideBtn={true}
                                                            component={"increment"}
                                                            isDot
                                                            handleView={() => handletemplateView(item)}
                                                        />

                                                    )

                                                })}
                                            </Box>
                                        </InfiniteScroll>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            {
                                templateView ?

                                    <Box>
                                        <BulkTemplate
                                            BulkData={[BulkData]}
                                            crossicon={true}
                                            buttonName={t("Add")}
                                            handleClick={() => changeTemplate(BulkData, true)}
                                            handleClose={() => setTemplateView(false)}
                                        />


                                    </Box> :

                                    <Box p={1}>



                                        {
                                            (selectedItem?.generalItem?.filter((val) => val?.is_active)?.length > 0 ||
                                                selectedItem?.bulkItem?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0)

                                                ?
                                                <Stack direction="row" justifyContent="space-between" padding="0px 12px 12px 12px">
                                                    <Typography className={classes.cartTitle}>{t("Inspection Items Preview")}</Typography>
                                                    <Typography className={classes.delete} onClick={deleteAll}>{t("Delete All")}</Typography>
                                                </Stack>
                                                :
                                                // <Stack alignItems="center" direction="row" justifyContent="center">
                                                <Box height="380px" justifyContent="center" display={"flex"} alignItems="center">
                                                    <center>
                                                        <NoInspectionData />
                                                        <Typography className={classes.cartTitle}>{t("No Item Added")}</Typography>
                                                        <Typography className={classes.propertyTitle}>{t("Add Inspection Items From The Master")}</Typography>
                                                    </center>
                                                </Box>
                                            // </Stack>
                                        }
                                        {
                                            (selectedItem?.generalItem?.filter((val) => val?.is_active)?.length > 0 ||
                                                selectedItem?.bulkItem?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0) &&
                                            <Box  >
                                                <Box sx={{ height: { xs: "auto", md: "380px", sm: "auto", lg: "380px" } }} overflow="auto" >
                                                    {
                                                        selectedItem?.generalItem?.filter((val) => val?.is_active)?.length > 0 &&
                                                        <Typography className={classes.propertyTitle}>{t("GENERAL ITEMS")}</Typography>
                                                    }


                                                    {
                                                        selectedItem?.generalItem?.filter((val) => val?.is_active).map((val) => {
                                                            return (
                                                                <CartItemCard
                                                                    list={val}
                                                                    increaseQty={() => addInspectionItem(val, true)}
                                                                    decreaseQty={() => addInspectionItem(val, false)}
                                                                    removeitem={() => removeInspectionItems(val, false)}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {/* {bulkItems?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0 &&
                                                        <Typography className={classes.propertyTitle} sx={{ marginTop: "12px", marginBottom: "12px" }}>TEMPLATE NAME</Typography>
                                                    } */}

                                                    {
                                                        selectedItem?.bulkItem?.filter((val, index) => val?.is_active).map((val) => {
                                                            return (
                                                                <Box>
                                                                    <Typography sx={{ marginTop: "12px", textTransform: "uppercase" }} className={classes.propertyTitle}>{val?.Name}</Typography>
                                                                    {
                                                                        val?.sub_items?.filter((val) => val?.is_active)?.filter((p) => p.is_active).map((r) => {

                                                                            return (

                                                                                <CartItemCard
                                                                                    list={r}
                                                                                    increaseQty={() => addTemplet(r, true)}
                                                                                    decreaseQty={() => addTemplet(r, false)}
                                                                                    removeitem={() => removeTemplate(r, false)}
                                                                                />
                                                                            )
                                                                        })
                                                                    }

                                                                </Box>
                                                            )
                                                        })
                                                    }


                                                </Box>
                                                <Box p={1}>
                                                    <Button fullWidth
                                                        disabled={(selectedItem?.generalItem?.filter((val) => val?.is_active)?.length > 0 ||
                                                            selectedItem?.bulkItem?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0) ? false : isDisableBtn ? true : true}
                                                        variant="contained" onClick={onSubmit}>{t("Map New Item")}</Button>
                                                </Box>
                                            </Box>
                                        }


                                    </Box>

                            }



                        </Grid>
                    </Grid>
            }
        </Box>
    )
}
export default withNamespaces("inspection")(AddInspectionItem)