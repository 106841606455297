//account statement
export const AccountStatementHeading = (t = () => false) => [
    { title: t("Date"), field: "date" },
    { title: t("Transactions"), field: "transaction" },
    { title: t("Details"), field: "details" },
    { title: t("Due On"), field: "dueOn" },
    { title: t("Payments"), field: "payments" },
    { title: t("Amount"), field: "amount" },
    { title: t("Balance"), field: "balance" },
];
export const AccountStatementListPath = [
    "date",
    "transaction",
    "details",
    "dueOn",
    "payments",
    "amount",
    "balance",
];
export const AccountStatementDataType = [
    { type: ["date"], name: "date" },
    { type: ["text"], name: "transaction" },
    { type: ["text"], name: "details" },
    { type: ["date"], name: "dueOn" },
    { type: ["text"], name: "payments" },
    { type: ["text"], name: "amount" },
    { type: ["text"], name: "balance" },
];
export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]
export const initialState = {
    accountNo: "",
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    agreement: { value: "All", label: "All" },
    error: {
        accountNo: "",
        startDate: "",
        endDate: "",
        agreement: "",

    }

}
export const returnEditPayload = (data) => {
    const result = {
        name: data?.data?.name ?? "",
        number: data?.data?.number ?? "",
        vehicle_type: data?.data?.vehicle_type ? {
            label: data?.data?.vehicle_type,
            value: data?.data?.vehicle_type,
        } : "",
        period: data?.data?.period ?
            {
                label: data?.data?.period,
                value: data?.data?.period,
            } : "",
        rate: data?.data?.rate ?? "",
        currency: data?.data?.company?.currency?.symbol ?? "",
        description: data?.data?.description ?? "",
        isEdit: true,
        id: data?.data?.id,
        assets: data?.data?.vehicle_assets ?? [],
        error: {
            description: "",
            number: "",
            period: "",
            rate: "",
            name: "",
            vehicle_type: "",
        }

    }
    return result;
}

export const returnSavePayload = (data, company, assets, userProfileId) => {
    let result2 = {
        rate: data?.rate ? parseInt(data?.rate) : null,
        currency_id: company?.currency_id,
        company_id: company?.value,
        assets: assets,
        name: data?.name ?? null,
        number: data?.number ?? null,
        description: data?.description ?? null,
        vehicle_type: data?.vehicle_type?.value ?? null,
        period: data?.period?.value ?? null,
        id: data?.id?.length > 0 ? data?.id : null,
        user_profile_id: userProfileId ?? null
    }
    return result2
}
