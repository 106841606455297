/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { Heading, Path, StatusOptionList, Type } from '../../utils/emergencyContact/emergencyContactListUtils';
import { useStyles } from "./style";

const EmergencyContact = ({
    t
}) => {
    const defaultFilterState = { status: [true] };

    const defaultAddState = {
        formType: "add",
        id: "",
        property: "",
        propertyName: "",
        name: "",
        department: "",
        contactNumber: "",
        contact: "",
        status: true,
        delete: false,
        error: {
            property: "",
            name: ""
        }
    }
    const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined

    const classes = useStyles()
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [addDialogOpen, setAddDialogOpen] = React.useState(false)
    const [addState, setAddState] = React.useState({ ...defaultAddState })
    const [loading, setLoading] = React.useState("")
    const [propertyData, setPropertyData] = React.useState([])
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [permissions, setPermission] = React.useState({})
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                let properties =
                    company?.selected?.property?.map((x) => {
                        return {
                            value: x.id,
                            label: x.name
                        }


                    })
                setPropertyData([
                    // allProperties,
                    ...properties
                ]);
                setSelectedProperty(properties?.[0])
            }
        }
        // eslint-disable-next-line
    }, [auth])

    // useEffect to get list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedProperty?.value) { getList() }
    }, [selectedProperty, filterData])

    // Function to get list based on the input data
    const getList = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: t("Loading...") })
        const payload = {
            status: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
            company_id: selectedCompany?.value,
            property_ids: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
            search,
            offset,
            limit,
            client: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
        }
        NetworkCall(
            `${config.api_url}/queries/emergency_master/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setList({
                data: r?.data?.data?.emergency_master,
                totalRowsCount: r?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    const Rows = React.useCallback(list?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                property: _?.propertyByID ?? "",
                propertyName: _?.propertyByID?.label ?? "",
                name: _?.name ?? "",
                department: _?.department ?? "-",
                contactNumber: _?.contact_number ?? "-",
                contact: _?.contact_number ? { mobile: _?.contact_number, mobile_code: _?.country_code } : "",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        let properties =
            value?.property?.map((x) => {
                return {
                    value: x.id,
                    label: x.name
                }


            })
        setPropertyData([
            // allProperties,
            ...properties
        ]);
        setSelectedProperty(properties?.[0])
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to open add form
    const handleAdd = () => {
        setAddState({ ...defaultAddState, property: selectedProperty })
        setAddDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddState = {
            formType: type,
            id: tempData?.id ?? "",
            property: tempData?.propertyByID ?? "",
            name: tempData?.name ?? "",
            department: tempData?.department ?? "",
            contact: tempData?.contact_number ?? "",
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddState?.error
        }
        setAddState({ ...tempAddState })
        if (type === "edit" || type === "view") { setAddDialogOpen(true) }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    // Function to update addState
    const updateAddDialogState = (k, v) => {
        let error = addState?.error
        error[k] = ""
        setAddState({ ...addState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addState.error
        if (addState?.property?.length === 0) {
            isValid = false; error.property = t("Property is Required")
        }
        if (addState?.name?.length === 0) {
            isValid = false; error.name = t("Name is Required")
        }
        if (addState?.contact?.length === 0) { isValid = false; error.contact = t("Contact Number is Required"); }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddState({ ...addState, error })
        return isValid
    }

    // Function to create a Emergency Contact
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            setIsDisableBtn(true)
            const currentDate = new Date().toISOString()
            const profileID = localStorage.getItem(LocalStorageKeys.profileID)

            const payload = {
                company_id: selectedCompany?.value ?? undefined,
                property_id: data?.property?.value ?? undefined,
                department: data?.department ?? undefined,
                contact_number: data?.contact ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                name: data?.name ?? undefined,
                updated_at: currentDate ?? undefined,
                updated_by: profileID ?? undefined
            }

            if (data?.formType === "add") {
                payload.client = clientID
                payload.created_at = currentDate ?? undefined
                payload.created_by = profileID ?? undefined
            }

            const insertData = {
                params: data?.id ? [
                    {
                        id: data?.id ?? undefined,
                        ...payload
                    }
                ] : [payload]
            }
            NetworkCall(
                `${config.api_url}/queries/emergency_master/upsert`,
                NetWorkCallMethods.post,
                insertData,
                null,
                true,
                false
            ).then((r) => {
                setAddState({ ...defaultAddState })
                getList()
                setAddDialogOpen(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: data?.id ?
                        (data?.formType === "delete"
                            ? `${data?.name} Emergency Number Deleted Successfully.!!!`
                            : (data?.formType === "active"
                                ? (data?.status
                                    ? `${data?.name} Emergency Number Is Active Now!!!`
                                    : `${data?.name} Emergency Number Is In-Active Now!!!`)
                                : `${data?.name} Emergency Number Updated Successfully.!!!`))
                        : "Emergency Number Created Successfully.!!!",
                })
                setIsDisableBtn(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
                setIsDisableBtn(false)
            })

        } else { return false }
    }

    // Function to load Async Select Options
    const loadOptions = async (search = "", array, type) => {
        setLoading(type)
        let result, query, offset, limit, companyID, variables = 0
        limit = 10
        companyID = selectedCompany?.value
        if (search && !Boolean(array?.length)) { offset = 0 }
        else { offset = array?.length }

        switch (type) {
            case "property":
                query = "/queries/property/list"
                variables = { company_id: companyID, offset, limit, search, is_dropdown: true }
                result = await networkCallBack(query, variables)
                return {
                    options: [...result?.property],
                    hasMore: (array?.length + result?.property?.length) < result?.count
                }
            default:
                return { options: [] }
        }
    }

    // Function for network callback for Async Select Options
    const networkCallBack = async (query, variables = {}) => {
        const options = await NetworkCall(
            `${config.api_url}${query}`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        ).then((r) => {
            let main = r?.data?.data
            setLoading(null)
            return main
        }).catch((e) => {
            setLoading(null)
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
            return null
        })
        return options
    }
    //property change function
    const onPropertyChange = (val) => {
        setSelectedProperty(val)
    }
    const render = () => {
        return (
            <div >
                <Subheader hideBackButton={true} title={t("Emergency Number")}
                    select options={companyList} value={selectedCompany}
                    onchange={(e) => handleCompanyChange(e)}
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    propertyValue={selectedProperty}
                    selectProperty
                    propertyOptions={propertyData}
                />
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <SearchFilter value={searchText} placeholder={t("Search Emergency Number")}
                                handleChange={(value) => handleSearch(value)}
                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>

                                <Stack direction="row"
                                    divider={<Divider orientation="vertical" flexItem sx={{ marginInline: '16px' }} />}>
                                    <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData.status?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                    <Button variant="contained" className={classes.button}
                                        onClick={handleAdd}>
                                        {t("Add Emergency Number")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableWithPagination
                                heading={Heading(t)}
                                rows={Rows}
                                path={Path}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleTableIcon}
                                onClick={() => console.log("")}
                                tableType="no-side"
                                dataType={Type}
                                handlePagination={handleTablePagination}
                                handleChangeLimit={handleTablePageLimit}
                                totalRowsCount={list?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                view={permissions?.read}
                                edit={permissions?.update}
                                delete={permissions?.delete} />
                        </Grid>
                    </Grid>
                    <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                        onApply={(value) => setFilterData(value)}
                        defaultState={defaultFilterState}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                options: [{ label: t("Active"), value: true }, { label: t("Inactive"), value: false }],
                                isMulti: true,
                                state_name: "status",
                                label: t("Status")
                            },
                        ]} />
                    <Dialog
                        className={classes.addDialog}
                        open={addDialogOpen}
                        onClose={() => setAddDialogOpen(false)}>
                        <div className={classes.addDialogHeader}>
                            <Typography className={classes.addDialogHeaderTitle}>
                                {addState?.formType === "add" ? t("Add Emergency Number") :
                                    addState?.formType === "edit" ? t("Edit Emergency Number") :
                                        addState?.formType === "view" ? t("View Emergency Number") : t("Add Emergency Number")}
                            </Typography>
                            <IconButton onClick={() => setAddDialogOpen(false)}
                                className={classes.addDialogCloseButton}>
                                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                            </IconButton>
                        </div>
                        <div className={classes.addDialogBody}>
                            <SelectBox
                                key={selectedCompany?.value}
                                isRequired
                                isReadOnly
                                label={t("Property Name")}
                                placeholder={t("Select Property")}
                                value={addState?.property}
                                onChange={(value) => updateAddDialogState("property", value)}
                                loading={loading === "property"}
                                isPaginate
                                debounceTimeout={800}
                                loadOptions={(search, array) => loadOptions(search, array, 'property')}
                                isError={addState?.error?.property?.length > 0}
                                errorMessage={addState?.error?.property} />
                            <Box height={16} />
                            <TextBox
                                isrequired
                                isReadonly={addState?.formType === "view"}
                                label={t("Name")}
                                placeholder={t("Enter Name")}
                                value={addState?.name ?? ""}
                                onChange={(e) => updateAddDialogState("name", e.target.value)}
                                isError={addState?.error?.name?.length > 0}
                                errorMessage={addState?.error?.name} />
                            <Box height={16} />
                            <TextBox
                                isReadonly={addState?.formType === "view"}
                                label={t("Department")}
                                placeholder={t("Enter Department")}
                                value={addState?.department ?? ""}
                                onChange={(e) => updateAddDialogState("department", e.target.value)} />
                            <Box height={16} />

                            <TextBox
                                isReadonly={addState?.formType === "view"}
                                label={t("Contact Number")}
                                type="number"
                                isrequired
                                placeholder={t("Enter Contact Number")}
                                value={addState?.contact}
                                onChange={(e) => updateAddDialogState("contact", e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'e') { e.preventDefault() }
                                }}
                                isError={addState?.error?.contact?.length > 0}
                                errorMessage={addState?.error?.contact}

                            />
                            {/*                             
                            <MobileNumberInputComponent
                                isRequired
                                noValidate
                                label={"Contact Number"}
                                placeholder={"Enter Number"}
                                value={addState?.contact}
                                handleChange={(value) => updateAddDialogState("contact", value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'e') { e.preventDefault() }
                                }}
                                isError={addState?.error?.contact?.length > 0}
                                errorMessage={addState?.error?.contact} /> */}

                            <Box height={16} />
                            <Typography className={classes.addDialogFieldLabel} noWrap>{t("Status")}</Typography>
                            <div className={classes.addDialogButtonContainer}>
                                {StatusOptionList.map((_) => {
                                    return <Button className={_?.value === addState?.status ?
                                        classes.addDialogButtonSelected :
                                        classes.addDialogButtonUnSelected}
                                        onClick={() => (addState?.formType === "add" ||
                                            addState?.formType === "edit") ?
                                            updateAddDialogState("status", _?.value) : false}>
                                        {t(_?.label)}
                                    </Button>
                                })}
                            </div>
                        </div>
                        {(addState?.formType === "add" || addState?.formType === "edit") &&
                            <div className={classes.addDialogFooter}>
                                {addState?.formType === "edit" && <><Button fullWidth
                                    className={classes.addDialogFooterCloseButton}
                                    onClick={() => setAddDialogOpen(false)}>
                                    {t("Cancel")}
                                </Button></>}
                                <Button variant="contained" fullWidth
                                    className={classes.addDialogFooterButton}
                                    onClick={() => handleCreateEdit(addState)}
                                    disabled={isDisableBtn}>
                                    {addState?.formType === "add" ? t("Create") : t("Save")}
                                </Button>
                            </div>}
                    </Dialog>
                </div>
            </div >
        )
    }

    return (
        (
            <Box>
                {accessCheckRender(render, permissions)}
            </Box>
        )
    )

}
export default withNamespaces("emergencyContact")(EmergencyContact)
