import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { LoadingSection } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { AssetMasterType, NetWorkCallMethods } from "../../../utils";
import { AssetView } from "../../propertyFinder3/component/assetView";
import { filterStyles } from "../../propertyFinder3/component/styles";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
export const UnitSingleDetails = (props) => {
    const { t } = (props);
    const classes = filterStyles();
    const [details, setDetails] = React.useState({
        unit: {},
        rentBreakUp: [],
        quott_unit: {}
    })
    const [image, setImage] = React.useState({
        asset: [],
        assets: []
    })
    const [loading, setLoading] = React.useState(true)
    const [pdf, setPdf] = React.useState(false)
    //get pricing details
    const getUnitPricing = () => {
        NetworkCall(
            `${config?.api_url}/queries/property_search/quotation_units`,
            NetWorkCallMethods.post,
            {
                unit_id: props?.selectedUnits,
                master_type: AssetMasterType.image,
            },
            null,
            true,
            false
          )
        .then(response => {
            setDetails({
                unit: response?.data?.data?.quotation_units[0]?.unit,
                rentBreakUp: response?.data?.data?.quotation_units[0]?.quotation_rent_breakup,
                quott_unit: response?.data?.data?.quotation_units[0]
            })
            setImage({
                asset: response?.data?.data?.quotation_units[0]?.unit?.asset ?? [],
                assets: response?.data?.data?.quotation_units[0]?.unit?.assets ?? [],
            })
            setLoading(false)
        }).catch(err => {
            console.log(err);
        })
    }
    //initial load
    React.useEffect(() => {
        getUnitPricing()
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div >
                {/*top bar */}
                <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
                    <Box flexGrow={1}>
                        <Typography className={classes.detailTitle}>{t("Unit View")}</Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={props?.onClose}>
                            <CloseIcon style={{ fontSize: "1.25rem" }} />
                        </IconButton>
                    </Box>
                </Box>

                {
                    loading ?
                        <div className={classes.Detailroot}>
                            <LoadingSection />
                        </div>
                        :

                        <div className={classes.Detailroot} style={{ overflow: "scroll" }}>
                            {/*asset view */}
                            {
                                image?.assets?.length > 0 ?
                                    <AssetView title="Unit View" asset={image?.asset?.filter(x => x?.asset_type !== 4)?.map(x => x)} assetAll={image?.assets} referenceId={props?.selectedUnits} /> :
                                    <img
                                        src={"/images/propertytumb.png"}
                                        alt=""
                                        style={{
                                            width: "100%",
                                            height: "229px",
                                            objectFit: "cover",
                                            borderRadius: '4px'
                                        }}
                                    />


                            }
                            <Stack direction="row" alignItems="center" >
                                <Typography className={classes.detailTitle}>{details?.unit?.name}</Typography>
                                <Typography className={classes.unitTag}>{details?.unit?.unit_no}</Typography>
                            </Stack>
                            <Typography className={classes.unitSub}>{details?.unit?.property?.name}, {details?.unit?.block?.name}, {details?.unit?.floor?.name}</Typography>
                            <Box height="8px" />
                            {/*unit details */}
                            <Stack direction="row" alignItems="center" >
                                {
                                    details?.unit?.total_bed_rooms &&
                                    <>
                                        <img src="/images/bed.svg" alt="" />
                                        <Typography className={classes.build1}>&nbsp;{details?.unit?.total_bed_rooms}&nbsp;&nbsp;</Typography>
                                        <Box className={classes.dot} />

                                    </>
                                }
                                {
                                    details?.unit?.total_rooms &&
                                    <>
                                        <img src="/images/bath.svg" alt="" />
                                        <Typography className={classes.build1}>&nbsp;{details?.unit?.total_rooms}&nbsp;&nbsp;</Typography>
                                        <Box className={classes.dot} />

                                    </>
                                }
                                {
                                    details?.unit?.unit_types?.name &&
                                    <>
                                        <img src="/images/Group 96257.svg" alt="" />
                                        <Typography className={classes.build1}>&nbsp;{details?.unit?.unit_types?.name}&nbsp;</Typography>
                                        <Box className={classes.dot} />
                                    </>
                                }
                                {
                                    details?.unit?.total_area &&
                                    <>
                                        <img src="/images/range.svg" alt="" />
                                        <Typography className={classes.build1}>{details?.unit?.total_area}&nbsp;{details?.unit?.uom_master?.name}</Typography>

                                    </>
                                }

                            </Stack >
                            <Box height="12px" className={classes.border} />
                            <Box height="12px" />
                            <Stack direction="row" alignItems="center" >
                                <img src="/images/Group 6050.svg" alt="" />
                                <Typography className={classes.rental}>&nbsp;&nbsp;{t("RENTAL BREAK UP")}</Typography>
                            </Stack>
                            {/*break up details */}
                            <>
                                {
                                    details?.rentBreakUp?.filter((val) => !val?.rent_breakup?.is_wallet && !val?.rent_breakup?.is_late_fee && !val?.is_wallet_item)?.map((val) => {
                                        return (
                                            <>
                                                <Grid container>
                                                    <Grid item xs={8}>
                                                        <Typography className={classes.rental1}>{t(val?.inspection_items?.name) ? t(val?.inspection_items?.name) : t(val?.rent_breakup?.name)}</Typography>
                                                        <Typography className={classes.tax}>{t("Tax")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.rental1} textAlign="right">{val?.currency?.symbol}&nbsp;{val?.rent_amount}</Typography>
                                                        <Typography className={classes.tax} textAlign="right">{val?.currency?.symbol}&nbsp;{val?.tax}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )

                                    })
                                }
                       

                                <Box height="14px" className={classes.border} />
                                <Box height="12px" />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.rental1bold}>{t("Total")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.rental1bold} textAlign="right">{details?.rentBreakUp?.[0]?.currency?.symbol} {details?.quott_unit?.unit_total_primary}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                            {/* <Box display="flex" alignItems="center" marginTop="12px">
                                <Box flexGrow={1}>
                                    <Stack direction="row" alignItems="center" >
                                        <img src="/images/Handbook.svg" alt="" />
                                        <Typography className={classes.rental}>&nbsp;&nbsp;Hand Book</Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Typography className={classes.viewdetail} onClick={() => setPdf(true)}>View / Download</Typography>
                                </Box>
                            </Box> */}
                                     {
                                    details?.rentBreakUp?.length > 0 &&
                                    <Box style={{ borderTop: "1px solid #E4E8EE", marginTop: "10px" }}>
                                        {
                                            details?.rentBreakUp?.filter((val) => val?.rent_breakup?.is_wallet || val?.rent_breakup?.is_late_fee || val?.is_wallet_item)?.map((val) => {
                                                return (
                                                    <>
                                                        <Grid container>
                                                            <Grid item xs={8}>
                                                                <Typography className={classes.rental1}>{val?.inspection_items?.name ? val?.inspection_items?.name : val?.rent_breakup?.name}</Typography>
                                                                <Typography className={classes.tax}>{t("Tax")}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className={classes.rental1} textAlign="right">{val?.currency?.symbol}&nbsp;{val?.rent_amount}</Typography>
                                                                <Typography className={classes.tax} textAlign="right">{val?.currency?.symbol}&nbsp;{val?.tax}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )

                                            })
                                        }
                                    </Box>
                                }

                        </div >
                }
            </div >
            {/*hand book detail*/}
            < Dialog fullWidth maxWidth="xs" onClose={() => setPdf(false)} open={pdf} className={classes.dialog} >
                <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
                    <Box flexGrow={1}>
                        <Typography className={classes.detailTitle}>{t("Hand Book")}</Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={() => setPdf(false)}>
                            <CloseIcon style={{ fontSize: "1.25rem" }} />
                        </IconButton>
                    </Box>
                </Box>
                <div style={{ height: "520px", overflow: "scroll" }}>

                    {
                        image?.assets?.filter((val) => val?.asset_type === 4)?.length > 0 ?
                            <DocumentViewer url={image?.assets?.filter((val) => val?.asset_type === 4)?.map(x => x)?.[0]?.url} />
                            :
                            <Typography textAlign="center" marginTop="30px">{t("No Hand Book Found")}</Typography>
                    }
                </div>
            </Dialog >
        </>
    )
}