import {
    Box, Divider, Stack, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    // Button,
} from "@mui/material";
import { useContext } from "react";
import { SubscriptionsContext } from "./subscriptionsContext";
import { CircularProgressBar } from "../../components/circularProgressBar";
import { UnitIcon } from "./assets/unitIcon";
// import { MarketplaceIcon } from "./assets/marketplaceIcon";
// import { RightChevronIcon } from "./assets/rightChevronIcon";
// import { ModuleIcon } from "./assets/moduleIcon";
import { GreenTickIcon } from "./assets/greenTickIcon";

export const PlanAndSubscriptions = () => {
    const {
        t=()=>false,
        planAndSubscriptionClasses: classes,
        current_plan_state,
        all_subscription_state,
    } = useContext(SubscriptionsContext);

    return (
        <Stack className={classes.tab_content_div}
            direction={{ sm: "column", md: "row" }} spacing={"20px"}>
            <Box className={classes.current_plan_div}
                width={{ sm: "100%", md: "30%" }}>
                {/* <Typography className={classes.title_1} mb={"12px"}>
                    {t("CURRENT SUBSCRIPTION")}
                </Typography>
                <Stack className={classes.current_plan_basic_details_div}
                    direction={"row"} spacing={"10px"} alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Stack spacing={"8px"}>
                        <Typography className={classes.plan_name_text}>
                            {current_plan_state?.name}
                        </Typography>
                        <Typography className={classes.valid_till_text}>
                            {`${t("Valid Till")} ${current_plan_state?.valid_till}`}
                        </Typography>
                    </Stack>
                    <img src={current_plan_state?.logo} alt={"plan_logo"} />
                </Stack> */}
                <Typography className={classes.title_1} mb={"12px"}>
                    {t("PLAN USAGE DETAILS")}
                </Typography>
                <Stack className={classes.current_plan_storage_usage_div}
                    direction={"row"} spacing={"10px"} alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Stack spacing={"8px"}>
                        <Typography className={classes.storage_info_text}>
                            {t("STORAGE USED")}
                        </Typography>
                        <Stack direction={"row"} spacing={"4px"} alignItems={"end"}>
                            <Stack direction={"row"} spacing={"4px"} alignItems={"end"}
                                divider={
                                    <Typography className={classes.storage_detail_text}>
                                        {"/"}
                                    </Typography>
                                }>
                                <Typography className={classes.storage_detail_text}>
                                    {current_plan_state?.used_storage_size}
                                </Typography>
                                <Typography className={classes.storage_detail_text}>
                                    {current_plan_state?.storage_size}
                                </Typography>
                            </Stack>
                            <Typography className={classes.storage_metric_text}>
                                {t("GB")}
                            </Typography>
                        </Stack>
                    </Stack>
                    <CircularProgressBar thickness={8} size={60}
                        value={current_plan_state?.used_storage_size_percentage} />
                </Stack>
                <Stack spacing={"16px"}
                    divider={<Divider className={classes.plan_usage_divider} light={true} />}>

                    <Stack direction={"row"} alignItems={"center"}>
                        <UnitIcon />
                        <Stack spacing={"6px"} sx={{ marginInlineStart: "8px" }}>

                            <Stack direction={"row"} spacing={"4px"} alignItems={"end"}>
                                <Stack direction={"row"} spacing={"4px"} alignItems={"end"}
                                    divider={
                                        <Typography className={classes.plan_usage_value_text}>
                                            {"/"}
                                        </Typography>
                                    }>
                                    <Typography className={classes.plan_usage_value_text}>
                                        {current_plan_state?.used_unit_size}
                                    </Typography>
                                    <Typography className={classes.plan_usage_value_text}>
                                        {current_plan_state?.unit_size}
                                    </Typography>
                                </Stack>
                                <Typography className={classes.plan_usage_metric_text}>
                                    {t("UNITS")}
                                </Typography>
                            </Stack>
                            <Typography className={classes.plan_usage_info_text}>
                                {t("UNITS AVAILED")}
                            </Typography>
                        </Stack>
                    </Stack>
                    {/* <Stack className={classes.plan_marketplace_usage_div}
                        direction={"row"} spacing={"8px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                            <MarketplaceIcon />
                            <Stack spacing={"6px"}>
                                <Stack direction={"row"} spacing={"4px"} alignItems={"end"}>
                                    <Typography className={classes.plan_usage_value_text}>
                                        {current_plan_state?.marketplace_enabled}
                                    </Typography>
                                    <Typography className={classes.plan_usage_metric_text}>
                                        {t("ENABLED")}
                                    </Typography>
                                </Stack>
                                <Typography className={classes.plan_usage_info_text}>
                                    {t("MARKETPLACE INTEGRATIONS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <RightChevronIcon />
                    </Stack> */}
                    {/* <Stack className={classes.plan_module_usage_div}
                        direction={"row"} spacing={"8px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        <Stack spacing={"12px"}>
                            <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                                <ModuleIcon />
                                <Stack spacing={"6px"}>
                                    <Stack direction={"row"} spacing={"4px"} alignItems={"end"}>
                                        <Stack direction={"row"} spacing={"4px"} alignItems={"end"}
                                            divider={
                                                <Typography className={classes.plan_usage_value_text}>
                                                    {"/"}
                                                </Typography>
                                            }>
                                            <Typography className={classes.plan_usage_value_text}>
                                                {current_plan_state?.used_users_size}
                                            </Typography>
                                            <Typography className={classes.plan_usage_value_text}>
                                                {current_plan_state?.users_size}
                                            </Typography>
                                        </Stack>
                                        <Typography className={classes.plan_usage_metric_text}>
                                            {t("USERS")}
                                        </Typography>
                                    </Stack>
                                    <Typography className={classes.plan_usage_info_text}>
                                        {t("MODULE USER AVAILED")}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                                <Box width={"44px"} />
                                <Typography className={classes.plan_upgrade_text}>
                                    {t("Upgrade Your Plan")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <RightChevronIcon />
                        <Box className={classes.plan_module_usage_tag}>
                            <Typography className={classes.plan_module_usage_tag_text}>
                                {t("Almost Full")}
                            </Typography>
                        </Box>
                    </Stack> */}
                </Stack>
            </Box>
            <Box className={classes.available_plan_div}
                width={{ sm: "100%", md: "70%" }}>
                <TableContainer className={classes.available_plan_table_container}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {all_subscription_state?.table_header?.map?.((header, index) => {
                                    return (
                                        index === 0 ?
                                            <TableCell className={classes.table_header_sticky_cell_0}>
                                                <Stack direction={"column"} spacing={"10px"}>
                                                    <Typography className={classes.table_header_sticky_cell_0_name}>
                                                        {header?.name}
                                                    </Typography>
                                                    <Typography className={classes.table_header_sticky_cell_0_description}>
                                                        {header?.description}
                                                    </Typography>
                                                </Stack>
                                            </TableCell> :
                                            <TableCell className={classes.table_header_cell_remaining}>
                                                {/* <Stack direction={"column"} spacing={"9px"}> */}
                                                <Stack direction={"column"} spacing={"6px"}>
                                                    <Typography className={classes.table_header_cell_remaining_name}
                                                        sx={{ color: header?.color }}>
                                                        {header?.name}
                                                    </Typography>
                                                    <Typography className={classes.table_header_cell_remaining_price}>
                                                        {`${header?.price ?? `-`} / ${header?.price_metric ?? `-`}`}
                                                    </Typography>
                                                    <Typography className={classes.table_header_cell_remaining_description}>
                                                        {header?.description}
                                                    </Typography>
                                                </Stack>
                                                {/* <Button className={classes.contact_sales_button}
                                                        disabled={header?.is_current_plan}
                                                        onClick={() => !header?.is_current_plan && console.log("hello")}>
                                                        <Typography className={header?.is_current_plan ?
                                                            classes.current_plan_text : classes.contact_sales_text}>
                                                            {header?.is_current_plan ?
                                                                t("Current Plan") :
                                                                t("Contact Sales")}
                                                        </Typography>
                                                    </Button> */}
                                                {/* </Stack> */}
                                            </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {all_subscription_state?.table_rows?.map?.((row) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.table_row_sticky_cell_0}>
                                            <Typography className={classes.table_row_sticky_cell_0_name}>
                                                {row.name}
                                            </Typography>
                                        </TableCell>
                                        {
                                            row?.plans_availability?.map?.((plan) => {
                                                return (
                                                    <TableCell className={classes.table_row_cell_remaining}>
                                                        <Box className={classes.table_row_cell_remaining_box}>
                                                            {
                                                                plan?.is_available ?
                                                                    <GreenTickIcon /> :
                                                                    <Box className={classes.module_not_available_place_holder} />
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Stack>
    )
}