import { Box, Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useEffect, useState } from 'react';
import { SelectBox, TextBox, ToggleButtonComponent } from '../../../components';
import { AlertContext, BackdropContext } from '../../../contexts';
import { AlertProps, Bold } from '../../../utils';
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { networkCallback } from '../saleagent/utilies';
const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize: "0.75rem",
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}));

const Label = styled(Typography)(({ theme }) => ({
      color: theme.typography.color.tertiary,
      fontSize: "0.75rem",
}));

const buttonStyle = {
      borderColor: "#E4E8EE",
      borderRadius: 1,
      backgroundColor: "#fff",
}

const textBoxStyle = {
      padding: "8px 14px",
      borderRadius: 8,
      height: 47
}

export const BankingDetails = (props) => {

      const { updateState = null, } = props;

      const classes = useStylesCreation();
      const alert = useContext(AlertContext);
      const [loading, setLoading] = useState(false);
      const backdrop = useContext(BackdropContext);
      const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([])

      // useEffect
      useEffect(() => {
            getBankAccountType()
            // eslint-disable-next-line
      }, [])


      // drop down function start
      // get account drop down option
      const getBankAccountType = async () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Fetching Bank Account Type"
            })
            let result = await networkCallback({ enumName: ["bank_account_type"] }, "enum");
            if (!result) {
                  return []
            }
            setBankAccountTypeOptions(result?.bank_account_type ?? []);
            backdrop.setBackDrop({
                  ...backdrop,
                  open: false,
            })
      }

      const loadOptionData = async (search, array, type) => {
            setLoading(type);
            let result;
            let enumTypes = ["bank_routing_type"]

            if (enumTypes?.includes(type)) {
                  result = await networkCallback({ enumName: [type] }, "enum", setLoading);
                  if (!result) {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Something went wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return {
                              options: [],
                              hasMore: false
                        }
                  }
                  return {
                        options: result?.[type] ?? [],
                        hasMore: false
                  }
            }
            else {
                  return {
                        options: [],
                        hasMore: false
                  }
            }
      }
      // end

      const manualCurrencyResponse = (array) => {
            return array?.currency_master
      }

      const manualCountryResponse = (array) => {
            return array?.country_master
      }

      return (
            <div>
                  {/* Banking Basic Details */}
                  <Box className={classes.imagebox1}>
                        <CustomTypography>Banking Basic Details</CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Bank Name */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Bank Name"
                                          placeholder="Enter Bank Name"
                                          value={props?.data?.bankName ?? ""}
                                          onChange={(e) => updateState("bankName", e.target.value)}
                                          isError={props?.data?.error?.bankName?.length > 0}
                                          errorMessage={props?.data?.error?.bankName}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Branch Name */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Branch Name"
                                          placeholder="Enter Branch Name"
                                          value={props?.data?.branchName ?? ""}
                                          onChange={(e) => updateState("branchName", e.target.value)}
                                          isError={props?.data?.error?.branchName?.length > 0}
                                          errorMessage={props?.data?.error?.branchName}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Currency */}
                                    <SelectBox
                                          isRequired={true}
                                          label={'Currency'}
                                          placeholder={'Select Currency'}
                                          // options={[]}
                                          loading={loading === "currency"}
                                          loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                      "queries/currency_master/getAll",
                                                      {
                                                            "orderByKey": "name",
                                                            "orderByValue": "asc"
                                                      },
                                                      search,
                                                      array,
                                                      handleLoading,
                                                      "data",
                                                      {},
                                                      manualCurrencyResponse
                                                )}
                                          isPaginate
                                          debounceTimeout={800}
                                          value={props?.data?.currency ?? null}
                                          onChange={(value) => updateState('currency', value)}
                                          isError={props?.data?.error?.currency?.length > 0}
                                          errorMessage={props?.data?.error?.currency}
                                    />
                              </Grid>
                              {/* bankAccountType */}
                              <Grid item xs={12} sm={4} md={4}>
                                    <Label
                                          variant="body1"
                                          color="textsecondary"
                                          align="left"
                                          gutterBottom
                                    // noWrap
                                    >
                                          {"Bank Account Type"}
                                          <Typography component="span" color={"error"} sx={{ ml: 1 / 2, fontSize: "0.75rem" }}>
                                                *
                                          </Typography>
                                    </Label>
                                    <ToggleButtonComponent
                                          options={bankAccountTypeOptions}
                                          value={props?.data?.bankAccountType ?? []}
                                          onChange={(value) => updateState('bankAccountType', value)}
                                          isMulti={false}
                                          fullWidth={false}
                                          buttonStyle={buttonStyle}
                                          isError={props?.data?.error?.bankAccountType?.length > 0}
                                          errorMessage={props?.data?.error?.bankAccountType}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Account Number */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Account Number"
                                          placeholder="Enter Account Number"
                                          value={props?.data?.accountNumber ?? ""}
                                          onChange={(e) => updateState("accountNumber", e.target.value)}
                                          isError={props?.data?.error?.accountNumber?.length > 0}
                                          errorMessage={props?.data?.error?.accountNumber}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Bank Routing Type */}
                                    <SelectBox
                                          isRequired={true}
                                          label={'Bank Routing Type'}
                                          placeholder={'Select Bank Routing Type'}
                                          // options={[]}
                                          loadOptions={(search, array) => loadOptionData(search, array, "bank_routing_type")}
                                          isPaginate
                                          debounceTimeout={800}
                                          loading={loading === "bank_routing_type"}
                                          isSearchable={false}
                                          value={props?.data?.bankRoutingType ?? null}
                                          onChange={(value) => updateState('bankRoutingType', value)}
                                          isError={props?.data?.error?.bankRoutingType?.length > 0}
                                          errorMessage={props?.data?.error?.bankRoutingType}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Bank Routing Code */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Bank Routing Code"
                                          placeholder="Enter Bank Routing Code"
                                          value={props?.data?.bankRoutingCode ?? ""}
                                          onChange={(e) => updateState("bankRoutingCode", e.target.value)}
                                          isError={props?.data?.error?.bankRoutingCode?.length > 0}
                                          errorMessage={props?.data?.error?.bankRoutingCode}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Address Line 1 */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Address Line 1"
                                          placeholder="Enter Address Line 1"
                                          value={props?.data?.bankaddressLineOne ?? ""}
                                          onChange={(e) => updateState("bankaddressLineOne", e.target.value)}
                                          isError={props?.data?.error?.bankaddressLineOne?.length > 0}
                                          errorMessage={props?.data?.error?.bankaddressLineOne}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Address Line 2 */}
                                    <TextBox
                                          {...textBoxStyle}
                                          // isrequired
                                          label="Address Line 2"
                                          placeholder="Enter Address Line 2"
                                          value={props?.data?.bankaddressLineTwo ?? ""}
                                          onChange={(e) => updateState("bankaddressLineTwo", e.target.value)}
                                          isError={props?.data?.error?.bankaddressLineTwo?.length > 0}
                                          errorMessage={props?.data?.error?.bankaddressLineTwo}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* City */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label={'City'}
                                          placeholder={'Select City'}
                                          options={[]}
                                          value={props?.data?.bankcity ?? null}
                                          onChange={(e) => updateState('bankcity', e.target.value)}
                                          isError={props?.data?.error?.bankcity?.length > 0}
                                          errorMessage={props?.data?.error?.bankcity}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Country */}
                                    {/* <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label={'Country'}
                                          placeholder={'Select Country'}
                                          options={[]}
                                          value={props?.data?.bankcountry ?? null}
                                          onChange={(e) => updateState('bankcountry', e.target.value)}
                                          isError={props?.data?.error?.bankcountry?.length > 0}
                                          errorMessage={props?.data?.error?.bankcountry}
                                    /> */}
                                    <SelectBox
                                          label={'Country'}
                                          placeholder={'Select Country'}
                                          value={props?.data?.bankcountry ?? null}
                                          isPaginate
                                          debounceTimeout={800}
                                          loading={loading === "country"}
                                          loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                      "queries/country_master/list",
                                                      {
                                                            "orderByKey": "country_name",
                                                            "orderByValue": "asc"
                                                      },
                                                      search,
                                                      array,
                                                      handleLoading,
                                                      "data",
                                                      {},
                                                      manualCountryResponse
                                                )}
                                          onChange={(value) => props?.updateState("bankcountry", value)}
                                          isError={props?.data?.error?.bankcountry?.length > 0}
                                          errorMessage={props?.data?.error?.bankcountry}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    {/* Cheque Name */}
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Cheque Name"
                                          placeholder="Enter Cheque Name"
                                          value={props?.data?.chequeName ?? ""}
                                          onChange={(e) => updateState("chequeName", e.target.value)}
                                          isError={props?.data?.error?.chequeName?.length > 0}
                                          errorMessage={props?.data?.error?.chequeName}
                                    />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3}>
                                    <TextBox
                                          {...textBoxStyle}
                                          isrequired
                                          label="Preferred Cash Collection Office"
                                          placeholder="Enter Preferred Cash Collection Office"
                                          value={props?.data?.preferredCashCollectionOffice ?? ""}
                                          onChange={(e) => updateState("preferredCashCollectionOffice", e.target.value)}
                                          isError={props?.data?.error?.preferredCashCollectionOffice?.length > 0}
                                          errorMessage={props?.data?.error?.preferredCashCollectionOffice}
                                    />
                              </Grid>
                        </Grid>
                  </Box>
            </div>
      )
}