import React from 'react'
import { ProjectedBOMStyle } from './style'
import { TextBox } from '../../../components'
import { CustomSelectBox } from '../../../components/customSelectBox'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Box, Stack, Tooltip } from "@mui/material"
import { ProjectContext } from '../projectContext'
import { DeleteIcon } from '../../../assets'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export const AddProjectedBOM = ({
    t = () => false
}) => {
    const classes = ProjectedBOMStyle()
    const header = [t("Item Type"), t("Item Category"), t("Sub-Category"), t("Item"), t("Value"), t("Qty"), t("Total Amount"), ""]
    const {
        addUOM,
        enumValue,
        addUOMTable,
        deleteUOMTableRow,
        selectedCompany,
        updateProjectedBOM
    } = React.useContext(ProjectContext)

    const itemMasterManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    };

    return (
        <Box p={2}>
            <TableContainer className={classes.table}>
                <Table>
                    <TableHead className={classes.head}>
                        <TableRow>
                            {
                                header?.map((e) => {
                                    return (
                                        <TableCell className={classes.headText} sx={{ width: 200 }}>{e}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            addUOM?.map((e, i) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.bodyCell}>
                                            <CustomSelectBox
                                                value={e?.itemType}
                                                menuOptionWidth={250}
                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                options={enumValue?.inspection_item_type}
                                                onChange={(val) => {
                                                    updateProjectedBOM("itemType", val, e)
                                                }}
                                                placeholder={t("Select Item Type")}
                                            // isReadOnly={stateData?.type === "view"}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <CustomSelectBox
                                                value={e?.itemCategory}
                                                menuOptionWidth={250}
                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                options={enumValue?.item_type}
                                                onChange={(val) => {
                                                    updateProjectedBOM("itemCategory", val, e)
                                                }}
                                                placeholder={t("Select Item Category")}
                                                // isReadOnly={stateData?.type === "view"}
                                                networkCallData={
                                                    {
                                                        path: "inspection_item_category",
                                                        payload: {
                                                            item_type: [addUOM?.[i]?.itemType?.value]
                                                        },
                                                        mappingVariable: "list",
                                                    }
                                                }
                                                key={JSON.stringify(addUOM?.[i]?.itemType)}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <CustomSelectBox
                                                value={e?.subCategory}
                                                menuOptionWidth={250}
                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                options={enumValue?.item_type}
                                                onChange={(val) => {
                                                    updateProjectedBOM("subCategory", val, e)
                                                }}
                                                placeholder={t("Select Sub Category")}
                                                // isReadOnly={stateData?.type === "view"}
                                                networkCallData={
                                                    {
                                                        path: "inspection_item_subcategory/get",
                                                        payload: {
                                                            item_type: [addUOM?.[i]?.itemType?.value],
                                                            is_active: [true]
                                                        },
                                                        mappingVariable: "data",
                                                        manualResponse: itemMasterManualResponse
                                                    }
                                                }
                                                key={JSON.stringify(addUOM?.[i]?.itemType, addUOM?.[i]?.itemCategory)}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                <Box sx={{ width: "150px" }}>
                                                    <CustomSelectBox
                                                        value={e?.item}
                                                        menuOptionWidth={250}
                                                        menuOptionPadding={"0px 0px 8px 0px"}
                                                        options={enumValue?.item_type}
                                                        onChange={(val) => {
                                                            updateProjectedBOM("item", val, e)
                                                        }}
                                                        placeholder={t("Select Item")}
                                                        // isReadOnly={stateData?.type === "view"}
                                                        networkCallData={
                                                            {
                                                                path: "free_text_invoice/get_free_text_item_types",
                                                                payload: {
                                                                    company_id: selectedCompany?.value,
                                                                    item_type: "Item Master",
                                                                    item_master_type: addUOM?.[i]?.itemType?.value,
                                                                    item_master_category: addUOM?.[i]?.itemCategory?.value,
                                                                    subcategory_id: addUOM?.[i]?.subCategory?.value,
                                                                    component_type: "item_based"
                                                                },
                                                                mappingVariable: "items",
                                                                manualResponse: itemMasterManualResponse
                                                            }
                                                        }
                                                        key={JSON.stringify(addUOM?.[i]?.itemType, addUOM?.[i]?.itemCategory)}
                                                    />
                                                </Box>
                                                {
                                                    (e?.itemError?.length > 0) &&
                                                    <Tooltip arrow title={e?.itemError ?? ""} placement="top">
                                                        <Box m="4px 0px 0px 4px">
                                                            <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                        </Box>
                                                    </Tooltip>
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <TextBox
                                                label={""}
                                                height={38}
                                                value={e?.value ?? ""}
                                                padding={"6px 8px"}
                                                onChange={(val) => {
                                                    updateProjectedBOM("value", val.target.value, e)
                                                }}
                                                placeholder={t("Enter Value")}
                                                endAdornment={<Typography className={classes.currencySymText}>{selectedCompany?.currency_symbol}</Typography>}
                                                onBlur={(val) => {
                                                    val?.target?.value?.length === 0 ?
                                                        updateProjectedBOM("value", 0, e)
                                                        : updateProjectedBOM("value", val.target.value, e)

                                                }}
                                            // isReadonly={stateData?.type === "view"}
                                            />
                                        </TableCell>
                                        {/* <TableCell className={classes.bodyCell}>
                                            <CustomSelectBox
                                                value={e?.uom}
                                                menuOptionWidth={250}
                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                options={enumValue?.item_type}
                                                onChange={(val) => {
                                                    updateProjectedBOM("uom", val, e)
                                                }}
                                                placeholder={"Select UOM"}
                                                // isReadOnly={stateData?.type === "view"}
                                                networkCallData={
                                                    {
                                                        path: "free_text_invoice/get_free_text_item_types",
                                                        payload: {
                                                            company_id: 2,
                                                            item_type: "Item Master",
                                                            item_master_type: addUOM?.[i]?.itemType?.value,
                                                            item_master_category: addUOM?.[i]?.itemCategory?.value,
                                                            component_type: "item_based"
                                                        },
                                                        mappingVariable: "items",
                                                        manualResponse: itemMasterManualResponse
                                                    }
                                                }
                                                key={JSON.stringify(addUOM?.[i]?.itemType, addUOM?.[i]?.itemCategory)}
                                            />
                                        </TableCell> */}
                                        <TableCell className={classes.bodyCell}>
                                            <TextBox
                                                label={""}
                                                height={38}
                                                value={e?.qty ?? ""}
                                                padding={"6px 8px"}
                                                onChange={(val) => {
                                                    updateProjectedBOM("qty", val.target.value, e)
                                                }}
                                                placeholder={t("Enter Qty")}
                                                endAdornment={<Typography className={classes.currencySymText}>{selectedCompany?.currency_symbol}</Typography>}
                                                onBlur={(val) => {
                                                    val?.target?.value?.length === 0 ?
                                                        updateProjectedBOM("qty", 0, e)
                                                        : updateProjectedBOM("qty", val.target.value, e)

                                                }}
                                            // isReadonly={stateData?.type === "view"}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <TextBox
                                                label={""}
                                                height={38}
                                                value={e?.totalAmount ?? ""}
                                                padding={"6px 8px"}
                                                onChange={(val) => {
                                                    updateProjectedBOM("totalAmount", val.target.value, e)
                                                }}
                                                placeholder={t("Enter Total Amount")}
                                                endAdornment={<Typography className={classes.currencySymText}>{selectedCompany?.currency_symbol}</Typography>}
                                                isReadonly={true}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Box onClick={() => deleteUOMTableRow(e)}><DeleteIcon /></Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography className={classes.viewText} onClick={() => addUOMTable()}>+ {t("Add New")}</Typography>
        </Box>
    )
}