import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Avatar, Box, Popover, Skeleton, Stack, Typography } from "@mui/material";
import React from 'react';
import ShowMoreText from "react-show-more-text";
import { LeadArchivedIcon } from "../../../../assets/leadArchive";
import { LeadCreatedIcon } from "../../../../assets/leadCreated";
import { LeadLostIcon } from "../../../../assets/leadLost";
import { LeadQualifiedIcon } from "../../../../assets/leadQualified";
import { LeadTransferredIcon } from "../../../../assets/leadTransferred";
import { LeadUnArchievedIcon } from "../../../../assets/leadUnArchive";
import NoDataFound from "../../../../assets/noData";
import { convertTimeUtcToZoneCalander, useWindowDimensions } from "../../../../utils";
import { useStyles } from './styles';


export const LeadTracker = (props) => {
    // styles
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState({})
const size = useWindowDimensions()
    const open = Boolean(anchorEl);
    const handlePopoverOpen = (event, val) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(val)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <div   style={{
            height: size?.height - 250,
            textAlign: "-webkit-auto",
            overflow: "auto",
          }}>
            <div>
                {
                    props?.trackData?.length > 0 ? props?.trackData?.map((v, i) => {
                        return (
                            <Timeline style={{ padding: 0, color: "#fff", margin: 0 }} className={classes.menuItem}>
                                <TimelineItem >
                                    <TimelineSeparator>
                                        <TimelineDot style={{ backgroundColor: '#fff', boxShadow: 'none', padding: 0, margin: 0 }}>
                                            <div>
                                                {/* <Box className={classes.timedot} /> */}
                                                {props?.isLoading
                                                    ? <Skeleton animation="wave" variant='circular' height={50} width={50} />
                                                    : v?.title === "Lead Created" ? <Box className={classes.leadImage}><LeadCreatedIcon /></Box>
                                                        : v?.title === "Lead Transferred" ? <LeadTransferredIcon />
                                                            : v?.title === "Lead Qualified" ? <LeadQualifiedIcon />
                                                                : v?.title === "Lead Unarchived" ? <LeadUnArchievedIcon/>
                                                                    : v?.title === "Lead Archived" ? <LeadArchivedIcon />
                                                                        : (v?.title === "Lead Disqualified" || v?.title === "Lead Unqualified") ? <LeadLostIcon />
                                                                            : <Avatar src={v?.image_url ?? ""} />}
                                            </div>
                                        </TimelineDot>
                                        {props?.trackData?.length - 1 !== i && (
                                            <TimelineConnector
                                                variant="TimelineDot"
                                                className={classes.dot_line}
                                            />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineContent className={classes.timeLineMain}>
                                        <div className={props?.isLoading === "loading" ? classes.profileroot2 : classes.profileroot3}>
                                            {props?.isLoading
                                                ? <>
                                                    <Skeleton animation="wave" variant='text' height={21} width={"50%"} />
                                                    <Skeleton animation="wave" variant='text' height={18} width={"20%"} />
                                                </>
                                                : <>
                                                    <Typography className={classes.message}>{v?.title ?? ""}</Typography>
                                                    {v?.title === "Lead Transferred" ?
                                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                                            {v?.transfer_from!==null && v?.transfer_from?.first_name?.length>0&&
                                                                <>
                                                                    <Typography className={classes.timelinecreated}>{`From: `}</Typography>
                                                                    <Typography className={classes.firstName} aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                        aria-haspopup="true"
                                                                        onMouseEnter={(e) => handlePopoverOpen(e, { createdBy: v?.transfer_from })}
                                                                        onMouseLeave={handlePopoverClose}>{` ${v?.transfer_from?.first_name}`} &nbsp;</Typography>
                                                                </>
                                                            }
                                                            {v?.transfer_to!==null&&v?.transfer_to?.first_name?.length>0&&
                                                                <>
                                                                    <Typography className={classes.timelinecreated}>{`To: `}</Typography>
                                                                    <Typography className={classes.firstName} aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                        aria-haspopup="true"
                                                                        onMouseEnter={(e) => handlePopoverOpen(e, { createdBy: v?.transfer_to })}
                                                                        onMouseLeave={handlePopoverClose}>{` ${v?.transfer_to?.first_name}`} &nbsp;</Typography>
                                                                    <Typography className={classes.timelinecreated}>{`on ${v?.created_at ? convertTimeUtcToZoneCalander(v?.created_at, 'yyyy-MM-DD hh:mm A') : '-'}`}</Typography>
                                                                </>
                                                            }
                                                        </Stack>
                                                        :v?.createdBy?.first_name?.length>0&&
                                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                                            <Typography className={classes.timelinecreated}>{`By: `}</Typography>
                                                            <Typography className={classes.firstName} aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                onMouseEnter={(e) => handlePopoverOpen(e, v)}
                                                                onMouseLeave={handlePopoverClose}>{`${v?.createdBy?.first_name}`} &nbsp;</Typography>
                                                            <Typography className={classes.timelinecreated}>{`on ${v?.created_at ? convertTimeUtcToZoneCalander(v?.created_at, 'yyyy-MM-DD hh:mm A') : '-'}`}</Typography>
                                                        </Stack>
                                                    }
                                                    {v?.title === "Lead Created" &&
                                                        <>
                                                            {v?.proxy_lead?.internal_lead_source !== null &&
                                                                <Stack direction="row" spacing={0.5} alignItems="center" mt={0.5}>
                                                                    <Typography className={classes.timelinecreated}>{`${props?.t("Internal Lead Source")}: `}</Typography>
                                                                    <Typography className={classes.source_title}>{v?.proxy_lead?.internal_lead_source?.name}</Typography>
                                                                </Stack>
                                                            }{v?.proxy_lead?.domain_referrer !== null &&
                                                                <Stack direction="row" spacing={0.5} alignItems="center" mt={0.5}>
                                                                    <Typography className={classes.timelinecreated}>{`${props?.t("External Lead Source")}: `}</Typography>
                                                                    <a
                                                                        href={v?.proxy_lead?.domain_referrer}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className={classes.source_title}
                                                                        style={{ textDecoration: 'none' }}
                                                                    >{v?.proxy_lead?.domain_referrer}</a>
                                                                </Stack>
                                                            }</>
                                                    }
                                                    <Box mt={0.5}>
                                                        {v?.note !== "" && v?.note !== null &&
                                                            <ShowMoreText
                                                                lines={3}
                                                                more={props?.t("Show More")}
                                                                less={props?.t("Show Less")}
                                                                className={classes.noteText}
                                                                anchorClass={classes.seeMoreLessTextStyle}
                                                                expanded={false}
                                                                truncatedEndingComponent={"... "}
                                                            >
                                                                {(v?.note)?.match(/\n+/g) ?
                                                                    <Typography className={classes.noteText} dangerouslySetInnerHTML={{ __html: `${props?.t("NOTE")}: ${(v?.note).replace(/\n+/g, "<br />")}` ?? "" }}>
                                                                    </Typography>
                                                                    : <Typography className={classes.noteText} dangerouslySetInnerHTML={{ __html: `${props?.t("NOTE")}: ${v?.note}` ?? "" }}>
                                                                    </Typography>
                                                                }
                                                            </ShowMoreText>

                                                        }
                                                    </Box>
                                                </>}
                                        </div>
                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'left',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                            className={classes.menuList}
                                        >
                                            <Box p={2}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Avatar src={selectedUser?.createdBy?.image_url} className={classes.leadImage} />
                                                    <Box>
                                                        <Typography className={classes.message}>{selectedUser?.createdBy?.first_name}</Typography>
                                                        <Stack direction="row" spacing={1} alignItems={"center"}>
                                                            <Typography className={classes.subDetailText}>{`${selectedUser?.createdBy?.mobile_no_country_code} ${selectedUser?.createdBy?.mobile_no}`}</Typography>
                                                            <Box className={classes.timedot} />
                                                            <Typography className={classes.subDetailText}>{selectedUser?.createdBy?.email_id}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Popover>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>

                        );
                    })
                        : (!props?.isLoading && props?.trackData?.length === 0) ?
                            <Box display={"flex"} justifyContent={"center"}>
                                <NoDataFound />
                            </Box>
                            : props?.isLoading && Array.from(Array(5).keys())?.map((c, i) => {
                                return (
                                    <Timeline style={{ padding: 0, color: "#fff", margin: 0 }} className={classes.menuItem}>
                                        <TimelineItem >
                                            <TimelineSeparator>
                                                <TimelineDot style={{ backgroundColor: '#fff', boxShadow: 'none', padding: 0, margin: 0 }}>
                                                    <div>
                                                        <Skeleton animation="wave" variant='circular' height={50} width={50} />

                                                    </div>
                                                </TimelineDot>
                                                {Array.from(Array(5).keys())?.length - 1 !== i && (
                                                    <TimelineConnector
                                                        variant="TimelineDot"
                                                        className={classes.dot_line}
                                                    />
                                                )}
                                            </TimelineSeparator>
                                            <TimelineContent className={classes.timeLineMain}>
                                                <div className={props?.isLoading === "loading" ? classes.profileroot2 : classes.profileroot3}>
                                                    <Skeleton animation="wave" variant='text' height={21} width={"50%"} />
                                                    <Skeleton animation="wave" variant='text' height={18} width={"20%"} />
                                                </div>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                )
                            })

                }
            </div>
        </div>
    )
}