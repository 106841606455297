import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from 'react';
import { SelectBox, ToggleButtonComponent } from '../../components';
import { AuthContext } from "../../contexts";
import { withNamespaces } from "react-i18next";


const CustomTypography = styled(Typography)({
    color: "#4E5A6B",
    fontSize:"0.75rem",
    marginTop: "10px",
})

const CustomGrid = styled(Grid)(({ theme }) => ({
    padding: `${theme.spacing(1)} 10px`
}))

const FINAL_STATUS_OPTIONS = [
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'All', value: 'All' }
]

const FilterComponent = ({
    data = {},
    onChange = null,
    applyFilter = null,
    clearAllFilter = null,
    t
}) => {
    const auth = React.useContext(AuthContext);
    const [companyList, setCompanyList] = React.useState([]);

    React.useEffect(() => {

        let tempCompany = auth?.auth?.auth?.access?.[auth?.auth?.auth?.activeRole]?.company?.map((item) => {
            let _c;
            try {
                _c = {
                    value: item?.value,
                    label: item?.label,
                };
            } catch (err) {

            }
            return _c;
        });
        setCompanyList(tempCompany);
        // eslint-disable-next-line
    }, [auth]);
    return (
        <div>
            <Grid container>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        {t("Company name")}
                    </CustomTypography>
                    <SelectBox
                        value={data?.companyName ?? []}
                        onChange={(value) => onChange('companyName', value)}
                        options={companyList}
                        isMulti
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        {t("Property Name")}
                    </CustomTypography>
                    <SelectBox
                        // options={[]}
                        key={data?.companyName}
                        value={data?.propertyName ?? []}
                        onChange={(value) => onChange('propertyName', value)}
                        isMulti
                        isPaginate
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        {t("Unit Name")}
                    </CustomTypography>
                    <SelectBox
                        // options={[]}
                        key={(data?.propertyName)}
                        value={data?.unitName ?? []}
                        onChange={(value) => onChange('unitName', value)}
                        isMulti
                        isPaginate
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        {t("Created by")}
                    </CustomTypography>
                    <SelectBox
                        key={data?.companyName}
                        value={data?.createdBy ?? []}
                        onChange={(value) => onChange('createdBy', value)}
                        isMulti
                        isPaginate
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        {t("Status")}
                    </CustomTypography>
                    <SelectBox
                        value={data?.status ?? []}
                        onChange={(value) => onChange('status', value)}
                        isMulti
                        isPaginate
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        {t("Final Status")}
                    </CustomTypography>
                    <ToggleButtonComponent
                        options={FINAL_STATUS_OPTIONS}
                        value={data?.finalStatus ?? []}
                        onChange={(value) => onChange('finalStatus', value)}
                    />
                </CustomGrid>
                <CustomGrid item xs={12} md={6}>
                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={clearAllFilter}
                    >
                        {t("Clear All")}
                    </Button>
                </CustomGrid>
                <CustomGrid item xs={12} md={6}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={applyFilter}
                    >
                        {t("Apply")}
                    </Button>
                </CustomGrid>
            </Grid>
        </div>
    )
}

export default withNamespaces("filterComponent")(FilterComponent)

