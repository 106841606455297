import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, SemiBold, enumSelect, enum_types, useWindowDimensions } from "../../../utils";
import { EditLeadUseStyles } from "./style";
import { constructFieldDataJSON } from "../utils";
import React from "react";
import { FormGenerator } from "../../../components";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { BackendRoutes } from "../../../router/routes";
import { AlertContext } from "../../../contexts";

export const EditLead = ({
    t = () => false,
    data = {},
    set_is_lead_edit = () => false,
    reload = () => false,
}) => {
    const size = useWindowDimensions();
    const classes = EditLeadUseStyles({ size });
    const alert = React.useContext(AlertContext);
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

    const field_data = constructFieldDataJSON({ data });

    const [edit_field_state, set_edit_field_state] = React.useState(field_data);
    const [is_loading, set_is_loading] = React.useState(false);
    const [options, set_options] = React.useState({
        preferred_mode_contact_type: [],
        budget_type: [],
        expected_close_period: [],
    });

    React.useEffect(() => {
        getOptions();
    }, []);

    const updateState = (key, value) => {
        set_edit_field_state({ ...edit_field_state, [key]: value });
    };

    const getOptions = async () => {
        const result = await enumSelect([
            enum_types.preferred_mode_contact_type,
            enum_types.budget_type,
            enum_types.expected_close_period,
        ]);
        set_options({
            preferred_mode_contact_type: result?.preferred_mode_contact_type ?? [],
            budget_type: result?.budget_type ?? [],
            expected_close_period: result?.expected_close_period ?? [],
        });
    };

    const handleSave = () => {
        set_is_loading(true);

        let payload = {
            id: data?.id,
            business_phone_country_code: edit_field_state?.business_mobile?.mobile_code?.length > 0 ? edit_field_state?.business_mobile?.mobile_code : undefined,
            business_phone: edit_field_state?.business_mobile?.mobile?.length > 0 ? edit_field_state?.business_mobile?.mobile : undefined,
            address_1: edit_field_state?.address_1?.length > 0 ? edit_field_state?.address_1 : undefined,
            address_2: edit_field_state?.address_2?.length > 0 ? edit_field_state?.address_2 : undefined,
            website: edit_field_state?.website?.length > 0 ? edit_field_state?.website : undefined,
            preferred_mode_contact: edit_field_state?.preferred_mode_contact?.value,
            referrer_name: edit_field_state?.referrer_name?.length > 0 ? edit_field_state?.referrer_name : undefined,
            referrer_company: edit_field_state?.referrer_company?.length > 0 ? edit_field_state?.referrer_company : undefined,
            is_showcase: edit_field_state?.is_showcase,
            last_showcase_date: edit_field_state?.last_showcase_date,
            is_existing_customer: edit_field_state?.is_existing_customer,
            budget: edit_field_state?.budget?.value,
            budget_amount: edit_field_state?.budget_amount?.length > 0 ? parseInt(edit_field_state?.budget_amount) : undefined,
            is_confirm_interest: edit_field_state?.is_confirm_interest,
            expected_close_period: edit_field_state?.expected_close_period?.value,
            updated_by: localStorage?.getItem(LocalStorageKeys?.profileID),
            monthly_salary: edit_field_state?.monthly_salary ?? undefined
        };


        NetworkCall(
            `${config.api_url}${BackendRoutes?.lead_proxy_upsert}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            reload(true);
            alert.setSnack({
                ...alert, open: true, msg: t("Lead Updated successfully."),
                severity: AlertProps.severity.success,
            });
            set_is_loading(false);
            set_is_lead_edit(false);
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Internal error. Please try again later."),
                severity: AlertProps.severity.error,
            });
            set_is_loading(false);
        });
    };

    return (
        <Stack className={classes.root} direction={"column"}>
            <Stack className={classes.field_content} direction={"column"} rowGap={"16px"} padding={"16px"}>
                <Grid container direction={"row"} spacing={"8px"}>
                    <Grid item xs={12}>
                        <Typography className={classes.header}>
                            {t("Contact Information")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGenerator t={t}
                            spacing={2}
                            components={[
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Contact Name"),
                                    value: edit_field_state?.lead_name,
                                    placeholder: t("Contact Name"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Job Title"),
                                    value: edit_field_state?.job_title,
                                    placeholder: t("Job Title"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Company"),
                                    value: edit_field_state?.company,
                                    placeholder: t("Company"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    xs1: 7,
                                    xs: 5,
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "mobile",
                                    label: t("Business Mobile"),
                                    value: edit_field_state?.business_mobile,
                                    placeholder: t("Business Mobile"),
                                    fontSize: '14px !important',
                                    onChange: (value) => updateState("business_mobile", value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    xs1: 7,
                                    xs: 5,
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "mobile",
                                    label: t("Mobile Phone"),
                                    value: edit_field_state?.mobile_phone,
                                    placeholder: t("Mobile Phone"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Email"),
                                    value: edit_field_state?.email,
                                    placeholder: t("Email"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    label: t("Address 1"),
                                    value: edit_field_state?.address_1,
                                    placeholder: t("Address 1"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("address_1", e.target.value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    label: t("Address 2"),
                                    value: edit_field_state?.address_2,
                                    placeholder: t("Address 2"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("address_2", e.target.value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Country"),
                                    value: edit_field_state?.country_name,
                                    placeholder: t("Country"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    label: t("Website"),
                                    value: edit_field_state?.website,
                                    placeholder: t("Website"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("website", e.target.value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "select",
                                    label: t("Preferred Contact"),
                                    value: edit_field_state?.preferred_mode_contact,
                                    placeholder: t("Preferred Contact"),
                                    onChange: (value) => updateState("preferred_mode_contact", value),
                                    options: options?.preferred_mode_contact_type,
                                },
                            ]} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={"16px"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.header}>
                            {t("Referral and Showcase")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGenerator t={t}
                            spacing={2}
                            components={[
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    label: t("Referrer Name"),
                                    value: edit_field_state?.referrer_name,
                                    placeholder: t("Referrer Name"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("referrer_name", e.target.value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    label: t("Referrer Company"),
                                    value: edit_field_state?.referrer_company,
                                    placeholder: t("Referrer Company"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("referrer_company", e.target.value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "toggleButton",
                                    label: t("Showcase"),
                                    value: edit_field_state?.is_showcase,
                                    onChange: (value) => updateState("is_showcase", value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "date",
                                    label: t("Last Showcase Date"),
                                    value: edit_field_state?.last_showcase_date,
                                    placeholder: t("Last Showcase Date"),
                                    onChange: (value) => updateState("last_showcase_date", value),
                                    minDate: new Date(),
                                },
                            ]} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={"16px"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.header}>
                            {t("Lead Information")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGenerator t={t}
                            spacing={2}
                            components={[
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Purpose"),
                                    value: edit_field_state?.purpose,
                                    placeholder: t("Purpose"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Type"),
                                    value: edit_field_state?.revenue_type,
                                    placeholder: t("Type"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("From Date"),
                                    value: edit_field_state?.lease_start_date,
                                    placeholder: t("From Date"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "duration",
                                    label: "Duration",
                                    value: edit_field_state?.duration,
                                    placeholder: "Duration",
                                    fontFamily: SemiBold,
                                    item_size: {
                                        item: 3,
                                        item1: 9
                                    }
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Description"),
                                    value: edit_field_state?.description,
                                    placeholder: t("Description"),
                                    fontSize: '14px !important',
                                },
                            ]} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={"16px"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.header}>
                            {t("Purchase and Budget Information")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGenerator t={t}
                            spacing={2}
                            components={[
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "toggleButton",
                                    label: t("Existing Customer"),
                                    value: edit_field_state?.is_existing_customer,
                                    onChange: (value) => updateState("is_existing_customer", value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Lead External Source"),
                                    value: edit_field_state?.lead_source,
                                    placeholder: t("Lead External Source"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: true,
                                    component: "text",
                                    label: t("Lead Internal Source"),
                                    value: edit_field_state?.internal_lead_source,
                                    placeholder: t("Lead Internal Source"),
                                    fontSize: '14px !important',
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "select",
                                    label: t("Budget"),
                                    value: edit_field_state?.budget,
                                    placeholder: t("Budget"),
                                    onChange: (value) => updateState("budget", value),
                                    options: options?.budget_type,
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    type: "number",
                                    label: t("Budget Amount"),
                                    value: edit_field_state?.budget_amount,
                                    placeholder: t("Budget Amount"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("budget_amount", e.target.value),
                                    endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "toggleButton",
                                    label: t("Confirm Interest"),
                                    value: edit_field_state?.is_confirm_interest,
                                    onChange: (value) => updateState("is_confirm_interest", value),
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "select",
                                    label: t("Expected Close Period"),
                                    value: edit_field_state?.expected_close_period,
                                    placeholder: t("Expected Close Period"),
                                    onChange: (value) => updateState("expected_close_period", value),
                                    options: options?.expected_close_period,
                                },
                                {
                                    size: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2, },
                                    isActive: true,
                                    isRequired: false,
                                    isReadonly: is_loading,
                                    component: "text",
                                    type: "number",
                                    label: t("Monthly/Total Budget"),
                                    value: edit_field_state?.monthly_salary,
                                    placeholder: t("Enter Monthly/Total Budget"),
                                    fontSize: '14px !important',
                                    onChange: (e) => updateState("monthly_salary", e.target.value),
                                    endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
                                },
                            ]} />
                    </Grid>
                </Grid>
            </Stack>
            <Box className={classes.bottom_button_box}>
                <Stack direction={"row"} columnGap={"8px"}>
                    <Button
                        disabled={is_loading}
                        variant="contained"
                        className={classes.cancel_button}
                        onClick={() => set_is_lead_edit(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button
                        disabled={is_loading}
                        variant="contained"
                        className={classes.save_button}
                        onClick={handleSave}>
                        {t("Save")}
                    </Button>
                </Stack>
            </Box>
        </Stack>
    )
}