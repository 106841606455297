import { concat_string, timeZoneConverter } from "../../../utils";
import { plan_name_color } from "./constant";

export const constructGetPlanInfoResponse = (response) => {
    return {
        id: response?.id,
        name: response?.name ?? "-",
        logo: "images/plan_logo.png",
        valid_till: response?.valid_till ? timeZoneConverter(response?.valid_till, "DD MMM YY") : "-",
        used_storage_size: response?.actual_bucket_size ? response?.actual_bucket_size?.toFixed(1) : 0,
        storage_size: response?.bucket_size ?? 0,
        used_storage_size_percentage: (response?.actual_bucket_size && response?.bucket_size) ? (response?.actual_bucket_size / response?.bucket_size) : 0,
        used_unit_size: response?.actual_unit_size ?? "-",
        unit_size: response?.unit_size ?? "-",
        marketplace_enabled: response?.marketplace_enabled ?? 20,
        used_users_size: response?.used_users_size ?? 24,
        users_size: response?.users_size ?? 25,
        data: response
    }
}

export const constructAllSubscriptionResponse = (t, response) => {
    let temp_table_header = response?.plans?.map?.((plan, index) => {
        return {
            id: plan?.id,
            name: plan?.name ?? "-",
            color: plan_name_color?.[index % 4],
            description: plan?.desc ?? "-",
            price: plan?.monthly_fees ?? "-",
            price_metric: plan?.billing_period ?? "-",
            is_current_plan: plan?.id === response?.current_plan?.id,
            available_modules: plan?.selected_modules?.length > 0 ? plan?.selected_modules : []
        }
    })

    let temp_table_rows = response?.modules?.map?.((module) => {
        return {
            id: module?.id,
            name: module?.name ?? "-",
            plans_availability: temp_table_header?.map?.((plan) => {
                return {
                    ...plan,
                    is_available: plan?.available_modules?.some((available_module) => available_module?.value === module?.id)
                }
            })
        }
    })

    return {
        table_header: [
            {
                id: "32e676fe-3b09-441d-822a-7ea3e305422f",
                name: t("Check & Choose You Best Subscription"),
                description: t("Here those plans are defined and shown with access levels, And those plans are simply customisable."),
            },
            ...temp_table_header ?? [],
        ],
        table_rows: temp_table_rows ?? [],
    }
}

export const constructGetBasicInfoResponse = (response) => {
    return {
        id: response?.id,
        logo: response?.logo,
        company_name: response?.company_name ?? "-",
        account_name: response?.account_name ?? "-",
        account_id: response?.account_id ?? "-",
        tax_id: response?.tax_id ?? "-",
        address: concat_string(
            {
                address_line_1: response?.address_line_1,
                address_line_2: response?.address_line_2,
                landmark: response?.landmark,
                city: response?.city,
                state: response?.state,
                pincode: response?.pincode,
                country: response?.country,

            },
            [
                "address_line_1", "address_line_2",
                "landmark", "city", "state",
                "pincode", "country",

            ]
        ),
        business_phone: response?.business_phone ?? "-",
        alternate_phone: response?.alternate_phone ?? "-",
        company_website: response?.company_website ?? "-",
        company_email_id: response?.company_email_id ?? "-",
        data: response,
    }
}

export const constructGetConfigurationResponse = ({ data = {}, app_builds = [] }) => {
    return {
        id: data?.id,
        login_logo: {
            type: "login_logo",
            label: "Login Logo",
            updated_image: data?.login_logo,
            updated_local_image: data?.login_logo,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.login_logo?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        },
        web_background: {
            type: "web_background",
            label: "Web Background",
            updated_image: data?.web_background,
            updated_local_image: data?.web_background,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.web_background?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        },
        mobile_background: {
            type: "mobile_background",
            label: "Mobile Background",
            updated_image: data?.mobile_background,
            updated_local_image: data?.mobile_background,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.mobile_background?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        },
        web_welcome: {
            type: "web_welcome",
            label: "Web Welcome Background",
            updated_image: data?.web_welcome,
            updated_local_image: data?.web_welcome,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.web_welcome?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        },
        mobile_welcome: {
            type: "mobile_welcome",
            label: "Mobile Welcome Background",
            updated_image: data?.mobile_welcome,
            updated_local_image: data?.mobile_welcome,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.mobile_welcome?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        },
        explore_meta: data?.explore_meta?.map?.((_) => {
            return {
                is_active: Boolean(_?.is_active),
                design: _?.design ?? "design_1",
                information: _?.information ?? "",
                cta_text: _?.cta_text ?? "",
                link: _?.link ?? "",
                error: {
                    information: "",
                    cta_text: "",
                    link: "",
                },
            }
        }),
        promotion_page_meta: app_builds?.map?.((ab) => {
            const temp_promotion_page_meta = data?.promotion_page_meta?.find?.(pp => pp?.app_build?.build_code === ab?.build_code)
            return {
                is_active: Boolean(temp_promotion_page_meta?.is_active),
                design: temp_promotion_page_meta?.design ?? "design_1",
                app_build: ab,
                description: temp_promotion_page_meta?.description ?? "",
                cta_text: temp_promotion_page_meta?.cta_text ?? "",
                mobile_background: temp_promotion_page_meta?.mobile_background ?? "",
                error: {
                    description: "",
                    cta_text: "",
                    mobile_background: "",
                },
            }
        }),
        data: data,
    }
}

export const getGridLayout = (size) => {
    let grid = size?.width > 1300
        ? 4
        : size?.width > 800
            ? 6
            : 12;

    return grid;
}