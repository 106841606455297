import { loadOptionsApis } from './asyncPaginateLoadOptions';

export const Servicelist = [
  {
    name: "General"
  },
  {
    name: "General Sub-Category"
  },
  {
    name: "General status"
  },
  {
    name: "Maintenance"
  },
  {
    name: "Maintenance Sub-Category"
  },
  {
    name: "Maintenance status"
  },
]

export const GeneralPath = [ "type", "icon"]

export const GeneralRow = [
  {
    type: "Hello",
    icon: "editviewtoggle"
  }


]
export const Generalheading =(t=()=>false)=> [
  { title: t("Category"), field: "type", type: "" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
]
export const GeneralType = [ 
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
]
export const Generaldrawer =(t=()=>false)=> [
  {
    componet_type: "text",
    label: t("Category"),
    state_name: "type",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
  },
]

export const SubGeneralPath = [  "generalcategory", "type", "icon"]

export const SubGeneralRow = [
  { 
    generalcategory: "general",
    type: "Hello",
    icon: "editviewtoggle"
  },

]
export const SubGeneralheading = (t=()=>false)=>[ 
  { title: t("Category"), field: "generalcategory" },
  { title: t("Sub-Category"), field: "type" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
]
export const SubGeneralType = [ 
  { type: ["object"], name: "generalcategory" },
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
]
export const SubGeneraldrawer = (t=()=>false)=>[
  {
    componet_type: "asyncSelect",
    label: t("Category"),
    state_name: "generalcategory",
    value:{},
    required: true,
    options: [],
    isPaginate:true,
    loadOptions:(search, array,handleLoading) =>  loadOptionsApis(
      "general-master/category_master",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
    ),
    debounceTimeout:800
  },
  {
    componet_type: "text",
    label: t("Sub-Category"),
    state_name: "type",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
  },

]

export const GeneralStatusPath = [ "type", "icon"]

export const GeneralStatusRow = [
  {
    sno: "",
    type: "Hello",
    icon: "editviewtoggle"
  }

]
export const GeneralStatusheading = [
  { title: "Type", field: "type", type: "" },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },


]
export const GeneralStatusType = [
  { type: ["text"], name: "type" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
]
export const GeneralStatusdrawer = [
  {
    componet_type: "text",
    label: "Type",
    state_name: "type",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
  },

]


export const MaintenancePath = [ "type", "icon"]

export const MaintenanceRow = [
  {
    sno: "",
    type: "Hello",
    icon: "editviewtoggle"
  }


]
export const Maintenanceheading =(t=()=>false)=> [
  { title: t("Category"), field: "type", type: "" },
  { title:t("Status"), field: 'status', },
  { title: "", field: 'icon' },
]
export const MaintenanceType = [
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
]
export const Maintenancedrawer = (t=()=>false)=>[
  {
    componet_type: "text",
    label: t("Category"),
    state_name: "type",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
  },
]

export const SubMaintenancePath = [ "maintenancecategory", "type", "icon"]

export const SubMaintenanceRow = [
  { 
    maintenancecategory: "Maintenance",
    type: "Hello",
    icon: "editviewtoggle"
  },

]
export const SubMaintenanceheading =(t=()=>false)=> [ 
  { title: t("Category"), field: "maintenancecategory" },
  { title: t("Sub-Category"), field: "type" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
]
export const SubMaintenanceType = [ 
  { type: ["object"], name: "maintenancecategory" },
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
]
export const SubMaintenancedrawer = (t=()=>false)=>[
  {
    componet_type: "asyncSelect",
    label: t("Category"),
    state_name: "maintenancecategory",
    value: "",
    required: true,
    options: [],
    isPaginate:true,
    loadOptions:(search, array,handleLoading) =>  loadOptionsApis(
      "maintenance-master/category_master",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
    ),
    debounceTimeout:800
  },
  {
    componet_type: "text",
    label: t("Sub-Category"),
    state_name: "type",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
  },

]

export const StatusMaintenancePath = [  "type", "icon"]

export const StatusMaintenanceRow = [
  { 
    type: "Hello",
    icon: "editviewtoggle"
  }

]
export const StatusMaintenanceheading = [ 
  { title: "Type", field: "type", type: "" },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },


]
export const StatusMaintenanceType = [ 
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
]
export const StatusMaintenancedrawer = [
  {
    componet_type: "text",
    label: "Type",
    state_name: "type",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
  },
]





