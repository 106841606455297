import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { LoadingSection } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { AssetView } from "./assetView";
import { filterStyles } from "./styles";

export const UnitSingleDetails = (props) => {
    const { t } = (props);
    const classes = filterStyles();
    const [details, setDetails] = React.useState({})
    // eslint-disable-next-line
    const [image, setImage] = React.useState({
        asset: [],
        assets: []
    })
    const [loading, setLoading] = React.useState(true)
    const [pdf, setPdf] = React.useState(false)
    const getUnitPricing = () => {
        const data = {
            tenantId: `${config.tenantId}`,
            unitId: [props?.selectedUnits],
            startDate: props?.startDate,
            endDate: props?.endDate,
            gracePeriod: props?.grace ?? 0,
            revenue_type: props?.revenue_type
        }

        NetworkCall(
            `${config.api_url}/quotation/unitprice`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails(response?.data?.data?.units[0])
                setImage({
                    asset: response?.data?.data?.units[0]?.asset ?? [],
                    assets: response?.data?.data?.units[0]?.assests ?? [],
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)

            })
    }
    React.useEffect(() => {
        getUnitPricing()
        // eslint-disable-next-line
    }, [])
    

    return (
        <>


            <div >
                {!props?.dis &&
                    <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
                        <Box flexGrow={1}>
                            <Typography className={classes.detailTitle}>{t("Unit View")}</Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={props?.onClose}>
                                <CloseIcon style={{ fontSize: "1.25rem" }} />
                            </IconButton>
                        </Box>
                    </Box>
                }

                {
                    loading ?
                        <div className={classes.Detailroot}>
                            <LoadingSection />
                        </div>
                        :

                        <div className={classes.Detailroot} style={{ overflow: "scroll" }}>
                            {image?.assets?.length > 0 ?
                                <AssetView title={t("Unit View")}
                                    asset={image?.asset}
                                    assetAll={image?.assets} referenceId={props?.selectedUnits} /> : <img src={'/images/propertybg.svg'} alt="img" />}
                            <Stack direction="row" alignItems="center" marginTop="8px">
                                <Typography className={classes.detailTitle}>{details?.name}</Typography>
                                <Typography className={classes.unitTag}>{details?.unit_no}</Typography>
                            </Stack>
                            <Typography className={classes.unitSub}>{details?.property?.name ?? ''}{details?.property?.name ? ',' : ''}{details?.block?.name ?? ''}{details?.block?.name ? ',' : ""} {details?.floor?.name ?? ''}</Typography>
                            <Box height="8px" />
                            <Stack direction="row" alignItems="center" >
                                {
                                    details?.totalbedroom &&
                                    <>
                                        <img src="/images/bed.svg" alt="" />
                                        <Typography className={classes.build1}>&nbsp;{details?.totalbedroom}&nbsp;&nbsp;</Typography>
                                        <Box className={classes.dot} />

                                    </>
                                }
                                {
                                    details?.rooms &&
                                    <>
                                        <img src="/images/bath.svg" alt="" />
                                        <Typography className={classes.build1}>&nbsp;{details?.rooms}&nbsp;&nbsp;</Typography>
                                        <Box className={classes.dot} />

                                    </>
                                }
                                {
                                    details?.unitType &&
                                    <>
                                        <img src="/images/Group 96257.svg" alt="" />
                                        <Typography className={classes.build1}>&nbsp;{details?.unitType}</Typography>
                                        <Box className={classes.dot} />
                                    </>
                                }
                                {
                                    details?.totalarea &&
                                    <>
                                        <img src="/images/range.svg" alt="" />
                                        <Typography className={classes.build1}>{details?.totalarea}&nbsp;{details?.uom?.name}</Typography>

                                    </>
                                }

                            </Stack>
                            {
                                !props?.dis &&
                                <>
                                    <Box height="12px" className={classes.border} />
                                    <Box height="12px" />
                                    <Stack direction="row" alignItems="center" >
                                        <img src="/images/Group 6050.svg" alt="" />
                                        <Typography className={classes.rental}>&nbsp;&nbsp;{t("RENTAL BREAK UP")}</Typography>
                                    </Stack>
                                </>
                            }

                            {
                                !props?.dis &&
                                <>
                                    {
                                        details?.pricing?.filter((val) => !val?.pricing_component?.[0]?.is_wallet && !val?.pricing_component?.[0]?.is_late_fee && !val?.is_wallet_item && !val?.pricing_component?.[0]?.is_commission_payment)?.map((val) => {
                                            return (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Typography className={classes.rental1}>{val?.rentalBreakup}</Typography>
                                                            <Typography className={classes.tax}>{t("Tax")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.rental2} textAlign="end">{val?.currencySymbol}&nbsp;{val?.componentvalue}</Typography>
                                                            <Typography className={classes.tax} textAlign="end">{val?.currencySymbol}&nbsp;{val?.appliedTaxAmount}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )

                                        })
                                    }


                                    <Box height="14px" className={classes.border} />
                                    <Box height="12px" />
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography className={classes.rental1bold}>{t("Total")}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.rental1bold} textAlign="right">{details?.pricing[0]?.currencySymbol} {details?.totalPricing}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }


                            {
                                !props?.dis &&
                                <>
                                    {
                                        details?.pricing?.filter((val) => val?.pricing_component?.[0]?.is_wallet || val?.pricing_component?.[0]?.is_late_fee || val?.is_wallet_item || val?.pricing_component?.[0]?.is_commission_payment)?.map((val) => {
                                            return (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <Typography className={classes.rental1}>{val?.rentalBreakup}</Typography>
                                                            <Typography className={classes.tax}>{t("Tax")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.rental2} textAlign="end">{val?.currencySymbol}&nbsp;{val?.componentvalue}</Typography>
                                                            <Typography className={classes.tax} textAlign="end">{val?.currencySymbol}&nbsp;{val?.appliedTaxAmount}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )

                                        })
                                    }
                                </>
                            }

                            <Box height="14px" className={classes.border} />
                            {/* <Box display="flex" alignItems="center" marginTop="12px">
                                <Box flexGrow={1}>
                                    <Stack direction="row" alignItems="center" >
                                        <img src="/images/Handbook.svg" alt="" />
                                        <Typography className={classes.rental}>&nbsp;&nbsp;Hand Book</Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Typography className={classes.viewdetail} onClick={() => setPdf(true)}>View / Download</Typography>
                                </Box>
                            </Box> */}

                            {
                                !props?.dis &&
                                <>
                                    {
                                        props?.shortlist ? "" :
                                            (details?.pricing?.filter((val) => !val?.pricing_component?.[0]?.is_wallet && !val?.pricing_component?.[0]?.is_late_fee && !val?.is_wallet_item && !val?.pricing_component?.[0]?.is_commission_payment)?.length ?
                                                <center>
                                                    <Button onClick={() => props?.handleShortlist(details?.id)} variant="contained" className={classes.shortlistbtnlong}>
                                                        {
                                                            props?.shortlistid?.some((val) => val === details?.id) ? t("Shortlisted") : t("Shortlist This Unit")
                                                        }

                                                    </Button>
                                                </center> : "")
                                    }

                                </>
                            }

                        </div>
                }
            </div>
            <Dialog fullWidth maxWidth="xs" onClose={() => setPdf(false)} open={pdf} className={classes.dialog}>
                <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
                    <Box flexGrow={1}>
                        <Typography className={classes.detailTitle}>{t("Hand Book")}</Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={() => setPdf(false)}>
                            <CloseIcon style={{ fontSize: "1.25rem" }} />
                        </IconButton>
                    </Box>
                </Box>
                <div style={{ height: "520px", overflow: "scroll" }}>

                    {
                        image?.assets?.filter((val) => val?.asset_type === 4)?.length > 0 ?
                            <DocumentViewer url={image?.assets?.filter((val) => val?.asset_type === 4)?.map(x => x)?.[0]?.url} />
                            :
                            <Typography textAlign="center" marginTop="30px">{t("No Hand Book Found")}</Typography>
                    }
                </div>
            </Dialog>
        </>
    )
}
