import React from 'react'
import { Box, Button, Checkbox, Divider, InputAdornment, Stack, Typography } from "@mui/material"
import { Info } from "../assets/info";
import { FormGenerator, ContactList } from '../../../components'
import { AccountList } from "../../../screens/quotationDetails/components";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'; 
import { useStyle } from "../style";
import { CheckBox } from "@mui/icons-material";
import { allowed_file_size, multiFileUpload, timeZoneConverter } from "../../../utils";
import { CustomFileUpload } from './customFileUpload';
import { AlertContext } from '../../../contexts';
import WaringIcon from '../../../assets/warning';
import moment from 'moment';

export const CreateOrder = ({
    t,
    state,
    updateState = () => false,
    details = [],
    details1 = [],
    data = {},
    disable = false,
    onChangeStep = () => false,
    toggleDrawer = () => false,
    openDrawer = () => false,
    agreementCreation = () => false,
    disableAcc = false,
    contactID = "",
    images = [],
    setImages = () => false,
    validateAgreement=()=>false,
    disableBTN
}) => {

    const classes = useStyle()
    const alert = React.useContext(AlertContext)

    const [show , setShow] = React.useState(false)

    const next = (type) => {
        if (type === "contact") {
            onChangeStep(1)
        }
        else {
            onChangeStep(2)
        }
    }


    const sumbit = () => {
            agreementCreation({
                order_reference: state?.reference?.length > 0 ? state?.reference : undefined,
                order_date: !!state?.order_date ? timeZoneConverter(state?.order_date) : undefined,
                order_value: state?.order_value?.length > 0 ? state?.order_value : undefined,
                uploadedAssets: images
            })
    }
    const handleAssetFile = (data) => {
        setImages(images?.filter((val) => val?.url !== data?.url));
    };

    const handleUploadAssets = async (value) => {
        let uploaded_file = await multiFileUpload(value, {}, alert, allowed_file_size)
        setImages([
            ...images,
            {
                is_asset: true,
                url: uploaded_file?.[0]?.url,
                file_meta: uploaded_file?.[0]?.file_meta,
                is_active: uploaded_file?.[0]?.is_active
            }
        ])
    }
    var today_date = new Date();
    var start_date = new Date(data?.quote_details?.lease_start_date);
    
    var today = moment(today_date).format("YYYY/MM/DD");
    var otherDate = moment(start_date).format("YYYY/MM/DD");

    const is_past_date = (today > otherDate) ? true : false
    const is_present = (today === otherDate) ? true : false

    const create = ()=>{
            if(validateAgreement()){
                setShow(true)
            }
    }


    return (
        <Box>
            <Box className={show ? (is_present ? classes.body3 : classes.body2) :  (is_present ? classes.body : classes.body1)} pb={2}>
                <Box className={classes.cardParent}>
                    <Box className={classes.card}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Info />
                            <Box>
                                <Typography className={classes.cardTitle}>{data?.quote_details?.total_quote_amount ?? (data?.quote_details?.total_amount + data?.quote_details?.total_tax) - data?.quote_details?.total_discount} {data?.quote_details?.code ?? data?.quote_details?.symbol}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography className={classes.cardSubTitle}>
                                        {data?.quote_details?.quotation_no}
                                    </Typography>
                                    <Typography className={classes.dot}>
                                    </Typography>
                                    <Typography className={classes.cardSubTitle}>
                                        {timeZoneConverter(data?.quote_details?.created_at)}
                                    </Typography>
                                </Stack>
                            </Box>

                        </Stack>
                        <Divider style={{ marginTop: "12px" }} />
                        <Stack direction={"column"} spacing={1} justifyContent={'space-between'} pt={2} pb={2}>
                            {
                                details?.map((x) => {
                                    return (
                                        <Stack direction={"row"} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography className={classes.name}>{x?.name}</Typography>
                                            <Typography className={(x?.is_tag && x?.details?.length) ? classes.details1 : classes.details}>{x?.details?.length > 0 ? x?.details : '-'}</Typography>
                                        </Stack>
                                    )
                                })
                            }

                        </Stack>
                        <Divider />
                        <Stack direction={"column"} spacing={1} justifyContent={'space-between'} pt={2}>
                            {
                                details1?.map((x) => {
                                    return (
                                        <Stack direction={"row"} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography className={classes.name}>{x?.name}</Typography>
                                            <Typography className={classes.details}>{x?.details}</Typography>
                                        </Stack>
                                    )
                                })
                            }

                        </Stack>


                    </Box>

                </Box>


                <Box pl={2} pr={2}>
                    <Box>
                        <Typography className={classes.orderTitle}>{t("ORDER CONFIRMATION DETAILS")}</Typography>

                        <FormGenerator t={t} components={[
                            {
                                size: {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6
                                },
                                component: "text",
                                isActive: true,
                                label: t("Order Reference"),
                                placeholder: t("Order Reference"),
                                value: state?.reference,
                                onChange: (e) => updateState("reference", e.target.value),
                                error: state?.error?.reference,
                            },
                            {
                                size: {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                },
                                isActive: true,
                                component: "date",
                                label: t("Order Date"),
                                value: state?.order_date,
                                placeholder: t("Order Date"),
                                onChange: (value) => updateState("order_date", value),
                                error: state?.error?.order_date,
                            },
                            {
                                size: {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6
                                },
                                component: "text",
                                isActive: true,
                                label: t("Order Value"),
                                placeholder: t("Order Value"),
                                value: state?.order_value,
                                onChange: (e) => updateState("order_value", e.target.value),
                                error: state?.error?.order_value,
                                type: "number",
                                endAdornment: <InputAdornment position="end">{data?.quote_details?.code ?? data?.quote_details?.symbol}</InputAdornment>
                            },



                        ]} />


                    </Box>

                    {/* Upload image */}

                    <Box mt={2}>
                        <CustomFileUpload
                            t={t}
                            accept={["image/*", ".pdf"]}
                            updateURL={handleUploadAssets}
                            selectedURL={images ?? []}
                            handleFile={handleAssetFile}
                        />
                    </Box>

                    {/* contact */}
                    <Box>
                        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} p={0}>
                            <Typography className={classes.orderTitle}>{t("BILLING PRIMARY CONTACT DETAILS")}</Typography>
                        </Stack>
                        {
                            !!data?.contact &&
                            <ContactList
                                t={t}
                                marginTop={'0px !important'}
                                data={{
                                    name: data?.contact?.name,
                                    img: data?.contact?.img,
                                    arg: false,
                                    phone: `${data?.contact?.mobile?.mobile_code} ${data?.contact?.mobile?.mobile}`,
                                    mail: data?.contact?.email
                                }}

                                leadtype={true}
                                padding={'10px 16px'}
                                is_edit={true}
                                edit_title={disable ? t("Edit") : t("Create")}
                                onClick={() => next("contact")}
                            />
                        }

                    </Box>

                    {/* account */}

                    <Box>
                        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} p={0}>
                            <Typography className={classes.orderTitle}>{t("BILLING ACCOUNT DETAILS")}</Typography>
                            {!data?.account?.account_no && <Typography className={classes.createNew} onClick={() => next()}>{t("Create New")}</Typography>}
                        </Stack>
                        {
                            data?.account?.account_no &&
                            <AccountList disable value={data?.account} padding={'12px !important'}
                                is_edit={true}
                                edit_title={t("Change")}
                                onClick={next}

                            />
                        }

                    </Box>


                </Box>


            </Box>
            {
                (!is_present && !show) &&
                <Box className={classes.bottom} backgroundColor={is_past_date ? "#FF9340" : "#5AC782"}>
                    {t(`The start date is ${is_past_date ? "Less" : "Greater"} than today`)}
                </Box>
            }
           
           {
            show ?  
            <Box className={classes.footerRoot}>
            <Box mb={1} className={classes.bottomRoot}>
               <WaringIcon />
               {
                !is_present &&  <Typography className={classes.waringText}>{t("The")} <Typography variant='span' className={classes.waringBoldText}>{t("Start Date")}</Typography>{t("is")}<Typography className={classes.waringBoldText}>{is_past_date ? t("Less") : t("Greater")}</Typography>{t(`today`)}</Typography>

               }
               <Typography className={classes.waringDes}>Are you sure to proceed?</Typography>
            </Box>
          <Stack direction="row" spacing={1}>
          <Button variant="contained" className={classes.next} disabled={state?.is_accept ? (disableAcc ? true : (disableBTN ? true : false)) : true} onClick={sumbit}>
                {t("Yes , Convert")}
            </Button>
            <Button variant="contained" className={classes.previous} disabled={state?.is_accept ? (disableAcc ? true : (disableBTN ? true : false)) : true} onClick={()=>setShow(false)}>
                {t("No , Go Back")}
            </Button>
          </Stack>

        </Box>
        :
        <Box className={classes.footerRoot}>
        <Stack direction="row" spacing={1} mb={1}>
            <Stack direction="row" spacing={1}>
                <Checkbox
                    onChange={() => updateState('is_accept', !state?.is_accept)}
                    checked={state?.is_accept}
                    icon={<CheckBoxOutlineBlankIcon sx={{ color: "#E4E8EE" }} />}
                    checkedIcon={<CheckBox sx={{ color: "#5078E1" }} />}
                />
                <Typography className={classes.acceptText}>Please, Reassure the billing period, Account name, Primary contact & Order values are correct</Typography>

            </Stack>
            {/* <InfoIcon /> */}

        </Stack>
        <Button variant="contained" className={classes.next} disabled={state?.is_accept ? disableAcc ? true : false : true} onClick={create}>
            {t("Convert Agreement")}
        </Button>

    </Box>
           }
           
        </Box>
    )
}