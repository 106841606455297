import React from "react"
import { LeadPreviewStylesPreview } from "./styles";
import { Box } from "@mui/material";
import { UploadComponent } from "../../../components";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { NetWorkCallMethods } from "../../../utils";

export const Attachments = ({id}) => {
    const classes = LeadPreviewStylesPreview();
     const {t = () => false,opportunityAssets,setOpportunityAssets}=React.useContext(CreateOppertunityContext)
     React.useEffect(()=>{
        if(id !==null){
        getAssetDetails()
        }
        //eslint-disable-next-line
     },[])
     const getAssetDetails = (data) => {
        const payload = {
            lead_id: id
        }

        NetworkCall(
            `${config?.api_url}/opportunity/get_asset`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setOpportunityAssets(response?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const uploadAssets = (data) => {
        const payload = {
          "params": data?.map((x) => {
            return (
              {
                "lead_id": id,
                id:x?.id,
                "asset_type": null,
                "url": x?.url,
                "file_meta":x?.file_meta,
                "is_active": x?.is_active
              }
            )
          })
        }
        NetworkCall(
          `${config?.api_url}/opportunity/upsert_asset`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).then((response) => {
        if(id!==null){
        getAssetDetails()
        }
        }).catch((err) => {
          console.log(err)
        })
      }
     return (
        <Box className={classes.card}>
            <Box mt={1}>
                <UploadComponent
                    // readOnly={type?.label === resourceTypes?.users && data?.is_exisit}
                    // handleChange={(val) => updateStateNew("opportunity_assets", val)}
                    logo_title={t("Documents")}
                    assets={opportunityAssets}
                    setAssets={setOpportunityAssets}
                    xs={2}
                    height={"190px"}
                    accept={"image/*,.pdf"}
                    imageHeight={"188px"}
                    isCustomDelete={true}
                    isCustomUpload={true}
                    handleImageUpload={(val)=>id!==null?uploadAssets(val):false}
                    // readOnly={data?.isView}
                    border={true}
                />
            </Box>
            </Box>
            )
}