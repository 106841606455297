import * as React from "react"
const ProjectOwnerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.619} height={16} {...props}>
    <path
      fill="#a4b2c1"
      d="M6.1 0a3.81 3.81 0 1 0 3.8 3.81A3.818 3.818 0 0 0 6.1 0Zm0 1.143A2.667 2.667 0 1 1 3.429 3.81 2.658 2.658 0 0 1 6.1 1.143Zm5.333 6.476a4.19 4.19 0 1 0 4.19 4.19 4.191 4.191 0 0 0-4.194-4.19ZM1.714 9.143A1.717 1.717 0 0 0 0 10.857v.457c0 2.2 2.678 3.924 6.1 3.924a9.418 9.418 0 0 0 1.634-.141 4.965 4.965 0 0 1-.72-1.048 8.854 8.854 0 0 1-.914.045c-3.059 0-4.952-1.444-4.952-2.781v-.457a.571.571 0 0 1 .571-.571h5a4.765 4.765 0 0 1 .541-1.143Zm9.714 0a.429.429 0 0 1 .406.289l.424 1.234h1.408a.429.429 0 0 1 .259.77l-1.141.865.386 1.237a.429.429 0 0 1-.666.471l-1.075-.809-1.077.806a.428.428 0 0 1-.666-.471l.386-1.236-1.141-.866a.429.429 0 0 1 .259-.77h1.41l.424-1.234a.429.429 0 0 1 .405-.286Z"
      data-name="icons8-popular-man (2)"
    />
  </svg>
)
export default ProjectOwnerIcon
