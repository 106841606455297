import { Drawer, Grid } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { DialogDrawer } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, entriesByGateHeading, entriesByGatePath, onDutyHeading, onDutyPath } from "../../utils";
import {
  CardDetails,
} from "../propertymanagerdashboard/components";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
import { constructBasicStatsData, constructEntriesByGatesData, constructEntriesData, constructOnDutyData, constructPeopleVisitedData, scale, stratureData } from "./common";
import { dashboardStyles } from "./style";


const Dashboard = (props) => {
  const {
    calendar = {},
    company = {},
    property = {},
  } = props;
  const { t } = (props)
  const table_limit = 10;

  const classes = dashboardStyles();

  // useContext
  const alert = React.useContext(AlertContext);

  // useState
  const [details, setDetails] = useState({});
  const [stats_state, set_stats_state] = useState([]);
  const [people_visited_state, set_people_visited_state] = useState({});
  const [entries_state, set_entries_state] = useState({});
  const [on_duty_state, set_on_duty_state] = useState([]);
  const [widget_entries_by_gates_state, set_widget_entries_by_gates_state] = useState([]);
  const [drawer_entries_by_gates_state, set_drawer_entries_by_gates_state] = useState([]);
  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  const [view, setView] = useState(false);
  const [widget_filter_value, set_widget_filter_value] = useState({
    widget_access_gate: "",
    drawer_access_gate: "",
  })
  const [is_widget_option_loading, set_is_widget_option_loading] = React.useState("")
  const [table_offset, set_table_offset] = React.useState({
    on_duty: 0,
    widget_entry_by_gate: 0,
    drawer_entry_by_gate: 0,
  })
  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(1)
  // useEffect to get initial data
  useEffect(() => {
    if (company?.value && property?.value) {
      getInitialData();
    }
    //eslint-disable-next-line
  }, [company?.value, property?.value, calendar?.value]);

  // Function to get initial data
  const getInitialData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        set_widget_filter_value({
          widget_access_gate: "",
          drawer_access_gate: "",
        })
        await Promise.all([
          getDetails(),
          getBasicStats(),
          getPeopleVisitedStats(),
          getEntriesStats(),
          getOnDutyStats(table_offset?.on_duty, table_limit),
          getWidgetEntriesByGatesStats([], table_offset?.widget_entry_by_gate, table_limit),
        ])
        setLoader(false);
      } catch (error) {
        reject(error);
      }
    })
  }

  // getDetails
  const getDetails = () => {
    const payload = {
      company_id: company?.value,
      selected_property_ids: [property?.value],
      from_date: `${moment(calendar?.value?.from_date).format("YYYY-MM-DD")} 00:00:00`,
      to_date: `${moment(calendar?.value?.to_date).format("YYYY-MM-DD")} 23:59:59`,
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.dashboard_security_stats}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const resut = stratureData(res?.data?.data, t);
      setDetails(resut);
    }).catch((error) => {
      setLoader(false);
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get basic stats
  const getBasicStats = () => {
    const payload = {
      property_id: property?.value,
      start_date: `${moment(calendar?.value?.from_date).format("YYYY-MM-DD")}`,
      end_date: `${moment(calendar?.value?.to_date).format("YYYY-MM-DD")}`,
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.security_manager}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructBasicStatsData(res?.data?.data, t);
      set_stats_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get people visited stats
  const getPeopleVisitedStats = () => {
    const payload = {
      property_id: property?.value,
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.security_manager_people_visited}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructPeopleVisitedData(res?.data?.data, t);
      set_people_visited_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get people visited stats
  const getEntriesStats = () => {
    const payload = {
      property_id: property?.value,
      checkout: true
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.security_manager_entries_check_in_and_out}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructEntriesData(res?.data?.data, t);
      set_entries_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get on duty stats
  const getOnDutyStats = (offset, limit, more = false) => {
    const payload = {
      property_id: property?.value, offset, limit,
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.security_manager_on_duty_info}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructOnDutyData(res?.data?.data?.data, t);
      set_on_duty_state(
        more ? [...on_duty_state, ...result] : { list: result, count: res?.data?.data?.count }
      );
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };



  // get widget entries by gates stats
  const getWidgetEntriesByGatesStats = (access_gate_ids, offset, limit, more = false) => {
    const payload = {
      property_id: property?.value,
      access_gate_ids, offset, limit,
      start_date: `${moment(calendar?.value?.from_date).format("YYYY-MM-DD")}`,
      end_date: `${moment(calendar?.value?.to_date).format("YYYY-MM-DD")}`,
      yet_to_checkout: true
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.security_manager_entries_for_all}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructEntriesByGatesData(res?.data?.data?.data);
      set_widget_entries_by_gates_state(
        more ? [...widget_entries_by_gates_state, ...result] : { list: result, count: res?.data?.data?.count }
      );
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };



  // Function to handle widget access gate change
  const handleWidgetAccessGateChange = (gate) => {
    set_widget_filter_value({ ...widget_filter_value, widget_access_gate: gate })
    getWidgetEntriesByGatesStats([gate?.value], table_offset?.widget_entry_by_gate, table_limit)
  }

  // get drawer entries by gates stats
  const getDrawerEntriesByGatesStats = (access_gate_ids, offset, limit, more = false) => {
    const payload = {
      property_id: property?.value,
      access_gate_ids, offset, limit,
      start_date: `${moment(calendar?.value?.from_date).format("YYYY-MM-DD")}`,
      end_date: `${moment(calendar?.value?.to_date).format("YYYY-MM-DD")}`,
      yet_to_checkout: true
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.security_manager_entries_for_all}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructEntriesByGatesData(res?.data?.data?.data);
      set_drawer_entries_by_gates_state(
        more ? [...drawer_entries_by_gates_state, ...result] : result
      );
      setView(true)
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // Function to fetch more data for entries by gates in drawer
  const fetchMoreDrawerEntriesByGatesStats = () => {
    let offset = table_offset?.drawer_entry_by_gate + table_limit
    set_table_offset({ ...table_offset, drawer_entry_by_gate: offset })
    getDrawerEntriesByGatesStats([widget_filter_value?.drawer_access_gate?.value], offset, table_limit, true)
  }

  // Function to handle drawer access gate change
  const handleDrawerAccessGateChange = (gate) => {
    set_widget_filter_value({ ...widget_filter_value, drawer_access_gate: gate })
    getDrawerEntriesByGatesStats([gate?.value], table_offset?.drawer_entry_by_gate, table_limit)
  }

  // Function to load async options for widget
  const loadOptions = async (search = "", array, type, loading) => {
    set_is_widget_option_loading(loading);
    let offset = 0, limit = 10, variables, result;
    if (search && !Boolean(array?.length)) { offset = 0 }
    else { offset = array?.length }
    switch (type) {
      case "access_gates_master":
        variables = {
          status: [true],
          property_id: property?.value,
          search, offset, limit,
        }
        result = await networkCallBack(variables, `${config.api_url}/queries/access_gates_master/by_property`)
        return {
          options: [...result?.access_gates_master],
          hasMore: (array?.length + result?.access_gates_master?.length) < result?.count
        }
      default:
        return { options: [] }
    }
  }

  // Function for network callback for Async Select Options
  const networkCallBack = async (payload, url) => {

    const options = await NetworkCall(
        `${url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
          set_is_widget_option_loading(false);
            let main = response.data.data;
            return main
        })
        .catch((error) => {
          set_is_widget_option_loading(false);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            return null
        });

    return options
}

  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };

  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };
  // Function to handle pagination
  const handleTablePagination = (value) => {
    setPage(value)
    let offset = (value - 1) * limit
    getOnDutyStats(offset, 10)
  }

  // Function to handle page limit
  const handleTablePageLimit = (value) => {
    setLimit(value)
    setPage(1)
    getOnDutyStats(0, limit)
  }
  // Function to handle pagination for entries
  const handleTablePaginationForEntries = (value) => {
    setPage(value)
    let offset = (value - 1) * limit
    getWidgetEntriesByGatesStats([widget_filter_value?.widget_access_gate?.value], offset, 10)
  }

  // Function to handle page limit for entries
  const handleTablePageLimitForEntries = (value) => {
    setLimit(value)
    setPage(1)
    getWidgetEntriesByGatesStats([widget_filter_value?.widget_access_gate?.value], 0, limit)
  }

  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {/* section1 */}
      <Grid container spacing={2}>
        {stats_state?.entry_data?.map?.((x) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={1.5}>
              <CardDetails t={t} data={x} maxWidth={"100%"}
                onClick={() => {
                  getDrawerEntriesByGatesStats(
                    widget_filter_value?.drawer_access_gate?.value ?
                      [widget_filter_value?.drawer_access_gate?.value] : [],
                    table_offset?.drawer_entry_by_gate, table_limit)
                }} />
            </Grid>
          )
        })}
      </Grid>
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={12} md={6} lg={6}>
          <GraphComponent
            padding={"0px 16px"}
            graphData={people_visited_state ?? []}
            t={t}
            graphTitle={("People Visited")}
            chartHeader={t("People Visited")}
            is_bar_new
            chartHeight={350}
            height={300}
            isNoLegend
            scale={scale}
            onZoomClick={zoomGraphFunction}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <GraphComponent
            t={t}
            padding={"0px 16px"}
            graphTitle={("Entries With Check-In And Check-Out")}
            chartHeader={t("Entries With Check-In And Check-Out")}
            graphData={entries_state ?? []}
            is_bar_new
            chartHeight={350}
            isNoLegend
            scale={scale}
            onZoomClick={zoomGraphFunction}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <GraphComponent
            graphTitle={("Visitor Requests")}
            chartHeader={t("Visitor Requests")}
            graphData={stats_state?.VisitorRequest_data ?? []}
            is_semi_circle_new
            isShowLegend
            innerRadius={75}
            paddingAngle={2}
            total={stats_state?.visitorRequestTotal ?? 0}
            minWidth={true}
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '0px 0px 0px',
              display: "auto",
              alignItems: "center",
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <GraphComponent
            graphTitle={("Worker Requests")}
            chartHeader={t("Worker Requests")}
            graphData={stats_state?.WorkerRequest_data ?? []}
            t={t}
            innerRadius={75}
            paddingAngle={2}
            is_semi_circle_new
            isShowLegend
            isNoLegend
            total={stats_state?.workerRequestTotal ?? 0}
            minWidth={true}
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '0px 0px 0px',
              display: "auto",
              alignItems: "center",
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <GraphComponent
            graphTitle={("Collection Requests")}
            chartHeader={t("Collection Requests")}
            graphData={stats_state?.collectionRequest_data ?? []}
            is_pie_new
            t={t}
            isShowLegend
            innerRadius={75}
            paddingAngle={2}
            total={stats_state?.collectionRequestTotal ?? 0}
            minWidth={true}
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '0px 0px 0px',
              display: "auto",
              alignItems: "center",
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <GraphComponent
            graphTitle={("Parking Slot Requests")}
            chartHeader={t("Parking Slot Requests")}
            graphData={stats_state?.ParkingRequest_data ?? []}
            is_pie_new
            isShowLegend
            innerRadius={75}
            paddingAngle={2}
            total={stats_state?.parkingRequestTotal ?? 0}
            minWidth={true}
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '0px 0px 0px',
              display: "auto",
              alignItems: "center",
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={0.5}>
        {stats_state?.parking_data?.map?.((x) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={1.5}>
              <CardDetails t={t} data={x} maxWidth={"100%"} />
            </Grid>
          )
        })}
      </Grid>
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={12} md={6} lg={3}>
          <GraphComponent
            padding={"0px 16px"}
            t={t}
            graphTitle={("Reserved Slots")}
            chartHeader={t("Reserved Slots")}
            graphData={stats_state?.reserved_slots_data ?? []}
            is_variable_pie_new
            height={380}
            isShowLegend
            innerRadius={75}
            paddingAngle={2}
            total={stats_state?.reservedSlotsTotal ?? 0}
            minWidth={true}
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '0px 0px 0px',
              display: "auto",
              alignItems: "center",
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <GraphComponent
            padding={"0px 16px"}
            height={380}
            t={t}
            graphTitle={("Visitor Slots")}
            chartHeader={t("Visitor Slots")}
            graphData={stats_state?.visitor_slots_data ?? []}
            is_variable_pie_new
            isShowLegend
            innerRadius={75}
            paddingAngle={2}
            total={stats_state?.visitorSlotsTotal ?? 0}
            minWidth={true}
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '0px 0px 0px',
              display: "auto",
              alignItems: "center",
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <GraphComponent
            t={t}
            isTable
            title={("On Duty Information")}
            header={t("On Duty Information")}
            tableData={on_duty_state?.list ?? []}
            totalRowsCount={on_duty_state?.count}
            tabelheight={350}
            showpagination={true}
            page={page}
            limit={limit}
            handlePagination={handleTablePagination}
            handleChangeLimit={handleTablePageLimit}
            heading={onDutyHeading(t)}
            path={onDutyPath}
            dataType={[
              { type: ["text"], name: "location" },
              { type: ["text"], name: "name" },
              { type: ["text"], name: "check_in" },
              { type: ["text"], name: "check_out" },
              { type: ["text"], name: "contact_number" },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={12}>
          <GraphComponent
            isTable
            title={("Entries by Gates")}
            header={t("Entries by Gates")}
            is_async_select={true}
            async_select={
              {
                key: property?.value,
                placeholder: "Select Access Gate",
                value: widget_filter_value?.widget_access_gate,
                onChange: (value) => handleWidgetAccessGateChange(value),
                loading: is_widget_option_loading === "widget_access_gates_master",
                loadOptions: (search, array) => loadOptions(search, array, 'access_gates_master', "widget_access_gates_master"),
              }
            }
            tableData={widget_entries_by_gates_state?.list ?? []}
            totalRowsCount={widget_entries_by_gates_state?.count}
            tabelheight={300}
            heading={entriesByGateHeading(t)}
            path={entriesByGatePath}
            showpagination={true}
            page={page}
            limit={limit}
            handlePagination={handleTablePaginationForEntries}
            handleChangeLimit={handleTablePageLimitForEntries}
            dataType={[
              { type: ["text"], name: "pass_type" },
              { type: ["text"], name: "pass_id" },
              { type: ["text"], name: "gate" },
              { type: ["text"], name: "visitor_name" },
              { type: ["text"], name: "visitor_contact" },
              { type: ["text"], name: "tagged_unit" },
              { type: ["text"], name: "check_in" },
              { type: ["text"], name: "check_in_by" },
              { type: ["text"], name: "check_out" },
              { type: ["text"], name: "check_out_by" },
            ]}
          />
        </Grid>
      </Grid>

      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* <Box style={{ fontSize:"1rem", fontWeight: "Bold" }} m={2}>Old Widgets</Box> */}
      {/* <Grid container spacing={2} mt={0.5}>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.visitorCard?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxWidth={"100%"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item sm={4} lg={4.5}>
          <GraphComponent
            title={"Entries With Check-In Only"}
            graphData={details?.checkInEntiries ?? []}
            margin={"20px"}
            isBar
            isZoom
            isLegend
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        <Grid item sm={4} lg={4.5}>
          <GraphComponent
            title={"Entries With Check-Out Only"}
            graphData={details?.checkOutEntiries ?? []}
            margin={"20px"}
            isBar
            isZoom
            isLegend
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.Requests?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxHeight={"117px"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

      </Grid> */}
      {/* section 3 */}
      {/* <Grid container spacing={2} mt={0.3}>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Domestic Help Requests"}
            graphData={details?.domesticHelperRequest ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            innerRadius={50}
            isZoom
            isTotal
            total={details?.domesticRequestTotal ?? 0}
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.visitors?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxWidth={"100%"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Parking Requests"}
            graphData={details?.parkingSlotRequest ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            innerRadius={50}
            isZoom
            isTotal
            total={details?.parkingSlotRequestToltal}
          />
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Parking Slots Occupancy"}
            graphData={details?.parkingSlotOccupancy ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            isZoom
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.vicle?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid> */}
      {/* section 4 */}
      {/* <Grid container spacing={2} mt={0.3}>
        Gates And Parking Area
        <Grid item lg={4.5}>
          <GraphComponentRevenue
            title={"Gates And Parking Area"}
            type={"bar"}
            height={"324px"}
            XAxisKey={"name"}
            yAxisKey={"value"}
            data={details?.gatesAndParkingArea ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={"0px 0px 17px"}
            padding={"19px 19px 0px"}
            isHorzontal
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        <Grid item xs={6}>
          Gates In Change
          <GateInCharge
            company={company}
            property={property}
            calendar={calendar}
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.security?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails
                    data={x}
                    maxWidth={"100%"}
                    height={"127px"}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid> */}
      {/* section 5 */}
      {/* <Grid container spacing={2} mt={0.3}>
        <Grid item sm={6} lg={3}>
          Entries By Gates
          <GraphComponent
            title={"Entries By Gates"}
            graphData={details?.entriesByGates ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            isZoom
          />
        </Grid>
        <Grid item xs={6}>
          Entries By Gates
          <GateInSecurity
            company={company}
            property={property}
            calendar={calendar}
          />
        </Grid>
        <Grid item sm={6} lg={3} alignItems="center">
          Entries By Gates
          <GraphComponent
            title={"Delivery Collection Summary"}
            graphData={details?.deliveryCollectionSummary ?? []}
            indexAxis={"y"}
            isBar
            // scale={scale}
            margin={"15px 0px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            height={"100%"}
            maxHeight={"220px"}
          />
        </Grid>
      </Grid> */}
      {/* zoom dialog for each graph */}
      <DialogDrawer
        header={t(zoomGraph)}
        maxWidth={"md"}
        handleClose={handleCloseModule}
        open={zoom}
        height={"auto"}
        borderRadius={"12px"}
        padding={'0px'}
        onClose={handleCloseModule}
        component={
          <>
            {zoomGraph === "People Visited" && (
              <GraphComponent
                graphData={people_visited_state ?? []}
                isBar
                scale={scale}
                margin={"0px"}
                minWidth={false}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                gheight={'500px'}
                gwidth={'400px'}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}



              />
            )}
            {zoomGraph === "Entries With Check-In And Check-Out" && (
              <GraphComponent
                graphData={entries_state ?? []}
                isBar
                scale={scale}
                margin={"0px"}
                minWidth={false}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                gheight={'500px'}
                gwidth={'400px'}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}


              />
            )}
            {zoomGraph === "Visitor Requests" && (
              <GraphComponent
                graphData={stats_state?.VisitorRequest_data ?? []}
                isPie
                t={t}
                title={"Visitor Requests"}
                header={t("Visitor Requests")}
                innerRadius={100}
                isTotal
                total={stats_state?.visitorRequestTotal ?? 0}
                paddingAngle={2}
                margin={"0px"}
                centerTop="44%"
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}

                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Worker Requests" && (
              <GraphComponent
                graphData={stats_state?.WorkerRequest_data ?? []}
                isPie
                t={t}
                title={"Worker Requests"}
                header={t("Worker Requests")}
                innerRadius={100}
                isTotal
                total={stats_state?.workerRequestTotal ?? 0}
                paddingAngle={2}
                margin={"0px"}
                padding={"16px"}

                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                centerTop="44%"

                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Collection Requests" && (
              <GraphComponent
                graphData={details?.collectionRequest ?? []}
                isPie
                t={t}
                title={"Collection Requests"}
                header={t("Collection Requests")}
                innerRadius={100}
                isTotal
                total={details?.collectionRequestTotal ?? 0}
                paddingAngle={2}
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                centerTop="44%"

                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Parking Slot Requests" && (
              <GraphComponent
                graphData={stats_state?.ParkingRequest_data ?? []}
                isPie
                innerRadius={100}
                isTotal
                total={stats_state?.parkingRequestTotal ?? 0}
                paddingAngle={2}
                t={t}
                title={"Parking Slot Requests"}
                header={t("Parking Slot Requests")}
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                centerTop="44%"

                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Reserved Slots" && (
              <GraphComponent
                graphData={stats_state?.reserved_slots_data ?? []}
                isPie
                innerRadius={100}
                isTotal
                t={t}
                title={t("Reserved Slots")}
                total={stats_state?.reservedSlotsTotal ?? 0}
                paddingAngle={2}
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                centerTop="44%"

                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Visitor Slots" && (
              <GraphComponent
                graphData={stats_state?.visitor_slots_data ?? []}
                isPie
                innerRadius={100}
                isTotal
                total={stats_state?.visitorSlotsTotal ?? 0}
                t={t}
                title={"Visitor Slots"}
                header={t("Visitor Slots")}
                paddingAngle={2}
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                centerTop="44%"

                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
          </>
        }
      />
      <Drawer
        anchor={"right"}
        open={view}
        onClose={() => setView(false)}
      >
        <GraphComponent
          isTableWithInfinity
          title={"Entries by Gates"}
          header={t("Entries by Gates")}
          is_async_select={true}
          async_select={
            {
              key: property?.value,
              placeholder: t("Select Access Gate"),
              value: widget_filter_value?.drawer_access_gate,
              onChange: (value) => handleDrawerAccessGateChange(value),
              loading: is_widget_option_loading === "drawer_access_gates_master",
              loadOptions: (search, array) => loadOptions(search, array, 'access_gates_master', "drawer_access_gates_master"),
            }
          }
          tableData={drawer_entries_by_gates_state ?? []}
          tabelheight={350}
          heading={entriesByGateHeading(t)}
          path={entriesByGatePath}
          fetchMoreData={fetchMoreDrawerEntriesByGatesStats}
          dataType={[
            { type: ["text"], name: "pass_type" },
            { type: ["text"], name: "pass_id" },
            { type: ["text"], name: "gate" },
            { type: ["text"], name: "visitor_name" },
            { type: ["text"], name: "visitor_contact" },
            { type: ["text"], name: "tagged_unit" },
            { type: ["text"], name: "check_in" },
            { type: ["text"], name: "check_in_by" },
            { type: ["text"], name: "check_out" },
            { type: ["text"], name: "check_out_by" },
          ]}
        />
      </Drawer>
    </div>)
  );
};
export default withNamespaces("visitorandSecurityDashboard")(Dashboard);