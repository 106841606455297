export const ProjectCategoryHeading = (t) => [
    { title: t("Reference ID"), field: "ref_id" },
    { title: t("Category Name"), field: "category_name" },
    { title: t("Status"), field: "status", },
    { title: "", field: "icon" },
]

export const ProjectSubCategoryHeading = (t) => [
    { title: t("Reference ID"), field: "ref_id" },
    { title: t("Sub Category Name"), field: "sub_category_name" },
    { title: t("Category Name"), field: "category_name" },
    { title: t("Status"), field: "status", },
    { title: "", field: "icon" },
]

export const ProjectCategoryPath = ["ref_id", "category_name", "status"]
export const ProjectSubCategoryPath = ["ref_id", "sub_category_name", "category_name", "status"]

export const ProjectCategoryType = [
    { type: ["text"], name: "ref_id" },
    { type: ["text"], name: "category_name" },
    { type: ["status"], name: "status" },
    { type: ["more_4"], icon: "icon" },
]

export const ProjectSubCategoryType = [
    { type: ["text"], name: "ref_id" },
    { type: ["text"], name: "sub_category_name" },
    { type: ["text"], name: "category_name" },
    { type: ["status"], name: "status" },
    { type: ["more_4"], icon: "icon" },
]
