import * as React from "react"
const ActiveProjectIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={26} {...props}>
    <defs>
      <linearGradient
        id="a"
        x2={1}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0f5695" />
        <stop offset={0.963} stopColor="#134575" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={0.96}
        y2={-0.007}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#0a85d9" />
      </linearGradient>
    </defs>
    <g data-name="Group 118073">
      <g data-name="Group 118072">
        <g data-name="icons8-project (2)">
          <path
            fill="#184f85"
            d="M13.297.667a.6.6 0 0 0-.6.6v1.2h1.2v-1.2a.6.6 0 0 0-.6-.6Z"
            data-name="Path 101949"
          />
          <path
            fill="url(#a)"
            d="M24.8 31.6a.6.6 0 0 1-.587-.479L21.2 16.562l-3.013 14.56a.6.6 0 0 1-1.175-.243l3.6-17.4a.6.6 0 0 1 1.175 0l3.6 17.4a.6.6 0 0 1-.466.709.645.645 0 0 1-.121.012Z"
            data-name="Path 101950"
            transform="translate(-7.903 -6.933)"
          />
          <path
            fill="#0078d4"
            d="M21.097 1.867h-15.6a.6.6 0 0 0-.6.6v17.4a.6.6 0 0 0 .6.6h15.6a.6.6 0 0 0 .6-.6v-17.4a.6.6 0 0 0-.6-.6Z"
            data-name="Path 101951"
          />
          <path
            fill="url(#b)"
            d="M7.391 7c-.125 8.489-2.837 13.521-3.83 15.076a.341.341 0 0 0 .289.524h15.882a.6.6 0 0 0 .465-.221c.81-.983 3.88-5.367 4-15.379Z"
            data-name="Path 101952"
            transform="translate(-2.506 -4.533)"
          />
          <path
            fill="#50e6ff"
            d="M21.697 1.867h-16.8a.6.6 0 1 0 0 1.2h16.8a.6.6 0 1 0 0-1.2Z"
            data-name="Path 101953"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default ActiveProjectIcon
