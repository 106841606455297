import * as React from "react"
const PropertyBasedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={40}
    height={40}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y1={-1.237}
        y2={0.677}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0d61a9" />
        <stop offset={0.482} stopColor="#0d60a7" />
        <stop offset={0.711} stopColor="#0f5c9f" />
        <stop offset={0.886} stopColor="#125592" />
        <stop offset={1} stopColor="#154e85" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={1.048}
        x2={0.433}
        y1={0.5}
        y2={0.5}
      />
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={-0.073}
        y2={0.823}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#9332bf" />
        <stop offset={1} stopColor="#912fbd" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.111}
        x2={0.91}
        y1={-0.074}
        y2={1.104}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#32bdef" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        xlinkHref="#b"
        id="e"
        x1={0.111}
        x2={0.406}
        y1={0.5}
        y2={0.5}
      />
      <linearGradient
        id="f"
        x1={-3.333}
        x2={-2.333}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0.247}
        x2={1.47}
        y1={0.146}
        y2={1.855}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ac4ad5" />
        <stop offset={1} stopColor="#ae4cd5" />
      </linearGradient>
      <linearGradient
        xlinkHref="#c"
        id="h"
        x1={-0.036}
        x2={1.04}
        y1={-0.186}
        y2={1.214}
      />
      <linearGradient
        xlinkHref="#c"
        id="i"
        x1={0.234}
        x2={2.464}
        y1={-0.648}
        y2={9.559}
      />
    </defs>
    <g data-name="Group 121866">
      <g data-name="icons8-skyscrapers (1)">
        <path
          fill="url(#a)"
          d="M28 11h8.186a.744.744 0 0 1 .744.744v2.233H28Z"
          data-name="Path 102116"
          transform="translate(-4.884 .442)"
        />
        <path
          fill="url(#d)"
          d="M25 14h12.651a.744.744 0 0 1 .744.744v21.582H25Z"
          data-name="Path 102117"
          transform="translate(-4.116 -.326)"
        />
        <path
          fill="url(#b)"
          d="M16.93 13.442H8v-2.057a1.488 1.488 0 0 1 .823-1.331L16.93 6Z"
          data-name="Path 102118"
          transform="translate(.233 1.721)"
        />
        <path
          fill="url(#c)"
          d="M37.977 17.488h1.488V16h-1.488Zm0 2.977h1.488v-1.488h-1.488ZM35 17.488h1.488V16H35Zm0 2.977h1.488v-1.488H35Zm2.977 2.977h1.488v-1.489h-1.488Zm0 2.977h1.488V24.93h-1.488Zm0 2.977h1.488v-1.489h-1.488ZM35 23.442h1.488v-1.489H35Zm0 2.977h1.488V24.93H35Zm0 2.981h1.488v-1.493H35Zm2.977 2.977h1.488v-1.493h-1.488Zm0 2.977h1.488V33.86h-1.488ZM35 32.372h1.488v-1.488H35Zm0 2.977h1.488V33.86H35Z"
          data-name="Path 102119"
          transform="translate(-6.674 -.837)"
        />
        <path
          fill="url(#e)"
          d="M5 34.791h13.4V8L5.884 13.561A1.488 1.488 0 0 0 5 14.921Z"
          data-name="Path 102120"
          transform="translate(1 1.209)"
        />
        <path
          fill="url(#f)"
          d="M0 0h8.93v2.977H0z"
          data-name="Rectangle 58948"
          transform="rotate(180 11.558 7.21)"
        />
        <path
          fill="url(#g)"
          d="M8.488 18.977H7v1.488h1.488ZM11.465 16H9.977v1.488h1.488Zm0 2.977H9.977v1.488h1.488Zm-2.977 2.976H7v1.488h1.488Zm0 2.977H7v1.488h1.488Zm0 2.977H7V29.4h1.488Zm2.977-5.953H9.977v1.488h1.488Zm0 2.977H9.977v1.488h1.488Zm0 2.977H9.977V29.4h1.488Zm-2.977 2.976H7v1.488h1.488Zm0 2.977H7v1.488h1.488Zm2.977-2.977H9.977v1.488h1.488Zm0 2.977H9.977v1.488h1.488Z"
          data-name="Path 102121"
          transform="translate(.488 -.837)"
        />
        <path
          fill="url(#h)"
          d="M15.744 6h11.907a.744.744 0 0 1 .744.744v27.535H15V6.744A.744.744 0 0 1 15.744 6Z"
          data-name="Path 102122"
          transform="translate(-1.558 1.721)"
        />
        <path
          fill="url(#i)"
          d="M19.744 1H25.7a.744.744 0 0 1 .744.744v3.721H19V1.744A.744.744 0 0 1 19.744 1Z"
          data-name="Path 102123"
          transform="translate(-2.581 3)"
        />
        <path
          fill="#50e6ff"
          d="M25.349 9.209v1.488H23.86V9.209Zm-1.489 4.465h1.488v-1.486H23.86Zm0 2.977h1.488v-1.489H23.86Zm0 2.977h1.488v-1.489H23.86Zm-2.977-8.93h1.488V9.209h-1.488Zm0 2.977h1.488v-1.487h-1.488Zm0 2.977h1.488v-1.49h-1.488Zm0 2.977h1.488v-1.49h-1.488Zm-2.977-8.93h1.488v-1.49h-1.487Zm2.977-2.977h1.488V5.488h-1.488Zm-2.977 0h1.488V5.488h-1.487Zm0 5.953h1.488v-1.487h-1.487Zm0 2.977h1.488v-1.49h-1.487Zm0 2.977h1.488v-1.49h-1.487Zm-2.976-8.932h1.488V9.209H14.93Zm0 2.977h1.488v-1.486H14.93Zm0 2.977h1.488v-1.489H14.93Zm0 2.977h1.488v-1.489H14.93Zm8.93 2.977h1.488v-1.489H23.86Zm0 2.977h1.488v-1.494H23.86Zm0 2.977h1.488v-1.49H23.86Zm-2.977-5.953h1.488v-1.49h-1.488Zm0 2.977h1.488v-1.495h-1.488Zm0 2.977h1.488v-1.491h-1.488Zm-2.977-5.953h1.488v-1.491h-1.487Zm0 2.977h1.488v-1.496h-1.487Zm0 2.977h1.488v-1.492h-1.487Zm-2.976-5.957h1.488v-1.488H14.93Zm0 2.977h1.488v-1.493H14.93Zm0 2.977h1.488v-1.489H14.93Zm8.93 2.977h1.488v-1.489H23.86Zm0 2.977h1.488v-1.489H23.86Zm-2.977-2.977h1.488v-1.489h-1.488Zm0 2.977h1.488v-1.489h-1.488Zm-2.977-2.977h1.488v-1.489h-1.487Zm0 2.977h1.488v-1.489h-1.487Zm-2.976-2.977h1.488v-1.489H14.93Zm0 2.977h1.488v-1.489H14.93Z"
          data-name="Path 102124"
        />
        <path
          d="M26.838 11.441V36h.744V11.441Zm-14.14-1.488V36h.744V9.581Z"
          data-name="Path 102125"
          opacity={0.05}
        />
        <path
          d="M26.837 11.441V36h.372V11.441ZM13.07 36h.372V9.581l-.372.186Z"
          data-name="Path 102126"
          opacity={0.07}
        />
      </g>
    </g>
  </svg>
)
export default PropertyBasedIcon
