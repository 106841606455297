import React from "react"

export const LeadTransferredIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_107674" data-name="Group 107674" transform="translate(-121 -503)">
          <circle id="Ellipse_39517" data-name="Ellipse 39517" cx="20" cy="20" r="20" transform="translate(121 503)" fill="#f1e9ff"/>
          <path id="icons8-exchange" d="M8.275,6A2.475,2.475,0,1,0,10.75,8.475,2.478,2.478,0,0,0,8.275,6Zm9.45,0A2.475,2.475,0,1,0,20.2,8.475,2.478,2.478,0,0,0,17.725,6ZM5.475,12.3A1.465,1.465,0,0,0,4,13.758v.1a2.376,2.376,0,0,0,.743,1.729A4.972,4.972,0,0,0,8.275,16.8a4.972,4.972,0,0,0,3.532-1.214,2.376,2.376,0,0,0,.743-1.729v-.1A1.465,1.465,0,0,0,11.075,12.3Zm9.45,0a1.465,1.465,0,0,0-1.475,1.458v.1a2.376,2.376,0,0,0,.743,1.729A4.972,4.972,0,0,0,17.725,16.8a4.972,4.972,0,0,0,3.532-1.214A2.376,2.376,0,0,0,22,13.857v-.1A1.465,1.465,0,0,0,20.525,12.3Zm4.143,5.393a.675.675,0,0,0-.47,1.159l.648.648H15.025a.675.675,0,1,0,0,1.35h4.221L18.6,21.5a.675.675,0,1,0,.954.954l1.8-1.8a.675.675,0,0,0,0-.954l-1.8-1.8a.675.675,0,0,0-.484-.2Zm-12.156,0a.675.675,0,0,0-.464.2l-1.8,1.8a.675.675,0,0,0,0,.954l1.8,1.8A.675.675,0,1,0,7.4,21.5l-.648-.648h4.221a.675.675,0,1,0,0-1.35H6.754l.648-.648a.675.675,0,0,0-.49-1.158Z" transform="translate(128 509)" fill="#896db3"/>
        </g>
      </svg>
      

    )
}