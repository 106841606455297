import { Box } from "@mui/material";
import { ProofComponent } from "../../components";
import { useStyles } from "./style";
export const KycDetails = ({ t, update = () => false, data = [] }) => {
    const classes = useStyles()

    return (
        <Box marginTop="16px" className={classes.box}>
            <ProofComponent t={t} file={data} update={update} />
        </Box>
    )
}