export const ActivitydataType =
    [
        { type: ["avatarmanagement"], name: "image" },
        { type: ["text"], name: "name" },
        { type: ["text"], name: "info" },
        { type: ["date"], name: "Date" },
        { type: ["text"], name: "CreatedBy" },
        { type: ["text"], name: "Priority" },
        { type: ["status2"], name: "Status" },
        { type: ["icon"], icon: "icon" },
    ]



export const ActivityPath = [
    "image",
    "name",
    "info",
    "Date",
    "CreatedBy",
    "Priority",
    "Status",
];
export const ActivityHeading = (t) => [
    { title: "", field: "image" },
    { title: t("Activity Name"), field: "name" },
    { title: t("Action Info"), field: "info" },
    { title: t("Date"), field: "Date" },
    { title: t("Created By"), field: "CreatedBy" },
    { title: t("Priority"), field: "Priority" },
    { title: t("Status"), field: "Status" },
    { title: t("Edit"), field: "icon" },
];

export const ActivityRow = [
    {
        activity_pic: null,
        name: "Call",
        action_info: "9998897898",
        date: "2022-07-13T12:29:49.713371+05:30",
        created_by: "Bala",
        priority: "High",
        status: "Open",
        icon: "edit",
    }
]
///todo
export const todopath = ["check_box", "name", "description"]


export const todoheading = (t) => [
    { title: "", field: "check_box" },
    { title: t("Name"), field: "name", },
    { title: t("Description"), field: "description", },
    { title: t("Edit"), field: "icon" },
]

export const tododataType =
    [
        { type: ["checkBox"], name: "check_box" },
        { type: ["text"], name: "name" },
        { type: ["text"], name: "description" },
        { type: ["icon"], icon: "icon" },
    ]

//quotation
export const quotationpath = [

    "quotationNo",
    "units",
    "value",
    "period",
    "quoteExpireOn",
    "status",
]


export const quotationheading = (t) => [
    { title: t("Quotation No"), field: "quotationNo" },
    { title: t("Units"), field: "units" },
    { title: t("Value"), field: "value" },
    { title: t("Period"), field: "period" },
    { title: t("Quote Expire On"), field: "quoteExpireOn" },
    { title: t("Status"), field: "status" },
]

export const quotationdataType =
    [
        { type: ["text"], name: "quotationNo" },
        { type: ["text"], name: "units" },
        { type: ["text"], name: "value" },
        { type: ["text"], name: "period" },
        { type: ["date"], name: "quoteExpireOn" },
        { type: ["q_status"], name: "status" },
    ]


export const todoState = {
    name: "",
    description: "",
    is_active: true,
    error: {
        name: "",
        description: "",
    }
}


export const returnShortlistGoData = (details) => {
    const result = {
        unitId: details?.shortlisted?.map(val => val?.unit_id),
        companyID: details?.company_id,
        disable: true,
        data: {
            companyID: details?.company_id,
            duration: {
                fromDate: new Date(details?.lease_start_date),
                toDate: new Date(details?.lease_end_date)
            },
            stateFilter: {
                facilitesbool: details?.grace_period_value,
            },
            revenue_type: details?.revenue_type,
            property_purpose: details?.unit_usage,
            account:details?.contact_account,
            borker:details?.broker,
            is_existing:details?.is_existing ?? false,
            primary:details?.primary,
            contact:details?.contact,
            lead_details:details?.lead_details,
            broker_agent_account:details?.broker_agent

        },
        duration: {
            lead: details,
            propertyPurpose: {
                value: details?.unit_usage,
                label: details?.unit_usage
            }
        }
    }
    return result
}