import { Badge, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { withNamespaces } from "react-i18next";
import FilterIMG from "../../assets/filter";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, TextBox, ToggleButtonComponent, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { ParkingGroupingMasterStyles } from "./style";
import { ParkingGroupingMasterHeading, ParkingGroupingMasterPath } from "./utils";



const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    height: '40px'
}))

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    height: `calc(100vh - 153px)`
}))


const initialState = () => {
    return {
        id: null,
        name: "",
        status: "Active",
        description: "",
        edit: false,
        view: false,
        error: {
            name: "",
            description: "",


        }

    }
}

const STATUS_OPTIONS = (t) => {
    return [
        { label: t("Active"), value: "Active" },
        { label: t("Inactive"), value: "Inactive" }
    ]

}


const ParkingGroupingMaster = ({
    t
}) => {
    const defaultFilterState = { status: [true] };
    const classes = ParkingGroupingMasterStyles()
    const [openDialog, setOpenDialog] = React.useState(false)
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const [parkingGroupingMasterData, setParkingGroupingMasterData] = React.useState({ ...initialState() })
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [parkingGroupingMasterList, setParkingGroupingMasterList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    const [loading, setLoading] = React.useState(false)
    const [permission, setPermission] = React.useState({})
    const [propertyOption, setPropertyOption] = React.useState([])
    const [propertyValue, setPropertyValue] = React.useState({})
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);



    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        ParkingGroupingMasterTableData(offset, limit, searchText)
    }
    //page limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        ParkingGroupingMasterTableData(0, value, searchText)
    }
    //search func
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search func
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        ParkingGroupingMasterTableData(0, limit, e)
    }


    //update state
    const updateState = (key, value) => {

        let error = parkingGroupingMasterData?.error;
        error[key] = "";
        setParkingGroupingMasterData({ ...parkingGroupingMasterData, [key]: value })


    }
    //validation
    const validate = () => {
        let isValid = true;
        let error = parkingGroupingMasterData.error;
        if (parkingGroupingMasterData?.name?.length === 0) {
            isValid = false;
            error.name = t("Parking group Name  is Required");
        }

        // if (parkingGroupingMasterData?.description?.length === 0) {
        //     isValid = false;
        //     error.description = "Description is Required";
        // }
        // if (parkingGroupingMasterData?.name?.length === 0 || parkingGroupingMasterData?.description?.length === 0) {
        //     alert.setSnack({
        //         ...alert,
        //         open: true,
        //         severity: AlertProps.severity.error,
        //         msg: "Please fill all mandatory field",
        //     });
        // }
        setParkingGroupingMasterData({ ...parkingGroupingMasterData, error });

        return isValid;
    };


    //Upsert API FUNCTION
    const UpsertParkingGroupingMaster = () => {
        if (validate()) {
            setIsDisableBtn(true)
            let payload;
            if (parkingGroupingMasterData?.id) {
                payload = {
                    name: parkingGroupingMasterData?.name,
                    description: parkingGroupingMasterData?.description,
                    property_id: propertyValue?.value,
                    id: parkingGroupingMasterData?.id,
                    is_active: parkingGroupingMasterData?.status === "Active" ? true : false
                }
            } else {
                payload = {
                    name: parkingGroupingMasterData?.name,
                    description: parkingGroupingMasterData?.description,
                    property_id: propertyValue?.value,
                    is_active: parkingGroupingMasterData?.status === "Active" ? true : false
                }
            }
            NetworkCall(
                `${config.api_url}/parking-grouping-master/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: parkingGroupingMasterData?.id ? t("Parking Grouping Master Updated successfully") : t("Parking Grouping Master Added successfully"),
                    });
                    setParkingGroupingMasterData({ ...initialState() })
                    setOpenDialog(false)
                    ParkingGroupingMasterTableData()
                    setIsDisableBtn(false)
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });
                    setIsDisableBtn(false)
                });

        }

    }

    //table listing function
    const ParkingGroupingMasterTableData = async (offset = 0, limit = 10, search = "") => {
        setLoading(true)
        let is_active = filterData?.status ?? [];
        const payload = {
            "offset": offset,
            "limit": limit,
            "search": search,
            "property_id": propertyValue?.value,
            "is_active": is_active
        }
        NetworkCall(
            `${config.api_url}/queries/parking_grouping_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setParkingGroupingMasterList({
                    data: response?.data?.data?.parking_grouping_master,
                    totalRowsCount: response?.data?.data?.count,
                })
                setLoading(false)
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
                setLoading(false)

            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        const payload = {
            id: data,
            update: {
                is_active: value === "Inactive" ? true : false
            }
        }
        NetworkCall(
            `${config.api_url}/queries/parking_grouping_master/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: t("Status Updated"),
            });
            ParkingGroupingMasterTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }

    //table row constructions
    const ParkingGroupingMasterRow = parkingGroupingMasterList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                name: val?.name ?? " - ",
                description: val?.description ?? "-",
                property: val?.property?.label ?? " - ",
                status: val?.is_active ? "Active" : "Inactive",
                icon: "more_4",
                id: val?.id,

            };
        } catch (err) {

        }
        return _d;
    });


    React.useEffect(() => {
        if (selectedCompany?.value) {
            let properties = selectedCompany?.property?.map((val) => {
                return {
                    value: val.id,
                    label: val.name
                }
            })
            setPropertyOption(properties)
            setPropertyValue(properties?.[0])
        }
        //eslint-disable-next-line
    }, [selectedCompany]);

    React.useEffect(() => {
        if (propertyValue?.value) {
            ParkingGroupingMasterTableData();
        }
        // eslint-disable-next-line
    }, [propertyValue, filterData]);


    const handleIcon = (type, data, status) => {

        if (type === "view") {
            setOpenDialog(true)
            setParkingGroupingMasterData({
                ...parkingGroupingMasterData,
                name: data?.name,
                description: data?.description,
                status: data?.status,
                view: true,
                id: data?.id,

            })
        }
        else if (type === "edit") {
            setOpenDialog(true)
            setParkingGroupingMasterData({
                ...parkingGroupingMasterData,
                name: data?.name,
                description: data?.description,
                status: data?.status,
                edit: true,
                id: data?.id,

            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }


    }

    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    //propertychange
    const onPropertyChange = (val) => {
        setPropertyValue(val)
    }

    // add and edit modal
    const addAndEdit = () => {
        return (
            <>
                <div style={{
                    height: size.height - 250,
                    overflow: "scroll",
                    padding: "24px"
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextBox
                                value={parkingGroupingMasterData?.name}
                                isrequired
                                label={t("Parking Group Name")}
                                placeholder={t("Enter Parking Group Name")}
                                onChange={(e) => {
                                    updateState("name", e.target.value);
                                }}
                                isError={parkingGroupingMasterData?.error?.name?.length > 0}
                                errorMessage={parkingGroupingMasterData?.error?.name}
                                isReadonly={parkingGroupingMasterData?.view}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox
                                value={parkingGroupingMasterData?.description}
                                //isrequired
                                multiline
                                label={t("Description")}
                                placeholder={t("Enter Description")}
                                onChange={(e) => {
                                    updateState("description", e.target.value);
                                }}
                                isError={parkingGroupingMasterData?.error?.description?.length > 0}
                                errorMessage={parkingGroupingMasterData?.error?.description}
                                isReadonly={parkingGroupingMasterData?.view}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom style={{ color: "#98A0AC", fontSize: "0.75rem", marginBottom: "8px" }}>
                                {t("Status")}
                            </Typography>
                            <ToggleButtonComponent
                                options={STATUS_OPTIONS(t)}
                                value={parkingGroupingMasterData?.status}
                                onChange={(value) => updateState('status', value)}
                                isMulti={false}
                                fullWidth={false}
                                isError={parkingGroupingMasterData?.error?.status?.length > 0}
                                errorMessage={parkingGroupingMasterData?.error?.status}
                                isReadOnly={parkingGroupingMasterData?.view}

                            />
                        </Grid>



                    </Grid>
                </div>

                <Grid container padding={"24px"} spacing={2} width={"100%"}>
                    {parkingGroupingMasterData?.view ?
                        <>
                            {permission?.update &&
                                <Grid item xs={12}>
                                    <Button fullWidth variant="contained" className={classes.btn}
                                        onClick={() => setParkingGroupingMasterData({
                                            ...parkingGroupingMasterData,
                                            view: false,
                                            edit: true
                                        })
                                        }
                                    >
                                        {t("Edit")}
                                    </Button>
                                </Grid>}
                        </> :
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" className={classes.btn}
                                onClick={UpsertParkingGroupingMaster}
                                disabled={isDisableBtn}
                            >
                                {parkingGroupingMasterData?.edit ? t("Update") : t("Create")}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    const render = () => {
        return <>
            {loading ? <LoadingSection bottom={"45vh"} message={t("Loading Parking Grouping Listing...")} />
                :
                <>
                    <div>
                        <Subheader title={t("Parking Grouping Master")} count={false}
                            hideBackButton
                            select
                            options={companyList}
                            value={selectedCompany}
                            onchange={(e) =>
                                setSelectedCompany(e)
                            }
                            onPropertyChange={(e) => {
                                onPropertyChange(e)
                            }}
                            selectProperty
                            propertyOptions={propertyOption}
                            propertyValue={propertyValue}

                        />
                        <CustomPaper>
                            <Grid container className={classes.content} spacing={2}>
                                <Grid item xs={4}>
                                    <SearchFilter placeholder={t("Search by Parking group name")} value={searchText} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                </Grid>
                                <Grid item xs={8} textAlign={"right"} justifyContent={"end"} display={"flex"}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Box marginInlineEnd={"12px"}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}

                                        </Box>

                                        {permission?.create &&
                                            <Box>
                                                <CustomButton
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => setOpenDialog(true)}
                                                >
                                                    {t("Add Parking Group")}
                                                </CustomButton>
                                            </Box>}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableWithPagination
                                        heading={ParkingGroupingMasterHeading(t)}
                                        rows={ParkingGroupingMasterRow?.length ? ParkingGroupingMasterRow : []}
                                        path={ParkingGroupingMasterPath}
                                        showpagination={true}
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        onClick={() => null}
                                        showpdfbtn={false}
                                        showexcelbtn={false}
                                        showSearch={false}
                                        dataType={[
                                            { type: ["text"], name: "name", width: "200px" },
                                            { type: ["text"], name: "property", width: "200px" },
                                            { type: ["text"], name: "description", width: "200px" },
                                            { type: ["status"], name: "status", width: "680px" },
                                            { type: ["more_4"], icon: "icon" },
                                        ]}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={parkingGroupingMasterList?.totalRowsCount}
                                        page={page}
                                        limit={limit}
                                        height={'calc(100vh - 299px)'}
                                        view={permission?.read}
                                        edit={permission?.update}
                                        delete={permission?.delete} />
                                </Grid>
                            </Grid>
                        </CustomPaper>

                    </div>
                    {/* add and edit  and view */}
                    <AlertDialog
                        isNormal
                        header={parkingGroupingMasterData?.view ? t("View  Parking Group") : parkingGroupingMasterData?.edit ? t("Edit  Parking Group") : t("Add Parking Group")}
                        onClose={() => {
                            setOpenDialog(false)
                            setParkingGroupingMasterData({ ...initialState() })
                        }}
                        open={openDialog}
                        component={
                            <>
                                <div>
                                    {

                                        addAndEdit()}
                                </div>
                            </>
                        }
                    />


                    {/* filter drawer */}
                    {
                        drawer &&
                        <FilterGenerator
                            open={drawer}
                            onClose={drawerclose}
                            defaultState={defaultFilterState}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: [
                                        { label: t('Active'), value: true },
                                        { label: t('Inactive'), value: false },
                                    ],
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status"),
                                    // required:true
                                },
                            ]}
                            onApply={(value) => onApplyFilter(value)}
                        />
                    }
                </>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

export default withNamespaces("parkingArea")(ParkingGroupingMaster);
