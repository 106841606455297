import * as React from "react"
const BlockBasedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 121866">
      <g data-name="Group 123621">
        <path fill="#0078d4" d="M6 24h28v14H6z" data-name="Rectangle 59182" />
        <path
          fill="#199be2"
          d="M6 4.75V24h28V4.75A2.775 2.775 0 0 0 31.2 2H8.8A2.775 2.775 0 0 0 6 4.75Z"
          data-name="Path 102307"
        />
        <path fill="#0d62ab" d="M21 6h2v2h-2Z" data-name="Path 102317" />
        <path fill="#0d62ab" d="M25 6h2v2h-2Z" data-name="Path 102320" />
        <path fill="#0d62ab" d="M13 6h2v2h-2Z" data-name="Path 102308" />
        <path fill="#0d62ab" d="M17 6h2v2h-2Z" data-name="Path 102309" />
        <path fill="#0d62ab" d="M9 6h2v2H9Z" data-name="Path 102310" />
        <path fill="#0d62ab" d="M29 6h2v2h-2Z" data-name="Path 102323" />
        <path fill="#0d62ab" d="M21 10h2v2h-2Z" data-name="Path 102318" />
        <path fill="#0d62ab" d="M25 10h2v2h-2Z" data-name="Path 102321" />
        <path fill="#0d62ab" d="M13 10h2v2h-2Z" data-name="Path 102311" />
        <path fill="#0d62ab" d="M17 10h2v2h-2Z" data-name="Path 102312" />
        <path fill="#0d62ab" d="M9 10h2v2H9Z" data-name="Path 102313" />
        <path fill="#0d62ab" d="M29 10h2v2h-2Z" data-name="Path 102324" />
        <path fill="#0d62ab" d="M21 14h2v2h-2Z" data-name="Path 102319" />
        <path fill="#0d62ab" d="M25 14h2v2h-2Z" data-name="Path 102322" />
        <path fill="#0d62ab" d="M13 14h2v2h-2Z" data-name="Path 102314" />
        <path fill="#0d62ab" d="M17 14h2v2h-2Z" data-name="Path 102315" />
        <path fill="#0d62ab" d="M9 14h2v2H9Z" data-name="Path 102316" />
        <path fill="#0d62ab" d="M29 14h2v2h-2Z" data-name="Path 102325" />
        <g fill="#0d62ab" data-name="Group 123621">
          <path d="M21 18h2v2h-2z" data-name="Rectangle 59201" />
          <path d="M25 18h2v2h-2z" data-name="Rectangle 59202" />
          <path d="M13 18h2v2h-2z" data-name="Rectangle 59203" />
          <path d="M17 18h2v2h-2z" data-name="Rectangle 59204" />
          <path d="M9 18h2v2H9z" data-name="Rectangle 59205" />
          <path d="M29 18h2v2h-2z" data-name="Rectangle 59206" />
        </g>
        <path fill="#005094" d="M25 28h2v2h-2Z" data-name="Path 102326" />
        <path fill="#005094" d="M13 28h2v2h-2Z" data-name="Path 102327" />
        <path fill="#005094" d="M9 28h2v2H9Z" data-name="Path 102328" />
        <path fill="#005094" d="M29 28h2v2h-2Z" data-name="Path 102329" />
        <path fill="#005094" d="M25 32h2v2h-2Z" data-name="Path 102331" />
        <path fill="#005094" d="M13 32h2v2h-2Z" data-name="Path 102332" />
        <path fill="#005094" d="M9 32h2v2H9Z" data-name="Path 102333" />
        <path fill="#005094" d="M29 32h2v2h-2Z" data-name="Path 102334" />
        <path fill="#005094" d="M17 28h6v10h-6Z" data-name="Path 102330" />
      </g>
    </g>
  </svg>
)
export default BlockBasedIcon
