import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
export const useStylesCreation = makeStyles((theme) => ({
  imagebox: {
    textAlign: 'center',
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    // height: "292px"
  },
  imagebox1: {
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
  },
  imagebox3: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    margin: "-24px"
  },
  button: {
    padding: '8px 16px',
    borderRadius: theme.palette.borderRadius,
    cursor: 'pointer',
    border: '1px solid #E4E8EE',
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    marginTop: '8px'
  },
  button2: {
    padding: '8px 16px',
    borderRadius: theme.palette.borderRadius,
    cursor: 'pointer',
    border: '1px solid #5078E1',
    color: "#5078E1",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    marginTop: '26px'
  },
  Requirements: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  completedCheckboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
  },
  checkboxIconStyle: {
    fontSize: "small",
    color: "white",
  },
  checkboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    border: "1px solid #98A0AC",
  },
  avatar: {
    height: '120px',
    width: '120px'
  },
  title: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: Bold,
    marginBottom: '12px'
  },
  label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize:"0.75rem",
    marginBottom: "5px",
  },
  tabListAssests: {
    display: 'flex'
  },
  listText: {
    backgroundColor: 'white',
    width: '135px !important',
    color: ' #5078E1',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: '1.5px solid #5078E1',

  },
  listText1: {
    width: '120px !important',
    color: '#98A0AC',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    cursor: 'pointer'
  },
  list: {
    '&.MuiList-root': {
      paddingBottom: "0px !important"
    },
    '&.MuiListItem-root': {
      width: '135px !important',
      padding: '0px!important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      // border: '1px solid #E4E8EE',
      cursor: 'pointer'
    }
  },
  next: {
    marginInlineStart: "10px",
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: '#5078E1',
    "&:hover": {
      backgroundColor: '#5078E1',
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: '1px solid #E4E8EE',
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottombtn: {
    padding: '11px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    display: 'flex',
    justifyContent: 'end',
    border: '1px solid #E4E8EE',
    position: 'sticky',
    bottom: '0px',
    width: '100%',
    zIndex: 2,
  },
  removeimg: {
    textDecoration: 'underline',
    color: '#5078E1',
    fontFamily: SemiBold,
    fontSize:"0.75rem",
    marginTop: '6px',
    cursor: 'pointer'
  },
  avatar1: {
    padding: '35px',
    background: '#98A0AC',
    height: '120px',
    width: '120px'
  },
  avatar2: {
    background: '#FFF1E1',
    height: '120px',
    width: '120px',
    margin: "auto"
  },
  tabtitle: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: Bold,
  },
  tabtitle1: {
    fontSize:"0.75rem",
    color: '#5078E1',
    fontFamily: Bold,
  },
  subtabtitle: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: Regular,
  },
  subtabtitle1: {
    fontSize:"0.75rem",
    color: '#5078E1',
    fontFamily: Regular,
  },
  selectBack1: {
    backgroundColor: '#F1F7FF',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  selectBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
  },
  ownerTitle: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    marginTop: "10px",
    textAlign: "-webkit-auto"
  },
  ownerSubtitle: {
    fontSize:"0.75rem",
    direction: "ltr"

  },
  icon: {
    border: "1px solid #E4E8EE",
    padding: "4px",
    borderRadius: "4px !important",
    "&:hover": {
      backgroundColor: "#F1F7FF",
    },

  },
  buttonUnSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  buttonTextUnSelected: {
    fontSize:"0.875rem",
    color: "#4E5A6B",
  },
  buttonSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
  },
  buttonTextSelected: {
    fontSize:"0.875rem",
    color: "white",
  },
  fontSize: {
    fontSize:"0.875rem"
  },
  root2: {
    height: `calc(100vh - 210px)`,
    overflow: "auto",
    '& .MuiTabPanel-root': {
      padding: "16px !important"
    }
  },
  headCell: {
    padding: "8px",
    border: "none"
  },
  headText: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold
  },
  bodyCell: {
    padding: "4px",
    border: "none",
  },
  addShareHolder: {
    fontSize:"0.75rem",
    color: theme.palette.primary.main,
    fontFamily: Bold,
    cursor: "pointer",
    whiteSpace: "noWrap"
  },
  optionAvatar: {
    height: 32,
    width: 32
  },
  optionHeader: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.primary
  },
  optionSubTitle: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: theme.typography.color.secondary
  },
  contactDropdown: {
    cursor: "pointer",
    display: "flex",
    border: "1.5px solid rgb(228, 232, 238)",
    borderRadius: "4px",
    alignItems: "center",
    padding: "8px",
    paddingInlineStart: "14px",
  },
  contactDropdownPlaceholder: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: "#b4b4b4",
  },
  contactDropdownValue: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
    textOverflow: "ellipsis",
  },
  shareHolderContactEnd: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: SemiBold,
  },
  shareHolderContactNoDataBox: {
    padding: "16px 0px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));