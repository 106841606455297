import { config } from "../../config";
import { LocalStorageKeys } from "../../utils";
import { returnValue } from "../companyCreation/utils";

export const listErrorState = [
    {
        profileDetails: [
            { key: "vendorCode", errorMsg: "Vendor Code is Required" },
            { key: "vendorName", errorMsg: "Vendor Name is Required" },
            // { key: "vendorCountry" },
        ],
        // address: [
        //     { key: 'latitude' },
        //     { key: 'longitude' },
        // ],
        contactInformation: [
            { key: "primaryTelephone", errorMsg: "Primary Telephone is Required" },
            { key: "primaryMobile", errorMsg: "Primary Mobile is Required" },
            { key: "primaryEmailAddress", errorMsg: "Primary Email Address is Required" }
        ]
    },
    {
        // tax: [
        //     { key: 'taxGroup' },
        //     { key: 'taxCode' },
        //     { key: 'taxRegistrationNumber' },
        // ],
        // bankingBasicDetails: [
        //     { key: "bankName" },
        //     { key: "branchName" },
        //     { key: "currency" },
        //     { key: "bankAccountType" },
        //     { key: "accountNumber" },
        //     { key: "bankRoutingType" },
        //     { key: "bankRoutingCode" },
        //     { key: "chequeName" },
        //     { key: "addressLineOne" },
        //     { key: "city" },
        //     { key: "country" },
        // ]
    },
    {
        // companyEstateRegistration: [
        //     { key: "companyRegistrationType" },
        //     { key: "companyRegistrationNumber" },
        // ],

    },
]

export const vendorTableDetails = (t) => [{
    header: [
        { title: t("Image") },
        { title: t("Vendor Name") },
        { title: t("Vendor Code") },
        { title: t("Primary Mobile Number") },
        { title: t("Primary Email Id") },
        { title: t("Status") },
        { title: "", field: 'icon' },
    ],
    dataType: [
        { type: ["logo"], name: "image" },
        { type: ["text"], name: "venderName" },
        { type: ["text"], name: "venderCode" },
        { type: ["text"], name: "primaryMobileNumber" },
        { type: ["text"], name: "primaryEmailID" },
        { type: ["status"], name: "status" },
        { type: ["more"], icon: "icon" },
    ]
}]

export const constructVendorListing = (data, offset) => {

    let constructData = data?.length ? data?.map((val, index) => ({
        id: val?.id,
        index: offset + (index + 1),
        image: {
            logo: returnValue(val?.image_url, '-'),
            name: val?.name ? returnValue(val?.name?.charAt(0)) : ''
        },
        venderCode: returnValue(val?.vendor_code, '-'),
        venderName: returnValue(val?.name, '-'),
        primaryMobileNumber: returnValue((`${returnValue(val?.vendor_address?.[0]?.mobile_code)} ${returnValue(val?.vendor_address?.[0]?.mobile_no)}`)?.trim(), "-"),
        primaryEmailID: returnValue(val?.vendor_address?.[0]?.email_id, '-'),
        status: val?.is_active ? "Active" : "Inactive",
    })) : []

    return constructData
}

export const constructPayload = (vendorProfile, paymentTaxDetails, registrationDetails, assets, companyID) => {
    //     const {
    //         profileDetails = null,
    //         logo = null,
    //         address = null,
    //         contactInformation = null
    //     } = vendorProfile;

    // const {
    //     // tax = null,
    //     bankingBasicDetails = null,
    // } = paymentTaxDetails;

    // const {
    //     companyEstateRegistration = null,
    // } = registrationDetails;

    let payload = {
        "tenantId": config.tenantId ?? null,
        "user_profile_id": localStorage.getItem(LocalStorageKeys?.profileID) ?? null,
        "image_url": vendorProfile?.logo?.src ?? null,
        "vendor_code": vendorProfile?.profileDetails?.vendorCode ?? null,
        "name": vendorProfile?.profileDetails?.vendorName ?? null,
        "country_id": vendorProfile?.profileDetails?.vendorCountry?.value ?? null,
        "company_id": companyID,
        "description": vendorProfile?.profileDetails?.vendorDescription ?? null,
        "is_active": true,

        "latitude": vendorProfile?.address?.latitude ?? null,
        "longitude": vendorProfile?.address?.longitude ?? null,
        "door_no": vendorProfile?.address?.doorNumber ?? null,
        "street_1": vendorProfile?.address?.addressLineOne ?? null,
        "street_2": vendorProfile?.address?.addressLineTwo ?? null,
        "landmark": vendorProfile?.address?.landmark ?? null,
        "city": vendorProfile?.address?.city ?? null,
        "state": vendorProfile?.address?.state ?? null,
        "country": vendorProfile?.address?.country ?? null,
        "pincode": vendorProfile?.address?.zipCode ?? null,


        "telephone_code": vendorProfile?.contactInformation?.primaryTelephone?.mobile_code ?? null,
        "telephone_no": vendorProfile?.contactInformation?.primaryTelephone?.mobile ?? null,
        "mobile_code": vendorProfile?.contactInformation?.primaryMobile?.mobile_code ?? null,
        "mobile_no": vendorProfile?.contactInformation?.primaryMobile?.mobile ?? null,
        "email_id": vendorProfile?.contactInformation?.primaryEmailAddress ?? null,
        "website": vendorProfile?.contactInformation?.website ?? null,

        "tax_code": paymentTaxDetails?.tax?.taxCode?.value ?? null,
        "vat_group_id": paymentTaxDetails?.tax?.taxGroup?.value ?? null,
        "tax_no": paymentTaxDetails?.tax?.taxRegistrationNumber ?? null,

        "bank_name": paymentTaxDetails?.bankingBasicDetails?.bankName ?? null,
        "branch_name": paymentTaxDetails?.bankingBasicDetails?.branchName ?? null,
        "currency_id": paymentTaxDetails?.bankingBasicDetails?.currency?.value?.length > 0 || paymentTaxDetails?.bankingBasicDetails?.currency?.value !== "" ?
            paymentTaxDetails?.bankingBasicDetails?.currency?.value : null,
        "account_type": paymentTaxDetails?.bankingBasicDetails?.bankAccountType?.length > 0 ?
            paymentTaxDetails?.bankingBasicDetails?.bankAccountType : null,
        "account_no": paymentTaxDetails?.bankingBasicDetails?.accountNumber ?? null,
        "routing_type": paymentTaxDetails?.bankingBasicDetails?.bankRoutingType?.value?.length > 0 ?
            paymentTaxDetails?.bankingBasicDetails?.bankRoutingType?.value : null,
        "routing_code": paymentTaxDetails?.bankingBasicDetails?.bankRoutingCode ?? null,
        "cheque_name": paymentTaxDetails?.bankingBasicDetails?.chequeName ?? null,
        "address_1": paymentTaxDetails?.bankingBasicDetails?.addressLineOne ?? null,
        "address_2": paymentTaxDetails?.bankingBasicDetails?.addressLineTwo ?? null,
        "bank_city": paymentTaxDetails?.bankingBasicDetails?.city ?? null,
        "bank_country": paymentTaxDetails?.bankingBasicDetails?.country ?? null,

        "company_registration": registrationDetails?.companyEstateRegistration?.companyRegistrationType?.value ?? null,
        "company_registration_no": registrationDetails?.companyEstateRegistration?.companyRegistrationNumber ?? null,

        "assetsData": assets?.uploadedImages?.filter(({ id }) => !id)?.map(({ priority, url }) => ({ priority, url })),
    }
    return payload
}

export const constructVendorDetails = (val) => {

    const returnAssests = (array) => array?.map(({ id = "", url = "", priority = null }) => ({ id, url, priority, asset_type: 2 }));

    let constructState = {};
    let information = val?.vendor_address?.[0] ?? null;

    constructState['vendorProfile'] = {
        logo: val?.image_url ? {
            src: returnValue(val?.image_url, null)
        } : null,
        profileDetails: {
            vendorCode: returnValue(val?.vendor_code),
            vendorName: returnValue(val?.name),
            vendorCountry: val?.country_master ? {
                label: returnValue(val?.country_master?.country_name),
                value: returnValue(val?.country_master?.id),
            } : null,
            vendorCompany: val?.company_master ? {
                label: returnValue(val?.company_master?.name),
                value: returnValue(val?.company_master?.id),
            } : null,
            vendorDescription: returnValue(val?.description),
        },
        address: {
            latitude: returnValue(information?.latitude, null),
            longitude: returnValue(information?.longitude, null),
            doorNumber: returnValue(information?.door_no),
            addressLineOne: returnValue(information?.street_1),
            addressLineTwo: returnValue(information?.street_2),
            landmark: returnValue(information?.landmark),
            city: returnValue(information?.city),
            state: returnValue(information?.state),
            country: returnValue(information?.country),
            pincode: returnValue(information?.pincode),
        },
        contactInformation: {
            primaryTelephone: {
                mobile: returnValue(information?.telephone_no, null),
                mobile_code: returnValue(information?.telephone_code, null)
            },
            primaryMobile: {
                mobile: returnValue(information?.mobile_no, null),
                mobile_code: returnValue(information?.mobile_code, null)
            },
            primaryEmailAddress: returnValue(information?.email_id),
            website: returnValue(information?.website)
        }
    }
    constructState['paymentTaxDetails'] = {
        tax: {
            taxGroup: val?.vat_group_master?.id ? {
                label: returnValue(val?.vat_group_master?.group_name),
                value: returnValue(val?.vat_group_master?.id),
            } : null,
            taxCode: val?.tax_code ? {
                label: returnValue(val?.tax_code),
                value: returnValue(val?.tax_code),
            } : null,
            taxRegistrationNumber: returnValue(val?.tax_no)
        },
        bankingBasicDetails: {
            bankName: returnValue(val?.bank_name),
            branchName: returnValue(val?.branch_name),
            currency: {
                label: returnValue(val?.currency?.name),
                value: returnValue(val?.currency?.id),
            },
            bankAccountType: returnValue(val?.account_type),
            accountNumber: returnValue(val?.account_no),
            bankRoutingType: {
                label: returnValue(val?.routing_type),
                value: returnValue(val?.routing_type)
            },
            bankRoutingCode: returnValue(val?.routing_code),
            chequeName: returnValue(val?.cheque_name),
            addressLineOne: returnValue(val?.address_1),
            addressLineTwo: returnValue(val?.address_2),
            city: returnValue(val?.bank_city),
            country: returnValue(val?.bank_country),
        }
    }
    constructState['registrationDetails'] = {
        companyEstateRegistration: {
            companyRegistrationType: val?.company_registration ? {
                label: returnValue(val?.company_registration),
                value: returnValue(val?.company_registration),
            } : null,
            companyRegistrationNumber: returnValue(val?.company_registration_no),

        }
    }
    constructState['assets'] = {
        uploadedImages: val?.vendor_assets?.length ? returnAssests(val?.vendor_assets) : [],
        deletedImages: [],
    }

    return { ...constructState }

}