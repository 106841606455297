/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Button, Dialog, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import FilterIcon from "../../assets/filterIcon";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  GenralRequestheading,
  NetWorkCallMethods,
  Requestpath,
  accessCheckRender,
  convertTimeUtcToZoneCalander,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  wrapLabelsInT
} from "../../utils";
import { CloseRequest } from "../maintenancerequest/components";
import { loadOptionsApis } from "../maintenancerequest/utils";
import { useStyles } from "./style";

// company drop down option
const GeneralRequest = (props) => {
  const { t } = (props)
  const classes = useStyles();
  const history = useHistory();
  // context
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);

  const [open, setOpen] = useState(false);
  const [list, setList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchdata, setsearchdata] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [companyList, setCompanyList] = React.useState([])
  const [requestclose, setRequestclose] = useState(false);
  const [request, setRequest] = useState({
    type: "",
    value: "",
  });
  const [drawer, setDrawer] = useState(false);
  const [filterData, setFilterData] = useState({
    property: [],
    unit: [],
    status: ['Open', 'Reopened', 'Assigned'],
  });
  const [enumValue, setEnumValue] = useState({
    type: [],
  });
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (localStorage.getItem("authToken")) {
          let company = getCompanyOption(backdrop, auth, alert)
          if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            if (company?.list?.length > 0) {
              getTable(
                0,
                10,
                searchdata,
                company?.selected,
                filterData?.unit,
                filterData?.status
              );
              getEnum();
            }
          }
        } else {
          history.push(Routes.login);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // handle icon
  const handleIcon = (type, value) => {
    if (type === "edit") {
      history.push({
        pathname: Routes.creategeneralrequest,
        data: {
          main: {
            company: selectedCompany,
            isEdit: true,
            id: value?.id ?? "",
          },
        },
      });
    } else {
      // setRequest({
      //   type: type ?? "",
      //   value: value ?? "",
      // });
      // setOpen(true);
      history.push({
        pathname: Routes.generalRequestView,
        state: {
          main: {
            value: value?.id ?? "",
            general_request_id: value?.id ?? "",
            type: type,
            request: "General",
            company: selectedCompany?.value,
            companyData: selectedCompany,
            unit_id: value?.unit_id


          },
        },
      });
    }
  };
  // pagination function
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getTable(offset, limit, searchdata, selectedCompany, filterData?.unit, filterData?.status);
  };
  // handle change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getTable(0, value, searchdata, selectedCompany, filterData?.unit, filterData?.statu);
  };
  // close dialog
  const CloseDialog = (v) => {
    if (v === "open") {
      setOpen(false);
      setRequestclose(true);
    } else {
      setOpen(false);
    }
  };

  // add request function
  const handleClick = () => {
    history.push({
      pathname: Routes.creategeneralrequest,
      data: {
        main: {
          company: selectedCompany,
        },
      },
    });
  };
  // get list
  const getTable = (offset, limit, value, company, unit, status) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    const requestPayload = {
      company_id: [company?.value],
      request_type: ["General"],
      search: value?.length > 0 ? value : undefined,
      offset: offset,
      limit: limit,
      request_units: unit?.length > 0 ? unit?.map((x) => x?.value) : undefined,
      request_status: status?.length > 0 ? status : undefined,
    };
    NetworkCall(
      `${config.api_url}/request`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((response) => {
        let arry = [];
        if (response?.data?.data && response?.data?.data.length > 0) {
          response?.data?.data?.map((val, index) => {
            let obj = {
              sno: offset + index + 1,
              index: offset + index + 1,
              id: val?.id,
              requestName: val?.subject ?? "-",
              request_no: val?.request_no ?? "-",
              category: val?.request_type ?? "-",
              raisedDate: val?.raised_on ? convertTimeUtcToZoneCalander(new Date(val?.raised_on)) : "-",
              property_name: val?.property_name ?? "-",
              unit: val?.units?.name ?? "",
              status: val?.request_status === "Closed" ? "Resolved" : val?.request_status ?? "-",
              icon: (val?.request_status === "Closed" || val?.request_status === "Cancelled") ? "" : "edit",
              ...val,
            };
            arry.push(obj);
          });
        }
        setList({
          data: arry,
          total: response?.data?.totalCount,
        });
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const drawerclose = () => {
    setDrawer(false);
  };
  const onApplyFilter = (value) => {
    setFilterData(value);
    getTable(
      0,
      10,
      searchdata,
      selectedCompany,
      value?.unit,
      value?.status
    );
  };
  const manualResponse = (array) => {
    const result = array?.unit?.map((x) => {
      return ({
        ...x,
        label: x?.label,
        value: x?.value
      })
    })
    return result
  }
  // enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.general_maintenance_type]);
    setEnumValue({
      type: result?.general_maintenance_type,
    });
  };

  const render = () => {
    return <>
      {/* sub nav bar */}
      <Subheader
        hideBackButton={true}
        title={`${t("General Requests")} (${list?.total ?? 0})`}
        select
        options={companyList}
        value={selectedCompany}
        goBack={() => {
          history.push(Routes.home);
        }}
        onchange={(e) => {
          setSelectedCompany(e);
          getTable(
            0,
            limit,
            searchdata,
            e,
            filterData?.unit,
            filterData?.status
          );
        }}
      />

      <div className={classes.root}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={4}>
            <SearchFilter
              value={searchdata}
              handleChange={(value) => {
                setsearchdata(value);
                getTable(
                  0,
                  10,
                  value,
                  selectedCompany,
                  filterData?.property,
                  filterData?.unit,
                  filterData?.status
                );
              }}
              placeholder={t("Search by Request ID")}
              customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
            />
          </Grid>
          <Grid item xs={8} textAlign={"right"}>
            <Box display={"flex"} justifyContent={'end'}>
              {(filterData?.status?.length > 0 || filterData?.unit?.length > 0) ?
                <FilterIcon onClick={() => setDrawer(true)} />
                : <IconButton
                  size="small"
                  className={classes.img}
                  onClick={() => setDrawer(true)}
                >
                  <img src="/images/filter.svg" alt="filter" />
                </IconButton>
              }
              &nbsp; &nbsp; &nbsp;
              {permission?.create && <Box>
                <Button variant="contained" onClick={handleClick} sx={{ height: '40px' }}>
                  {t("Add Request")}
                </Button >
              </Box >}
            </Box >
          </Grid >
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "24px",
              height: "calc(100vh - 228px)"
            }}
          >
            <>
              <Grid item xs={12}>
                <TableWithPagination
                  heading={GenralRequestheading(t)}
                  rows={list?.data ?? []}
                  path={Requestpath}
                  showpagination={true}
                  onClick={() => false}
                  handleIcon={handleIcon}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  showSearch={false}
                  page={page}
                  totalRowsCount={list?.total ?? 0}
                  limit={limit}
                  tableType="no-side"
                  dataType={[
                    { type: ["icon"], icon: "icon" },
                    { type: ["maintenance_status"], name: "status" },
                    { type: ["text"], name: "request_no" },
                    { type: ["description"], name: "requestName" },
                    { type: ["text"], name: "property_name" },
                    { type: ["text"], name: "unit" },
                    { type: ["text"], name: "category" },
                    { type: ["text"], name: "build_source" },
                    { type: ["text"], name: "raisedDate" },
                    // { type: ["text"], name: "closed_date" },
                    // { type: ["text"], name: "contact_name" },
                    // { type: ["text"], name: "contact_number" },
                  ]}
                  height={"calc(100vh - 322px)"}
                  view={permission?.read}
                  edit={permission?.update}
                  delete={permission?.delete} />
              </Grid>
            </>
          </Grid>
        </Grid >

        {/* View Dialog */}
        {/* <Dialog open={open} className={classes.dialogStyle} maxWidth={"md"}>
          <ViewSample
            request={"General"}
            CloseDialog={CloseDialog}
            value={request?.value}
            handleIcon={handleIcon}
          />
        </Dialog> */}
        {/* close request */}
        <Dialog open={requestclose} className={classes.closeDialog}>
          <CloseRequest
            value={request?.value}
            request={"General"}
            setRequestclose={setRequestclose}
            setRequest={setRequest}
            getTable={getTable}
            selectedCompany={selectedCompany}
            searchdata={searchdata}
            t={t}
          />
        </Dialog>
      </div >
      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={drawerclose}
          components={[
            {
              component: "select",
              value: filterData?.unit,
              options: [],
              isMulti: true,

              label: t("Unit"),
              state_name: "unit",
              placeholder: t("Select Unit"),
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/unit/list",
                  {
                    "company_id": selectedCompany?.value,
                    "status": [true]
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponse
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "toggleButton",
              value: filterData?.status,
              options: wrapLabelsInT(enumValue?.type, t) ?? [],
              isMulti: true,
              state_name: "status",

              label: t("General Status"),
              // required:true
            },
          ]}
          onApply={(value) => onApplyFilter(value)}
        />
      )}
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};

export default withNamespaces("maintanceRequest")(GeneralRequest)
