import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router";
import { LoadingSection, Subheader } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods, enumSelect, enum_types } from "../../../utils";
import { useStyles } from "../style";
import { tabTittle } from "../utils";
import FeedBackReplies from "./feedBackReplies";
import Form from "./form";
import { PropertyAndrRoles } from "./propertiesAndRoles";


const ViewFeedBackForm = ({ state = {}, t = () => false }) => {
    const history = useHistory()
    const classes = useStyles()
    const [offset, setOffset] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = useState({
        tabValue: 0,
    });
    const [data, setData] = React.useState()
    // get feedBack details by id
    const GetFeedBackDetailsById = (offset = 0, limit = 15, showMore = false) => {
        if (!showMore) {
            setLoading(true)
        }
        const payload = {
            id: state?.main?.data?.id,
            company_id: state?.main?.company?.value,
            offset: offset,
            limit: limit
        }
        NetworkCall(
            `${config?.api_url}/survey/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(async (res) => {
            setLoading(false)
            const enumData = await enumSelect([enum_types.feedback_element_type])
            const result = res?.data?.data
            setData({
                ...result, "property": !showMore ? result?.property_details?.data?.map((x) => {
                    return {
                        title: x?.name,
                        subTitle: `${x?.state} , ${x?.country}`,
                        is_active: false,
                        logo: x?.logo,
                        id: x?.id,
                    }
                }) : [...data.property, ...result?.property_details?.data?.map((x) => {
                    return {
                        title: x?.name,
                        subTitle: `${x?.state} , ${x?.country}`,
                        is_active: false,
                        logo: x?.logo,
                        id: x?.id,
                    }
                })],
                "roles": result?.build_name?.map((x) => {
                    return {
                        title: x?.build_name,
                        subTitle: x?.build_code,
                        is_active: false,
                    }
                }),
                "form_title": result?.survey_details?.title,
                "questions": result?.survey_details?.survey_element?.map((x) => {
                    return {
                        question: x?.title,
                        type: x?.type,
                        name: enumData?.feedback_element_type?.filter((val) => val.value === x.type)?.[0]?.label,
                        is_mandatory: x?.is_mandatory,
                        options: x?.data_type_options,
                        start_range: x.type === "rating" ? x.data_type_options?.[0]?.value : 1,
                        end_range: x.type === "rating" ? x.data_type_options?.[x.data_type_options?.length - 1]?.value : 10,
                    }
                })
            })
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    React.useEffect(() => {
        GetFeedBackDetailsById()
        //eslint-disable-next-line
    }, [])
    const handleTab = (val) => {
        setValue({ tabValue: val })
    }
    const fetchMoreDataProperty = () => {
        setOffset(offset + 15);
        GetFeedBackDetailsById(offset + 15, 15, true);
    }
    const component = {
        0: (
            <PropertyAndrRoles data={data} t={t} state={state} fetchMoreDataProperty={fetchMoreDataProperty} />
        ),
        1: (
            <Form data={data} state={state} questions={data?.questions} />
        ),
        2: (
            <FeedBackReplies data={data} state={state} />
        ),
    };
    return (
        <Box>
            <Subheader
                title={t("View")}
                goBack={() => {
                    history.goBack(-1);
                }}
            />
            {loading ?
                <LoadingSection />
                :
                <>
                    <Box className={classes.tab}>
                        {tabTittle(value?.tabValue, classes, state?.main?.is_view)?.filter((x) => { return x?.is_active })?.map((val, index) => {
                            return (
                                <Box className={val?.selectBack} onClick={() => handleTab(val?.value)}>
                                    {val?.imgSelect ?? ""}
                                    <Typography className={val?.className}>{t(val?.label)}</Typography>
                                    <Box className={classes.borderAbsolute} />
                                </Box>
                            );
                        })}
                    </Box>
                    <div>{component[value?.tabValue]}</div>
                </>
            }
        </Box>
    )
}
export default withNamespaces("formBuilder")(ViewFeedBackForm)