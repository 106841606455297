import { makeStyles } from "@mui/styles";
import { Bold, ExtraBold, Italic, Regular, SemiBold, remCalc } from "../../utils";


export const FunctionalStyle = makeStyles((theme) => ({

    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        justifyContent: "center",
    },
    addNewButton: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "0.875rem",
        height: '40px'
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginLeft: "16px",
        height: '40px',
        width: '40px'
    },
    addNewDialog: {
        "& .MuiDialog-paper": {
            width: "551px",
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            backgroundColor: "white",
            textAlign: "start"
        },
    },
    addNewDialogHeader: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    addNewDialogHeaderTitle: {
        fontSize: "1rem",
        color: "#091B29",
        fontFamily: ExtraBold
    },
    addNewDialogHeaderCloseButton: {
        padding: "0px"
    },
    addNewDialogHeaderCloseIcon: {
        height: "14px",
        width: "14px",
        padding: "0px"
    },
    addNewDialogQuestionBody: {
        padding: "24px"
    },
    addNewDialogFormBody: {
        // maxHeight: "504px",
        overflow: "overlay",
        padding: "16px"
    },
    addQuestionCard: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
    },
    addQuestionContent1: {
        marginRight: "12px",
        display: "flex",
        alignItems: "center"
    },
    addQuestionContent2: {
        display: "flex",
        alignItems: "center"
    },
    addQuestionSerialAvatar: {
        height: "22px",
        width: "22px",
    },
    addQuestionSerialText: {
        fontSize: "0.75rem",
        color: "white",
        fontFamily: Bold
    },
    addNewDialogQuestionText: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        marginInlineStart: "12px"
    },
    addNewDialogInfoIcon1: {
        marginInlineEnd: "24px",
        display: "flex",
        alignItems: "center"
    },
    addNewDialogInfoIcon2: {
        display: "flex",
        alignItems: "center"
    },
    addNewDialogFormContent1: {
        padding: "24px",
        borderBottom: "1px solid #E4E8EE"
    },
    addNewDialogFormContent2: {
        padding: "24px",
    },
    addNewDialogFormFieldLabel: {
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        fontSize: "0.875rem",
        marginBottom: "5px",
        direction: "ltr"
    },
    addNewDialogFormButtonUnSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    addNewDialogFormButtonTextUnSelected: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
    },
    addNewDialogFormButtonSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    addNewDialogFormButtonTextSelected: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "white",
    },
    switchContainerOn: {
        position: "relative",
        height: "20px",
        width: "36px",
        borderRadius: "20px",
        backgroundColor: "#EEF9EE"
    },
    switchToggleOn: {
        position: "absolute",
        right: "0px",
        height: "20px",
        width: "20px",
        borderRadius: "20px",
        border: "2px solid white",
        backgroundColor: "#5AC782"
    },
    switchContainerOff: {
        position: "relative",
        height: "20px",
        width: "36px",
        borderRadius: "20px",
        backgroundColor: "#E4E8EE"
    },
    switchToggleOff: {
        height: "20px",
        width: "20px",
        borderRadius: "20px",
        border: "2px solid #E4E8EE",
        backgroundColor: "#98A0AC"
    },
    addNewDialogCustomSwitchOn: {
        cursor: "pointer",
        // display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        minHeight: "50px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
    },
    addNewDialogCustomSwitchContent1: {
        marginRight: "12px",
        display: "flex",
        alignItems: "center"
    },
    addNewDialogCustomSwitchText: {
        color: "#091B29",
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        direction: "ltr"
    },
    addNewDialogCustomSwitchContent2: {
        display: "flex",
        alignItems: "center",
        direction: "ltr"
    },
    addNewDialogCustomSwitchOff: {
        cursor: "pointer",
        // display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        minHeight: "50px",
        border: "1px solid #F2F4F7",
        backgroundColor: "#F2F4F7",
    },
    addNewDialogButtonContentAdd: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
    },
    addNewDialogButtonContentViewEdit: {
        justifyContent: "center",
        alignItems: "center",
        padding: "24px",
    },
    addNewDialogBackButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    addNewDialogBackButtonText: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
    },
    addNewDialogAddButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    addNewDialogAddButtonText: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "white",
    },
    addNewDialogViewEditButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    addNewDialogViewEditButtonText: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "white",
    },
    addAdditionalFields: {
        cursor: "pointer",
        // display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "8px",
        borderRadius: theme.palette.borderRadius,
        // minHeight: "50px",
        border: "1px solid #F2F4F7",
        backgroundColor: "#F2F4F7",
    },
    toggleBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    AssociateText: {
        color: "#98A0AC",
        fontSize: remCalc(12),
        fontFamily: Bold,
    },
    switchBox: {
        borderRadius: "4px",
        backgroundColor: "#F5F7FA",
        padding: "12px",
        marginTop: "16px",

    },
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 150px)',
        // overflow: "auto",
        backgroundColor: "white"
    },
    box2: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 150px)',
        // overflow: "auto",
        backgroundColor: "white"
    },
    noData: {
        color: "#98A0AC",
        fontSize: remCalc(12),
        fontFamily: Bold,
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: `translate(-50 %, -50 %)`
    },
    cancelButton: {
        "&:hover": {
            backgroundColor: "white",
        },
    },
}))



export const FunctionalDetailsStyle = makeStyles((theme) => ({
    mainbox: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        height: "300px"
    },
    avatar: {
        height: "80px",
        width: "80px",
    },
    activeText: {
        display: "flex",
        justifyContent: 'center',
        backgroundColor: "#5ac782",
        height: "16px",
        borderRadius: "12px",
        border: "1px solid #fff",
        color: "#fff",
        fontSize: remCalc(10),
        fontFamily: Bold,
        marginTop: "70px",
        position: "absolute",
        alignItems: "center",
        padding: "1px 4px",
    },
    inactiveText: {
        display: "flex",
        justifyContent: 'center',
        backgroundColor: "#FF4B4B",
        height: "16px",
        borderRadius: "12px",
        border: "1px solid #fff",
        color: "#fff",
        fontSize: remCalc(10),
        fontFamily: Bold,
        marginTop: "70px",
        position: "absolute",
        alignItems: "center",
        padding: "1px 4px",
    },
    locationName: {
        color: "#091B29",
        fontSize: remCalc(18),
        fontFamily: Bold,
    },
    imgBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    desc: {
        color: "#4E5A6B",
        fontSize: remCalc(14),
        fontFamily: Regular,
        marginTop: "6px",
    },
    location: {
        color: "#98A0AC",
        fontSize: remCalc(12),
        fontFamily: Italic,
        marginTop: "12px"
    },
    parent: {
        color: "#4E5A6B",
        fontSize: remCalc(12),
        fontFamily: Italic,
        marginTop: "14px",
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        height: "20px",
        padding: "2px 4px "
    },
    textBox: {
        marginTop: "10px"
    },
    parentBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}))