import { Avatar, Stack, Typography } from "@mui/material"
import { concat_string, stringAvatar } from "../../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const ShareHolderContactCard = ({
    classes = {},
    data = {},
    onClick = () => false,
}) => {
    return <Stack direction={"row"} columnGap={"16px"} p={"8px"}
        justifyContent={"space-between"} alignItems={"center"}
        sx={{ cursor: "pointer" }} onClick={() => onClick(data)}>
        <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}>
            <Avatar className={classes.optionAvatar}
                src={data?.image_url}
                {...stringAvatar(data?.first_name)}></Avatar>
            <Stack>
                <Typography className={classes.optionHeader}>
                    {concat_string(
                        {
                            user_no: data?.user_no,
                            first_name: data?.first_name,
                        },
                        ["user_no", "first_name"],
                        " - "
                    )}
                </Typography>
                <Typography className={classes.optionSubTitle}>
                    {concat_string(
                        {
                            mobile: concat_string(
                                {
                                    mobile_code: data?.mobile_no_country_code,
                                    mobile_no: data?.mobile_no,
                                },
                                ["mobile_code", "mobile_no"], " - "
                            ),
                            email_id: data?.email_id,
                        },
                        ["mobile", "email_id"], ", "
                    )}
                </Typography>
            </Stack>
        </Stack>
        {data?.isSelected
            ? <CheckCircleIcon htmlColor="#5078E1" />
            : <CheckCircleOutlineIcon htmlColor="#98A0AC" />}
    </Stack>
}