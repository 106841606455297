import React from "react"

export const LeadQualifiedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Group_107673" data-name="Group 107673" transform="translate(-121 -503)">
    <circle id="Ellipse_39517" data-name="Ellipse 39517" cx="20" cy="20" r="20" transform="translate(121 503)" fill="#d5f5d5"/>
    <g id="Group_105494" data-name="Group 105494" transform="translate(21624 -4302)">
      <rect id="Rectangle_55517" data-name="Rectangle 55517" width="20" height="20" transform="translate(-21493 4815)" fill="none"/>
      <path id="icons8-leadership" d="M9.993,5.986a.417.417,0,0,0-.41.422v2.5a.417.417,0,1,0,.833,0v-2.5a.417.417,0,0,0-.423-.422ZM5.779,7.6a.417.417,0,0,0-.291.715l1.768,1.768A.417.417,0,1,0,7.845,9.5L6.077,7.73a.417.417,0,0,0-.3-.126Zm8.43,0a.417.417,0,0,0-.286.126L12.155,9.5a.417.417,0,1,0,.589.589l1.768-1.768a.417.417,0,0,0-.3-.715ZM10,10.159a2.079,2.079,0,0,0-1.561.666,2.185,2.185,0,0,0,0,2.835,2.163,2.163,0,0,0,3.122,0,2.185,2.185,0,0,0,0-2.835A2.079,2.079,0,0,0,10,10.159Zm0,1.25a.75.75,0,0,1,.627.246.938.938,0,0,1,0,1.175.75.75,0,0,1-.627.246.75.75,0,0,1-.627-.246.938.938,0,0,1,0-1.175A.75.75,0,0,1,10,11.409Zm-6.25,1.25a2.079,2.079,0,0,0-1.561.666,2.184,2.184,0,0,0,0,2.835,2.079,2.079,0,0,0,1.561.666,2.079,2.079,0,0,0,1.561-.666,2.185,2.185,0,0,0,0-2.835A2.079,2.079,0,0,0,3.75,12.659Zm12.5,0a2.079,2.079,0,0,0-1.561.666,2.185,2.185,0,0,0,0,2.835,2.163,2.163,0,0,0,3.122,0,2.185,2.185,0,0,0,0-2.835A2.079,2.079,0,0,0,16.25,12.659Zm-12.5,1.25a.75.75,0,0,1,.627.246.938.938,0,0,1,0,1.175.75.75,0,0,1-.627.246.75.75,0,0,1-.627-.246.938.938,0,0,1,0-1.175A.75.75,0,0,1,3.75,13.909Zm12.5,0a.75.75,0,0,1,.627.246.938.938,0,0,1,0,1.175.922.922,0,0,1-1.253,0,.938.938,0,0,1,0-1.175A.75.75,0,0,1,16.25,13.909Zm-8.728,1.25A1.282,1.282,0,0,0,6.25,16.431v3.29A2.256,2.256,0,0,0,7.4,21.568a4.82,4.82,0,0,0,2.6.675,4.818,4.818,0,0,0,2.6-.675,2.257,2.257,0,0,0,1.155-1.847v-3.29a1.282,1.282,0,0,0-1.272-1.272Zm0,1.25h4.956c.019,0,.022,0,.022.022v3.29c0,.231-.151.522-.576.793A3.65,3.65,0,0,1,10,20.993a3.65,3.65,0,0,1-1.924-.479,1.02,1.02,0,0,1-.576-.793v-3.29C7.5,16.411,7.5,16.409,7.522,16.409Zm-6.25,1.25A1.274,1.274,0,0,0,0,18.931v.79c0,1.438,1.612,2.522,3.75,2.522a5,5,0,0,0,2.458-.589,2.807,2.807,0,0,1-.792-1.933V17.659Zm13.311,0v2.062a2.807,2.807,0,0,1-.792,1.933,5,5,0,0,0,2.458.589c2.138,0,3.75-1.084,3.75-2.522v-.79a1.274,1.274,0,0,0-1.272-1.272Z" transform="translate(-21493 4811.014)" fill="#5ac782"/>
    </g>
  </g>
</svg>


    )
}