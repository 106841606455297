import React from 'react';
import { Box, List, ListItem, Typography } from "@mui/material";
import ImgUpload from "../../../components/imgUpload";
import { useStylesCreation } from "./style";
import { BackdropContext } from "../../../contexts";
import { assestType } from '../../../utils/common'
import { NetworkCall } from '../../../networkcall';
import { config } from '../../../config';
import { NetWorkCallMethods } from '../../../utils';


export const Assests = (props) => {
      const classes = useStylesCreation()
      const backdrop = React.useContext(BackdropContext);
      const [uuid, setUUID] = React.useState(1);
      const [list, setList] = React.useState([])
      const [indexs, setIndexs] = React.useState(0);

      const handleList = (val, index) => {
            setIndexs(index)
      };
      const getAssests = () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            NetworkCall(
                  `${config.api_url}/queries/asset_master/get`,
                  NetWorkCallMethods.post, {}, null, true, false
            ).then((res) => {
                  setList(res.data?.data?.asset_master);
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });

            }).catch((err) => { console.log(err) })
      }
      React.useEffect(() => {
            getAssests()
            // eslint-disable-next-line
      }, [])

      return (
            <>
                  <Box className={classes.imagebox3}>
                        <List style={{ display: 'flex', marginBottom: '14px', marginLeft: "12px" }} className={classes.list}>
                              {
                                    list?.filter(x => x?.id !== assestType?.Thumbnails)?.map((x, index) => {
                                          return (
                                                <ListItem className={index === indexs ? classes.listText : classes.list}
                                                      onClick={() => handleList(x?.id, index)}
                                                >
                                                      <Typography className={classes.tabname}>{x?.type}</Typography>
                                                </ListItem>
                                          )

                                    })
                              }
                        </List>
                  </Box>
                  <br />
                  <Box className={classes.imagebox1}>


                        {
                              list?.filter(x => x?.id !== assestType?.Thumbnails)?.map((x, index) => {
                                    return (
                                          <>
                                                {
                                                      indexs === index &&
                                                      <ImgUpload
                                                            accept={x?.accept}
                                                            setUUID={setUUID ?? null}
                                                            uuid={uuid ?? null}
                                                            updateImageURL={props?.setImages}
                                                            handleFile={props?.handleAssetFile}
                                                            selectedImageURL={props?.images ?? []}
                                                            id={x?.order}
                                                            companyID={props?.companyId ?? ""}
                                                            link
                                                      />
                                                }

                                          </>
                                    )
                              })
                        }

                  </Box>
            </>

      )
}