import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { ContactList, TextBox } from "../index";
import { useStyles } from "./styles";
export const ContactSearch = ({ t, search = "", onClose = () => false, setSearch = () => false, open = false, chooseCustomer = () => false }) => {
    const classes = useStyles()
    const [contact, setContact] = React.useState([])
    const [offset, setOffset] = React.useState(0)
    const size = useWindowDimensions()
    //get contact
    const getContact = (search, offset, searchBool) => {
        const payload = {
            search: search,
            offset: offset,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/account/get_detail`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((response) => {
            if (searchBool) {
                setContact(response?.data?.data?.contact);
            } else {
                setContact(contact.concat(response?.data?.data?.contact));
            }

        }).catch((err) => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        //initial load
        getContact(search, 0, false)
        // eslint-disable-next-line
    }, [])
    const handleexisitlead = (val) => {
        chooseCustomer(val)
        onClose()
    }
    const fetchMoreData = () => {
        setOffset(offset + 10, false)
        getContact(search, offset + 10, false)
    }

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
            <Box display="flex" alignItems="center" p={1} className={classes.titleRoot}>
                <Box flexGrow={1}>
                    <Typography className={classes.title}>{t("Search Contact")}</Typography>
                </Box>
                <Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box p={1}>
                {/*search box */}
                <TextBox
                    color="#F5F7FA"
                    onChange={(e) => {
                        setSearch(e.target.value)
                        getContact(e.target.value, 0, true)
                    }}
                    label={false}
                    autoFocus={true}
                    value={search}
                    placeholder={t("Search Contact")} />
                <Box p={2}>
                    <InfiniteScroll
                        dataLength={contact?.length}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 400}
                    >
                        {contact?.map((val) => {
                            return (
                                <>
                                    <ContactList data={{
                                        name: `${val?.first_name} ${val?.last_name ?? ""}`,
                                        img: val?.get_assets_url_id,
                                        phone: val?.mobile_no,
                                        mail: val?.email,
                                        id: val?.id
                                    }}
                                        onClick={() => handleexisitlead(val)}
                                        leadtype={true} />
                                    <Box height="8px" />
                                </>
                            );
                        })}
                    </InfiniteScroll>
                </Box>
            </Box>
        </Dialog>

    )
}