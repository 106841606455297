import React from 'react'
import { Box, Stack, Typography, Avatar, Grid } from "@mui/material"
import { MemberSelectBox } from './memberSelectBox'
import { SelectBox } from '../../../components'
import NoSelectionIcon from '../../../components/newFormSteps/utils/noSelectionIcon'
import { ProjectStyle } from '../style'
import { SelectedMemberCard } from './selectedMemberCard'
import { CloseIconWithBG } from '../../../assets'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { ProjectContext } from '../projectContext'
import { stringAvatar } from '../../../utils'


export const MemberForm = ({
    data = "",
    t = () => false,
    handleMemberFormSelect = () => false
}) => {
    const classes = ProjectStyle()
    const {
        addMember,
        updateAddMember
    } = React.useContext(ProjectContext)

    const manualResponseProjectMember = (array) => {
        const result = array?.data?.map((e) => {
            return {
                label: e?.resource_name,
                value: e?.id,
                follower: false,
                member: false,
                ...e
            }
        })
        return result
    }

    return (
        <>
            <Box p={2}>
                <MemberSelectBox data={data} selected={addMember?.selectedMember} t={t} customSelectedWidth={"300px"} onChange={handleMemberFormSelect} initial />
                <Box mt={"12px"}>
                    <SelectBox
                        value={addMember?.projectMember}
                        noSearch={false}
                        color={"#fff"}
                        placeholder={t("Search")}
                        customOptionComponent={(props) => {
                            return (
                                <Stack direction={"row"} spacing={1} alignItems={"center"} p={"8px"}>
                                    <Avatar className={classes.customOptionAvatar} {...stringAvatar(props?.data?.label)}></Avatar>
                                    <Stack>
                                        <Typography className={classes.optionHeader}>{props?.data?.label}</Typography>
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <Typography className={classes.optionSubTitle}>{props?.data?.reference_id}</Typography>
                                            <Box className={classes.dot}></Box>
                                            <Typography className={classes.optionSubTitle}>{props?.data?.profession_name}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )
                        }}
                        isPaginate={true}
                        isMulti
                        onChange={(value) => updateAddMember("projectMember", value)}
                        key={JSON.stringify(addMember?.selectedMember)}
                        loadOptions={(search, array, handleLoading) =>
                            loadOptionsApis(
                                "project_member/drop_down",
                                {
                                    "group_id": addMember?.selectedMember?.map((e) => { return e?.id }),
                                    "project_id": data?.id,
                                    "exclude_ids": []
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualResponseProjectMember
                            )
                        }
                    />
                    <>
                        {
                            addMember?.projectMember?.length === 0 ?
                                <Stack spacing={1} alignItems={"center"} mt={3}>
                                    <NoSelectionIcon />
                                    <Typography className={classes.hrsLabel}>{t("No Member selected to display")}</Typography>
                                </Stack>
                                :
                                <Grid container padding={"4px 8px"}>
                                    <Grid item xs={6}><Typography className={classes.optionSubTitle} pt={"8px"}>{addMember?.projectMember?.length} {t("Members")}</Typography></Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.optionSubTitle} sx={{ textAlign: "center" }} pt={"8px"}>{t("Follower")}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.optionSubTitle} sx={{ textAlign: "center" }} pt={"8px"}>{t("Member")}</Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }} onClick={() => updateAddMember("projectMember", [])}>
                                        <CloseIconWithBG />
                                    </Grid>
                                </Grid>
                        }
                    </>
                    <>
                        {
                            addMember?.projectMember?.map((data, i) => {
                                return (
                                    <SelectedMemberCard data={data} index={i} />
                                )
                            })
                        }
                    </>
                </Box>
            </Box>
        </>
    )
}