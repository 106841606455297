import React from 'react'
import { Checkbox, Stack, Typography, Grow, MenuList, Paper, Popper, ClickAwayListener } from "@mui/material"
import { MemberSelectBoxStyle } from './style'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TextBox, UseDebounce } from '../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AlertContext } from '../../../contexts';
import { AlertProps, NetWorkCallMethods } from '../../../utils';
import { NetworkCall } from '../../../networkcall';
import { config } from '../../../config';
import { ProjectContext } from '../projectContext';
import ScrollContainer from 'react-indiana-drag-scroll';

export const MemberSelectBox = ({
    data = "",
    customWidth = "auto",
    selected = [],
    onChange = () => false,
    initial = false,
    t = () => false,
    customSelectedWidth = "auto"
}) => {
    const anchorRef = React.useRef()
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const classes = MemberSelectBoxStyle({ anchorRef })
    const [open, setOpen] = React.useState(false)
    const [offset, setOffset] = React.useState(0);
    const [menuList, setMenuList] = React.useState([])
    const [search, setSearch] = React.useState("")

    const {
        updateAddMember,
        addMember
    } = React.useContext(ProjectContext)

    const handleOnClose = () => {
        setOpen(false)
    }

    const fetchMoreData = () => {
        setOffset(offset + 10);
        getMemberList(offset + 10, "", true);
    };


    const getMemberList = (offset, search, bool) => {
        const payload = {
            project_id: data?.id,
            search: search,
            offset: offset,
            limit: 10,
        }
        NetworkCall(
            `${config.api_url}/project/resource_group/drop_down`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            // console.log("res?.data?.data?.data", res?.data?.data?.data)
            if (bool) {
                // console.log("[...menuList, ...res?.data?.data?.data]",[...menuList, ...res?.data?.data?.data])
                setMenuList([...menuList, ...res?.data?.data?.data])
            } else {
                setMenuList(
                    [
                        {
                            name: t("All Resource Group"),
                            id: null
                        },
                        ...res?.data?.data?.data
                    ]
                )
                // setSelectedMember(res?.data?.data?.data?.[0])
            }
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went Wrong"),
            });
            console.log("err", err)
        })
    }

    const handleOpen = (bool) => {
        setOpen(bool)
        getMemberList(0, "", false)
    }

    const handleSearch = (search) => {
        setSearch(search)
        debounce(() => getMemberList(0, search, false), 800)
    }

    return (
        <>
            <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
                className={classes.box}
                onClick={() => handleOpen(!open)}
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                ref={anchorRef}
            >
                <Stack direction="row" alignItems={"center"} spacing={"4px"}>
                    <Typography className={classes.menuSubText}>{t("Members")}:</Typography>
                    <ScrollContainer className="scroll-container">
                        <Stack sx={{ maxWidth: customSelectedWidth ?? "150px" }} direction={"row"} alignItems={"center"} columnGap={"4px"}>
                            {
                                selected?.map((e, i, len) => {
                                    return (
                                        <Typography className={classes.menuText} sx={{ whiteSpace: "nowrap" }}>{e?.name} {len?.length - 1 !== i && `,`}</Typography>
                                    )
                                })
                            }
                        </Stack>
                    </ScrollContainer>
                </Stack>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Stack>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                style={{
                    position: "relative",
                    zIndex: 9,
                    width: anchorRef?.current?.offsetWidth,
                    marginTop: "8px"
                }}
                disablePortal
                modifiers={{
                    offset: {
                        enabled: true,
                        offset: '0, 30'
                    }
                }}
            >
                {({ TransitionProps, placement }) => (
                    <>
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: '8px'
                            }}
                        >
                            <Paper sx={{
                                width: `${customWidth} !important`
                            }}>
                                <ClickAwayListener onClickAway={() => handleOnClose()}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        style={{ padding: "8px 8px" }}
                                    >
                                        <TextBox
                                            value={search}
                                            label={""}
                                            placeholder={"Search"}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                        <InfiniteScroll
                                            dataLength={menuList?.length ?? ""}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            height={200}
                                        >
                                            {
                                                menuList?.map((data) => {
                                                    return (
                                                        <Stack direction={"row"} alignItems={'center'} spacing={1}>
                                                            <Checkbox
                                                                checked={selected?.map((e) => { return e?.id })?.includes(data?.id)}
                                                                onChange={() => {
                                                                    setOpen(false)
                                                                    if (initial) {
                                                                        onChange(data)
                                                                    } else {
                                                                        updateAddMember("selectedMember", [...addMember?.selectedMember, data])
                                                                    }

                                                                }}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                            <Typography className={classes.menuText}>{data?.name}</Typography>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </InfiniteScroll>

                                    </MenuList>

                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    </>
                )}
            </Popper>
        </>
    )
}