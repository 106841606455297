
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

import { SelectBox } from "../../../components";


import CompanyLogoUpload from "../../../components/companyLogoUpload";
import { logoState } from "../../../utils/clientsettings";
// import { useStyles } from "../styles";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";

export const LogoUploadForm = ({ company = {}, reload = () => false, onClose = () => false, t = () => false }) => {

    const [data, setData] = React.useState({ ...logoState });
    // const classes = useStyles()
    const alert = React.useContext(AlertContext);

    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.logo?.length === 0 || !data?.logo?.src) {
            isValid = false;

            error.logo = t("Logo is Required");
        }
        setData({ ...data, error });
        return isValid;
    }
    React.useEffect(() => {
        setData({ ...data, logo: { src: company?.logo } })
        // eslint-disable-next-line
    }, [])
    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //on submit form
    const onSubmit = () => {

        if (validateForm()) {
            const payload = {
                id: company?.id,
                update: {
                    logo: data?.logo?.src
                },
            }
            NetworkCall(
                `${config.api_url}/queries/company_master/update`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,

                    msg: t("Logo Successfully Updated"),

                });
                reload(10, 0)
                onClose()
            }).catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,

                    msg: t("Some Thing Went Wrong"),

                });
            })


        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,

                msg: t("Please Fill All Mandatory Field"),
            });
        }
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Box p={2}>
                        <CompanyLogoUpload
                            label={t("Upload Image here")}

                            data={data?.logo}
                            handleChange={(value) => updateState("logo", value)}
                        />
                        {
                            data?.error?.logo?.length > 0 &&
                            <Typography variant={"caption"} color={"error"}>
                                {data?.error?.logo}
                            </Typography>
                        }

                        <Box height="10px" />
                        <SelectBox label={t("Company Name")}
                            isReadOnly
                            value={{ value: company?.id, label: company?.name }}
                        />
                        <Box height="34px" />
                        <Button variant="contained" fullWidth onClick={onSubmit}>

                            {t("Save")}

                        </Button>
                    </Box>
                </Grid>
                {/* <Grid item xs={6}>
                    <Box p={2}>
                        <Typography className={classes.labellogo}>WEB APP</Typography>
                        <Box className={classes.imagepreviewRoot}>
                            {
                                data?.logo?.src &&
                                <img className={classes.logo} src={data?.logo?.src} alt="" />
                            }
                            <img className={classes.previewImg} src="/images/Group 99604.svg" alt="" />
                        </Box>
                        <Box height="10px" />
                        <Typography className={classes.labellogo}>MOBILE APP</Typography>
                        <Box className={classes.imagepreviewRoot}>
                            {
                                data?.logo?.src &&
                                <img className={classes.logo1} src={data?.logo?.src} alt="" />
                            }
                            <img className={classes.previewImg} src="/images/Group 99609.svg" alt="" />
                        </Box>
                    </Box>
                </Grid> */}
            </Grid>
        </Box>
    )
}