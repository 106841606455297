import { Divider, Grid, InputBase, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import { LocalStorageKeys, Sample, toEnglishNumber, SemiBold } from "../../utils";
import { Label } from "../label";
import { SimpleSelect } from "./select";

const BootstrapInput = withStyles((props) => ({
  root: {
    width: "100%",
  },
  input: {
    fontSize:"0.875rem",
    position: "relative",
    width: "100%",
    marginInlineStart: "8px",
    fontFamily: SemiBold
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginBottom: 0,
  },
  wrapper: {
    border: `1.5px solid #E4E8EE`,
    borderRadius: 4,
    height: 48,

    alignItems: "center",
  },
}));

export const MobileNumberInputComponent = (props) => {
  const classes = useStyles(props);
  const localStorageData = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  const initialMobileCode = props?.value?.mobile_code ? props?.value?.mobile_code : (localStorageData?.data?.country?.dial_code ?? "+91")


  const [selectValue, setSelectValue] = React.useState({
    mobile: props?.value?.mobile ?? "",
    mobile_code: initialMobileCode,
  });

  // const initialLimit = getMobileLimitBasedOnCC(initialMobileCode)

  // const [limits, setLimits] = React.useState(initialLimit);
  // const [isvalid, setIsvalid] = React.useState(true);

  // const mobileNoValidation = (limitValue = limits) => {
  //   let inputLengthValue = selectValue?.mobile?.length;

  //   if (limitValue) {
  //     if (limitValue === inputLengthValue) {
  //       setIsvalid(true);
  //     } else if (limitValue > inputLengthValue) {
  //       setIsvalid(false);
  //     } else if (limitValue < inputLengthValue) {
  //       setIsvalid(false);
  //     } else {
  //       setIsvalid(true);
  //     }
  //   }
  // };

  const handleChangeSelect = (mobile_code = {}) => {
    let newState = {
      mobile: selectValue.mobile,
      mobile_code,
    };

    setSelectValue(newState);
    props?.handleChange(newState);

    // if (Object.keys(mobile_code)?.length > 0) {
    //   const limit = getMobileLimitBasedOnCC(mobile_code);
    //   setLimits(limit);
    // }
  };

  const handleChange = (mobile) => {
    // if (mobile?.length <= limits || props?.noValidate) {
      let newState = {
        mobile: mobile.replace(".", ""),
        mobile_code: selectValue.mobile_code,
      };

      setSelectValue(newState);
      props?.handleChange(newState);
    // }
  };

  // React.useEffect(() => {
  //   if (!props?.noValidate) {
  //     const limit = getMobileLimitBasedOnCC(selectValue?.mobile_code);
  //     mobileNoValidation(limit);
  //   }

  //   // eslint-disable-next-line
  // }, [selectValue]);

  React.useEffect(() => {
    if (props?.value) {
      setSelectValue({
        ...selectValue,
        mobile: props?.value?.mobile ?? "",
        mobile_code: props?.value?.mobile_code ?? "+91",
      });
    }
    // eslint-disable-next-line
  }, [props?.value]);

  // React.useEffect(() => {
  //   if (props?.onErrorOccured) {
  //     props?.onErrorOccured(!isvalid);
  //   }

  //   // eslint-disable-next-line
  // }, [isvalid]);

  const onChangeNumber = (value) => {
    if(value?.length <= 15){
      const number = toEnglishNumber(value)
      handleChange(number)
    }
  
  }



  return (
    <div className={classes.root} noValidate>
      {/* Input Label */}
      {props?.label && <Label label={props?.label} isRequired={props?.isRequired} />}
      <Grid container className={classes.wrapper}>
        <Grid item xs={4} sm={5} md={4} lg={2.5} xl={2.5}>
          <SimpleSelect
            className={classes.select}
            options={Sample}
            handleChangeSelect={handleChangeSelect}
            value={selectValue?.mobile_code ?? ""}
            disabled={props.isReadonly || props.disabled}
            errorValidation={props?.errorValidation}
          />
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
        <Grid item xs={8} sm={7} md={8} lg={9.5} xl={9}>
          <BootstrapInput
            // inputProps={{ maxLength: limits && limits }}
            // type={"number"}
            autoFocus={props.autoFocus ? true : false}
            disabled={(props?.isReadonly || props?.disabled) ?? false}
            onInvalid={(e) => {
              e.preventDefault();
            }}
            placeholder={props.placeholder}
            onChange={(e) => onChangeNumber(e.target.value)}
            value={selectValue?.mobile}
            // error={
            //   props?.errorValidation && props?.errorValidation?.error
            //     ? props?.errorValidation?.error
            //     : false
            // }
            onKeyPress={(e) => {
              if (e.key === "e") {
                e.preventDefault();
              }
            }}
            fontSize={props?.fontSize}
          />
        </Grid>
      </Grid>

      {/* Warning Message */}
      <Grid container direction={"row"}>
        {/* {isvalid === false &&
          limits !== null && !props?.noValidate &&
          selectValue?.mobile?.length > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="caption" color="error">
                {"Please enter " + limits + " digits"}

              </Typography>
            </Grid>
          )} */}

        {/* Error Message */}
        {
          props?.errorValidation?.error && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="caption" color="error">
                {props?.errorValidation?.errorMessage ?? ""}
              </Typography>
            </Grid>
          )
        }

        {/* Field required Message */}
        {
          props.isError && !Boolean(selectValue?.mobile?.length) && (
            <Typography variant={"caption"} color={"error"}>
              {props.errorMessage}
            </Typography>
          )
        }

        {
          props.error && (
            <Typography variant="caption" color="error">
              {props.helperText}
            </Typography>
          )
        }
      </Grid >

    </div >
  );
};

// Specifies the required props for the component:
MobileNumberInputComponent.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  // value: PropTypes.object,
  type: PropTypes.string,
  errorValidation: PropTypes.object,
  handleChange: PropTypes.func,
  onErrorOccured: PropTypes.func,
};
MobileNumberInputComponent.defaultProps = {
  handleChange: () => { },
};
