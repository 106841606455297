import React from "react";
import { withNavBars } from "../../HOCs";
import ResourceGroupMaster from "./resourceGroupMaster";


class ResourceGroupMasterParent extends React.Component {
    render() {
        return <ResourceGroupMaster/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ResourceGroupMasterParent, props);