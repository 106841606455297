import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { SubscriptionsContext } from "./subscriptionsContext";
import { ItemCard } from "./components/itemCard";
import ExploreMetaIcon from "./assets/explore_meta_icon.png";
import { DialogBox, Label, TextBox } from "../../components";
import { StatusOptions } from "./utils/constant";
import LanguageIcon from '@mui/icons-material/Language';
import { FileUploadCard } from "./components/fileUploadCard";
import { DeleteIcon } from "./components/itemCard/deleteIcon";

export const Configuration = () => {
    const {
        t,
        configurationClasses: classes,
        login_logo_ref,
        login_logo_config_state,
        web_background_ref,
        web_background_config_state,
        mobile_background_ref,
        mobile_background_config_state,
        web_welcome_ref,
        web_welcome_config_state,
        mobile_welcome_ref,
        mobile_welcome_config_state,
        isExploreMetaFormOpen,
        handleExploreMetaForm,
        exploreMetaFormState,
        updateExploreMetaForm,
        is_button_disable,
        appBuilds,
        isPromotionPageMetaFormOpen,
        handlePromotionPageMetaForm,
        promotionPageMetaFormState,
        updatePromotionPageMetaForm,
        promotionPageMetaState,
        file_upload_limit,
        allowed_file_format,
        promotion_page_upload_file_ref,
        handlePromotionPageChooseImage,
        handlePromotionPageUploadImage,
    } = useContext(SubscriptionsContext);

    return (
        <Box className={classes.tab_content_div}>
            <Box className={classes.tab_content_inner_div}>
                <Grid container direction={"row"} spacing={"16px"}
                    justifyContent={"start"}>
                    <ItemCard
                        state={login_logo_config_state}
                        upload_file_ref={login_logo_ref} />
                    <ItemCard
                        state={web_background_config_state}
                        upload_file_ref={web_background_ref} />
                    <ItemCard
                        state={mobile_background_config_state}
                        upload_file_ref={mobile_background_ref} />
                    <ItemCard
                        state={web_welcome_config_state}
                        upload_file_ref={web_welcome_ref} />
                    <ItemCard
                        state={mobile_welcome_config_state}
                        upload_file_ref={mobile_welcome_ref} />
                    <ItemCard
                        type="trigger_card"
                        state={{
                            updated_image: ExploreMetaIcon,
                            label: t("Explore Link Details"),
                        }}
                        handleForm={handleExploreMetaForm}
                    />
                    {appBuilds?.map?.((_) => {
                        const mobile_background = promotionPageMetaState?.find?.(pp => pp?.app_build?.build_code === _?.build_code)?.mobile_background;
                        return <ItemCard
                            type="trigger_card"
                            state={{
                                updated_image: mobile_background,
                                label: `${t("Promotional Page")} - ${_?.build_name}`,
                            }}
                            appBuild={_}
                            handleForm={handlePromotionPageMetaForm}
                        />
                    })}
                </Grid>
            </Box>
            <DialogBox
                maxWidth={'sm'}
                open={isExploreMetaFormOpen}
                onClose={() => handleExploreMetaForm({ type: "close" })}
                handleClose={() => handleExploreMetaForm({ type: "close" })}
                header={t("Update Explore Link Details")}
                component={
                    <Stack className={classes.exploreMetaForm}
                        direction={"column"} rowGap={"16px"} p={"24px"}>
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("Information")}
                            placeholder={t("Enter Information")}
                            multiline
                            rowheight={2}
                            color="white"
                            value={exploreMetaFormState?.information}
                            onChange={(e) =>
                                updateExploreMetaForm({ key: "information", value: e?.target?.value })}
                            isError={exploreMetaFormState?.error?.information?.length > 0}
                            errorMessage={exploreMetaFormState?.error?.information} />
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("CTA Text")}
                            placeholder={t("Enter CTA Text")}
                            color="white"
                            value={exploreMetaFormState?.cta_text}
                            onChange={(e) =>
                                updateExploreMetaForm({ key: "cta_text", value: e?.target?.value })}
                            isError={exploreMetaFormState?.error?.cta_text?.length > 0}
                            errorMessage={exploreMetaFormState?.error?.cta_text} />
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("Link")}
                            placeholder={t("Enter Link")}
                            color="white"
                            endAdornment={<Box sx={{
                                display: "flex", paddingInlineStart: "4px",
                                justifyContent: "center", alignItems: "center",
                                borderInlineStart: "1px solid #98a0ac",
                            }}>
                                <LanguageIcon htmlColor="#98a0ac" fontSize="small" />
                            </Box>}
                            value={exploreMetaFormState?.link}
                            onChange={(e) =>
                                updateExploreMetaForm({ key: "link", value: e?.target?.value })}
                            isError={exploreMetaFormState?.error?.link?.length > 0}
                            errorMessage={exploreMetaFormState?.error?.link} />
                        <Stack direction={"column"} rowGap={"8px"}>
                            <Label label={t("Status")} isRequired labelMarginBottom={"0px"} />
                            <Stack direction={"row"} columnGap={"8px"}>
                                {StatusOptions({ t }).map((_) => {
                                    return <Button className={_?.value === exploreMetaFormState?.is_active ?
                                        classes.selectedButton :
                                        classes.unSelectedButton}
                                        disabled={is_button_disable}
                                        onClick={() => updateExploreMetaForm({ key: "is_active", value: _?.value })}>
                                        {_?.label}
                                    </Button>
                                })}
                            </Stack>
                        </Stack>
                        <Box height={"8px"} />
                        <Stack direction={"row"} columnGap={"16px"}>
                            <Button className={classes.exploreMetaFormCancelButton}
                                disabled={is_button_disable}
                                onClick={() => handleExploreMetaForm({ type: "close" })}
                                fullWidth>{t("Cancel")}</Button>
                            <Button className={classes.exploreMetaFormSaveButton}
                                disabled={is_button_disable}
                                onClick={() => handleExploreMetaForm({ type: "save", app_build: exploreMetaFormState?.app_build })}
                                fullWidth>{t("Save")}</Button>
                        </Stack>
                    </Stack>
                } />
            <DialogBox
                maxWidth={'sm'}
                open={isPromotionPageMetaFormOpen}
                onClose={() => handlePromotionPageMetaForm({ type: "close" })}
                handleClose={() => handlePromotionPageMetaForm({ type: "close" })}
                header={`${t("Update Promotion Page Details")} - ${promotionPageMetaFormState?.app_build?.build_name}`}
                component={
                    <Stack className={classes.exploreMetaForm}
                        direction={"column"} rowGap={"16px"} p={"24px"}>
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("Description")}
                            placeholder={t("Enter Description")}
                            multiline
                            rowheight={2}
                            maxLength={200}
                            color="white"
                            value={promotionPageMetaFormState?.description}
                            onChange={(e) =>
                                updatePromotionPageMetaForm({ key: "description", value: e?.target?.value })}
                            isError={promotionPageMetaFormState?.error?.description?.length > 0}
                            errorMessage={promotionPageMetaFormState?.error?.description} />
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("CTA Text")}
                            placeholder={t("Enter CTA Text")}
                            maxLength={25}
                            color="white"
                            value={promotionPageMetaFormState?.cta_text}
                            onChange={(e) =>
                                updatePromotionPageMetaForm({ key: "cta_text", value: e?.target?.value })}
                            isError={promotionPageMetaFormState?.error?.cta_text?.length > 0}
                            errorMessage={promotionPageMetaFormState?.error?.cta_text} />
                        {promotionPageMetaFormState?.mobile_background
                            ? <Stack rowGap={"8px"}>
                                <Label isRequired label={t("Mobile Background")} />
                                <Stack direction={"row"} columnGap={"4px"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack direction={"row"} columnGap={"8px"}
                                        justifyContent={"space-between"} alignItems={"center"}>
                                        <Box className={classes.selected_image_div}>
                                            <img className={classes.selected_image}
                                                src={promotionPageMetaFormState?.mobile_background} alt="mobile_background" />
                                        </Box>
                                        <Typography className={classes.item_card_label}>
                                            {promotionPageMetaFormState?.mobile_background?.split('/')?.reverse()?.[0]}
                                        </Typography>
                                    </Stack>
                                    <Box className={classes.delete_button}
                                        onClick={() =>
                                            !is_button_disable && updatePromotionPageMetaForm({ key: "mobile_background", value: "" })}>
                                        <DeleteIcon />
                                    </Box>
                                </Stack>
                            </Stack>
                            : <FileUploadCard
                                t={t}
                                label={t("Mobile Background")}
                                file_upload_limit={file_upload_limit}
                                allowed_file_format={[...allowed_file_format, ".gif"]}
                                upload_file_ref={promotion_page_upload_file_ref}
                                handleChooseImage={() => handlePromotionPageChooseImage()}
                                handleUploadImage={(file) => handlePromotionPageUploadImage(file)}
                                errorMessage={promotionPageMetaFormState?.error?.mobile_background} />}
                        <Stack direction={"column"} rowGap={"8px"}>
                            <Label label={t("Status")} isRequired labelMarginBottom={"0px"} />
                            <Stack direction={"row"} columnGap={"8px"}>
                                {StatusOptions({ t }).map((_) => {
                                    return <Button className={_?.value === promotionPageMetaFormState?.is_active ?
                                        classes.selectedButton :
                                        classes.unSelectedButton}
                                        disabled={is_button_disable}
                                        onClick={() => updatePromotionPageMetaForm({ key: "is_active", value: _?.value })}>
                                        {_?.label}
                                    </Button>
                                })}
                            </Stack>
                        </Stack>
                        <Box height={"8px"} />
                        <Stack direction={"row"} columnGap={"16px"}>
                            <Button className={classes.exploreMetaFormCancelButton}
                                disabled={is_button_disable}
                                onClick={() => handlePromotionPageMetaForm({ type: "close" })}
                                fullWidth>{t("Cancel")}</Button>
                            <Button className={classes.exploreMetaFormSaveButton}
                                disabled={is_button_disable}
                                onClick={() => handlePromotionPageMetaForm({ type: "save", app_build: promotionPageMetaFormState?.app_build })}
                                fullWidth>{t("Save")}</Button>
                        </Stack>
                    </Stack>
                } />
        </Box>
    )
}