import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { paymentDashboardStyle } from "../style";

const ListCard = ({ list,currency }) => {
  const classes = paymentDashboardStyle();
  return (
    <Box className={classes.listBox}>
      <Grid container spacing={2}>
        {list?.map((v, i) => (
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            gap="10px"
            alignItems={"center"}
          >
            {i !== 0 && (
              <Divider
                orientation="vertical"
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
              />
            )}
            <Stack>
              <Typography
                className={classes.titleFont}
                fontSize={"1.4rem !important"}
              >
                {v??0} &nbsp;{""}
                {i === 0 && <span style={{ color: "#98A0AC" }}>{currency}</span>}
              </Typography>
              <Typography
                className={classes.graphDataTitle}
                fontSize={"1rem !important"}
              >
                {i === 0
                  ? "Total Transaction Value"
                  : i === 1
                  ? "Total Transactions Count"
                  : i === 2
                  ? "Success Transactions Count"
                  : "Failure Transactions Count"}{" "}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ListCard;
