import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './style'
import { Label } from '../label';
import ResidentialInActiveIcon from '../../screens/residentOnboarding/utils/residentialInActiveIcon'
import ResidentialActiveIcon from '../../screens/residentOnboarding/utils/residentialActiveIcon'
import CommercialActiveIcon from '../../screens/residentOnboarding/utils/commercialActiveIcon'
import CommercialInActiveIcon from '../../screens/residentOnboarding/utils/commercialInActiveIcon'

export const PurposeSelect = ({
    options = [],
    value = "",
    label = "",
    isrequired = false,
    onChange=()=>false,
    isError=false,
    errorMessage=""
}) => {
    const classes = useStyles()

    const images = {
        "Residential": <ResidentialInActiveIcon/>,
        "Commercial":<CommercialInActiveIcon/>,
        "Mixed": <CommercialInActiveIcon/>,
        
    }

    const activeImages = {
        "Residential": <ResidentialActiveIcon/>,
        "Commercial":<CommercialActiveIcon/>,
        "Mixed": <CommercialActiveIcon/>,


    }
    return (
        <Box>
            {label && <Label label={label} isRequired={isrequired} />}

            <Grid container spacing={1}>
                {
                    options?.map((x) => {
                        return (
                            <Grid item xs={4} sm={2.5}>
                                <Box className={classes.root} style={{ border: value === x?.value ? "1px solid #5078E1" : "1px solid #E4E8EE", backgroundColor: value === x?.value ? "#F1F7FF" : "#fff" }} onClick={()=>onChange(x)}>
                                    {value === x?.value ? activeImages[x?.value]:images[x?.value]}
                                    <Typography className={classes.label} style={{color:value === x?.value ? "#5078E1" : "#091B29"}}>{x?.label}</Typography>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
            {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
        </Box>
    )
}