import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import { ExtraBold } from "../../../utils";

Highcharts3D(Highcharts);

export const VariablePieChart = ({ data = [], height = 300, graphTitle }) => {
  const chartRef = useRef(null);
  const highchartRef = useRef(null);
  const chartColors = [
    "#278ECF",
    "#48D762",
    "#FFCA1F",
    "#FF9416",
    "#D42AE8",
    "#535AD7",
    "#FF402C",
    "#83BFFF",
    "#6ED88F",
    "#FFE366",
  ];

  useEffect(() => {
    const options = {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 60, // increase the tilt
          beta: 0,
          depth: 50, // increase the thickness
        },
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true, 
      },
      title: {
        text: graphTitle,
        align: "left",
        style: {
          color: "#091B29",
          fontSize: "1rem",
          fontFamily: ExtraBold,
          marginInlineStart: "8px",
        },
      },
      colors: chartColors,
      tooltip: {
        useHTML: true,
        formatter: function () {
          let number = this.y;
          let metric = '';
          if (number >= 1.0e+12) {
            number = number / 1.0e+12;
            metric = 'Tn';
          } else if (number >= 1.0e+9) {
            number = number / 1.0e+9;
            metric = 'Bn';
          } else if (number >= 1.0e+6) {
            number = number / 1.0e+6;
            metric = 'Mn';
          } else if (number >= 1.0e+3) {
            number = number / 1.0e+3;
            metric = 'k';
          }
          return '<b>' + this.point.name + '</b>: ' + number + metric;
        }
      },
      plotOptions: {
        pie: {
          innerSize: "50%",
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
        },
      },
      series: [
        {
          name: "",
          colorByPoint: true,
          data: data.map((item) => ({ name: item.name, y: parseInt(item?.count) })),
        },
      ],
    };

    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, options);
    } else {
      highchartRef.current.update(options);
    }

    const handleFullscreenChange = () => {
      if (highchartRef.current) {
        highchartRef.current.reflow();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (highchartRef.current) {
        highchartRef.current = null;
      }
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, graphTitle]);

  return <div ref={chartRef} style={{ width: "100%", height: height }} />;
};
