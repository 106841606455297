export const PropertyFinderPath = [
  "id",
  "image",
  "property_name",
  "units",
  "location",
];

export const PropertyFinderHeading = [
  { title: "S.No", field: "id", type: "numeric" },
  { title: "Image", field: "image" },
  { title: "Property Name", field: "property_name" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];
export const PropertyFinderType = [
  { type: ["increment"], name: "id" },
  { type: ["PFImage"], name: "image" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "units" },
  { type: ["text"], name: "location" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const Propertypath = [
  "propertyName",
  "propertyNo",
  "loction",
  "blocks",
  "floors",
  "units",
  "propertyPurpose",
  "propertyHierarchy",
  "active",
  "icon",
];

export const SignatureReportrow = [
  {
    sno: "",
    propertyName: "Rubix Appartment",
    location: "T.Nagar,Chennai",
    blocks: "8 ",
    floors: "96",
    units: "768",
    propertyType: "Appartment",
    icon: "edit",
  },
];
export const Propertyheading = (t) => [
  { title: "", field: "icon" },
  { title: t("Property ID"), field: "propertyNo" },
  { title: t("Name"), field: "propertyName" },
  { title: t("Location"), field: "loction" },
  { title: t("Purpose"), field: "propertyPurpose" },
  { title: t("Hierarchy"), field: "propertyHierarchy" },
];

export const PricingtablePropertypath = [
  "propertyNo",
  "propertyName",
  "companyName",
  "location",
  "units",
  "propertyType",
  "status",
];

export const PricingTablePropertyheading = (t) => [
  { title: t("Property No"), field: "propertyNo" },
  { title: t("Property Name"), field: "propertyName" },
  { title: t("Company Name"), field: "companyName" },
  { title: t("Location"), field: "location" },
  { title: t("Units"), field: "units" },
  { title: t("Property Type"), field: "propertyType" },
  { title: t("Status"), field: "status" },
];

export const Signaturepath = ["sno", "propertyName", "units", "location"];

export const Signaturerow = [
  {
    sno: "",
    propertyName: "Rubix Appartment",
    units: "768",
    location: "T.Nagar,Chennai",
    icon: "arrow",
  },
];
export const Signatureheading = [
  { title: "S.NO", field: "sno", type: "numeric" },
  { title: "Property Name", field: "date" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];

export const Blockpath = [
  "sno",
  "propertyName",
  "units",
  "leasableArea",
  "totalArea",
  "contact",
];

export const Blockrow = [
  {
    propertyName: "Rubix Appartment",
    units: "768",
    location: "T.Nagar,Chennai",
    icon: "arrow",
  },
  {
    sno: "",
    propertyName: "Rubix Appartment",
    units: "2400",
    LeasableArea: "10000 Sq.Ft",
    TotalArea: "165480 Sq.Ft",
    Contact: "+91-9900990099",
    BusinessType: "Residential",
  },
  {
    sno: "",
    propertyName: "Rubix Appartment",
    units: "2400",
    LeasableArea: "10000 Sq.Ft",
    TotalArea: "165480 Sq.Ft",
    Contact: "+91-9900990099",
    BusinessType: "Residential",
  },
];
export const Blockheading = [
  { title: "S.NO", field: "sno", type: "numeric" },
  { title: "Property Name", field: "propertyName" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];
export const Floorpath = [
  "sno",
  "propertyName",
  "units",
  "leasableArea",
  "totalArea",
  "contact",
];

export const Floorrow = [
  {
    propertyName: "Rubix Appartment",
    units: "768",
    location: "T.Nagar,Chennai",
    icon: "arrow",
  },
];
export const Floorheading = [
  { title: "S.NO", field: "sno", type: "numeric" },
  { title: "Property Name", field: "date" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];

export const Unitpath = [
  "unitNo",
  "unitName",
  "parentUnitName",
  "unitType",
  "carpetArea",
  "furnishType",
  "unitPurpose",
  "currentStatus",
];

export const Unitheading = [
  { title: "Unit NO.", field: "unitNo" },
  { title: "Unit Name", field: "unitName" },
  { title: "Parent Unit Name", field: "parentUnitName" },
  { title: "Unit Type", field: "unitType" },
  { title: "Carpet Area", field: "carpetArea" },
  { title: "Furnish Type", field: "furnishType" },
  { title: "Unit Purpose", field: "unitPurpose" },
  { title: "Current Status", field: "currentStatus" },
  { title: "", field: "icon" },
];

export const Leadpath = [
  "sno",
  "leadNo",
  "owner",
  "description",
  "purpose",
  "source",
  "activeQuote",
  "value",
  "date",
  "statusDate",
  "priority",
  "status",
  "contact_name",
  "contact_email",
  "company_name",
  "country",
  "contact_mobile_no",
  "source",
  // "leadId"
];

export const Leadrow = [
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
];
export const Leadheading = (t = () => false) => [
  { title: "", field: "icon" },
  { title: t("Opportunity ID"), field: "leadNo" },
  { title: t("Date"), field: "date" },
  { title: t("Description"), field: "description" },
  { title: t("PURPOSE"), field: "purpose" },
  { title: t("Type"), field: "opportunity_type" },
  { title: t("Quote ID"), field: "activeQuote" },
  { title: t("Revenue"), field: "value" },
  { title: t("Owner"), field: "owner" },
  { title: t("Contact Name"), field: "contact_name" },
  { title: t("Contact Email"), field: "contact_email" },
  { title: t("Company Name"), field: "company_name" },
  { title: t("Country"), field: "country" },
  { title: t("Contact Mobile number"), field: "contact_mobile_no" },
  { title: t("Source"), field: "source" },
  { title: t("Status"), field: "status" },
  { title: t("Status Date"), field: "statusDate" },
  { title: t("Closed On"), field: "dateOfClosed" },
  // { title: "Lead ID", field: "leadId" },
];

export const announsment = [
  { title: "S.No", field: "sno", type: "numeric" },
  { title: "Category", field: "Category" },
  { title: "Announcement Title", field: "Announcement_Title" },
  { title: "Assigned Property", field: "Assigned_Property" },
  { title: "Assigned Roles", field: "Assigned_Roles" },
  { title: "Created By", field: "Created_By" },
  { title: "", field: "icon" },
];
export const announsmentRow = [
  {
    numeric: "",
    Category: "Category Name here",
    Announcement_Title: "COVID Vaccination camp for senior citi….  ",
    Assigned_Property: "Rukon Al Salim Real Estate D....",
    Assigned_Roles: "4",
    Created_By: "22 nov 21",
    icon: "viewdelete",
  },
];

//Activity
export const ActivityPath = [
  "avatar",
  "action info",
  "category",
  "date",
  "createdBy",
  "priority",
  "status",
];

export const ActivityRow = [
  {
    img: "/images/phonecall.svg",
    name: "gokul",
    actionInfo: "2400 sq ft",
    date: "vishnu",
    createdBy: "22 nov 21",
    priority: "high",
    status: "open",
    icon: "editview",
  },
];
export const ActivityHeading = [
  { title: "Activity Name", field: "img" },
  { title: "Action Info", field: "action info" },
  // { title: "Purpose", field: 'purpose' },
  { title: "Date", field: "date" },
  { title: "Created By", field: "createdBy" },
  { title: "Priority", field: "priority" },
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];

//quotation
export const QuotationPath = [
  "quotationNo",
  "units",
  "value",
  "period",
  "quoteExpireOn",
  "status",
];

export const QuotationRow = [
  {
    quotationNo: "2344",
    units: "2400 sq ft",
    value: "$ 2,400",
    period: "high",
    quoteExpireOn: " 22 nov 21",
    status: "requested for visit",
  },
  {
    quotationNo: "2344",
    units: "2400 sq ft",
    value: "$ 2,400",
    period: "high",
    quoteExpireOn: " 22 nov 21",
    status: "Quote Accepted",
  },
  {
    quotationNo: "2344",
    units: "2400 sq ft",
    value: "$ 2,400",
    period: "high",
    quoteExpireOn: " 22 nov 21",
    status: "Quote Accepted",
  },
];
export const QuotationHeading = [
  { title: "Quotation  No", field: "quotationNo" },
  { title: "Units", field: "units" },
  { title: "Value", field: "value" },
  { title: "Period", field: "period" },
  { title: "Quote Expire On", field: "quoteExpireOn" },
  { title: "status", field: "status" },
];

//to-do
export const TodoPath = ["name", "description", "date", "priority", "status"];

export const TodoRow = [
  {
    checkBox: "checkbox",
    Name: "Gokul",
    description: "Fusce at nisi eget dolor rhoncus",
    icon: "editview",
  },
];
export const TodoHeading = [
  { title: "", field: "checkBox" },
  { title: "Name", field: "Name" },
  { title: "Description", field: "description" },
  { title: "", field: "icon" },
];
export const Managementheading = (t) => [
  { title: t("Image"), field: "Image" },
  { title: t("Property Name"), field: "PropertyName" },
  // { title: "Revenue Type", field: "revenueType" },
  { title: t("Location"), field: "location" },
  { title: t("Roles Assigned"), field: "Roles" },
];

export const Managementpath = ["Image", "PropertyName", "location", "Roles"];

export const UnitRentallHeading = [
  { title: "Property Num", field: "PropertyNum" },
  { title: "Property Name", field: "PropertyName" },
  { title: "Company Name", field: "CompanyName" },
  { title: "Location", field: "Location" },
  { title: "Units", field: "Units" },
  { title: "Property Type", field: "PropertyType" },
  { title: "Status", field: "Status" },
];

export const UnitRentallPath = [
  "PropertyNum",
  "PropertyName",
  "CompanyName",
  "Location",
  "Units",
  "PropertyType",
  "Status",
];

export const UnitRentallCreateHeading = [
  { title: "Unit Number", field: "UnitNumber" },
  { title: "Unit Name", field: "UnitName" },
  { title: "Unit Category", field: "UnitCategory" },
  { title: "Revenue Type", field: "RevenueType" },
  { title: "Unit Purpose", field: "UnitPurpose" },
];

export const UnitRentallCreatePath = [
  "UnitNumber",
  "UnitName",
  "UnitCategory",
  "RevenueType",
  "UnitPurpose",
];

export const Salesheading = [
  { title: "Image", field: "Image" },
  { title: "Name", field: "Name" },
  { title: "UCID of the profile", field: "UCID" },
  { title: "Email id", field: "email" },
  { title: "Phone number", field: "number" },
  { title: "Reporting to", field: "reporting" },
  { title: "", field: "icon" },
];

export const Salespath = [
  "Image",
  "Name",
  "UCID",
  "email",
  "number",
  "reporting",
];

export const LeadNewheading = (t) => [
  { title: t("Actions"), field: "icon" },
  { title: t("Lead ID"), field: "LeadNumber" },
  { title: t("Date"), field: "date" },
  { title: t("Description"), field: "description" },
  { title: t("Contact Name"), field: "LeadName" },
  { title: t("Contact Email"), field: "Email" },
  { title: t("Company Name"), field: "company" },
  { title: t("Country"), field: "country" },
  { title: t("Contact Mobile"), field: "MobileNumber" },
  { title: t("Purpose"), field: "purpose" },
  { title: t("Type"), field: "type" },
  { title: t("Internal Lead Source"), field: "internal_lead_source_name" },
  { title: t("Priority"), field: "urgent" },
  { title: t("Owner"), field: "LeadOwner" },
  { title: t("Status"), field: "Status" },
];

export const LeadNewpath = [
  "LeadNumber",
  "LeadName",
  "date",
  "description",
  "MobileNumber",
  "Email",
  "purpose",
  "type",
  "internal_lead_source_name",
  "urgent",
  "LeadOwner",
  "Status",
  "statusDate",
];
export const UtilitiesMasterPath = ["utilityName", "description", "status"];
export const UtilitiesMasterHeading = (t) => [
  { title: t("Utility Name"), field: "utilityName" },
  { title: t("Description"), field: "description" },
  { title: t("Status"), field: "status" },
  { title: "", field: "icon" },
];
export const Utilitypath = [
  "utilityName",
  "utilityProvider",
  "serialNumber",
  "price",
  "period",
  "previous_reading",
  "current_reading",
  "status",
];

export const Utilityheading = (t) => [
  { title: t("Utility Name"), field: "utilityName" },
  { title: t("Utility Provider"), field: "utilityProvider" },
  { title: t("Serial Number"), field: "serialNumber" },
  { title: t("Amount"), field: "price" },
  { title: t("Period"), field: "period" },
  { title: t("Previous Reading"), field: "previous_reading" },
  { title: t("Current Reading"), field: "current_reading" },
  { title: t("Status"), field: "status" },
  { title: "", field: "icon" },
];

export const AmenitiesMasterPath = [
  "amenityName",
  "amenityType",
  "amenityCategory",
  "booking",
  "is_active",
  "images",
];
export const AmenitiesMasterHeading = (t) => [
  { title: t("Amenity Name"), field: "amenityName" },
  { title: t("Amenity Type"), field: "amenityType" },
  { title: t("Amenity Category"), field: "amenityCategory" },
  { title: t("Booking"), field: "booking" },
  { title: t("Status"), field: "is_active" },
  { title: t("Images"), field: "images" },
  { title: "", field: "icon" },
];
export const AmenitiesPath = [
  "amenityId",
  "booking",
  "chargeable",
  "amenityName",
  "period",
  "amount",
  "description",
  "status",
];
export const AmenitiesHeadingUnit = (t) => [
  { title: t("Amenity Name"), field: "amenityName" },
  { title: t("Description"), field: "description" },
  { title: t("Chargeable"), field: "chargeable" },
  { title: t("Amount"), field: "amount" },
  { title: t("Period"), field: "period" },
  { title: t("Booking"), field: "booking" },
  { title: t("Status"), field: "status" },
  { title: "", field: "icon" },
];

export const SecurityDashBoardsPath = ["Image", "gate", "name"];
export const SecurityDashBoardsHeading = [
  { title: "Image", field: "Image" },
  { title: "Access Gate", field: "gate" },
  { title: "Person Names", field: "name" },
];

export const InvoicePath = [
  "invoiceNumber",
  "invoiceType",
  "agreementNumber",
  "accountID",
  "invoiceAmount",
  "generatedDate",
  "dueDate",
  "balance",
  "status",
  "submitted_on"
];
export const InvoiceHeading = (t, is_configured) => [
  { title: t("Invoice Number"), field: "invoiceNumber" },
  { title: t("Invoice Type"), field: "invoiceType" },
  { title: t("Agreement Number"), field: "agreementNumber" },
  { title: t("Account ID"), field: "accountID" },
  { title: t("Invoice Amount"), field: "invoiceAmount" },
  { title: t("Generated Date"), field: "generatedDate" },
  { title: t("Due Date"), field: "dueDate" },
  { title: t("Status"), field: "status" },
  { title: t("Balance"), field: "balance" },
  is_configured && { title: t("E Invoice"), field: "submitted_on" },
];
export const occupencyDashboradPath = [
  // "property_no",
  "name",
  "total_unit",
  "vacant_unit",
  "occupied_unit",
  "occupied_percentage",
];
export const occupencyDashboradHead = (t) => [
  // { title: "Property Number", field: "property_no" },
  { title: t("Property Name"), field: "name" },
  { title: t("Total Units"), field: "total_unit" },
  { title: t("Vacant"), field: "vacant_unit" },
  { title: t("Occupied Units"), field: "occupied_unit" },
  { title: t("Percentage"), field: "occupied_percentage" },
];
export const PurposePath = [
  // "property_no",
  "purpose",
  "carpetArea",
  "buildupArea",
  "superbuildupArea",
  "unit_count",
];
export const PurposeHead = (t) => [
  // { title: "Property Number", field: "property_no" },
  { title: t("Unit Type"), field: "purpose" },
  { title: t("Carpet Area"), field: "carpetArea" },
  { title: t("Buildup Area"), field: "buildupArea" },
  { title: t("Super Buildup Area"), field: "superbuildupArea" },
  { title: t("Units"), field: "unit_count" },
];

export const teamPerformPath = ["Image", "rep", "opportunities", "won", "lost", "Amount", "Target"];
export const teamPerformHead = (t) => [
  { title: t("Image"), field: "Image" },
  { title: t("Name"), field: "rep" },
  { title: t("Opportunities"), field: "opportunities" },
  { title: t("Won"), field: "won" },
  { title: t("Lost"), field: "lost" },
  { title: t("Won Revenue"), field: "Amount" },
];
export const teamPath = ["name", "opportunities", "won", "lost", "Amount", "Target"];
export const teamHead = (t) => [
  { title: t("Name"), field: "name" },
  { title: t("Opportunities"), field: "opportunities" },
  { title: t("Won"), field: "won" },
  { title: t("Lost"), field: "lost" },
  { title: t("Won Revenue"), field: "Amount" },
];


export const openLeadPath = ["Lead", "Email", "Phone", "Company", 'Created At'];
export const openLeadHead = (t) => [
  { title: t("Lead"), field: "Lead" },
  { title: t("Email"), field: "Email" },
  { title: t("Phone"), field: "Phone" },
  { title: t("Company"), field: "Company" },
  { title: t("Created At"), field: "Created At" },
];

export const myTaskPath = ["to-do", "Lead", "Email", "Phone", "Company"];
export const myTaskHead = (t) => [
  { title: t("To Do"), field: "to-do" },
  { title: t("Lead"), field: "Lead" },
  { title: t("Email"), field: "Email" },
  { title: t("Phone"), field: "Phone" },
  { title: t("Company"), field: "Company" },
];

export const top10PropertyPath = ["name", "amount"];
export const top10PropertyHead = [
  { title: "Property Name", field: "name" },
  { title: "Amount", field: "amount" },
];

export const top10AgreeMentPath = ["Number", "amount"];
export const top10AgreeMentHead = [
  { title: "Agreement Number", field: "Number" },
  { title: "Amount", field: "amount" },
];

export const top10AccountPath = ["number", "name", "amount"];
export const top10AccountHead = [
  { title: "Account Number", field: "number" },
  { title: "Name", field: "name" },
  { title: "Amount", field: "amount" },
];

export const gateChangePath = ["Image", "Name", "Number"];
export const gateChangetHead = [
  { title: "Image", field: "Image" },
  { title: "Name", field: "Name" },
  { title: "Contact Number", field: "Number" },
];

export const staffServicePath = ["Image", "Name", "Number", "Type", "App"];
export const staffServiceHead = [
  { title: "Image", field: "Image" },
  { title: "Name", field: "Name" },
  { title: "Contact Number", field: "Number" },
  { title: "Employee Type", field: "Type" },
  // { title: "App", field: "App" },
];
export const DeliveryMilestoneMasterHeading = (t) => [
  { title: t("Delivery Milestone Name"), field: "name", },
  { title: t("Description"), field: "description" },
  { title: t("Status"), field: "status" },
  { title: "", field: "icon" },
];
export const DeliveryMilestoneMasterPath = ["name", "description", "status"];
export const DeliveryMilestoneTemplateHeading = (t) => [
  { title: t("Delivery Milestone Name"), field: "name" },
  { title: t("Template Based On"), field: "templateBasedOn" },
  { title: t("Created By"), field: "createdBy" },
  { title: t("Updated On"), field: "updatedOn" },
  { title: t("Status"), field: "status" },
  { title: "", field: "icon" },
];
export const DeliveryMilestoneTemplatePath = [
  "name",
  "templateBasedOn",
  "createdBy",
  "updatedOn",
  "status",
];

// INSPECTION START

export const InspectionListHeading = (t) =>
  [
    { title: t("jobId"), field: "reference_id" },
    { title: t("category"), field: "type" },
    { title: t("unitNumber"), field: "unit_reference_id" },
    { title: t("inspector_name"), field: "assigned_to" },
    { title: t("projected"), field: "hours" },
    { title: t("actual"), field: "actual_hours" },
    { title: t("others"), field: "other_hours" },
    { title: t("assignedBy"), field: "assigned_by" },
    { title: t("executionDate"), field: "execution" },
    { title: t("completedOn"), field: "completed_on" },
    { title: t("KPI"), field: "KPI" },
    { title: t("job_status"), field: "status" },
  ]

export const InspectionListDataType = [
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "type" },
  { type: ["text"], name: "unit_reference_id" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["text"], name: "assigned_by" },
  { type: ["date"], name: "execution" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "KPI" },
  { type: ["inspection_status"], name: "status" },
]
export const InspectionListPath = [
  "reference_id",
  "type",
  "unit_reference_id",
  "created_at",
  "assigned_to",
  // "execution",
  "hours",
  "actual_hours",
  "other_hours",
  "assigned_by",
  "execution",
  "completed_on",
  "KPI",
  "status",
  "info",
];

export const InspectionMappingHeading = (t) => [
  { title: t("Image"), field: "image" },
  { title: t("Property Name"), field: "propertyName" },
  { title: t("Property No"), field: "property_no" },
  { title: t("Total Units"), field: "total_units" },
];
export const InspectionMappingPath = [
  "image",
  "propertyName",
  "property_no",
  "total_units",
  "icon",
];

export const InspectionMappingDataType = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "propertyName" },
  { type: ["text"], name: "property_no" },
  { type: ["text"], name: "total_units" },
];
export const InspectionUnitHeading = (t) => [
  { title: t("Unit Number"), field: "unitNumber" },
  { title: t("Unit Name"), field: "unitName" },
  { title: t("Unit Category"), field: "unitCategory" },
  { title: t("Revenue Type"), field: "revenueType" },
  { title: t("Unit Purpose"), field: "unitPurpose" },
];
export const InspectionUnitPath = [
  "unitNumber",
  "unitName",
  "unitCategory",
  "revenueType",
  "unitPurpose",
];

export const InspectionUnitDataType = [
  { type: ["text"], name: "unitNumber", width: "250px" },
  { type: ["text"], name: "unitName", width: "250px" },
  { type: ["text"], name: "unitCategory", width: "250px" },
  { type: ["text"], name: "revenueType", width: "250px" },
  { type: ["text"], name: "unitPurpose", width: "250px" },
];
export const InspectionUnitItemHeading = (t) => [
  { title: t("Item Id"), field: "reference_no" },
  { title: t("Item Type"), field: "item" },
  { title: t("Item Name"), field: "name" },
  // { title: "Product Id", field: "product_id" },
  { title: t("Model Number"), field: "model_number" },
  { title: t("Manufacturer Name"), field: "manufaturer_name" },
  { title: t("Serial Number"), field: "serial_number" },
  { title: t("Item Condition"), field: "item_condition" },
  { title: t("Status"), field: "is_active" },
  { title: "", field: "icon" },
];
export const InspectionUnitItemPath = [
  "reference_no",
  "item",
  "name",
  // "product_id",
  "model_number",
  "manufaturer_name",
  "serial_number",
  "item_condition",
  "is_active",
  "icon",
];
export const InspectionUnitItemRow = [
  {
    inspectionName: "Inspection Name Here",
    productId: "PDI-12345",
    status: "Active",
  },
];
export const InspectionUnitItemDataType = [
  { type: ["text"], name: "reference_no" },
  { type: ["text"], name: "item" },
  { type: ["assetName"], name: "name" },
  // { type: ["text"], name: "product_id" },
  { type: ["text"], name: "model_number" },
  { type: ["text"], name: "manufaturer_name" },
  { type: ["text"], name: "serial_number" },
  { type: ["text"], name: "item_condition" },
  { type: ["status"], name: "is_active" },
  { type: ["view_more"], name: "icon" },
];


export const InspectionItemsData = [
  {
    GeneralItems: [
      {
        id: 1,
        Name: "Inspection Name Here",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
      },
      {
        id: 2,
        Name: "Inspection Name Here1",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
      },
    ],
    TemplateItems: [
      {
        id: 3,
        Name: "Inspection Name Here",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [
          {
            id: 11,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 12,
            image: "",
            Name: "Template Name Here",
            count: "4",
          },
          {
            id: 13,
            image: "",
            Name: "Template Name Here",
            count: "3",
          },
        ],
      },
      {
        id: 4,
        Name: "Inspection Name Here1",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [
          {
            id: 21,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 22,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 23,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 24,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
        ],
      },
    ],
  },
];
export const CartData = [
  {
    GeneralItems: [
      {
        title: "General Items",
        Items: [
          {
            id: 1,
            Name: "Inspection Name Here",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
          },
          {
            id: 2,
            Name: "Inspection Name Here1",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
          },
        ],
      },
    ],
    TemplateItems: [
      {
        title: "Template Items",
        Items: [
          {
            id: 3,
            Name: "Inspection Name Here",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
            sub_items: [
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "5",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "2",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
            ],
          },
          {
            id: 4,
            Name: "Inspection Name Here1",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
            sub_items: [
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "5",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "2",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
            ],
          },
        ],
      },
    ],
  },
];


export const PaymentScheduleHeading = [
  {
    title: "Schedule Number", field: "scheduleNumber"
  },
  {
    title: "Start Date", field: "start_date"
  },
  {
    title: "To Date", field: "to_date"
  },
  {
    title: "Payment Period", field: "payment_period"
  },
  {
    title: "Total Amount", field: "total_amount"
  },
  {
    title: "Due Date", field: "due_date"
  },
]

export const PaymentSchedulePath = [
  "scheduleNumber",
  "start_date",
  "to_date",
  "payment_period",
  "total_amount",
  "due_date"
];
export const KycListheading = (t) => [
  { title: `${t("contactName")}`, field: "Name" },
  { title: `${t("IDType")}`, field: "ID" },
  { title: `${t("NameAsPerID")}`, field: "Name_per_id" },
  { title: `${t("IDNumber")}`, field: "ID_Number" },
  { title: `${t("Mandatory")}`, field: "Verify" },
  { title: `${t("Vaild From")}`, field: "Valid_from" },
  { title: `${t("Vaild To")}`, field: "Valid_to" },
  { title: `${t("Verified")}`, field: "verified" },
  { title: `${t("source")}`, field: "source" }
];

export const KycListpath = [
  "Name",
  "ID",
  "Name_per_id",
  "ID_Number",
  "Verify",
  "Valid_from",
  "Valid_to",
  "verified",
  "source"
];

export const kycdataType = [
  { type: ["text"], name: "Name" },
  { type: ["text"], name: "ID" },
  { type: ["text"], name: "Name_per_id" },
  { type: ["text"], name: "ID_Number" },
  { type: ["text"], name: "Verify" },
  { type: ["date"], name: "Valid_from" },
  { type: ["date"], name: "Valid_to" },
  { type: ["text"], name: "verified" },
  { type: ["text"], name: "source" },
]

export const KycListheading_account = (t) => [
  { title: `${t("Account Number")}`, field: "account_no" },
  { title: `${t("Account Name")}`, field: "account_number" },
  { title: `${t("ID Title")}`, field: "ID" },
  { title: `${t("Name As Per ID")}`, field: "Name_per_id" },
  { title: `${t("ID Number")}`, field: "ID_Number" },
  { title: `${t("Mandatory Verify")}`, field: "Verify" },
  { title: `${t("Valid From")}`, field: "Valid_from" },
  { title: `${t("Valid To")}`, field: "Valid_to" },
  { title: `${t("Issuing Country")}`, field: "Issuing_Country" },
];

export const KycListpath_account = [
  "account_no",
  "account_number",
  "ID",
  "Name_per_id",
  "ID_Number",
  "Verify",
  "Valid_from",
  "Valid_to",
  "Issuing_Country"
];

export const kycdataType_account = [
  { type: ["text"], name: "account_no" },
  { type: ["text"], name: "account_number" },
  { type: ["text"], name: "ID" },
  { type: ["text"], name: "Name_per_id" },
  { type: ["text"], name: "ID_Number" },
  { type: ["text"], name: "Verify" },
  { type: ["date"], name: "Valid_from" },
  { type: ["date"], name: "Valid_to" },
  { type: ["text"], name: "Issuing_Country" },
]


export const pricingApprialsdataType = [
  { type: ["text"], name: "Appraisal_Reference_ID" },
  { type: ["text"], name: "Component_Type" },
  { type: ["text"], name: "Pricing_Component_Name" },
  { type: ["text"], name: "Revenue_Type" },
  { type: ["text"], name: "Component_Based_On" },
  { type: ["text"], name: "Previous_Value" },
  { type: ["text"], name: "Present_Value" },
  { type: ["status"], name: "Status" },
  { type: ["more_appraisal"], name: "icon" },
]

export const pricingApprialsHeader = (t) => [
  { title: `${t("Appraisal Reference ID")}`, field: "Appraisal_Reference_ID" },
  { title: `${t("Component Type")}`, field: "Component_Type" },
  { title: `${t("Pricing Component Name")}`, field: "Pricing_Component_Name" },
  { title: `${t("Revenue Type")}`, field: "Revenue_Type" },
  { title: `${t("Component Based On")}`, field: "Component_Based_On" },
  { title: `${t("Previous Value")}`, field: "Previous_Value" },
  { title: `${t("Present Value")}`, field: "Present_Value" },
  { title: `${t("Status")}`, field: "Status" },
  { title: ``, field: "" },
];

export const pricingApprialsPath = [
  "Appraisal_Reference_ID",
  "Component_Type",
  "Pricing_Component_Name",
  "Revenue_Type",
  "Component_Based_On",
  "Previous_Value",
  "Present_Value",
  "Status",
  "icon"
];

export const rentalIndexType = [
  { type: ["info"], name: "icon" },
  { type: ["text"], name: "PropertyName" },
  { type: ["text"], name: "Location" },
  { type: ["text"], name: "UnitNumber" },
  { type: ["text"], name: "UnitType" },
  { type: ["text"], name: "UnitPurpose" },
  { type: ["text"], name: "Revenuetype" },
  { type: ["text"], name: "Bedrooms" },
]

export const rentalIndexHeading = (t) => [
  { title: ``, field: "" },
  { title: `${t("PropertyName")}`, field: "PropertyName" },
  { title: `${t("Location")}`, field: "Location" },
  { title: `${t("UnitNumber")}`, field: "UnitNumber" },
  { title: `${t("UnitType")}`, field: "UnitType" },
  { title: `${t("UnitPurpose")}`, field: "UnitPurpose" },
  { title: `${t("Revenuetype")}`, field: "Revenuetype" },
  { title: `${t("Bedrooms")}`, field: "Bedrooms" },
  // { title: `${t("Status")}`, field: "Status" },
];

export const rentalIndexPath = [
  "PropertyName",
  "Location",
  "UnitNumber",
  "UnitType",
  "UnitPurpose",
  "Revenuetype",
  "Bedrooms"
];


export const reservationType = [
  { type: ["text"], name: "Reservation_ID" },
  { type: ["text"], name: "Reservation_Type" },
  { type: ["text"], name: "Quotation_ID" },
  { type: ["text"], name: "Purpose" },
  { type: ["text"], name: "No.Of_Units" },
  { type: ["text"], name: "Revenue" },
  { type: ["date"], name: "Reservation_Valid_Till" },
  { type: ["status"], name: "Status" },
]

export const reservationHeading = (t) => [
  { title: `${t("Reservation_ID")}`, field: "Reservation_ID" },
  { title: `${t("Reservation_Type")}`, field: "Reservation_Type" },
  { title: `${t("Quotation_ID")}`, field: "Quotation_ID" },
  { title: `${t("Purpose")}`, field: "Purpose" },
  { title: `${t("No.Of_Units")}`, field: "No.Of_Units" },
  { title: `${t("Revenue")}`, field: "Revenue" },
  { title: `${t("Reservation_Valid_Till")}`, field: "Reservation_Valid_Till" },
  { title: `${t("Status")}`, field: "Status" }
];

export const reservationPath = [
  "Reservation_ID",
  "Reservation_Type",
  "Quotation_ID",
  "Purpose",
  "No.Of_Units",
  "Revenue",
  "Reservation_Valid_Till",
  "Status"
];


// manage invoice
export const manageInvoiceType = [
  { type: ["icon"], icon: "icon" },
  { type: ["inputCheck"], name: "selected" },
  { type: ["text"], name: "Agreement_Number" },
  { type: ["text"], name: "Account_Number" },
  { type: ["text"], name: "Account_Name" },
  { type: ["text"], name: "Managed_Invoice_Number" },
  { type: ["description"], name: "Description" },
  { type: ["date"], name: "Posting_Date" },
  { type: ["text"], name: "Invoice_Type" },
  { type: ["text"], name: "Total_Amount" },
  { type: ["text"], name: "Payment_Method" },

]

export const manageInvoiceHeading = (t, selected = 0) => [
  { title: "", field: "icon" },
  { Checkbox: ["checkbox"], field: "invoice_id" },
  { title: `${t("Agreement_Number")}`, field: "Agreement_Number" },
  { title: `${t("Account_Number")}`, field: "Account_Number" },
  { title: `${t("Account_Name")}`, field: "Account_Name" },
  { title: `${t("Managed_Invoice_Number")}`, field: "Managed_Invoice_Number" },
  { title: `${t("Description")}`, field: "Description" },
  { title: `${t("Posting_Date")}`, field: "Posting_Date" },
  { title: `${t("Invoice_Type")}`, field: "Invoice_Type" },
  { title: `${t("Total_Amount")}`, field: "Total_Amount" },
  { title: `${t("Payment_Method")}`, field: "Payment_Method" },

];

export const manageInvoicePath = [
  "selected",
  "Agreement_Number",
  "Account_Number",
  "Account_Name",
  "Managed_Invoice_Number",
  "Description",
  "Posting_Date",
  "Invoice_Type",
  "Total_Amount",
  "Payment_Method"
];

// manage invoice preview
export const manageInvoicePreviewType = [
  { type: ["text"], name: "Managed_Invoice_Number" },
  { type: ["text"], name: "Account_Number" },
  { type: ["text"], name: "Total_Amount" },

]

export const manageInvoicePreviewHeading = (t, selected = 0) => [
  { title: `${t("Agreement_Number")}`, field: "Managed_Invoice_Number" },
  { title: `${t("Account_Number")}`, field: "Account_Number" },
  { title: `${t("Total_Amount")}`, field: "Total_Amount" },
];

export const manageInvoicePreviewPath = [
  "Managed_Invoice_Number",
  "Account_Number",
  "Total_Amount"
];

// announsment
export const Announcement_Type = (icon, type) => [

  { type: ["text"], name: "category" },
  { type: ["description"], name: "announcement_title" },
  { type: ["text"], name: "properties" },
  { type: ["text"], name: "apps" },
  { type: ["text"], name: "attachments" },
  { type: ["text"], name: type === "publish" ? "published_by" : "created_by" },
  { type: ["text"], name: type === "publish" ? "published_date" : "created_date" },
  { type: ["status"], name: "status" },
  { type: [icon ? "more_5" : "view_more"], name: "icon" },
]

export const Announcement_Heading = (t, type) => [

  { title: `${t("Category")}`, field: "category" },
  { title: `${t("Announcement Title")}`, field: "announcement_title" },
  { title: `${t("Properties")}`, field: "properties" },
  { title: `${t("Apps")}`, field: "apps" },
  { title: `${t("Attachments")}`, field: "attachments" },
  {
    title: `${type === "publish" ? t("Published By") : t("Created By")}`,
    field: type === "publish" ? "published_by" : "created_by"
  },
  {
    title: `${type === "publish" ? t("Published Date") : t("Created Date")}`,
    field: type === "publish" ? "published_date" : "created_date"
  },
  { title: t("Status"), field: "status" },
  { title: ``, field: "" },
];

export const Announcement_Path = (type) => [
  "category",
  "announcement_title",
  "properties",
  "apps",
  "attachments",
  "status",
  [...type === "publish" ?
    ["published_by", "published_date"] :
    ["created_by", "created_date"]]

];

export const domesticContactListHeading = (t) => [
  { title: `${t("postedBy")}`, field: "postedBy" },
  { title: `${t("contactName")}`, field: "name" },
  { title: `${t("contactNumber")}`, field: "contact_number" },
  { title: `${t("Unit Name")}`, field: "unit_name" },
  { title: `${t("unitNo")}`, field: "unitNo" },
  { title: `${t("profession")}`, field: "profession" },
];
export const domesticContactListPath = [
  "postedBy",
  "name",
  "contact_number",
  "unit_name",
  "unitNo",
  "profession",
];
export const domesticContactListDataType = [
  { type: ["text"], name: "postedBy" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "contact_number" },
  { type: ["text"], name: "unit_name" },
  { type: ["text"], name: "unitNo" },
  { type: ["text"], name: "profession" },
]

export const reportType = [
  { type: ["text"], name: "property_id" },
  { type: ["text"], name: "Property_Name" },
  { type: ["text"], name: "Unit_id" },
  { type: ["text"], name: "Unit_name" },
  { type: ["text"], name: "Unit_category" },
  { type: ["text"], name: "unit_purpose" },
  { type: ["text"], name: "status" }
];

export const reportHeading = (t) => [
  { title: `${t("property_id")}`, field: "property_id" },
  { title: `${t("Property_Name")}`, field: "Property_Name" },
  { title: `${t("Unit_id")}`, field: "Unit_id" },
  { title: `${t("Unit_name")}`, field: "Unit_name" },
  { title: `${t("Unit_category")}`, field: "Unit_category" },
  { title: `${t("unit_purpose")}`, field: "unit_purpose" },
  { title: `${t("status")}`, field: "status" }
];

export const reportPath = [
  "property_id",
  "Property_Name",
  "Unit_id",
  "Unit_name",
  "Unit_category",
  "unit_purpose",
  "status",
];
export const ScheduleHeading = (t) => [
  { title: t("Inspection ID"), field: "inspection_id" },
  // { title: "Unit ID", field: "unit_id" },
  { title: t("Property Name"), field: "property_name" },
  { title: t("Inspector Name"), field: "resource_name" },
  { title: t("ETA"), field: "eta_minutes" },
  { title: t("Assigned On"), field: "assigned_on" },
  // { title: "Assigned By", field: "assigned_By" },
  { title: t("Status"), field: "agreement_inspection_status" },

];
export const ScheduleDataType = [
  { type: ["text"], name: "inspection_id" },
  // { type: ["text"], name: "unit_id" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "resource_name" },
  { type: ["text"], name: "eta_minutes" },
  { type: ["date"], name: "assigned_on" },
  // { type: ["text"], name: "assigned_By" },
  { type: ["agreement_inspection_status"], name: "agreement_inspection_status" },
];
export const SchedulePath = [
  "inspection_id",
  // "unit_id",
  "property_name",
  "resource_name",
  "assigned_By",
  "assigned_on",
  "agreement_inspection_status"
];
export const committeeTableHeading = (t) => [
  { title: `${t("Image")}`, field: "image" },
  { title: `${t("Name")}`, field: "member_name" },
  { title: t("Unit No"), field: "unit_no" },
  { title: t("Unit Name"), field: "unit_name" },
  { title: t("Type"), field: "is_owner" },
  { title: `${t("Role")}`, field: "role" },
  { title: `${t("Start Period")}`, field: "assigned_on" },
  { title: `${t("Status")}`, field: "is_active" },
  { title: "", field: "more" },
];
export const committeeTableDatatype = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "member_name" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "unit_name" },
  { type: ["text"], name: "is_owner" },
  { type: ["role"], name: "role" },
  { type: ["date"], name: "assigned_on" },
  { type: ["status"], name: "is_active" },
  { type: ["more"], name: "icon" },
];
export const committeeTablePath = [
  "image",
  "member_name",
  "unit_no",
  "unit_name",
  "is_owner",
  "role",
  "assigned_on",
  "is_active",
  "more"
]


export const fileManagerType = [
  { type: ["image_icon"], name: "icon" },
  { type: ["text"], name: "Folder_Name" },
  { type: ["text"], name: "Created_By" },
  { type: ["text"], name: "Date_Modified" },
  { type: ["text"], name: "Number_Files" },
  { type: ["file_manager_option"], name: "icon" },
]

export const fileManagerHeading = (t) => [
  { title: ``, field: "" },
  { title: `${t("Folder_Name")}`, field: "Folder_Name" },
  { title: `${t("Created_By")}`, field: "Created_By" },
  { title: `${t("Date_Modified")}`, field: "Date_Modified" },
  { title: `${t("Number_Files")}`, field: "Number_Files" },
  { title: ``, field: "" },
];

export const fileManagerPath = [
  "icon",
  "Folder_Name",
  "Created_By",
  "Date_Modified",
  "Number_Files",
];
export const TimeOffResourceBookingHeading = (t) => [
  { title: `${t("resource_name")}`, field: "contact_name" },
  { title: `${t("leaveType")}`, field: "leave_name" },
  { title: `${t("timeOff_Type")}`, field: "leave_type" },
  { title: `${t("startDateAndTime")}`, field: "start_date_time" },
  { title: `${t("endDateAndTime")}`, field: "end_date_time" },
  { title: `${t("duration")}`, field: "duration_type" },
  { title: `${t("days/hours")}`, field: "no_of_days" },
  // {title:`${t("hours")}`,field:"hours"},
  { title: `${t("created_by")}`, field: "created_by" },
  // {title:`${t("source")}`,field:"source"},
  { title: `${t("created_on")}`, field: "created_at" },
  // {title:`${t("approved_by")}`,field:"approved_by"},
]
export const TimeOffResourceBookingDataType = [
  { type: ["text"], name: "contact_name" },
  { type: ["text"], name: "leave_name" },
  { type: ["text"], name: "leave_type" },
  { type: ["text"], name: "start_date_time" },
  { type: ["text"], name: "end_date_time" },
  { type: ["text"], name: "duration_type" },
  { type: ["text"], name: "no_of_days" },
  // { type: ["text"], name: "hours" },
  { type: ["text"], name: "created_by" },
  // { type: ["text"], name: "source" },
  { type: ["date"], name: "created_at" },
  // { type: ["text"], name: "approved_by" },
]
export const TimeOffResourceBookingPath = [
  "contact_name",
  "leave_name",
  "leave_type",
  "start_date_time",
  "end_date_time",
  "duration_type",
  "no_of_days",
  // "hours",
  "created_by",
  // "source",
  "created_at",
  // "approved_by"
];
export const resourceBookingHeading = (t) => [
  { title: `${t("job_id")}`, field: "job_reference_id" },
  { title: `${t("date")}`, field: "created_at" },
  { title: `${t("name")}`, field: "name" },
  { title: `${t("job/position")}`, field: "job" },
  { title: `${t("job_type")}`, field: "type" },
  { title: `${t("start_date_time")}`, field: "start_date_time" },
  { title: `${t("end_date_time")}`, field: "end_date_time" },
  { title: `${t("proposedHours")}`, field: "eta" },
];
export const resourceBookingDataType = [
  { type: ["text"], name: "job_reference_id" },
  { type: ["text"], name: "created_at" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "job" },
  { type: ["text"], name: "type" },
  { type: ["text"], name: "start_date_time" },
  { type: ["text"], name: "end_date_time" },
  { type: ["text"], name: "eta" },
]
export const resourceBookingPath = [
  "job_reference_id",
  "created_at",
  "name",
  "job",
  "type",
  "start_date_time",
  "end_date_time",
  "eta"
];
export const serviceInspectionHeading = (t) => [
  { title: `${t("jobId")}`, field: "job_no" },
  { title: `${t("maintenanceRequest")}`, field: "maintenance_request_no" },
  { title: `${t("propertyName")}`, field: "property_name" },
  { title: `${t("unitName")}`, field: "unit_name" },
  { title: `${t("requestedOn")}`, field: "created_at" },
  { title: `${t("job_date")}`, field: "execution" },
  { title: `${t("inspector_name")}`, field: "assigned_to" },
  { title: `${t("projected")}`, field: "hours" },
  { title: `${t("actual")}`, field: "actual_hours" },
  { title: `${t("others")}`, field: "other_hours" },
  { title: `${t("completed_on")}`, field: "completed_on" },
  { title: `${t("KPI")}`, field: "KPI" },
  { title: `${t("job_status")}`, field: "status" },
]
export const serviceInspectionDataType = [
  { type: ["text"], name: "job_no" },
  { type: ["text"], name: "maintenance_request_no" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "unit_name" },
  { type: ["date"], name: "created_at" },
  { type: ["date"], name: "execution" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "KPI" },
  { type: ["agreement_inspection_status"], name: "status" },
]
export const serviceInspectionPath = [
  "job_no",
  "maintenance_request_no",
  "property_name",
  "unit_name",
  "created_at",
  "execution",
  "assigned_to",
  "hours",
  "actual_hours",
  "other_hours",
  "completed_on",
  "KPI",
  "status",
];
export const generalInspectionHeading = (t) => [
  { title: `${t("jobId")}`, field: "job_no" },
  { title: `${t("propertyName")}`, field: "property_name" },
  { title: `${t("unitName")}`, field: "unit_name" },
  { title: `${t("requestedOn")}`, field: "created_at" },
  { title: `${t("job_date")}`, field: "execution" },
  { title: `${t("inspector_name")}`, field: "assigned_to" },
  { title: `${t("projected")}`, field: "hours" },
  { title: `${t("actual")}`, field: "actual_hours" },
  { title: `${t("others")}`, field: "other_hours" },
  { title: `${t("completed_on")}`, field: "completed_on" },
  { title: `${t("KPI")}`, field: "KPI" },
  { title: `${t("job_status")}`, field: "status" },
]
export const generalInspectionDataType = [
  { type: ["text"], name: "job_no" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "unit_name" },
  { type: ["date"], name: "created_at" },
  { type: ["date"], name: "execution" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "KPI" },
  { type: ["agreement_inspection_status"], name: "status" },

]
export const generalInspectionPath = [
  "job_no",
  "property_name",
  "unit_name",
  "created_at",
  "assigned_to",
  "hours",
  "actual_hours",
  "other_hours",
  "completed_on",
  "KPI",
  "status",
];
export const CompletedJobHdr = (t) => [
  { title: t("Resource Name"), field: "first_name" },
  { title: t("Jobs"), field: "jobs" },
  { title: t("Projected"), field: "projected_hour" },
  { title: t("Actual"), field: "actual_hours" },
  { title: t("Others"), field: "other_hour" },
  { title: t("Ontime %"), field: "on_time" },
  { title: t("Delayed %"), field: "delay" },
];
export const CompletedJobDataType = [
  { type: ["text"], name: "first_name" },
  { type: ["text"], name: "jobs" },
  { type: ["text"], name: "projected_hour" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hour" },
  { type: ["text"], name: "on_time" },
  { type: ["text"], name: "delay" }
]
export const CompletedJobPath = [
  "first_name",
  "jobs",
  "projected_hour",
  "actual_hours",
  "other_hour",
  "on_time",
  "delay"
];
export const OngoingJobHdr = (t) => [
  { title: t("Resource Name"), field: "first_name" },
  { title: t("Job Id"), field: "reference_id" },
  { title: t("Job Type"), field: "request_type" },
  { title: t("Job Date"), field: "execution" },
  { title: t("KPI"), field: "work_status" },
  { title: t("Job Status"), field: "status" },
];
export const OngoingJobDataType = [
  { type: ["text"], name: "first_name" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "request_type" },
  { type: ["date"], name: "execution" },
  { type: ["text"], name: "work_status" },
  { type: ["text"], name: "status" },
]
export const OngoingJobPath = [
  "first_name",
  "reference_id",
  "request_type",
  "execution",
  "work_status",
  "status",
];
export const ResourceHdr = (t) => [
  { title: t("Resource Name"), field: "label" },
  { title: t("Total Jobs"), field: "total_jobs" },
  { title: t("Assigned"), field: "assigned_jobs" },
  { title: t("Ongoing"), field: "ongoing_jobs" },
  { title: t("Completed"), field: "completed_jobs" },
];
export const ResourceDataType = [
  { type: ["text"], name: "label" },
  { type: ["text"], name: "total_jobs" },
  { type: ["text"], name: "assigned_jobs" },
  { type: ["text"], name: "ongoing_jobs" },
  { type: ["text"], name: "completed_jobs" },
]
export const ResourcePath = [
  "label",
  "total_jobs",
  "assigned_jobs",
  "ongoing_jobs",
  "completed_jobs",
]
export const getSiteVisitRequestHeading = (t) => [
  { title: `${t("jobId")}`, field: "job_no" },
  { title: `${t("propertyName")}`, field: "property_name" },
  { title: `${t("unitName")}`, field: "unit_name" },
  { title: `${t("requestedOn")}`, field: "created_at" },
  { title: `${t("job_date")}`, field: "execution" },
  { title: `${t("inspector_name")}`, field: "assigned_to" },
  { title: `${t("projected")}`, field: "hours" },
  { title: `${t("actual")}`, field: "actual_hours" },
  { title: `${t("others")}`, field: "other_hours" },
  { title: `${t("completed_on")}`, field: "completed_on" },
  { title: `${t("KPI")}`, field: "KPI" },
  { title: `${t("job_status")}`, field: "status" },
]
export const siteVisitRequestDataType = [
  { type: ["text"], name: "job_no" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "unit_name" },
  { type: ["date"], name: "created_at" },
  { type: ["date"], name: "execution" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "KPI" },
  { type: ["agreement_inspection_status"], name: "status" },

]
export const siteVisitRequestPath = [
  "job_no",
  "property_name",
  "unit_name",
  "created_at",
  "execution",
  "assigned_to",
  "hours",
  "actual_hours",
  "other_hours",
  "completed_on",
  "KPI",
  "status",
];
export const getItemConditionMasterHeading = (t) => {

  const heading = [
    { title: t("inspectionItemType"), field: "type" },
    { title: t("categoryName"), field: "title" },
    { title: t("status"), field: "is_active" },
    { title: "", field: "icon" }
  ]

  return heading
}
export const ItemConditionDataType = [
  { type: ["text"], name: "type" },
  { type: ["text"], name: "title" },
  { type: ["status"], name: "status" },
  { type: ["more"], name: "icon" }

]
export const ItemConditionPath = [
  "type",
  "title",
  "status",
  "icon"
]
export const getItemConditionOptionHeading = (t) => {

  const heading = [
    { title: t("inspectionItemType"), field: "type" },
    { title: t("category"), field: "category_name" },
    { title: t("name"), field: "name" },
    { title: t("color"), field: "colorLabel" },
    { title: t("status"), field: "is_active" },
    { title: "", field: "icon" }
  ]

  return heading
}
export const ItemConditionOptionDataType = [
  { type: ["text"], name: "type" },
  { type: ["text"], name: "category_name" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "colorLabel" },
  { type: ["status"], name: "status" },
  { type: ["more"], name: "icon" }

]
export const ItemConditionOptionPath = [
  "type",
  "category_name",
  "name",
  "colorLabel",
  "status",
  "icon"
]
export const ActivityBoardHeading = (t) => [
  { title: "", field: "img" },
  { title: t("Activity Name"), field: "name" },
  { title: t("Action Info"), field: "action_info" },
  // { title: "Purpose", field: 'purpose' },
  { title: t("Date"), field: "date" },
  { title: t("Created By"), field: "createdBy" },
  { title: t("Priority"), field: "priority" },
  { title: t("Status"), field: "status" },
];

export const ActivityBoardPath = [
  "img",
  "name",
  "action_info",
  "date",
  "createdBy",
  "priority",
  "status"
]
export const utilityReadingHeading = (t) => [
  { title: "", field: "icon" },
  { title: t("unitBillingId"), field: "reading_no" },
  { title: t("date"), field: "date" },
  { title: t("propertyName"), field: "Property_name" },
  { title: t("unitId"), field: "unit_id" },
  { title: t("utilityName"), field: "utility_name" },
  { title: t("meterNo"), field: "meter_no" },
  { title: t("unitsConsumed"), field: "units_consumed" },
  { title: t("ratePerUOM"), field: "ratePerUOM" },
  // { title: t("status"), field: "status" },

]

export const utilityReadingDataType = [
  { type: ["utility_reading"], name: "icon" },
  { type: ["text"], name: "reading_no" },
  { type: ["date"], name: "date" },
  { type: ["text"], name: "Property_name" },
  { type: ["text"], name: "unit_id" },
  { type: ["text"], name: "utility_name" },
  { type: ["text"], name: "meter_no" },
  { type: ["text"], name: "units_consumed" },
  { type: ["text"], name: "ratePerUOM" },
  { type: ["status"], name: "status" },

]
export const utilityReadingPath = [
  "icon",
  "reading_no",
  "date",
  "Property_name",
  "unit_id",
  "utility_name",
  "meter_no",
  "units_consumed",
  "ratePerUOM",
  // "status",
]
export const getSummaryTableHeading = (t) => [
  { title: `${t("jobId")}`, field: "job_no" },
  { title: `${t("jobType")}`, field: "request_type" },
  { title: `${t("resourceName")}`, field: "assigned_to" },
  { title: `${t("role")}`, field: "role" },
  { title: `${t("completed_on")}`, field: "completed_on" },
  { title: `${t("projected_hours")}`, field: "hours" },
  { title: `${t("actualhours")}`, field: "actual_hours" },
  { title: `${t("other_hours")}`, field: "other_hours" },
  { title: `${t("KPI")}`, field: "KPI" },
  { title: `${t("per_hour_rate")}`, field: "per_hour_rate" },
  { title: `${t("billing_hours")}`, field: "billable_hour" },
  { title: `${t("billing_amount")}`, field: "billing_amount" },
  { title: `${t("job_status")}`, field: "status" },
]
export const SummaryTableDataType = [
  { type: ["text"], name: "job_no" },
  { type: ["text"], name: "request_type" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "role" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["text"], name: "KPI" },
  { type: ["text"], name: "per_hour_rate" },
  { type: ["text"], name: "billable_hour" },
  { type: ["text"], name: "billing_amount" },
  { type: ["agreement_inspection_status"], name: "status" },

]
export const SummaryTablePath = [
  "job_no",
  "request_type",
  "assigned_to",
  "role",
  "completed_on",
  "hours",
  "actual_hours",
  "other_hours",
  "KPI",
  "per_hour_rate",
  "billable_hour",
  "billing_amount",
  "status",
];

export const FeedBackHeading = (t) => [
  { title: `${t("referenceNumber")}`, field: "form_no" },
  { title: `${t("user")}`, field: "first_name" },
  { title: `${t("contactInfo")}`, field: "email_id" },
  { title: `${t("job")}`, field: "job" },
  // { title: `${t("reasonForFeedBack")}`, field: "reason" },
]
export const FeedBackDataType = [
  { type: ["text"], name: "form_no" },
  { type: ["text"], name: "first_name" },
  { type: ["text"], name: "email_id" },
  { type: ["text"], name: "job" },
  // { type: ["text"], name: "reason" },
]
export const FeedBackPath = [
  "form_no",
  "first_name",
  "email_id",
  "job",
  // "reason"
]
export const feedBackFormHeading = (t) => [
  { title: `${t("formID")}`, field: "form_no" },
  { title: `${t("formCategory")}`, field: "form_category" },
  { title: `${t("formName")}`, field: "title" },
  { title: `${t("createdBy")}`, field: "created_by" },
  { title: `${t("publishedOn")}`, field: "last_updated_on" },
  { title: `${t("status")}`, field: "is_active" },
  { title: "", field: "icon" },
]
export const feedBackDraftHeading = (t) => [
  { title: `${t("formID")}`, field: "form_no" },
  { title: `${t("formCategory")}`, field: "form_category" },
  { title: `${t("formName")}`, field: "title" },
  { title: `${t("createdBy")}`, field: "created_by" },
  { title: `${t("last_updated_on")}`, field: "last_updated_on" },
  // { title: `${t("status")}`, field: "is_active" },
  { title: "", field: "icon" },
]
export const feedBackFormPath = [
  "form_id",
  "form_category",
  "form_name",
  "created_by",
  "published_on",
  "is_active",
  // "reason"
]
export const FeedBackPublishDataType = [
  { type: ["text"], name: "form_no" },
  { type: ["text"], name: "form_category" },
  { type: ["text"], name: "title" },
  { type: ["text"], name: "created_by" },
  { type: ["date"], name: "last_updated_on" },
  { type: ["status"], name: "is_active" },
  { type: ["feedback"], name: "icon" },
]
export const FeedBackDraftDataType = [
  { type: ["text"], name: "form_no" },
  { type: ["text"], name: "form_category" },
  { type: ["text"], name: "title" },
  { type: ["text"], name: "created_by" },
  { type: ["date"], name: "last_updated_on" },
  { type: ["draft_feedback"], name: "icon" },
]

export const UnitBlockingHeading = (t) => [
  { title: `${t("unitno")}`, field: "unit_no" },
  { title: `${t("unitName")}`, field: "unit_name" },
  { title: `${t("duration")}`, field: "duration" },
  { title: `${t("purpose")}`, field: "purpose" },
  { title: `${t("status")}`, field: "status" },
]
export const UnitBlockingPath = [
  "unit_no",
  "unit_name",
  "duration",
  "purpose",
  "status",
]
export const UnitBlockingDataType = [
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "unit_name" },
  { type: ["text"], name: "duration" },
  { type: ["text"], name: "purpose" },
  { type: ["blocking_status"], name: "status" },
]
export const InspectionHubHeading = [
  { title: "Inspection ID", field: "inspection_id" },
  { title: "Request Type", field: "type" },
  { title: "Unit Name", field: "unit_name" },
  { title: "Request No", field: "request_no" },
  { title: "Inspector Name", field: "resource_name" },
  { title: "Assigned On", field: "assigned_on" },
  // { title: "Assigned By", field: "assigned_By" },
  { title: "Status", field: "agreement_inspection_status" },

];
export const InspectionHubDataType = [
  { type: ["text"], name: "inspection_id" },
  { type: ["text"], name: "type" },
  { type: ["text"], name: "unit_name" },
  { type: ["text"], name: "request_no" },
  { type: ["text"], name: "resource_name" },
  { type: ["date"], name: "assigned_on" },
  // { type: ["text"], name: "assigned_By" },
  { type: ["agreement_inspection_status"], name: "agreement_inspection_status" },
];
export const InspectionHubPath = [
  "inspection_id",
  "type",
  "unit_name",
  "request_no",
  "resource_name",
  "assigned_on",
  "agreement_inspection_status"
]
export const LeadheadingResident = (t) => [
  { title: "", field: "icon" },
  { title: t("Onboarding ID"), field: "leadNo" },
  { title: t("Date"), field: "date" },
  { title: t("Description"), field: "description" },
  { title: t("Purpose"), field: "purpose" },
  // { title: "Type", field: "opportunity_type" },
  // { title: "Quote ID", field: "activeQuote" },
  { title: t("Revenue"), field: "value" },
  { title: t("Owner"), field: "owner" },
  { title: t("Status"), field: "status" },
  { title: t("Status Date"), field: "dateOfOpen" },
  { title: t("Closed On"), field: "dateOfClosed" },
  // { title: "Lead ID", field: "leadId" },
];

export const LeadpathResident = [
  "icon",
  "leadNo",
  "owner",
  "description",
  "purpose",
  "source",
  // "activeQuote",
  "value",
  "date",
  "dateOfOpen",
  "priority",
  "status",
  "dateOfClosed"
  // "leadId"
];

export const costingHeading = [
  { title: "Request Name", field: "subject" },
  { title: "ID", field: "request_no" },
  // { title: "Release Date", field: "release_date" },
  { title: "Unit", field: "unit_no" },
  { title: "Initial Costing Amount", field: "amount" },
  { title: "Status", field: "costing_status" },
  { title: "", field: "icon" },
]

export const costingPath = [
  "subject",
  "request_no",
  // "release_date",
  "unit_no",
  "amount",
  "costing_status",
  "icon"
]
export const costingDataType = [
  { type: ["text"], name: "subject" },
  { type: ["text"], name: "request_no" },
  // { type: ["text"], name: "release_date" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "amount" },
  { type: ["status"], name: "status" },
  { type: ["costing"], name: "icon" },
]
export const InvoiceDetailsHead = (t) => [
  { title: t("Account"), field: "customer" },
  { title: t("Invoice No"), field: "invoice_no" },
  { title: t("Due Date"), field: "due_date" },
  { title: t("Due Amount"), field: "due_amount" },
  // { title: "Due Spilt", field: "due_spilt" },
]
export const InvoiceDetailsPath = [
  "name",
  "invoice_no",
  "payment_due_date",
  "invoice_due_amount",
  // "due_spilt",
]
export const onDutyHeading = (t = () => false) => [
  { title: t("Location"), field: "location" },
  { title: t("Name"), field: "name" },
  { title: t("Check In"), field: "check_in" },
  { title: t("Check Out"), field: "check_out" },
  { title: t("Contact Number"), field: "contact_number" },
]
export const onDutyPath = [
  "location",
  "name",
  "check_in",
  "check_out",
  "contact_number",
]
export const entriesByGateHeading = (t = () => false) => [
  { title: t("Pass Type"), field: "pass_type" },
  { title: t("Pass Id"), field: "pass_id" },
  { title: t("Gate"), field: "gate" },
  { title: t("Visitor Name"), field: "visitor_name" },
  { title: t("Visitor Contact"), field: "visitor_contact" },
  { title: t("Tagged Unit"), field: "tagged_unit" },
  { title: t("Check In"), field: "check_in" },
  { title: t("Check In By"), field: "check_in_by" },
  { title: t("Check Out"), field: "check_out" },
  { title: t("Check Out By"), field: "check_out_by" },
]
export const entriesByGatePath = [
  "pass_type",
  "pass_id",
  "gate",
  "visitor_name",
  "visitor_contact",
  "tagged_unit",
  "check_in",
  "check_in_by",
  "check_out",
  "check_out_by",
]
export const CostingApprovalHeading = (t) => [
  { title: t("Approval Id"), field: "request_no" },
  { title: t("Request Id"), field: "request_id" },
  { title: t("Request Type"), field: "source" },
  { title: t("Requested By"), field: "requested_by" },
  { title: t("Requested On"), field: "requested_on" },
  { title: t("Updated On"), field: "updated_on" },
  { title: t("Status"), field: "status" },
]

export const CostingApprovalPath = [
  "request_no",
  "request_id",
  "source",
  "requested_by",
  "requested_on",
  "updated_on",
  "costing_status",
]
export const CostingApprovalDataType = [
  { type: ["text"], name: "request_no" },
  { type: ["text"], name: "request_id" },
  // { type: ["text"], name: "release_date" },
  { type: ["text"], name: "source" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "requested_on" },
  { type: ["text"], name: "updated_on" },
  { type: ["status"], name: "status" },
]
export const unitGroupHeading = (t) => [
  { title: t("Group Id"), field: "group_id" },
  { title: t("Check List Group Name"), field: "name" },
  { title: t("Item Type"), field: "inspection_type" },
  { title: t("Unit Type"), field: "unit_type" },
  { title: t("No.of Checklists"), field: "check_list_count" },
  { title: t("Status"), field: "is_active" },
  { title: t(""), field: "icon" },
]

export const unitGroupPath = [
  "group_id",
  "name",
  "inspection_type",
  "unit_type",
  "check_list_count",
  "is_active",
  "icon",
]
export const unitGroupDataType = [
  { type: ["text"], name: "group_id" },
  { type: ["text"], name: "name" },
  // { type: ["text"], name: "release_date" },
  { type: ["text"], name: "inspection_type" },
  { type: ["text"], name: "unit_type" },
  { type: ["text"], name: "check_list_count" },
  { type: ["status"], name: "is_active" },
  { type: ["more_data"], name: "icon" },
]
export const generalGroupHeading = (t) => [
  { title: t("Group Id"), field: "group_id" },
  { title: t("Check List Group Name"), field: "name" },
  { title: t("Item Type"), field: "inspection_type" },
  { title: t("Item Category"), field: "item_category" },
  { title: t("No.of Checklists"), field: "check_list_count" },
  { title: t("Status"), field: "is_active" },
  { title: t(""), field: "icon" },
]

export const generalGroupPath = [
  "group_id",
  "name",
  "inspection_type",
  "item_category",
  "check_list_count",
  "is_active",
  "icon",
]
export const generalGroupDataType = [
  { type: ["text"], name: "group_id" },
  { type: ["text"], name: "name" },
  // { type: ["text"], name: "release_date" },
  { type: ["text"], name: "inspection_type" },
  { type: ["text"], name: "item_category" },
  { type: ["text"], name: "check_list_count" },
  { type: ["status"], name: "is_active" },
  { type: ["more_data"], name: "icon" },
]
export const itemGroupHeading = (t) => [
  { title: t("Group Id"), field: "group_id" },
  { title: t("Check List Group Name"), field: "name" },
  { title: t("Item Type"), field: "inspection_type" },
  { title: t("Item Category"), field: "item_category" },
  { title: t("Item Name"), field: "inspection_item" },
  { title: t("No.of Checklists"), field: "check_list_count" },
  { title: t("Status"), field: "is_active" },
  { title: t(""), field: "icon" },
]

export const itemGroupPath = [
  "group_id",
  "name",
  "inspection_type",
  "item_category",
  "inspection_item",
  "check_list_count",
  "is_active",
  "icon",
]
export const itemGroupDataType = [
  { type: ["text"], name: "group_id" },
  { type: ["text"], name: "name" },
  // { type: ["text"], name: "release_date" },
  { type: ["text"], name: "inspection_type" },
  { type: ["text"], name: "item_category" },
  { type: ["text"], name: "inspection_item" },
  { type: ["text"], name: "check_list_count" },
  { type: ["status"], name: "is_active" },
  { type: ["more_data"], name: "icon" },
]
export const checkListGroupHeading = (t) => [
  { title: t("Check List Name"), field: "feed_back_name" },
  { title: t("Condition"), field: "condition_count" },
  { title: t("Mandatory Check List"), field: "is_mandatory" },
  { title: t("Hardware/Software"), field: "is_software_hardware" },
  { title: t("Status"), field: "is_active" },
  { title: t(""), field: "icon" },
]

export const checkListGroupPath = [
  "name",
  "condition_count",
  "is_mandatory",
  "is_software_hardware",
  "is_active",
  "icon",
]
export const checkListGroupDataType = [
  { type: ["text"], name: "name" },
  { type: ["text"], name: "condition_count" },
  // { type: ["text"], name: "release_date" },
  { type: ["text"], name: "is_mandatory" },
  { type: ["text"], name: "is_software_hardware" },
  { type: ["status"], name: "is_active" },
  { type: ["more_data"], name: "icon" },
]

export const SurveyFormHeading = (t) => [
  { title: `${t("formID")}`, field: "form_id" },
  // { title: `${t("formCategory")}`, field: "form_category" },
  { title: `${t("formName")}`, field: "title" },
  { title: `${t("createdBy")}`, field: "created_by_name" },
  { title: `${t("publishedOn")}`, field: "published_on" },
  { title: `${t("status")}`, field: "is_active" },
  { title: "", field: "icon" },
]
export const SurveyFormDraftHeading = (t) => [
  { title: `${t("formID")}`, field: "form_id" },
  // { title: `${t("formCategory")}`, field: "form_category" },
  { title: `${t("formName")}`, field: "title" },
  { title: `${t("createdBy")}`, field: "created_by_name" },
  { title: `${t("last_updated_on")}`, field: "last_updated_on" },
  // { title: `${t("status")}`, field: "is_active" },
  { title: "", field: "icon" },
]
export const SurveyFormPath = [
  "form_id",
  // "form_category",
  "form_name",
  "created_by_name",
  "published_on",
  "is_active",
  // "reason"
]
export const SurveyFormPublishDataType = [
  { type: ["text"], name: "form_id" },
  // { type: ["text"], name: "form_category" },
  { type: ["text"], name: "title" },
  { type: ["text"], name: "created_by_name" },
  { type: ["date"], name: "last_updated_on" },
  { type: ["status"], name: "is_active" },
  { type: ["feedback"], name: "icon" },
]
export const SurveyFormDraftDataType = [
  { type: ["text"], name: "form_id" },
  // { type: ["text"], name: "form_category" },
  { type: ["text"], name: "title" },
  { type: ["text"], name: "created_by_name" },
  { type: ["date"], name: "last_updated_on" },
  { type: ["more_5"], name: "icon" },
]
export const SurveyDraftFormPath = [
  "form_id",
  // "form_category",
  "form_name",
  "created_by_name",
  "last_updated_on",
  "is_active",
  // "reason"
]
export const FeedBackRepliesHeading = (t) => [
  { title: `${t("referenceNumber")}`, field: "reference_no" },
  { title: `${t("user")}`, field: "created_by_name" },
  { title: `${t("contactInfo")}`, field: "email_id" },
  { title: `${t("Source")}`, field: "build_source" },
  // { title: `${t("reasonForFeedBack")}`, field: "reason" },
]
export const FeedBackRepliesDataType = [
  { type: ["text"], name: "reference_no" },
  { type: ["text"], name: "created_by_name" },
  { type: ["text"], name: "email_id" },
  { type: ["text"], name: "build_source" },
  // { type: ["text"], name: "reason" },
]
export const FeedBackRepliesPath = [
  "reference_no",
  "created_by_name",
  "email_id",
  "build_source",
  // "reason"
]
export const SchedulePlanViewPath = ["reference_no", "schedule_no", "property_name", "pmp_criteria", "frequency", "execution", "start_date", "status"]

export const SchedulePlanViewHeading = (t) => [
  { title: t("Dynamic Plan Id"), field: "reference_no" },
  { title: t("Schedule Plan Id"), field: "schedule_no" },
  { title: t("Property Name"), field: "property_name" },
  // { title: t("Unit/Asset Type"), field: "type" },
  { title: t("Criteria"), field: "pmp_criteria" },
  { title: t("Frequency"), field: "frequency" },
  { title: t("Execution Period"), field: "execution" },
  { title: t("Schedule Date"), field: "start_date" },
  { title: t("Status"), field: "status" },
]

export const SchedulePlanViewDataType = [
  { type: ["text"], name: "reference_no" },
  { type: ["text"], name: "schedule_no" },
  { type: ["text"], name: "property_name" },
  // { type: ["text"], name: "type" },
  { type: ["text"], name: "pmp_criteria" },
  { type: ["text"], name: "frequency" },
  { type: ["text"], name: "execution" },
  { type: ["date"], name: "start_date" },
  { type: ["agreement_inspection_status"], name: "status" },
]
export const ScheduleWorkOrdersViewPath = ["work_reference_no", "plan_reference", "execution", "item_reference_no", "item_name", "unit_name", "property_name", "first_name", "status"]

export const ScheduleWorkOrdersViewHeading = (t) => [
  { title: t("Schedule ID"), field: "work_reference_no" },
  { title: t("Plan ID"), field: "plan_reference" },
  { title: t("Execution Date"), field: "execution" },
  { title: t("Item ID"), field: "item_reference_no" },
  { title: t("Item Name"), field: "item_name", },
  { title: t("Unit"), field: "unit_name", },
  { title: t("Property"), field: "property_name", },
  { title: t("Resource"), field: "first_name", },
  { title: t("Status"), field: "status", },
]
export const ScheduleWorkOrdersViewDataType = [
  { type: ["text"], name: "work_reference_no" },
  { type: ["text"], name: "plan_reference" },
  { type: ["text"], name: "execution" },
  { type: ["text"], name: "item_reference_no" },
  { type: ["text"], name: "item_name" },
  { type: ["text"], name: "unit_name" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "first_name" },
  { type: ["agreement_inspection_status"], name: "status" },
]
export const ExecutedWorkOrdersViewPath = [
  "work_order_no",
  "plan_no",
  "job_id",
  "execution",
  "item_reference_no",
  "item_name",
  "unit_name",
  "property_name",
  "status",
];

export const ExecutedWorkOrdersViewHeading = (t) => [
  { title: t("Work Order ID"), field: "work_order_no" },
  { title: t("Plan ID"), field: "plan_no" },
  { title: t("Job ID"), field: "job_id" },
  { title: t("Execution Date"), field: "execution" },
  { title: t("Item ID"), field: "item_reference_no" },
  { title: t("Item Name"), field: "item_name", },
  { title: t("Unit"), field: "unit_name", },
  { title: t("Property"), field: "property_name", },
  { title: t("Status"), field: "status", },
]

export const ExecutedWorkOrdersViewDataType = [
  { type: ["text"], name: "work_order_no" },
  { type: ["text"], name: "plan_no" },
  { type: ["text"], name: "job_id" },
  { type: ["text"], name: "execution" },
  { type: ["text"], name: "item_reference_no" },
  { type: ["text"], name: "item_name" },
  { type: ["text"], name: "unit_name" },
  { type: ["text"], name: "property_name" },
  { type: ["agreement_inspection_status"], name: "status" },
]
export const ExecutedJobsViewPath = ["plan_reference", "work_reference_no", "job_referenece_no", "job_name", "execution", "first_name", "completed_on", "eta", "billable_hour", "status"]

export const ExecutedJobsViewHeading = (t) => [
  { title: t("Plan ID"), field: "plan_reference" },
  { title: t("Work Order ID"), field: "work_reference_no" },
  { title: t("Job ID"), field: "job_referenece_no" },
  { title: t("Job Type"), field: "job_name" },
  { title: t("Execution Date"), field: "execution", },
  { title: t("Resource Name"), field: "first_name", },
  { title: t("Completed on"), field: "completed_on", },
  { title: t("Total Hours"), field: "eta", },
  { title: t("Billable Hours"), field: "billable_hour", },
  { title: t("Status"), field: "status", },
]

export const ExecutedJobsViewDataType = [
  { type: ["text"], name: "plan_reference" },
  { type: ["text"], name: "work_reference_no" },
  { type: ["text"], name: "job_referenece_no" },
  { type: ["text"], name: "job_name" },
  { type: ["date"], name: "execution" },
  { type: ["text"], name: "first_name" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "eta" },
  { type: ["text"], name: "billable_hour" },
  { type: ["agreement_inspection_status"], name: "status" },
]
export const resourceGroupMasterHeading = (t) => [
  { title: `${t("Group Id")}`, field: "reference_no" },
  { title: `${t("Resource Group Name")}`, field: "name" },
  { title: `${t("Job / Roles")}`, field: "job_count" },
  { title: `${t("profession / Skills")}`, field: "profession_count" },
  { title: `${t("Resources")}`, field: "resource_count" },
  { title: `${t("Created On")}`, field: "created_at" },
  { title: `${t("Last Modification On")}`, field: "updated_at" },
  { title: `${t("Status")}`, field: "is_active" },
  { title: "", field: "icon" },
];
export const resourceGroupMasterDataType = [
  { type: ["text"], name: "reference_no" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "job_count" },
  { type: ["text"], name: "profession_count" },
  { type: ["text"], name: "resource_count" },
  { type: ["text"], name: "created_at" },
  { type: ["text"], name: "updated_at" },
  { type: ["status"], name: "is_active" },
  { type: ["more_all"], name: "icon" },
]
export const resourceGroupMasterPath = [
  "reference_no",
  "name",
  "job_count",
  "profession_count",
  "resource_count",
  "created_at",
  "updated_at",
  "is_active",
  "icon"
];

