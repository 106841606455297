/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Card,
  Container, Grid, Stack, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import QRCode from "react-qr-code";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, ExtraBold, NetWorkCallMethods, SemiBold, concat_string, timeZoneConverter } from "../../utils";

import { Bold } from "../../utils";
import InfoIcon from "../../assets/infoIcon";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "12px",
    padding: "10px",
    background: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontWeight: "normal",
    color: "#FFF",
    margin: 7,
  },
  rootCard: {
    backgroundColor: "white",
    borderRadius: "16px",
    // boxShadow: "0px 3px 16px #00000014",
    border: "1px solid rgb(228, 232, 238)",
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.Tertiary,
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "4px 8px",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
  },

  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
  },
  expired: {
      fontSize: "0.75rem",
      color: "#FFFFFF",
      fontFamily: Bold,
      background: "#98A0AC",
      padding: "1px 8px",
      borderRadius: theme.palette.borderRadius,
      display: "inline",
  },
  round: {
    color: "whitesmoke",
    fontSize: "40px",
  },
  border: {
    borderTop: "1px solid #E4E8EE",
  },
  heading: {
    fontSize: "0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },

  cancelbtn: {
    "&:hover": "none",
    background: "#FF4B4B 0% 0% no-repeat padding-box",
    borderRadius: theme.palette.borderRadius,
    margin: 10,
  },
  acceptbtn: {
    "&:hover": "none",
    background: "#5AC782 0% 0% no-repeat padding-box",
    borderRadius: theme.palette.borderRadius,
    margin: 10,
  },
  backbtn: {
    "&:hover": "none",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #CED3DD",
    borderRadius: theme.palette.borderRadius,
    opacity: 1,
    margin: 10,
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },

  qr: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
  },
  response: {
    backgroundColor: "#FFECEC",
    color: "#FF4B4B",
    textAlign: "center",

    fontSize: "0.75rem",
    fontFamily: SemiBold,
  },
  cardfam: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
  },
  card: {
    height: "32px",
    textAlign: "center",
    paddingTop: 4,
    width: "102px",
    cursor: "pointer",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #CED3DD",
    borderRadius: theme.palette.borderRadius,
    opacity: 1,
  },
  cardHighlight: {
    height: "32px",
    textAlign: "center",
    paddingTop: 4,
    color: "white",
    width: "102px",
    background: "#5078E1 0% 0% no-repeat padding-box",
    borderRadius: theme.palette.borderRadius,
    opacity: 1,
  },
  slot: {
    padding: 10,
    color: theme.typography.color.tertiary,
  },
  leftHalfCircle: {
    width: 20,
    height: 40,
    border: "1px solid #E4E8EE",
    backgroundColor: "#fff",
    position: "absolute",
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    zIndex: 1,
    borderLeft: 0,
    left: -1,
  },
  rightHalfCircle: {
    width: 20,
    border: "1px solid #E4E8EE",
    height: 40,
    position: "absolute",
    backgroundColor: "#fff",
    zIndex: 1,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    right: -1,
    borderRight: 0,
  },
}));

export const ParkingDetails = (props) => {
  // const size = useWindowDimensions();
  const { t = () => false } = props
  const classes = useStyles();
  const [details, setDetails] = React.useState([]);
  const [Index, setIndex] = React.useState();
  const [plots, setPlots] = React.useState([]);
  const [isDeclineForm, setIsDeclineForm] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const [disable, setDiable] = React.useState(false);
  const [isPassExpired, setIsPassExpired] = React.useState(true);
  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantId}`,
      request_id: props.passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const currentDateTime = new Date();
        const request_to = response?.data?.data?.[0]?.request_to ? new Date(response?.data?.data?.[0]?.request_to) : currentDateTime;
        const tempIsPassExpired = currentDateTime > request_to;

        setDetails(response.data.data[0]);
        setIsPassExpired(tempIsPassExpired);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  React.useEffect(() => {
    getPassDetails();
    // eslint-disable-next-line
  }, []);
  const onParking = (index) => {
    setIndex(index);
  };

  const setAcceptPlot = () => {
    if (Index !== "") {
      setDiable(true)
      const payload = {
        tenantId: `${config.tenantId}`,
        request_id: props.passId,
        parking_id: Index,
      };

      NetworkCall(
        `${config.api_url}/parking/slot/confirm`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          //setCancel(false);
          getPassDetails();
          props?.onCancel()
          // props.onLoad();
          props?.getList()
          setDiable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Parking Slot Confirmed !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });

        })
        .catch(() => { });
    }
  };
  const setAccept = () => {
    setCancel(true);
    const payload = {
      tenantId: `${config.tenantId}`,
      request_id: props.passId,
    };

    NetworkCall(
      `${config.api_url}/parking/slot/available`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data?.data;
        setPlots(main);
        // props?.onCancel()
      })
      .catch(() => { });
  };

  const declinePass = () => {
    const payload = {
      request_id: props?.passId,
      status: "Rejected",
      remarks: declineFormData.remarkForDecline,
      is_notify: true,
  }
    NetworkCall(
      `${config.api_url}/security/update_security_pass_status`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {

        setCancel(false);
        setIsDeclineForm(false);
        setDeclineFormData({ ...initialDeclineFormData });
        getPassDetails();
        props.onLoad();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Parking Slot Rejected !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const initialDeclineFormData = {
    remarkForDecline: "",
    error: {
      remarkForDecline: "",
    },
  };
  const [declineFormData, setDeclineFormData] = React.useState({
    ...initialDeclineFormData,
  });
  const updateDeclineFormData = (key, value) => {
    let error = declineFormData?.error;
    error[key] = "";
    setDeclineFormData({ ...declineFormData, [key]: value, error });
  };

  const declineDomesticHelperRequestForm = () => {
    return (
      <Grid p={2}>
        <TextBox
          multiline
          label={t("Remark For Decline")}
          placeholder={t("Enter Remarks")}
          value={declineFormData?.remarkForDecline}
          onChange={(e) => {
            updateDeclineFormData("remarkForDecline", e.target.value);
          }}
          isError={declineFormData?.error?.remarkForDecline?.length > 0}
          errorMessage={declineFormData?.error?.remarkForDecline}
        />
      </Grid>
    );
  };

  return (
    <div>
      {!cancel ? (
        <Container
          className={classes.root}
          maxWidth="sm"
          sx={{ padding: "0px" }}
        >
          <Grid
            container
            className={classes.screenScrollEffect}
          // style={{
          //   height:
          //     details?.status === "Approved"
          //       ? size?.height - 140
          //       : size?.height - 60,
          //   overflow: "scroll",
          // }}
          >
            <Grid item xs={12} sx={{ padding: "12px" }} id="ticket">
              <div className={classes.rootCard}>
                {details?.status === "Approved" && (
                  <>
                    <br />
                    <center>
                      <QRCode value={`${details?.qr_no}`} size={150} />
                      <Typography className={classes.qr}>
                        {details?.qr_no}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        className={classes.expire}
                      >
                        {t("Valid Till")}&nbsp;
                        {timeZoneConverter(details?.request_to, "DD MMM YY,hh:mm")

                        }
                      </Typography>
                    </center>
                  </>
                )}
                <Box display="flex" alignItems="center" p={3} pb={details?.status !== "Rejected" ? 0 : 3}>
                  <Box className={classes.img}>
                    <img
                      src="/images/parkingslot.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  </Box>
                  <Box flexGrow={1} marginInlineStart="10px">
                    <Typography variant="subtitle2" className={classes.title}>
                      {details?.request_type === "PSR" &&
                        t("Parking Slot Requirement")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {t("Requested on")}{" "}
                      {timeZoneConverter(details?.request_from, "DD MMM YY ,hh:mm")
                      }
                    </Typography>
                    {details?.status === "Pending" && (
                      <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}>
                        {isPassExpired
                          ? <Typography variant="subtitle2" className={classes.expired}>
                            {t("Expired")}
                          </Typography>
                          : <Typography variant="subtitle2" className={classes.progress}>
                            {t("In Progress")}
                          </Typography>}
                        {isPassExpired &&
                          <InfoIcon
                            info={t("This Pass has been expired. You can only decline this pass.")}
                            placement={"right"}
                            toolTipStyle={{ color: "#98A0AC" }} />}
                      </Stack>)}
                    {details?.status === "Approved" && (
                      <Typography
                        variant="subtitle2"
                        className={classes.approved}
                      >
                        {t("Approved")}
                      </Typography>
                    )}
                    {details?.status === "Rejected" && (
                      <Typography
                        variant="subtitle2"
                        className={classes.delete}
                      >
                        {t("Rejected")}
                      </Typography>
                    )}
                    {details?.status === "Cancelled" && (
                      <Typography
                        variant="subtitle2"
                        className={classes.delete}
                      >
                        {t("Cancelled")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {details?.status === "Rejected" && (
                  <Box
                    className={classes.response}
                    style={{ padding: "4px", marginBottom: "26px" }}
                  >
                    <Typography className={classes.response}>
                      {details?.remarks}
                    </Typography>
                  </Box>
                )}

                <Box
                  display="flex"
                  alignItems="center"
                  marginTop="24px"
                  style={{ position: "relative" }}
                >
                  <div className={classes.leftHalfCircle} />
                  <Box
                    flexGrow={1}
                    style={{ position: "absolute", width: "100%" }}
                  >
                    <Box className={classes.border} />
                  </Box>
                  <div className={classes.rightHalfCircle} />
                </Box>

                <Box p={3}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Reference Id")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.request_no ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Allotted Slot")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.slot_name ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Property Name")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.property_name ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Unit")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {concat_string(
                          {
                            name: details?.unit?.[0]?.name ?? "",
                            unit_no: details?.unit?.[0]?.unit_no ?? "",
                          },
                          ["name", "unit_no",]
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Vehicle Type")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.vechicle_type ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Vehicle Number")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.vechicle_number ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Gate Entry")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.gate ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Requested By")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.requested_by ?? " - "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("Visit Start & End Date")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {timeZoneConverter(details?.request_from, "DD MMM YY,hh:mm")
                        }
                        &nbsp;-&nbsp;
                        {timeZoneConverter(details?.request_to, "DD MMM YY,hh:mm")

                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                </Box>
              </div>
            </Grid>
          </Grid>
          {details?.status === "Pending" && (
            <div className={classes.button}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                style={{ marginLeft: "8px", backgroundColor: "#FF4B4B" }}
                variant="contained"
                disable={disable}
                onClick={() => {
                  setIsDeclineForm(true);
                  setCancel(true);
                }}
              >
                {t("Decline")}
              </Button>
              {!isPassExpired &&
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  style={{ marginLeft: "8px", backgroundColor: "#5AC782" }}
                  variant="contained"
                  disable={disable}
                  onClick={() => setAccept()}>
                  {t("Accept")}
                </Button>}
              {/* <Button
              fullWidth
             className={classes.cancelbtn}
             onClick={props.onCancel}
           >
            <Typography style={{color:"white"}}>Decline</Typography>
           </Button> */}
              {/* <Button
             fullWidth
             className={classes.acceptbtn}
             onClick={() => setAccept()}
           >
            <Typography style={{color:"white"}}>Accept</Typography>
           </Button> */}
            </div>
          )}
        </Container>
      ) : isDeclineForm ? (
        <Container
          className={classes.root}
          maxWidth="sm"
          sx={{ padding: "0px" }}
        >
          <Grid container flexDirection={"column"}>
            <div style={{ minWidth: 500 }}>
              {declineDomesticHelperRequestForm()}
            </div>
            <div className={classes.button}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                style={{
                  marginLeft: "8px",
                  backgroundColor: "#FFFFFF",
                  color: "black",
                }}
                disale={disable}
                variant="contained"
                onClick={() => {
                  setCancel(false);
                  setIsDeclineForm(false);
                  setDeclineFormData({ ...initialDeclineFormData });
                }}
              >
                {t("Back")}
              </Button>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                style={{ marginLeft: "8px", backgroundColor: "#FF4B4B" }}
                variant="contained"
                onClick={() => declinePass()}
                disale={disable}
              >
                {t("Decline")}
              </Button>
              {/* <Button
              fullWidth
              className={classes.backbtn}
              onClick={()=>setCancel(false)}
           >
            <Typography style={{color:"black"}}>{t("Back")}</Typography>
           </Button>
           <Button
             fullWidth
             className={classes.acceptbtn}
             onClick={() => setAcceptPlot()}
           >
            <Typography style={{color:"white"}}>Accept</Typography>
           </Button> */}
            </div>
          </Grid>
        </Container>
      ) : (
        <Container
          className={classes.root}
          maxWidth="sm"
          sx={{ padding: "0px" }}
        >
          <Grid container flexDirection={"column"}>
            <div style={{ height: 450, minWidth: 500 }}>
              <Typography className={classes.slot}>Slot Available</Typography>
              <Grid item className={classes.cardfam}>
                {plots?.length > 0 ? plots?.map((tabs, index) => (

                  <div key={tabs.id} style={{ padding: 10 }}>
                    <Card
                      className={
                        tabs.id === Index ? classes.cardHighlight : classes.card
                      }
                      onClick={() => onParking(tabs.id)}
                    >
                      {tabs.parking_no}
                    </Card>
                  </div>

                ))
                  :
                  <center>

                    <Typography className={classes.slot}>{"No Data Found"}</Typography>
                  </center>

                }

              </Grid>
            </div>

            <div className={classes.button}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                style={{
                  marginLeft: "8px",
                  background: "#fff",
                  border: "1px solid #CED3DD",
                  color: "#000",
                }}
                variant="contained"
                onClick={() => setCancel(false)}
              >
                {t("Back")}
              </Button>
              <Button
                fullWidth
                disabled={disable}
                className={classes.drawerButtonStyle}
                style={{ marginLeft: "8px", backgroundColor: "#5AC782" }}
                variant="contained"
                onClick={() => setAcceptPlot()}
                disale={disable}
              >
                {t("Accept")}
              </Button>
              {/* <Button
              fullWidth
              className={classes.backbtn}
              onClick={()=>setCancel(false)}
           >
            <Typography style={{color:"black"}}>{t("Back")}</Typography>
           </Button>
           <Button
             fullWidth
             className={classes.acceptbtn}
             onClick={() => setAcceptPlot()}
           >
            <Typography style={{color:"white"}}>Accept</Typography>
           </Button> */}
            </div>
          </Grid>
        </Container>
      )}
    </div>
  );
};
