import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { withNamespaces } from "react-i18next";
import { Account as AccountIMG, AgreementTag } from '../../../assets';
import { AlertDialog, Subheader, ReceiptHistoryTable, IframeViwer, TemplateGenerator } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { InvoiceForm, PostCard } from "./index";
import InvoiceDetailCard from "./invoiceDetailCard";
import { AggrementComponentStyles } from "./styles";
import { AlertContext } from '../../../contexts';

const InvoiceDetails = ({ inVoice_id = {}, details = {}, goBack = () => false, setLoading = () => false, t = () => false, tabledetails = {}, searchText = {}, handleSearch = () => false }) => {

    const classes = AggrementComponentStyles()
    const [open, setOpen] = React.useState({ bool: false, type: null, data: null })
    const [data, setData] = React.useState({})
    const [status, setStatus] = React.useState("invoice")
    const alert = React.useContext(AlertContext);


    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })
    const [template, setTemplate] = React.useState(false);


    //get invoice details
    const getInvoiceDetails = () => {

        const payload = {
            invoice_id: inVoice_id?.id,
        }

        NetworkCall(
            `${config.api_url}/invoice/resident_invoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setData(response?.data?.invoice?.[0])
        }).catch((err) => {
            console.log(err)
        })


    }
    //on click type
    const handelOpen = (val = null, data = null) => {
        setOpen({
            bool: !open?.bool,
            type: val,
            data: data
        })
    }
    const getInvoicePDF = (v) => {
        setPdf({
            bool: false,
            data: ""
        })
        setTemplate(false)
        const variables = {
            "invoice_id": inVoice_id?.id,
            type_master_id: v?.value ?? undefined
        }
        NetworkCall(
            `${config.api_url}/invoice/template`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        ).then((res) => {
            setPdf({
                data: res?.data,
                bool: true,

            })

        }).catch((err) => {
            setPdf({
                pdf: "",
                bool: false,

            })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some thing went wrong"),
            });
        })
    }

    //initial load
    React.useEffect(() => {
        getInvoicePDF()
        getInvoiceDetails()
        // eslint-disable-next-line
    }, [])


    return (
        <Box className={classes.invoiceDetail}>
            <Subheader title={t("invoices")} goBack={goBack} />

            <Grid container>
                <Grid item xs={3.5}>
                    <Box className={data?.payment_status !== "no-due" ? classes.invoiceDetail1 : classes.invoiceDetails} p={2}>
                        <InvoiceDetailCard data={data} tabledetails={tabledetails} />
                        {
                            data?.payment_schedule?.payment_schedule_cheques?.length > 0 &&
                            <Box className={classes.detailCardRoot} marginTop="16px" p={1}>
                                <PostCard
                                    mainTitle={t("cheque_details")}
                                    icon={<ContactMailIcon sx={{ color: "#7D534C" }} />}
                                    title={data?.payment_schedule?.payment_schedule_cheques?.[0]?.cheque_name}
                                    description={data?.payment_schedule?.payment_schedule_cheques?.[0]?.bank_name}
                                    status={data?.payment_schedule?.payment_schedule_cheques?.[0]?.remarks ? null : "Settle Cheque"}
                                    bgColor="#FEEAEA80"
                                    statusFlg={data?.payment_schedule?.payment_schedule_cheques?.[0]?.remarks ? false : true}
                                    statusColor='#5078E1'
                                    statusBg='#F1F7FF'
                                    onClick={() => handelOpen("Settle Cheque", data?.payment_schedule?.payment_schedule_cheques?.[0])}
                                />
                            </Box>
                        }

                        <Box className={classes.detailCardRoot} marginTop="16px" >
                            <Box p={1}>
                                <PostCard
                                    mainTitle={t("customer_details")}
                                    icon={<img src={details?.contact?.image_url?.lenth > 0 ? details?.contact?.image_url : "/images/profile_image.png"} className={classes.img} alt="" />}
                                    title={`${details?.contact?.first_name}`}
                                    description={details?.contact?.user_profiles?.user_no}
                                    bgColor="#EEF9EE"
                                    open={true}

                                />
                            </Box>
                            <Divider />
                            <Box p={1}>
                                <PostCard
                                    mainTitle={t("account_details")}
                                    icon={<AccountIMG color="#75D094" />}
                                    title={details?.customer_account?.name}
                                    description={details?.customer_account?.account_no}
                                    bgColor="#EEF9EE"
                                />
                            </Box>
                            <Divider />
                            <Box p={1}>
                                <PostCard
                                    mainTitle={t("agreement_details")}
                                    icon={<AgreementTag color="#5078E1" />}
                                    title={details?.agreement_no}
                                    //description={details?.customer_account?.account_no}
                                    bgColor="#F1F7FF"
                                />
                            </Box>
                        </Box>
                    </Box>
                    {/* As per CR Commented */}
                    {/* {
                        data?.payment_status !== "no-due" &&
                        <Grid container spacing={1} p={1}>
                            <Grid item xs={6}>
                                <Button className={classes.btn} variant='outlined' fullWidth>{t("sendPaymentLink")}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button className={classes.btn} variant="contained" onClick={() => handelOpen("Settle Offline")} fullWidth>{t("settle_offline")}</Button>
                            </Grid>
                        </Grid>
                    } */}

                </Grid>
                <Grid item xs={8.5} className={classes.invoiceDetail2}>
                    {/* <center>
                        {
                            data?.asset_url ?
                                <DocumentViewer url={data?.asset_url} />
                                :
                                <Typography className={classes.title} sx={{ marginTop: "12px" }}>No Document Found</Typography>
                        }

                    </center> */}
                    <Grid container rowSpacing={3} justifyContent={"center"} pt={0.8} style={{ position: 'relative' }}>
                        <Grid item>
                            <Stack direction={"row"} mt={"6px"}>
                                <Typography

                                    onClick={() => {
                                        setStatus("invoice");
                                    }}
                                    className={
                                        status === "invoice"
                                            ? classes.unselect
                                            : classes.select
                                    }
                                >
                                    {t("Invoice")}
                                </Typography>
                                <Typography

                                    onClick={() => {
                                        setStatus("receipt");
                                    }}
                                    className={
                                        status === "receipt"
                                            ? classes.unselect
                                            : classes.select
                                    }
                                >
                                    {t("Receipt")}
                                </Typography>
                                <Typography

                                    onClick={() => {
                                        setStatus("receipthistory");
                                    }}
                                    className={
                                        status === "receipthistory"
                                            ? classes.unselect
                                            : classes.select
                                    }
                                    sx={{ whiteSpace: "nowrap" }}
                                >
                                    {t("Receipt History")}
                                </Typography>
                            </Stack>
                        </Grid>
                        {/* <Grid item xs={1} >
                            <Typography

                                onClick={() => {
                                    setStatus("invoice");
                                }}
                                className={
                                    status === "invoice"
                                        ? classes.unselect
                                        : classes.select
                                }
                            >
                                {t("Invoice")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography

                                onClick={() => {
                                    setStatus("receipt");
                                }}
                                className={
                                    status === "receipt"
                                        ? classes.unselect
                                        : classes.select
                                }
                            >
                                {t("Receipt")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1.5} >
                            <Typography

                                onClick={() => {
                                    setStatus("receipthistory");
                                }}
                                className={
                                    status === "receipthistory"
                                        ? classes.unselect
                                        : classes.select
                                }
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                {t("Receipt History")}
                            </Typography>
                        </Grid> */}
                        <Box className={classes.next1} onClick={() => setTemplate(true)}>
                            {t("Generate")}
                        </Box>
                        {
                            status === "receipthistory" ?
                                <Grid item xs={12} m={2} p={2} className={classes.tabledoc}>
                                    <ReceiptHistoryTable ReceiptTableData={tabledetails} searchText={searchText} handleSearch={handleSearch} />
                                </Grid>
                                :
                                <Grid item xs={12} >
                                    <center className={classes.doc}>
                                        {
                                            status === "receipt" ?
                                                <div>
                                                    {
                                                        tabledetails.map(dtl => {
                                                            return (
                                                                <div>
                                                                    {
                                                                        dtl?.url ?
                                                                            <DocumentViewer url={dtl?.url} />
                                                                            :
                                                                            t("No Receipt Found")
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    <IframeViwer pdf={pdf} height='319px' />

                                                </div>
                                        }

                                    </center>
                                </Grid>
                        }
                    </Grid>
                    {t("DocumentViewer ")}     </Grid>
            </Grid>
            <AlertDialog
                open={open?.bool}
                header={open?.type}
                onClose={() => handelOpen()}
                component={<InvoiceForm t={t} invoice={data} details={details} reload={getInvoiceDetails} type={open?.type} data={open?.data} onClose={() => handelOpen()} />}
                isNormal={true}
            />



            <TemplateGenerator t={t} name={t("Invoice Template")} btnText={t("Generate")} open={template} onClose={() => setTemplate(false)} type={2} generate={getInvoicePDF} />


        </Box >
    )
}
export default withNamespaces("agreement")(InvoiceDetails); 