import { Box, Grid } from "@mui/material";
import React from "react";
import { AlertDialog, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../../components";
import { AuthContext } from "../../../contexts";
import { NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../../utils";
import { welcomedataType, welcomeheading, welcomepath } from "../../../utils/clientsettings";
import { WelcomeScreenForm } from "../components";

import { withNamespaces } from "react-i18next";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";

const WelcomeScreen = ({
    loading,
    handleLoading = () => false,
    t = () => false

}) => {

    const [open, setOpen] = React.useState(false)
    const [limits, setLimit] = React.useState(10);
    const [selectData, setSelectData] = React.useState({})
    const [page, setPage] = React.useState(1);
    const [logoList, setLogoList] = React.useState({
        list: [],
        count: 0
    })

    const [searchText, setSearchText] = React.useState("");
    const auth = React.useContext(AuthContext);
    const [permission, setPermission] = React.useState({})
    //debounce
    const debounce = UseDebounce();
    //get Bannerlist
    const getBannerlist = (limit, offset, e) => {
        const payload = {
            limit: limit,
            offset: offset,
            search: e ?? "",
            "type": ""
        }
        NetworkCall(
            `${config.api_url}/queries/company_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLogoList({
                list: response.data.data?.company_master,
                count: response.data.count
            })
            handleLoading(false)
        }).catch((err) => {
            handleLoading(false)

            console.log(err)
        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getBannerlist(limits, offset, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getBannerlist(value, 0, "")
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getBannerlist(limits, 0, "")
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //onclick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectData(data)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getBannerlist(limits, 0, e)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const render = () => {
        return (
            <Box>
                <Subheader hideBackButton title={t("Welcome Screen Banners")} />
                <Box p={2}>
                    <Box backgroundColor={'#fff'} p={2}>
                        <Grid container>
                            <Grid item xs={4} pt={'0px !importantx'}>
                                <Box>
                                    {/*setting list */}
                                    <SearchFilter value={searchText} placeholder={t("Search Company")} handleChange={(value) => handleSearch(value)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    {
                                        loading ? <LoadingSection bottom={"45vh"} message="Fetching ..." /> :
                                            <TableWithPagination
                                                heading={welcomeheading(t)}
                                                rows={logoList?.list}
                                                path={welcomepath}
                                                showpagination={true}
                                                onClick={onClickRow}
                                                dataType={welcomedataType}
                                                handlePagination={handlePagination}
                                                handleChangeLimit={handleChangeLimit}
                                                totalRowsCount={logoList?.count}
                                                page={page}
                                                limit={limits}
                                                height={'calc(100vh - 301px)'}
                                                view={true}
                                                edit={true}
                                                delete={true}
                                                marginTop={'0px !important'} />
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/*upload dialog*/}
                <AlertDialog
                    medium
                    header={t("Welcome Screen Banner")}
                    onClose={() => setOpen(false)}
                    open={open}
                    component={
                        <>
                            <WelcomeScreenForm
                                t={t}
                                reload={getBannerlist}
                                company={selectData}
                                onClose={() => setOpen(false)}
                            />
                        </>
                    }
                />
            </Box>
        )
    }

    return (
        <div>

            {accessCheckRender(render, permission, "", loading)}
        </div >
    );
}
export default withNamespaces("banners")(WelcomeScreen)
