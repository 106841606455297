import * as React from "react"
const WaringIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 139004" transform="translate(-1997 22028)">
      <rect
        width={40}
        height={40}
        fill="#ffeee6"
        data-name="Rectangle 59534"
        rx={4}
        transform="translate(1997 -22028)"
      />
      <g data-name="icons8-error (1)">
        <path
          fill="#ff7d3b"
          d="m2032.721-21997.297-14.38-22.9a1.524 1.524 0 0 0-2.682 0l-14.38 22.9a1.611 1.611 0 0 0-.279.879 1.523 1.523 0 0 0 1.524 1.524h28.952a1.523 1.523 0 0 0 1.524-1.524 1.611 1.611 0 0 0-.279-.879Z"
          data-name="Path 102496"
        />
        <path
          fill="#ffeee6"
          d="M2017.011-21998.094a2.041 2.041 0 0 1-1.45-.533 1.7 1.7 0 0 1-.571-1.29 1.644 1.644 0 0 1 .577-1.3 2.111 2.111 0 0 1 1.444-.5 2.053 2.053 0 0 1 1.438.51 1.67 1.67 0 0 1 .56 1.29 1.707 1.707 0 0 1-.554 1.306 2.036 2.036 0 0 1-1.444.517Zm1.778-15.423-.385 9.74a.555.555 0 0 1-.554.533h-1.748a.555.555 0 0 1-.554-.534l-.364-9.739a.555.555 0 0 1 .558-.577h2.493a.554.554 0 0 1 .553.577Z"
          data-name="Path 102497"
        />
      </g>
    </g>
  </svg>
)
export default WaringIcon
