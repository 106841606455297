import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import CalendarIcon from "../../assets/calendar";
import ClockArrow from "../../assets/clockArrow";
import ClockIcon from "../../assets/clockIcon";
import { Male } from "../../assets/male";
import StarIcon from "../../assets/starIcon";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, subUserTimeOffset } from "../../utils";
import {
  loadOptionsApis,
} from "../../utils/asyncPaginateLoadOptions";
import { initialState } from "../../utils/moveoutInspection";
import { PopupCard } from "../assignModal/card";
import { AssignCompenentStyles } from "../assignModal/styles";
import { CustomSelectOptions } from "../customOptions";
import { DateTimePicker } from "../dateTimePicker";
import { CustomSelect } from "../filterGenerator/components";
import { TextBox } from "../textbox";

const Assign = (props) => {
  const { t, disable } = props;
  const classes = AssignCompenentStyles();
  const [company, setCompany] = React.useState([]);
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const [editHours, setEditHours] = useState(false);
  const [edit, setEdit] = useState(false);

  const [assignData, setAssignData] = useState({ ...initialState });

  //To edit hours
  const onEditHours = (type) => {
    if (type === "edit") {
      setEditHours(false);
      setEdit(true);
    } else if (type === "check") {
      setEditHours(true);
      setEdit(false);
    }
  };

  //get assign details
  // eslint-disable-next-line
  const getAssignDetails = (company_id, module_id) => {
    const payload = {
      agreement_inspection_id: props?.agreement_inspection_id,
    };
    NetworkCall(
      `${config.api_url}/agreement_inspection/get_assign_resource`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const data = res?.data?.data;
        setAssignData({
          ...assignData,
          dateTime: new Date(data?.data?.start_date_time),
          hours: data?.data?.total_hours,
          agreement_inspection_id: props?.agreement_inspection_id,
          disable: new Date() > new Date(data?.execution) ? true : false,
          moduleId: module_id,
          company: company_id,
          resource: data?.parent_resources?.[0],
          otherResources: data?.child_resources,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const manualDeptResponse = (array) => {
    return array?.department_master
}

  React.useEffect(() => {
    // console.log(auth.auth.auth.access,"lll")
    // let company = getCompanyOption(backdrop, auth, alert);
    // const authData = auth.auth.auth.access["Inspections Management"]
    if (company) {
      setCompany(company?.list);
      if (props?.reassign) {
        // getAssignDetails(company?.selected, authData?.module_id)
      } else {
        setAssignData({
          ...assignData,
          company: company?.selected,
          // moduleId: authData?.module_id,
          agreement_inspection_id: props?.agreement_inspection_id,
        });
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //handleChange
  const updateState = (key, value, isPrimary) => {
    assignData[key] = value;
    let error = assignData.error;
    error[key] = "";
    if (key === "company") {
      assignData[key] = value;
      setAssignData({
        ...assignData,
        isPrimary: isPrimary,
        department: "",
        role: "",
        error,
      });
    } else {
      assignData.isPrimary = isPrimary;
      setAssignData({ ...assignData, error });
    }
  };
  //validate
  const validate = () => {
    let isValid = true;
    //validate name
    if (!assignData?.resource?.name) {
      isValid = false;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Select Resource"),
      });
    }
    return isValid;
  };

  //on submit
  const onSubmit = () => {
    if (validate()) {
      let otherResource_id = assignData?.otherResources
        ?.filter((i) => i?.resource_id !== assignData?.resource?.resource_id)
        .map((x) => x?.resource_id);
      const payload = {
        agreement_inspection_id: assignData?.agreement_inspection_id,
        parent_resource_id: assignData?.resource?.resource_id,
        resource_ids: otherResource_id,
        start_date_time: assignData?.dateTime,
        hours: assignData?.hours,
      };
      props?.onSubmit(payload);
    }
  };

  return (
    <Box className={classes.main}>
      <Grid container className={classes.formRoot}>
        <Grid item xs={12} className={classes.section1}>
          <Box className={classes.section1header}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography className={classes.modalName}>
                  {props?.moduleName}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomSelect
                  noSearch
                  options={company}
                  isReadOnly={props?.isReadOnly}
                  color="white"
                  placeholder={t("companyName")}
                  value={assignData?.company}
                  onChange={(value) => {
                    updateState("company", value, true);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.steps}>
            <Typography className={classes.stepTitle}>{t("step1")}</Typography>
          </Box>
          <Grid container>
            <Grid item xs={5.8} className={classes.subHeader}>
              <Box className={classes.datePicker}>
                <Box>
                  <CalendarIcon className={classes.icon} />
                </Box>
                <Box flexGrow={1} marginLeft={"8px"}>
                  <Typography className={classes.datePickerText}>
                    {t("startDateandTime")}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip
                    title={t("toolTipTextForHours")}
                    placement="top"
                    arrow
                  >
                    <Box>
                      <InfoOutlinedIcon className={classes.icon} />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>

              <Box>
                <DateTimePicker
                  isReadOnly={disable}
                  handleDateChange={(value) => updateState("dateTime", value)}
                  dateTime={assignData?.dateTime}
                />
              </Box>
            </Grid>
            <Grid item xs={5.8} className={classes.subHeader}>
              <Box className={classes.datePicker}>
                <Box>
                  <ClockIcon className={classes.icon} />
                </Box>
                <Box flexGrow={1} marginLeft={"8px"}>
                  <Typography className={classes.datePickerText}>
                    {t("projectedHours")}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip
                    title={t("toolTipTextForHours")}
                    placement="top"
                    arrow
                  >
                    <Box>
                      <InfoOutlinedIcon className={classes.icon} />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
              <Box>
                <TextBox
                  label={null}
                  placeholder=""
                  border={0}
                  type="number"
                  isReadonly={disable}
                  endAdornment={
                    <InputAdornment position="end">{t("hr")}</InputAdornment>
                  }
                  color="white"
                  onChange={(e) => updateState("hours", e.target.value, true)}
                  value={assignData?.hours}
                  isError={assignData?.error?.hours?.length > 0}
                  errorMessage={assignData?.error?.hours}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.dividerstyle} />
          {assignData?.hours?.length !== 0 && (
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box className={classes.steps}>
                  <Typography className={classes.stepTitle}>
                    {t("step2")}
                  </Typography>
                </Box>
                <Box className={classes.step2}>
                  <StarIcon className={classes.icon} />
                  <Typography className={classes.PrimaryTitle}>
                    {t("primaryResource")}
                  </Typography>
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems="center">
                  <Male />

                  <Box ml={1.5}>
                    <Typography className={classes.resourceTitle}>
                      {t("selectResource")}
                    </Typography>
                    <Typography className={classes.resourceSubTitle}>
                      {t("selectSpecificResource")}
                    </Typography>
                  </Box>
                </Box>
                {editHours === true ? (
                  <Box display={"flex"} className={classes.editAssignedhrs}>
                    <ClockArrow className={classes.arrowicon} color="#5AC782" />
                    <Typography className={classes.edithoursText}>
                      Assigned for {assignData?.hours} Hr
                    </Typography>
                    <EditOutlinedIcon
                      className={classes.editIcon}
                      onClick={() => onEditHours("edit")}
                    />
                  </Box>
                ) : edit === true ? (
                  <Box>
                    <Box display={"flex"} className={classes.editAssignedhrs}>
                      <ClockArrow
                        className={classes.arrowicon}
                        color="#5AC782"
                      />
                      <Typography className={classes.edithoursText}>
                        Edit Hour
                      </Typography>
                      <OutlinedInput
                        size="small"
                        className={classes.smalltextfield}
                        endAdornment={
                          <InputAdornment position="end">
                            {t("hr")}
                          </InputAdornment>
                        }
                        onChange={(e) =>
                          updateState("hours", e.target.value, true)
                        }
                        value={assignData?.hours}
                      />
                      <CheckCircleIcon
                        className={classes.checkIcon}
                        onClick={() => onEditHours("check")}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.assignedhrs}>
                    <ClockArrow className={classes.icon} />
                    <Typography
                      className={classes.assignText}
                      onClick={() => setEditHours(true)}
                    >
                      Assigned for {assignData?.hours} Hr
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.searchBlock}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Grid item xs={6}>
                    <Typography className={classes.selecttitle}>
                      {t("department")}
                    </Typography>
                    <CustomSelect
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/opportunity/department_master/get",
                          {
                            company_id: assignData?.company?.value
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualDeptResponse,
                          {
                            is_all:true
                          }
                        )
                      }
                      isPaginate={true}
                      borderRadius={false}
                      noBorder={true}
                      isReadOnly={props?.isReadOnly}
                      placeholder={"All"}
                      label={false}
                      value={assignData?.department}
                      onChange={(value) => {
                        updateState("department", value, true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.selecttitle}>
                      {t("role")}
                    </Typography>
                    <CustomSelect
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/job_master/list",
                          {
                            company_id: assignData?.company?.value
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          null,
                          {
                            is_all:true
                          }
                        )
                      }
                      isPaginate={true}
                      borderRadius={false}
                      noBorder={true}
                      isReadOnly={props?.isReadOnly}
                      placeholder={"All"}
                      label={false}
                      value={assignData?.role}
                      onChange={(value) => {
                        updateState("role", value, true);
                      }}
                    />
                  </Grid>
                </Stack>
                <Divider className={classes.dividerstyle1} />
                <Box padding="8px 0px">
                  <CustomSelectOptions
                    menuPlacement="top"
                    placeholder={t("search_employee")}
                    padding="12px"
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "agreement_inspection/get_resources",
                        {
                          module_id: parseInt(assignData?.moduleId),
                          company_id: assignData?.company?.value,
                          start_date_time: subUserTimeOffset(
                            assignData?.dateTime
                          ),
                          hours: parseInt(assignData?.hours),
                          resource_type_name: assignData?.resource_type?.label,
                          department_id: assignData?.department?.value,
                          job_id: assignData?.role?.value,
                          search: search,
                        },
                        search,
                        array,
                        handleLoading,
                        "data"
                      )
                    }
                    value={assignData?.resource}
                    isPaginate={true}
                    key={JSON.stringify(assignData)}
                    onChange={(val) => updateState("resource", val)}
                    // options={props?.resourceList}
                    noBorder={true}
                  />
                </Box>
              </Box>

              <Box>
                {assignData?.resource?.name && (
                  <PopupCard
                    list={assignData?.resource}
                    removeData={(val) => updateState("resource", "")}
                    deleteIcon={true}
                    selected={true}
                  />
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Box
        className={classes.footer}
        display={"flex"}
        justifyContent={"space-between"}
        p={2}
      >
        <Button
          variant="outlined"
          className={classes.cancelBtn}
          onClick={() => props?.handleClose()}
        >
          {t("close")}
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          {t(`${props?.buttonName}`)}
        </Button>
      </Box>
    </Box>
  );
};
export default withNamespaces("assignModal")(Assign);
