import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { FormGenerator } from "../../../components";
import { enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import {
  saledataType,
  saleheading,
  salepath,
} from "../../../utils/createQuotationUtils";
import { MileStoneTemplate } from "../../createquotation/component/mileStoneTemplate";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
import moment from "moment";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const SaleForm = ({ data, updateState, type = "", purpose = "" ,is_convert=false,t=()=>false,contact_id=null, opperEdit=false, setOpperEdit = () =>false}) => {
  const classes = useStyles();
  const { dataNew, setDataNew } = useContext(CreateOppertunityContext);

  const [enumValue, setEnum] = React.useState({
    priority: [],
    source: [],
    payment_period: [],
    payment_mode: [],
  });
  const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

  const marketPlace = [
    {
      label: t("Yes"),
      value: true,
    },
    {
      label: t("No"),
      value: false,
    },
  ];

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.lead_source,
      enum_types.unit_payment_period,
      enum_types?.urgent_type,
      enum_types?.payment_mode,
    ]);
    setEnum({
      payment_period: result?.payment_value_type
        ?.filter((val) => val?.value !== "Weekly")
        .filter((val) => val?.value !== "Half Yearly")
        .filter((val) => val?.value !== "Yearly")
        .filter((val) => val?.value !== "Quarterly")
        .filter((val) => val?.value !== "Prepaid")
        .filter((val) => val?.value !== "Hourly")
        .filter((val) => val?.value !== "Monthly"),
      source: result?.lead_source,
      priority: result?.urgent_type,
      payment_mode: result?.payment_mode,
    });
  };
  React.useEffect(() => {
    if (dataNew?.delivery_timestone_template?.value) {
      getTemplate(dataNew?.delivery_timestone_template);
    }
    updateState("oppertunity_subject", `${moment(new Date()).format("DD-MM-YYYY HH:mm")} ${data?.name}`)
    getEnum();
// eslint-disable-next-line
  }, []);
  //get template
  const getTemplate = async (value) => {
      await NetworkCall(
        `${config?.api_url}/queries/opportunity/get_milestone_items`,
        NetWorkCallMethods.post,
        {
          template_id: value?.value
        },
        null,
        true,
        false
      )
      .then((response) => {
        console.log(response , 'response')
        const data = response?.data?.data?.map((val) => {
          return { ...val, milestone: val?.delivery_milestone_masters?.name };
        });
        // let error = data.error;
        // error.payment_period= "";
        setDataNew({
          ...dataNew,
          payment_period: "Milestone Based",
          delivery_timestone_template: value,
          mailstoneList: data,
          mileStoneName: value?.label,
          payment_period_value:value,

          // error
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const choosePaymentPeriod = (value, list) => {
    if (value?.label === "On Completion") {
      // let error = data.error;
      // error.payment_period = "";
      setDataNew({
        ...dataNew,
        payment_period: value?.value,
        mailstoneList: [],
        mileStoneName: value?.label,
        delivery_timestone_template:"",
        payment_period_value:value,
        // error
      });
    } else {
      if(value?.value?.length > 0){

        getTemplate(value);
      }
    }
  };

  const formSale = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 7,

        lg: 9,
      },
      isActive: true,
      component: "text",

      label: t("Opportunity Subject"),
      value: data?.oppertunity_subject,
      placeholder: t("Opportunity Subject"),
      onChange: (value) =>
        updateState("oppertunity_subject", value.target.value),
      error: data?.error?.oppertunity_subject,
      isRequired: true,
      options: [],
      // multiline: true
    },

    {
      size: {
        xs: 12,
        sm: 12,
        md: 5,

        lg: 3,
      },
      isActive: true,
      component: "priority",

      label: t("Priority"),
      value: data?.priority,
      placeholder: t("Priority"),
      onChange: (value) => updateState("priority", value),
      error: data?.error?.priority,
      isRequired: true,
      options: enumValue?.priority,
    },
    {
      size: {
        xs: 12,
        sm: 6,

        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: t("Earliest Occupation Date"),
      value: data?.earlist_occupation_date,
      placeholder: t("Earliest Occupation Date"),
      onChange: (value) => updateState("earlist_occupation_date", value),
      error: data?.error?.earlist_occupation_date,
      isRequired: true,
      
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",

      label: t("Contract Start Date"),
      value: data?.contract_start_date,
      placeholder: t("Contract Start Date"),
      onChange: (value) => updateState("contract_start_date", value),
      error: data?.error?.contract_start_date,
      isRequired: true,
      options: enumValue?.lease_type,
      minDate:new Date()
    },

    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,

        lg: 3,
      },
      isActive: true,
      component: "payment",

      label: t("Payment Period"),
      value: data?.payment_period,
      companyId: data?.company,
      placeholder: t("Payment Period"),
      onChange: choosePaymentPeriod,
      mileStoneName: data?.mileStoneName,
      error: data?.error?.payment_period,
      isRequired: true,
      mileStoneId: {
        payment_period_value: data?.payment_period_value?.value,
      },
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: t("Payment Options"),
      value: data?.payment_option,
      placeholder: t("Payment Options"),
      onChange: (value) => updateState("payment_option", value),
      error: data?.error?.payment_option,
      isRequired: true,
      options: enumValue?.payment_mode,
    },
    // {
    //   size: {
    //     xs: 12,
    //     sm: 6,
    //     md: 4,
    //     lg: 3
    //   },
    //   isActive: true,
    //   component: "toggle",
    //   label: t("Wallet Credits Carry Over"),
    //   value: data?.wallet_carry_over,
    //   placeholder: t("Wallet Credits Carry Over"),
    //   onChange: (value) => updateState("wallet_carry_over", value),
    //   error: data?.error?.wallet_carry_over,
    //   isRequired: true,
    //   options: marketPlace
    // },
  ];

  const formSale2 = [
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Company Name"),
      value: data?.company_name,
      placeholder: t("Company Name"),
      onChange: (value) =>
        updateState("company_name", value.target.value),
      options: [],
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("City"),
      value: data?.city,
      placeholder: t("City"),
      onChange: (value) =>
        updateState("city", value.target.value),
      options: [],
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "select",
      label: t("Country"),
      value: data?.country,
      placeholder: t("Country"),
      onChange: (value) =>
        updateState("country", value),
      isPaginate: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/opportunity/country_master",
          {},
          search,
          array,
          handleLoading,
          "data",
          {},
        )
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Budget Amount"),
      value: data?.budget_amount,
      placeholder: t("Budget Amount"),
      onChange: (value) =>
        updateState("budget_amount", value.target.value),
      options: [],
      type: "number",
      endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Probability"),
      value: data?.probability,
      placeholder: t("Probability"),
      onChange: (value) =>
        updateState("probability", value.target.value),
      options: [],
      type: "number",
      endAdornment: <Typography className={classes.endAdornment}>%</Typography>
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "toggle",
      label: t("Confirm Interest"),
      value: data?.confirm_interest,
      placeholder: t("Confirm Interest"),
      onChange: (value) => updateState("confirm_interest", value),
      // error: data?.error?.confirm_interest,
      isRequired: false,
      options: marketPlace
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: t("Expected Close Period"),
      value: data?.expected_close_period,
      placeholder: t("Expected Close Period"),
      onChange: (value) => updateState("expected_close_period", value),
      minDate: new Date()
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Monthly Salary"),
      value: data?.monthly_salary,
      placeholder: t("Monthly Salary"),
      onChange: (value) =>
        updateState("monthly_salary", value.target.value),
      options: [],
      type: "number",
      endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
    },
  ];

  return (
    <Box >
      <Grid container spacing={1.5} className={classes.leadDetailsRoot}>
        <Grid item lg={3} md={12} sm={12} sx={{position:"relative",width:"100%"}}>

          <Box p={2} className={classes.leadDetailsRootDivider}>
            <LeadDetailsCard
              data={{
                name: data?.name,
                image:
                  data?.image_url?.length !== 0 ? data?.image_url : null,
                email: data?.email_id,
                mobile: ` ${data.mobile?.mobile_code ?? ""} ${
                  data.mobile?.mobile??"-"
                }`,

                no: null,
                type: type,
                purpose: purpose,
                source: data?.source?.value,
              }}
              source_enum={enumValue?.source ?? ""}
              is_lead={true}
              is_convert={is_convert}
              contact_id={contact_id}
              opperEdit={opperEdit}
              setOpperEdit={setOpperEdit}
            />
          </Box>
        </Grid>
        <Grid item lg={9} md={12} sm={12}>

          <Box p={2} className={classes.leadDetailsRootDivider}>
            <FormGenerator t={t} components={formSale} />
            <Box py={2}>
              <Divider></Divider>
            </Box>
            <FormGenerator t={t} components={formSale2} />
            <Box height="18px" />
            {dataNew?.mailstoneList?.length > 0 && (
              <Grid item lg={6} md={12} sm={12}>
                <MileStoneTemplate
                  dataType={saledataType}
                  rows={dataNew?.mailstoneList}
                  heading={saleheading}
                  path={salepath}
                  title={data?.mileStoneName}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};