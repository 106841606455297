import React from 'react'
import { ProjectContext } from './projectContext'
import { FormGenerator } from '../../components'
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions'
import { Typography } from '@mui/material'

export const EditForm = ({
    t = () => false
}) => {
    const {
        selectedCompany,
        enumValue,
        updateEditSpec,
        specificationEdit,
        setSpecificationEdit,
        projectedBOMEdit,
        updateEditBOM,
        data
    } = React.useContext(ProjectContext)

    const manualSubCategoryResponse = (array) => {
        const result = array?.map((e) => {
            return {
                label: e?.name,
                value: e?.id,
                ...e
            }
        })
        return result
    }

    const manualMasterResponse = (array) => {
        const result = array?.map((e) => {
            return {
                label: e?.name,
                value: e?.id,
                ...e
            }
        })

        return result
    }

    const specificationForm = [
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Item Type"),
            value: specificationEdit?.itemType,
            placeholder: t("Enter Item Type"),
            onChange: (value) =>
                updateEditSpec("itemType", value),
            options: enumValue?.inspection_item_type
            //error: data?.error?.oppertunity_subject,
            // isRequired: true,
            // multiline: true
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Item Category"),
            value: specificationEdit?.itemCategory,
            placeholder: t("Enter Item Category"),
            isPaginate: true,
            onChange: (value) =>
                updateEditSpec("itemCategory", value),
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_category",
                    {
                        item_type: [specificationEdit?.itemType?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "list"
                ),
            key: JSON.stringify(specificationEdit?.itemType),
            isReadOnly: specificationEdit?.itemType === ""
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Sub Category"),
            value: specificationEdit?.subCategory,
            placeholder: t("Enter Sub Category"),
            isPaginate: true,
            onChange: (value) => {
                setSpecificationEdit({
                    ...specificationEdit,
                    subCategory: value,
                    specificationTitle: value?.specification ? JSON.parse(value?.specification) : "-"
                })
            },
            // updateEditSpec("subCategory", value),
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_subcategory/get",
                    {
                        item_type: [specificationEdit?.itemType?.value],
                        is_active: [true]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualSubCategoryResponse
                ),
            key: JSON.stringify(specificationEdit?.itemType)
        },
        // {
        //     size: {
        //         xs: 6,
        //         sm: 6,
        //         md: 6,
        //         lg: 6,
        //     },
        //     isActive: true,
        //     component: "select",
        //     label: t("Item Master"),
        //     value: specificationEdit?.itemMaster,
        //     placeholder: t("Enter Item Master"),
        //     isPaginate: true,
        //     onChange: (value) =>
        //         updateEditSpec("itemMaster", value),
        //     loadOptions: (search, array, handleLoading) =>
        //         loadOptionsApis(
        //             "free_text_invoice/get_free_text_item_types",
        //             {
        //                 company_id: selectedCompany?.value,
        //                 item_type: "Item Master",
        //                 item_master_type: specificationEdit?.itemType?.value,
        //                 item_master_category: specificationEdit?.itemCategory?.value,
        //                 subcategory_id: specificationEdit?.subCategory?.value,
        //                 component_type: "item_based"
        //             },
        //             search,
        //             array,
        //             handleLoading,
        //             "items",
        //             {},
        //             manualMasterResponse
        //         ),
        //     key: JSON.stringify(specificationEdit?.itemType || specificationEdit?.itemCategory)
        // },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: true,
            component: "textEditer",
            label: t("Specification Title"),
            value: specificationEdit?.specificationTitle,
            placeholder: t("Enter Specification Title"),
            isReadonly: true,
            height: 150,
            toolbar: { toolbar: false }
            // onChange: (value) =>
            //     updateEditSpec("specificationTitle", value.target.value),
            // //error: data?.error?.oppertunity_subject,
            // isRequired: true,
        },
    ]

    const projectedBOMForm = [
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Item Type"),
            value: projectedBOMEdit?.itemType,
            placeholder: t("Enter Item Type"),
            onChange: (value) =>
                updateEditBOM("itemType", value),
            options: enumValue?.inspection_item_type
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Item Category"),
            value: projectedBOMEdit?.itemCategory,
            placeholder: t("Enter Item Category"),
            isPaginate: true,
            onChange: (value) =>
                updateEditBOM("itemCategory", value),
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_category",
                    {
                        item_type: [projectedBOMEdit?.itemType?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "list"
                ),
            key: JSON.stringify(projectedBOMEdit?.itemType),
            isReadOnly: projectedBOMEdit?.itemType === ""
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Sub Category"),
            value: projectedBOMEdit?.subCategory,
            placeholder: t("Enter Sub Category"),
            isPaginate: true,
            onChange: (value) =>
                updateEditBOM("subCategory", value),
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_subcategory/get",
                    {
                        item_type: [projectedBOMEdit?.itemType?.value],
                        is_active: [true]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualSubCategoryResponse
                ),
            key: JSON.stringify(projectedBOMEdit?.itemType)
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "select",
            label: t("Item Master"),
            value: projectedBOMEdit?.item,
            placeholder: t("Enter Item Master"),
            isPaginate: true,
            onChange: (value) =>
                updateEditBOM("item", value),
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "free_text_invoice/get_free_text_item_types",
                    {
                        company_id: selectedCompany?.value,
                        item_type: "Item Master",
                        item_master_type: projectedBOMEdit?.itemType?.value,
                        item_master_category: projectedBOMEdit?.itemCategory?.value,
                        subcategory_id: projectedBOMEdit?.subCategory?.value,
                        component_type: "item_based"
                    },
                    search,
                    array,
                    handleLoading,
                    "items",
                    {},
                    manualMasterResponse
                ),
            key: JSON.stringify(projectedBOMEdit?.itemType || projectedBOMEdit?.itemCategory)
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "text",
            label: t("Value"),
            value: projectedBOMEdit?.value,
            placeholder: t("Enter Value"),
            onChange: (value) =>
                updateEditBOM("value", value.target.value),
            onBlur: (val) => {
                val?.target?.value?.length === 0 ?
                    updateEditBOM("value", 0)
                    : updateEditBOM("value", val.target.value)
            },
            endAdornment: <Typography>{selectedCompany?.currency_symbol}</Typography>
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "text",
            label: t("Qty"),
            value: projectedBOMEdit?.qty,
            placeholder: t("Enter Qty"),
            onChange: (value) =>
                updateEditBOM("qty", value.target.value),
            onBlur: (val) => {
                val?.target?.value?.length === 0 ?
                    updateEditBOM("qty", 0)
                    : updateEditBOM("qty", val.target.value)
            }
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "text",
            label: t("Total Value"),
            value: projectedBOMEdit?.totalAmount,
            placeholder: t("Enter Total Value"),
            onChange: (value) =>
                updateEditBOM("totalAmount", value.target.value),
            isReadonly: true,
            endAdornment: <Typography>{selectedCompany?.currency_symbol}</Typography>
        },
    ]
    return (
        <>
            <FormGenerator
                spacing={2}
                components={data?.type === "bom" ? projectedBOMForm : specificationForm}
            />
        </>
    )
}