import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold, remCalc } from "../../../utils";

export const CustomSelectBoxStyle = makeStyles((theme) => ({
    selectBox: {
        height: (props) => props?.height ?? 32,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        background: (props) => props?.background ?? "#fff"
    },
    listText: {
        fontSize: remCalc(14),
        color: (props) => props?.textColor ?? theme.typography.color.primary,
        fontFamily: SemiBold,
        padding: "8px",
        cursor: "pointer"
    },
    noOptions: {
        fontSize: remCalc(14),
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
    selectedListText: {
        fontSize: remCalc(14),
        color: "#fff",
        // (props) => props?.textColor ?? "#fff",
        background: "#2684FF",
        fontFamily: SemiBold,
        padding: (props) => props?.padding ?? "8px",
        cursor: "pointer"
    },
    hoverlist: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        background: "#DEEBFF",
        fontFamily: SemiBold,
        padding: "8px",
        cursor: "pointer"
    },
    titletext: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: Bold,
    },
    bottomdiv: {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        padding: "8px"
    },
    applybtn: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        height:"30px"
    },
    menuItem: {
            paddingTop: "0px !important",
            paddingBottom: "0px !important"
    },
    clearAlltxt:{
        color:"#FF4B4B",
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        cursor:"pointer"
    },
    optionHeader:{
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
}))
export const ChooseCardStyle = makeStyles((theme, props) => ({
    title: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    subTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    box: {
        borderRadius: 4,
        // border: (props)=> props?.border ?? `1px solid ${theme.palette.border.primary}`,
        width: "100%",
        backgroundColor:"#F5F7FA"
    },
    header: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold
    },
    boldHeader: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    remove: {
        fontSize:"0.875rem",
        color: theme.palette.error.main,
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    optionAvatar: {
        height: 32,
        width: 32,
        borderRadius: "4px !important",
        fontSize:"1rem",
        fontFamily: Bold
    },
    optionHeader: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#091B29"
    },
    optionSubTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        whiteSpace: "noWrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft:"0px !important",
        direction: "ltr"
    },
    selectParent: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        margin: "5px",
    },
    choosecards: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #F1F7FF",
        background: "#F1F7FF",
        position: "relative",
        display: "flex",
        // justifyContent:"space-between"

    },
    selectMultiParent: {
        borderBottom: "1px solid #E4E8EE !important",
        borderRadius: 0,
        borderTop: 'none !important',
        borderLeft: 'none !important',
        borderRight: 'none !important',
        margin: "5px"



    },
    choosecardsMuti: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        background: "#FFFFFF",
        position: "relative"

    },
    newRoot: {
        borderRadius: "0px 0px 4px 4px",
        cursor: "pointer",
        alignItems: "center",
        borderTop: "none"
    },
    existRoot: {
        borderRadius: "4px 4px 0px 0px",
        cursor: "pointer",
        alignItems: "center",
        borderBottom: "none"
    },
    labelBold: {
        color: "#showCard091B29",
        fontFamily: Bold,
        fontSize:"0.875rem",
        marginBottom: "8px"
    },
    tabSwitchBlock: {
        backgroundColor: "#E4E8EE",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    tabSelecttxt: {
        fontSize: remCalc(12),
        textAlign: "center",
        fontFamily: SemiBold,
    },
    selectedTabBox:{
        boxShadow:"0px 0px 16px #00000014",
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#FFFFFF",
        padding:"4px"
    },
    unSelectedTabBox:{
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#E4E8EE",
        padding:"4px"
    },
    resourceName: {
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    jobName: {
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: Regular,
    },
    dot: {
        width: "6px",
        height: "6px",
        backgroundColor: "#98A0AC",
        borderRadius: "50%"
    },
}))