import { networkCallback } from "../utils";
import { loadOptionsApis } from "./asyncPaginateLoadOptions";
import { enum_types } from "./constants";
export const manualResponse = (data) => {
  let result = data?.data?.map((val) => {
    return {
      value: val?.id,
      label: val?.name + "-" + val?.number
    }
  })
  return result;
}

export const loadOptionDataApi = async (search, array, handleLoading, type) => {

  let result;
  let enumTypes = [enum_types.vat_code_type, enum_types.vat_item_code_type]

  if (enumTypes?.includes(type)) {
    result = await networkCallback({ enumName: [type] }, "enum");
    if (!result) {
      return {
        options: [],
        hasMore: false
      }
    }
    handleLoading(null)
    return {
      options: result?.[type] ?? [],
      hasMore: false
    }

  }
  else {
    handleLoading(null)
    return {
      options: [],
      hasMore: false
    }
  }
}

const loadOptionData = async (search, array, handleLoading, type) => {

  let result;
  let enumTypes = [enum_types.vat_code_type, enum_types.vat_item_code_type, enum_types.coa_type]

  if (enumTypes?.includes(type)) {
    result = await networkCallback({ enumName: [type], search }, "enum");
    if (!result) {
      return {
        options: [],
        hasMore: false
      }
    }
    handleLoading(null)
    return {
      options: result?.[type] ?? [],
      hasMore: false
    }

  }
  else {
    handleLoading(null)
    return {
      options: [],
      hasMore: false
    }
  }
}
export const BuisnessList = [
  {
    name: "Country",
  },
  {
    name: "Currency",
  },
  {
    name: "Tax Group",
  },
  {
    name: "Tax Item",
  },
  {
    name: "Company",
  },
  {
    name: "State",
  },
  {
    name: "City",
  },
  {
    name: "Locality",
  },
  // {
  //   name: "Invoice"
  // },
  // {
  //   name: "Quotation status"
  // },
  // {
  //   name: "Lead type",
  // },
  // {
  //   name: "Lead status"
  // },
  // {
  //   name: "Activity",
  // },
  {
    name: "Activity Category",
  },
  {
    name: "Activity Sub-Category",
  },
  // {
  //   name: "Move-in Move-out"
  // }
];

export const CountryPath = ["country"];

export const CountryRow = [
  {
    country_name: "India",
    icon: "editviewtoggle",
  },
];

export const Countryheading = (t = () => false) => [
  { title: t("Country"), field: "country_name" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const CountryType = [
  { type: ["text"], name: "country_name" },
  { type: ["status"], name: "status" },
  { type: ["more_3"], icon: "icon" },
];

export const CountryDrawer = (t) => [
  {
    componet_type: "text",
    label: t("Country Name"),
    state_name: "country_name",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

export const CurrencyPath = [
  "country_name",
  "Currency_name",
  "Currency_code",
  "Symbol",
];

export const CurrencyRow = [
  {
    name: "Dollar",
    code: "001",
    Symbol: "$",
    country_name: "America",
    icon: "editviewtoggle",
  },
];

export const CurrencyHeading = (t = () => false) => [
  // { title: "Country", field: "country_name" },
  { title: t("Currency"), field: "name" },
  { title: t("Code"), field: "code" },
  { title: t("Symbol"), field: "symbol" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const CurrencyType = [
  // { type: ["object"], name: "country_name" },
  { type: ["text"], name: "name" },
  { type: ["number"], name: "code" },
  { type: ["text"], name: "symbol" },
  { type: ["status"], name: "status" },
  { type: ["more_3"], icon: "icon" },
];

export const CurrencyDrawer = (t = () => false) => [
  {
    componet_type: "text",
    label: t("Currency"),
    state_name: "name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: t("Code"),
    state_name: "code",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: t("Symbol"),
    state_name: "symbol",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

export const vatGroupPath = [
  "group_name",
  "country_name",
  "tax_group_code",
  "total_rate",
  // "account_type",
  // "account_number",
];

export const vatGroupRow = [
  {
    group_name: "product dev",
    description: "Block D-105",
    country_name: "India",
    icon: "editviewtoggle",
  },
];

export const vatGroupheading = (t = () => false) => [
  { title: t("Tax Group"), field: "group_name" },
  { title: t("Country"), field: "country_name" },
  { title: t("Tax Group Code"), field: "tax_group_code" },
  { title: t("Total Rate"), field: "total_rate" },
  // { title: t("Account Type"), field: "account_type" },
  // { title: t("Account Number"), field: "account_number" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const vatGroupType = [
  { type: ["text"], name: "group_name" },
  { type: ["object"], name: "country_name" },
  { type: ["object"], name: "tax_group_code" },
  { type: ["text"], name: "total_rate" },
  // { type: ["text"], name: "account_type" },
  // { type: ["text"], name: "account_number" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const VATGroupDrawer = (t) => [
  {
    componet_type: "text",
    label: t("Tax Group"),
    state_name: "group_name",
    value: "",
    required: true,
  },
  {
    componet_type: "asyncSelect",
    label: t("Country"),
    state_name: "country_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>
      loadOptionsApis(
        "queries/opportunity/country_master",
        {},
        search,
        array,
        handleLoading,
        "data",
    ),
    debounceTimeout: 800,
  },
  {
    componet_type: "asyncSelect",
    label: t("TAX Group Code"),
    state_name: "tax_group_code",
    value: "",
    required: true,
    loadOptions: (search, array, handleLoading) =>
      loadOptionData(search, array, handleLoading, enum_types.vat_code_type),
    debounceTimeout: 800,
    isPaginate: true,
    options: [],
  },
  {
    componet_type: "text",
    label: t("HSN/SAC Code"),
    placeholder:t("Enter HSN/SAC Code"),
    state_name: "hsn_code",
    value: "",
    // required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

export const vatGroupItemPath = [
  "Vat group",
  "group_name",
  "tax_group_code",
  "rate",
];

export const vatGroupItemRow = [
  {
    group_name: "22-11-21",
    description: "Block D-105",
    Vat_group: "Yes",
    rate: "No",
    icon: "editviewtoggle",
  },
];

export const vatGroupItemheading = (t = () => false) => [
  { title: t("Tax Group"), field: "Vat_group" },
  { title: t("Tax Item"), field: "group_name" },
  { title: t("Tax Group Code"), field: "tax_group_code" },
  { title: t("Rate"), field: "rate" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const vatGroupItemType = [
  { type: ["object"], name: "Vat_group" },
  { type: ["text"], name: "group_name" },
  { type: ["object"], name: "tax_group_code" },
  { type: ["text"], name: "rate" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];
export const manualResponseTax = (array) => {
  return array?.vat_group_master?.map((i) => {
    return {
        ...i,
        label: i?.label,
        value: i?.value
    }
})
}
export const VATGroupItemDrawer = (t = () => false) => [
  {
    componet_type: "asyncSelect",
    label: t("Country"),
    state_name: "country",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) => loadOptionsApis(
      "queries/opportunity/country_master",
      {},
      search,
      array,
      handleLoading,
      "data",
  ),
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['Vat_group']
  },
  {
    componet_type: "asyncSelect",
    label: t("Tax Group"),
    state_name: "Vat_group",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "country",
    loadOptions: (search, array, handleLoading, state) => {
      if (state?.states?.country?.value) {
        return loadOptionsApis(
          "queries/vat_group_master/list",
          {
            "country_id": state?.states?.country?.value,
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponseTax
        )
      }
      handleLoading()
      return { options: [] }
    },
    debounceTimeout: 800
  },
  {
    componet_type: "text",
    label: t("Tax Item"),
    state_name: "group_name",
    value: "",
    required: true,
  },
  {
    componet_type: "asyncSelect",
    label: t("TAX Group Code"),
    state_name: "tax_group_code",
    value: "",
    required: true,
    loadOptions: (search, array, handleLoading) => loadOptionData(search, array, handleLoading, enum_types.vat_item_code_type),
    debounceTimeout: 800,
    isPaginate: true,
    options: [],
  },
  {
    componet_type: "text",
    label: t("Rate"),
    state_name: "rate",
    value: "",
    required: true,
    type: "text",
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

export const CompanyPath = [
  "company_code_name",
  "company_name",
  "city",
  "country_name",
  "currency",
  "company_registration",
  "tax_registration",
  "tax_group",
  "tax_rate"
];

export const CompanyRow = [
  {
    name: "Admin",
    description: "Block D-105",
    icon: "editviewtoggle",
  },
];

export const CompanyHeading = (t = () => false) => [
  { title: "", field: 'icon' },
  { title: t("Logo"), field: "upload_logo" },
  { title: t("Company Code"), field: "company_code" },
  { title: t("Company Name"), field: "company_name" },
  { title: t("City"), field: "city" },
  { title: t("Country"), field: "country_name" },
  { title: t("Currency"), field: "currency" },
  // { title: "Company Registration", field: "company_registration" },
  { title: t("Tax Registration"), field: "tax_registration" },
  { title: t("Tax Group"), field: "tax_group" },
  { title: t("Total Tax Rate"), field: "tax_rate" },
  { title: t("Status"), field: 'status', },
];

export const CompanyType = [
  { type: ["more"], icon: "icon" },
  { type: ["logo"], name: "upload_logo" },
  { type: ["text"], name: "company_code" },
  { type: ["text"], name: "company_name" },
  { type: ["text"], name: "city" },
  { type: ["text"], name: "country_name" },
  { type: ["object"], name: "currency" },
  // { type: ["text"], name: "company_registration" },
  { type: ["text"], name: "tax_registration" },
  { type: ["text"], name: "tax_group" },
  { type: ["text"], name: "tax_rate" },
  { type: ["status"], name: "status" },
];
export const manualCurrencyResponse = (array) =>{
  return array?.currency_master
}
export const CompanyDrawer = (t = () => false) => [
  {
    componet_type: "uploadLogo",
    state_name: "upload_logo",
    value: "",
  },
  {
    componet_type: "text",
    label: t("Company Code"),
    state_name: "company_code",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: t("Company Name"),
    state_name: "name",
    value: "",
    required: true,
  },
  {
    componet_type: "asyncSelect",
    label: t("Country"),
    state_name: "country_name",
    value: "",
    required: false,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>  loadOptionsApis(
      "queries/opportunity/country_master",
      {},
      search,
      array,
      handleLoading,
      "data",
  ),
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['state_name', 'city']
  },
  {
    componet_type: "asyncSelect",
    label: t("State"),
    state_name: "state_name",
    value: "",
    required: false,
    options: [],
    isPaginate: true,
    key: "country_name",
    // loadOptions: (search, array, handleLoading, state) => {
    //   if (state?.states?.country_name?.value) {
    //     return loadOptions(
    //       search,
    //       array,
    //       handleLoading,
    //       GET_STATE_DROPDOWN,
    //       'state_master',
    //       { isActive: true, country_id: state?.states?.country_name?.value },
    //     )
    //   }
    //   handleLoading()
    //   return { options: [] }
    // },
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['city']
  },
  {
    componet_type: "asyncSelect",
    label: t("City"),
    state_name: "city",
    value: "",
    required: false,
    options: [],
    isPaginate: true,
    key: "state_name",
    // loadOptions: (search, array, handleLoading, state) => {
    //   if (state?.states?.state_name?.value) {
    //     return loadOptions(
    //       search,
    //       array,
    //       handleLoading,
    //       GET_CITY_DROPDOWN,
    //       'cities_master',
    //       { isActive: true, state_id: state?.states?.state_name?.value },
    //     )
    //   }
    //   handleLoading()
    //   return { options: [] }
    // },
    debounceTimeout: 800,
  },
  {
    componet_type: "asyncSelect",
    label: t("Currency"),
    state_name: "currency",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>  loadOptionsApis(
      "queries/currency_master/list",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
      manualCurrencyResponse
  ),
    debounceTimeout: 800,
  },
  {
    componet_type: "text",
    label: t("Company Registration"),
    state_name: "company_registration",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: t("Tax Registration"),
    state_name: "tax_registration",
    value: "",
    required: true,
  },
  {
    componet_type: "asyncSelect",
    label: t("Tax Group"),
    state_name: "tax_group",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) => loadOptionsApis(
      "queries/vat_group_master/list",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
      manualResponseTax
  ),
    debounceTimeout: 800,
  },
  {
    componet_type: "text",
    label: t("Description"),
    state_name: "description",
    value: "",
    required: true,
  },

  {
    componet_type: "text",
    label: t("Cash Collection Office"),
    state_name: "cash_collection_office",
    value: "",
    required: true,
  },

  {
    componet_type: "text",
    label: t("Cheque Name"),
    state_name: "cheque_name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: t("Primary Bank Name"),
    state_name: "primary_bank_name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: t("Primary Account No"),
    state_name: "primary_account_no",
    value: "",
    required: true,
  },

  {
    componet_type: "select",
    label: t("Bank Account Type"),
    state_name: "account_type",
    value: "",
    required: true,
    options: [
      {
        label: "IBAN Number",
        value: "IBAN-Number",
      },
      {
        label: "Account Number",
        value: "Account-Number",
      },
    ],
  },
  {
    componet_type: "text",
    label: t("Bank Routing Code"),
    state_name: "bank_routing_code",
    value: "",
    required: true,
  },
  {
    componet_type: "select",
    label: t("Bank Routing Type"),
    state_name: "routing_type",
    value: "",
    required: true,
    options: [
      {
        label: "IFSC",
        value: "IFSC",
      },
      {
        label: "SWIFT",
        value: "SWIFT",
      },
    ],
  },

  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

// State

export const StatePath = ["Country", "Name"];

export const StateRow = [
  {
    state_name: "Admin",
    country_name: "Block D-105",
    icon: "editviewtoggle",
  },
];

export const StateHeading = (t = () => false) => [
  { title: t("Country"), field: "country_name" },
  { title: t("State"), field: "state_name" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const StateType = [
  { type: ["object"], name: "country_name" },
  { type: ["text"], name: "state_name" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const StateDrawer = (t = () => false) => [
  {
    componet_type: "asyncSelect",
    label: t("Country"),
    state_name: "country_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>  loadOptionsApis(
      "queries/opportunity/country_master",
      {},
      search,
      array,
      handleLoading,
      "data",
  ),
    debounceTimeout: 800
  },
  {
    componet_type: "text",
    label: t("Name"),
    state_name: "state_name",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

// cities

export const CitiesPath = ["Country", "State", "Name"];

export const CitiesRow = [
  {
    city: "Admin",
    country_name: "Block D-105",
    state_name: "Block D-105",
    icon: "editviewtoggle",
  },
];

export const CitiesHeading = (t = () => false) => [
  { title: t("Country"), field: "country_name" },
  { title: t("State"), field: "state_name" },
  { title: t("City"), field: "city" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const CitiesType = [
  { type: ["object"], name: "country_name" },
  { type: ["object"], name: "state_name" },
  { type: ["text"], name: "city" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const CitiesDrawer = (t = () => false) => [
  // {
  //   type: "text",
  //   title: "Name",
  // },
  // {
  //   type: "dropdown",
  //   title: "Country",
  // },
  // {
  //   type: "dropdown",
  //   title: "State",
  // },
  // {
  //   type: "switchbox",
  //   title: "Status"
  // },
  {
    componet_type: "asyncSelect",
    label: t("Country"),
    state_name: "country_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>  loadOptionsApis(
      "queries/opportunity/country_master",
      {},
      search,
      array,
      handleLoading,
      "data",
  ),
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['state_name']
  },
  {
    componet_type: "asyncSelect",
    label: t("State"),
    state_name: "state_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "country_name",
    // loadOptions: (search, array, handleLoading, state) => {
    //   if (state?.states?.country_name?.value) {
    //     return loadOptions(
    //       search,
    //       array,
    //       handleLoading,
    //       GET_STATE_DROPDOWN,
    //       'state_master',
    //       { isActive: true, country_id: state?.states?.country_name?.value },
    //     )
    //   }
    //   handleLoading()
    //   return { options: [] }
    // },
    debounceTimeout: 800,
  },
  {
    componet_type: "text",
    label: t("Name"),
    state_name: "city",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

// city area

export const CityAreaPath = ["Country", "State", "City", "Name"];

export const CityAreaRow = [
  {
    area_name: "Admin",
    country_name: "Block D-105",
    city: "chennai",
    description: "Block D-105",
    icon: "editviewtoggle",
  },
];

export const CityAreaHeading = [
  { title: "Country", field: "country_name" },
  { title: "State", field: "state_name" },
  { title: "City", field: "city" },
  { title: "Locality", field: "area_name" },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },
];

export const CityAreaType = [
  { type: ["object"], name: "country_name" },
  { type: ["object"], name: "state_name" },
  { type: ["object"], name: "city" },
  { type: ["text"], name: "area_name" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const CityAreaDrawer = [
  {
    componet_type: "asyncSelect",
    label: "Country",
    state_name: "country_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) =>  loadOptionsApis(
      "queries/opportunity/country_master",
      {},
      search,
      array,
      handleLoading,
      "data",
  ),
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['state_name', 'city']
  },
  {
    componet_type: "asyncSelect",
    label: "State",
    state_name: "state_name",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "country_name",
    // loadOptions: (search, array, handleLoading, state) => {
    //   if (state?.states?.country_name?.value) {
    //     return loadOptions(
    //       search,
    //       array,
    //       handleLoading,
    //       GET_STATE_DROPDOWN,
    //       'state_master',
    //       { isActive: true, country_id: state?.states?.country_name?.value },
    //     )
    //   }
    //   handleLoading()
    //   return { options: [] }
    // },
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['city']
  },
  {
    componet_type: "asyncSelect",
    label: "City",
    state_name: "city",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "state_name",
    // loadOptions: (search, array, handleLoading, state) => {
    //   if (state?.states?.state_name?.value) {
    //     return loadOptions(
    //       search,
    //       array,
    //       handleLoading,
    //       GET_CITY_DROPDOWN,
    //       'cities_master',
    //       { isActive: true, state_id: state?.states?.state_name?.value },
    //     )
    //   }
    //   handleLoading()
    //   return { options: [] }
    // },
    debounceTimeout: 800,
  },
  {
    componet_type: "text",
    label: "Locality",
    state_name: "area_name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: "Description",
    state_name: "description",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// invoice

export const inVoicePath = ["Type"];

export const inVoiceRow = [
  {
    type: "Admin",
    icon: "editviewtoggle",
  },
];

export const inVoiceHeading = [
  { title: "Type", field: "type" },
  { title: "", field: "icon" },
];

export const inVoiceType = [
  { type: ["text"], name: "type" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const inVoiceDrawer = [
  // {
  //   type: "text",
  //   title: "type",
  // },
  // {
  //   type: "switchbox",
  //   title: "Status"
  // },

  {
    componet_type: "text",
    label: "type",
    state_name: "type",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// quotations

export const quotationsPath = ["Status"];

export const quotationsRow = [
  {
    status: "Admin",
    icon: "editviewtoggle",
  },
];

export const quotationsHeading = [
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];

export const quotationsType = [
  { type: ["text"], name: "status" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const quotationsDrawer = [
  // {
  //   type: "text",
  //   title: "Status",
  // },
  // {
  //   type: "switchbox",
  //   title: "Status"
  // },

  {
    componet_type: "text",
    label: "Status",
    state_name: "Status",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// Lead type

export const leadTypePath = ["type"];

export const leadTypeRow = [
  {
    name: "Admin",
    icon: "editviewtoggle",
  },
];

export const leadTypeHeading = [
  { title: "Type", field: "name" },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },
];

export const leadTypeType = [
  { type: ["text"], name: "name" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const leadTypeDrawer = [
  {
    componet_type: "text",
    label: "Type",
    state_name: "name",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// Lead Status

export const leadStatusPath = ["status"];

export const leadStatusRow = [
  {
    status: "Admin",
    icon: "editviewtoggle",
  },
];

export const leadStatusHeading = [
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];

export const leadStatusType = [
  { type: ["text"], name: "status" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const leadStatusDrawer = [
  // {
  //   type: "text",
  //   title: "Status",
  // },
  // {
  //   type: "switchbox",
  //   title: "Status"
  // },

  {
    componet_type: "text",
    label: "Status",
    state_name: "status",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// activity

export const activityPath = ["status"];

export const activityRow = [
  {
    type: "Admin",
    icon: "editviewtoggle",
  },
];

export const activityHeading = (t = () => false) => [
  { title: t("Status"), field: "type" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const activityType = [
  { type: ["text"], name: "type" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const activityDrawer = (t = () => false) => [
  {
    componet_type: "text",
    label: t("Status"),
    state_name: "type",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

// Activity Category

export const activityCategoryPath = ["activity", "category"];

export const activityCategoryRow = [
  {
    activity_category: "Admin",
    type: "Admin",
    icon: "editviewtoggle",
  },
];

export const activityCategoryHeading = (t = () => false) => [
  { title: t("Lead Activity"), field: "type" },
  { title: t("Category"), field: "activity_category" },
  { title: t("Status"), field: 'status', },
  { title: "", field: 'icon' },
];

export const activityCategoryType = [
  { type: ["object"], name: "type" },
  { type: ["text"], name: "activity_category" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];
export const manualLeadCategoryResponse = (array) => {
  return array.lead_activity_master
}
export const activityCategoryDrawer = (t = () => false) => [
  {
    componet_type: "asyncSelect",
    label: t("Lead Activity"),
    state_name: "type",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) => loadOptionsApis(
      "queries/lead_activity_master/get",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
      manualLeadCategoryResponse
  ),
    debounceTimeout: 800
  },

  {
    componet_type: "text",
    label: t("Category"),
    state_name: "activity_category",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: t("Status"),
    state_name: "is_active",
    value: "",
  },
];

// activity sub category

export const activitySubCategoryPath = [
  "lead activity",
  "lead activity category",
  "subcategory",
];

export const activitySubCategoryRow = [
  {
    sub_category: "Admin",
    type: "Admin",
    activity_category: "Admin",
    icon: "editviewtoggle",
  },
];

export const activitySubCategoryHeading = [
  { title: "Lead Activity", field: "type" },
  { title: "Category", field: "activity_category" },
  { title: "Sub-Category", field: "sub_category" },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },
];

export const activitySubCategoryType = [
  { type: ["object"], name: "type" },
  { type: ["object"], name: "activity_category" },
  { type: ["text"], name: "sub_category" },
  { type: ["status"], name: "status" },
  { type: ["more"], icon: "icon" },
];

export const activitySubCategoryDrawer = [
  {
    componet_type: "asyncSelect",
    label: "Lead Activity",
    state_name: "type",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    loadOptions: (search, array, handleLoading) => loadOptionsApis(
      "queries/lead_activity_master/get",
      {},
      search,
      array,
      handleLoading,
      "data",
      {},
      manualLeadCategoryResponse
  ),
    debounceTimeout: 800,
    dependency: true,
    dependencyState: ['activity_category']
  },
  {
    componet_type: "asyncSelect",
    label: "Category",
    state_name: "activity_category",
    value: "",
    required: true,
    options: [],
    isPaginate: true,
    key: "type",
    loadOptions: (search, array, handleLoading, state) => {
      if (state?.states?.type?.value) {
        return loadOptionsApis(
          "queries/activity_category_master/get",
          {
            lead_activity_master_id: state?.states?.type?.value,
            status:[true],
          },
          search,
          array,
          handleLoading,
          "data",
      )
      }
      handleLoading()
      return { options: [] }
    },
    debounceTimeout: 800,
  },
  {
    componet_type: "text",
    label: "Sub-Category",
    state_name: "sub_category",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];

// Move-in Move-out

export const MoveInMoveOutPath = ["Type", "Description", "is Reading"];

export const MoveInMoveOutRow = [
  {
    type: "Admin",
    description: "Admin",
    is_reading: "Admin",
    icon: "editviewtoggle",
  },
];

export const MoveInMoveOutHeading = [
  { title: "Type", field: "type" },
  { title: "Description", field: "description" },
  { title: "is Reading", field: "is_reading" },
  { title: "", field: "icon" },
];

export const MoveInMoveOutType = [
  { type: ["text"], name: "type" },
  { type: ["text"], name: "description" },
  { type: ["text"], name: "is_reading" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const MoveInMoveOutDrawer = [
  {
    componet_type: "text",
    label: "Type",
    state_name: "Type",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: "Description",
    state_name: "Description",
    value: "",
    required: true,
  },

  {
    componet_type: "switch",
    label: "is Reading",
    state_name: "is Reading",
    value: "",
  },

  {
    componet_type: "switch",
    label: "Status",
    state_name: "is_active",
    value: "",
  },
];
