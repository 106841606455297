import React from "react"

export const LeadLostIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_107673" data-name="Group 107673" transform="translate(-121 -503)">
          <circle id="Ellipse_39517" data-name="Ellipse 39517" cx="20" cy="20" r="20" transform="translate(121 503)" fill="#ffe0e0"/>
          <g id="Group_105494" data-name="Group 105494" transform="translate(21624 -4302)">
            <rect id="Rectangle_55517" data-name="Rectangle 55517" width="20" height="20" transform="translate(-21493 4815)" fill="none"/>
            <path id="icons8-loss" d="M11.95,3A7.074,7.074,0,0,0,4.967,9.386L3.247,11.535a1.145,1.145,0,0,0,.515,1.8l1.14.4V15.7a1.712,1.712,0,0,0,1.173,1.626l2.126.709a.19.19,0,0,1,.13.181v.213A.572.572,0,0,0,8.9,19h6.476a.572.572,0,0,0,.571-.571V17.274a2.383,2.383,0,0,1,.781-1.726A7.339,7.339,0,0,0,19,10.238,7.153,7.153,0,0,0,11.95,3ZM15,10.619H8.9a.571.571,0,1,1,0-1.143H15a.571.571,0,1,1,0,1.143Z" transform="translate(-21494.996 4814)" fill="#fd5f5f"/>
          </g>
        </g>
      </svg>
      

    )
}