
import { Box } from "@mui/material";
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, Subheader } from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../utils";
import { Address, BankingDetails, KYCDetails, Profile } from "./sections";
import { useStyles } from "./styles";
import { withNamespaces } from "react-i18next";
import { RegisteredDetails } from "./sections/registeredDetails";
import { IdDetails } from "./sections/idDetails";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
const AccountDetails = ({ accid = null, is_edit = true, t }) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    // const searchURL = useLocation().search;
    // const acc_id = new URLSearchParams(searchURL).get("id");
    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const history = useHistory()
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const { state } = useLocation()

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getDetails()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getDetails = () => {

        const payload = {
            id: accid === null ? state?.id : accid
        }
        NetworkCall(
            `${config.api_url}/account/get_detail`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            setData(res?.data?.data?.[0])
            setLoading(false)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
            setLoading(false)
        })
    }

    const typeSelect = {
        "Vendor": Routes.vendorAccountCreate,
        "Property Owner": Routes.ownerAccountCreate,
        "Customer": Routes.customerAccountCreate,
        "AllAccount": Routes.createAccount,
        "cashBank": Routes.cashBankAccountCreate
    }



    const handleEdit = () => {
        // state?.type === "Vendor" ?
        // history.push(Routes.createAccount + "?id=" + state?.id)
        history.push(
            {
                pathname: state?.type?.length > 0 ? typeSelect[state?.type] : Routes.createAccount,
                state: { type: state?.type, id: state?.id, viewtype: "edit" }
            }
        )
    }
    const render = () => {
        return <>
            <Subheader
                title={data?.company?.company_name}
                goBack={() => history.goBack(-1)}
                isEdit={is_edit ? permission.update : false}
                handleEdit={handleEdit}
                t={t}
            />
            {
                loading ?
                    <LoadingSection bottom={"45vh"} message="Fetching ..." />
                    :
                    <Box className={classes.root}>

                        {/*profile section */}
                        <Profile t={t} data={data} />
                        {/* Registered Details */}
                        <RegisteredDetails t={t} data={data} />
                        {/*address section */}
                        <Address t={t} data={data} />
                        {/*BankingDetails section */}
                        <BankingDetails t={t} data={data} />
                        {/*KYCDetails*/}
                        <KYCDetails t={t} data={data} />
                        {/* ID Details */}
                        <IdDetails t={t} data={data} />
                    </Box>
            }
        </>
    }
    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withNamespaces("accountDetails")(AccountDetails)
