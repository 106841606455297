import React, { useContext } from "react";
import { AlertDialog, TableWithPagination } from "../../../components";
import { UnitHeading, UnitPath, leadtransfer } from "../common";
import { Box, Fab, Grid, Popover, SpeedDialIcon, Typography } from "@mui/material";
import { LeadStylesParent } from "./style";
import { UnitSelectionLead } from "../../addOppertunity/component/unitselectlead";
import { getDateValue } from "../../propertyFinder4/utils";
import { AlertProps } from "../../../utils";
import { AlertContext } from "../../../contexts";


export const UnitTable = (props) => {
  const classes = LeadStylesParent(props);
  const { t = () => false } = (props);
  const alert = useContext(AlertContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const language = localStorage.getItem("i18nextLng");
  const [dataNew , setDataNew]= React.useState({
    lead_owner:null,
    property_id:null,
    selectedUnit:[],
    fixedUnit:[]
  });
  const [state, setState] = React.useState({
    bool: false
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateState = (k, v) => {
    setState({ ...state, [k]: v })
  }

  const onChangeUnits = (unit, owner, type) => {
    setDataNew({
      ...dataNew,
      selectedUnit: unit ?? [],
      lead_owner: null,
      // type === 1 ? owner : null,
      property_id: owner
      // type === 2 ? owner : null,
    });
  };

  const addUnit = (unit , setChooseOwner , handleClose) => {
    let arr2 = dataNew?.fixedUnit?.map((x) => x);
    let arr = unit?.filter((val) => val?.is_active === true)?.map((x) => x);

    let addedUnit = arr.filter(item => !arr2.map(i => i.id).includes(item?.id));
    let deleted = dataNew?.fixedUnit.map((val) => !unit.map((x) => x?.id).includes(val?.id) ? val?.id : false).filter(Boolean)
 
    if(!addedUnit?.length > 0){
       return  alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please select a unit"),
      });
    }
    props?.updateLead({
      units: addedUnit?.length > 0 ? addedUnit : undefined,
      remove_units: deleted?.length > 0 ? deleted : undefined,
      is_reload: true,
      msg: t("Unit Created Successfully")
    })
    setChooseOwner(false)
    handleClose()
  }


  React.useEffect(() => {
    const unit_id = props?.details?.proxy_lead_units?.map((x) => {
      return {
        id: x?.unitByID?.id ?? "",
        logo: x?.unitByID?.logo ?? "",
        name: x?.unitByID?.unit_name ?? "",
        property_id: x?.unitByID?.propertyByID?.id ?? "",
        property_name: x?.unitByID?.propertyByID?.name ?? "",
        unit_no: x?.unitByID?.unit_no ?? "",
        is_active: true
      };
    })
    setDataNew({
      ...dataNew,
      selectedUnit: unit_id ?? [],
      fixedUnit: unit_id ?? [],
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.details])

  const handleIcon = (type, data) => {
    if (type === 'delete') {
      console.log(data, 'data')
      props?.updateLead({
        remove_units: data?.lead_id?.length > 0 ? [data?.lead_id] : undefined,
        is_reload: true,
        msg: t("Unit Updated Successfully")
      })
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const heading = UnitHeading(t);

  return (
    <div>
      <TableWithPagination
        heading={heading}
        marginTop={'1px'}
        rows={props?.unitList?.data ?? []}
        path={UnitPath}
        showpagination={true}
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        handlePagination={props?.handlePagination}
        handleChangeLimit={props?.handleChangeLimit}
        handleIcon={handleIcon}

        page={props?.page}
        limit={props?.limit}
        totalRowsCount={props?.unitList?.count}
        tableType="no-side"
        count="2"
        dataType={[
          { type: ["more_3"], name: "icon" },
          { type: ["avatarmanagement"], name: "image" },
          { type: ["text"], name: "propertyname" },
          { type: ["text"], name: "name" },
          { type: ["text"], name: "unit_no" },
          { type: ["text"], name: "type" },

        ]}
        height={"calc(100vh - 298px)"}
        view={true}
        delete={props?.Status}
        edit={false}
        noDataSvg />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.menuList}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{ width: '257px' }}>
          <Grid container>
            {
              leadtransfer?.map((x, index) => {
                return (
                  <Grid item sm={12} md={12} lg={12}>
                    <Box
                      className={classes.leadtransfersbox1}
                      onClick={() => index === 1 ? updateState('bool', true) : false}

                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {x?.image}
                        <Typography
                          className={classes.options}
                          style={{ marginInlineStart: "10px" }}
                        >
                          {x?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <img src="/images/leadarrow.svg" alt="" />
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            }
          </Grid>

        </Box>
      </Popover>


      {/* unit creation */}
      <AlertDialog
        open={state?.bool}
        isNormal
        isnotTitle
        onClose={() => updateState('bool', false)}
        component={
          <UnitSelectionLead
            datas={{ ...dataNew, company: props?.selectedCompany, units: dataNew?.selectedUnit, ...props?.details, revenue_type: props?.details?.revenue_type }}
            onChange={onChangeUnits}
            value={dataNew?.selectedUnit}
            t={t}
            isEdit={true}
            company={props?.selectedCompany}
            setDataProps={setDataNew}
            dataProps={dataNew}
            handleClose={() => updateState('bool', false)}
            addUnit={addUnit}
            typeData={props?.details?.revenue_type}
            purpose={props?.details?.purpose}
            end_date={
              getDateValue(
                {
                  date: props?.details?.lease_start_date ?? "",
                  input: props?.details?.value ?? "",
                  period: props?.details?.lease_period ?? "",
                },
                true,
                0,
                true
              )
            }
          />
        }
      />


      {
        props?.Status && (
          <Fab
            className={classes.fab}
            size="large"
            color="primary"
            onClick={handleClick}
            aria-describedby={id}
          >
            <SpeedDialIcon />
          </Fab>
        )
      }

      {props?.Status && (
        <Fab
          className={language === "ar" ? classes.fabArabic : classes.fab}
          size="large"
          color="primary"
          onClick={()=>updateState('bool', true)}
          aria-describedby={id}
        >
          <SpeedDialIcon />
        </Fab>
      )}
    </div>
  );
};
