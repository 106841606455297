import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import { useHistory, useLocation } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { AlertDialog, Subheader } from "../../components";
import { BackdropContext } from '../../contexts';
import { useStyles } from './style';
// import MyGoogleMap from '../../components/interactivemap/myGoogleMaps';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { DocumentViewer } from '../../components/fileViewer';
import { ProofView } from '../../components/proofComponent/section/proofView';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, useWindowDimensions } from '../../utils';

const DomesticHelperView = (props) => {
    const { t } = (props)

    const modules = { toolbar: false }

    const history = useHistory()
    const size = useWindowDimensions()
    const classes = useStyles(size)
    const search = useLocation().search
    const domesticHelperID = new URLSearchParams(search).get("domesticHelperID")

    // useContext
    const backdrop = React.useContext(BackdropContext)

    // useState
    const [data, setData] = React.useState({})
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("")

    //view document function and state

    const [openPdf, setOpenPdf] = React.useState(false)
    const [currentImageDoc, setCurrentImageDoc] = React.useState("");
    const [currentImageType, setCurrentImageType] = React.useState("");
    const setOpenPdfBtn = (data) => {
        setOpenPdf(true)
        setCurrentImageDoc(data?.[0])
        setCurrentImageType(data?.[1])
    }

    // Function to open ImageViewer
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index)
        setIsViewerOpen(true)
    }, [])
    // Function to close ImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }
    // Function to get domestic helper details
    const getDetails = () => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        const payload = {
            id: domesticHelperID
        }
        NetworkCall(
            `${config.api_url}/queries/domestic_helper_master/get_by_id`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setData(r?.data?.data?.domestic_helper_master?.[0])
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        })
    }

    // useEffect to load data in the beginning
    React.useEffect(() => {
        if (domesticHelperID) { getDetails() } else { history.goBack(-1) }
        //eslint-disable-next-line
    }, [])

    // Google Map
    // const GoogleMap = React.useCallback(() => {
    //     return (<MyGoogleMap
    //         lat={data?.domestic_helper_addressByID?.[0]?.latitude}
    //         lng={data?.domestic_helper_addressByID?.[0]?.longitude}
    //         center={{
    //             lat: data?.domestic_helper_addressByID?.[0]?.latitude,
    //             lng: data?.domestic_helper_addressByID?.[0]?.longitude
    //         }}
    //         zoom={13}
    //         readonly />)
    // }, [data])

    // Function to handle edit button
    const handleEdit = () => {
        history.push({
            pathname: Routes.domesticHelperCreateEdit,
            state: {
                main: {
                    company: data?.companyByID,
                    companyID: data?.companyByID?.id,
                    domesticHelperID,
                }
            }
        })
    }

    return <div>
        <Subheader title={t("Registered Workers")} goBack={() => history.goBack(-1)}
            isEdit handleEdit={handleEdit} />
        <Grid container className={classes.content}>
            <Grid container className={classes.profileDetailCard}>
                <Grid item md={2} lg={2} className={classes.profileImageDiv}>
                    <img src={data?.helper_image ?? "/images/imagesproperty.svg"} alt="" className={classes.image}
                        onClick={() => openImageViewer([data?.helper_image])} />
                    {isViewerOpen && (<ImageViewer
                        src={currentImage}
                        currentIndex={0}
                        onClose={closeImageViewer}
                        disableScroll={false}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)",
                        }}
                        closeOnClickOutside={true} />)}
                </Grid>
                <Grid item md={10} lg={10} className={classes.profileDetailDiv}>
                    <Grid container rowGap={"8px"}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{t("Profile Details")}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                <Typography className={classes.sub}>{data?.companyByID?.name ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Property Name")}</Typography>
                                <Typography className={classes.sub}>{data?.propertyByID?.name ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Name")}</Typography>
                                <Typography className={classes.sub}>{data?.name ?? "-"}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Gender")}</Typography>
                                <Typography className={classes.sub}>{data?.gender ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Nationality")}</Typography>
                                <Typography className={classes.sub}>{data?.nationality ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Profession")}</Typography>
                                <Typography className={classes.sub}>{data?.profession_masterByID?.name ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>ID Type</Typography>
                                <Typography className={classes.sub}>{data?.identification_masterByID?.type ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>ID Number</Typography>
                                <Typography className={classes.sub}>{data?.identification_no ?? "-"}</Typography>
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Barred")}</Typography>
                                <Typography className={classes.sub}>{data?.is_barred === true ? 'Yes' : "No"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginTop="14px">
                                <Typography className={classes.heading}>{t("Description")}</Typography>
                                {data?.description ?
                                    <ReactQuill
                                        readOnly
                                        theme="bubble"
                                        value={data?.description ?? ""}
                                        modules={modules}
                                        className={classes.sub} /> :
                                    <Typography className={classes.sub}>{"-"}</Typography>}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <Grid container className={classes.card}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={5}>
                        <div className={classes.mapDiv}>
                            <GoogleMap />
                        </div>
                    </Grid> */}
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={3}> */}
                    <Grid item xs={6}>
                        <div className={classes.addressDiv}>
                            <Stack direction="row" >
                                <img src='/images/loc.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Typography className={classes.sub}>
                                {data?.domestic_helper_addressByID?.[0]?.door_no ? <span>{data?.domestic_helper_addressByID?.[0]?.door_no + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.street_1 ? <span>{data?.domestic_helper_addressByID?.[0]?.street_1 + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.street_2 ? <span>{data?.domestic_helper_addressByID?.[0]?.street_2 + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.landmark ? <span>{data?.domestic_helper_addressByID?.[0]?.landmark + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.area ? <span>{data?.domestic_helper_addressByID?.[0]?.area + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.city ? <span>{data?.domestic_helper_addressByID?.[0]?.city + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.state ? <span>{data?.domestic_helper_addressByID?.[0]?.state + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.country ? <span>{data?.domestic_helper_addressByID?.[0]?.country + ","}&nbsp;</span> : ""}
                                {data?.domestic_helper_addressByID?.[0]?.zipcode ? data?.domestic_helper_addressByID?.[0]?.zipcode : ""}
                            </Typography>
                            {/* <Box height="16px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                <Typography className={classes.sub1}>
                                    &nbsp;{data?.domestic_helper_addressByID?.[0]?.latitude}
                                </Typography>
                            </Stack>
                            <Box height="12px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                <Typography className={classes.sub1}>
                                    &nbsp;{data?.domestic_helper_addressByID?.[0]?.longitude}
                                </Typography>
                            </Stack> */}
                        </div>
                    </Grid>
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={4}> */}
                    <Grid item xs={6}>
                        <div className={classes.contactDiv}>
                            <Stack direction="row" >
                                <img src='/images/Group 7015.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box marginTop="18px">
                                        <Typography className={classes.heading}>{t("Primary Mobile")} : </Typography>
                                        <Typography className={classes.sub}>
                                            {data?.domestic_helper_addressByID?.[0]?.mobile_no ?
                                                (data?.domestic_helper_addressByID?.[0]?.mobile_code +
                                                    (data?.domestic_helper_addressByID?.[0]?.mobile_code ? " " : "") +
                                                    data?.domestic_helper_addressByID?.[0]?.mobile_no) : ""}
                                        </Typography>
                                    </Box>
                                    <Box marginTop="12px">
                                        <Typography className={classes.heading}>{t("Email Address")} : </Typography>
                                        <Typography className={classes.subEmail}>
                                            {data?.domestic_helper_addressByID?.[0]?.email_id}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box marginTop="18px">
                                        <Typography className={classes.heading}>{t("Primary Telephone")} : </Typography>
                                        <Typography className={classes.sub}>
                                            {data?.domestic_helper_addressByID?.[0]?.telephone_no ?
                                                (data?.domestic_helper_addressByID?.[0]?.telephone_code +
                                                    (data?.domestic_helper_addressByID?.[0]?.telephone_code ? " " : "") +
                                                    data?.domestic_helper_addressByID?.[0]?.telephone_no) : ""}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid container className={classes.card}>
                <Typography className={classes.title}>Banking Basic Details</Typography>
                <Grid item md={12}>
                    <Grid container marginTop={"8px"}>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Bank Name</Typography>
                            <Typography className={classes.sub}>{data?.bank_name ? data?.bank_name : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Branch Name</Typography>
                            <Typography className={classes.sub}>{data?.branch_name ? data?.branch_name : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Currency</Typography>
                            <Typography className={classes.sub}>
                                {data?.currency_masterByID?.code ? data?.currency_masterByID?.code : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Bank Account Type</Typography>
                            <Typography className={classes.sub}>{data?.account_type ? data?.account_type : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Account Number</Typography>
                            <Typography className={classes.sub}>{data?.account_no ? data?.account_no : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Bank Routing Type</Typography>
                            <Typography className={classes.sub}>{data?.routing_type ? data?.routing_type : "-"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container marginTop={"8px"}>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Bank Routing Code</Typography>
                            <Typography className={classes.sub}>{data?.routing_code ? data?.routing_code : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Preferred Cash Collection Office</Typography>
                            <Typography className={classes.sub}>
                                {data?.cash_collection_office ? data?.cash_collection_office : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Cheque Name</Typography>
                            <Typography className={classes.sub}>{data?.cheque_name ? data?.cheque_name : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Address Line 1</Typography>
                            <Typography className={classes.sub}>{data?.address_1 ? data?.address_1 : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Address Line 2</Typography>
                            <Typography className={classes.sub}>{data?.address_2 ? data?.address_2 : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>City</Typography>
                            <Typography className={classes.sub}>{data?.bank_city ? data?.bank_city : "-"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container marginTop={"8px"}>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Country</Typography>
                            <Typography className={classes.sub}>{data?.bank_country ? data?.bank_country : "-"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.card}>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Facebook</Typography>
                            <Typography className={classes.sub}>{data?.facebook ? data?.facebook : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Twitter</Typography>
                            <Typography className={classes.sub}>{data?.twitter ? data?.twitter : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Linkedin</Typography>
                            <Typography className={classes.sub}>{data?.linkedin ? data?.linkedin : "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Whatsapp</Typography>
                            <Typography className={classes.sub}>{data?.whatsapp ? data?.whatsapp : "-"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            {data?.proof?.length > 0 &&
                <Grid container className={classes.card}>
                    <Typography className={classes.title}>{t("PROOF DETAILS")}</Typography>
                    {
                        data?.proof?.map((e) => {
                            return (
                                <Grid item md={12}>
                                    <Box pt={2} pb={2}>
                                        <Typography className={classes.title}>{e?.identification_master?.type}</Typography>
                                    </Box>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography className={classes.heading}>{t("Name As In Proof")}</Typography>
                                            <Typography className={classes.sub}>{e?.name ?? "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.heading}>{t("ID Proof Number")}</Typography>
                                            <Typography className={classes.sub}>{e?.id_no ?? "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.heading}>{t("Valid From")}</Typography>
                                            <Typography className={classes.sub}>{e?.valid_from ? moment(e?.valid_from).format("YYYY-MM-DD") : "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.heading}>{t("Valid To")}</Typography>
                                            <Typography className={classes.sub}>{e?.valid_to ? moment(e?.valid_to).format("YYYY-MM-DD") : "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.heading}>{t("Issuing Country")}</Typography>
                                            <Typography className={classes.sub}>{e?.country_master?.label ?? "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.heading}>{t("Mandatory Verify")}</Typography>
                                            <Typography className={classes.sub}>{e?.is_mandatory === true ? 'Yes' : "False"}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>

                                                {
                                                    e?.url?.length > 0 &&
                                                    e?.url?.map((val) => {
                                                        return (
                                                            <Grid item xs={3}>
                                                                <ProofView
                                                                    disable={true}
                                                                    name={val?.name}
                                                                    src={val?.src}
                                                                    fileType={val?.type}
                                                                    setOpenPdfBtn={setOpenPdfBtn}
                                                                    t={t}

                                                                />
                                                            </Grid>
                                                        )

                                                    })
                                                }
                                            </Grid>

                                            <AlertDialog isNormal isnotTitle component={
                                                currentImageType === "img" ?
                                                    <div style={{ display: 'flex' }}>
                                                        <img src={currentImageDoc} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                                                    </div>
                                                    :
                                                    <div>
                                                        <DocumentViewer url={currentImageDoc} />
                                                    </div>

                                            } open={openPdf} onClose={() => setOpenPdf(false)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
        </Grid>
    </div>
}
export default withNamespaces("domesticHelper")(DomesticHelperView)
