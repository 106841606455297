import * as React from "react"
const TaskBasedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <defs>
      <linearGradient
        id="a"
        x2={1}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0f5695" />
        <stop offset={0.963} stopColor="#134575" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={0.96}
        y2={-0.007}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#0a85d9" />
      </linearGradient>
    </defs>
    <g data-name="Group 121866">
      <g data-name="icons8-project (3)">
        <path
          fill="#184f85"
          d="M21.394 4a.8.8 0 0 0-.8.8v1.6h1.6V4.8a.8.8 0 0 0-.8-.8Z"
          data-name="Path 102127"
        />
        <path
          fill="url(#a)"
          d="M27.4 37.8a.8.8 0 0 1-.783-.638L22.6 17.749l-4.017 19.413a.8.8 0 0 1-1.567-.324l4.8-23.2a.8.8 0 0 1 1.567 0l4.8 23.2a.8.8 0 0 1-.622.946.86.86 0 0 1-.161.016Z"
          data-name="Path 102128"
          transform="translate(-1.205 -1.8)"
        />
        <path
          fill="#0078d4"
          d="M31.794 5.6h-20.8a.8.8 0 0 0-.8.8v23.2a.8.8 0 0 0 .8.8h20.8a.8.8 0 0 0 .8-.8V6.4a.8.8 0 0 0-.8-.8Z"
          data-name="Path 102129"
        />
        <path
          fill="url(#b)"
          d="M8.685 7C8.518 18.318 4.9 25.028 3.579 27.1a.454.454 0 0 0 .386.7h21.176a.8.8 0 0 0 .62-.294C26.841 26.2 30.934 20.35 31.094 7Z"
          data-name="Path 102130"
          transform="translate(1.493 -.6)"
        />
        <path
          fill="#50e6ff"
          d="M32.594 5.6h-22.4a.8.8 0 0 0 0 1.6h22.4a.8.8 0 0 0 0-1.6Z"
          data-name="Path 102131"
        />
      </g>
    </g>
  </svg>
)
export default TaskBasedIcon
