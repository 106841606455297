import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import ImageViewer from "react-simple-image-viewer";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { unitStyles } from "../style";
import { concat_string, convertTimeUtcToZoneCalander, LocalStorageKeys, rich_text_replacer_condition, stringAvatar, textReplacer, timeZoneConverter } from "../../../utils";
import { useState } from 'react';
import { returnValue } from '../../companyCreation/utils';

const CustomtoolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "white",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        padding: "12px",
        color: "#4E5A6B",
        border: "1px solid #E4E8EE"
    },
}));

export const UnitDetail = (props) => {
    const localStorageCompany = localStorage?.getItem?.(LocalStorageKeys?.selectedCompany);
    const currency_code = localStorageCompany ? JSON?.parse?.(localStorageCompany)?.code : "";
    const { t } = (props)
    const [isSeeMore, setIsSeeMore] = useState(true)
    const classes = unitStyles({ ...props, isSeeMore })
    const size = useWindowDimensions()
    const seeMoreDivRef = React.useRef(null);
    const [seeMoreHeight, setSeeMoreHeight] = React.useState(null)
    const buildupAreades = t("The area of an apartment that can be covered by a carpet or the net usable area is known as the carpet area. It is the distance between the inner walls. The carpet area would include the areas of the bedroom, living room, kitchen, bathrooms, balconies & staircases within the house/flat. It does not include the external and internal walls, terraces, common areas, lifts, corridors, utility ducts, etc")
    const superbuilup = t("Super Built-up area is referred to as the ‘saleable area’ and homebuyers are usually charged based on this value. It is the sum of the exclusive built-up area, and common areas including the lift, corridors, staircase, clubhouse, and other amenities.")
    const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          era: 'short',
          calendar: 'islamic-umalqura' // Specify the Hijri calendar
    };

    const hijriFormatter = new Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', options);

    React.useEffect(() => {
        setSeeMoreHeight(seeMoreDivRef?.current?.clientHeight ?? 0)
    }, [seeMoreDivRef?.current?.clientHeight, props]);
    return (
        <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
            <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                <Grid item md={2} lg={2}>
                    <div className={classes.imgDiv}>
                        <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                            onClick={() => props?.openImageViewer([props?.assets?.[0]?.url])}

                        />
                        <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.unit_no}&nbsp;&nbsp;</Typography>
                    </div>
                </Grid>
                <Grid item md={10} lg={10} className={classes.imgDiv2}>
                    <Typography className={classes.title}>{t("UNIT DETAILS")}</Typography>
                    <Grid container>
                        <Grid item md={12} lg={9}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                <Box>
                                    <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.property?.company?.name ?? "-"}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Unit Name")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.name ?? "-"}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Execution Status")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.execution_status ?? "-"}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Status")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In Active"}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Source")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.is_external ? t("External") : t("Internal")}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {props?.data?.description
                        ? <>
                            <Grid container marginTop="14px">
                                <Grid item>
                                    <Typography className={classes.heading}>Unit Description</Typography>
                                </Grid>
                            </Grid>
                            <Grid ref={seeMoreDivRef} container className={classes.descriptionDiv}>
                                <Grid item>
                                    {props?.data?.description !== "<p><br></p>"
                                        ? <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: returnValue(textReplacer({
                                                    text: props?.data?.description,
                                                    condition: rich_text_replacer_condition,
                                                }), "-")
                                            }} />
                                        : <Typography className={classes.sub}>{"-"}</Typography>}
                                </Grid>
                            </Grid>
                            {seeMoreHeight > 199 &&
                                <div
                                    className={classes.seeMore}
                                    onClick={() => setIsSeeMore(!isSeeMore)}>
                                    {isSeeMore ? "See More" : "See Less"}
                                </div>
                            }
                        </>
                        : <Typography className={classes.sub}>{"-"}</Typography>
                    }
                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <br />
            <Grid container className={classes.card}>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Property Type")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.property?.property_groupByID?.group_name ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Revenue Type")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.revenue_type ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Purpose")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.unit_purpose ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Payment Period")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.payment_period ?? "-"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container className={classes.card}>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Seat Count")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.is_seat ? props?.data?.seat_count : "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Parent Unit")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.parent_unit?.name ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Year Built")}</Typography>
                                <Typography className={classes.sub}>
                                    &#x202a;{props?.data?.year_built ? convertTimeUtcToZoneCalander(props?.data?.year_built) : "-"}&#x202c;
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Handover Date")}</Typography>
                                <Typography className={classes.sub}>
                                    &#x202a;{props?.data?.planned_hand_over_date ? convertTimeUtcToZoneCalander(props?.data?.planned_hand_over_date) : "-"}&#x202c;
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Orientation")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.orientation ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Pets Allowed")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.is_pet_allowed ? t("Yes") : t("No")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Unit Category")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.unit_categoryByID?.label ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>
                                    {"Super Built-Up Area"}
                                    <CustomtoolTip title={`${superbuilup}`}>
                                        <InfoOutlinedIcon sx={{ fontSize: "1rem", marginLeft: "4px" }} />
                                    </CustomtoolTip>
                                </Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.total_area ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>
                                        {props?.data?.uomByID?.label ?? ""}
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>
                                    {"Carpet Area"}
                                    <CustomtoolTip title={`${buildupAreades}`}>
                                        <InfoOutlinedIcon sx={{ fontSize: "1rem", marginLeft: "4px" }} />
                                    </CustomtoolTip>
                                </Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.carpet_area ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>
                                        {props?.data?.uomByID?.label ?? ""}
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Total Balcony Size")}</Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.balcony_area ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>
                                        {props?.data?.uomByID?.label ?? ""}
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Unit Type")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.unit_type?.label ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Measurement Unit")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.uomByID?.label ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Furnishing Type")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.furnishing ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Rooms")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.total_bed_rooms ?? "0"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Baths")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.total_baths ?? "0"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Balconies")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.balconies ?? 0}</Typography>
                            </Box>
                        </Grid>
                        {props?.data?.is_pet_allowed && <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Pet Policy")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.pet_policy ?? "-"}</Typography>
                            </Box>
                        </Grid>}
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Title Deed")}</Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.title_deed ?? "-"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Title Deed Date")}</Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.deed_date ? convertTimeUtcToZoneCalander(props?.data?.deed_date) : "-"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Hijri Calendar Date")}</Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.hijri_date ? hijriFormatter.format(new Date(props?.data?.hijri_date)) : "-"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Documented Land Area")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.whole_land_area ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.whole_land_area &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Market Price")}</Typography>
                                <Typography className={classes.sub}>
                                    <span className={classes.matric} style={{ marginInlineEnd: "4px"}}>{props?.data?.market_price &&
                                        (currency_code ?? "")}</span>
                                    {props?.data?.market_price ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Living Room Size")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.living_room_size ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.living_room_size &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Master Room Size")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.master_bedroom_size ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.master_bedroom_size &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Guest Room Size")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.guest_room_size ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.guest_room_size &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Children Room Size")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.children_room_size ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.children_room_size &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Kitchen Room Size")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.kitchen_size ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.kitchen_size &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Servant Room Size")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.servant_room_size ?? "-"}
                                    <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.servant_room_size &&
                                        (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <div className={classes.card}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className={classes.addressRoot} style={{height: "100%"}}>
                            <Stack direction="row" >
                                <img src='/images/loc.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Typography className={classes.sub}>
                                {concat_string(
                                    {
                                        door_no: props?.data?.addressByID?.door_no ?? "",
                                        street_1: props?.data?.addressByID?.street_1 ?? "",
                                        street_2: props?.data?.addressByID?.street_2 ?? "",
                                        landmark: props?.data?.addressByID?.landmark ?? "",
                                        area: props?.data?.addressByID?.area ?? "",
                                        city: props?.data?.addressByID?.city ?? "",
                                        state: props?.data?.addressByID?.state ?? "",
                                        country: props?.data?.addressByID?.country ?? "",
                                        zipcode: props?.data?.addressByID?.zipcode ?? "",
                                    },
                                    [
                                        "door_no", "street_1", "street_2",
                                        "landmark", "area", "city", "state",
                                        "country", "zipcode",
                                    ]
                                )}
                            </Typography>
                            <Box height="16px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.addressByID?.latitude}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.addressByID?.longitude}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Smart Geo Address")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.smart_geo_address}</Typography>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.addressRoot} style={{height: "100%"}}>
                            <Stack direction="row" >
                                <img src='/images/Group 7015.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box marginTop="18px">
                                        <Typography className={classes.heading}>{t("Business Phone")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.business_phone_country_code} {props?.data?.property?.business_phone}</Typography>
                                    </Box>
                                    <Box marginTop="12px">
                                        <Typography className={classes.heading}>{t("Website")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.website}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box marginTop="18px">
                                        <Typography className={classes.heading}>{t("Mobile Phone")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.mobile_country_code} {props?.data?.property?.mobile_phone}</Typography>
                                    </Box>
                                    <Box marginTop="12px">
                                        <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.email}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <br />
            {props?.data?.registration_officeByID?.id &&
                <>
                    <Grid container className={classes.card} alignItems={"center"} style={{ padding: "16px 16px 16px 0px", }}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                            <div className={classes.imgDiv}>
                                <Avatar src={props?.data?.registration_officeByID?.image_url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                    onClick={() => props?.openImageViewer([props?.data?.registration_officeByID?.image_url])}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                            <Typography className={classes.title}>{t("Registration Office")}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Vendor ID")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.registration_officeByID?.vendor_no ? props?.data?.registration_officeByID?.vendor_no : " - "}</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Office Name")}</Typography>
                                    <Typography className={classes.sub}>
                                        {concat_string(
                                            {
                                                name: props?.data?.registration_officeByID?.name,
                                                code: props?.data?.registration_officeByID?.vendor_code,
                                            },
                                            ["name", "code"],
                                            " - ",
                                            "-"
                                        )}</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Mobile No")}</Typography>
                                    <Typography className={classes.sub}>
                                        {concat_string(
                                            {
                                                mobile_code: props?.data?.registration_officeByID?.vendor_address?.[0]?.mobile_code,
                                                mobile_no: props?.data?.registration_officeByID?.vendor_address?.[0]?.mobile_no,
                                            },
                                            ["mobile_code", "mobile_no"],
                                            " - ",
                                            "-"
                                        )}
                                    </Typography></Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Email")}</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.registration_officeByID?.vendor_address?.[0]?.email_id ? props?.data?.registration_officeByID?.vendor_address?.[0]?.email_id : "-"}
                                    </Typography></Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Website")}</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.registration_officeByID?.vendor_address?.[0]?.website ? props?.data?.registration_officeByID?.vendor_address?.[0]?.website : "-"}
                                    </Typography></Grid>
                                <Grid item xs={12} md={9} lg={9}>
                                    <Typography className={classes.heading}>{t("Address")}</Typography>
                                    <Typography className={classes.sub}>
                                        {concat_string(
                                            {
                                                door_no: props?.data?.registration_officeByID?.vendor_address?.[0]?.door_no,
                                                street_1: props?.data?.registration_officeByID?.vendor_address?.[0]?.street_1,
                                                street_2: props?.data?.registration_officeByID?.vendor_address?.[0]?.street_2,
                                                landmark: props?.data?.registration_officeByID?.vendor_address?.[0]?.landmark,
                                                area: props?.data?.registration_officeByID?.vendor_address?.[0]?.area,
                                                city: props?.data?.registration_officeByID?.vendor_address?.[0]?.city,
                                                state: props?.data?.registration_officeByID?.vendor_address?.[0]?.state,
                                                country: props?.data?.registration_officeByID?.vendor_address?.[0]?.country,
                                                pincode: props?.data?.registration_officeByID?.vendor_address?.[0]?.pincode,
                                            },
                                            [
                                                "door_no", "street_1", "street_2",
                                                "landmark", "area", "city",
                                                "state", "country", "pincode",
                                            ], ", ", "-"
                                        )}
                                    </Typography></Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Latitude")}</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.registration_officeByID?.vendor_address?.[0]?.latitude ? props?.data?.registration_officeByID?.vendor_address?.[0]?.latitude : "-"}
                                    </Typography></Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <Typography className={classes.heading}>{t("Longitude")}</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.registration_officeByID?.vendor_address?.[0]?.longitude ? props?.data?.registration_officeByID?.vendor_address?.[0]?.longitude : "-"}
                                    </Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                </>}
            {props?.shareHolders?.length > 0 &&
                <Grid container className={classes.card} rowGap={"8px"}>
                    <Grid item xs={12}><Typography className={classes.title}>{t("Share Holders Details")}</Typography></Grid>
                    {props?.shareHolders?.map?.(_ => {
                        return (<Grid item xs={12}>
                            <div className={classes.shareHolderCard}>
                                <Avatar className={classes.shareHolderImage}
                                    src={_?.contactByID?.image_url ?? "/images/imagesproperty.svg"} alt=""
                                    onClick={() => props?.openImageViewer([_?.contactByID?.image_url])} />
                                <Grid container columnSpacing={"4px"}>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {_?.contactByID?.first_name ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Mobile Number")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {concat_string(
                                                {
                                                    mobile_code: _?.contactByID?.mobile_no_country_code,
                                                    mobile_no: _?.contactByID?.mobile_no,
                                                },
                                                ["mobile_code", "mobile_no"],
                                                " - ",
                                                "-"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Email Address")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {_?.contactByID?.email_id ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Share")}</Typography>
                                        <Typography className={classes.sub}>
                                            {_?.value ?? "-"}
                                            {_?.value && <span className={classes.matric} style={{ marginInlineStart: "4px"}}>%</span>}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>)
                    })}
                </Grid>}
            <br />
            {props?.unitContracts?.length > 0 &&
                <Grid container className={classes.card} rowGap={"8px"}>
                    <Grid item xs={12}><Typography className={classes.title}>{t("Contracts Details")}</Typography></Grid>
                    {props?.unitContracts?.map?.(_ => {
                        return (<Grid item xs={12}>
                            <div className={classes.shareHolderCard}>
                                <Avatar className={classes.shareHolderImage}
                                    {...stringAvatar(_?.contractByID?.contract_no, 24)} alt="" />
                                <Grid container columnSpacing={"4px"}>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Contract ID")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {_?.contractByID?.contract_no ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Contract Date")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {concat_string(
                                                {
                                                    start_date: _?.contractByID?.start_date ? timeZoneConverter(_?.contractByID?.start_date) : "",
                                                    end_date: _?.contractByID?.end_date ? timeZoneConverter(_?.contractByID?.end_date) : "",
                                              },
                                                ["start_date", "end_date"],
                                                " - ",
                                                "-"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Period")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {concat_string(
                                                {
                                                    period_value: _?.contractByID?.period_value?.toString(),
                                                    period: _?.contractByID?.period,
                                                },
                                                ["period_value", "period"],
                                                " ",
                                                "-"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Account ID")}</Typography>
                                        <Typography className={classes.sub}>
                                            {_?.contractByID?.contact_accountByID?.account_no ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Vendor ID")}</Typography>
                                        <Typography className={classes.sub}>
                                            {_?.contractByID?.contact_accountByID?.vendor_masterByID?.vendor_no ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Vendor Name")}</Typography>
                                        <Typography className={classes.sub}>
                                            {_?.contractByID?.contact_accountByID?.vendor_masterByID?.name ?? "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>)
                    })}
                </Grid>}
            <br />
            {props?.unitProjects?.length > 0 &&
                <Grid container className={classes.card} rowGap={"8px"}>
                    <Grid item xs={12}><Typography className={classes.title}>{t("Project Details")}</Typography></Grid>
                    {props?.unitProjects?.map?.(_ => {
                        return (<Grid item xs={12}>
                            <div className={classes.shareHolderCard}>
                                <Avatar className={classes.shareHolderImage}
                                    {...stringAvatar(_?.projectByID?.title, 24)} alt="" />
                                <Grid container columnSpacing={"4px"}>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Project ID")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {_?.projectByID?.project_no ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {_?.projectByID?.title ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Period")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {concat_string(
                                                {
                                                    planned_start_date: timeZoneConverter(_?.projectByID?.planned_start_date, "DD MMM YYYY"),
                                                    planned_end_date: timeZoneConverter(_?.projectByID?.planned_end_date, "DD MMM YYYY"),
                                                },
                                                ["planned_start_date", "planned_end_date"],
                                                " - ",
                                                "-"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                        <Typography className={classes.heading}>{t("Project Type")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {_?.projectByID?.type ?? "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>)
                    })}
                </Grid>}
            <br />
            {props?.isViewerOpen && (
                <ImageViewer
                    src={props?.currentImage}
                    currentIndex={0}
                    onClose={props?.closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </div>
    )
}