import React from "react";
import { withNavBars } from "../../HOCs";
import Project from "./project";
import ProjectContext from "./projectContext";

class ProjectParent extends React.Component {
    render() {
        return <ProjectContext>
            <Project />
        </ProjectContext>
    }
}

const props = {
    boxShadow: false
}

export default withNavBars(ProjectParent, props);