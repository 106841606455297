import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DatePickerTimeNew, NewDatePicker, SelectBox, TextBox } from "../../../components";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
const useStyles = makeStyles((theme) => ({
  dropdown: {
    margin: "10px 0px",
  },
}));

export const Service = (props) => {
  const classes = useStyles();
  // const [unit, setUnit] = React.useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false)
  let option = JSON.parse(localStorage.getItem("role"))
    ?.filter((item) => item.name === "Manager")
    .map((v) => v);

  const properyRow = option?.map((val, index) => {
    let _d;
    try {
      _d = {
        value: val.company_id,
        label: val.company_name,
      };
    } catch (err) {

    }
    return _d;
  });


  const manualCategoryResponse = (array) => {
    return array?.general_category_master
  }

  const manualSubCategoryResponse = (array) => {
    return array?.general_sub_category_master
  }

  const manualPropertyResponse = (array) => {
    return array?.property
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* company */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Company"
              options={properyRow}
              placeholder="Select Company"
              value={props?.data?.company}
              onChange={(value) => {
                props?.updateState("company", value);
              }}
              isError={props?.data?.error?.company?.length > 0}
              errorMessage={props?.data?.error?.company}
              isReadOnly
            />
          </div>
          {/* Category */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Category"
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/general_category_master",
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualCategoryResponse
                  )
              }
              loading={loading === "catagory"}
              debounceTimeout={800}
              placeholder="Select Category"
              value={props?.data?.category}
              onChange={(value) => {
                props?.updateState("category", value);
              }}
              isError={props?.data?.error?.category?.length > 0}
              errorMessage={props?.data?.error?.category}
            />
          </div>
          {/* Sub-Category */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Sub-Category"

              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/general_sub_category_master/list",
                    { id: props?.data?.category?.value },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualSubCategoryResponse
                  )
              }
              loading={loading === "subcatagory"}
              debounceTimeout={800}
              placeholder="Select Sub-Category"
              value={props?.data?.subcategory}
              key={JSON.stringify(props?.data?.category)}
              onChange={(value) => {
                props?.updateState("subcategory", value);
              }}
              isError={props?.data?.error?.subcategory?.length > 0}
              errorMessage={props?.data?.error?.subcategory}
            />
          </div>

          {/* Date */}
          <div className={classes.dropdown}>
            <DatePickerTimeNew
              isRequired
              isTime
              disableFuture={true}
              disablePast={false}
              minDate={new Date()}
              label="Preferred Visit Date & Time"
              placeholder="Preferred Visit Date & Time"
              value={props?.data?.preferreddate}
              onChange={(value) => props?.updateState("preferreddate", value)}
              isError={props?.data?.error?.preferreddate?.length > 0}
              errorMessage={props?.data?.error?.preferreddate}
            />
          </div>


        </Grid>
        <Grid item xs={6}>
          {/*  */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Property"
              placeholder="Select Property"
              value={props?.data?.propperty}
              onChange={(value) => {
                props?.updateState("propperty", value);
              }}
              key={JSON.stringify(props?.data?.company)}
              isError={props?.data?.error?.propperty?.length > 0}
              errorMessage={props?.data?.error?.propperty}
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/property/list",
                    {
                      "orderByKey": "name",
                      "orderByValue": "asc",
                      company_id: [props?.data?.company?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualPropertyResponse
                  )
              }
              loading={loading === "propertyName"}
              debounceTimeout={800}
            />
          </div>
          {/* units */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Unit"
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/unit/list",
                    {
                      property_id: props?.data?.propperty?.value
                    },
                    search,
                    array,
                    handleLoading,
                    "data"
                  )
              }
              loading={loading === "unit"}
              debounceTimeout={800}
              placeholder="Select Unit"
              key={JSON.stringify(props?.data?.propperty)}
              value={props?.data?.unit}
              onChange={(value) => {
                props?.updateState("unit", value);
              }}
              isError={props?.data?.error?.unit?.length > 0}
              errorMessage={props?.data?.error?.unit}
            />
          </div>

          {/* problem tittle */}
          <div className={classes.dropdown}>
            <TextBox
              isrequired
              label="Problem Title"
              placeholder="Problem Title"
              value={props?.data?.problem}
              onChange={(e) => {
                props?.updateState("problem", e.target.value);
              }}
              isError={props?.data?.error?.problem?.length > 0}
              errorMessage={props?.data?.error?.problem}
            />
          </div>
          {/* Date */}
          <div className={classes.dropdown}>
            <NewDatePicker
              maxDate={new Date()}
              required
              value={props?.data?.since ?? ""}
              label="Problem Since"
              placeholder="Problem Since"
              onChange={(value) => {
                props?.updateState("since", value);
              }}
              isError={props?.data?.error?.since?.length > 0}
              errorMessage={props?.data?.error?.since}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {/* Description */}
          <div>
            <TextBox
              multiline
              isrequired
              label="Problem Description"
              placeholder="Problem Description"
              value={props?.data?.description}
              onChange={(e) => {
                props?.updateState("description", e.target.value);
              }}
              isError={props?.data?.error?.description?.length > 0}
              errorMessage={props?.data?.error?.description}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
