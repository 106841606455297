import { Grid, Stack } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ImageViewer from "react-simple-image-viewer";
import { MapWithFields, MobileNumberInputComponent, Subheader, TextBox } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, allowed_file_size, getRoutePermissionNew, LocalStorageKeys, mapResult, NetWorkCallMethods, singleFileUpload } from '../../utils';
import {
    AssignedDiv,
    BodyDiv,
    CancelButton,
    CardTitle,
    CompanyRoleListDiv,
    Desc,
    FooterDiv,
    Image,
    MapAddressDiv,
    ProfileDetailDiv,
    ProfileImageDiv,
    PropertyListDiv,
    RemoveButton,
    SaveButton,
    Title,
    UploadButton,
} from "./component/styledComponent";
import { useStyles } from "./style";

const ProfileEdit = (props) => {
    const { loading, handleLoading, t } = props;
    const [loader, setLoader] = React.useState(true)
    const [buttonDisable, setButtonDisable] = React.useState(false)

    // constants
    const classes = useStyles()
    const history = useHistory()
    const inputFile = React.useRef(null)
    const defaultState = {
        userID: "",
        contactID: "",
        image: "",
        imageUrl: "",
        name: "",
        emailID: "",
        mobileNo: "",
        altMobileNo: "",
        reportingTo: "",
        companyNames: [],
        roles: [],
        propertyNames: [],
        doorNo: "",
        addressLineOne: "",
        addressLineTwo: "",
        landmark: "",
        area: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        error: {
            name: ""
        }
    }

    // useContext
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [data, setData] = React.useState({ ...defaultState })
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("")
    const [fullScreenMap, setFullScreenMap] = React.useState(false)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getProfileData()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getProfileData = () => {
        NetworkCall(
            `${config.api_url}/user-info?is_manager=true`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data
            let user_profile = _?.data?.data?.user_profiles?.[0]
            let contact = user_profile?.contact
            setData({
                userID: user_profile?.id ?? "",
                contactID: contact?.id ?? "",
                imageUrl: user_profile?.image_url ?? "",
                name: user_profile?.first_name ?? "",
                emailID: user_profile?.email_id ?? "",
                mobileNo: user_profile?.mobile_no ? {
                    mobile: user_profile?.mobile_no ?? "",
                    mobile_code: user_profile?.mobile_no_country_code ?? "",
                } : "",
                altMobileNo: user_profile?.alternative_mobile_no ? {
                    mobile: user_profile?.alternative_mobile_no ?? "",
                    mobile_code: user_profile?.alternative_mobile_no_country_code ?? "",
                } : "",
                reportingTo: tempData?.reporting_to?.first_name ?? "",
                companyNames: tempData?.company_names ?? "",
                roles: tempData?.roles ?? "",
                propertyNames: tempData?.property_names ?? "",
                doorNo: contact?.door_no ?? "",
                addressLineOne: contact?.street_1 ?? "",
                addressLineTwo: contact?.street_2 ?? "",
                landmark: contact?.landmark ?? "",
                area: contact?.area ?? "",
                city: contact?.city ?? "",
                state: contact?.state ?? "",
                country: contact?.country ?? "",
                zipcode: contact?.zipcode ?? "",
                latitude: contact?.latitude ?? "",
                longitude: contact?.longitude ?? "",
                data: tempData,
                error: defaultState?.error
            })
            setLoader(false)
            handleLoading(false)
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error,
            })
        })
    }

    // useEffect for map full screen event listener
    React.useEffect(() => {
        document.addEventListener("fullscreenchange", existFullscreen)
        return () => {
            document.removeEventListener("fullscreenchange", existFullscreen)
        }
    }, [])

    // Go Back
    const goBack = () => { history.goBack(-1) }

    //openImageViewer
    const openImageViewer = (image) => {
        setCurrentImage(image)
        setIsViewerOpen(true)
    }

    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }

    //exist map full screen
    const existFullscreen = () => {
        if (document.fullscreenElement === null) {
            setFullScreenMap(false)
        }
    }

    //open map full screen
    const fullScreenControl = () => {
        setFullScreenMap(true)
        document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()
    }

    // map result data
    const mapResultData = (map) => {
        let result = mapResult(map)
        setData({ ...data, ...result })
    }

    // function to update data state
    const updateState = (k, v) => {
        let error = data?.error
        error[k] = ""
        setData({ ...data, [k]: v, error })
    }

    const validate = () => {
        let isValid = true
        let error = data.error
        if (data?.name?.length === 0) { isValid = false; error.name = t("Name is Required"); }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true, msg: t("Please fill all mandatory field"),
                severity: AlertProps.severity.error,
            });
        }
        setData({ ...data, error })
        return isValid
    }

    const onUploadButton = () => {
        inputFile.current.click();
    };

    const browseImage = (_) => {
        let tempImageUrl = URL.createObjectURL(_)
        setData({ ...data, image: _, imageUrl: tempImageUrl })
    }

    const onRemoveButton = () => {
        setData({ ...data, image: "", imageUrl: "" })
    };

    const saveProfileImage = async () => {
        if (validate()) {
            setButtonDisable(true);
            let file = { url: data?.imageUrl }
            let uploaded_file = []
            try {
                if (data?.image) {
                    uploaded_file = await singleFileUpload(data?.image, null, alert, allowed_file_size)
                    if (uploaded_file?.[0]?.url) {
                        file['url'] = uploaded_file?.[0]?.url
                    }
                }
                let currentDate = new Date().toISOString();
                let profileID = localStorage.getItem(LocalStorageKeys.profileID);
               
                const upPayload = {
                    id: data?.userID,
                    update: {
                        image_url: file?.url,
                        first_name: data?.name,
                        updated_at: currentDate,
                        updated_by: profileID
                    }
                }
                NetworkCall(
                    `${config.api_url}/queries/user_profile/update`,
                    NetWorkCallMethods.post,
                    upPayload,
                    null,
                    true,
                    false
                ).then((r) => {
                    const payload = {
                        id: data?.contactID,
                        update: {
                            image_url: file?.[0]?.url,
                            first_name: data?.name,
                            door_no: data?.doorNo,
                            street_1: data?.addressLineOne,
                            street_2: data?.addressLineTwo,
                            landmark: data?.landmark,
                            area: data?.area,
                            city: data?.city,
                            state: data?.state,
                            country: data?.country,
                            zipcode: data?.zipcode,
                            latitude: parseFloat(data?.latitude),
                            longitude: parseFloat(data?.longitude),
                            updated_at: currentDate,
                            updated_by: profileID
                        }
                    }

                    NetworkCall(
                        `${config.api_url}/queries/contact/update`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        true,
                        false
                    ).then((r) => {
                        alert.setSnack({
                            ...alert, open: true,
                            severity: AlertProps.severity.success,
                            msg: t("Profile Updated Successfully.!!!"),
                        })
                        goBack()
                    }).catch((e) => {
                        setButtonDisable(false);
                        alert.setSnack({
                            ...alert, open: true, msg: t("Some Thing Went Wrong"),
                            severity: AlertProps.severity.error
                        })
                    })
                }).catch((e) => {
                    setButtonDisable(false);
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                })
            }
            catch (e) {
                setButtonDisable(false);
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error,
                })
            }
        }
    };

    const render = () => {
        return <>
            <Subheader hideBackButton={false} goBack={goBack} title={t("Edit Profile")} />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <BodyDiv>
                        <Grid container>
                            <Grid container spacing={"16px"}>
                                <Grid xs={12} sm={12} md={12} lg={7} item>
                                    <Grid container>
                                        <Grid container spacing={"16px"}>
                                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                                <ProfileImageDiv>
                                                    <Grid container>
                                                        <Grid container spacing={"16px"}>
                                                            <Grid item xs={12}>
                                                                <CardTitle>{t("PROFILE IMAGE")}</CardTitle>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Image
                                                                    src={data?.imageUrl ?? ""}
                                                                    alt={"profile_img"}
                                                                    onClick={() => data?.imageUrl &&
                                                                        openImageViewer([data?.imageUrl])} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <label>
                                                                    <UploadButton onClick={onUploadButton}>
                                                                        {t("Upload Image")}
                                                                    </UploadButton>
                                                                    <input type='file' style={{ display: 'none' }} accept="image/*"
                                                                        ref={inputFile} onClick={(e) => e.target.value = null}
                                                                        onChange={(e) => browseImage(e?.target?.files?.[0])} />
                                                                </label>
                                                            </Grid>
                                                            {data?.imageUrl && <Grid item xs={12}>
                                                                <RemoveButton onClick={onRemoveButton}>
                                                                    {t("Remove Image")}
                                                                </RemoveButton>
                                                            </Grid>}
                                                        </Grid>
                                                    </Grid>
                                                </ProfileImageDiv>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={8} lg={8} item>
                                                <ProfileDetailDiv>
                                                    <Grid container>
                                                        <Grid container spacing={"16px"}>
                                                            <Grid item xs={12}>
                                                                <CardTitle>{t("Profile Details")}</CardTitle>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextBox
                                                                    isrequired
                                                                    label={t("Name")}
                                                                    labelSize={"12px"}
                                                                    labelMarginBottom={"8px"}
                                                                    value={data?.name ?? ""}
                                                                    placeholder={t("Enter Name")}
                                                                    onChange={(e) => updateState("name", e?.target?.value)}
                                                                    isError={data?.error?.name?.length > 0}
                                                                    errorMessage={data?.error?.name} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextBox
                                                                    isReadonly
                                                                    label={t("Email ID")}
                                                                    labelSize={"12px"}
                                                                    labelMarginBottom={"8px"}
                                                                    value={data?.emailID ?? ""} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <MobileNumberInputComponent
                                                                    isReadonly
                                                                    label={t("Primary Mobile")}
                                                                    labelSize={"12px"}
                                                                    labelMarginBottom={"8px"}
                                                                    placeholder={t("Primary Mobile")}
                                                                    value={data?.mobileNo} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <MobileNumberInputComponent
                                                                    isReadonly
                                                                    label={t("Alternate Mobile")}
                                                                    labelSize={"12px"}
                                                                    labelMarginBottom={"8px"}
                                                                    placeholder={t("Alternate Mobile")}
                                                                    value={data?.altMobileNo} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Title>{t("Reporting To")}</Title>
                                                                <Desc>{data?.reportingTo ?? " - "}</Desc>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ProfileDetailDiv>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={5} item>
                                    <Grid container>
                                        <Grid container spacing={"16px"}>
                                            <Grid xs={12} sm={6} md={6} lg={6} item>
                                                <Grid container>
                                                    <Grid container spacing={"16px"}>
                                                        <Grid xs={12} item>
                                                            <AssignedDiv>
                                                                <Title>{t("Assigned Companies")}</Title>
                                                                <CompanyRoleListDiv testProps={"hello"}>
                                                                    {data?.companyNames?.map((_) =>
                                                                        <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                                </CompanyRoleListDiv>
                                                            </AssignedDiv>
                                                        </Grid>
                                                        <Grid xs={12} item>
                                                            <AssignedDiv>
                                                                <Title>{t("Associated Roles")}</Title>
                                                                <CompanyRoleListDiv>
                                                                    {data?.roles?.map((_) =>
                                                                        <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                                </CompanyRoleListDiv>
                                                            </AssignedDiv>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} sm={6} md={6} lg={6} item>
                                                <AssignedDiv>
                                                    <Title>{t("Associated Roles")}</Title>
                                                    <PropertyListDiv>
                                                        {data?.propertyNames?.map((_) =>
                                                            <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                    </PropertyListDiv>
                                                </AssignedDiv>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                    <MapAddressDiv>
                                        {(data?.latitude && data?.longitude) &&
                                            <MapWithFields
                                                fullScreenMap={fullScreenMap}
                                                fullScreenControl={fullScreenControl}
                                                mapOptions={{
                                                    isInput: true,
                                                    center: {
                                                        lat: data?.latitude,
                                                        lng: data?.longitude
                                                    },
                                                    lat: data?.latitude,
                                                    lng: data?.longitude,
                                                    mapLoad: false
                                                }}
                                                mapHeight={"300px"}
                                                breakpoints={{
                                                    map: { xs: 12, sm: 12, md: 5 },
                                                    components: { xs: 12, sm: 12, md: 7 }
                                                }}
                                                mapResult={mapResultData}
                                                fields={[
                                                    {
                                                        label: t("Door Number"),
                                                        component: "TextField",
                                                        value: data?.doorNo,
                                                        state_name: 'doorNo',
                                                        isrequired: false,
                                                        placeholder: t("Enter Door Number"),
                                                        breakpoints: { sm: 6, md: 2 }
                                                    },
                                                    {
                                                        label: t("Address Line 1"),
                                                        component: "TextField",
                                                        value: data?.addressLineOne,
                                                        state_name: 'addressLineOne',
                                                        isrequired: false,
                                                        placeholder: t("Enter Address Line 1"),
                                                        breakpoints: { sm: 6, md: 5 }
                                                    },
                                                    {
                                                        label: t("Address Line 2"),
                                                        component: "TextField",
                                                        value: data?.addressLineTwo,
                                                        state_name: 'addressLineTwo',
                                                        isrequired: false,
                                                        placeholder: t("Enter Address Line 2"),
                                                        breakpoints: { sm: 6, md: 5 }
                                                    },
                                                    {
                                                        label: t("Landmark"),
                                                        component: "TextField",
                                                        value: data?.landmark,
                                                        state_name: 'landmark',
                                                        isrequired: false,
                                                        placeholder: t("Enter Landmark"),
                                                        breakpoints: { sm: 6, md: 3 }
                                                    },
                                                    {
                                                        label: t("Area"),
                                                        component: "TextField",
                                                        value: data?.area,
                                                        state_name: 'area',
                                                        isrequired: false,
                                                        placeholder: t("Enter Area"),
                                                        breakpoints: { sm: 6, md: 3 }
                                                    },
                                                    {
                                                        label: t("City / District"),
                                                        component: "TextField",
                                                        value: data?.city,
                                                        state_name: 'city',
                                                        isrequired: false,
                                                        placeholder: t("Enter City"),
                                                        breakpoints: { sm: 6, md: 3 }
                                                    },
                                                    {
                                                        label: t("State"),
                                                        component: "TextField",
                                                        state_name: 'state',
                                                        value: data?.state,
                                                        isrequired: false,
                                                        placeholder: t("Enter State"),
                                                        breakpoints: { sm: 6, md: 3 }
                                                    },
                                                    {
                                                        label: t("Country"),
                                                        component: "TextField",
                                                        value: data?.country,
                                                        state_name: 'country',
                                                        isrequired: false,
                                                        placeholder: t("Enter Country"),
                                                        breakpoints: { sm: 6, md: 3 }
                                                    },
                                                    {
                                                        label: t("Pincode"),
                                                        component: "TextField",
                                                        state_name: 'zipcode',
                                                        value: data?.zipcode,
                                                        isrequired: false,
                                                        placeholder: t("Enter Pincode"),
                                                        breakpoints: { sm: 6, md: 3 }
                                                    },
                                                ]}
                                                onChangeFields={(k, v) => updateState(k, v)} />}
                                    </MapAddressDiv>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BodyDiv>)}
            {permission?.update &&
                <FooterDiv>
                    <Stack direction={"row"} gap={"8px"}>
                        <CancelButton onClick={goBack}>
                            {t("Cancel")}
                        </CancelButton>
                        <SaveButton
                            disabled={buttonDisable}
                            onClick={saveProfileImage}>
                            {t("Save")}
                        </SaveButton>
                    </Stack>
                </FooterDiv>}
            {isViewerOpen && (<ImageViewer
                src={currentImage}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                className={classes.imageViewer}
                closeOnClickOutside={true} />)}
        </>
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("profileView")(ProfileEdit);
