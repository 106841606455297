import { Box, Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useState } from 'react';
import { SelectBox, TextBox } from '../../../components';
import { config } from '../../../config';
import { AlertContext, BackdropContext } from '../../../contexts';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, Bold, enum_types, NetWorkCallMethods } from '../../../utils';
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { networkCallback } from '../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1)
}));


const textBoxStyle = {
    // padding: "8px 14px",
    // borderRadius: 8,
    // height: 44
}

export const GovernmentalDetails = (props) => {

    const {
        data = {},
        updateState = null,
        error = {},
        countryID = "",
        onChangeParticularState,
        isEdit = false,
        t
    } = props;

    const classes = useStylesCreation();
    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext);
    const [loading, setLoading] = useState(null);
    const manualResponse = (array) => {
        return array?.vat_group_master?.map((i) => {
          return {
              ...i,
              label: i?.label,
              value: i?.value
          }
      })
      };
    const loadOptionData = async (search, array,handleLoading, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.company_registration, enum_types.rera_type, enum_types.tax_code]

        if (type === "taxGroup") {
            if (countryID) {
                return loadOptionsApis(
                    "queries/vat_group_master/list",
                    {
                      "country_id": countryID,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualResponse
                  )
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please select country's company in company profile tab"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setLoading(null);
                return {
                    options: [],
                    hasMore: false
                }
            }
        }
        else if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    const onChangeTaxGroup = (value) => {

        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading..."
        })
        NetworkCall(
            `${config.api_url}/queries/vat_group_item/get_sum_rate`,
            NetWorkCallMethods.post,
            { vat_group_master_id: value?.value },
            null,
            true,
            false
        ).then(res => {
            let updateState = {
                ...data,
                tax: {
                    ...data.tax,
                    taxGroup: value,
                    totalRateTax: res?.data?.data?.vat_group_item?.[0]?.sum_rate ?? ''
                }
            }
            onChangeParticularState('companyProfile', updateState);
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: ""
            })
        }).catch(err => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: ""
            })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })

    }

    const {
        tax = null
    } = data;

    return (
        <div>
            <Box className={classes.imagebox1}>
                <CustomTypography>{t("Tax")}</CustomTypography>
                <Grid container rowSpacing={1} columnSpacing={3}>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <SelectBox
                            isRequired={true}
                            label={t("Country Taxation System")}
                            placeholder={t("Select Taxation System")}
                            // options={[]}
                            loadOptions={(search, array,handleLoading) => loadOptionData(search, array,handleLoading, enum_types.tax_code)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === "tax_code"}
                            isSearchable={false}
                            value={tax?.taxCode ?? null}
                            onChange={(value) => updateState('tax', 'taxCode', value)}
                            isError={error?.tax?.taxCode?.error ?? false}
                            errorMessage={error?.tax?.taxCode?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label={t("Tax Registration Number")}
                            placeholder={t("Enter Tax Registration Number")}
                            value={tax?.taxRegistrationNumber ?? ''}
                            onChange={(e) => updateState('tax', 'taxRegistrationNumber', e.target.value)}
                            isError={error?.tax?.taxRegistrationNumber?.error ?? false}
                            errorMessage={error?.tax?.taxRegistrationNumber?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <SelectBox
                            isRequired={true}
                            label={t("Tax Group")}
                            placeholder={t("Select Tax Group")}
                            loadOptions={(search, array,handleLoading) => loadOptionData(search, array, handleLoading,"taxGroup")}
                            isPaginate
                            debounceTimeout={800}
                            value={tax?.taxGroup ?? null}
                            onChange={(value) => onChangeTaxGroup(value)}
                            isError={error?.tax?.taxGroup?.error ?? false}
                            errorMessage={error?.tax?.taxGroup?.errorMsg ?? ''}
                            key={JSON.stringify(countryID)}

                        />
                    </Grid>
                    {
                        isEdit &&

                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <TextBox
                                {...textBoxStyle}
                                // isrequired
                                label={t("Total Tax Rate")}
                                placeholder={t("Total Tax Rate")}
                                value={tax?.totalRateTax ?? ''}
                                isReadonly
                            />
                        </Grid>
                    }

                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            // isrequired
                            label={t("CR Number")}
                            onChange={(e) => updateState('tax', 'cr_no', e.target.value)}
                            placeholder={t("CR Number")}
                            value={tax?.cr_no ?? ''}
                        />
                    </Grid>
                </Grid >
            </Box >
        </div >
    )
}