import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Subheader } from "../../../components";
import { config } from "../../../config";
import { AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { accessCheckRender, getRoutePermissionNew, NetWorkCallMethods } from "../../../utils";
import { UnitDetails } from "./unitView/unitDetails";

export const ShortTermPricingUnitView = () => {
    const [details, setDetails] = React.useState({
        data: {},
        assets: [],
        asset: []
    })

    const search = useLocation().search;
    const unitID = new URLSearchParams(search).get("unitID");
    const history = useHistory()
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: true,
                    message: "",
                });
                getUnitDetails()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getUnitDetails = () => {
        const payload={
            unitId:unitID
        }
        NetworkCall(
            `${config.api_url}/queries/unit/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((res) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            setDetails({
                data: res.data?.data?.unit[0],
                assets: res.data?.data?.assets,
                asset: res.data?.data?.asset
            })
        })
    }

    const headerpage = [
        {
            id: "1",
            label: "Unit"
        }
    ]

    const render = () => {
        return <>
            <Subheader title={details?.data?.property?.name} pageoptions={headerpage} goBack={() => history.goBack(-1)} select isReadOnly={true} value={details?.data?.property?.company} />
            <UnitDetails data={details?.data} assets={details?.asset} permission={permission} />
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    )
}