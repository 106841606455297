import React from 'react'
import { componentsStyle } from './style'
import { Avatar, Box, Button, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


export const MarketPlaceCard = (props) => {
    const classes = componentsStyle(props)
    return (
        <>
            <Box className={classes.parentBox}>
                {props?.master &&
                    <Box className={classes.imgParent}>
                        {props?.data?.logo?.length > 0 ? <img src={props?.data?.logo} alt="s" className={classes.img} /> : <Avatar className={classes.img} />}
                    </Box>
                }
                {props?.master === false &&
                    <Box className={classes.imgParent2}>
                        <Box>
                            {props?.data?.logo?.length > 0 ? <img src={props?.data?.logo} alt="s" className={classes.img2} /> : <Avatar className={classes.img2} />}
                        </Box>
                        <Box>
                            <span className={props?.data?.purchased ? classes.tag : classes.tag1}>{props?.data?.purchased ? "Purchased" : "Not Purchased"}</span>

                        </Box>
                    </Box>
                }
                <Box>
                    <Typography className={classes.header}>{props?.data?.name ?? ''}</Typography>
                    <Typography className={classes.subHeader}>{props?.data?.description ?? props?.data?.desc ?? ""}</Typography>
                </Box>
                <Box>
                    <Button className={classes.btn} variant="text" endIcon={<KeyboardArrowRightIcon />} onClick={() => props?.onClick(props?.data , props?.item)}>
                        View
                    </Button>

                </Box>

            </Box>
        </>
    )
}