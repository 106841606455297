import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import { TableWithPagination } from "../../../components/tablewithpagination";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils/constants";
import { FormCommmunication } from "./form";
const useStyles = makeStyles((theme, props) => ({
  root: {
    height: "100%",
    borderRadius: "12px",
    background: "#F5F7FA",
    boxShadow: "0px 0px 16px #00000014",
  },
  addbtn: {
    float: "right",
    margin: "10px",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  userImg: {
    borderRadius: "50%",
    height: "50px",
    width: "50px",
  },
}));

export const Communication = () => {
  const [property, setProperty] = React.useState([]);
  const [drawer, setDrawer] = React.useState(false);
  const classes = useStyles();
  const [notification, setNotification] = React.useState([]);
  const alert = React.useContext(AlertContext);
  moment.defaultFormat = "DD MMM YY";
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [user, setUser] = React.useState([]);
  const [useropen, setUseropen] = React.useState(false);

  const getNotification = (val, offset = 0, limit = 10, property) => {
    const params = {
      tenantId: `${config.tenantId}`,
      company_id: val,
      property_id: property,
      type: ["Announcement"],
      offset,
      limit,
    };
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {

        const properyRow = response.data.data?.response?.map((val, index) => {
          let _d;
          try {
            _d = {
              // sno: "",
              index: offset + index + 1,
              id: val?.id,
              title: val?.title,
              description: val?.description,
              url: val?.url,
              createdby: `${val?.first_name ? val?.first_name : ""} ${val?.last_name ? val?.last_name : ""
                }`,
              triggeredat: val?.triggered_at,
              audience: val?.usersCount,
              users: val?.users,
            };
          } catch (err) {

          }
          return _d;
        });
        setNotification(properyRow);
        setTotal(response.data.data.totalRecords);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };

  const getProperty = (val) => {
    const payload = {
        user_profile_id: localStorage.getItem("profileID"),
        offset: 0,
        limit:null
      }

    NetworkCall(
      `${config.api_url}/queries/access_control/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let tempProperty = response?.data?.data?.access_control.map(
          (val) => val.property
        );

        const key = "value";
        const u_arr = [
          ...new Map(tempProperty.map((item) => [item[key], item])).values(),
        ];
        setProperty(u_arr);
        getNotification(
          [...new Set(u_arr.map((val) => val.company.id))],
          0,
          10,
          [...new Set(tempProperty.map((val) => val?.value))]
        );
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };

  React.useEffect(() => {
    getProperty();
    // eslint-disable-next-line
  }, []);
  const reload = () => [
    getNotification(
      [...new Set(property.map((val) => val.company.id))],
      0,
      10,
      [...new Set(property.map((val) => val?.value))]
    ),
  ];
  const communicationPath = [
    "sno",
    "title",
    "description",
    "url",
    "createdby",
    "triggeredat",
    "audience",
  ];

  const communicationheading = [
    { title: "S.NO", field: "index", type: "numeric" },
    { title: "Title", field: "title" },
    { title: "Description", field: "description" },
    { title: "URL", field: "url" },
    { title: "Created by", field: "createdby" },
    { title: "Triggered at", field: "triggeredat" },
    { title: "Audience Count", field: "audience" },
  ];

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getNotification(
      [...new Set(property?.map((val) => val.company.id))],
      offset,
      limit,
      [...new Set(property?.map((val) => val?.value))]
    );
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getNotification(
      [...new Set(property?.map((val) => val.company.id))],
      0,
      value,
      [...new Set(property?.map((val) => val?.value))]
    );
  };

  const handleLinkClick = (value) => {

    setUser(value?.users);

    setUseropen(true);
  };
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        onClick={() => setDrawer(true)}
        className={classes.addbtn}
      >
        Add Communication
      </Button>

      <Grid constainer spacing={2} direction="row">
        <Grid item xs={12} p={1}>
          {communicationheading && (
            <TableWithPagination
              heading={communicationheading}
              rows={notification}
              path={communicationPath}
              count="2"
              tableType=""
              showpagination={true}
              handleLink={() => console.log("")}
              dataType={[
                { type: ["text"], name: "index" },
                { type: ["text"], name: "title" },
                { type: ["description"], name: "description" },
                { type: ["redirect"], name: "url" },
                { type: ["text"], name: "createdby" },
                { type: ["date"], name: "triggeredat" },
                { type: ["textLink"], name: "audience" },
              ]}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              onClick={() => console.log("")}
              totalRowsCount={total}
              page={page}
              limit={limit}
              onUnitClick={handleLinkClick}
              view={true}
              edit={true}
              delete={true} />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={drawer}
        className={classes.dialog}
        fullWidth
        maxWidth="lg"
        onClose={() => setDrawer(false)}
      >
        <FormCommmunication setDrawer={setDrawer} reload={reload} />
      </Dialog>
      <Dialog
        open={useropen}
        fullWidth
        maxWidth="xs"
        onClose={() => setUseropen(false)}
      >
        <div>
          {user?.map((val) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                style={{ borderBottom: "1px solid #c1c1c1" }}
                p={1}
              >
                <Box>
                  <img
                    src={val?.image_url}
                    alt=""
                    className={classes.userImg}
                  />
                </Box>
                <Box>
                  <Typography
                    style={{
                      padding: "12px",
                    }}
                  >
                    {val?.first_name}&nbsp;{val?.last_name}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};
