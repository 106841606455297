import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { AlertContext } from "../../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { logoState } from "../../../utils/clientsettings";

import CompanyLogoUpload from "../../../components/companyLogoUpload";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
export const WelcomeScreenForm = ({ t = () => false, company = {}, reload = () => false, onClose = () => false }) => {
    const [data, setData] = React.useState({ ...logoState });
    const alert = React.useContext(AlertContext);

    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.logo?.length === 0 || !data?.logo?.src) {
            isValid = false;
            error.logo = t("Welcome Screen Picture is Required");

        }
        setData({ ...data, error });
        return isValid;
    }
    React.useEffect(() => {
        setData({ ...data, logo: { src: company?.welcome_screen_logo } })
        // eslint-disable-next-line
    }, [])
    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //on submit form
    const onSubmit = () => {

        if (validateForm()) {
            const payload = {
                id: company?.id,
                update: {
                    welcome_screen_logo: data?.logo?.src
                },
            }
            NetworkCall(
                `${config.api_url}/queries/company_master/update`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then(rs => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Banner Successfully Updated"),

                });
                reload(10, 0)
                onClose()
            }).catch(er => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),

                });
            })


        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill All Mandatory Field"),
            });
        }
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Box p={2}>
                        <CompanyLogoUpload
                            label={t("Upload Image here")}
                            data={data?.logo}
                            noValidate={true}
                            handleChange={(value) => updateState("logo", value)}
                        />
                        {
                            data?.error?.logo?.length > 0 &&
                            <Typography variant={"caption"} color={"error"}>
                                {data?.error?.logo}
                            </Typography>
                        }


                        <Box height="34px" />
                        <Button variant="contained" fullWidth onClick={onSubmit}>

                            {t("Save")}
                        </Button>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}