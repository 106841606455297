export const paymentDashboardPath = [
  "date_time",
  "payment_method",
  "reference_no",
  "transaction_id",
  "amount",
  "currency",
  "status",
];

export const getpaymentDashboardHeading = (t) => {
  const paymentheading = [
    { title: t("Date & Time"), field: "date_time" },
    { title: t("Payment Method"), field: "payment_method" },
    { title: t("Reference Number"), field: "reference_no" },
    { title: t("Transaction ID"), field: "transaction_id" },
    { title: t("Amount"), field: "amount" },
    { title: t("Currency"), field: "currency" },
    { title: t("Status"), field: "status" },
  ];
  return paymentheading;
};

export const paymentDashboardDataType = [
  { type: ["date"], name: "date_time" },
  { type: ["text"], name: "payment_method" },
  { type: ["text"], name: "reference_no" },
  { type: ["text"], name: "transaction_id" },
  { type: ["text"], name: "amount" },
  { type: ["text"], name: "currency" },
  { type: ["text"], name: "status" },
];

//filter status
export const taskCategory = [
  { label: "Credit card", value: "Credit card" },
  { label: "Debit card", value: "Debit card" },
  { label: "Net Banking", value: "Net Banking" },
  { label: "UPI", value: "UPI" },
];
export const status = [
  { label: "Sucess", value: "sucess" },
  { label: "Failure", value: "failure" },
];

export const transctionHistoryDetails = [
  "Status",
  "Amount",
  "Date & Time",
  "Payment Method",
  "Reference Number",
  "Customer Name",
  "Email Id",
  "Account Reference Number",
];
