import { Box, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
import { convertTimeUtcToZoneCalander, timeZoneConverter } from '../../../utils'

export const QuotationDetail = ({ data, t }) => {
    const classes = componantsStyles()
    return (
        <>
            {
                data?.revenue_type === "Sale" ?
                    <Box className={classes.detailRoot}>
                        <Box >
                            <Typography className={classes.head}>{t("HANDOVER DATE")}</Typography>
                            <Typography className={classes.sub}>&#x202a;{convertTimeUtcToZoneCalander(data?.occpation_date)}&#x202c;</Typography>
                        </Box>
                        <Box >
                            <Typography className={classes.head}>{t("CONTRACT START DATE")}</Typography>
                            <Typography className={classes.sub}>&#x202a;{convertTimeUtcToZoneCalander(data?.contract_start_date)
                              }&#x202c;</Typography>
                        </Box>

                    </Box>
                    : <Box className={classes.detailRoot}>
                        <Box >
                            <Typography className={classes.head}>{data?.revenue_type === "Lease" ? t("LEASE START DATE") : t("MANAGE START DATE")}</Typography>
                            <Typography className={classes.sub}>&#x202a;{timeZoneConverter(data?.startDate)}&#x202c;</Typography>
                        </Box>
                        <Box >
                            <Typography className={classes.head}>{data?.revenue_type === "Lease" ? t("LEASE END DATE") : t("MANAGE END DATE")}</Typography>
                            <Typography className={classes.sub}>&#x202a;{convertTimeUtcToZoneCalander(data?.endDate)
                               }&#x202c;</Typography>
                        </Box>
                        <Box >
                            <Typography className={classes.head}>{t("BILLING START DATE")}</Typography>
                            <Typography className={classes.sub}>&#x202a;{convertTimeUtcToZoneCalander(data?.billstartDate)
                                }&#x202c;</Typography>
                        </Box>

                        {
                            data?.payment_mode &&
                            <Box >
                                <Typography className={classes.head}>{t("PAYMENT OPTION")}</Typography>
                                <Typography className={classes.sub}>{data?.payment_mode}</Typography>
                            </Box>
                        }
                        {
                            data?.lease_payment_period &&
                            <Box >
                                <Typography className={classes.head}>{t("PAYMENT PERIOD")}</Typography>
                                <Typography className={classes.sub}>{data?.lease_payment_period}</Typography>
                            </Box>
                        }
                        {
                            data?.bill_generation &&
                            <Box >
                                <Typography className={classes.head}>{t("BILLING CYCLE METHOD")}</Typography>
                                <Typography className={classes.sub}>{data?.bill_generation}</Typography>
                            </Box>
                        }
                        {
                            data?.billing_cycle_date &&
                            <Box >
                                <Typography className={classes.head}>{t("BILLING CYCLE DATE")}</Typography>
                                <Typography className={classes.sub}>{data?.billing_cycle_date === "As per the Agreement Date" ? data?.billing_cycle_date : data?.billing_day}</Typography>
                            </Box>
                        }
                        {
                            data?.gradeperiod &&
                            <Box>
                                <Typography className={classes.head}>{t("GRACE PERIOD")}</Typography>
                                <Typography className={classes.sub}>{data?.gradeperiod} {t("Days")}({data?.graceApplicable})</Typography>
                            </Box>
                        }

                    </Box>
            }

        </>
    )
}