import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import FilterIMG from "../../assets/filter";
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { KycDetailViewTableDataType, KycDetailViewTableHeading, KycDetailViewTablePath, Mandatory } from "../../utils/kycDetails";
import { ApprovalPopup } from "./approvelPopup";
import { CountCard } from "./component/countCard";
import { RemarksPopup } from "./remarksPopup";
import { KycDetailViewStyle } from "./style";
const KycDetailView = (props) => {
    const { loading, handleLoading, t } = props;
    const defaultFilterState = { is_mandatory: [true] };
    const Heading = KycDetailViewTableHeading(t)
    const debounce = UseDebounce()
    const classes = KycDetailViewStyle()
    const history = useHistory()
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const state = useLocation()?.state
    // const client_id = localStorage.getItem(LocalStorageKeys.clinetID)
    //company
    const [isloading, setLoading] = React.useState(false)
    const [companyList, setCompanyList] = React.useState([])
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [searchText, setSearchText] = React.useState('')
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [countData, setCountData] = React.useState("")
    const [list, setList] = React.useState([])
    const [openPopup, setOpenPopup] = React.useState(false)
    const [selectedRow, setSelectedRow] = React.useState({})
    const [openRemarks, setOpenRemarks] = React.useState(false)
    const [remarksHeader, setRemarkHeader] = React.useState("")
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getKycViewDetail(0, 10, state?.company)
                }

            }
        }

        // eslint-disable-next-line
    }, [auth]);

    // Go Back
    const goBack = () => { history.goBack(-1) }

    // Function to change the company
    const handleCompanyChange = (value) => {
        setLoading(true)
        setSelectedCompany(value)
    }

    // Function to change search text
    const handleSearchTextChange = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const getListDetails = (value, offset = 0, limit = 10, searchText, mandatory, proofType = "") => {
        // const variables = {
        //     query: KYC_DETAIL_VIEW_LIST(value?.contact_id, client_id, offset, limit, searchText, mandatory, proofType, moment(new Date()).format("YYYY-MM-DD 00:00:00")),
        //     variables: {},
        // };

        const payload = {
            contact_id: value?.contact_id ?? undefined,
            search: searchText,
            limit: limit,
            offset: offset,
            agreement_no: state?.data?.agreement_no,
            filters: {
                is_mandatory: mandatory ?? undefined,
                proof_id: proofType !== "" ? [proofType] : undefined
            }
        }

        NetworkCall(
            `${config.api_url}/kyc/view_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let listData = response?.data?.know_your_client?.map((data) => {
                    return {
                        contact_id: data?.account_no ?? "-",
                        contact_name: data?.contact?.first_name ?? "-",
                        id_type: data?.identification_master?.type ?? "-",
                        name_as_per_id: data?.name ?? "-",
                        id_number: data?.proof_no ?? "-",
                        mandatory: data?.is_mandatory === true ? 'Yes' : 'No',
                        validatity: `${data?.valid_from ? data?.valid_from : "-"} - ${data?.valid_to ? data?.valid_to : "-"}` ?? "-",
                        verified_text: data?.verified === true ? 'Yes' : 'No',
                        verified: data?.verified === true ? 'Yes' : 'No',
                        source: data?.source ?? "-",
                        status: data?.status ?? "-",
                        ...data
                    }
                })
                setList({ ...list, data: listData, count: response?.data?.count })
            }).catch((err) => {
                console.log(err)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("somethingWentWrong"),
                });
                // setLoading(false)

            })
    }


    const getKycViewDetail = (company) => {
        setLoading(true)
        const payload = {
            id: state?.data?.id
        }

        NetworkCall(
            `${config.api_url}/kyc_approval_requests/get-details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setCountData(response?.data?.data?.[0])
                if (state?.company) {
                    getListDetails(response?.data?.data?.[0], 0, 10, "", [true])
                }
                setLoading(false)
                handleLoading(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("somethingWentWrong"),
                });
                setLoading(false)
            })
    }


    // Function to search data in amenity booking
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getListDetails(countData, 0, limit, e, [true])
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit
        getListDetails(countData, offset, limit, "", [true])
    }

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)
        getListDetails(countData, 0, e, "", [true])
    }

    const handleTableIcon = (type, data) => {
        if (type === "view") {
            setSelectedRow(data)
            setOpenPopup(!openPopup)
        }
    }

    const handleApproval = (type, remark = "") => {
        setIsDisableBtn(true)
        const payload = {
            agreement_id: countData?.agreement_id,
            id: countData?.id,
            status: type === "Approve Proof" ? "approved" : "pending",
            remarks: remark
        }

        NetworkCall(
            `${config.api_url}/kyc_approval_requests/upsert-kyc-request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                setOpenRemarks(!openRemarks)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: type === "Approve Proof" ? t("Approved Successfully") : t("Declined Successfully"),
                });
                history.push(Routes.kycDetails)
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("somethingWentWrong"),
                });
                setLoading(false)
            })
    }

    const handleRemarksPopup = (title, type) => {
        setRemarkHeader({ title: title, type: type })
        setOpenRemarks(!openRemarks)
    }

    const handleUpdate = (text) => {
        if (remarksHeader?.type === "popup") {
            onSubmit(text)
        } else {
            handleApproval(remarksHeader?.title, text)
        }
    }

    const onSubmit = (remark) => {
        setIsDisableBtn(true)
        const payload = {
            id: selectedRow?.id,
            payload: {
                status: remarksHeader?.title === "Decline Proof" ? "Declined" : "Verified",
                remarks: remark,
                verified: true

            }
        }
        NetworkCall(
            `${config.api_url}/queries/know_your_client/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setIsDisableBtn(false)
            getKycViewDetail(0, 10, selectedCompany?.value)
            setOpenPopup(!openPopup)
            setOpenRemarks(!openRemarks)

        }).catch((err) => {
            setIsDisableBtn(false)
            console.log(err)
        })
    }

    const handleFilter = (value) => {
        setFilterData(value)
        getListDetails(countData, 0, 10, "", value?.is_mandatory, value?.proof_type?.value)
    }


    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={false}
                    title={t("AccountDetails")}
                    select
                    isReadOnly
                    options={companyList}
                    value={selectedCompany}
                    goBack={goBack}
                    onchange={(e) => { handleCompanyChange(e) }}
                    padding={0} />
                <Grid container spacing={2} p={"16px 22px 16px 22px"}>
                    <Grid item md={3}>
                        <CountCard
                            cardType
                            title={t("CONTACT DETAILS")}
                            subTitle={countData?.first_name}
                            subTitle1={`${countData?.mobile_no_country_code} ${countData?.mobile_no}`}
                            imgUrl={countData?.image_url}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <CountCard
                            type="mandatory"
                            count={countData?.no_of_proof}
                            content={t("NoOfMandatoryProof")} />
                    </Grid>
                    <Grid item md={3}>
                        <CountCard
                            type="accepted"
                            count={countData?.no_of_approved_count}
                            content={t("AcceptedCount")} />
                    </Grid>
                    <Grid item md={3}>
                        <CountCard
                            type="declined"
                            count={countData?.no_of_declined_count}
                            content={t("DeclinedCount")} />
                    </Grid>
                </Grid>

                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SearchFilter
                                placeholder={t("Search")}
                                value={searchText}
                                handleChange={(value) => handleSearchTextChange(value)} />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem />}>
                                    <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData.status?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    {isloading ? (
                        <NewLoader minusHeight="500px" />
                    ) : (
                        <TableWithPagination
                            heading={Heading}
                            rows={list?.data}
                            path={KycDetailViewTablePath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => false}
                            tableType="no-side"
                            dataType={KycDetailViewTableDataType}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={list?.count}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 490px)'}
                            view={permission?.view}
                            edit={permission?.edit}
                            delete={permission?.delete} />
                    )}
                    {list?.data?.length > 0 && <Box mt={2}>
                        <Divider />
                    </Box>}
                    {list?.data?.length > 0 && <Grid container mt={2}>
                        <Grid item md={9}></Grid>
                        <Grid item md={3} display={'flex'} justifyContent={'end'}>
                            <Stack direction={"row"} sx={{ float: "right" }}>
                                <Button variant="contained" fullWidth className={classes.declineBtn}
                                    disabled={state?.data?.status === "pending" ? false : isDisableBtn ? true : true}
                                    onClick={() => handleRemarksPopup("Decline Proof")}>{t("Decline")}</Button>
                                <Button variant="contained"
                                    disabled={state?.data?.status === "pending" ? false : isDisableBtn ? true : true}
                                    fullWidth className={classes.approvalBtn}
                                    onClick={() => handleRemarksPopup("Approve Proof")}
                                >{t("Approve")}</Button>
                            </Stack>
                        </Grid>
                    </Grid>}
                </div>


                <AlertDialog
                    open={openPopup}
                    header={`${t(selectedRow?.name)} ${selectedRow?.identification_master?.type ? "| " + t(selectedRow?.identification_master?.type) : ""}`}
                    md={true}
                    onClose={() => setOpenPopup(!openPopup)}
                    component={
                        <ApprovalPopup t={t} data={selectedRow} handleRemarksPopup={handleRemarksPopup} isDisableBtn={isDisableBtn} />
                    }
                />
                <AlertDialog
                    open={openRemarks}
                    header={remarksHeader?.title}
                    isNormal
                    onClose={() => setOpenRemarks(!openRemarks)}
                    component={
                        <RemarksPopup t={t} close={() => setOpenRemarks(!openRemarks)} handleUpdate={handleUpdate} isDisableBtn={isDisableBtn} />
                    }
                />

                <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => handleFilter(value)}
                    defaultState={defaultFilterState}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.is_mandatory,
                            options: Mandatory(t),
                            isMulti: false,
                            state_name: "is_mandatory",
                            label: t("Mandatory"),
                        },
                        {
                            component: "select",
                            placeholder: t("Select Proof Type"),
                            value: filterData?.proof_type,
                            options: [],
                            isMulti: false,
                            label: t("Proof Type"),
                            state_name: "proof_type",
                            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                                "queries/identification_master",
                                {
                                    "api": true
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                            ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        }
                    ]} />
            </Box>
        )
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}

export default withNavBars(withNamespaces('kycDetails')(KycDetailView))
