import { Box, Grid } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { DialogBox, FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import AddPopup from "./addPopup"
import { Delpopup } from "./delPopup"
import TableComp from "./tableComp"
import { TableView } from "./tableView"
import { StatusOptionList } from "./toolsMasterUtils"

const ToolsMaster = ({ t }) => {
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [TableBtn, setTableBtn] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const [isdelete, setIsDelete] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false);
    const [isEdit, setisEdit] = React.useState(false)
    const [companyList, setCompanyList] = React.useState([])
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [permissions, setPermission] = React.useState({})
    const [tableData, setTableData] = React.useState({
        data: [],
        count: 0
    })
    const [searchText, setSearchText] = React.useState("")
    const [editData, setEditData] = React.useState("")
    const [filterData, setFilterData] = React.useState({
        is_active: null
    });
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext);

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            let company = getCompanyOption(backdrop, auth, alert)
            if (company) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getToolList(0, limit, "", company?.selected?.value, true)
            }
        }
        // eslint-disable-next-line
    }, [auth, filterData]);


    //get tableData 
    //table list
    const getToolList = (offset = 0, limit = 10, searchText = "", companyId, loading = true) => {
        let is_active = filterData?.is_active ?? [true];
        setLoading(loading)
        const payload={
            offset:offset,
            limit:limit,
            search:searchText,
            company_id:companyId,
            status: is_active
        }
       NetworkCall(
            `${config.api_url}/queries/tools_master/get`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(res => {
            //table row constructions
            const List = res?.data?.data?.tools_master?.map((val, index) => {
                let _d;
                try {
                    _d = {
                        index: ((page - 1) * limit) + index + 1,
                        name: val?.name ?? " - ",
                        description: val?.description ?? " - ",
                        brand_name: val?.brand_name ?? "-",
                        tool_id: val?.tool_id ?? " - ",
                        cost: val?.rate ? `${val?.company?.currency?.symbol} ${val?.rate} / ${val?.period}` : " - ",
                        status: val?.is_active ? "Active" : "Inactive",
                        icon: "more_2",
                        id: val?.id,
                        period: val?.period,
                        ...val

                    };
                } catch (err) {

                }
                return _d;
            });
            setTableData({
                data: List,
                count: res?.data?.data?.count?.[0]?.count??res?.data?.data?.count
            })
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }


    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getToolList(0, limit, "", company?.selected?.value, true)
            }
        }

        // eslint-disable-next-line
    }, [filterData, auth])
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getToolList(0, 10, e, selectedCompany?.value, false)

    }
    //reload
    const reload = () => {
        getToolList(0, 10, "", selectedCompany?.value, true)

    }
    // is active changing function
    const changeActive = async (data) => {
        const payload ={
            id: data?.id,
            payload: {
                is_active: data?.is_active === false ? true : false
            }
        }
        NetworkCall(
            `${config.api_url}/queries/tools_master/update`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: data?.is_active === false ? AlertProps.severity.success : AlertProps.severity.error,
                msg: data?.is_active === false ? "Activated" : "DeActivated",
            });
            getToolList(0, 10, "", selectedCompany?.value, true)

            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }

    // view table row descrip
    const handleTableIcon = (type, data) => {
        if (type === "view") {
            setTableBtn(true)
            setEditData(data)
            setisEdit(false)
        }
        else if (type === "edit") {
            setisEdit(true)
            setIsOpen(true)
            setEditData(data)
        }
        else if (type === "active") {
            changeActive(data, data)
        }
    }

    // close table row descrip
    const handleTableClose = () => {
        setTableBtn(false)
    }
    //add form open
    const handleAdd = () => {
        setIsOpen(true)
        setisEdit(false)
    }
    //To filter data based on status
    const handleFilter = (data) => {
        setFilterData(data)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getToolList(offset, limit, "", selectedCompany?.value, false)

    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getToolList(0, limit, "", selectedCompany?.value, false)


    }
    //company handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getToolList(0, 10, "", value?.value)

    }
    const render = () => {
        return (
            <Box>
                <>
                    <Subheader title={t("toolsMaster")} hideBackButton={true} select value={selectedCompany} options={companyList} onchange={(e) => {
                        handleCompanyChange(e)
                    }} />
                    {
                        loading ?
                            <LoadingSection top="20vh" message={t("Fetching ...")} />
                            :
                            <Grid container>
                                <Grid item xs={TableBtn ? 7 : 12}>
                                    <TableComp
                                        handleTableIcon={handleTableIcon}
                                        handleAdd={handleAdd}
                                        handleChangeLimit={handleChangeLimit}
                                        handlePagination={handlePagination}
                                        page={page}
                                        limit={limit}
                                        data={tableData?.data}
                                        permissions={permissions}
                                        totalRows={tableData?.count}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        handleFilter={handleFilter}
                                        openfilter={() => setDrawer(true)}

                                    />
                                </Grid>
                                <Grid item xs={!TableBtn ? 0 : 5} >
                                    <TableView t={t} handleTableClose={handleTableClose} viewData={editData} />
                                </Grid>
                            </Grid>
                    }
                    {/* add /edit modal */}
                    <DialogBox
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        header={isEdit === true ? t("Edit Tool") : t("Add New Tool")}
                        maxWidth="sm"
                        width={800}
                        component={<AddPopup t={t} isEdit={isEdit} company={selectedCompany} setIsOpen={setIsOpen} editData={editData} reload={reload} />}
                    />
                    {/* delete modal */}
                    <DialogBox
                        open={isdelete}
                        onClose={() => setIsDelete(false)}
                        isnotTitle={true}
                        // isNormal
                        maxWidth="md"
                        // width={400}
                        component={<Delpopup t={t} handleClose={() => setIsDelete(false)} />}
                    />
                    {/*filter component */}
                    {drawer && (
                        <FilterGenerator
                            open={drawer}
                            onClose={() => setDrawer(false)}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.is_active,
                                    state_name: "is_active",
                                    label: t("Status"),
                                    options: StatusOptionList(t),
                                    isMulti: true
                                },


                            ]}
                            onApply={(value) => handleFilter(value)}
                        />
                    )}
                </>
            </Box>
        )
    }
    return (
        <Box>

            {accessCheckRender(render, permissions)}
        </Box>
    )
}
export default withNamespaces("toolsMaster")(ToolsMaster)