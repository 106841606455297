import { Box, Grid } from "@mui/material";
import React from "react";
import { SelectBox } from "../select";
import { TextBox } from "../textbox";
import { loadOptionsApis } from "../../screens/maintenancerequest/utils";
export const ProductUpdate = ({ t = () => false, details = {}, setDetails = () => false, inspection_item_map = "", itemConditionOptions = [], isDisableBtn = false, company = {} }) => {
  const updateDetails = (k, v) => {
    let error = details.error;
    error[k] = "";
    setDetails({ ...details, [k]: v, error })
  }
  return (

    <Box >
      <>
        <Box mt={2}>
          <TextBox
            label={t("Model Number")}
            placeholder={("Enter Model Number")}
            // onChange={(val) => updateDetails("modalNumber", val.target.value)}
            value={details?.modalNumber ?? ""}
            // isrequired={true}
            // isError={details?.error?.modalNumber?.length < 0 ? false : true}
            // errorMessage={details?.error?.modalNumber}
            isReadonly={true}

          />


        </Box>
        <Box mt={2}>

          <TextBox
            label={t("Serial Number")}
            placeholder={t("Enter Serial Number")}
            onChange={(val) => updateDetails("serialNumber", val.target.value)}
            value={details?.serialNumber ?? ""}
            isrequired={true}
            isError={details?.error?.serialNumber?.length < 0 ? false : true}
            errorMessage={details?.error?.serialNumber}
            isReadonly={details?.isView}
          />


        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mt={2}>

              <TextBox
                label={t("Purchase Price")}
                placeholder={t("Enter Purchase Price")}
                onChange={(val) => updateDetails("purchasePrice", val.target.value)}
                value={details?.purchasePrice ?? ""}
                type="number"
                endAdornment={company?.currency_symbol}
                isReadonly={details?.isView}
              />


            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mt={2}>
              <TextBox
                label={t("Cost Price")}
                placeholder={t("Enter Cost Price")}
                onChange={(val) => updateDetails("costPrice", val.target.value)}
                value={details?.costPrice ?? ""}
                type="number"
                endAdornment={company?.currency_symbol}
                isReadonly={details?.isView}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mt={2}>
              <SelectBox
                label={t("Location")}
                placeholder={t("Select Location")}
                onChange={(val) => updateDetails("location", val)}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionsApis(
                    "inspection_item_location_master",
                    {},
                    search,
                    array,
                    handleLoading,
                    "list",
                  )}
                isPaginate={true}
                value={details?.location ?? ""}
                menuPlacement={"top"}
                isReadOnly={details?.isView}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mt={2}>
              <SelectBox
                label={t("condition")}
                placeholder={t("Select Item Condition")}
                onChange={(val) => updateDetails("itemCondition", val)}
                options={itemConditionOptions}
                value={details?.itemCondition ?? ""}
                isRequired={true}
                isError={details?.error?.itemCondition?.length < 0 ? false : true}
                errorMessage={details?.error?.itemCondition}
                menuPlacement={"top"}
                isReadOnly={details?.isView}
              />
            </Box>
          </Grid>
        </Grid>
        <Box height="16px" />
        {details?.isView &&
          <Box>
            <Grid container spacing={2}>
              {details?.device_name !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Device Name")}
                    placeholder={t("Device Name")}
                    value={details?.device_name ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.platform !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Platform")}
                    placeholder={t("Platform")}
                    value={details?.platform ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.license !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("License")}
                    placeholder={t("License")}
                    value={details?.license ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.imei !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("IMEI")}
                    placeholder={t("IMEI")}
                    value={details?.imei ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.mac !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Mac")}
                    placeholder={t("Mac")}
                    value={details?.mac ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.hardware !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Hardware")}
                    placeholder={t("Hardware")}
                    value={details?.hardware ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.firmware !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Firmware")}
                    placeholder={t("Firmware")}
                    value={details?.firmware ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.software !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Software")}
                    placeholder={t("Software")}
                    value={details?.software ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.build !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Build")}
                    placeholder={t("Build")}
                    value={details?.build ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.last_service_date !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Last Service Date")}
                    placeholder={t("Last Service Date")}
                    onChange={(val) => updateDetails("last_service_date", val.target.value)}
                    value={details?.last_service_date ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
              {details?.last_service_reference !== null &&
                <Grid item xs={12} sm={12} md={6} lg={6}>

                  <TextBox
                    label={t("Last Service Reference")}
                    placeholder={t("Last Service Reference")}
                    onChange={(val) => updateDetails("last_service_reference", val.target.value)}
                    value={details?.last_service_reference ?? ""}
                    isReadonly={true}
                    color="#F6F6F6"
                  />
                </Grid>
              }
            </Grid>
          </Box>
        }
      </>
    </Box >

  );
};
