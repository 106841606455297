import { Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { CustomSelect } from '../../../components/filterGenerator/components'
import { config } from '../../../config'
import { NetworkCall } from '../../../networkcall'
import { NetWorkCallMethods } from '../../../utils'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { RequestMessage } from '../components'
import { UpdatePopupStyle } from './style'

export const UpdatePopup = (
    {
        type = "",
        close = () => false,
        invoice_data = {},
        title = "", message = "",
        enumData = {},
        statusUpdate = () => false,
        closeReqpopup = () => false,
        selectedData = {},
        selectedCompany = {},
        t = () => false,
        reload = () => false
    }
) => {
    const initialState = {
        receipt_id: "",
        remarks: ""
    }
    const [data, setData] = React.useState({ ...initialState })
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const mutaionUpdate = () => {
        let invoice_id = invoice_data?.id
        setIsDisableBtn(true)
        const payload = {
            id: invoice_id,
            payload: {
                compensated_type: type === "Reconcile"?enumData[1]?.value:enumData[0]?.value,
                reciepts_id: type === "Reconcile" ? data?.receipt_id : null,
                remarks: data?.remarks,
                updated_at: new Date()
            }
        }
        NetworkCall(
            `${config.api_url}/queries/compensated_invoices/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(rs => {
            reload()
            statusUpdate()
            setIsDisableBtn(false)
            close()
            reload()
            closeReqpopup()
        }).catch(er => {
            console.log(er)
            setIsDisableBtn(false)
        })
    }
    const classes = UpdatePopupStyle()


    const manualResponse = (array) => array?.receipts?.map(_ => {
        return {
            ..._,
            label: _?.reciepts_no,
            value: _?.id,
        }
    });

    return (
        <Stack spacing={2} m={1}>
            <RequestMessage type={type} title={title} message={message} />
            {
                type !== "Rejected" &&
                <CustomSelect
                    label={t("Receipt ID")}
                    isPaginate={true}
                    onChange={(val) => {
                        setData({ ...data, receipt_id: val.value })
                    }}
                    loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                            "receipt/already_paid_receipts",
                            {
                                "company_id": selectedCompany?.value,
                                "invoice_no": selectedData?.invoice_number,
                                "account_no": invoice_data?.invoice?.account_no
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            manualResponse
                        )
                    }
                />
            }
            <Stack spacing={1}>
                <Typography className={classes.label}>{t("Remarks")}</Typography>
                <TextField id="outlined-basic" variant="outlined" multiline="true" minRows={4} maxRows={5}
                    onChange={(val) => setData({ ...data, remarks: val.target.value })} />
            </Stack >

            <Stack direction={"row"} spacing={2}>
                <Button variant="outlined" fullWidth disableElevation onClick={close}> {t("Cancel")} </Button>
                <Button variant="contained" fullWidth disableElevation onClick={mutaionUpdate} disabled={type === "Reconcile" ? (data?.receipt_id?.length > 0 ? isDisableBtn : true) : isDisableBtn}> {t("Update")} </Button>
            </Stack >
        </Stack >
    )
}