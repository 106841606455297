import React from "react";
import { withNavBars } from "../../HOCs";
import ExecutedJobsView from "./executedJobsView";
class ExecutedJobViewParent extends React.Component {
    render() {
        return <ExecutedJobsView {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ExecutedJobViewParent, props);