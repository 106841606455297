/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, getCompanyOption, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { filterOptionList, ProfessionHeading, ProfessionPath, ProfessionType, StatusOptionList } from '../../utils/profession/professionListUtils';
import { useStyles } from "./style";

const Profession = ({
    t
}) => {
    const defaultFilterState = { status: [true] };

    const defaultAddProfessionState = {
        formType: "add",
        id: "",
        name: "",
        description: "",
        status: true,
        delete: false,
        error: {
            name: ""
        }
    }

    const classes = useStyles()
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [professionList, setProfessionList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [addProfessionDialogOpen, setAddProfessionDialogOpen] = React.useState(false)
    const [addProfessionState, setAddProfessionState] = React.useState({ ...defaultAddProfessionState })
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth])

    // useEffect to get profession list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getProfession() }
    }, [selectedCompany, filterData])

    // Function to get profession list based on the input data
    const getProfession = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        const payload = {
            status: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
            company_id: selectedCompany?.value,
            search: search,
            offset: offset,
            limit: limit,
        }
        NetworkCall(
            `${config.api_url}/queries/profession_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setProfessionList({
                data: r?.data?.data?.profession_master,
                totalRowsCount: r?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    const ProfessionRows = React.useCallback(professionList?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                name: _?.name ?? "",
                description: _?.description ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [professionList])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in profession list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getProfession(0, limit, e)
    }

    // Function to open add profession form
    const handleAddProfession = () => {
        setAddProfessionState({ ...defaultAddProfessionState })
        setAddProfessionDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddProfessionState = {
            formType: type,
            id: tempData?.id,
            name: tempData?.name,
            description: tempData?.description,
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddProfessionState?.error
        }
        setAddProfessionState({ ...tempAddProfessionState })
        if (type === "edit" || type === "view") { setAddProfessionDialogOpen(true) }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddProfessionState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getProfession(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getProfession(0, value, searchText)
    }

    // Function to update addProfessionState
    const updateAddProfessionDialogState = (k, v) => {
        let error = addProfessionState?.error
        error[k] = ""
        setAddProfessionState({ ...addProfessionState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addProfessionState.error
        if (addProfessionState?.name?.length === 0) { isValid = false; error.name = t("Name is Required") }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddProfessionState({ ...addProfessionState, error })
        return isValid
    }

    // Function to create a profession
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            setIsDisableBtn(true)
            const currentDate = new Date().toISOString()
            const profileID = localStorage.getItem(LocalStorageKeys.profileID)

            const payload = {
                id: data?.id ?? undefined,
                company_id: selectedCompany?.value ?? undefined,
                description: data?.description ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                name: data?.name ?? undefined,
                updated_at: currentDate ?? undefined,
                updated_by: profileID ?? undefined
            }

            if (data?.formType === "add") {
                payload.client = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
                payload.created_at = currentDate ?? undefined
                payload.created_by = profileID ?? undefined
            }
            NetworkCall(
                `${config.api_url}/queries/profession_master/upsert`,
                NetWorkCallMethods.post,
                {
                    "payload":[payload]
                },
                null,
                true,
                false
            ).then((r) => {
                setAddProfessionState({ ...defaultAddProfessionState })
                getProfession()
                setAddProfessionDialogOpen(false)
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `${data?.id
                        ? (data?.formType === "delete"
                            ? t("Profession Deleted Successfully.!!!")
                            : t("Profession Updated Successfully.!!!"))
                        : t("Profession Created Successfully.!!!")}`,
                })
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
            })

        } else { return false }
    }


    return <div >
        <Subheader hideBackButton={true} title={t("Professions")}
            select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder={t("Search Professions")}
                        handleChange={(value) => handleSearch(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Box display={"flex"} justifyContent={"end"}>

                        <Stack direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                            <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                className={classes.filterButton}>
                                <Badge variant="dot" color="primary"
                                    invisible={!(filterData.status?.length > 0)}>
                                    <FilterIMG color="#091b29" />
                                </Badge>
                            </IconButton>
                            <Button variant="contained" className={classes.button}
                                onClick={handleAddProfession} disabled={isDisableBtn}>
                                {t("Add Profession")}
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TableWithPagination
                        heading={ProfessionHeading(t)}
                        rows={ProfessionRows}
                        path={ProfessionPath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleTableIcon}
                        onClick={() => console.log("")}
                        tableType="no-side"
                        dataType={ProfessionType}
                        handlePagination={handleTablePagination}
                        handleChangeLimit={handleTablePageLimit}
                        totalRowsCount={professionList?.totalRowsCount}
                        page={page}
                        limit={limit}
                        height={'calc(100vh - 290px)'}
                        view={true}
                        edit={true}
                        delete={true} />
                </Grid>
            </Grid>
            <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                onApply={(value) => setFilterData(value)}
                defaultState={defaultFilterState}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: filterOptionList(t),
                        isMulti: true,
                        state_name: "status",
                        label: t("Status")
                    },
                ]} />
            <Dialog
                className={classes.addDialog}
                open={addProfessionDialogOpen}
                onClose={() => setAddProfessionDialogOpen(false)}>
                <div className={classes.addDialogHeader}>
                    <Typography className={classes.addDialogHeaderTitle}>
                        {addProfessionState?.formType === "add" ? t("Add Profession") :
                            addProfessionState?.formType === "edit" ? t("Edit Profession") :
                                addProfessionState?.formType === "view" ? t("View Profession") : t("Add Profession")}
                    </Typography>
                    <IconButton onClick={() => setAddProfessionDialogOpen(false)}
                        className={classes.addDialogCloseButton}>
                        <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                    </IconButton>
                </div>
                <div className={classes.addDialogBody}>
                    <TextBox
                        isrequired
                        isReadonly={addProfessionState?.formType === "view"}
                        label={t("Profession Name")}
                        placeholder={t("Enter Name")}
                        value={addProfessionState?.name ?? ""}
                        onChange={(e) => updateAddProfessionDialogState("name", e.target.value)}
                        isError={addProfessionState?.error?.name?.length > 0}
                        errorMessage={addProfessionState?.error?.name} />
                    <Box height={16} />
                    <TextBox
                        isReadonly={addProfessionState?.formType === "view"}
                        label={t("Notes")}
                        placeholder={t("Enter Notes")}
                        multiline
                        value={addProfessionState?.description ?? ""}
                        onChange={(e) => updateAddProfessionDialogState("description", e.target.value)} />
                    <Box height={16} />
                    <Typography className={classes.addDialogFieldLabel} noWrap>{t("Status")}</Typography>
                    <div className={classes.addDialogButtonContainer}>
                        {StatusOptionList.map((_) => {
                            return <Button className={_?.value === addProfessionState?.status ?
                                classes.addDialogButtonSelected : classes.addDialogButtonUnSelected}
                                onClick={() => (addProfessionState?.formType === "add" || addProfessionState?.formType === "edit") ?
                                    updateAddProfessionDialogState("status", _?.value) : false}>
                                {t(_?.label)}
                            </Button>
                        })}
                    </div>
                </div>
                {(addProfessionState?.formType === "add" || addProfessionState?.formType === "edit") &&
                    <div className={classes.addDialogFooter}>
                        {addProfessionState?.formType === "edit" && <><Button fullWidth
                            className={classes.addDialogFooterCloseButton}
                            onClick={() => setAddProfessionDialogOpen(false)}>
                            {t("Cancel")}
                        </Button></>}
                        <Button variant="contained" fullWidth
                            className={classes.addDialogFooterButton}
                            onClick={() => handleCreateEdit(addProfessionState)} disabled={isDisableBtn}>
                            {addProfessionState?.formType === "add" ? t("Create") : t("Save")}
                        </Button>
                    </div>}
            </Dialog>
        </div>
    </div>
}
export default withNamespaces("profession")(Profession)
