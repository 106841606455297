import React from "react"
import { withNavBars } from "../../HOCs"
import VideoRecorder from "./videoRecorder"

class VideoRecorderParent extends React.Component {
    render() { return <VideoRecorder {...this.props} /> }
}
const props = { boxShadow: false }

export default withNavBars(VideoRecorderParent, props)
