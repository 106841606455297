import Grid from "@mui/material/Grid";
import React, { useEffect, useMemo, useState } from "react";
import { Location, ToggleButtonComponent } from "../../../components";
import {
  LocalStorageKeys,
  useWindowDimensions,
  NetWorkCallMethods,
} from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import {
  BedBathSelect,
  CustomAsyncSelect,
  CustomDatePicker,
  LeadSelect,
  LocationTextField,
  RequiredArea,
} from "../components";
import { Purpose, SearchIcon, UnitCategory } from "../components/svg";
import {
  BED_BATH,
  getDateValue,
  measurementUnits,
  returnMomentFormat,
} from "../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const Residential = (props) => {
  const {
    data = {},
    filterData = {},
    onChangeState,
    location,
    handleLead,
    selectedLead,
    t,
        purpose
  } = props;

  const size = useWindowDimensions();
  const current_date = new Date();
  let min_date_duration = current_date.setFullYear(
    current_date.getFullYear() - 1
  );
  const [propertyPurpose, setPropertyPurpose] = useState("Lease");
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const is_workspace = ['49', '35'].includes(moduleId)

  useEffect(() => {
    if (data?.propertyPurpose) {
      setPropertyPurpose(data.propertyPurpose);
    }
  }, [data]);

  const getCompnayDetails = () => {
      NetworkCall(
        `${config?.api_url}/queries/opportunity/get_company_details`,
        NetWorkCallMethods.post,
        {
          company_id: props?.company,
        },
        null,
        true,
        false
      )
      .then((res) => {
        onChangeState("requiredArea", {
          maxArea: "10000",
          minArea: "1",
          measurement: res?.data?.company_master[0]?.uom_master?.value,
          mesurmentLabel: res?.data?.company_master[0]?.uom_master?.label,
        });
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    if (props?.company) {
      getCompnayDetails();
    }
    // eslint-disable-next-line
  }, [props?.company]);

  const getDateOutput = useMemo(() => {
    return getDateValue(data?.duration, false, filterData?.facilitesbool);
  }, [data?.duration, filterData?.facilitesbool]);

  const returnRequiredArea = useMemo(() => {
    const {
      maxArea = "",
      minArea = "",
      mesurmentLabel = "",
    } = data?.requiredArea ?? {};
    return minArea && maxArea
      ? `${minArea} - ${maxArea} ${mesurmentLabel}`
      : "";
  }, [data?.requiredArea]);

  const manualResponse = (array) =>
    array?.map((_) => {
      return {
        ..._,
        label: _?.contact?.first_name,
        value: _?.contact?.id,
      };
    });
    
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={size?.width >= 1323 ? 3 : 6} lg={4}>
          <LeadSelect
            label={t("Search Opportunities")}
            placeholder={t("Search")}
            icon={<SearchIcon width={12} height={12} />}
            value={selectedLead ?? null}
            onChange={(value) => handleLead(value)}
            isPaginate
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "queries/property_search/lead/get",
                {
                    company_id: props?.company
                },
                search,
                array,
                handleLoading,
                "lead",
                {},
                manualResponse
            )
            }
            debounceTimeout={800}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Location
            value={location}
            handleChange={(value) => onChangeState("location", value)}
            textFieldComponent={(ref, value, handleChange) => (
              <LocationTextField
                label={t("Location")}
                inputRef={ref}
                handleChange={handleChange}
                value={value}
                placeholder={t("Select City")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={size?.width >= 1323 ? 3 : 6} lg={4}>
          {data?.propertyPurpose === "Sale" ? (
            <CustomDatePicker
              placeholder={t("Choose Handover Date")}
              label={t("Handover")}
              value={data?.handOverDate}
              data={
                data?.handOverDate?.date
                  ? returnMomentFormat(data?.handOverDate?.date)
                  : ""
              }
              onChange={(value) => onChangeState("handOverDate", value)}
              title={t("Handover Date")}
              min_date_duration={min_date_duration}
            />
          ) : (
            <CustomDatePicker
              placeholder={t("Choose Date Range")}
              label={t("Duration")}
              value={data?.duration}
              data={getDateOutput}
              onChange={(value) => onChangeState("duration", value)}
              title={t("Lease Duration")}
              showEndDate
              lead={data?.lead}
              disabled={data?.lead ? true : false}
              min_date_duration={min_date_duration}
            />
          )}
        </Grid>
        <Grid item xs={3} md={3}>
          <ToggleButtonComponent
            options={[
              { label: "Lease", value: "Lease" },
              { label: "Sale", value: "Sale" },
            ]}
            value={propertyPurpose}
            onChange={(value) => {
              setPropertyPurpose(value);
              onChangeState("propertyPurpose", value);
            }}
            height="62px"
            buttonGroupStyle={{
              gap: "6px",
              marginTop: "0px",
            }}
            buttonStyle={{
              width: "48%",
              border: "1px solid transparent",
              "&&:hover": {
                backgroundColor: "#FFFFFF",
                border: "1px solid #5078E1",
                color: "#5078E1",
              },
              "&.Mui-selected": {
                "&:hover": {
                  backgroundColor: "#5078E1",
                  border: "1px solid #5078E1",
                  color: "#FFFFFF",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={3} md={3}>
          <CustomAsyncSelect
            placeholder={t("Select Type")}
            value={data?.propertyType ?? null}
            onChange={(value) => onChangeState("propertyType", value)}
            label={t("Property Type")}
            icon={<Purpose width={15} height={15} />}
            isPaginate
            debounceTimeout={800}
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "queries/property_group_master",
                {
                    company_id: props?.company,
                    revenue: "Residential",
                },
                search,
                array,
                handleLoading,
                "property_group_master",
                {},
                null,
                {all: true}
            )
            }
          />
        </Grid>

                <Grid item xs={12} sm={6} md={size?.width >= 1323 ? 3 : 6}>
                    <CustomAsyncSelect
                        placeholder={t("Select Category")}
                        value={data?.unitCategory ?? null}
                        onChange={value => onChangeState('unitCategory', value)}
                        label={t("Unit Category")}
                        isPaginate={true}
                        icon={<UnitCategory width={15} height={15} />}
                        loadOptions={(search, array, handleLoading)=> 
                            loadOptionsApis(
                                "queries/unit_category/get",
                                { type:!is_workspace ? "property_search" : "property_search_workspace" , purpose:purpose },
                                search,
                                array,
                                handleLoading,
                                "unit_category_master"
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={size?.width >= 1323 ? 3 : 6}>
                    {
                        data?.unitCategory?.label === "Residential Unit" ?
                            <BedBathSelect
                                bedOptions={BED_BATH}
                                bathOptions={BED_BATH}
                                label={t("Bed Room and Bath")}
                                value={data?.bedBath}
                                onChange={(value) => onChangeState('bedBath', value)}
                            />
                            :
                            <RequiredArea
                                label={t("Required Area")}
                                data={data?.requiredArea}
                                onChange={value => onChangeState('requiredArea', value)}
                                options={measurementUnits}
                                value={returnRequiredArea}
                            />

                    }

                </Grid>
            </Grid >
        </div >
    )
}
