import {
  Box
} from "@mui/material";
import React from "react";
import { CustomPaymentSelectBox } from "./paymentSelectBox";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";


export const PaymentSelectBox = ({
  label = "",
  handleChange = () => false,
  data = [],
  mileStoneName = "",
  companyId = "",
  error = "",
  mileStoneId = "",
  isRequired = "",
  value
}) => {
  return (
    <Box >
      <CustomPaymentSelectBox
        value={mileStoneName.length > 0 ? { value: mileStoneName, label: mileStoneName } : null}
        onChange={handleChange}
        mileStoneId={mileStoneId}
        data={data}
        label={"Payment Period"}
        placeholder={"Select Payment period"}
        error={error}
        required={isRequired}
        loadOptions={(search, array, handleLoading) =>
          loadOptionsApis(
            "queries/opportunity/get_milestone_master",
            {
                company_id: companyId ,
            },
            search,
            array,
            handleLoading,
            "data",
            null,
            null,
            {
              all_value:"On Completion",
                all_label:"On Completion",
                all:true
            }
            )
        }

      />

    </Box>
  );
};
