export const Path = ["inspectionID", "unitID", "associatedUnit", "propertyName", "tenantName", "inspectorName", "status"]

export const Heading = (t) => [
  { title: t("Inspection IDr"), field: "inspectionID" },
  { title: t("Unit ID"), field: "unitID" },
  { title: t("Associated Unit"), field: "associatedUnit", },
  { title: t("Property Name"), field: "propertyName", },
  { title: t("Tenant Name"), field: "tenantName", },
  { title: t("Inspector Name"), field: "inspectorName", },
  { title: t("Status"), field: "status", },
]

export const Type = [
  { type: ["text"], name: "inspectionID" },
  { type: ["text"], name: "unitID" },
  { type: ["text"], name: "associatedUnit" },
  { type: ["text"], name: "propertyName" },
  { type: ["text"], name: "tenantName" },
  { type: ["text"], name: "inspectorName" },
  { type: ["status_inspection"], name: "status" },
]