import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { Box, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import moment from 'moment'
import React from "react"
import { AssetPMP } from "../../../assets/assetPMP"
import { InternalResourceIcon } from '../../../assets/internalresourceIcon'
import { SelectedResourceIcon } from '../../../assets/selectedResource'
import { SelectedVendorAsset } from '../../../assets/selectedVendor'
import { VendorAssetIcon } from '../../../assets/vendorAsset'
import { FormGenerator, SelectBox, TextBox } from "../../../components"
import { useWindowDimensions } from "../../../utils"
// import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { useStyles } from "../style"
import { CriteriaList, loadOptionsApis } from "../utils"
import { CustomLoadOptionsApis } from '../../../components/assignModal/utils'

export const BasicPlanDetails = ({ state = {}, t = () => false, data = {}, setData = () => false, updateState = () => false, isView = false }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const matches = useMediaQuery('(min-width:900px)')
    const [resourceType, setResourceType] = React.useState("internal")
    const [isVendor, setIsVendor] = React.useState(true)
    React.useEffect(() => {
        if (data?.resource_type) {
            setResourceType(data?.resource_type)
        }
        //eslint-disable-next-line
    }, [])
    const manualResponse = (array) => {
        const details = {
            data: array?.data?.map((i) => {
                return {
                    ...i,
                    label: i?.label,
                    value: i?.value
                }
            }),
            count: array?.count
        }
        return details
    };
    const InspectionItemManualResponse = (array) => {
        const details = {
            list: array?.list?.map((i) => {
                return {
                    label: i?.name,
                    value: i?.id,
                    ...i
                }
            }),
            count: array?.count
        }
        return details
    }
    const form2 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("Resource Group"),
            placeholder: t("Select Resource Group"),
            value: data?.resource_group,
            onChange: (val) => updateState("resource_group", val, "resource_type", resourceType),
            error: data?.error?.resource_group,
            isRequired: true,
            loadOptions: (search, array, handleLoading) => CustomLoadOptionsApis(
                "resource_group_master/drop_down",
                {
                    company_id: state?.company?.value
                },
                search,
                array,
                handleLoading,
                "data",
                {},
                false,
                // manualResponse,
                // { all: true }
            ),
            debounceTimeout: 800,
            isPaginate: true,
            selectHeight: "auto",
            isMulti: true,
            color: "white",
            menuPlacement: "top",
            isReadonly: isView
        },
        // {
        //     size: {
        //         xs: 12,
        //         sm: 12,
        //         md: 12,
        //         lg: 12
        //     },
        //     isActive: true,
        //     component: "select",
        //     label: t("Profession and Skills"),
        //     placeholder: t("Select Profession and Skills"),
        //     value: data?.profession,
        //     onChange: (val) => updateState("profession", val),
        //     error: data?.error?.profession,
        //     isRequired: true,
        //     // options: [],
        //     debounceTimeout: 800,
        //     loadOptions: (search, array, handleLoading) =>
        //         loadOptionsApis(
        //             "queries/profession_master",
        //             {
        //                 "company_id": state?.company?.value,
        //             },
        //             search,
        //             array,
        //             handleLoading,
        //             "data",
        //             { "custom_data": true },
        //             manualResponse
        //         ),
        //     isPaginate: true,
        //     selectHeight: "auto",
        //     color: "white",
        //     isMulti: true,
        //     menuPlacement: "top",
        //     isReadonly: isView
        // },
    ]
    const handleResourceType = (val) => {
        if (val === "internal") {
            setIsVendor(false)
        }
        // updateState("job","","Profession","")
        setResourceType(val)
    }
    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={matches ? { borderRight: "1px solid #E4E8EE", padding: "16px 16px 0px 16px" } : { borderTop: "1px solid #E4E8EE", padding: "16px 16px 16px 16px" }}>
                <Typography className={classes.planTitle}>{t("Basic Plan Details")}</Typography>
                <Box className={classes.created_box}>
                    <Typography className={classes.created_value}>{t("Created On")} - {moment(data?.created_at).format("DD MMM YYYY")}</Typography>
                </Box>
            </Box>
            <Box sx={{ height: !matches ? "auto" : (isView ? size?.height - 260 : size?.height - 365), overflow: "scroll", borderRight: matches ? "1px solid #E4E8EE" : "none", padding: matches ? "0px 16px 0px 16px" : "16px", borderTop: !matches ? "1px solid #E4E8EE" : "none" }}>
                {state?.planner_type === "Fixed" &&
                    <>
                        <Box mt={1}>
                            <Typography className={classes.customlabel}>{t("PMP Type")} <Typography variant={"caption"} color={"error"}>
                                *
                            </Typography></Typography>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Box className={data?.PMPType === "Asset" ? classes.selectedPMPCard : classes.card} onClick={() => isView ? false : updateState("PMPType", "Asset")}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            {data?.PMPType === "Asset" ?
                                                <img src="/images/selectedAsset.svg" alt="unit icon" />
                                                : <AssetPMP />}
                                            <Box>
                                                <Typography className={classes.title}>{t("Asset")}</Typography>
                                                <Typography className={classes.examples}>{t("Eg:Ac, Lift, Tv etc")}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Box className={data?.PMPType === "Unit" ? classes.selectedPMPCard : classes.card} onClick={() => isView ? false : updateState("PMPType", "Unit")}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            {data?.PMPType === "Unit" ?
                                                <img src="/images/selectedUnit.svg" alt="unit icon" />
                                                : <img src="/images/unitBlock.svg" alt="unit icon" />
                                            }
                                            <Box>
                                                <Typography className={classes.title}>{t("Unit")}</Typography>
                                                <Typography className={classes.examples}>{t("Eg:2BHk, villa etc")}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
                <Box mt={1}>
                    <TextBox
                        isrequired
                        isReadonly={isView}
                        label={t("Plan Name")}
                        placeholder={t("Enter Plan Name")}
                        value={data?.plan_name ?? ""}
                        onChange={(e) => updateState("plan_name", e.target.value)}
                        isError={data?.error?.plan_name?.length > 0}
                        height={"40px"}
                        padding={"8px 14px"}
                        errorMessage={data?.error?.plan_name} />
                </Box>
                <Box mt={1}>
                    <TextBox
                        isrequired
                        isReadonly={isView}
                        label={t("Plan Description")}
                        placeholder={t("Enter Plan Description")}
                        value={data?.plan_description ?? ""}
                        multiline
                        onChange={(e) => updateState("plan_description", e.target.value)}
                        isError={data?.error?.plan_description?.length > 0}
                        errorMessage={data?.error?.plan_description} />
                </Box>
                {state?.planner_type === "Fixed" &&
                    <Box mt={1}>
                        <TextBox
                            isrequired
                            isReadonly={isView}
                            label={t("Execution Hours")}
                            placeholder={t("Enter Execution Hours")}
                            value={data?.execution_hours ?? ""}
                            type={"number"}
                            height={"40px"}
                            padding={"8px 14px"}
                            keyPress={(e) => {
                                if (e.key === 'e' || e.key === '-' || e.key === '.') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => updateState("execution_hours", e.target.value)}
                            isError={data?.error?.execution_hours?.length > 0}
                            errorMessage={data?.error?.execution_hours} />
                    </Box>
                }
                <Box mt={2}>
                    <Typography className={classes.planTitle}>{data?.PMPType === "Asset" ? t("Asset Preferences") : t("Unit Preferences")}</Typography>
                </Box>
                {data?.PMPType === "Unit" &&
                    <Box mt={1} mb={isView ? 2 : 0}>
                        <SelectBox
                            label={t("Unit Type")}
                            placeholder={t("Select unit type")}
                            onChange={(val) => updateState("unit_type", val)}
                            // options={[]}
                            loadOptions={(search, array, handleLoading) =>
                                loadOptionsApis(
                                    `queries/unit_type_master`,
                                    {
                                        "company_id": state?.company?.value,
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    manualResponse
                                )}
                            isPaginate={true}
                            value={data?.unit_type ?? ""}
                            isError={data?.error?.unit_type?.length > 0}
                            errorMessage={data?.error?.unit_type}
                            menuPlacement={"bottom"}
                            selectHeight={"40px"}
                            // padding={"8px 14px"}
                            isRequired
                            isReadOnly={isView}
                        />
                    </Box>
                }
                {data?.PMPType === "Asset" &&
                    <Box mt={1} mb={isView ? 1 : 0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={6}>
                                <SelectBox
                                    label={t("Category")}
                                    placeholder={t("Select category")}
                                    onChange={(val) => updateState("category", val)}
                                    // options={[]}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            `/inspection_item_category`,
                                            {
                                                is_active: [true]
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "list",
                                            {},
                                            InspectionItemManualResponse
                                        )}
                                    isPaginate={true}
                                    value={data?.category ?? ""}
                                    isError={data?.error?.category?.length > 0}
                                    errorMessage={data?.error?.category}
                                    menuPlacement={"bottom"}
                                    selectHeight={"40px"}
                                    isRequired
                                    isReadOnly={isView}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <SelectBox
                                    label={t("Sub category")}
                                    placeholder={t("Select  Sub category")}
                                    onChange={(val) => updateState("sub_category", val)}
                                    // options={[]}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            `/inspection_item_subcategory`,
                                            {
                                                "category_id": data?.category?.value ? data?.category?.value : null,
                                                is_active: [true]
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "list",
                                            {},
                                            InspectionItemManualResponse
                                        )}
                                    isPaginate={true}
                                    key={JSON.stringify(data?.category)}
                                    value={data?.sub_category ?? ""}
                                    isError={data?.error?.sub_category?.length > 0}
                                    errorMessage={data?.error?.sub_category}
                                    menuPlacement={"bottom"}
                                    isRequired
                                    selectHeight={"40px"}
                                    isReadOnly={isView}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                }
                {!isView && state?.planner_type === "Fixed" &&
                    <Box mt={2}>
                        <Typography className={classes.planTitle}>{t("Criteria")}</Typography>
                        <Box mt={1} mb={1} className={classes.card}>
                            {CriteriaList(t)?.map((val, index) => {
                                return (
                                    <>
                                        <Stack direction={"row"} spacing={1} alignItems="center">
                                            {val?.is_selected ?
                                                <RadioButtonCheckedIcon sx={{ color: "#5078E1" }} />
                                                :
                                                <RadioButtonCheckedIcon sx={{ color: "#E4E8EE", cursor: "not-allowed" }} />
                                            }
                                            <Box>
                                                <Typography className={classes.criteriaTitle}>{val?.title}</Typography>
                                                <Typography className={classes.criteriaSubTitle}>{val?.sub_title}</Typography>
                                            </Box>
                                        </Stack>
                                        {CriteriaList(t)?.length - 1 !== index &&
                                            <Box mt={1} mb={1}>
                                                <Divider />
                                            </Box>
                                        }
                                    </>
                                )
                            })}

                        </Box>
                    </Box>
                }
                {state?.planner_type === "Dynamic" &&
                    <Box mt={2}>
                        <Typography className={classes.planTitle}>{t("Resource Preference")}</Typography>
                        <Box mt={1}>
                            <Typography className={classes.title}>{t("Choose Preference")}</Typography>
                        </Box>
                        {isView ?
                            <Box mt={1} mb={1}>
                                {resourceType === "internal" &&
                                    <Box className={classes.viewResourceCard} onClick={() => handleResourceType("internal")}>
                                        <Stack direction={"row"} spacing={1} alignItems="center">
                                            {resourceType === "internal" ? <SelectedResourceIcon /> : <InternalResourceIcon />}
                                            <Box>
                                                <Typography className={classes.resourceTitle}>{t("Internal Resource")}</Typography>
                                                <Typography className={classes.criteriaSubTitle}>{t("Resource Pooling from internal team")}</Typography>
                                            </Box>
                                        </Stack>
                                        {resourceType === "internal" &&
                                            <>
                                                <FormGenerator t={t} components={form2} />
                                            </>
                                        }
                                    </Box>
                                }
                                {resourceType === "vendor" &&
                                    <Box className={classes.viewResourceCard} onClick={() => handleResourceType("vendor")}>
                                        <Stack direction={"row"} spacing={1} alignItems="center">
                                            {resourceType === "vendor" ? <SelectedVendorAsset /> : <VendorAssetIcon />}
                                            <Box>
                                                <Typography className={classes.resourceTitle}>{t("Vendor")}</Typography>
                                                <Typography className={classes.criteriaSubTitle}>{t("Delegate job to external vendor")}</Typography>
                                            </Box>
                                        </Stack>
                                        {resourceType === "vendor" &&
                                            <>
                                                <Box mt={1}>
                                                    <SelectBox
                                                        label={t("Vendor Name")}
                                                        placeholder={t("Choose Vendor")}
                                                        onChange={(val) => updateState("vendor_name", val)}
                                                        loadOptions={(search, array, handleLoading) =>
                                                            loadOptionsApis(
                                                                "queries/vendor_master",
                                                                {
                                                                    "company_id": state?.company?.value,
                                                                },
                                                                search,
                                                                array,
                                                                handleLoading,
                                                                "data",
                                                                {},
                                                                manualResponse
                                                            )}
                                                        value={data?.vendor_name ?? ""}
                                                        isPaginate={true}
                                                        isError={data?.error?.vendor_name?.length > 0}
                                                        errorMessage={data?.error?.vendor_name}
                                                        menuPlacement={"bottom"}
                                                        isRequired
                                                        color="white"
                                                        isReadOnly={isView}
                                                        selectHeight={"35px"}
                                                    />
                                                </Box>
                                                <Box mt={1}>
                                                    <FormGenerator t={t} components={form2} />
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                }
                            </Box>
                            :
                            <Box mt={1} className={classes.resourceCard}>
                                <Box className={resourceType === "internal" ? classes.selectedCard : classes.vendorCard} onClick={() => handleResourceType("internal")}>
                                    <Stack direction={"row"} spacing={2} alignItems="center">
                                        {resourceType === "internal" ? <SelectedResourceIcon /> : <InternalResourceIcon />}
                                        <Box>
                                            <Typography className={classes.resourceTitle}>{t("Internal Resource")}</Typography>
                                            <Typography className={classes.criteriaSubTitle}>{t("Resource Pooling from internal team")}</Typography>
                                        </Box>
                                    </Stack>
                                    {resourceType === "internal" &&
                                        <>
                                            <FormGenerator t={t} components={form2} />
                                        </>
                                    }
                                </Box>
                                <Box className={resourceType === "vendor" ? classes.selectedCard : classes.vendorCard} onClick={() => handleResourceType("vendor")}>
                                    <Stack direction={"row"} spacing={2} alignItems="center">
                                        {resourceType === "vendor" ? <SelectedVendorAsset /> : <VendorAssetIcon />}
                                        <Box>
                                            <Typography className={classes.resourceTitle}>{t("Vendor")}</Typography>
                                            <Typography className={classes.criteriaSubTitle}>{t("Delegate job to external vendor")}</Typography>
                                        </Box>
                                    </Stack>
                                    {resourceType === "vendor" &&
                                        <>
                                            <Box mt={1}>
                                                <SelectBox
                                                    label={t("Vendor Name")}
                                                    placeholder={t("Choose Vendor")}
                                                    onChange={(val) => { return (updateState("vendor_name", val, "resource_type", resourceType), setIsVendor(true)) }}
                                                    loadOptions={(search, array, handleLoading) =>
                                                        loadOptionsApis(
                                                            "queries/vendor_master",
                                                            {
                                                                "company_id": state?.company?.value,
                                                            },
                                                            search,
                                                            array,
                                                            handleLoading,
                                                            "data",
                                                            {},
                                                            manualResponse
                                                        )}
                                                    value={(isVendor ? data?.vendor_name : "") ?? ""}
                                                    isPaginate={true}
                                                    isError={data?.error?.vendor_name?.length > 0}
                                                    errorMessage={data?.error?.vendor_name}
                                                    menuPlacement={"bottom"}
                                                    isRequired
                                                    color="white"
                                                    selectHeight={"35px"}
                                                />
                                            </Box>
                                            <Box mt={1}>
                                                <FormGenerator t={t} components={form2} />
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </Box>
    )
}