import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../contexts";
import { Routes } from "../router/routes";
import { AlertProps, makeArrayUniqueByProperty, NetWorkCallMethods } from "../utils";
import { createState, returnContactCreatePayload, returnLeadCreatePayload } from "../utils/createQuotationUtils";
import { withNamespaces } from "react-i18next";
import { NetworkCall } from "../networkcall";
import { config } from "../config";

export const CreateQuotationListContext = createContext();

const CreateQuotationContextProvider = (props) => {
    const { t = () => false } = props

    const [step, setStep] = useState(1);
    const [dataNew, setDataNew] = React.useState({ ...createState });
    const [selectedAccount, setSelectedAccount] = React.useState({
        broker: null,
        normal: null
    })
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [hideBtn, setHideBtn] = React.useState(false)
    const [hidenBtn1, setHiddenBtn1] = React.useState(false)
    const [opperEdit, setOpperEdit] = React.useState(true)
    const alert = React.useContext(AlertContext);
    const history = useHistory()
    //validate sale oppertunity
    const validateSaleOppertunity = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.contract_start_date?.length === 0) {
            isValid = false;

            error.contract_start_date = t("Contract Start Date is Required");
        }
        if (dataNew?.payment_period?.value === "Milestone Based") {
            if (dataNew?.delivery_timestone_template?.length === 0) {
                isValid = false;
                error.delivery_timestone_template = t("Delivery Milstone is Required");
            }
        }

        if (dataNew?.payment_period?.length === 0) {
            isValid = false;
            error.payment_period = t("Payment Period is Required");
        }
        if (dataNew?.payment_option?.length === 0) {
            isValid = false;
            error.payment_option = t("Payment Options is Required");
        }
        if (dataNew?.oppertunity_subject?.length === 0) {
            isValid = false;
            error.oppertunity_subject = t("Subject is Required");
        }
        if (dataNew?.source?.length === 0) {
            isValid = false;
            error.source = t("Source is Required");
        }
        if (dataNew?.priority?.length === 0) {
            isValid = false;
            error.priority = t("Priority is Required");
        }
        // if(dataNew?.confirm_interest === "" || dataNew?.confirm_interest === null){
        //     isValid = false
        //     error.confirm_interest = "Confirm Interest is Required"
        //   }

        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //update state
    const updateStateNew = (key, value, key1, value1) => {
        let error = dataNew.error;
        error[key] = "";
        setDataNew({ ...dataNew, [key]: value, [key1]: value1, error });
    };
    //validate sale quotation
    const validateSaleQuotationDetails = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.quotation_description?.length === 0) {
            isValid = false;
            error.quotation_description = t("Description is Required");
        }

        if ((dataNew?.quotation_billing_start_date === null || dataNew?.quotation_billing_start_date?.length === 0)) {
            isValid = false;
            error.quotation_billing_start_date = t("Billing Start Date is Required");
        }


        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //validate lease quotation
    const validateLeaseQuotationDetails = () => {
        let isValid = true;
        let error = dataNew.error;
        
        if (dataNew?.quotation_description?.length === 0) {
            isValid = false;
            error.quotation_description = t("Description is Required");
        }

        if (dataNew?.quotation_billing_start_date?.length === 0) {
            isValid = false;
            error.quotation_billing_start_date = t("Billing Start Date is Required");
        }

        if (dataNew?.quotation_payment_peroid?.value !== "Prepaid") {
            if (dataNew?.quotation_billing_cycle?.length === 0 || dataNew?.quotation_billing_cycle === null) {
                isValid = false;
                error.quotation_billing_cycle = t("Billing Cycle Method is Required");
            }
            if ((dataNew?.quotation_billing_cycle_date === null || dataNew?.quotation_billing_cycle_date?.length === 0 ) && (dataNew?.custom_date === null || dataNew?.custom_date.length === 0)) {
                isValid = false;
                error.quotation_billing_cycle_date = t("Billing Cycle Date is Required");
            }
        }

        if(dataNew?.quotation_auto_renewal === null ||  dataNew?.quotation_auto_renewal === ""){
            isValid = false;
            error.quotation_auto_renewal = t("Auto Renewals is Required");
      
          }
          if(dataNew?.agreement_lock === null ||  dataNew?.agreement_lock === ""){
            isValid = false;
            error.agreement_lock = t("Agreement Lock In Period is Required");
      
          }
          if(dataNew?.wallet_carry_over === "" || dataNew?.wallet_carry_over === null){
            isValid = false
            error.wallet_carry_over = "Wallet Carry over is Required"
          }

          if(dataNew?.bill_cycle_method === "" || dataNew?.bill_cycle_method === null){
            isValid = false
            error.bill_cycle_method = "Billing Cycle Method is Required"
          }
          

        setDataNew({ ...dataNew, error });
        return isValid;
    }
    const checkBillingDate = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.quotation_payment_peroid?.value !== "Prepaid") {
            if ((dataNew?.custom_date === null  || dataNew?.custom_date?.length === 0) && dataNew?.bill_cycle_date !== "As per the Agreement Date") {
                isValid = false;
                error.bill_cycle_date = t("Billing Cycle Date is Required");
            }
            if (dataNew?.bill_cycle_date === null) {
                isValid = false;
                error.bill_cycle_date = t("Billing Cycle Date is Required");
            }
        }
        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //validate lead
    const validateLeadDetails = () => {

        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.duration?.length === 0) {
            isValid = false;
            error.duration = t("Duration is Required");
        }
        // if (dataNew?.unitUsage?.length === 0) {
        //     isValid = false;
        //     error.unitUsage = "Unit Usage is Required";
        // }
        if (dataNew?.lease_start_date?.length === 0) {
            isValid = false;
            error.lease_start_date = t("Start Date & End Date is Required");
        }

        if (dataNew?.billing_start_date?.length === 0) {
            isValid = false;
            error.billing_start_date = t("Billing Start Date is Required");
        }
        if (dataNew?.payment_period?.length === 0) {
            isValid = false;
            error.payment_period = t("Payment Period is Required");
        }
        if (dataNew?.payment_option?.length === 0) {
            isValid = false;
            error.payment_option = t("Payment Options is Required");
        }
        // if (dataNew?.grace_peroid?.length === 0) {
        //     isValid = false;
        //     error.grace_peroid = "Grace Peroid is Required";
        // }

        if (dataNew?.payment_period?.value !== "Prepaid") {
            if ((dataNew?.bill_cycle_date === null || dataNew?.bill_cycle_date?.length === 0) && (dataNew?.custom_date === null  || dataNew?.custom_date?.length === 0)) {
                isValid = false;
                error.bill_cycle_date = t("Billing Cycle Date is Required");
            }
            if (dataNew?.bill_cycle_date !== "As per the Agreement Date") {
                if (new Date(dataNew?.lease_start_date).getDate() > 28) {
                    if (dataNew?.custom_date > 28) {
                        isValid = false;
                        error.bill_cycle_date = t("Billing Cycle Date must be smaller than 28");
                    } else {
                        isValid = true;
                        error.bill_cycle_date = "";
                    }
                }
            }
            if (dataNew?.bill_cycle_method?.length === 0) {
                isValid = false;
                error.bill_cycle_method = "Billing Cycle Method is Required";
            }
        }

        if (dataNew?.oppertunity_subject?.length === 0) {
            isValid = false;
            error.oppertunity_subject = "Subject is Required";
        }
        if (dataNew?.source?.length === 0) {
            isValid = false;
            error.source = "Source is Required";
        }
        if (dataNew?.priority?.length === 0) {
            isValid = false;
            error.priority = "Priority is Required";
        }
        if (dataNew?.renewal_based_on?.length === 0) {
            isValid = false;
            error.renewal_based_on = "Renewal Based on is Required";
        }
        if (dataNew?.renewal && (dataNew?.renewal_based_on?.value === "Escalation %" || dataNew?.renewal_based_on?.value === "Increment %")) {
            if (dataNew?.esclation_percentage?.length === 0) {
                isValid = false;
                error.esclation_percentage = t("Increment/Escalation Percentage is Required");
            }
        }
        if (dataNew?.bill_cycle_date?.value === "Custom Date" && dataNew?.payment_period?.value !== "Prepaid") {
            if (dataNew?.custom_date?.length === 0 || dataNew?.custom_date === null) {
                isValid = false;
                error.custom_date = t("Custom Date is Required");
            }
        }

        
        if(dataNew?.auto_renewal === null ||  dataNew?.auto_renewal === ""){
            isValid = false;
            error.auto_renewal = t("Auto Renewals is Required");
      
          }
          if(dataNew?.agreement_lock === null ||  dataNew?.agreement_lock === ""){
            isValid = false;
            error.agreement_lock = t("Agreement Lock In Period is Required");
      
          }
          if(dataNew?.wallet_carry_over === "" || dataNew?.wallet_carry_over === null){
            isValid = false
            error.wallet_carry_over = "Wallet Carry over is Required"
          }
        //   if(dataNew?.confirm_interest === "" || dataNew?.confirm_interest === null){
        //     isValid = false
        //     error.confirm_interest = "Confirm Interest is Required"
        //   }
          if(dataNew?.bill_cycle_method === "" || dataNew?.bill_cycle_method === null){
            isValid = false
            error.bill_cycle_method = "Billing Cycle Method is Required"
          }
          if(dataNew?.renewal === null ||  dataNew?.renewal === ""){
            isValid = false;
            error.renewal = t("Renewals is Required");
          }
          if(dataNew?.allow_extension === null ||  dataNew?.allow_extension === ""){
            isValid = false;
            error.allow_extension = t("Allow Extension is Required");
          }


        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //previous btn function
    const previous = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };
    //create shortlist
    const createShortlist = (id, unitIds, isMsg = false) => {
      const units = unitIds?.map((val) => {
        return {
          unit_id: val?.id,
          lead_id: id,
          is_active: true,
        };
      });
      NetworkCall(
        `${config?.api_url}/queries/opportunity/create/shortlisted`,
        NetWorkCallMethods.post,
        {
          data: units,
        },
        null,
        true,
        false
      )
        .then((rs) => {
          if (!isMsg) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Shortlist Successfully Created"),
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };
    //next function if newlead
    const goNew = () => {

        if (step === 0) {
            setStep(1)
            setOpperEdit(true)
        } else if (step === 1) {
            if (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") {
                if (validateLeadDetails()) {
                    setStep(2)
                }
            } else if (dataNew?.revenue_type?.value === "Sale") {
                if (validateSaleOppertunity()) {
                    setStep(2)
                }
            }
            setOpperEdit(true)
        } else if (step === 2) {
            if (dataNew?.lead_id?.length > 0) {
                setStep(3)
                setOpperEdit(false)
            } else {
                upsertLead()
            }
        } else if (step === 3) {
            if (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") {
                if (validateLeaseQuotationDetails()) {
                    setStep(4)
                }
            } else if (dataNew?.revenue_type?.value === "Sale") {
                if (validateSaleQuotationDetails()) {
                    setStep(4)
                }
            }
        } else if (step === 4) {
            setStep(5)
        } else if (step === 5) {
            history.push(props?.isOppertunity ? Routes.oppertunityQuotation : Routes.quotation)
        }
    }
    //next function if existinglead
    const goExisting = () => {
        if (step === 1) {
            if (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") {
                if (validateLeaseQuotationDetails() && checkBillingDate()) {
                    setStep(2)
                }
            } else if (dataNew?.revenue_type?.value === "Sale") {
                if (validateSaleQuotationDetails()) {
                    setStep(2)
                }
            }
            setOpperEdit(true)
        }
        else if (step === 2) {
            setStep(3)
            setOpperEdit(false)
        } if (step === 3) {
            history.push(props?.isOppertunity ? Routes.oppertunityQuotation : Routes.quotation)
        }
    }
    //next btn function
    const next = () => {

        if (dataNew.type === "new lead") {
            goNew()
        } else if (dataNew.type === "existing lead") {
            goExisting()

        }

    };
    const upsertLead = ()=>{
        let unit_id = []
        const prospect = returnContactCreatePayload(dataNew);
        let lead = returnLeadCreatePayload(
          dataNew,
          selectedAccount,
        );
        if(dataNew?.units?.length > 0){
          const units = makeArrayUniqueByProperty(dataNew?.units, 'id')
          unit_id =  units?.map((val) => {
            return {
              unit_id: val?.id,
              company_id: dataNew?.company,
              is_active: true,
              property_id: val?.property_id ?? null
            };
          })
        }
        const payload = {
          prospective:prospect,
          opportunity:lead,
          units:unit_id,
          is_response:true
        }
        NetworkCall(
          `${config?.api_url}/queries/opportunity/upsert`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).then((rs) => {
          setDataNew({
            ...dataNew,
            lead_id: rs?.data?.data?.id,
            lead_no: rs?.data?.data?.lead_no,
            quotation_billing_start_date: dataNew?.billing_start_date,
            quotation_turn_over_rent: dataNew?.turn_over_rent_type,
            quotation_lease_duration: dataNew?.duration,
            quotation_payment_peroid: dataNew?.payment_period,
            quotation_payment_option: dataNew?.payment_option,
            quotation_billing_cycle: dataNew?.bill_cycle_method,
            quotation_billing_cycle_date: dataNew?.bill_cycle_date,
            quotation_renewal_based_on: dataNew?.renewal_based_on,
            quotation_auto_renewal: dataNew?.auto_renewal,
            quotation_escalculation_percent: dataNew?.esclation_percentage,
            quotation_include_market_place: dataNew?.market_places,
            quotation_custom_date: dataNew?.custom_date,
            quotation_revenue_based_lease: dataNew?.revenue_base_lease,
            quotation_grace_period: dataNew?.grace_peroid,
            quotation_handover_date: dataNew?.earlist_occupation_date,
            quotation_milestone: dataNew?.delivery_timestone_template,
            quotation_contract_start_date: dataNew?.contract_start_date,
            name: dataNew?.name,
            email_id: dataNew?.email_id,
            mobile: dataNew?.mobile,
            grace_peroid: dataNew?.grace_peroid ? dataNew?.grace_peroid : null,
            bill_cycle_date:
                dataNew?.bill_cycle_date?.length > 0
                    ? dataNew?.bill_cycle_date
                    : null,
            custom_date: dataNew?.custom_date ?? "",
            shortlist_convert: true
        });
        setStep(3)
        setHiddenBtn1(true)
        }).catch((err) => {
          console.log(err)
          setHiddenBtn1(true)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        })
      }

    return (
        <CreateQuotationListContext.Provider
            value={{
                step,
                previous,
                next,
                updateStateNew,
                dataNew,
                setDataNew,
                hideBtn,
                setHideBtn,
                setShow,
                show,
                setShow1,
                show1,
                setSelectedAccount,
                selectedAccount,
                createShortlist,
                hidenBtn1,
                t,
                opperEdit, 
                setOpperEdit
            }}
        >
            {props.children}
        </CreateQuotationListContext.Provider>
    );
};

export default withNamespaces("createQuotation")(CreateQuotationContextProvider)