import React, { useState } from "react"
import { MyOrgStyle } from "./style"
import { Box, Stack, Typography, Avatar } from "@mui/material"
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { AlertProps, NetWorkCallMethods, stringAvatar } from "../../utils";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
export const HistoryViewCard = ({ formdata = "", t }) => {
    const [historyData, setHistoryData] = React.useState([])
    const [isLoading, setIsLoading] = useState(true);
    const classes = MyOrgStyle()
    const [offset, setOffset] = React.useState(0)
    const alert = React.useContext(AlertContext)
    React.useEffect(() => {
        getHistoryData()
        // eslint-disable-next-line
    }, [])
    // infinity scroll function for history
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getHistoryData();
    };
    //Get History 
    const getHistoryData = (offset = 0) => {
        const payload = {
            org_management_id: formdata?.id,
            limit: 10,
            offset: offset
        };
        NetworkCall(
            `${config.api_url}/my_organisation/history`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((res) => {
                setHistoryData(res?.data?.data)
                setIsLoading(false);
            }).catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    }
    return (
        <Box className={classes.historyCard}>
            <Box className={classes.role}
                style={{ background: "#EBFBFF", color: "#2CA2BF" }}
            >{isLoading ? historyData?.[0]?.role_name : ""}</Box>
            <InfiniteScroll
                dataLength={historyData?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={300}
            >
                {isLoading
                ? <Typography sx={{ textAlign: "center" }}>{`${t("Loading")}...`}</Typography>
                : (historyData?.length > 0 
                    ? historyData?.map((details, i) => {
                    return (
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <Box className={classes.historydot}></Box>
                                <Box display="flex">
                                    <Typography className={classes.historyTitle}>{t("Assigned On")}</Typography>
                                    <Typography className={classes.titlecontent}>{moment(details?.assigned_at).format("DD-MM-YYYY")}</Typography>
                                </Box>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                {historyData.length - 1 !== i ?
                                    <Box className={classes.dashedborder}></Box>
                                    :
                                    <Box className={classes.noborder} />
                                }
                                <Avatar src={details?.image_url ?? ""} className={classes.avatarStyle} {...stringAvatar(details?.first_name)} />
                                <Box className={classes.detailsBlock}>
                                    <Typography className={classes.memberName}>{`${details?.first_name ?? "-"}`}
                                    </Typography>
                                    <Typography className={classes.subdetails}>{`${details?.mobile_no_country_code ?? ""} ${details?.mobile_no ?? "-"}`}</Typography>
                                    <Typography className={classes.subdetails}>{details?.email_id ?? "-"}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    )
                })
                    : <Typography sx={{ textAlign: "center" }}>{t("No History Found")}</Typography>)
                }
            </InfiniteScroll>
        </Box>
    )
}