import React from "react"

export const LeadCreatedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.739" viewBox="0 0 20 19.739">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_53779" data-name="Rectangle 53779" width="20" height="19.739" transform="translate(0 0)" fill="#316ab4" />
                </clipPath>
            </defs>
            <g id="Group_102054" data-name="Group 102054" transform="translate(0 0)">
                <g id="Group_102047" data-name="Group 102047" transform="translate(0 0)" clip-path="url(#clip-path)">
                    <path id="Path_96787" data-name="Path 96787" d="M17.27,15.157c.077,2.014-1.157,3.065-3.129,2.539a1.654,1.654,0,0,0-2.1.775c-1.151,1.666-2.895,1.693-4.026.058A1.733,1.733,0,0,0,5.826,17.7c-1.944.526-3.217-.552-3.051-2.591a1.691,1.691,0,0,0-1.116-1.95c-1.916-.905-2.2-2.536-.708-4.037a1.553,1.553,0,0,0,.4-2.076c-.914-1.9-.111-3.384,1.977-3.6A1.749,1.749,0,0,0,5.095,1.915,2.225,2.225,0,0,1,8.8.566,1.8,1.8,0,0,0,11.248.543a2.207,2.207,0,0,1,3.647,1.3,1.827,1.827,0,0,0,1.833,1.619c2.016.2,2.827,1.681,1.958,3.514a1.645,1.645,0,0,0,.408,2.192c1.436,1.437,1.128,3.053-.657,4-1.219.646-1.219.646-1.167,1.995" transform="translate(0 0)" fill="#5078e1" />
                    <path id="Path_96788" data-name="Path 96788" d="M30.125,26.769a1.261,1.261,0,0,1-.5.84c-1.485,1.486-2.985,2.958-4.451,4.463a1,1,0,0,1-1.691-.008c-.656-.708-1.372-1.361-2.046-2.054-.34-.349-.6-.744-.162-1.2s.827-.249,1.205.089c.062.056.117.121.178.178,2.047,1.879,1.333,2,3.4-.064q1.338-1.337,2.68-2.668a.772.772,0,0,1,.907-.255.744.744,0,0,1,.478.682" transform="translate(-15.719 -19.438)" fill="#fbfbfb" />
                </g>
            </g>
        </svg>

    )
}