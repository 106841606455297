import { Box, Dialog, Divider, Grid, Pagination, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router";
import NoDataFound from "../../assets/noData";
import { TotalIcon } from "../../assets/serviceHub/Total";
import { InspectionItemIcon } from "../../assets/serviceHub/inspectionItem";
import { ServiceOfferedIcon } from "../../assets/serviceHub/serviceOffered";
import { UnitWithAssetsIcon } from "../../assets/serviceHub/unitWithAssets";
import { DialogDrawer, SearchFilter, Subheader } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { JobType, NetWorkCallMethods, accessCheckRender, getCalendarOptions, getCompanyOption, getRoutePermissionNew } from "../../utils";
import { CardDetails } from "../inspectionNewDashboard/components/card";
// import { GraphComponent } from "./components/graphComponent";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
import { DynamicTabs } from "./components/tab";
import ServiceHubMapView from "./mapView";
import { DashboardStyles } from "./style";

const ServiceHubDashboard = ({ t }) => {
    const history = useHistory()
    const classes = DashboardStyles()
    const [selectedCompany, setSelectedCompany] = React.useState("");
    const [calendarOptions, setCalendarOptions] = React.useState([])
    const [companyList, setCompanyList] = React.useState([])
    const [searchText, setSearchText] = React.useState("")
    const [selectedCalendar, setSelectedCalendar] = React.useState({})
    const [tabValue, setTabValue] = React.useState(1)
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const [zoom, setZoom] = useState(false);
    const [zoomGraph, setZoomGraph] = useState("");
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const TabList = [
        {
            title: t("Maintenance"),
            value: 1,
            count: 20
        },
        {
            title: t("General"),
            value: 2,
            count: 10
        },

    ]
    const [permission, setPermission] = React.useState({});
    const [propertyData, setPropertyData] = React.useState([])
    const [propertyValue, setPropertyValue] = React.useState("")
    const [showMap, setShowMap] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const matches = useMediaQuery('(min-width:1920px)');
    const mobileStyle = useMediaQuery('(max-width:600px)');
    const [details, setDetails] = React.useState({
        requestData: [],
        requestCount: 0
    })
    const [data, setData] = React.useState([])

    const CardData = [
        {
            icon: <ServiceOfferedIcon />,
            count: data?.service_item,
            title: t("Services Offered")
        },
        {
            icon: <InspectionItemIcon />,
            count: data?.inspection_item,
            title: t("Inspections Items")
        },
        {
            icon: <UnitWithAssetsIcon />,
            count: data?.mapping_item,
            title: t("Units with Assets and Services")
        },
        {
            image: "../images/inventoryAsset.png",
            count: data?.product_item,
            title: t("Inventory and Asset Items")
        },
        {
            icon: <TotalIcon />,
            count: data?.total_items,
            title: t("Total Assets & Inventory Items Configured")
        },
    ]
    //pagination
    const returnPageNumber = () => {
        //check if props.rows have a index key
        const checkIndexKeyInType = details?.requestData?.some((_) => _?.index);
        if (details?.requestData?.length && checkIndexKeyInType) {
            if (details?.requestData?.length === 1) {
                return `${details?.requestData?.[0]?.index} of ${tabValue === 1 ? details?.maintenanceCount : details?.generalCount}`;
            } else {
                let lastCountIndex = details?.requestData?.length - 1;
                return `${details?.requestData?.[0]?.index} - ${details?.requestData?.[lastCountIndex]?.index} of ${tabValue === 1 ? details?.maintenanceCount : details?.generalCount}`;
            }
        }
        return "";
    };

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getPropertyDropdown(company?.selected?.value)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    //get properties based on company
    const getPropertyDropdown = (company = []) => {
      let payload = {
        company_ids: [company],
        offset:0,
        limit:null
      };
      NetworkCall(
        `${config.api_url}/queries/property/list`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((res) => {
        const result = [{ label: "All Properties", value: null }];
        setPropertyData(result.concat(res?.data?.data?.property));
        setPropertyValue(result?.[0]);
        let tempCalendarOptions = getCalendarOptions();
        setCalendarOptions(tempCalendarOptions);
        setSelectedCalendar(tempCalendarOptions[6]);
        getDashboardData(
          company,
          result?.[0]?.value,
          tempCalendarOptions[6].value.from_date,
          tempCalendarOptions[6].value.to_date
        );
        getRequestDetails(
          company,
          0,
          10,
          result?.[0]?.value,
          tempCalendarOptions[6].value.from_date,
          tempCalendarOptions[6].value.to_date,
          tabValue,
          searchText
        );
      });
    };
    //get maintenance-general request list
    const getRequestDetails = (company_id = "", offset = 0, limit = 10, property_id = [], start_date = "", end_date = "", type = 1, search = "", fetchMore = false) => {
        const payload = {
            "property": property_id,
            "company_id": company_id,
            "start_date": start_date,
            "end_date": end_date,
            "start": offset,
            "length": limit,
            "search": search,
            "type": type
        };
        NetworkCall(
            `${config.api_url}/inspection_manager/general_maintanence_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {

                setLoading(false)
                if (fetchMore) {
                    setDetails({ ...details, requestData: details?.requestData.concat(res?.data?.data?.list) })
                }
                else {

                    setDetails({ ...details, requestData: res?.data?.data?.list, maintenanceCount: res?.data?.data?.maintance_count, generalCount: res?.data?.data?.general_count })
                }


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }
    //get dashboard widgets data
    const getDashboardData = (company_id = "", property_id = "", start_date = "", end_date = "") => {
        const payload = {
            "company_id": company_id,
            "property": property_id,
            "start_date": start_date,
            "end_date": end_date,
        };
        NetworkCall(
            `${config.api_url}/inspection_manager/inspection_hub`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setLoading(false)
                //    setDetails({...details,requestData:res?.data?.data,requestCount:{requestType:type,count:res?.data?.data?.length}})
                setData(res?.data?.data)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }
    // Function to change the Calendar
    const handleCalendarChange = (date) => {
        if (date?.load) {
            setSelectedCalendar(date)
            getDashboardData(selectedCompany?.value, propertyValue?.value, date.value.from_date, date.value.to_date)
            getRequestDetails(selectedCompany?.value, 0, 10, propertyValue?.value, date.value.from_date, date.value.to_date, tabValue, "")
        }
    }
    //handleCompanyChange
    const handleCompanyChange = (val) => {
        setSelectedCompany(val)
        getDashboardData(val?.value, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
        getPropertyDropdown(val?.value)
    }
    //handlePropertyChange
    const onPropertyChange = (val) => {
        setPropertyValue(val)
        getDashboardData(selectedCompany?.value, val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
        getRequestDetails(selectedCompany?.value, 0, 10, val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, tabValue, "")
    }
    //handleTabChange
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        getRequestDetails(selectedCompany?.value, 0, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, newValue, "")

    }
    //handleSearch
    const handleSearch = (value) => {
        setSearchText(value)
        getRequestDetails(selectedCompany?.value, 0, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, tabValue, value)

    }

    // go to request page 
    const goToRequestPage = (val) => {
        history.push({
            pathname: tabValue === 1 ? Routes?.maintanceRequestView : Routes?.generalRequestView,
            state: {
                main: {
                    maintenance_id: tabValue === 1 ? val?.id : undefined,
                    general_request_id: tabValue === 2 ? val?.id : undefined,
                    value: val?.id,
                    request: tabValue === 1 ? "Maintenance" : "General"
                }
            }
        })
    }
    //handleMap view
    const handleMapView = () => {
        setShowMap(true)
    }
    // zoom function
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };
    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };
    const handlePagination = (value) => {
        setPage(value);
        setLimit(10)
        let offset = (value - 1) * limit;
        getRequestDetails(selectedCompany?.value, offset, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, tabValue, "")
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("inspectionManagementServiceHub")}
                    select options={companyList}
                    value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
                    calanderselect
                    calendarOptions={calendarOptions} calanderValue={selectedCalendar}
                    onChangeCalendar={(e) => handleCalendarChange(e)}
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    selectProperty
                    propertyOptions={propertyData}
                    propertyValue={propertyValue}
                />
                <Box className={classes.root}>
                    {
                        loading ?
                            <NewLoader minusHeight="100px" />
                            :
                            <>
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Grid container>
                                                <Box className={classes.customCard}>
                                                    <Grid container>
                                                        <Grid item xs={12} md={6} lg={6}>
                                                            <GraphComponent
                                                                title={"Maintenance Request"}
                                                                header={t("maintenanceRequest")}
                                                                graphData={data?.maintenance_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                                isPie
                                                                noStyle
                                                                isZoom
                                                                onZoomClick={zoomGraphFunction}
                                                                margin={"36px"}
                                                                innerRadius={70}
                                                                isTotal
                                                                t={t}
                                                                total={data?.maintenance_type?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                                textMargin={'0px 0px 16px'}
                                                                divider1={
                                                                    {
                                                                        xs: 12,
                                                                        sm: 12,
                                                                        md: 12,
                                                                        lg: 12
                                                                    }
                                                                }
                                                                divider={
                                                                    {
                                                                        xs: 12,
                                                                        sm: 12,
                                                                        md: 12,
                                                                        lg: 12
                                                                    }
                                                                }
                                                                gradiantStyle={{
                                                                    margin: '0px 0px 0px',
                                                                    display: "auto",
                                                                    alignItems: "center",
                                                                    top: {
                                                                        lg: "auto",
                                                                        md: '16px',
                                                                        sm: "3px",
                                                                    },
                                                                    height: {
                                                                        md: "400px"
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={6}>
                                                            <GraphComponent
                                                                boxShadow={false}
                                                                title={"By Category"}
                                                                header={t("By Category")}
                                                                graphData={data?.maintenance_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                                isPie
                                                                noStyle
                                                                margin={"20px"}
                                                                innerRadius={70}
                                                                isTotal
                                                                total={data?.maintenance_category?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                                textMargin={'0px 0px 16px'}
                                                                divider1={
                                                                    {
                                                                        xs: 12,
                                                                        sm: 12,
                                                                        md: 12,
                                                                        lg: 12
                                                                    }
                                                                }
                                                                divider={
                                                                    {
                                                                        xs: 12,
                                                                        sm: 12,
                                                                        md: 12,
                                                                        lg: 12
                                                                    }
                                                                }
                                                                gradiantStyle={{
                                                                    margin: '15px 0px 0px',
                                                                    display: "auto",
                                                                    alignItems: "center",
                                                                    top: {
                                                                        lg: "auto",
                                                                        md: '16px',
                                                                        sm: "3px",
                                                                    },
                                                                    height: {
                                                                        md: "400px"
                                                                    }
                                                                }}
                                                                t={t}

                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </Box>
                                                <Grid container className={classes.customCard} mt={2}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <GraphComponent
                                                            title={"General Request"}
                                                            header={t("General Request")}
                                                            graphData={data?.general_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                            isPie
                                                            noStyle
                                                            margin={"36px"}
                                                            innerRadius={70}
                                                            isTotal
                                                            isZoom
                                                            onZoomClick={zoomGraphFunction}
                                                            total={data?.general_type?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                            textMargin={'0px 0px 16px'}
                                                            divider1={
                                                                {
                                                                    xs: 12,
                                                                    sm: 12,
                                                                    md: 12,
                                                                    lg: 12
                                                                }
                                                            }
                                                            divider={
                                                                {
                                                                    xs: 12,
                                                                    sm: 12,
                                                                    md: 12,
                                                                    lg: 12
                                                                }
                                                            }
                                                            gradiantStyle={{
                                                                margin: '0px 0px 0px',
                                                                display: "auto",
                                                                alignItems: "center",
                                                                top: {
                                                                    lg: "auto",
                                                                    md: '16px',
                                                                    sm: "3px",
                                                                },
                                                                height: {
                                                                    md: "400px"
                                                                }
                                                            }}
                                                            t={t}

                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <GraphComponent
                                                            title={"By Category"}
                                                            header={t("By Category")}
                                                            graphData={data?.general_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                            isPie
                                                            noStyle
                                                            margin={"20px"}
                                                            innerRadius={70}
                                                            isTotal
                                                            total={data?.general_category?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                            textMargin={'0px 0px 16px'}
                                                            divider1={
                                                                {
                                                                    xs: 12,
                                                                    sm: 12,
                                                                    md: 12,
                                                                    lg: 12
                                                                }
                                                            }
                                                            divider={
                                                                {
                                                                    xs: 12,
                                                                    sm: 12,
                                                                    md: 12,
                                                                    lg: 12
                                                                }
                                                            }
                                                            gradiantStyle={{
                                                                margin: '0px 0px 0px',
                                                                display: "auto",
                                                                alignItems: "center",
                                                                top: {
                                                                    lg: "auto",
                                                                    md: '16px',
                                                                    sm: "3px",
                                                                },
                                                                height: {
                                                                    md: "400px"
                                                                }
                                                            }}
                                                            t={t}

                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <Box className={classes.tabBlock} height="786px">
                                                <DynamicTabs tabList={TabList} value={tabValue} handleChange={handleTabChange} count={details} handleMapView={handleMapView} t={t} />
                                                <Box mt={2}>
                                                    <SearchFilter placeholder={t("Search Request ID, Request Name, Category")} value={searchText} handleChange={(e) => handleSearch(e)} />
                                                </Box>
                                                {details?.requestData?.length > 0 ?
                                                    <>

                                                        {/* <InfiniteScroll
                                                            dataLength={details?.requestData?.length ?? ""}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            height={450}
                                                        > */}
                                                        <Box mt={2}>
                                                            {details?.requestData?.map((val, i) => {
                                                                return (
                                                                    <Box style={{ cursor: "pointer" }} onClick={() => goToRequestPage(val)}>
                                                                        <Typography className={classes.serviceTitle}>{val?.name}</Typography>
                                                                        <Stack direction="row"
                                                                            alignItems="center"
                                                                            spacing={1}
                                                                        >
                                                                            {mobileStyle ?
                                                                                <>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap>{val?.property_name}</Typography>
                                                                                    <Box className={classes.seperateDot}></Box>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap>{val?.category_name}</Typography>
                                                                                    <Box className={classes.seperateDot}></Box>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap>&#x202a;{moment(val?.created_at).format("DD MMM YY")}&#x202c;</Typography>
                                                                                </>
                                                                                : <>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap>{val?.property_name}</Typography>
                                                                                    <Box className={classes.seperateDot}></Box>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap>{val?.category_name}</Typography>
                                                                                    <Box className={classes.seperateDot}></Box>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap>&#x202a;{moment(val?.created_at).format("DD MMM YY")}&#x202c;</Typography>
                                                                                    <Box className={classes.seperateDot}></Box>
                                                                                    <Typography className={classes.serviceSubTxt} noWrap style={{ width: "250px" }}>{val?.description}</Typography>
                                                                                </>
                                                                            }
                                                                        </Stack>
                                                                        {details?.requestData?.length - 1 !== i &&
                                                                            <Box mt={1} mb={1}><Divider /></Box>
                                                                        }
                                                                    </Box>

                                                                )
                                                            })}

                                                        </Box>
                                                        {/* </InfiniteScroll> */}
                                                        <>
                                                            <div className={classes.serviceHubpagination}>
                                                                <div className={classes.flexRow}>
                                                                    <p className={classes.typo}>Rows per page :10</p>

                                                                </div>
                                                                <div className={classes.flexRow}>
                                                                    {details?.requestData?.length > 0 && (
                                                                        <p className={classes.typo}>{returnPageNumber()}</p>
                                                                    )}
                                                                    <Pagination
                                                                        className={classes.paginate}
                                                                        shape="rounded"
                                                                        count={tabValue === 1 ? Math.ceil(details?.maintenanceCount / limit) : Math.ceil(details?.generalCount / limit)}
                                                                        page={page}
                                                                        size={mobileStyle ? "small" : "medium"}
                                                                        // onChange={handleChange}
                                                                        onChange={(e, value) => handlePagination(value)}
                                                                        color="primary"
                                                                    // showFirstButton
                                                                    // showLastButton
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    </>
                                                    :
                                                    <Box mt={"10%"} display={"flex"} justifyContent={"center"}>
                                                        <NoDataFound />
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <GraphComponent
                                                        title={"Work Jobs"}
                                                        header={t("Work Jobs")}
                                                        graphData={data?.inspection_types?.map((val) => { return { name: JobType(val?.type), count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                        isPie
                                                        margin={"20px"}
                                                        innerRadius={70}
                                                        matches={matches}
                                                        isTotal
                                                        total={data?.inspection_types?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                        textMargin={'0px 0px 16px'}
                                                        divider1={
                                                            {
                                                                xs: 12,
                                                                sm: 12,
                                                                md: 12,
                                                                lg: 12
                                                            }
                                                        }
                                                        divider={
                                                            {
                                                                xs: 12,
                                                                sm: 12,
                                                                md: 12,
                                                                lg: 12
                                                            }
                                                        }
                                                        gradiantStyle={{
                                                            margin: '0px 0px 0px',
                                                            display: "auto",
                                                            alignItems: "center",
                                                            top: {
                                                                lg: "auto",
                                                                md: '16px',
                                                                sm: "3px",
                                                            },
                                                            height: {
                                                                md: "400px"
                                                            }
                                                        }}
                                                        onZoomClick={zoomGraphFunction}
                                                        isZoom
                                                        t={t}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <GraphComponent
                                                        title={"Unmanaged 3rd Party Requests"}
                                                        header={t("Unmanaged 3rd Party Requests")}
                                                        graphData={data?.third_party_request?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                        height={"278px"}
                                                        isPie
                                                        margin={"20px"}
                                                        innerRadius={70}
                                                        isTotal
                                                        onZoomClick={zoomGraphFunction}
                                                        isZoom
                                                        total={data?.third_party_request?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                        textMargin={'0px 0px 16px'}
                                                        divider1={
                                                            {
                                                                xs: 12,
                                                                sm: 12,
                                                                md: 12,
                                                                lg: 12
                                                            }
                                                        }
                                                        divider={
                                                            {
                                                                xs: 12,
                                                                sm: 12,
                                                                md: 12,
                                                                lg: 12
                                                            }
                                                        }
                                                        gradiantStyle={{
                                                            margin: '0px 0px 0px',
                                                            display: "auto",
                                                            alignItems: "center",
                                                            top: {
                                                                lg: "auto",
                                                                md: '16px',
                                                                sm: "3px",
                                                            },
                                                            height: {
                                                                md: "400px"
                                                            }
                                                        }}
                                                        t={t}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Grid container spacing={2}>
                                                {CardData?.map((x) => {
                                                    return (
                                                        <Grid item xs={6} md={4} lg={4}>
                                                            <CardDetails data={x} maxHeight={"120px"} />
                                                        </Grid>
                                                    )
                                                })}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                    }
                </Box>
                {/* zoom dialog for each graph */}
                <DialogDrawer
                    header={["Maintenance Request", "General Request"]?.includes(zoomGraph) ? "" : t(zoomGraph)}
                    // isnotTitle={["Maintenance Request", "General Request"]?.includes(zoomGraph)}
                    maxWidth={"sm"}
                    handleClose={handleCloseModule}
                    open={zoom}
                    height={"auto"}
                    borderRadius={"12px"}
                    padding={'0px'}
                    onClose={handleCloseModule}
                    component={
                        <div>
                            {zoomGraph === "Unmanaged 3rd Party Requests" && (
                                <GraphComponent
                                    graphData={data?.third_party_request?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                    total={data?.third_party_request?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                    centerTitle="Overdue"
                                    isPie
                                    innerRadius={100}
                                    isTotal
                                    margin={"0px"}
                                    minWidth={false}
                                    paddingAngle={2}
                                    padding={"16px"}
                                    maxHeightGraph={"250px"}
                                    height={"auto"}
                                    is_popUp
                                    justifyContent={'start'}
                                    centerTop={"44%"}
                                    t={t}

                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }
                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '0px',
                                            sm: "auto",
                                        },
                                        bottom: {
                                            sm: "auto",
                                            md: "-16px",
                                            lg: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                            )}

                            {zoomGraph === "Work Jobs" && (
                                <GraphComponent
                                    graphData={data?.inspection_types?.map((val) => { return { name: JobType(val?.type), count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                    total={data?.inspection_types?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                    isPie
                                    innerRadius={100}
                                    isTotal
                                    margin={"0px"}
                                    minWidth={false}
                                    paddingAngle={2}
                                    padding={"16px"}
                                    maxHeightGraph={"250px"}
                                    height={"auto"}
                                    is_popUp
                                    justifyContent={'start'}
                                    centerTop={"44%"}
                                    t={t}

                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }
                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '0px',
                                            sm: "auto",
                                        },
                                        bottom: {
                                            sm: "auto",
                                            md: "-16px",
                                            lg: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                            )}
                            {zoomGraph === "Maintenance Request" && (
                                <Box className={classes.customCard}>
                                    <Grid container>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <GraphComponent
                                                title={"Maintenance Request"}
                                                header={t("Maintenance Request")}
                                                graphData={data?.maintenance_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                isPie
                                                t={t}

                                                noStyle
                                                margin={"20px"}
                                                innerRadius={70}
                                                isTotal
                                                total={data?.maintenance_type?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                textMargin={'0px 0px 16px'}
                                                divider1={
                                                    {
                                                        xs: 12,
                                                        sm: 12,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                divider={
                                                    {
                                                        xs: 12,
                                                        sm: 12,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                gradiantStyle={{
                                                    margin: '0px 0px 0px',
                                                    display: "auto",
                                                    alignItems: "center",
                                                    top: {
                                                        lg: "auto",
                                                        md: '16px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        md: "400px"
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <GraphComponent
                                                t={t}

                                                boxShadow={false}
                                                title={"By Category"}
                                                header={t("By Category")}
                                                graphData={data?.maintenance_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                                isPie
                                                noStyle
                                                margin={"20px"}
                                                innerRadius={70}
                                                isTotal
                                                total={data?.maintenance_category?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                                textMargin={'0px 0px 16px'}
                                                divider1={
                                                    {
                                                        xs: 12,
                                                        sm: 12,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                divider={
                                                    {
                                                        xs: 12,
                                                        sm: 12,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                gradiantStyle={{
                                                    margin: '15px 0px 0px',
                                                    display: "auto",
                                                    alignItems: "center",
                                                    top: {
                                                        lg: "auto",
                                                        md: '16px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        md: "400px"
                                                    }
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Box>
                            )}
                            {zoomGraph === "General Request" && (
                                <Grid container className={classes.customCard}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <GraphComponent
                                            t={t}

                                            title={"General Request"}
                                            header={t("General Request")}
                                            graphData={data?.general_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                            isPie
                                            noStyle
                                            margin={"20px"}
                                            innerRadius={70}
                                            isTotal
                                            total={data?.general_type?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                            textMargin={'0px 0px 16px'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            gradiantStyle={{
                                                margin: '0px 0px 0px',
                                                display: "auto",
                                                alignItems: "center",
                                                top: {
                                                    lg: "auto",
                                                    md: '16px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    md: "400px"
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <GraphComponent
                                            t={t}

                                            title={"By Category"}
                                            header={t("By Category")}
                                            graphData={data?.general_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                            isPie
                                            noStyle
                                            margin={"20px"}
                                            innerRadius={70}
                                            isTotal
                                            total={data?.general_category?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                            textMargin={'0px 0px 16px'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            gradiantStyle={{
                                                margin: '0px 0px 0px',
                                                display: "auto",
                                                alignItems: "center",
                                                top: {
                                                    lg: "auto",
                                                    md: '16px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    md: "400px"
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>)}
                        </div>
                    }
                />
                <Dialog
                    fullScreen
                    open={showMap}
                >

                    <ServiceHubMapView
                        company_id={selectedCompany?.value}
                        property_id={propertyValue?.value}
                        date={selectedCalendar}
                        setShowMap={setShowMap}
                        data={data}
                        t={t}
                    />


                </Dialog>
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
}

export default withNamespaces("inspection")(ServiceHubDashboard)