import React from "react"

export const LeadUnArchievedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_107673" data-name="Group 107673" transform="translate(-121 -503)">
          <circle id="Ellipse_39517" data-name="Ellipse 39517" cx="20" cy="20" r="20" transform="translate(121 503)" fill="#dbebff"/>
          <g id="Group_105494" data-name="Group 105494" transform="translate(21624 -4302)">
            <rect id="Rectangle_55517" data-name="Rectangle 55517" width="20" height="20" transform="translate(-21493 4815)" fill="none"/>
            <path id="icons8-archive-list-of-parts" d="M8.6,3a.6.6,0,1,0,0,1.2h9.2a.6.6,0,1,0,0-1.2Zm0,2A1.8,1.8,0,0,0,6.8,6.8v5.8H9.825a1.4,1.4,0,0,1,1.391,1.237,2,2,0,0,0,3.969,0A1.4,1.4,0,0,1,16.575,12.6H19.6V6.8A1.8,1.8,0,0,0,17.8,5Zm1.6,2.4h3.6a.6.6,0,0,1,0,1.2H10.2a.6.6,0,0,1,0-1.2Zm6,0a.6.6,0,1,1-.6.6A.6.6,0,0,1,16.2,7.4Zm-6,2.8h3.6a.6.6,0,1,1,0,1.2H10.2a.6.6,0,0,1,0-1.2Zm6,0a.6.6,0,1,1-.6.6A.6.6,0,0,1,16.2,10.2ZM6.6,13.4A.6.6,0,0,0,6,14v2.4A2.6,2.6,0,0,0,8.6,19h9.2a2.6,2.6,0,0,0,2.6-2.6V14a.6.6,0,0,0-.6-.6H16.575a.6.6,0,0,0-.6.53,2.8,2.8,0,0,1-5.557,0,.6.6,0,0,0-.6-.53Z" transform="translate(-21496.199 4814)" fill="#5078e1" stroke="rgba(0,0,0,0)" stroke-width="1"/>
          </g>
        </g>
      </svg>
      

    )
}