import React from 'react'
import { Stack, Checkbox, Grid, Avatar, Typography, Box } from '@mui/material'
import { ProjectStyle } from '../style'
import { CloseIconWithBG } from '../../../assets'
import { ProjectContext } from '../projectContext'

export const SelectedMemberCard = ({
    data = "",
    index = ""
}) => {
    const classes = ProjectStyle()

    const {
        addMember,
        setAddMember
    } = React.useContext(ProjectContext)

    const updateSecondObject = (type, value) => {
        const newData = [...addMember?.projectMember];
        newData[index][`${type}`] = value;
        setAddMember({
            ...addMember,
            projectMember: newData
        });
    };

    const deleteRow = (value) => {
        setAddMember({
            ...addMember,
            projectMember: addMember?.projectMember?.filter((i) => i?.id !== value?.id)
        });
    }

    return (
        <>
            <Grid container className={classes.smCardBox}>
                <Grid item xs={6}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Avatar className={classes.customOptionAvatar}></Avatar>
                        <Stack>
                            <Typography className={classes.optionHeader}>{data?.label}</Typography>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <Typography className={classes.optionSubTitle}>{data?.reference_id}</Typography>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.optionSubTitle}>{data?.profession_name}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <center>
                        <Checkbox
                            checked={data?.follower}
                            onChange={(e) => updateSecondObject("follower", e.target.checked)}
                        // inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </center>
                </Grid>
                <Grid item xs={2}>
                    <center>
                        <Checkbox
                            checked={data?.member}
                            onChange={(e) => updateSecondObject("member", e.target.checked)}
                        // inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </center>
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Box onClick={() => deleteRow(data)}>
                        <CloseIconWithBG />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}