import { Box } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { AlertContext } from "../../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const TodoForm = ({ t, data = {}, setData = () => false, oppertunity_id = "", reload = () => false, onClose = () => false, id = null }) => {
    const alert = React.useContext(AlertContext);
    const [disable, setDisable] = React.useState(false);

    //update state
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //validate 
    const validateTodo = () => {
        let isValid = true;
        let error = data.error;
        if (data?.name?.length === 0) {
            isValid = false;
            error.name = t("Name is Required");
        }
        if (data?.description?.length === 0) {
            isValid = false;
            error.description = t("Description is Required");
        }
        setData({ ...data, error });
        return isValid;
    };
    //onSubmit
    const onSubmit = () => {
        if (validateTodo()) {
            setDisable(true)
            NetworkCall(
                `${config.api_url}/queries/opportunity/lead_checklist/upsert`,
                NetWorkCallMethods.post,
                {
                   
                    insert_params: {
                        lead_id: oppertunity_id,
                        name: data?.name,
                        description: data?.description,
                        is_active: false,
                    }

                },
                null,
                true,
                false
              ).then((response) => {
                setDisable(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Todo Successfully Created"),
                });
                reload();
                onClose()

            }).catch((err) => {
                setDisable(false)
                console.log(err)
            })
        }
    }
    //on edit
    const onEdit = () => {
        if (validateTodo()) {
            NetworkCall(
                `${config.api_url}/queries/opportunity/lead_checklist/upsert`,
                NetWorkCallMethods.post,
                {
                    insert_params: {
                        id: id,
                        name: data?.name,
                        description: data?.description,
                    }
                },
                null,
                true,
                false
              )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Todo Successfully Updated",
                });
                reload();
                onClose()

            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const formData = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Name"),
            value: data?.name,
            placeholder: t("Enter Name"),
            onChange: (value) => updateState("name", value?.target.value),
            error: data?.error?.name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Description"),
            value: data?.description,
            placeholder: t("Enter Description"),
            onChange: (value) => updateState("description", value?.target.value),
            error: data?.error?.description,
            isRequired: true,
            multiline: true
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "button",
            label: id ? t("Update") : t("Create"),
            onClick: (value) => id ? onEdit() : onSubmit(),
            disable:disable

        }
    ]
    return (
        <Box p={3}>
            <FormGenerator t={t} components={formData} />
        </Box>
    )
}