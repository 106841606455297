import * as React from "react"
const ProjectLayoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    data-name="Group 121844"
    {...props}
  >
    <path
      fill="#a4b2c1"
      d="M8.929 0a.553.553 0 0 0-.554.553v.545H3.78a.531.531 0 0 0-.182 0h-.66a.545.545 0 1 0 0 1.089H3.1a17.78 17.78 0 0 1-.56 4.622A4.721 4.721 0 0 1 .748 9.544a.536.536 0 0 0-.221.611.561.561 0 0 0 .537.384h2.061v.908a1.671 1.671 0 0 0 1.688 1.634h2.172l-1.167 2.1a.53.53 0 0 0 .006.554.572.572 0 0 0 .984-.037l1.454-2.619h1.352l1.454 2.619a.572.572 0 0 0 .984.037.53.53 0 0 0 .006-.554l-1.167-2.1h2.172a1.671 1.671 0 0 0 1.688-1.634v-9.26h.188a.545.545 0 1 0 0-1.089h-.659a.581.581 0 0 0-.182 0H9.5V.553A.536.536 0 0 0 9.335.16.572.572 0 0 0 8.929 0ZM4.223 2.187h4.622a.581.581 0 0 0 .182 0H13.6a17.779 17.779 0 0 1-.56 4.622 4.767 4.767 0 0 1-1.7 2.641H2.428a6.846 6.846 0 0 0 1.19-2.335 18.6 18.6 0 0 0 .605-4.928Zm9.4 6.185v3.075a.51.51 0 0 1-.562.545h-3.028a.548.548 0 0 0-.176 0H8.012a.538.538 0 0 0-.167 0H4.813a.51.51 0 0 1-.562-.545v-.908h7.313a.576.576 0 0 0 .316-.094 5.1 5.1 0 0 0 1.745-2.072Z"
    />
  </svg>
)
export default ProjectLayoutIcon
