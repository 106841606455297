import moment from "moment";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CityProperty from '../../assets/cityproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, allowed_file_size, enumName, enumSelect, enum_types, ListingTypeOptions, NetWorkCallMethods, unitCategoryMaster, deleteS3File, LocalStorageKeys } from "../../utils";
import { assestType, concat_string, convertTimeUtcToZoneCalander, getCustomBlockName, getCustomFloorName, getPropertyHierarchyUnitRule, getRoutePermissionNew, mapResult, singleFileUpload, stringAvatar } from '../../utils/common';
import { Assests } from '../propertcreation/createproperty/assests';
import { useStylesCreation } from "./tabs/style";
import { UnitDetails } from './tabs/unitDetails';
import { withNamespaces } from "react-i18next";
import { Avatar, Stack, Typography } from "@mui/material";

const initial = {
      propertyID: "",
      blockID: "",
      pet_policy: { value: "Not Allowed", label: "Not Allowed" },
      execution_status: { value: "Plan", label: "Plan" },
      floorID: "",
      unitID: "",
      image: "",
      company: "",
      unitName: "",
      status: { value: true, label: "Active" },
      description: "",
      propertyType: "",
      unitPurpose: "",
      revenueType: "",
      unitCategory: "",
      parentUnitID: "",
      unitFurnishingType: { label: "Unfurnished", value: "Unfurnished" },
      unitType: "",
      unitCurrentStatus: { label: "Vacant", value: "Vacant" },
      measurementUnit: "",
      carpetArea: "",
      totalArea: "",
      govtUtilityNumbers: "",
      yearBuilt: "",
      handoverDate: "",
      orientation: { label: "None", value: "None" },
      annualRentOrSalesAmount: "",
      numberOfRoom: "",
      numberOfBed: "",
      numberOfBaths: "",
      paymentPeriod: "",
      publicListing: ListingTypeOptions[0],
      petsAllowed: false,
      doorNo: "",
      addressLineOne: "",
      addressLineTwo: "",
      landmark: "",
      area: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      businessPhone: "",
      mobilePhone: "",
      website: "",
      emailAddress: "",
      isBlock: "",
      isFloor: "",
      showAllUnits: false,
      noOfBalcony: "",
      balconyArea: "",
      ownerAccount: "",
      buildupArea: "",
      terraceArea: "",
      assetFunc: { value: true, label: "Yes" },
      assetProperty: { value: true, label: "Yes" },
      shortTermRental: { value: true, label: "Yes" },
      underPromotion: { value: true, label: "Yes" },
      seat: null,
      landArea: "",
      masterRoomSize: "",
      livingRoomSize: "",
      kitchenRoomSize: "",
      guestRoomSize: "",
      marketPrice: "",
      titleDeed: "",
      deedDate: "",
      hijriDate: "",
      childrenRoomSize: "",
      servantRoomSize: "",
      smartGeoAddress: "",
      shareHolders: [],
      registrationOffice: "",
      is_external: false,
      unitContracts: [],
      unitProjects: [],
      error: {
            image: "",
            company: "",
            unitName: "",
            description: "",
            propertyType: "",
            unitPurpose: "",
            revenueType: "",
            unitCategory: "",
            parentUnitID: "",
            unitFurnishingType: "",
            unitType: "",
            measurementUnit: "",
            carpetArea: "",
            totalArea: "",
            govtUtilityNumbers: "",
            yearBuilt: "",
            handoverDate: "",
            orientation: "",
            annualRentOrSalesAmount: "",
            numberOfRoom: "",
            numberOfBed: "",
            numberOfBaths: "",
            paymentPeriod: "",
            businessPhone: "",
            mobilePhone: "",
            website: "",
            emailAddress: "",
            buildupArea: "",
            terraceArea: "",
            assetFunc: "",
            assetProperty: "",
            shortTermRental: "",
            underPromotion: "",
            seat: "",
            landArea: "",
            masterRoomSize: "",
            livingRoomSize: "",
            kitchenRoomSize: "",
            guestRoomSize: "",
            marketPrice: "",
            titleDeed: "",
            deedDate: "",
            hijriDate: "",
            childrenRoomSize: "",
            servantRoomSize: "",
            smartGeoAddress: "",
            shareHolders: [],
            registrationOffice: "",
            unitContracts: "",
            unitProjects: "",
      }
}

let included_category_purpose =
      [enumName?.residential, enumName?.commercial, enumName?.stay, enumName?.facilities];

// Residential, Room, Bed, Parking, Storage, Land, Plot, Facility, Device
let included_residential_unit_category = [2, 10, 6, 8, 7, 1, 5, 11, 14];

// Commercial, Land, Plot, Advertising, Parking, Storage, Coworking, Facility, Warehouse, Device
let included_commercial_unit_category = [4, 1, 5, 3, 8, 7, 9, 11, 12, 14];

// Residential, Parking, Room, Bed, Commercial, Device
let included_stay_unit_category = [2, 8, 10, 6, 4, 14];

// Facility, Device
let included_facilities_unit_category = [11, 14];

const CreateUnit = ({
      t
}) => {
      const localStorageCompany = localStorage?.getItem?.(LocalStorageKeys?.selectedCompany);
      const selectedCompany = localStorageCompany ? JSON?.parse?.(localStorageCompany) : "";
      const userProfileID = localStorage.getItem("profileID");
      const location = useLocation();
      const main = location?.state?.main ?? null;
      const backdrop = React.useContext(BackdropContext);
      const [data, setData] = React.useState(JSON.parse(JSON.stringify(initial)))
      const [value, setValue] = React.useState(1);
      const [images, setImages] = React.useState([]);
      const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
      const [mapLoad, setMapLoad] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const history = useHistory();
      const classes = useStylesCreation();
      const [load, setLoad] = React.useState(false)
      const [initialLoad, setInitialLoad] = React.useState(false)
      const [permission, setPermission] = React.useState({})
      const [disable, setDisable] = React.useState(true)
      const [deleted_s3_urls, set_deleted_s3_urls] = React.useState([]);
      const [finalShareHolders, setFinalShareHolders] = React.useState([]);
      const [finalUnitContracts, setFinalUnitContracts] = React.useState([]);
      const [finalUnitProjects, setFinalUnitProjects] = React.useState([]);
      const [options, setOptions] = React.useState({
            revenue_type: [],
            purpose_global_type: [],
            payment_period: [],
            furnish: [],
            orientation: [],
            pet_policy_type: [],
            area_metric_type: [],
            listing_type: [],
            unit_execution_status: []
      })

      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (initialLoad) {
                              if (main?.unitID) {
                                    getUnitDetails();
                              } else if (main?.floorID) {
                                    getPropertyDetailsByFloorID(main?.floorID)
                              } else if (main?.blockID) {
                                    getPropertyByBlockID(main?.blockID)
                              } else if (main?.propertyID) {
                                    getPropertyById(main?.propertyID)
                              }
                        }
                  }
            }
            // eslint-disable-next-line
      }, [auth, initialLoad, options]);

      React.useEffect(() => {
            getEnum()
            // eslint-disable-next-line
      }, [])

      const getUnitDetails = () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });
            const payload = { unitId: main?.unitID };

            NetworkCall(
                  `${config?.api_url}/queries/unit/get`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then(async (response)=> {
                  let tempUnit = response?.data?.data?.unit?.[0] ?? null;
                  const responseShareHolders = response?.data?.data?.share_holder ?? [];
                  const responseUnitContracts = response?.data?.data?.unit_contracts ?? [];
                  const responseUnitProjects = response?.data?.data?.unit_projects ?? [];
                  setImages(response?.data?.data?.assets);
                  let tempUnitCategory = ""
                  tempUnitCategory = tempUnit?.unit_categoryByID?.value ?
                        { ...tempUnit?.unit_categoryByID, ...unitCategoryMaster[tempUnit?.unit_categoryByID?.value] } : ""

                  let contact_payload = {
                        filter: { contact_ids: responseShareHolders?.map?.(_ => _?.contact_id) },
                        is_full_list: true,
                  }

                  const shareHolderContactsResponse = await NetworkCall(
                        `${config.api_url}/contact/list`,
                        NetWorkCallMethods.post, contact_payload, null, true, false
                  ).catch(() => {
                        alert.setSnack({
                              ...alert, open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                        });
                  })

                  const shareHolderContacts = shareHolderContactsResponse?.data?.data ?? [];
                
                  let contract_payload = {
                        contract_type: [9],
                        company_id: selectedCompany?.value,
                        filter: { contract_ids: responseUnitContracts?.map?.(_ => _?.contract_id) },
                        is_full_list: true,
                  }

                  const unitContractsResponse = await NetworkCall(
                        `${config.api_url}/contract/get_all_contract`,
                        NetWorkCallMethods.post, contract_payload, null, true, false
                  ).catch((e) => {
                        alert.setSnack({
                              ...alert, open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                        });
                  })

                  const unitContracts = unitContractsResponse?.data?.data ?? [];

                  let project_payload = {
                        company_id: selectedCompany?.value,
                        filter: { project_ids: responseUnitProjects?.map?.(_ => _?.project_id) },
                        is_full_list: true,
                  }

                  const unitProjectsResponse = await NetworkCall(
                        `${config.api_url}/project/list`,
                        NetWorkCallMethods.post, project_payload, null, true, false
                  ).catch((e) => {
                        alert.setSnack({
                              ...alert, open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                        });
                  })

                  const unitProjects = unitProjectsResponse?.data?.data?.data ?? [];
                
                  setData({
                        ...data,
                        propertyID: tempUnit?.property_id,
                        blockID: {
                              label: tempUnit?.block?.label,
                              value: tempUnit?.block?.value,
                        },
                        floorID: {
                              label: tempUnit?.floor?.label,
                              value: tempUnit?.floor?.value,
                        },
                        unitID: tempUnit?.id,
                        image: tempUnit?.logo,
                        property_hierarchy: tempUnit?.propertyByID?.property_hierarchyByID,
                        company: tempUnit?.propertyByID?.companyByID ?? "",
                        unitName: tempUnit?.name ?? "",
                        status: { value: tempUnit?.is_active ? true : false, label: tempUnit?.is_active ? "Active" : "In-Active" },
                        description: tempUnit?.description ?? "",
                        propertyType: tempUnit?.propertyByID?.property_groupByID ?? "",
                        unitPurpose: tempUnit?.unit_purpose
                              ? options?.purpose_global_type?.find?.(_ => tempUnit?.unit_purpose === _?.value)
                              : "",
                        revenueType: tempUnit?.revenue_type ?
                              { value: tempUnit?.revenue_type, label: tempUnit?.revenue_type } : "",
                        unitCategory: tempUnitCategory,
                        unitFurnishingType: tempUnit?.furnishing ?
                              { value: tempUnit?.furnishing, label: tempUnit?.furnishing } : "",
                        unitType: tempUnit?.unit_type ?? "",
                        unitCurrentStatus: tempUnit?.status ?
                              { value: tempUnit?.status, label: tempUnit?.status } : "",
                        measurementUnit: tempUnit?.uomByID ? {
                              label: tempUnit?.uomByID?.label,
                              value: tempUnit?.uomByID?.value
                        } : "",
                        carpetArea: tempUnit?.carpet_area ?? "",
                        totalArea: tempUnit?.total_area ?? "",
                        govtUtilityNumbers: tempUnit?.govt_utility_no ?? "",
                        yearBuilt: new Date(tempUnit?.year_built) ?? "",
                        handoverDate: new Date(tempUnit?.planned_hand_over_date) ?? "",
                        orientation: tempUnit?.orientation ?
                              { value: tempUnit?.orientation ?? "", label: tempUnit?.orientation ?? "" } : "",
                        annualRentOrSalesAmount: tempUnit?.lease_or_sale_value ?? "",
                        numberOfRoom: tempUnit?.total_rooms ?? "",
                        numberOfBed: tempUnit?.total_bed_rooms ?? "",
                        numberOfBaths: tempUnit?.total_baths ?? "",
                        paymentPeriod: tempUnit?.payment_period ?
                              { value: tempUnit?.payment_period ?? "", label: tempUnit?.payment_period ?? "" } : "",
                        publicListing: tempUnit?.listing_status ?
                              { value: tempUnit?.listing_status ?? "", label: tempUnit?.listing_status ?? "" } : "",
                        pet_policy: tempUnit?.pet_policy ?
                              { value: tempUnit?.pet_policy ?? "", label: tempUnit?.pet_policy ?? "" } : "",
                        execution_status: tempUnit?.execution_status ?
                              { value: tempUnit?.execution_status ?? "", label: tempUnit?.execution_status ?? "" } : "",
                        petsAllowed: tempUnit?.is_pet_allowed ?? "",
                        parentUnitID: (tempUnit?.parentUnit && tempUnit?.parentUnit?.length > 0) ?
                              { value: tempUnit?.parentUnit?.[0]?.id, label: tempUnit?.parentUnit?.[0]?.name + " - " + tempUnit?.parentUnit?.[0]?.unit_no } : "",
                        doorNo: tempUnit?.addressByID?.door_no ?? "",
                        addressLineOne: tempUnit?.addressByID?.street_1 ?? "",
                        addressLineTwo: tempUnit?.addressByID?.street_2 ?? "",
                        landmark: tempUnit?.addressByID?.landmark ?? "",
                        area: tempUnit?.addressByID?.area ?? "",
                        city: tempUnit?.addressByID?.city ?? "",
                        state: tempUnit?.addressByID?.state ?? "",
                        country: tempUnit?.addressByID?.country ?? "",
                        zipcode: tempUnit?.addressByID?.zipcode ?? "",
                        latitude: parseFloat(tempUnit?.addressByID?.latitude) ?? "",
                        longitude: parseFloat(tempUnit?.addressByID?.longitude) ?? "",
                        businessPhone: {
                              mobile: tempUnit?.addressByID?.mobile_no ?? "",
                              mobile_code: tempUnit?.addressByID?.mobile_no_country_code ?? "",
                        },
                        mobilePhone: {
                              mobile: tempUnit?.addressByID?.alternative_mobile_no ?? "",
                              mobile_code: tempUnit?.addressByID?.alternative_mobile_no_country_code ?? "",
                        },
                        website: tempUnit?.propertyByID?.website ?? "",
                        emailAddress: tempUnit?.addressByID?.email ?? "",
                        noOfBalcony: tempUnit?.balconies,
                        balconyArea: tempUnit?.balcony_area,
                        ownerAccount: tempUnit?.ownerByID ?? tempUnit?.propertyByID?.ownerByID ?? "",
                        buildupArea: tempUnit?.build_up_area ?? "",
                        terraceArea: tempUnit?.terrace_area ?? "",
                        assetFunc: {
                              value: tempUnit?.is_asset_functional_location ? true : false,
                              label: tempUnit?.is_asset_functional_location ? t("Yes") : t("No")
                        },
                        assetProperty: {
                              value: tempUnit?.is_asset_property ? true : false,
                              label: tempUnit?.is_asset_property ? t("Yes") : t("No")
                        },
                        shortTermRental: {
                              value: tempUnit?.is_short_term_rental ? true : false,
                              label: tempUnit?.is_short_term_rental ? t("Yes") : t("No")
                        },
                        underPromotion: {
                              value: tempUnit?.is_under_promotion ? true : false,
                              label: tempUnit?.is_under_promotion ? t("Yes") : t("No")
                        },
                        seat: tempUnit?.seat_count ?? null,
                        is_seat: tempUnit?.is_seat ?? false,
                        landArea: tempUnit?.whole_land_area,
                        masterRoomSize: tempUnit?.master_bedroom_size,
                        livingRoomSize: tempUnit?.living_room_size,
                        kitchenRoomSize: tempUnit?.kitchen_size,
                        guestRoomSize: tempUnit?.guest_room_size,
                        marketPrice: tempUnit?.market_price,
                        titleDeed: tempUnit?.title_deed,
                        deedDate: tempUnit?.deed_date ? new Date(tempUnit?.deed_date) : "",
                        hijriDate: tempUnit?.hijri_date ? new Date(tempUnit?.hijri_date) : "",
                        childrenRoomSize: tempUnit?.children_room_size,
                        servantRoomSize: tempUnit?.servant_room_size,
                        smartGeoAddress: tempUnit?.addressByID?.smart_geo_address ?? "",
                        shareHolders: responseShareHolders?.map?.(_ => {
                              const shareHolderContact = shareHolderContacts?.find?.(__ => __?.id === _?.contact_id);

                              return {
                                    ..._,
                                    contact: {
                                          ...shareHolderContact,
                                          label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                                                <Avatar className={classes.optionAvatar}
                                                      src={shareHolderContact?.image_url} {...stringAvatar(shareHolderContact?.first_name)} />
                                                <Stack>
                                                      <Typography className={classes.optionHeader}>
                                                            {concat_string(
                                                                  {
                                                                        user_no: shareHolderContact?.user_no,
                                                                        first_name: shareHolderContact?.first_name,
                                                                  },
                                                                  ["user_no", "first_name"],
                                                                  " - "
                                                            )}
                                                      </Typography>
                                                </Stack>
                                          </Stack>),
                                          value: shareHolderContact?.id,
                                    },
                                    isDeleted: false,
                                    isEdited: false,
                              }
                        }),
                        unitContracts: responseUnitContracts?.map?.(_ => {
                          const unitContract = unitContracts?.find?.(__ => __?.id === _?.contract_id);
              
                          return {
                            ..._,
                            contract: {
                              ...unitContract,
                              label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                                <Avatar className={classes.optionAvatar}
                                  {...stringAvatar(unitContract?.contract_no)} />
                                <Stack>
                                  <Typography className={classes.optionHeader}>
                                    {unitContract?.contract_no ?? "-"}
                                  </Typography>
                                </Stack>
                              </Stack>),
                              value: unitContract?.id,
                            },
                            isDeleted: false,
                            isEdited: false,
                          }
                        }),
                        unitProjects: responseUnitProjects?.map?.(_ => {
                          const unitProject = unitProjects?.find?.(__ => __?.id === _?.project_id);
              
                          return {
                            ..._,
                            project: {
                              ...unitProject,
                              label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                                <Avatar className={classes.optionAvatar}
                                  {...stringAvatar(unitProject?.project_no)} />
                                <Stack>
                                  <Typography className={classes.optionHeader}>
                                    {unitProject?.project_no ?? "-"}
                                  </Typography>
                                </Stack>
                              </Stack>),
                              value: unitProject?.id,
                            },
                            isDeleted: false,
                            isEdited: false,
                          }
                        }),
                        registrationOffice: tempUnit?.registration_officeByID?.id
                              ? {
                                    label: <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                                          <Avatar className={classes.optionAvatar}
                                                src={tempUnit?.registration_officeByID?.image_url} {...stringAvatar(tempUnit?.registration_officeByID?.name)} />
                                          <Typography className={classes.optionHeader}>
                                                {concat_string(
                                                      {
                                                            vendor_no: tempUnit?.registration_officeByID?.vendor_no,
                                                            vendor_name: tempUnit?.registration_officeByID?.name,
                                                      },
                                                      ["vendor_no", "vendor_name"],
                                                      " - "
                                                )}
                                          </Typography>
                                    </Stack>,
                                    value: tempUnit?.registration_officeByID?.id,
                                    officeName: tempUnit?.registration_officeByID?.name ?? "",
                                    mobile: concat_string(
                                          {
                                                mobile_code: tempUnit?.registration_officeByID?.vendor_address?.[0]?.mobile_code,
                                                mobile_no: tempUnit?.registration_officeByID?.vendor_address?.[0]?.mobile_no,
                                          },
                                          ["mobile_code", "mobile_no"],
                                          " - ",
                                          "-"
                                    ),
                                    email: tempUnit?.registration_officeByID?.vendor_address?.[0]?.email_id ?? "",
                                    website: tempUnit?.registration_officeByID?.vendor_address?.[0]?.website ?? "",
                                    address: concat_string(
                                          {
                                                door_no: tempUnit?.registration_officeByID?.vendor_address?.[0]?.door_no,
                                                street_1: tempUnit?.registration_officeByID?.vendor_address?.[0]?.street_1,
                                                street_2: tempUnit?.registration_officeByID?.vendor_address?.[0]?.street_2,
                                                landmark: tempUnit?.registration_officeByID?.vendor_address?.[0]?.landmark,
                                                area: tempUnit?.registration_officeByID?.vendor_address?.[0]?.area,
                                                city: tempUnit?.registration_officeByID?.vendor_address?.[0]?.city,
                                                state: tempUnit?.registration_officeByID?.vendor_address?.[0]?.state,
                                                country: tempUnit?.registration_officeByID?.vendor_address?.[0]?.country,
                                                pincode: tempUnit?.registration_officeByID?.vendor_address?.[0]?.pincode,
                                          },
                                          [
                                                "door_no", "street_1", "street_2",
                                                "landmark", "area", "city",
                                                "state", "country", "pincode",
                                          ], ", ", "-"
                                    ),
                                    latitude: tempUnit?.registration_officeByID?.vendor_address?.[0]?.latitude,
                                    longitude: tempUnit?.registration_officeByID?.vendor_address?.[0]?.longitude,
                              }
                              : "",
                        is_external: tempUnit?.is_external,
                        error: initial?.error,
                  })
                  setLoad(true)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch(() => {


                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      const getPropertyDetailsByFloorID = (floor) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });

            let floorValue = typeof floor === "string" ? floor : floor?.value

            const payload = { floorId: floorValue };

            NetworkCall(
                  `${config?.api_url}/queries/floor/get`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                  let tempFloor = response?.data?.data?.floor?.[0] ?? null;
                  if (getPropertyHierarchyUnitRule(tempFloor?.propertyByID?.property_hierarchyByID, tempFloor?.unit?.[0]?.count)) {
                        setData({
                              ...data,
                              isBlock: tempFloor?.propertyByID?.is_block,
                              isFloor: tempFloor?.propertyByID?.is_floor,
                              company: tempFloor?.propertyByID?.companyByID,
                              propertyID: tempFloor?.property_id,
                              blockID: {
                                    label: tempFloor?.block?.label,
                                    value: tempFloor?.block?.value,
                              },
                              floorID: {
                                    value: tempFloor?.id,
                                    label: tempFloor?.name,
                              },
                              unitID: null,
                              image: tempFloor?.logo,
                              propertyHierarchy: tempFloor?.propertyByID?.property_hierarchy,
                              property_hierarchy: tempFloor?.propertyByID?.property_hierarchyByID,
                              propertyType: tempFloor?.propertyByID?.property_groupByID,
                              unitPurpose: (tempFloor?.floor_purpose &&
                                    included_category_purpose?.includes?.(tempFloor?.floor_purpose))
                                    ? options?.purpose_global_type?.find?.(_ => tempFloor?.floor_purpose === _?.value)
                                    : "",
                              paymentPeriod: {
                                    label: tempFloor?.propertyByID?.payment_period,
                                    value: tempFloor?.propertyByID?.payment_period,
                              },
                              revenueType: {
                                    label: tempFloor?.revenue_type,
                                    value: tempFloor?.revenue_type,
                              },
                              measurementUnit: tempFloor?.propertyByID?.uomByID ? {
                                    label: tempFloor?.propertyByID?.uomByID?.label,
                                    value: tempFloor?.propertyByID?.uomByID?.value
                              } : "",
                              doorNo: tempFloor?.addressByID?.door_no ?? "",
                              addressLineOne: tempFloor?.addressByID?.street_1 ?? "",
                              addressLineTwo: tempFloor?.addressByID?.street_2 ?? "",
                              landmark: tempFloor?.addressByID?.landmark ?? "",
                              area: tempFloor?.addressByID?.area ?? "",
                              city: tempFloor?.addressByID?.city ?? "",
                              yearBuilt: new Date(tempFloor?.year_built) ?? "",
                              handoverDate: new Date(tempFloor?.planned_hand_over_date) ?? "",
                              state: tempFloor?.addressByID?.state ?? "",
                              country: tempFloor?.addressByID?.country ?? "",
                              zipcode: tempFloor?.addressByID?.zipcode ?? "",
                              latitude: parseFloat(tempFloor?.addressByID?.latitude) ?? "",
                              longitude: parseFloat(tempFloor?.addressByID?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempFloor?.addressByID?.mobile_no ?? "",
                                    mobile_code: tempFloor?.addressByID?.mobile_no_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempFloor?.addressByID?.alternative_mobile_no ?? "",
                                    mobile_code: tempFloor?.addressByID?.alternative_mobile_no_country_code ?? "",
                              },
                              website: tempFloor?.propertyByID?.website ?? "",
                              emailAddress: tempFloor?.propertyByID?.email ?? "",
                              error: {
                                    ...initial?.error,
                                    floorID: "",
                                    blockID: "",
                              },
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: t("Unit creation is restricted."),
                        });
                        history.goBack(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch(() => {


                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again1"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      const getPropertyByBlockID = (block) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });

            let blockValue = typeof block === "string" ? block : block?.value

            const payload = { blockId: blockValue };

            NetworkCall(
                  `${config?.api_url}/queries/block/get`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                  let tempBlock = response?.data?.data?.block?.[0] ?? null;
                  if (getPropertyHierarchyUnitRule(tempBlock?.propertyByID?.property_hierarchyByID, tempBlock?.unit?.[0]?.count)) {
                        setData({
                              ...data,
                              isBlock: tempBlock?.propertyByID?.is_block,
                              isFloor: tempBlock?.propertyByID?.is_floor,
                              company: tempBlock?.propertyByID?.companyByID,
                              propertyID: tempBlock?.property_id,
                              blockID: {
                                    value: tempBlock?.id,
                                    label: tempBlock?.name,
                              },
                              floorID: null,
                              unitID: null,
                              image: tempBlock?.logo,
                              propertyHierarchy: tempBlock?.propertyByID?.property_hierarchy,
                              property_hierarchy: tempBlock?.propertyByID?.property_hierarchyByID,
                              propertyType: tempBlock?.propertyByID?.property_groupByID,
                              unitPurpose: (tempBlock?.block_purpose &&
                                    included_category_purpose?.includes?.(tempBlock?.block_purpose))
                                    ? options?.purpose_global_type?.find?.(_ => tempBlock?.block_purpose === _?.value)
                                    : "",
                              paymentPeriod: {
                                    label: tempBlock?.propertyByID?.payment_period,
                                    value: tempBlock?.propertyByID?.payment_period,
                              },
                              revenueType: {
                                    label: tempBlock?.revenue_type,
                                    value: tempBlock?.revenue_type,
                              },
                              measurementUnit: tempBlock?.propertyByID?.uomByID ? {
                                    label: tempBlock?.propertyByID?.uomByID?.label,
                                    value: tempBlock?.propertyByID?.uomByID?.value
                              } : "",
                              doorNo: tempBlock?.addressByID?.door_no ?? "",
                              addressLineOne: tempBlock?.addressByID?.street_1 ?? "",
                              addressLineTwo: tempBlock?.addressByID?.street_2 ?? "",
                              landmark: tempBlock?.addressByID?.landmark ?? "",
                              area: tempBlock?.addressByID?.area ?? "",
                              city: tempBlock?.addressByID?.city ?? "",
                              yearBuilt: new Date(tempBlock?.year_built) ?? "",
                              handoverDate: new Date(tempBlock?.planned_hand_over_date) ?? "",
                              state: tempBlock?.addressByID?.state ?? "",
                              country: tempBlock?.addressByID?.country ?? "",
                              zipcode: tempBlock?.addressByID?.zipcode ?? "",
                              latitude: parseFloat(tempBlock?.addressByID?.latitude) ?? "",
                              longitude: parseFloat(tempBlock?.addressByID?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempBlock?.addressByID?.mobile_no ?? "",
                                    mobile_code: tempBlock?.addressByID?.mobile_no_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempBlock?.addressByID?.alternative_mobile_no ?? "",
                                    mobile_code: tempBlock?.addressByID?.alternative_mobile_no_country_code ?? "",
                              },
                              website: tempBlock?.propertyByID?.website ?? "",
                              emailAddress: tempBlock?.propertyByID?.email ?? "",
                              error: initial?.error,
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: t("Unit creation is restricted."),
                        });
                        history.goBack(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch(() => {


                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again1"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      const getPropertyById = (property) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });

            const payload = { propertyID: property };

            NetworkCall(
                  `${config.api_url}/queries/property/get`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then(async (res) => {
                  let tempProperty = res?.data?.data?.property?.[0] ?? null;
                  if (getPropertyHierarchyUnitRule(tempProperty?.property_hierarchyByID, tempProperty?.unit_count?.[0]?.count_id)) {
                        setData({
                              ...data,
                              isBlock: tempProperty?.is_block,
                              isFloor: tempProperty?.is_floor,
                              company: tempProperty?.company,
                              image: tempProperty?.logo,
                              propertyID: tempProperty?.id,
                              propertyType: tempProperty?.property_groupByID ?
                                    {
                                          value: tempProperty?.property_groupByID?.id,
                                          label: tempProperty?.property_groupByID?.group_name
                                    } : "",
                              unitPurpose: (tempProperty?.property_purpose &&
                                    included_category_purpose?.includes?.(tempProperty?.property_purpose))
                                    ? options?.purpose_global_type?.find?.(_ => tempProperty?.property_purpose === _?.value)
                                    : "",
                              propertyHierarchy: tempProperty?.property_hierarchy,
                              property_hierarchy: tempProperty?.property_hierarchyByID,
                              paymentPeriod: {
                                    label: tempProperty?.payment_period,
                                    value: tempProperty?.payment_period,
                              },
                              revenueType: {
                                    label: tempProperty?.revenue_type,
                                    value: tempProperty?.revenue_type,
                              },
                              measurementUnit: tempProperty?.uomByID ? {
                                    label: tempProperty?.uomByID?.label,
                                    value: tempProperty?.uomByID?.value
                              } : "",
                              doorNo: tempProperty?.address?.door_no ?? "",
                              addressLineOne: tempProperty?.address?.street_1 ?? "",
                              addressLineTwo: tempProperty?.address?.street_2 ?? "",
                              landmark: tempProperty?.address?.landmark ?? "",
                              area: tempProperty?.address?.area ?? "",
                              city: tempProperty?.address?.city ?? "",
                              yearBuilt: new Date(tempProperty?.year_built) ?? "",
                              handoverDate: new Date(tempProperty?.planned_hand_over_date) ?? "",
                              state: tempProperty?.address?.state ?? "",
                              country: tempProperty?.address?.country ?? "",
                              zipcode: tempProperty?.address?.zipcode ?? "",
                              latitude: parseFloat(tempProperty?.address?.latitude) ?? "",
                              longitude: parseFloat(tempProperty?.address?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempProperty?.business_phone ?? "",
                                    mobile_code: tempProperty?.business_phone_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempProperty?.mobile_phone ?? "",
                                    mobile_code: tempProperty?.mobile_country_code ?? "",
                              },
                              website: tempProperty?.website ?? "",
                              emailAddress: tempProperty?.email ?? "",
                              ownerAccount: tempProperty?.ownerByID ?? "",
                              error: initial?.error,
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: t("Unit creation is restricted."),
                        });
                        history.goBack(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch(() => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      };

      const handleAssetFile = (data) => {
            if (data.asset_type === 3) {
                  setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
                  setImages(images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id));
            } else {
                  set_deleted_s3_urls([...deleted_s3_urls, data?.url]);
                  setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
                  setImages(images?.filter((val) => val?.url !== data?.url));
            }
      };
      const updateState = (k, v) => {

            let error = data?.error;

            error[k] = "";

            if (k === "unitPurpose") {
                  setData({
                        ...data, [k]: v, unitCategory: "", parentUnitID: "", numberOfRoom: "", numberOfBed: "",
                        numberOfBaths: "", carpetArea: "", buildupArea: "", totalArea: "", noOfBalcony: "",
                        balconyArea: "", terraceArea: "", unitFurnishingType: initial.unitFurnishingType,
                        orientation: initial.orientation, pet_policy: initial.pet_policy,
                        publicListing: initial?.publicListing, error
                  });
            } else if (k === "revenueType") {
                  setData({ ...data, [k]: v, handoverDate: "", error });
            } else if (k === "unitCategory") {
                  let tempData = {
                        ...data, [k]: v, parentUnitID: "", numberOfRoom: "", numberOfBed: "",
                        numberOfBaths: "", carpetArea: "", buildupArea: "", totalArea: "", noOfBalcony: "",
                        balconyArea: "", terraceArea: "", unitFurnishingType: initial.unitFurnishingType,
                        orientation: initial.orientation, pet_policy: initial.pet_policy,
                        publicListing: initial?.publicListing, error
                  }
                  switch (v.value) {
                        case 6: // Bed Space
                              setData({ ...tempData, numberOfRoom: "1", numberOfBed: "1", numberOfBaths: "1" });
                              break;
                        default: // Default
                              setData({ ...tempData });
                              break;
                  }
            } else if (k === "numberOfRoom") {
                  if (data?.unitCategory.value === 2) { // Residential Unit
                        setData({ ...data, [k]: v, numberOfBed: v, error });
                  } else { setData({ ...data, [k]: v, error }); }
            } else if (k === "deedDate") {
                  setData({ ...data, [k]: v, hijriDate: v, error });
            } else if (k === "registrationOffice") {
                  let registration_office_value = v;
                  if (v?.value) {
                        registration_office_value = {
                              ...v,
                              officeName: v?.name ?? "",
                              mobile: concat_string(
                                    {
                                          mobile_code: v?.mobile_code,
                                          mobile_no: v?.mobile_no,
                                    },
                                    ["mobile_code", "mobile_no"],
                                    " - ",
                                    "-"
                              ),
                              email: v?.email_id ?? "",
                              website: v?.website ?? "",
                              address: concat_string(
                                    {
                                          door_no: v?.door_no,
                                          street_1: v?.street_1,
                                          street_2: v?.street_2,
                                          landmark: v?.landmark,
                                          area: v?.area,
                                          city: v?.city,
                                          state: v?.state,
                                          country: v?.country,
                                          pincode: v?.pincode,
                                    },
                                    [
                                          "door_no", "street_1", "street_2",
                                          "landmark", "area", "city",
                                          "state", "country", "pincode",
                                    ], ", ", "-"
                              ),
                              latitude: v.latitude,
                              longitude: v.longitude,
                        };
                  }
                  setData({ ...data, [k]: registration_office_value, error });
            } else if (k === "project") {
                  let project_value = v;
                  if (v?.value) {
                        project_value = {
                              ...v,
                              title: v?.title ?? "",
                              project_no: v?.project_no ?? "",
                              planned_start_date: v?.planned_start_date ? convertTimeUtcToZoneCalander(v?.planned_start_date) : "",
                              planned_end_date: v?.planned_end_date ? convertTimeUtcToZoneCalander(v?.planned_end_date) : "",
                        };
                  }
                  setData({ ...data, [k]: project_value, error });
            } else { setData({ ...data, [k]: v, error }); }
      }

      const validate = () => {
            let isValid = true;
            let error = data.error;

            if (data?.property_hierarchy?.is_block) {
                  if (!main?.unitID && (Boolean(main?.propertyID) && !(!!data?.blockID?.value))) {
                        isValid = false;
                        error.blockID = getCustomBlockName(data?.property_hierarchy) + " is Required";
                  }
            }

            if (data?.property_hierarchy?.is_floor) {
                  if (!main?.unitID && ((Boolean(main?.propertyID) || Boolean(main?.blockID)) && !(!!data?.floorID?.value))) {
                        isValid = false;
                        error.floorID = getCustomFloorName(data?.property_hierarchy) + " is Required";
                  }
            }

            if (data?.company?.length === 0) { isValid = false; error.company = t("Company Name is Required"); }

            if (data?.unitName?.length === 0) { isValid = false; error.unitName = t("Unit Name is Required"); }

            if (data?.status?.length === 0) { isValid = false; error.status = t("Status is Required"); }

            if (data?.unitCurrentStatus?.length === 0) { isValid = false; error.unitCurrentStatus = t("Occupancy Status is Required"); }

            if (data?.propertyType?.length === 0) { isValid = false; error.propertyType = t("Property Type is Required"); }

            if (data?.unitPurpose?.length === 0) { isValid = false; error.unitPurpose = t("Unit Purpose is Required"); }

            if (data?.revenueType?.length === 0) { isValid = false; error.revenueType = t("Revenue Type is Required"); }

            if (data?.paymentPeriod?.length === 0) { isValid = false; error.paymentPeriod = t("Payment Period is Required"); }

            if (data?.unitCategory?.length === 0) { isValid = false; error.unitCategory = t("Unit Category is Required"); }

            if (data?.yearBuilt?.length === 0) { isValid = false; error.yearBuilt = t("Year Built is Required"); }

            if (data?.revenueType?.value === enumName.sale && data?.handoverDate?.length === 0) {
                  isValid = false; error.handoverDate = t("Handover Date is Required");
            }

            if (data?.unitType?.length === 0) { isValid = false; error.unitType = t("Unit Type ID is Required"); }

            if (data?.unitCategory?.field_permission?.is_total_rooms) {
                  if (data?.numberOfRoom?.length === 0) {
                        isValid = false; error.numberOfRoom = t("No. of Room is Required");
                  } else if ((parseFloat(data?.numberOfRoom) - parseInt(data?.numberOfRoom) > 0)) {
                        isValid = false; error.numberOfRoom = t("Enter Valid no. of Room");
                  } else if (parseInt(data?.numberOfRoom) < 1) {
                        isValid = false; error.numberOfRoom = t("Should not be less than 1");
                  }
            }

            if (data?.unitCategory?.field_permission?.is_total_baths) {
                  if (data?.numberOfBaths?.length === 0) {
                        isValid = false; error.numberOfBaths = t("No. of Baths is Required");
                  } else if ((parseFloat(data?.numberOfBaths) - parseInt(data?.numberOfBaths) > 0)) {
                        isValid = false; error.numberOfBaths = t("Enter Valid no. of Baths");
                  } else if (parseInt(data?.numberOfBaths) < 1) {
                        isValid = false; error.numberOfBaths = t("Should not be less than 1");
                  }
            }

            if (data?.measurementUnit?.length === 0) { isValid = false; error.measurementUnit = t("Measurement Unit is Required"); }

            if (data?.unitCategory?.field_permission?.is_carpet_area) {
                  if (data?.carpetArea?.length === 0) {
                  } else if (parseFloat(data?.carpetArea) <= 0) {
                        isValid = false; error.carpetArea = t("Should not be less than or equal 0");
                  } else if (parseFloat(data?.carpetArea) > parseFloat(data?.buildupArea)) {
                        isValid = false; error.carpetArea = t("Should not be greater than Buildup Area");
                  } else { error.carpetArea = ""; }
            }

            if (data?.unitCategory?.field_permission?.is_build_up_area) {
                  if (data?.buildupArea?.length === 0) {
                  } else if (parseFloat(data?.buildupArea) <= 0) {
                        isValid = false; error.buildupArea = t("Should not be less than or equal 0");
                  } else if (parseFloat(data?.buildupArea) > parseFloat(data?.totalArea)) {
                        isValid = false; error.buildupArea = t("Should not be greater than Super Buildup Area");
                  } else { error.buildupArea = ""; }
            }

            if (data?.unitCategory?.field_permission?.is_total_area) {
                  if (data?.totalArea?.length === 0) {
                        isValid = false; error.totalArea = t("Super Buildup Area is Required");
                  } else if (parseFloat(data?.totalArea) <= 0) {
                        isValid = false; error.totalArea = t("Should not be less than or equal 0");
                  } else if (parseFloat(data?.totalArea) < parseFloat(data?.buildupArea)) {
                        isValid = false; error.totalArea = t("Should not be less than Buildup Area");
                  } else { error.totalArea = ""; }
            }

            if (data?.unitCategory?.field_permission?.is_balconies) {
                  if (data?.noOfBalcony?.length === 0) {
                  } else if ((parseFloat(data?.noOfBalcony) - parseInt(data?.noOfBalcony) > 0)) {
                        isValid = false; error.noOfBalcony = t("Enter Valid no. of Balcony");
                  } else if (parseInt(data?.noOfBalcony) < 0) {
                        isValid = false; error.noOfBalcony = t("Should not be less than 0");
                  }
            }

            if (data?.unitCategory?.field_permission?.is_balcony_area && data?.noOfBalcony > 0) {
                  if (data?.balconyArea?.length === 0) {
                  } else if (parseFloat(data?.balconyArea) <= 0) {
                        isValid = false; error.balconyArea = t("Should not be less than or equal 0");
                  }
            }

            if (data?.unitCategory?.field_permission?.is_terrace_area) {
                  if (data?.terraceArea?.length === 0) {
                  } else if (parseFloat(data?.terraceArea) <= 0) {
                        isValid = false; error.terraceArea = t("Should not be less than or equal 0");
                  }
            }

            if (data?.unitCategory?.field_permission?.is_furnishing && data?.unitFurnishingType?.length === 0) {
                  isValid = false; error.unitFurnishingType = t("Unit Furnishing Type ID is Required");
            }

            if (data?.businessPhone?.length === 0) { isValid = false; error.businessPhone = "Business Phone is Required"; }

            if (data?.mobilePhone?.length === 0) { isValid = false; error.mobilePhone = t("Mobile Phone is Required"); }

            if (data?.website?.length === 0) { isValid = false; error.website = t("Website is Required"); }

            if (data?.emailAddress?.length === 0) { isValid = false; error.emailAddress = t("Email Address is Required"); }

            if ((data?.unitPurpose?.value === "Commercial" &&
                  data?.unitCategory?.value === 9)) {
                  if (data?.seat === null) { isValid = false; error.seat = t("Seat is Required"); }
            }

            if (!isValid) {
                  alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: t("Please fill all mandatory field"),
                  });
            }

            setData({ ...data, error });

            return isValid;
      };

      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }

      const next = () => {
            if (value === 1) {
                  if (validate()) {
                        const payload = {
                              unit_id: data?.unitID ?? undefined,
                              total_area: parseFloat(data?.totalArea) ?? undefined,
                              is_active: data?.status?.value ? true : false,
                              "size_type": "total_area_size",
                              "is_seat": (data?.unitPurpose?.value === "Commercial" && data?.unitCategory?.value === 9) ? true : false,
                              "seat_count": data?.seat,
                              "uom_id": data?.measurementUnit?.value
                        };
                        NetworkCall(
                              `${config.api_url}/unit/total_area_validation`,
                              NetWorkCallMethods.post,
                              payload,
                              null,
                              true,
                              false).then(() => {
                                    const current_date = new Date()?.toISOString();

                                    let tempShareHolders = [];
                                    let tempFinalShareHolders = [];

                                    if (data?.shareHolders?.length > 0) {
                                          tempShareHolders = [
                                                ...data?.shareHolders?.filter?.(_ => _?.isEdited)?.map?.(_ => {
                                                      let tempShareHolder = {
                                                            contact_id: _.contact.id,
                                                            name: _?.name,
                                                            email_id: _?.email_id,
                                                            mobile_no: _?.mobile_no,
                                                            mobile_no_country_code: _?.mobile_no_country_code,
                                                            is_active: _?.is_active,
                                                            is_delete: _?.is_delete,
                                                            updated_at: current_date,
                                                            updated_by: userProfileID,
                                                      };
                                                      if (_?.id) {
                                                            tempShareHolder["id"] = _?.id;
                                                      } else {
                                                            tempShareHolder["created_at"] = current_date;
                                                            tempShareHolder["created_by"] = userProfileID;
                                                      }
                                                      if (_?.value?.length > 0) {
                                                            tempShareHolder["value"] = parseFloat(_?.value);
                                                      }
                                                      return tempShareHolder;
                                                })
                                          ]
                                    }

                                    if (tempShareHolders?.length > 0) {
                                          tempFinalShareHolders = [
                                                ...tempShareHolders?.filter?.(_ => !(!_?.is_active && _?.is_delete && !Boolean(_?.id)))
                                          ];
                                          setFinalShareHolders(tempFinalShareHolders);
                                    }

                                    let tempUnitContracts = [];
                                    let tempFinalUnitContracts = [];

                                    if (data?.unitContracts?.length > 0) {
                                          tempUnitContracts = [
                                                ...data?.unitContracts?.filter?.(_ => _?.isEdited)?.map?.(_ => {
                                                      let tempUnitContract = {
                                                            contract_id: _.contract.id,
                                                            is_active: _?.is_active,
                                                            is_delete: _?.is_delete,
                                                            updated_at: current_date,
                                                            updated_by: userProfileID,
                                                      };
                                                      if (_?.id) {
                                                            tempUnitContract["id"] = _?.id;
                                                      } else {
                                                            tempUnitContract["created_at"] = current_date;
                                                            tempUnitContract["created_by"] = userProfileID;
                                                      }
                                                      return tempUnitContract;
                                                })
                                          ]
                                    }

                                    if (tempUnitContracts?.length > 0) {
                                          tempFinalUnitContracts = [
                                                ...tempUnitContracts?.filter?.(_ => !(!_?.is_active && _?.is_delete && !Boolean(_?.id)))
                                          ];
                                          setFinalUnitContracts(tempFinalUnitContracts);
                                    }

                                    let tempUnitProjects = [];
                                    let tempFinalUnitProjects = [];

                                    if (data?.unitProjects?.length > 0) {
                                          tempUnitProjects = [
                                                ...data?.unitProjects?.filter?.(_ => _?.isEdited)?.map?.(_ => {
                                                      let tempUnitProject = {
                                                            project_id: _.project.id,
                                                            is_active: _?.is_active,
                                                            is_delete: _?.is_delete,
                                                            updated_at: current_date,
                                                            updated_by: userProfileID,
                                                      };
                                                      if (_?.id) {
                                                            tempUnitProject["id"] = _?.id;
                                                      } else {
                                                            tempUnitProject["created_at"] = current_date;
                                                            tempUnitProject["created_by"] = userProfileID;
                                                      }
                                                      return tempUnitProject;
                                                })
                                          ]
                                    }

                                    if (tempUnitProjects?.length > 0) {
                                          tempFinalUnitProjects = [
                                                ...tempUnitProjects?.filter?.(_ => !(!_?.is_active && _?.is_delete && !Boolean(_?.id)))
                                          ];
                                          setFinalUnitProjects(tempFinalUnitProjects);
                                    }

                                    let totalShare = 0.0;
                                    tempFinalShareHolders?.forEach(_ => {
                                          totalShare = totalShare + parseFloat(_?.value);
                                    })

                                    if (totalShare > 100) {
                                          alert.setSnack({
                                                ...alert,
                                                open: true,
                                                severity: AlertProps.severity.error,
                                                msg: t("Total Share % should not exceed 100%"),
                                          });
                                    } else {
                                          setDisable(false);
                                          setValue(2)
                                    }
                              }).catch((error) => {
                                    if (error?.response?.status === 406)
                                          alert.setSnack({
                                                ...alert,
                                                open: true,
                                                severity: AlertProps.severity.error,
                                                msg: error.response?.data?.message === "seat_size Limit Exceed" ? t("Seat count limit exceed") : `${t("Total Area Size (super built up area size)")} ${t("Limit Exceeded")} ${t("Please upgrade your plan!")}`,
                                                vertical: AlertProps.vertical.top,
                                                horizontal: AlertProps.horizontal.center,
                                          });
                                    console.log(error)
                              });
                  } else {
                        return false;
                  }
            }
      };
      const previous = () => {
            setValue(prevValue => prevValue - 1);
            if (!main?.unitID) {
                  setMapLoad(false)
            }
      };
      const updateimg = async (image) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            try {
                  const uploaded_file = await singleFileUpload(image, { companyId: data?.company?.value, type: assestType?.Thumbnails }, alert, allowed_file_size)
                  if (uploaded_file?.[0]?.url) {
                        updateState("image", uploaded_file?.[0]?.url)
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  } else {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  }
            }
            catch (err) {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: t("Some Thing Went Wrong"),
                  });
            }

      }
      const removeimg = () => {
            set_deleted_s3_urls([...deleted_s3_urls, data?.image]);
            setData({ ...data, image: "" })
      }
      const tabTittle = [
            {
                  label: t("Unit Details"),
                  sub: t("Enter Unit, Address & Contact Details"),
                  imgSelect: value === 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle

            },
            {
                  label: t("Attachments"),
                  sub: t("Upload assets like Image, 360 Image, Vid.."),
                  imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]

      const save = () => {
            deleteS3File({ urls: deleted_s3_urls });

            if (validate()) {
                  const payload = {
                        tenantId: `${config.tenantId}`,
                        company_id: data?.company?.value ?? null,
                        property_id: data?.propertyID ?? null,
                        block_id: data?.blockID?.value ?? null,
                        floor_id: data?.floorID?.value ?? null,
                        unit_id: data?.unitID ?? null,
                        name: data?.unitName ?? null,
                        logo: data?.image?.length > 0 ? data?.image : null,
                        carpet_area: parseFloat(data?.carpetArea) ?? null,
                        total_area: parseFloat(data?.totalArea) ?? null,
                        total_bed_rooms: parseInt(data?.numberOfBed) ?? null,
                        is_active: data?.status?.value ? true : false,
                        is_room: data?.parentUnitID?.value ? true : false,

                        unit_type: data?.unitType?.value ?? null,
                        parent_unit_id: data?.parentUnitID?.value ?? null,
                        unit_purpose: data?.unitPurpose?.value ?? null,
                        furnishing: data?.unitFurnishingType?.value ?? null,
                        status: data?.unitCurrentStatus?.value ?? null,
                        revenue_type: data?.revenueType?.value ?? null,
                        unit_category_id: data?.unitCategory?.value ?? null,
                        year_built: data?.yearBuilt ? moment(data?.yearBuilt).format("yyyy-MM-DD") : null,
                        planned_hand_over_date: (data?.revenueType?.value === enumName.sale && data?.handoverDate) ?
                              moment(data?.handoverDate).format("yyyy-MM-DD") : null,
                        description: data?.description ?? null,
                        orientation: data?.orientation.value ?? null,
                        pet_policy: data?.pet_policy?.value ?? null,
                        execution_status: data?.execution_status?.value ?? null,
                        total_rooms: parseInt(data?.numberOfRoom) ?? null,
                        total_baths: parseInt(data?.numberOfBaths) ?? null,
                        payment_period: data?.paymentPeriod?.value ?? null,
                        latitude: parseFloat(data?.latitude) ?? null,
                        longitude: parseFloat(data?.longitude) ?? null,
                        mobile_phone: data?.mobilePhone?.mobile,
                        business_phone_country_code: data?.businessPhone?.mobile_code ?? "+91",
                        business_phone: data?.businessPhone?.mobile,
                        mobile_country_code: data?.mobilePhone?.mobile_code,
                        website: data?.website,
                        email: data?.emailAddress ?? null,
                        assetsData: main?.isEdit
                              ? images?.filter((val) => !val?.id).map(del => {
                                    delete del.__typename
                                    return {
                                          id: del?.id,
                                          url: del?.url,
                                          priority: del?.priority,
                                          asset_type: del?.asset_type
                                    }
                              })
                              : images,
                        deleteAssets: deletedAssetURL,
                        door_no: data.doorNo,
                        street_1: data.addressLineOne,
                        street_2: data.addressLineTwo,
                        landmark: data.landmark,
                        area: data.area,
                        locality: data?.area,
                        district: data?.city,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zipcode: data.zipcode,
                        balconies: parseInt(data.noOfBalcony) ?? null,
                        balcony_area: parseFloat(data.balconyArea) ?? null,
                        owner_id: data?.ownerAccount?.id ?? null,
                        uom_id: data?.measurementUnit?.value,
                        build_up_area: parseFloat(data?.buildupArea) ?? null,
                        terrace_area: parseFloat(data?.terraceArea) ?? null,
                        is_asset_functional_location: data?.assetFunc?.value ? true : false,
                        is_asset_property: data?.assetProperty?.value ? true : false,
                        is_short_term_rental: data?.shortTermRental?.value ? true : false,
                        is_under_promotion: data?.underPromotion?.value ? true : false,
                        "size_type": "unit_size",
                        is_seat: (data?.unitPurpose?.value === "Commercial" && data?.unitCategory?.value === 9) ? true : false,
                        seat_count: data?.seat ?? null,
                        title_deed: data?.titleDeed,
                        deed_date: data?.deedDate ? new Date(data?.deedDate) : null,
                        hijri_date: data?.hijriDate ? new Date(data?.hijriDate) : null,
                        whole_land_area: data?.landArea ? parseFloat(data?.landArea) : null,
                        market_price: data?.marketPrice ? parseFloat(data?.marketPrice) : null,
                        smart_geo_address: data?.smartGeoAddress,
                        share_holders: finalShareHolders,
                        registration_office_id: data?.registrationOffice?.value,
                        unit_contracts: finalUnitContracts,
                        unit_projects: finalUnitProjects,
                  };

                  if (data?.unitCategory?.value === 2) { // Residential Unit Category
                        payload["living_room_size"] = data?.livingRoomSize ? parseFloat(data?.livingRoomSize) : null;
                        payload["master_bedroom_size"] = data?.masterRoomSize ? parseFloat(data?.masterRoomSize) : null;
                        payload["guest_room_size"] = data?.guestRoomSize ? parseFloat(data?.guestRoomSize) : null;
                        payload["children_room_size"] = data?.childrenRoomSize ? parseFloat(data?.childrenRoomSize) : null;
                        payload["kitchen_size"] = data?.kitchenRoomSize ? parseFloat(data?.kitchenRoomSize) : null;
                        payload["servant_room_size"] = data?.servantRoomSize ? parseFloat(data?.servantRoomSize) : null;
                  } else {
                        payload["living_room_size"] = null;
                        payload["master_bedroom_size"] = null;
                        payload["guest_room_size"] = null;
                        payload["children_room_size"] = null;
                        payload["kitchen_size"] = null;
                        payload["servant_room_size"] = null;
                  }

                  NetworkCall(
                        `${config.api_url}/unit/${data?.unitID ? `update` : `create`}`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        true,
                        false).then(() => {
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `${main?.unitID ? t("Unit Updated Successfully") : t("Unit Created Successfully")}`,
                                    vertical: AlertProps.vertical.top,
                                    horizontal: AlertProps.horizontal.center,
                              });
                              history.goBack(-1);
                        }).catch((error) => {
                              if (error?.response?.status === 406)
                                    alert.setSnack({
                                          ...alert,
                                          open: true,
                                          severity: AlertProps.severity.error,
                                          msg: error?.response?.data?.error?.message ? `${error?.response?.data?.error?.message} ${t("Please upgrade your plan!")}` : t("Limit Exceeded"),
                                          vertical: AlertProps.vertical.top,
                                          horizontal: AlertProps.horizontal.center,
                                    });
                              setDisable(false);
                              console.log(error)
                        });
            } else { return false; }
      };

      const checkSave = (type) => {
            setDisable(true);
            if (main?.unitID) {
                  if (permission?.update) {
                        type === "previous" && previous()
                        type === "next" && next()
                        type === "save" && save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission?.create) {
                  type === "previous" && previous()
                  type === "next" && next()
                  type === "save" && save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                        severity: AlertProps.severity.error,
                  })
            }
      }

      const onChangeFloor = (value) => {
            getPropertyDetailsByFloorID(value)
      }
      const getEnum = async () => {
            let purpose_global_type = [], residential_unit_category = [],
                  commercial_unit_category = [], facilities_unit_category = [],
                  stay_unit_category = [];

            const result = await enumSelect([
                  enum_types.purpose_global_type,
                  enum_types.revenue_type, enum_types.unit_payment_period,
                  enum_types.furnishing_type,
                  enum_types.orientation_type, enum_types.pet_policy_type,
                  enum_types.area_metric_type,
                  enum_types.listing_type, enum_types.unit_execution_status
            ])

            const unit_category_master =
                  await NetworkCall(
                        `${config?.api_url}/queries/unit_category/get`,
                        NetWorkCallMethods.post, { limit: null }, null, true, false
                  )
                        .then(res => res?.data?.unit_category_master)
                        .catch(err => console.log(err));

            purpose_global_type = result?.purpose_global_type?.filter?.(_ => included_category_purpose?.includes?.(_?.value));

            for (let i = 0; i < unit_category_master.length; i++) {
                  if ((included_residential_unit_category.includes(unit_category_master[i].value))) {
                        residential_unit_category = [
                              ...residential_unit_category,
                              {
                                    ...unit_category_master[i],
                                    ...unitCategoryMaster[unit_category_master[i].value]
                              }
                        ]
                  }
                  if ((included_commercial_unit_category.includes(unit_category_master[i].value))) {
                        commercial_unit_category = [
                              ...commercial_unit_category,
                              {
                                    ...unit_category_master[i],
                                    ...unitCategoryMaster[unit_category_master[i].value]
                              }
                        ]
                  }
                  if ((included_stay_unit_category.includes(unit_category_master[i].value))) {
                        stay_unit_category = [
                              ...stay_unit_category,
                              {
                                    ...unit_category_master[i],
                                    ...unitCategoryMaster[unit_category_master[i].value]
                              }
                        ]
                  }
                  if ((included_facilities_unit_category.includes(unit_category_master[i].value))) {
                        facilities_unit_category = [
                              ...facilities_unit_category,
                              {
                                    ...unit_category_master[i],
                                    ...unitCategoryMaster[unit_category_master[i].value]
                              }
                        ]
                  }
            }

            for (let i = 0; i < purpose_global_type.length; i++) {
                  purpose_global_type[i].value === enumName.residential &&
                        (purpose_global_type[i].unitCategoryList = residential_unit_category)
                  purpose_global_type[i].value === enumName.commercial &&
                        (purpose_global_type[i].unitCategoryList = commercial_unit_category)
                  purpose_global_type[i].value === enumName.stay &&
                        (purpose_global_type[i].unitCategoryList = stay_unit_category)
                  purpose_global_type[i].value === enumName.facilities &&
                        (purpose_global_type[i].unitCategoryList = facilities_unit_category)
            }

            setOptions({
                  ...options,
                  revenue_type: result?.revenue_type,
                  purpose_global_type: purpose_global_type,
                  payment_period: result?.payment_value_type,
                  furnish: result?.furnishing_type,
                  orientation: result?.orientation_type,
                  pet_policy_type: result?.pet_policy_type,
                  area_metric_type: result?.area_metric_type,
                  listing_type: result?.listing_type,
                  unit_execution_status: result?.unit_execution_status
            })

            setInitialLoad(true)
      }

      const updateShareHolders = (t, k, v, i) => {
        let tempShareHolders = [...data?.shareHolders];
    
        switch (t) {
          case "add":
            const defaultShareHolder = {
              contact: "",
              name: "",
              mobile: {
                mobile: "",
                mobile_code: "",
              },
              email_id: "",
              value: "0.0",
              mobile_no_country_code: "",
              mobile_no: "",
              is_active: false,
              is_delete: false,
              isDeleted: false,
              isEdited: false,
            };
            tempShareHolders?.push(defaultShareHolder);
            break;
    
          case "edit":
            switch (k) {
              case "mobile":
                tempShareHolders[i] = {
                  ...tempShareHolders[i],
                  [k]: v,
                  mobile_no_country_code: v?.mobile_code,
                  mobile_no: v?.mobile,
                  is_active: true,
                  is_delete: false,
                  isEdited: true,
                }
                break;
              case "isDeleted":
                tempShareHolders[i] = {
                  ...tempShareHolders[i],
                  [k]: v,
                  is_active: false,
                  is_delete: true,
                  isEdited: true,
                }
                break;
    
              default:
                tempShareHolders[i] = {
                  ...tempShareHolders[i],
                  [k]: v,
                  is_active: true,
                  is_delete: false,
                  isEdited: true,
                }
                break;
            }
    
            break;
    
          default:
            break;
        }
    
        setData({ ...data, shareHolders: tempShareHolders });
      }

      const updateUnitContracts = (t, k, v, i) => {
            let tempUnitContracts = [...data?.unitContracts];

            switch (t) {
                  case "add":
                        const defaultUnitContract = {
                              contract: "",
                              is_active: false,
                              is_delete: false,
                              isDeleted: false,
                              isEdited: false,
                        };
                        tempUnitContracts?.push(defaultUnitContract);
                        break;

                  case "edit":
                        switch (k) {
                              case "isDeleted":
                                    tempUnitContracts[i] = {
                                          ...tempUnitContracts[i],
                                          [k]: v,
                                          is_active: false,
                                          is_delete: true,
                                          isEdited: true,
                                    }
                                    break;

                              default:
                                    tempUnitContracts[i] = {
                                          ...tempUnitContracts[i],
                                          [k]: v,
                                          is_active: true,
                                          is_delete: false,
                                          isEdited: true,
                                    }
                                    break;
                        }

                        break;

                  default:
                        break;
            }

            setData({ ...data, unitContracts: tempUnitContracts });
      }

      const updateUnitProjects = (t, k, v, i) => {
            let tempUnitProjects = [...data?.unitProjects];

            switch (t) {
                  case "add":
                        const defaultUnitProject = {
                              project: "",
                              is_active: false,
                              is_delete: false,
                              isDeleted: false,
                              isEdited: false,
                        };
                        tempUnitProjects?.push(defaultUnitProject);
                        break;

                  case "edit":
                        switch (k) {
                              case "isDeleted":
                                    tempUnitProjects[i] = {
                                          ...tempUnitProjects[i],
                                          [k]: v,
                                          is_active: false,
                                          is_delete: true,
                                          isEdited: true,
                                    }
                                    break;

                              default:
                                    tempUnitProjects[i] = {
                                          ...tempUnitProjects[i],
                                          [k]: v,
                                          is_active: true,
                                          is_delete: false,
                                          isEdited: true,
                                    }
                                    break;
                        }

                        break;

                  default:
                        break;
            }

            setData({ ...data, unitProjects: tempUnitProjects });
      }

      const render = () => {
            return <>
                  <Subheader
                        title={t("Unit Add/Edit")}
                        goBack={() => { history.goBack(-1) }}
                        previousAndNextBtn={true}
                        previousBtn={() => checkSave("previous")}
                        nextBtn={() => checkSave("next")}
                        previousBtnDisabled={value <= 1 && true}
                        nextBtnDisabled={value === 2 ? true : false}
                  />
                  <PropertyTabs value={value} isHeigth
                        t={t}

                        tab1={(
                              <UnitDetails
                                    id='pushTitle'
                                    data={data}
                                    main={main}
                                    load={load}
                                    updateState={updateState}
                                    updateimg={updateimg}
                                    mapResult={mapResultData}
                                    removeimg={removeimg}
                                    onChangeFloor={onChangeFloor}
                                    mapLoad={mapLoad}
                                    propertyID={main?.propertyID ?? data?.propertyID}
                                    blockID={main?.blockID}
                                    floorID={main?.floorID}
                                    showBlock={data?.property_hierarchy?.is_block}
                                    showFloor={data?.property_hierarchy?.is_floor}
                                    options={options}
                                    t={t}
                                    setData={setData}
                                    updateShareHolders={updateShareHolders}
                                    updateUnitContracts={updateUnitContracts}
                                    updateUnitProjects={updateUnitProjects}
                              />
                        )}
                        tab2={<Assests imageList={images} updateImg={setImages} handleAssetFile={handleAssetFile} companyId={data?.company?.value ?? ""} t={t} />}
                        tabTittle={tabTittle}
                        showBtns={true}
                        isSaveDisable={disable}
                        saveBtnText={main?.unitID ? t("Update") : t("Submit")}
                        save={() => checkSave("save")}
                  />
            </>
      }

      return (
            <div style={{ position: 'relative' }}>
                  {accessCheckRender(render, permission)}
            </div>
      )
}
export default withNamespaces("unitList")(CreateUnit)
