import { Box } from "@mui/material";
import React from "react";
import { AlertDialog, LoadingSection, SearchFilter, TableWithPagination, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { countrydataType, countryheading, countrypath } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { FormGenerator } from "../components";

export const Country = ({ selected = {}, t = () => false }) => {
    const [open, setOpen] = React.useState(false);
    const [selectData, setSelectData] = React.useState({});
    const [loading, setLoading] = React.useState(true)
    const [limits, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = React.useState("");
    //debounce
    const debounce = UseDebounce();
    const [countryList, setCountryList] = React.useState({
        list: [],
        count: 0
    })
    //get country
    const getCountrylist = (limit, offset, search) => {
        const payload={
            limit: limit,
            offset: offset,
            search: search ?? ""
        } 
        NetworkCall(
            `${config.api_url}/queries/opportunity/country_master`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setCountryList({
                list: response.data.data?.country_master,
                count: response.data.data?.count[0]?.count??response?.data?.data?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    //initial load
    React.useEffect(() => {
        getCountrylist(limits, 0, "")
        // eslint-disable-next-line 
    }, [])
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getCountrylist(limits, offset)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getCountrylist(value, 0)
    }
    //onclick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectData(data)
    }
    //form data list
    const formData = [
        {
            isActive: true,
            component: "text",
            label: t("Country Name"),
            value: selectData?.name,
            placeholder: t("Country Name"),
            isReadonly: true
        },
        {
            isActive: true,
            component: "check",
            label: t("Status"),
            value: selectData?.status,
        },
    ]
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getCountrylist(limits, 0, e)
    }
    return (
        <Box>
            {
                loading ? <LoadingSection bottom={"45vh"} message="Fetching ..." />
                    :
                    <Box>
                        {/*save section*/}
                        <SettingSave
                        t={t}
                            title={selected?.label} subTitle={selected?.subtitle} />
                        {/*table*/}
                        <Box padding="8px 12px 0px 12px" width={"330px"}>
                            <SearchFilter value={searchText} placeholder={t("Search Country")} handleChange={(value) => handleSearch(value)} />
                        </Box >
                        <Box padding="0px 12px">
                            <TableWithPagination
                                heading={countryheading(t)}
                                rows={countryList?.list}
                                path={countrypath}
                                showpagination={true}
                                onClick={onClickRow}
                                dataType={countrydataType}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={countryList?.count}
                                page={page}
                                limit={limits}
                                height={'calc(100vh - 351px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box>
                    </Box >
            }

            {/*form dialog*/}
            <AlertDialog
                isNormal

                header={t("Country")}
                onClose={() => setOpen(false)}
                open={open}
                component={
                    <Box p={2}>
                        <FormGenerator t={t} components={formData} />
                    </Box>
                }
            />
        </Box >
    )
}