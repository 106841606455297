/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DownloadImage } from "../../assets";
import {
  FilterGenerator,
  SearchFilter, Subheader, TableWithPagination,
  UseDebounce
} from "../../components";
import { CreateLead } from "../../components/createLead";
import { LeadComments } from "../../components/viewComments/leadComments";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, enum_types, enumSelect, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, wrapLabelsInT } from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { Leadheading, Leadpath } from "../../utils/tableData";
import { CreateLeadContext } from "./leadcontext";
import { useStyles } from "./styles";

export const Leads = (props) => {
  const {
    leadList,
    commentCount,
    getListData,
    openFilter,
    OpenDrawer,
    searchdata,
    total,
    companySelectFunction,
    selectedCompany,
    setSelectedCompany,
    setSearchdata,
    t = () => false
  } = React.useContext(CreateLeadContext);

  const auth = React.useContext(AuthContext);
  const history = useHistory();
  const [listTab, setTab] = React.useState("");
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const { state } = useLocation();
  const [companyList, setCompanyList] = React.useState([]);
  const backdrop = React.useContext(BackdropContext)
  const alert = React.useContext(AlertContext)
  const [permission, setPermission] = React.useState({})
  const [selectedTeams, setSelectedTeams] = React.useState('')
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const [commentsDrawer, setCommentsDrawer] = React.useState(false);
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const [create, setCreate] = React.useState({
    open: false,
    lead_id: null
  })
  const [state1, setState1] = React.useState(true)
  const [status, setStatus] = React.useState({
    value: [],
    enum: []
  })
  const [filterData, setFilterData] = React.useState({
    periorty: "",
    status: [2],
    name: "",
    lead: ""
  })
  const debounce = UseDebounce();
  const is_workspace = ['49', '35'].includes(moduleId)


  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (localStorage.getItem("authToken")) {
          let company = getCompanyOption(backdrop, auth, alert)
          if (company) {
            setCompanyList(company?.list)
            getStatusMaster()
            if (state?.main?.leadNew) {
              setSelectedCompany(state?.main?.selectedCompany ?? company?.selected)
            }
            else {
              setSelectedCompany(company?.selected)
            }
          }
        } else {
          history.push(Routes.login);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const OpenCommentsDrawer = () => {
    setCommentsDrawer(!commentsDrawer);
  };
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getListData(offset, limit, listTab, "key", selectedCompany, selectedTeams, filterData);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getListData(0, value, listTab, "key", selectedCompany, selectedTeams, filterData);
  };

  const handleIcon = (type, data) => {
    if (type === "edit") {


      history.push({
        pathname: Routes.createOpportunity,
        state: {
          id: data?.id,
          company: {
            value: data?.company_id,
            label: data?.company_name
          }
        }
      });

      setCreate({
        open: true,
        lead_id: data?.id
      })
    } else if (type === "double_click") {
      history.push({
        pathname: Routes.leadsDetails,
        state: {
          opportunity: data,
          company: selectedCompany,
          id: data?.id,
          team:selectedTeams?.value === "noteams" ? null : selectedTeams
        }
      });
    }
  };

  const onNext = (val) => {
    history.push({
      pathname: Routes.createOpportunity,
      state: { opportunity: val, company: selectedCompany, is_opportunity: true, primary: state1 }
    })
  }

  const goCreateOppertunity = () => {
    handleCreate("open", true)
  }
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
    getListData(0, 10, listTab, "key", selectedCompany, team, filterData);

  }

  const handleDownload = () => {
    getListData(0, 10, listTab, "key", selectedCompany, selectedTeams, filterData, true);
  }

  // get status master
  const getStatusMaster = () => {
    NetworkCall(
      `${config.api_url}/queries/opportunity/lead_status_master`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        getEnum(response?.data?.data?.lead_status_master)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  }

  const getEnum = async (value) => {
    const result = await enumSelect([enum_types.urgent_type])
    setStatus({
      value: result?.urgent_type ?? [],
      enum: value ?? []
    })
  }

  const onApplyFilter = (value) => {
    setFilterData(value)
    getListData(0, 10, listTab, "filter", selectedCompany, selectedTeams, value)
  }

  const handleCreate = (key, value) => {
    setCreate({
      ...create,
      [key]: value,
      lead_id: null
    })
  }

  const is_instigator = Object.values(filterData)?.flat()?.filter((v) => !!v)?.length
  const searchLead = (value) => {
    debounce(() => getListData(0, 10, value, "key", selectedCompany, selectedTeams, filterData), 800);

  }

  const manualResponse = (array) => {
    const details = array?.contact?.map((i) => {
      return {
        label: i?.label,
        value: i?.id
      }
    })
    return details
  };
  const manualResponseLead = (array) => {
    return array.data
  };
  const render = () => {
    return <>
      <Subheader hideBackButton={true} title={`${t("Opportunity")}(${total ?? 0
        })`} select options={companyList} value={selectedCompany}
        onchange={(e) => {
          companySelectFunction(e)
        }

        }
        selectTeamsOptions
        handleTeamsChange={handleTeamsChange}
        companyId={selectedCompany?.value}
        moduleId={moduleId}
        selectedTeams={selectedTeams} />
      <div className={classes.root}>
        {
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        }
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12} sm={4}>
            <SearchFilter
              value={searchdata}
              handleChange={(value) => {
                setSearchdata(value)
                debounce(() => searchLead(value), 800);
              }}
              placeholder={t("Search by Opportunity ID")}
              customfieldSx={{ height: '40px' }}
            />
          </Grid>

          <Grid item xs={12} sm={8} textAlign={"right"} display={'flex'} justifyContent={'end'}>
            <Box display={"flex"} sx={{ float: "right" }}>
              <Box>
                <IconButton
                  size="small"
                  className={classes.img}
                  onClick={handleDownload}
                // disabled={list?.data === 0}
                >
                  <DownloadImage />
                </IconButton>
              </Box>
              <Divider orientation="vertical" p={0.5} height={2} sx={{ marginInline: "16px" }} />
              <Box mr={1}>
                <Tooltip title={t("View Latest Comment")} placement="top" arrow>
                  {commentCount > 0 ?
                    <IconButton size="small" className={classes.img} onClick={OpenCommentsDrawer} >
                      <Badge variant="dot" color="error" classes={{ badge: classes.customBadge }}>
                        <img src="/images/chat.svg" alt="chat" />
                      </Badge>
                    </IconButton>
                    :
                    <IconButton size="small" className={classes.img} onClick={OpenCommentsDrawer} >
                      <img src="/images/chat.svg" alt="chat" />
                    </IconButton>
                  }
                </Tooltip>
              </Box>
              <Divider orientation="vertical" p={0.5} height={2} sx={{ marginInline: "16px" }} />
              <Box>
                {is_instigator ?
                  <IconButton
                    size="small"
                    className={classes.img}
                    onClick={OpenDrawer}
                  >
                    <Badge variant="dot" color="primary" classes={{ badge: classes.customBadge }}>
                      <img src="/images/filter.svg" alt="filter" />
                    </Badge>
                  </IconButton>
                  :
                  <IconButton
                    size="small"
                    className={classes.img}
                    onClick={OpenDrawer}
                  >
                    <img src="/images/filter.svg" alt="filter" />

                  </IconButton>
                }
              </Box>
              <Divider orientation="vertical" p={0.5} height={2} sx={{ marginInline: "16px" }} />
              {/* &nbsp; &nbsp; &nbsp; */}
              {permission?.create && 
                <Button
                  variant="contained"
                  onClick={goCreateOppertunity}
                  sx={{ height: "40px" }}
                >
                  {t("Add opportunity")}
                </Button>}
            </Box>
          </Grid>
          {/* Main tableData */}
          <Grid item xs={12} className={classes.tableMain}>

            <TableWithPagination
              heading={Leadheading(t)}
              rows={leadList}
              path={Leadpath}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleIcon}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={total}
              page={page}
              limit={limit}
              tableType="no-side"
              dataType={[
                { type: ["more_5"], icon: "icon" },
                { type: ["text"], name: "leadNo" },
                { type: ["date"], name: "date" },
                { type: ["long_text"], name: "description" },
                { type: ["text"], name: "purpose" },
                { type: ["text"], name: "opportunity_type" },
                { type: ["text"], name: "activeQuote" },
                { type: ["text"], name: "value" },
                { type: ["text"], name: "owner" },
                { type: ["text"], name: "contact_name" },
                { type: ["text"], name: "contact_email" },
                { type: ["text"], name: "company_name" },
                { type: ["text"], name: "country" },
                { type: ["text"], name: "contact_mobile_no" },
                { type: ["text"], name: "source" },
                { type: ["oppertunity_status"], name: "status" },
                { type: ["date"], name: "dateOfOpen" },
                { type: ["date"], name: "dateOfClosed" },
                // { type: ["text"], name: "leadId" },
              ]}
              height={isXs ? "calc(100vh - 440px)" : "calc(100vh - 315px)"}
              view={permission?.read}
              edit={permission?.update}
              delete={permission?.delete}
              enable_double_click />
          </Grid>


          {/* create section */}
          <React.Fragment key={'right'}>
            <Drawer
              anchor={'right'}
              open={create?.open}
              onClose={() => handleCreate("open", false)}
            >
              <CreateLead
                t={t}
                title={`${create?.lead_id ? (is_workspace ? t("Update Workspace Opportunity") : t("Update Opportunity")) : (is_workspace ? t("Create Workspace Opportunity") : t("Create Opportunity"))}`}
                onClose={() => handleCreate("open", false)}
                state={{
                  SelectedCompany: selectedCompany,
                  company: companyList
                }}
                moduleId={moduleId}
                btnName="Opportunity"
                oppertunity_only={true}
                reload={() => {
                  getListData(0, 10, "", "key", selectedCompany, selectedTeams, filterData)
                  handleCreate("open", false)
                }}
                company_id={selectedCompany?.value}
                // quote_id={state?.selected_id}
                lead_id={create?.lead_id}
                onNext={onNext}
                setState1={setState1}
                state1={state1}
                is_opportunity={true}
              // revenue_type={"Lease"} 
              />
            </Drawer >
          </React.Fragment >
          <React.Fragment key={'right'}>
            <Drawer
              anchor={'right'}
              open={commentsDrawer}
              onClose={() => setCommentsDrawer(false)}
            >
              <Box>
                <LeadComments t={t} closeDrawer={() => setCommentsDrawer(false)} company_id={selectedCompany?.value} clientID={clientId} filterData={filterData} selectedCompany={selectedCompany} type={"opportunity"} selectedTeams={selectedTeams} />
              </Box>
            </Drawer >
          </React.Fragment >
          {
            openFilter &&
            <FilterGenerator
              open={openFilter}
              onClose={OpenDrawer}
              components={[
                {
                  component: "toggleButton",
                  value: filterData?.periorty,
                  options: wrapLabelsInT(status?.value, t),
                  isMulti: true,
                  state_name: "periorty",
                  label: t("Priority Type"),
                  // required:true
                },
                {
                  component: "toggleButton",
                  value: filterData?.status,
                  options: wrapLabelsInT(status?.enum, t) ?? [],
                  isMulti: true,
                  state_name: "status",
                  label: t("Status"),
                  // required:true
                },
                {
                  component: "select",
                  value: filterData?.name,
                  isMulti: true,
                  label: t("Contact Name"),
                  state_name: "name",
                  placeholder: t("Select Contact Name"),
                  loadOptions: (search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/opportunity/contact/dropdown",
                      {
                        company_id: selectedCompany?.value,
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      { "custom_data": true },
                      manualResponse
                    ),
                  debounceTimeout: 800,
                  isPaginate: true,
                },
                {
                  component: "select",
                  value: filterData?.lead,
                  isMulti: true,
                  label: t("Lead Owner"),
                  state_name: "lead",
                  placeholder: t("Select Lead Owner"),
                  loadOptions: (search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/opportunity/lead_owner/dropdown",
                      {
                        company_id: selectedCompany?.value,
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      manualResponseLead
                    ),
                  debounceTimeout: 800,
                  isPaginate: true,
                },
              ]}
              onApply={(value) => onApplyFilter(value)}
              defaultState={{
                periorty: "",
                status: [2],
                name: "",
                lead: ""
              }}
            />
          }
        </Grid >
      </div >
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
