import React from 'react'
import { NewFormStep } from '../../../components/newFormSteps'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import ChooseProperty from '../../contract/utils/chooseProperty'
import ChooseUnit from '../../contract/utils/chooseUnit'
import { Box } from "@mui/material"
import { ProjectContext } from '../projectContext'


export const PropertyBlockUnitForm = ({
    type = "",
    data = "",
    t = () => false
}) => {
    const {
        selectedCompany,
        addTableData,
        setAddTableData,
        viewData
    } = React.useContext(ProjectContext)


    const manualPropertyResponse = (array) => {
        const result = array?.map((e) => {
            return {
                label: e?.name,
                value: e?.property_id,
                subTitle1: e?.property_no,
                subTitle2: `${e?.area}${e?.city && ','} ${e?.city}` ?? "",
                url: e?.logo,
                area: e?.area,
                city: e?.city,
                country: e?.country,
                district: e?.district,
                landmark: e?.landmark,
                property_no: e?.property_no,
                state: e?.state,
                is_block: e?.is_block,
                id: e?.id
            }
        })
        return result
    }

    const manualBlockResponse = (array) => {
        const result = array?.data?.map((e) => {
            return {
                label: e?.name,
                value: e?.block_id,
                area: e?.area,
                block_no: e?.block_no,
                city: e?.city,
                country: e?.country,
                district: e?.district,
                landmark: e?.landmark,
                logo: e?.logo,
                name: e?.name,
                property_id: e?.property_id,
                property_logo: e?.property_logo,
                property_name: e?.property_name,
                property_no: e?.property_no,
                state: e?.state,
            }
        })
        return result
    }


    const manualUnitResponse = (array) => {
        const result = array?.data?.map((e) => {
            return {
                subTitle1: e?.unit_no,
                subTitle2: `${e?.area}${e?.city && ','} ${e?.city}` ?? "",
                label: e?.name,
                value: e?.id,
                area: e?.area,
                block_no: e?.block_no,
                city: e?.city,
                country: e?.country,
                district: e?.district,
                landmark: e?.landmark,
                logo: e?.logo,
                name: e?.name,
                state: e?.state,
            }
        })
        return result
    }
    return (
        <Box p={2}>
            <NewFormStep
                divider
                component={
                    [
                        {
                            type: "chooseComponent",
                            title: t("Choose Property"),
                            subTitle: t("Pick the project property"),
                            placeholder: t("Search Property"),
                            noSelectedTitle: t("No Property selected to display"),
                            icon: <ChooseProperty />,
                            showCard: true,
                            options: [],
                            topHeader: "Choose Vendor Contact",
                            onchange: (value) => {

                                setAddTableData({
                                    ...addTableData,
                                    property:
                                        addTableData?.property?.map((e) => { return e?.value })?.includes(value?.value) ? addTableData?.property?.filter((i) => i?.value !== value?.value) : [...addTableData?.property, value]
                                })
                            },
                            value: addTableData?.property,
                            onDeleteMuti: (value) => {
                                setAddTableData({
                                    ...addTableData,
                                    property: addTableData?.property?.filter((i) => i?.value !== value?.value)
                                })
                            },
                            isRequired: false,
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    type === "property" ? "project_properties/drop_down" : "project_properties/list",
                                    {
                                        exclude_ids: [],
                                        company_id: selectedCompany?.value,
                                        project_id: data?.id,
                                        is_block: type === "block" ? true : false ?? false
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    manualPropertyResponse
                                ),
                            // error: contractData?.error?.selectedUnitProperty?.length > 0,
                            // errorMsg: contractData?.error?.selectedUnitProperty,
                            isActive: true,
                            isBorder: false,
                            isMulti: true,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            // isReadOnly: stateData?.type === "view"
                        },
                        {
                            type: "chooseComponent",
                            title: t("Choose Block"),
                            subTitle: t("Pick block from above selected property"),
                            placeholder: t("Search Block"),
                            noSelectedTitle: t("No Block selected to display"),
                            icon: <ChooseUnit />,
                            showCard: true,
                            options: [],
                            onchange: (value) => setAddTableData({
                                ...addTableData,
                                block:
                                    addTableData?.block?.map((e) => { return e?.id })?.includes(value?.value) ? addTableData?.block?.filter((i) => i?.value !== value?.value) : [...addTableData?.block, value]
                            }),
                            value: addTableData?.block,
                            onDeleteMuti: (value) => setAddTableData({
                                ...addTableData,
                                block: addTableData?.block?.filter((i) => i?.value !== value?.value)
                            }),
                            isRequired: false,
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    type === "unit" ? "project_block/list" : "project_block/drop_down",
                                    {
                                        exclude_ids: [],
                                        property_id: addTableData?.property?.map((e) => e?.value),
                                        project_id: data?.id
                                    }
                                    ,
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    manualBlockResponse
                                ),
                            // error: contractData?.error?.selectedUnitProperty?.length > 0,
                            // errorMsg: contractData?.error?.selectedUnitProperty,
                            isActive: ((type !== "property" && viewData?.type !== "Property") ? true : false),
                            isBorder: false,
                            isMulti: true,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            isReadOnly: addTableData?.property?.length === 0,
                            key: JSON.stringify(addTableData?.property)
                        },

                        {
                            type: "chooseComponent",
                            title: t("Choose Unit"),
                            subTitle: t("Pick the project unit"),
                            placeholder: t("Search Unit"),
                            noSelectedTitle: t("No Unit selected to display"),
                            icon: <ChooseUnit />,
                            showCard: true,
                            options: [],
                            topHeader: "Choose Vendor Contact",
                            onchange: (value) => setAddTableData({
                                ...addTableData,
                                unit:
                                    addTableData?.unit?.map((e) => { return e?.value })?.includes(value?.value) ? addTableData?.unit?.filter((i) => i?.value !== value?.value) : [...addTableData?.unit, value]
                            }),
                            value: addTableData?.unit,
                            onDeleteMuti: (value) => {
                                setAddTableData({
                                    ...addTableData,
                                    unit: addTableData?.unit?.filter((i) => i?.value !== value?.value)
                                })
                            },
                            isRequired: false,
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    "project_unit/drop_down",
                                    {
                                        exclude_ids: [],
                                        property_id: addTableData?.property?.map((e) => e?.value),
                                        block_id: addTableData?.block?.length > 0 ? addTableData?.block?.map((e) => e?.value) : undefined,
                                        project_id: data?.id
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    manualUnitResponse
                                ),
                            // error: contractData?.error?.selectedUnitProperty?.length > 0,
                            // errorMsg: contractData?.error?.selectedUnitProperty,
                            isActive: (type === "unit" ? true : false),
                            isBorder: false,
                            isMulti: true,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            key: JSON.stringify(addTableData?.property && addTableData?.block)
                        },
                    ]}
            />
        </Box>
    )
}