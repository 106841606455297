import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Stepper, Subheader } from "../../components";
import { AuthContext, BackdropContext } from "../../contexts";
import { CreateShortListContext } from "../../contexts/shortlistLedContext";
import { Routes } from "../../router/routes";
import { accessCheckRender, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { LeadNew, LeadPreview } from "./steps";
import { useStyles } from "./styles";
import { retutnMappedValues } from "../createquotation/utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

export const Shortlistedlead = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const backdrop = React.useContext(BackdropContext)

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading...",
      });
      if (perm?.read) {
        if (state) {
          //get company details
          getCompanyDetails()
        }
        else {
          history.push(Routes.propertyFinder)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //context state
  const { step, previous, next, setDataNew, dataNew, disable, setSelectedAccount, t } = React.useContext(CreateShortListContext);
  //new lead
  const stepsNew = [
    {
      label: t("Opportunity Details"),
      step: 1,
      value: step
    },
    {
      label: t("Preview and Create Lead"),
      step: 2,
      value: step
    }
  ]
  //exist lead step
  const stepsNewExist = [
    {
      label: t("Preview and Create Lead"),
      step: 1,
      value: step
    }
  ]
  //get company details
  const getCompanyDetails = () => {
    NetworkCall(
      `${config?.api_url}/queries/opportunity/get_company_details`,
      NetWorkCallMethods.post,
      {
        company_id: state?.companyID?.value,
      },
      null,
      true,
      false
    )
    .then((res) => {
      let result = retutnMappedValues({
        is_lead: state?.duration?.lead,
        state: state,
        company: res?.data?.company_master[0]
      })

      setSelectedAccount({
        normal: state?.account ?? state?.duration?.lead?.account,
        broker: state?.broker ?? state?.duration?.lead?.borker?? null
      })
      setDataNew({
        ...dataNew,
        ...result
      })
      if (state?.duration?.lead) {
        next()
      }
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });

    }).catch((err) => console.log(err))
  }


  const newLeadComponent = {
    1: <Box className={classes.contentNew}><LeadNew /></Box>,
    2: <Box className={classes.content}><LeadPreview /></Box>,
  }

  const existLeadComponent = {
    1: <Box className={classes.content}><LeadPreview /></Box>,
  }

  const render = () => {
    return <>
      {/*sub header*/}
      <Subheader goBack={() => history.goBack()} title={dataNew.type === "existing lead" ? t("Create Quotation To Existing Opportunity") : t("Create Quotation To New Opportunity")} />
      <Stepper t={t} step={step} steps={dataNew.type === "existing lead" ? stepsNewExist : stepsNew} />
      {/*conditional component*/}
      {
        dataNew.type === "existing lead" ?
          existLeadComponent[step] :
          newLeadComponent[step]
      }

      {/*btn container */}
      <Grid container className={classes.btnroot} spacing={1}>
        <Grid item xs={6}>
          <Button className={classes.previousbtn} variant="contained" onClick={previous} disabled={step === 1}>
            {t("Previous")}
          </Button>
        </Grid>
        <Grid item xs={6} textAlign={"right"} >
          <Button className={classes.previousbtn} variant="contained" onClick={() => history.goBack()}>
            {t("Cancel")}
          </Button>

          {permission?.create &&
            <Button disabled={disable} className={classes.submitbtn} variant="contained" onClick={next}>
              {step === 3 ? t("Done") : t("Next")}
            </Button>}
        </Grid>
      </Grid>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>

  );
};