import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { useStyles } from "./styles";


export const ResidentSetting = ({ t, data = {},
    updateState = () => false, }) => {
    const classes = useStyles();
    const updateClientControls = (selected_val, value) =>{
        const result = data?.client_controls?.map((x) => {
            return {
                ...x,
                details: x?.details?.map((e) => {
                    return {
                        ...e,
                        is_active: selected_val?.id === e.id ? value?.is_active : e?.is_active
                    }
                })
            }
        })
        updateState("client_controls",result)
    }
    return (
        <Box>
            {/*setting save */}
            <Box className={classes.settingRoot}>
                {/*"Enable Reservation Feature"*/}
                {data?.client_controls?.map((x) => {
                    return (
                        <Box p={2}>
                            <Typography className={classes.dynamicTitle}>{x?.header}</Typography>
                            <Grid container spacing={2}>

                                {x?.details?.map((val) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                            <Box mt={1.5}>
                                                <CompanySettingsList
                                                    title={val?.title}
                                                    onChange={(value) => updateClientControls(val, value)}
                                                    value={{ is_active: val?.is_active }}
                                                    toggle={true}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                })}

                            </Grid>

                        </Box>
                    )
                })}

                {/*Enable Auto Deposite Amount Calculation Feature*/}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("My Neighbours")}
                        onChange={(value) => updateState("neigbours", value)}
                        value={data?.neigbours}
                        toggle={true}
                    />

                </Box> */}
                {/*Enable Auto Reservation Amount Calculation Feature*/}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("My Contacts")}
                        onChange={(value) => updateState("contact", value)}
                        value={data?.contact}
                        toggle={true}
                    />
                </Box>

                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Survey")}
                        onChange={(value) => updateState("survey", value)}
                        value={data?.survey}
                        toggle={true}
                    />

                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Walk-in Tracking")}
                        onChange={(value) => updateState("walkin", value)}
                        value={data?.walkin}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Parking Alarm Notification")}
                        onChange={(value) => updateState("parking", value)}
                        value={data?.parking}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Amenity Booking")}
                        onChange={(value) => updateState("booking", value)}
                        value={data?.booking}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Delivery Collection")}
                        onChange={(value) => updateState("deleivery", value)}
                        value={data?.deleivery}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Polls")}
                        onChange={(value) => updateState("polls", value)}
                        value={data?.polls}
                        toggle={true}
                    />
                </Box> */}
            </Box>
        </Box>
    )
}