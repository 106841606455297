import { Skeleton, Stack, Typography } from "@mui/material"

export const ShareHolderContactLoader = ({
    classes = {},
}) => {
    return <Stack direction={"row"} columnGap={"16px"}
        p={"8px"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack direction={"row"} columnGap={"8px"} alignItems={"center"} width={"93%"}>
            <Skeleton animation="wave" variant='rounded' height={32} width={32} />
            <Stack width={"91%"}>
                <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}
                    divider={<Typography className={classes.optionHeader}>{"-"}</Typography>}>
                    <Skeleton animation="wave" variant='text' width={"20%"} />
                    <Skeleton animation="wave" variant='text' width={"80%"} />
                </Stack>
                <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}
                    divider={<Typography className={classes.optionSubTitle}>{","}</Typography>}>
                    <Skeleton animation="wave" variant='text' width={"30%"} />
                    <Skeleton animation="wave" variant='text' width={"60%"} />
                </Stack>
            </Stack>
        </Stack>
        <Skeleton animation="wave" variant='circular' width={"24px"} height={"24px"} />
    </Stack>
}