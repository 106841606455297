import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PieCharts from "../../../components/piechart";
import { paymentDashboardStyle } from "../style";

const Piechart = (props) => {
  const classes = paymentDashboardStyle(props);
  return (
    <div className={classes.card}>
      <Typography className={classes.titleFont}>{props?.header}</Typography>
      <Grid container spacing={2} alignItems={"center"} p={'20px 0px 0px 0px'}>
        <Grid
          item
          xs={props?.divider?.xs ?? 12}
          sm={props?.divider?.sm ?? 12}
          md={props?.divider?.md ?? 6}
          lg={props?.divider?.lg ?? 6}
        >
          <div className={classes.graphMargin}>
            <PieCharts
              data={props?.graphData}
              radius={props?.innerRadius}
              width={200}
              isTotal={props?.isTotal}
              total={props?.total}
              height={props?.isZoom ? null : "300px !important"}
              is_popUp={props?.is_popUp}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={props?.divider1?.xs ?? 12}
          sm={props?.divider1?.sm ?? 12}
          md={props?.divider1?.md ?? 6}
          lg={props?.divider1?.lg ?? 6}
        >
          <Box className={classes.graphdatabox}>
            {props?.graphData?.map((x, i) => {
              return (
                <Box>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Box display={"flex"} alignItems={"baseline"} gap={"10px"}>
                      <Box
                        className={classes.dot}
                        style={{ backgroundColor: x?.fill }}
                      />
                      <Typography className={classes.graphDataTitle} noWrap>
                        {x?.name}
                      </Typography>
                    </Box>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography className={classes.graphDataTotal}>
                        {x?.totalValue ?? 0}
                      </Typography>
                      <Typography className={classes.graphDataCount}>
                        {`(${x?.count + "%" ?? 0})`}
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* {props?.graphData?.length - 1 !== i && (
                    <Box mt={1} mb={1}>
                      <Divider />
                    </Box>
                  )} */}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Piechart;
