import { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AlertContext } from "../../../contexts";
import { LightTooltip, QuickViewDrawerStyles } from "./style";
import { LineIndicatorTab } from "../../../components/lineIndicatorTab";
import { InternalTable } from "./internalTable";
import { ExternalTable } from "./externalTable";
import {
    TabData, TabPaths,
    INITIAL_INTERNAL_TABLE_LIMIT, INITIAL_INTERNAL_TABLE_OFFSET, INITIAL_INTERNAL_TABLE_PAGE,
    INITIAL_EXTERNAL_TABLE_LIMIT, INITIAL_EXTERNAL_TABLE_OFFSET, INITIAL_EXTERNAL_TABLE_PAGE,
    INITIAL_TO_DO_LIST_TABLE_LIMIT, INITIAL_TO_DO_LIST_TABLE_OFFSET, INITIAL_TO_DO_LIST_TABLE_PAGE,
    INITIAL_ACTIVITY_TABLE_LIMIT, INITIAL_ACTIVITY_TABLE_OFFSET, INITIAL_ACTIVITY_TABLE_PAGE, INITIAL_COMMENT_OFFSET, INITIAL_COMMENT_LIMIT, INITIAL_TO_DO_FORM_STATE, TO_DO_FORM_CTA,
} from "../utils/quickViewUtils";
import { useHistory } from "react-router";
import { BackendRoutes, Routes } from "../../../router/routes";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { ToDoListTable } from "./todoListTable";
import { ActivityTable } from "./activityTable";
import { config } from "../../../config";
import { ActivityComments } from "./activityComments";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { Hidden } from "@mui/material";

export const QuickViewDrawer = ({
    t = () => false,
    selected_company = {},
    data = {},
}) => {
    const InitialTabValue = TabData({ t })?.activity_comments;
    const comment_limit = INITIAL_COMMENT_LIMIT;
    const internal_table_offset = INITIAL_INTERNAL_TABLE_OFFSET;
    const external_table_offset = INITIAL_EXTERNAL_TABLE_OFFSET;
    const to_do_list_table_offset = INITIAL_TO_DO_LIST_TABLE_OFFSET;
    const activity_table_offset = INITIAL_ACTIVITY_TABLE_OFFSET;
    const toDoFormCTA = TO_DO_FORM_CTA;

    const size = useWindowDimensions();
    const miniLeadCardRef = useRef(null);
    const descriptionRef = useRef(null);
    const classes = QuickViewDrawerStyles({ size, priority: data?.priority });
    const addCommentRef = useRef(null);

    const history = useHistory();
    const alert = useContext(AlertContext);

    const [selected_tab, set_selected_tab] = useState(InitialTabValue);
    const [is_initial_comment_loading, set_is_initial_comment_loading] = useState(true);
    const [is_add_comment_loading, set_is_add_comment_loading] = useState(false);
    const [add_comment_state, set_add_comment_state] = useState("");
    const [comment_offset, set_comment_offset] = useState(INITIAL_COMMENT_OFFSET);
    const [comment_state, set_comment_state] = useState({ rows: [], total_rows_count: 0, });
    const [is_internal_table_loading, set_is_internal_table_loading] = useState(true);
    const [internal_table_state, set_internal_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [internal_table_page, set_internal_table_page] = useState(INITIAL_INTERNAL_TABLE_PAGE);
    const [internal_table_limit, set_internal_table_limit] = useState(INITIAL_INTERNAL_TABLE_LIMIT);
    const [is_external_table_loading, set_is_external_table_loading] = useState(true);
    const [external_table_state, set_external_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [external_table_page, set_external_table_page] = useState(INITIAL_EXTERNAL_TABLE_PAGE);
    const [external_table_limit, set_external_table_limit] = useState(INITIAL_EXTERNAL_TABLE_LIMIT);
    const [is_to_do_list_table_loading, set_is_to_do_list_table_loading] = useState(true);
    const [to_do_list_table_state, set_to_do_list_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [to_do_list_table_page, set_to_do_list_table_page] = useState(INITIAL_TO_DO_LIST_TABLE_PAGE);
    const [to_do_list_table_limit, set_to_do_list_table_limit] = useState(INITIAL_TO_DO_LIST_TABLE_LIMIT);
    const [isToDoFormOpen, setIsToDoFormOpen] = useState(false);
    const [toDoFormState, setToDoFormState] = useState({ ...INITIAL_TO_DO_FORM_STATE });
    const [is_activity_table_loading, set_is_activity_table_loading] = useState(true);
    const [activity_table_state, set_activity_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [activity_table_page, set_activity_table_page] = useState(INITIAL_ACTIVITY_TABLE_PAGE);
    const [activity_table_limit, set_activity_table_limit] = useState(INITIAL_ACTIVITY_TABLE_LIMIT);
    const [descriptionHeight, setDescriptionHeight] = useState(null);
    const [miniLeadCardHeight, setMiniLeadCardHeight] = useState(null);
    const [isDescriptionToolTipOpen, setIsDescriptionToolTipOpen] = useState(false);
    const [disable, setDisable] = useState(false)

    const loader = {
        initial: {
            true: () => set_is_initial_comment_loading(true),
            false: () => set_is_initial_comment_loading(false),
        },
        addingComment: {
            true: () => set_is_add_comment_loading(true),
            false: () => {
                set_is_add_comment_loading(false);
                set_add_comment_state("");
            },
        },
    }

    useEffect(() => {
        if (data?.id && data?.id?.length > 0) {
            getInitialData();
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (addCommentRef?.current &&
            selected_tab?.value === "activity_comments" &&
            is_add_comment_loading) {
            addCommentRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_add_comment_loading, addCommentRef])

    useEffect(() => {
        setDescriptionHeight(descriptionRef?.current?.clientHeight ?? 0)
    }, [descriptionRef?.current?.clientHeight]);

    useEffect(() => {
        setMiniLeadCardHeight(miniLeadCardRef?.current?.clientHeight ?? 0)
    }, [miniLeadCardRef?.current?.clientHeight]);

    const getInitialData = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getCommentsData({}),
                    getInternalTableData({}),
                    getExternalTableData({}),
                    getToDoListTableData({}),
                    getActivityTableData({}),
                ]);
            } catch (error) {
                reject(error);
            }
        })
    }

    const getCommentsData = ({
        offset = comment_offset,
        limit = comment_limit,
        proxy_lead_id = data?.id,
        load_type = "initial",
    }) => {
        loader?.[load_type]?.true();

        let payload = { offset, limit, id: proxy_lead_id, };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.lead_proxy_get_comments}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_row = [];

            if (load_type === "loadMore") {
                temp_row = response?.data?.data?.list
                    ? [
                        ...comment_state?.rows,
                        ...response?.data?.data?.list,
                    ]
                    : comment_state?.rows;
            } else {
                temp_row = response?.data?.data?.list ?? [];
            }

            set_comment_state({
                rows: temp_row,
                total_rows_count: response?.data?.data?.total ?? 0,
            })

            loader?.[load_type]?.false();
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })

            loader?.[load_type]?.false();
        });
    }

    const fetchMoreComments = () => {
        if (comment_state?.rows?.length < comment_state?.total_rows_count) {
            const offset = comment_offset + comment_limit;
            set_comment_offset(offset);
            getCommentsData({ offset, load_type: "loadMore" });
        }
    }

    const onCommentSubmit = (e) => {
        e.preventDefault();
        addComment();
    }

    const addComment = async () => {
        loader?.addingComment?.true();

        let payload = {
            id: data?.id,
            comment: add_comment_state,
            created_at: new Date().toISOString(),
        };

        await NetworkCall(
            `${config?.api_url}${BackendRoutes?.lead_proxy_upsert_comments}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })
        });

        const offset = INITIAL_COMMENT_OFFSET;
        set_comment_offset(offset);

        getCommentsData({ offset, load_type: "addingComment" });
    }

    const getInternalTableData = ({
        offset = internal_table_offset,
        limit = internal_table_limit,
        proxy_lead_id = data?.id,
    }) => {
        set_is_internal_table_loading(true);
        let payload = { offset, limit, proxy_lead_id, };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.proxy_lead_units_get_proxy_lead_units_by_proxy_lead_id}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    image: _?.logo,
                    property_name: _?.property_name?.length > 0 ? _?.property_name : "-",
                    unit_name: _?.unit_name?.length > 0 ? _?.unit_name : "-",
                    unit_no: _?.unit_no?.length > 0 ? _?.unit_no : "-",
                    unit_type: _?.unit_type?.length > 0 ? _?.unit_type : "-",
                }
            })
            set_internal_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.count ?? 0
            })
            set_is_internal_table_loading(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })
        });
    }

    const handleInternalTablePagination = ({ page = internal_table_page }) => {
        set_internal_table_page(page);
        let offset = (page - 1) * internal_table_limit;
        getInternalTableData({ offset });
    }

    const handleInternalTablePageLimit = ({ limit = internal_table_limit }) => {
        set_internal_table_limit(limit);
        set_internal_table_page(1);
        getInternalTableData({ limit });
    }

    const getExternalTableData = ({
        offset = external_table_offset,
        limit = external_table_limit,
        proxy_lead_id = data?.id,
    }) => {
        set_is_external_table_loading(true);
        let payload = { offset, limit, proxy_lead_id, };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.lead_proxy_get_third_party}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    property_name: _?.property_name?.length > 0 ? _?.property_name : "-",
                    unit_name: _?.property_name?.length > 0 ? _?.property_name : "-",
                    unit_type: _?.type?.length > 0 ? _?.type : "-",
                    period: _?.period?.length > 0 ? _?.period : "-",
                    price: _?.price ?? "0",
                    agent: _?.agent_name?.length > 0 ? _?.agent_name : "-",
                    owner: _?.owner_name?.length > 0 ? _?.owner_name : "-",
                    listing_site: _?.external_view_listing_source?.length > 0 ? _?.external_view_listing_source : "-",
                }
            })
            set_external_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.data?.count ?? 0
            })
            set_is_external_table_loading(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })
        });
    }

    const handleExternalTablePagination = ({ page = external_table_page }) => {
        set_external_table_page(page);
        let offset = (page - 1) * external_table_limit;
        getExternalTableData({ offset });
    }

    const handleExternalTablePageLimit = ({ limit = external_table_limit }) => {
        set_external_table_limit(limit);
        set_external_table_page(1);
        getExternalTableData({ limit });
    }

    const getToDoListTableData = ({
        offset = to_do_list_table_offset,
        limit = to_do_list_table_limit,
        proxy_lead_id = data?.id,
    }) => {
        let payload = { offset, limit, proxy_lead_id, };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.proxy_lead_checklist_get_proxy_lead_checklist_by_proxy_lead_id}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    is_done: _?.is_done,
                    name: _?.name?.length > 0 ? _?.name : "-",
                    description: _?.description?.length > 0 ? _?.description : "-",
                    icon: _?.is_done ? "" : "edit",
                    selectedRow: _?.is_done,
                }
            })
            set_to_do_list_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.count ?? 0
            })
            set_is_to_do_list_table_loading(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })
        });
    }

    const handleToDoListTablePagination = ({ page = to_do_list_table_page }) => {
        set_to_do_list_table_page(page);
        let offset = (page - 1) * to_do_list_table_limit;
        set_is_to_do_list_table_loading(true);
        getToDoListTableData({ offset });
    }

    const handleToDoListTablePageLimit = ({ limit = to_do_list_table_limit }) => {
        set_to_do_list_table_limit(limit);
        set_to_do_list_table_page(1);
        set_is_to_do_list_table_loading(true);
        getToDoListTableData({ limit });
    }

    const handleToDoListTableIcon = ({ type, data: handleIconData }) => {
        switch (type) {
            case "checkBox":
                const params = {
                    id: handleIconData?.id,
                    name: handleIconData?.name,
                    is_done: !Boolean(handleIconData?.is_done),
                    updated_at: new Date()?.toISOString(),
                }
                upsertToDo({ type: "check", params });
                break;
            case "edit":
                setToDoFormState({
                    ...toDoFormState,
                    form_type: type,
                    id: handleIconData?.id,
                    name: handleIconData?.name ?? "",
                    description: handleIconData?.description ?? "",
                    error: { ...INITIAL_TO_DO_FORM_STATE?.error },
                });
                setIsToDoFormOpen(true);
                break;
            case "view":
                if (!Boolean(handleIconData?.is_done)) {
                    setToDoFormState({
                        ...toDoFormState,
                        form_type: type,
                        id: handleIconData?.id,
                        name: handleIconData?.name ?? "",
                        description: handleIconData?.description ?? "",
                        error: { ...INITIAL_TO_DO_FORM_STATE?.error },
                    });
                    setIsToDoFormOpen(true);
                }
                break;

            default:
                break;
        }
    }

    const upsertToDo = ({ type = "check", params }) => {
        const original_state = { ...to_do_list_table_state };

        if (["check", "mark"]?.includes?.(type)) {
            let temp_row = to_do_list_table_state?.rows?.map?.((_) => {
                const is_done = params?.id === _?.id
                    ? params?.is_done
                    : _?.is_done;

                return {
                    ..._,
                    is_done,
                    icon: params?.id === _?.id
                        ? (params?.is_done ? "" : "edit")
                        : (_?.is_done ? "" : "edit"),
                    selectedRow: is_done,
                }
            })
            set_to_do_list_table_state({
                ...to_do_list_table_state,
                rows: temp_row,
            });

            alert.setSnack({
                ...alert, open: true, msg: t("Updating..."),
                severity: AlertProps?.severity?.info,
            });
        } else {
            set_is_to_do_list_table_loading(true);
        }

        let payload = { params };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.proxy_lead_checklist_upsert_proxy_lead_checklist}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            const alert_obj = {
                check: {
                    message: `${params?.name} ${params?.is_done ? t("marked as completed") : t("marked as not completed")}`,
                    severity: params?.is_done ? AlertProps?.severity?.success : AlertProps?.severity?.warning,
                },
                add: {
                    message: `${params?.name} ${t("is added to the list")}`,
                    severity: AlertProps?.severity?.success,
                },
                edit: {
                    message: `${params?.name} ${t("is updated")}`,
                    severity: AlertProps?.severity?.success,
                },
                mark: {
                    message: `${params?.name} ${params?.is_done ? t("marked as completed") : t("marked as not completed")}`,
                    severity: params?.is_done ? AlertProps?.severity?.success : AlertProps?.severity?.warning,
                },
            };

            alert.setSnack({
                ...alert, open: true, msg: alert_obj?.[type]?.message,
                severity: alert_obj?.[type]?.severity
            });
            setDisable(false)

            if (type !== "check") {
                setIsToDoFormOpen(false);
                setToDoFormState({
                    ...INITIAL_TO_DO_FORM_STATE,
                    error: { ...INITIAL_TO_DO_FORM_STATE?.error },
                });
                set_to_do_list_table_page(1);
                getToDoListTableData({});
            }
        }).catch((e) => {
            setDisable(false)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            });

            if (["check", "mark"]?.includes?.(type)) {
                set_to_do_list_table_state(original_state);
            } else {
                set_is_to_do_list_table_loading(false);
            }
        });
    }

    const handleAddToDo = () => {
        setToDoFormState({
            ...INITIAL_TO_DO_FORM_STATE,
            error: { ...INITIAL_TO_DO_FORM_STATE?.error },
            form_type: "add",
        });
        setIsToDoFormOpen(true);
    }

    const handleCloseToDoForm = () => {
        setToDoFormState({
            ...INITIAL_TO_DO_FORM_STATE,
            error: { ...INITIAL_TO_DO_FORM_STATE?.error },
        });
        setIsToDoFormOpen(false);
    }

    const updateToDoFormState = ({ key, value }) => {
        let error = toDoFormState?.error;
        error[key] = "";
        setToDoFormState({ ...toDoFormState, [key]: value, error });
    }

    const toDoFormValidate = () => {
        let isValid = true
        let error = toDoFormState?.error
        if (toDoFormState?.name?.length === 0) { isValid = false; error.name = t("Name is Required"); } else { error.name = ""; }

        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps?.severity?.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setToDoFormState({ ...toDoFormState, error })
        return isValid;
    }

    const handleToDoFormButton = () => {
        setDisable(true)
        if (toDoFormValidate()) {
            let payload = {};

            const current_date = new Date()?.toISOString();

            const params = {
                name: toDoFormState?.name ?? "",
                description: toDoFormState?.description ?? "",
                updated_at: current_date,
            }

            switch (toDoFormState?.form_type) {
                case "add":
                    params["proxy_lead_id"] = data?.id;
                    params["is_done"] = false;
                    params["company_id"] = selected_company?.value;
                    params["created_at"] = current_date;
                    params["is_active"] = true;

                    payload = { type: "add", params };
                    break;
                case "edit":
                    params["id"] = toDoFormState?.id;

                    payload = { type: "edit", params };
                    break;
                case "view":
                    params["id"] = toDoFormState?.id;
                    params["is_done"] = true;

                    payload = { type: "mark", params };
                    break;

                default:
                    break;
            }

            upsertToDo(payload);
        } else {
            setDisable(false)
            return false;
        }
    }

    const getActivityTableData = ({
        offset = activity_table_offset,
        limit = activity_table_limit,
        proxy_lead_id = data?.id,
    }) => {
        set_is_activity_table_loading(true);
        let payload = { offset, limit, proxy_lead_id, };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.proxy_lead_activities_get_proxy_lead_activities_by_proxy_lead_id}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    image: _?.status === "Open" ? _?.open_image : _?.closed_image,
                    activity_name: _?.lead_activity_master?.length > 0 ? _?.lead_activity_master : "-",
                    action_info: _?.notes?.length > 0 ? _?.notes : "-",
                    date: _?.date_time,
                    created_by: _?.created_by?.length > 0 ? _?.created_by : "-",
                    priority: _?.priority?.length > 0 ? _?.priority : "-",
                    status: _?.status?.length > 0 ? _?.status : "-",
                }
            })
            set_activity_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.count ?? 0
            })
            set_is_activity_table_loading(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            })
        });
    }

    const handleActivityTablePagination = ({ page = activity_table_page }) => {
        set_activity_table_page(page);
        let offset = (page - 1) * activity_table_limit;
        getActivityTableData({ offset });
    }

    const handleActivityTablePageLimit = ({ limit = activity_table_limit }) => {
        set_activity_table_limit(limit);
        set_activity_table_page(1);
        getActivityTableData({ limit });
    }

    const renderTab = ({ tab }) => {
        switch (tab?.value) {
            case TabData({ t })?.activity_comments?.value:
                return ActivityComments({
                    t, classes, size,
                    miniLeadCardHeight,
                    comments: comment_state,
                    is_initial_comment_loading,
                    is_add_comment_loading,
                    fetchMoreComments,
                    is_add_comment_enabled: data?.is_add_comment_enabled,
                    add_comment_state,
                    set_add_comment_state,
                    onCommentSubmit,
                    addCommentRef,
                });
            case TabData({ t })?.internal?.value:
                return InternalTable({
                    t, size,
                    miniLeadCardHeight,
                    is_table_loading: is_internal_table_loading,
                    table_state: internal_table_state,
                    table_page: internal_table_page,
                    handleTablePagination: handleInternalTablePagination,
                    table_limit: internal_table_limit,
                    handleTablePageLimit: handleInternalTablePageLimit,
                });
            case TabData({ t })?.external?.value:
                return ExternalTable({
                    t, size,
                    miniLeadCardHeight,
                    is_table_loading: is_external_table_loading,
                    table_state: external_table_state,
                    table_page: external_table_page,
                    handleTablePagination: handleExternalTablePagination,
                    table_limit: external_table_limit,
                    handleTablePageLimit: handleExternalTablePageLimit,
                });
            case TabData({ t })?.to_do_list?.value:
                return ToDoListTable({
                    t, classes, size,
                    miniLeadCardHeight,
                    is_table_loading: is_to_do_list_table_loading,
                    table_state: to_do_list_table_state,
                    table_page: to_do_list_table_page,
                    handleTablePagination: handleToDoListTablePagination,
                    table_limit: to_do_list_table_limit,
                    handleTablePageLimit: handleToDoListTablePageLimit,
                    handleTableIcon: handleToDoListTableIcon,
                    toDoFormCTA: toDoFormCTA,
                    handleAddToDo: handleAddToDo,
                    isToDoFormOpen: isToDoFormOpen,
                    handleCloseToDoForm: handleCloseToDoForm,
                    toDoFormState: toDoFormState,
                    updateToDoFormState: updateToDoFormState,
                    handleToDoFormButton: handleToDoFormButton,
                    disable: disable
                });
            case TabData({ t })?.activity?.value:
                return ActivityTable({
                    t, size,
                    miniLeadCardHeight,
                    is_table_loading: is_activity_table_loading,
                    table_state: activity_table_state,
                    table_page: activity_table_page,
                    handleTablePagination: handleActivityTablePagination,
                    table_limit: activity_table_limit,
                    handleTablePageLimit: handleActivityTablePageLimit,
                });
            default:
                break;
        }
    }

    const handleTabChange = ({ tab }) => {
        if (selected_tab?.value !== tab?.value) {
            set_selected_tab(tab);
            switch (tab?.value) {
                case "activity_comments":
                    const offset = 0;
                    set_comment_offset(offset);
                    getCommentsData({ offset, load_type: "initial" });
                    break;
                case "internal":
                    set_internal_table_page(1);
                    getInternalTableData({});
                    break;
                case "external":
                    set_external_table_page(1);
                    getExternalTableData({});
                    break;
                case "to_do_list":
                    set_is_to_do_list_table_loading(true);
                    set_to_do_list_table_page(1);
                    getToDoListTableData({});
                    break;
                case "activity":
                    set_activity_table_page(1);
                    getActivityTableData({});
                    break;

                default:
                    break;
            }
        }
    }

    const handleViewDetails = () => {
        history.push({
            pathname: Routes.leadview,
            state: {
                main: {
                    company: selected_company,
                    id: data?.id,
                    selectedCompany: selected_company,
                },
            },
        });
    }

    const constructDescriptionElementArray = (rows = 3) => {
        let all_element = [];
        let sliced_element = [];

        if (data?.description?.length > 0) {
            all_element = data?.description?.split('\n').map((_, i) => <Typography className={classes.mini_lead_card_description}>{_}</Typography>)

            if (all_element?.length > rows) {
                sliced_element = [
                    ...all_element?.slice(0, rows),
                    <Typography className={classes.mini_lead_card_description}>{"..."}</Typography>
                ]
            }
        }

        all_element = <Box maxWidth={size?.width > 899 ? 899 : "-webkit-fill-available"}
            maxHeight={"-webkit-fill-available"}>{all_element}</Box>

        return { all_element, sliced_element };
    }

    return (
        <Stack className={classes.home}
            direction={"column"} rowGap={"16px"}>
            <Box ref={miniLeadCardRef} p={"0px 16px"}>
                <Stack className={classes.mini_lead_card}
                    direction={"column"} p={"16px"} >
                    <Stack direction={"row"} columnGap={"16px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        <Hidden smDown>
                            <Stack direction={"column"} rowGap={"8px"}>
                                {(data?.lead_name && data?.reference_no &&
                                    data?.purpose && data?.revenue_type &&
                                    data?.priority) &&
                                    <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}>
                                        {data?.lead_name &&
                                            <Typography className={classes.mini_lead_card_lead_name}>
                                                {data?.lead_name}
                                            </Typography>
                                        }
                                        {data?.reference_no &&
                                            <Typography className={classes.mini_lead_card_reference_no}>
                                                {data?.reference_no}
                                            </Typography>
                                        }
                                        {data?.purpose &&
                                            <Typography className={classes.mini_lead_card_purpose}>
                                                {data?.purpose}
                                            </Typography>
                                        }
                                        {data?.revenue_type &&
                                            <Typography className={classes.mini_lead_card_revenue_type}>
                                                {data?.revenue_type}
                                            </Typography>
                                        }
                                        {data?.priority &&
                                            <Typography className={classes.mini_lead_card_priority}>
                                                {data?.priority}
                                            </Typography>
                                        }
                                    </Stack>}
                                {(data?.mobile_no && data?.email_id) &&
                                    <Stack direction={"row"} columnGap={"12px"} alignItems={"center"}
                                        divider={<Box className={classes.round_box} />}>
                                        {data?.mobile_no &&
                                            <Typography className={classes.mini_lead_card_mobile_no}>
                                                {data?.mobile_no}
                                            </Typography>
                                        }
                                        {data?.email_id &&
                                            <Typography className={classes.mini_lead_card_email_id}>
                                                {data?.email_id}
                                            </Typography>
                                        }
                                    </Stack>
                                }
                            </Stack>
                        </Hidden>
                        <Hidden smUp>
                            <Stack sx={{ width: "100%" }} rowGap={1} mb={1}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    {data?.lead_name &&
                                        <Typography className={classes.mini_lead_card_lead_name}>
                                            {data?.lead_name}
                                        </Typography>
                                    }
                                    {data?.reference_no &&
                                        <Typography className={classes.mini_lead_card_reference_no}>
                                            {data?.reference_no}
                                        </Typography>
                                    }
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} columnGap={2}>
                                        {data?.purpose &&
                                            <Typography className={classes.mini_lead_card_purpose}>
                                                {data?.purpose}
                                            </Typography>
                                        }
                                        {data?.revenue_type &&
                                            <Typography className={classes.mini_lead_card_revenue_type}>
                                                {data?.revenue_type}
                                            </Typography>
                                        }
                                        {data?.priority &&
                                            <Typography className={classes.mini_lead_card_priority}>
                                                {data?.priority}
                                            </Typography>
                                        }
                                    </Stack>
                                    <Button className={classes.view_details_button} sx={{ padding: "0px !important" }}
                                        onClick={handleViewDetails}>
                                        <Typography className={classes.view_details_text}>
                                            {t("View Details")}
                                        </Typography>
                                    </Button>
                                </Stack>
                            </Stack>
                        </Hidden>
                        <Hidden smDown>
                            <Button className={classes.view_details_button}
                                onClick={handleViewDetails}>
                                <Typography className={classes.view_details_text}>
                                    {t("View Details")}
                                </Typography>
                            </Button>
                        </Hidden>
                    </Stack>
                    {data?.description?.length > 0 &&
                        <LightTooltip placement="bottom-start" open={isDescriptionToolTipOpen}
                            title={constructDescriptionElementArray?.()?.all_element}>
                            <Box onClick={() => setIsDescriptionToolTipOpen(!isDescriptionToolTipOpen)}
                                onMouseEnter={() => setIsDescriptionToolTipOpen(true)}
                                onMouseLeave={() => setIsDescriptionToolTipOpen(false)}>
                                <Box ref={descriptionRef} className={classes.mini_lead_card_description_section}>
                                    {constructDescriptionElementArray?.()?.all_element}
                                </Box>
                                {descriptionHeight > 49 && <Typography className={classes.mini_lead_card_description}>{"..."}</Typography>}
                            </Box>
                        </LightTooltip>
                    }
                </Stack>
            </Box>
            <Stack direction={"column"} divider={<Divider />}>
                <Box className={classes.tab_div}>
                    <LineIndicatorTab
                        t={t}
                        path={TabPaths({ t })}
                        data={TabData({
                            t,
                            count: {
                                activity_comments: comment_state?.total_rows_count,
                                internal: internal_table_state?.total_rows_count,
                                external: external_table_state?.total_rows_count,
                                to_do_list: to_do_list_table_state?.total_rows_count,
                                activity: activity_table_state?.total_rows_count,
                            },
                        })}
                        selected={selected_tab}
                        onClick={(tab) => handleTabChange({ tab })} />
                </Box>
                <Box className={classes.tab_box}>
                    {renderTab({ tab: selected_tab })}
                </Box>
            </Stack>
        </Stack>
    );
}