import * as React from "react"
const ActiveContractIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={26} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        x2={0.509}
        y1={45.957}
        y2={46.418}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
      <linearGradient
        id="c"
        x2={1}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
      <radialGradient
        id="b"
        cx={0.922}
        cy={0.709}
        r={0.706}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.348} />
        <stop offset={0.936} stopOpacity={0.098} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
    <g data-name="Group 121811">
      <g
        data-name="Group 118072"
        style={{
          mixBlendMode: "darken",
          isolation: "isolate",
        }}
      >
        <g data-name="icons8-agreement-new (2)" transform="translate(-8 -5)">
          <path
            fill="#50e6ff"
            d="M27.14 11.651v15.116a1.209 1.209 0 0 1-1.209 1.209H10.209A1.209 1.209 0 0 1 9 26.767V6.209A1.209 1.209 0 0 1 10.209 5h10.279Z"
            data-name="Path 101954"
          />
          <path
            fill="url(#a)"
            d="M28 5v5.442a1.209 1.209 0 0 0 1.209 1.209h5.442Z"
            data-name="Path 101955"
            transform="translate(-7.512)"
          />
          <path
            fill="#057093"
            d="M12.024 22.535a.605.605 0 0 1-.306-1.127 8.981 8.981 0 0 0 1.3-1 6.989 6.989 0 0 1-1-3.857c0-2.883 1.119-4.9 2.721-4.9 1.562 0 2.116 1.85 2.116 3.433a8.064 8.064 0 0 1-2.154 5.349 1.675 1.675 0 0 0 .945.288 4.449 4.449 0 0 0 2.318-.657 4.62 4.62 0 0 1-.5-2.185c0-1.331.611-3.205 1.97-3.205.5 0 1.658.238 1.658 2.446a4.525 4.525 0 0 1-1.274 2.972 1.3 1.3 0 0 0 .255.024c.652 0 .98-.351 1.5-.972a3.165 3.165 0 0 1 2.545-1.446.605.605 0 0 1 0 1.209c-.72 0-1.082.386-1.613 1.016a3.061 3.061 0 0 1-2.429 1.4 2.325 2.325 0 0 1-1.281-.353 5.625 5.625 0 0 1-3.142.957 2.9 2.9 0 0 1-1.807-.625 9.83 9.83 0 0 1-1.518 1.149.6.6 0 0 1-.304.084Zm2.72-9.674c-.73 0-1.512 1.482-1.512 3.688a6.319 6.319 0 0 0 .663 2.955 6.842 6.842 0 0 0 1.756-4.419c0-.861-.246-2.225-.907-2.225Zm4.691 3.023c-.286 0-.761.967-.761 2a3.809 3.809 0 0 0 .249 1.405 3.3 3.3 0 0 0 .96-2.165c.001-.373-.043-1.24-.448-1.24Z"
            data-name="Path 101956"
          />
          <path
            fill="url(#b)"
            d="M33.256 26a7.244 7.244 0 0 0-6.589 10.279h5.985a1.209 1.209 0 0 0 1.208-1.209v-9.04c-.2-.016-.4-.03-.604-.03Z"
            data-name="Path 101957"
            opacity={0.3}
            transform="translate(-6.721 -8.302)"
          />
          <path
            fill="#fdb700"
            d="m26.535 18.907 1.408.79 1.615.02.825 1.388 1.388.825.02 1.615.79 1.408-.79 1.408-.02 1.615-1.388.825-.825 1.388-1.615.02-1.408.79-1.408-.79-1.615-.02-.824-1.387-1.39-.825-.02-1.615-.79-1.408.79-1.408.02-1.615 1.39-.824.825-1.388 1.615-.02Z"
            data-name="Path 101958"
          />
          <circle
            cx={4.233}
            cy={4.233}
            r={4.233}
            fill="url(#c)"
            data-name="Ellipse 131096"
            transform="translate(22.302 20.721)"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default ActiveContractIcon
