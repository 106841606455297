/* eslint-disable array-callback-return */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    CircularProgress
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import * as React from 'react';
import { TableWithPagination } from '..';
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
        fontSize:"1rem"
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        backgroundColor: theme.palette.background.tertiary,
        "&.MuiAccordion-root:last-of-type": {
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px"
        },
        "&.MuiAccordion-root:first-of-type": {
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px"
        },
        "&.Mui-expanded": {
            backgroundColor: "white",
            border: "1px solid #CED3DD",
        }
    }
}))
export const AccordionWithTable = (props) => {
    const classes = useStyles(props);
    const [loading, setLoading] = React.useState(true);
    const [offset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    // eslint-disable-next-line
    const [count, setCount] = React.useState("");
    const [expand, setExpand] = React.useState(false);
    const toggleAcordion = () => {
        setExpand(!expand);
    };
    const [list, setList] = React.useState([]);
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTable(offset, limit);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTable(0, value);
    };

    const getTable = (offset, limit) => {
        if (expand === true) {

            //api
            if (props?.data?.api) {
                const payload = {
                    tenantId: `${config.tenantId}`,
                    unitId: props?.data?.unitId,
                    offset: offset,
                    limit: limit
                };
                NetworkCall(
                    `${config.api_url}${props?.data?.api}`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then((response) => {
                        let main = response?.data?.data;

                        const aData = [];
                        if (main) {
                            main.map(data => {
                                const aObj = {}
                                aObj.requestId = data?.request_no
                                aObj.requestName = data?.request_name
                                aObj.raisedDate = moment(data?.raised_on).format("DD MMM YY")
                                aObj.category = data?.category
                                aObj.description = data?.description
                                aObj.status = data?.invoice?.is_active ? "Active" : "Inactive"
                                aData.push(aObj)
                            })
                        }
                        setList(aData)
                        setLoading(false)

                    })
                    .catch((error) => {
                        //             alert.setSnack({
                        // ...alert,
                        // open: true,
                        // severity: AlertProps.severity.error,
                        // msg: "Please fill all mandatory field",
                        // vertical: AlertProps.vertical.top,
                        // horizontal: AlertProps.horizontal.center,
                        //  });
                        console.log(error)
                    });

            }
        }
    }
    React.useEffect(() => {
        getTable(offset, limit)
        // eslint-disable-next-line
    }, [expand, offset, limit])
    return (
        <div>
            <Accordion className={classes.accordion} elevation={0} expanded={expand} onChange={toggleAcordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#091B29" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.text}>{props?.data?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "white" }}>
                    {loading ? (
                        <CircularProgress color="secondary" />
                    ) : (
                        <>
                            {list?.length > 0 ?
                                <TableWithPagination
                                    heading={list?.length > 0 ? props?.data?.table?.heading : []}
                                    rows={list}
                                    path={list?.length > 0 ? props?.data?.table?.path : []}
                                    showpagination={true}
                                    onClick={() => false}
                                    dataType={props?.data?.table?.dataType}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={count}
                                    page={page}
                                    limit={limit}
                                    accordian={true}
                                    height={'calc(100vh - 351px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                                :
                                <Typography color={"error"} textAlign="center">
                                    {"No Records Found"}
                                </Typography>
                            }
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
