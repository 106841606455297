import { Box, Grid } from "@mui/material";
import React from 'react';
import { DialogWithTable } from "../../components/dialogWithTable";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";

export const OtherInfoCard = (props) => {
    const { t } = props
    const allUnitid = props?.allUnitid
    const agreementId = props?.agreementId
    // eslint-disable-next-line
    const [count, setCount] = React.useState({
        accounts: 0,
        otherResidents: 0,
        request: 0,
        agreement: 0,
        invoice: 0
    })

    const tableData = []
    
    React.useEffect(() => {
        if (allUnitid.length > 0 && agreementId.length > 0) {
            const payload = {
                unit_ids: allUnitid,
            }
            NetworkCall(
                `${config.api_url}/queries/get_counts_by_unit_ids`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setCount({
                    owners: res.data?.data?.owner_customer_count?.[0]?.count,
                    otherResidents: res.data?.data?.other_residnets_count?.[0]?.count ?? 0,
                    breakup: res.data?.data?.pricing_table?.[0]?.count,
                    vacancy: res.data?.data?.unit_vacancy_period?.[0]?.count,
                    amenities: res.data?.data?.unit_amenities_breakup?.[0]?.count,
                    rooms: res.data?.data?.unit_room?.[0]?.count,
                    request: res.data?.data?.security_count?.[0]?.count,
                    agreement: res.data?.data?.agreement_units_count?.[0]?.count,
                    invoice:res.data?.data?.invoice_units_count?.[0]?.count,
                    accounts: res.data?.data?.agreement_count?.[0]?.count
                })
            })
        }
        // eslint-disable-next-line
    }, [allUnitid, agreementId])
    return (

        <div>
            <Box height="8px" />
            <Grid container spacing={2}>
                {tableData.map((item) => (
                    <>
                        <Grid item xs={2}>
                            <Box p={0.5}>
                                <DialogWithTable data={item} t={t}/>
                            </Box>
                        </Grid>
                    </>

                )
                )}
            </Grid>

        </div>


    )
}