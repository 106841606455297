import React from "react";
import { withNavBars } from "../../HOCs";
import Dashboard from "./dashboard";

class PaymentDashboard extends React.Component {
  render() {
    return <Dashboard {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(PaymentDashboard, props);
