import React, { useEffect, useState, useContext } from "react";
import { TableWithPagination, DialogBox } from "../../../components";
import { ActivityHeading, ActivityPath } from "../common";
import { LeadStylesParent } from "./style";
import { Grid } from "@mui/material";
import Fab from "@mui/material/Fab";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {
  Card,
  AddActivity,
  CloseActivity,
  ViewActivity
} from "./activitycomponent";

import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

const initial = {
  Category: "",
  Purpose: "",
  mobile: "",
  visitorDate: new Date(),
  Notes: "",
  Priority: "",
  Followup: "",
  location: "",
  email: "",
  isEdit: false,
  status: "",
  remarks: "",
  closed_on: "",
  subcatagory: "",
  error: {
    Category: "",
    Purpose: "",
    mobile: "",
    visitorDate: "",
    Notes: "",
    Priority: "",
    Followup: "",
    location: "",
    email: "",
    subcatagory: "",
  },
};
export const Activity = (props) => {
  const { t } = (props)
  // apolo clint
  const language = localStorage.getItem("i18nextLng");
  // state
  const [viewdialog, setViewDialog] = useState(false);
  const [type, setType] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [viewDialog, setviewDialog] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [state, setState] = useState({ ...initial });
  const [activities, setActivities] = useState({
    data: [],
    count: 0,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [step, setStep] = React.useState(1);
  // classes
  const classes = LeadStylesParent(props);
  const alert = useContext(AlertContext);
  // context
  const backdrop = useContext(BackdropContext);
  const typeSelect = (type) => {
    setType(type);
    setViewDialog(false);
    setAddDialog(true);
  };
  const getTypes = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
      NetworkCall(
        `${config.api_url}/queries/opportunity/lead_activity_master`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
      )
      .then((res) => {
        setTypeList(res?.data?.data?.lead_activity_master?.map((c)=>{
          return{
            ...c,
            name:c?.name === "Arrange Site Visit" ? "Showcase and Site Visit" : c?.name
          }
        }));
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
  };
  // updateState
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    setState({ ...state, [key]: value, error });
  };
  const validate = () => {
    let isValid = true;
    let error = state.error;

    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = t("Category is Required");
    }
    // if (state?.subcatagory?.length === 0) {
    //   isValid = false;
    //   error.subcatagory = "Sub Category is Required";
    // }
    // if (state?.Purpose?.length === 0) {
    //   isValid = false;
    //   error.Purpose = "Purpose is Required";
    // }
    if (state?.mobile?.length === 0 && type?.name === "Phone call") {
      isValid = false;
      error.mobile = t("Mobile Number is Required");
    }
    if (state?.visitorDate?.length === 0) {
      isValid = false;
      error.visitorDate = t("Call Date & Time is Required");
    }
    if (state?.Notes?.length === 0) {
      isValid = false;
      error.Notes = t("Notes is Required");
    }
    if (state?.Priority?.length === 0) {
      isValid = false;
      error.Priority = t("Priority is Required");
    }
    if (
      state?.Followup?.length === 0 &&
      (type?.name === "Follow up" ||
        type?.name === "Meeting" ||
        type?.name === "Appointment")
    ) {
      isValid = false;
      error.Followup = t("Followup is Required");
    }
    if (
      state?.location?.length === 0 &&
      state?.Followup?.value === "In-Person"
    ) {
      isValid = false;
      error.location = t("location is Required");
    }
    if (
      state?.email?.length === 0 &&
      (type?.name === "Send Email" ||
        type?.name === "Send Quotation")
    ) {
      isValid = false;
      error.email = t("email is Required");
    }
    setState({ ...state, error });

    return isValid;
  };
  // close pop up
  const handleClose = () => {
    setAddDialog(false);
    setState({ ...initial });
  };
  const getActivityList = (offset, limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
      NetworkCall(
        `${config.api_url}/queries/opportunity/proxy_lead_activity`,
        NetWorkCallMethods.post,
        {
          id: props?.proxy_lead_id,
          offset: offset,
          limit: limit,
        },
        null,
        true,
        false
      )
      .then((res) => {
        let result = res?.data?.data?.proxy_lead_activities?.map((x) => {
          return {
            data: x,
            image:
              x?.status === "Open"
                ? x?.proxy_lead_activity_master?.image_url
                : x?.proxy_lead_activity_master?.inactive_logo,
            name: x?.proxy_lead_activity_master?.name,
            info:
              (x?.email_id?.length > 0 && x?.email_id) ||
              (x?.location?.length > 0 && x?.location) ||
              (x?.type?.length > 0 && x?.type) ||
              (x?.notes?.length > 0 && x?.notes) ||
              x?.phone_code + x?.phone_number,
            Date: x?.date_time,
            CreatedBy: (x?.user_profiles?.first_name || x?.user_profiles?.last_name) ?  `${x?.user_profiles?.first_name ?? ""} ${x?.user_profiles?.last_name ?? ""}` : '-' ,
            Priority: x?.priority,
            Status: x?.status ?? "",
            icon: x?.status === "Open" ? "edit" : "",
          };
        });

        setActivities({
          data: result ?? [],
          count: res?.data?.count?.[0]?.count,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `something went wrong`,
        });
      });
  };
  const handleIcon = (type, value) => {
    const data = value?.data;
    // if (data?.status !== "Closed") {
    setState({
      Category: data?.activity_category ?? "",
      Purpose: {
        value: data?.purpose ?? "",
        label: data?.purpose ?? "",
      },
      mobile: {
        mobile: data?.phone_number,
        mobile_code: data?.phone_code,
      },
      visitorDate:
        data?.date_time?.length > 0 ? new Date(data?.date_time) : "",
      Notes: data?.notes ?? "",
      Priority: data?.priority,
      Followup: {
        value: data?.type ?? "",
        label: data?.type ?? "",
      },
      location: data?.location ?? "",
      email: data?.email_id ?? "",
      isEdit: true,
      id: data?.id,
      status: data?.status ?? "",
      remarks: data?.remarks ?? "",
      closed_on: data?.closed_on ?? "",
      subcatagory: data?.activity_sub_category ?? "",
      error: {
        Category: "",
        Purpose: "",
        mobile: "",
        visitorDate: "",
        Notes: "",
        Priority: "",
        Followup: "",
        location: "",
        email: "",
      },
    });
    setType(data?.proxy_lead_activity_master);
    if (type === "edit") {
      setAddDialog(true);
    } else {
      setviewDialog(true);
    }
    // }
  };

  // pagination function
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getActivityList(offset, limit);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getActivityList(0, value);
  };
  // setviewDialog
  const closeDialogFunction = (key) => {
    if (key === "close") {
      setState({ ...initial });
    }
    setCloseDialog(!closeDialog);
    setviewDialog(false);
  };

  const closeView = () => {
    setviewDialog(false);
    setState({ ...initial });
  };

  // open edit
  const openEdit = () => {
    setAddDialog(true)
    setviewDialog(false)
  }

  //on previous
  const previous = () => {
    setStep(step - 1);
  };
  //on next
  const next = () => {
    if (validate()) {
      setStep(step + 1);
    }
  };


  useEffect(() => {
    getTypes();
    getActivityList(0, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TableWithPagination
        heading={ActivityHeading}
        rows={activities?.data ?? []}
        path={ActivityPath}
        showpagination={true}
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        page={page}
        limit={limit}
        totalRowsCount={activities?.count}
        handlePagination={handlePagination}
        handleChangeLimit={handleChangeLimit}
        handleIcon={handleIcon}
        tableType="no-side"
        count="2"
        marginTop={"1px"}
        dataType={[
          { type: ["avatarmanagement"], name: "image" },
          { type: ["text"], name: "name" },
          { type: ["description"], name: "info" },
          { type: ["date"], name: "Date" },
          { type: ["text"], name: "CreatedBy" },
          { type: ["text"], name: "Priority" },
          { type: ["status2"], name: "Status" },
          { type: ["icon"], icon: "icon" },
        ]}
        height={"calc(100vh - 298px)"}
        view={true}
        edit={props?.Status}
        noDataSvg
        delete={props?.Status} />

      {/* choose dialog */}
      <DialogBox
        open={viewdialog}
        padding={"24px"}
        maxWidth={"sm"}
        onClose={() => setViewDialog(false)}
        header={t("Create Activity")}
        width={"551px !important"}
        background
        component={
          <div style={{ padding: "16px" }}>
            <Grid container spacing={3}>
              {typeList?.map((item) => {
                return (
                  <Grid item xs={4}>
                    <Card t={t} data={item} onClick={typeSelect} type={type} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        }
      />

      {/* add activity */}
      <DialogBox
        open={addDialog}
        padding={"16px"}
        maxWidth={step === 1 ? "md" : "sm"}
        onClose={handleClose}
        header={`${state?.isEdit ? t("Edit Activity") : t("Create Activity")} ${type?.name ? "-" : ""} ${type?.name ? type?.name : ""}`}
        width={"748px !important"}
        background
        paddingHead={"16px"}
        component={
          <div>
            <AddActivity
              cancel={handleClose}
              type={type}
              proxy_lead_id={props?.proxy_lead_id}
              proxy_lead_data={props?.details}
              updateState={updateState}
              state={state}
              validate={validate}
              getActivityList={getActivityList}
              selectedCompany={props?.selectedCompany}
              previous={previous}
              next={next}
              step={step}
              reset={() => setStep(1)}
              t={t}
            />
          </div>
        }
      />

      {/* Activity */}
      <DialogBox
        open={viewDialog}
        padding={"24px"}
        maxWidth={"sm"}
        onClose={closeView}
        header={`Activity`}
        width={"748px !important"}
        background
        component={
          <div>
            <ViewActivity
              state={state}
              type={type}
              viewDialogFunction={closeDialogFunction}
              openEdit={openEdit}
              t={t}
              Status={props?.Status}
            />
          </div>
        }
      />
      {/* CloseActivity */}
      <DialogBox
        open={closeDialog}
        padding={"24px"}
        maxWidth={"xs"}
        onClose={() => closeDialogFunction("close")}
        header={`Close Activity`}
        width={"374px !important"}
        background
        component={
          <div>
            <CloseActivity
              state={state}
              type={type}
              id={state?.id}
              closeDialogFunction={closeDialogFunction}
              getActivityList={getActivityList}
              t={t}
            />
          </div>
        }
      />

      {/* fab */}
      {/* fab */}
      {props?.Status && (
        <Fab
          className={language === "ar" ? classes.fabArabic : classes.fab}
          size="large"
          color="primary"
          onClick={() => setViewDialog(true)}
        >
          <SpeedDialIcon />
        </Fab>
      )}
    </>
  );
};
