import { Box } from "@mui/material";
import React from "react";
import { UploadComponent } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";

export const Attachments = ({ t = () => false, id = null }) => {
    const [assets, setAssets] = React.useState([])
    React.useEffect(() => {
        if (id !== null) {
            getAssetDetails()
        }
        //eslint-disable-next-line
    }, [])
    const getAssetDetails = (data) => {
        const payload = {
            lead_id: id
        }

        NetworkCall(
            `${config?.api_url}/opportunity/get_asset`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setAssets(response?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const uploadAssets = (data) => {
        const payload = {
            "params": data?.map((x) => {
                return (
                    {
                        "lead_id": id,
                        id: x?.id,
                        "asset_type":null,
                        "url": x?.url,
                        "file_meta": x?.file_meta,
                        "is_active": x?.is_active
                    }
                )
            })
        }
        NetworkCall(
            `${config?.api_url}/opportunity/upsert_asset`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            getAssetDetails()
        }).catch((err) => {
            console.log(err)
        })
    }
    return (

        <Box>
                <UploadComponent
                    logo_title={t("Documents")}
                    assets={assets}
                    setAssets={setAssets}
                    xs={2}
                    height={"190px"}
                    accept={"image/*,.pdf"}
                    imageHeight={"188px"}
                    isCustomUpload={true}
                    handleImageUpload={(val)=>uploadAssets(val)}
                    isCustomDelete={true}
                    isOpportunity={true}
                    border={true}
                />
          
        </Box>
    )
}