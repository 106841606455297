
import { Box, } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import CityProperty from '../../assets/cityproperty';
import { LoadingSection, PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, enum_types, enumSelect, getRoutePermissionNew, isEmptyObject, NetWorkCallMethods, ValidateEmail } from "../../utils";
import { accountState, returnEditPayloadAccount, returnPayloadAccount } from "../../utils/accountUtiles";
import { Address, BankDetails, Contact, Profile, Proof } from "./sections";
import { IdDetails } from "./sections/idDetails";
import { RegisteredDetails } from "./sections/registeredDetails";
import { useStyles } from "./styles";
const CreateAccount = ({ reltype = null, t = () => false }) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const { state } = useLocation()
    const acc_id = state?.id
    const [data, setData] = React.useState({ ...accountState });
    const [loading, setLoading] = React.useState(false)
    const [fetch, setFetch] = React.useState(false)
    const [disable, setDisable] = React.useState(false)
    const [value, setValue] = React.useState(1);
    const [enumValue, setEnumValue] = React.useState({
        account_relationship: [],
        bank_routing_type: [],
        bank_account_type: [],
        tax_code: [],
        rera_type: [],
        company_registration: [],
        payment_classification: []
    })
    const [permission, setPermission] = React.useState({})

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getAccount()
            }
        }
        get_enum()
        // eslint-disable-next-line
    }, [auth]);

    const typeSelect = {
        "Vendor": Routes.vendorAccountList,
        "Property Owner": Routes.ownerAccountList,
        "Customer": Routes.customerAccountList,
        "AllAccount": Routes.accounts,
        "cashBank": Routes.cashBankAccountList
    }

    const tabTittle = [
        {
            label: t("Account Details"),
            imgSelect: value > 1 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: t("KYC Details"),
            imgSelect: value > 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]

    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === "relation_ship") {
            setData({
                ...data,
                relation_ship: value,
                contact: "",
                error: {
                    ...error,
                    contact: "",
                }
            })
        } else if (key === "registered_company") {
            if (value === false) {
                setData({
                    ...data,
                    registered_company: value,
                    cr_number: ""
                })
            } else {
                setData({
                    ...data,
                    registered_company: value
                })
            }
        } else if (key === "tax_registered") {
            if (value === false) {
                setData({
                    ...data,
                    tax_registered: value,
                    tax_number: ""
                })
            } else {
                setData({
                    ...data,
                    tax_registered: value,
                })
            }
        } else if (key === "account_type_ap_ar") {
            if (value === "AR") {
                setEnumValue({
                    ...enumValue,
                    account_relationship: enumValue?.non_filtered_relationship?.filter((val) => ["Walk In Account", "Customer"].includes(val?.value))
                })
            }
            else if (value === "AP") {
                setEnumValue({
                    ...enumValue,
                    account_relationship: enumValue?.non_filtered_relationship?.filter((val) => ["Property Owner", "Sales Broker", "Vendor", "Service Provider"].includes(val?.value))
                })
            }
            else{
                setEnumValue({
                    ...enumValue,
                    account_relationship: []
                })
            }
            setData({
                ...data,
                account_type_ap_ar: value,
                relation_ship: "",
                contact: ""
            })
        }
        else {
            setData({ ...data, [key]: value, error });
        }
    };

    const get_enum = async () => {
        const res = await enumSelect([enum_types.account_relationship, enum_types.bank_routing_type, enum_types.bank_account_type, enum_types?.tax_code, enum_types?.rera_type, enum_types?.company_registration, enum_types.payment_classification])

        let relationship = []

        switch (reltype) {
            case "cashBank":
                relationship = res?.account_relationship?.filter((val) => ["Cash", "Credit Card", "Bank Account"].includes(val?.value))
                break
            case "Customer":
                relationship = res?.account_relationship?.filter((val) => ["Walk In Account", "Customer"].includes(val?.value))
                break
            case "Vendor":
                relationship = res?.account_relationship?.filter((val) => ["Property Owner", "Sales Broker", "Vendor", "Service Provider"].includes(val?.value))
                break
            case "Property Owner":
                relationship = res?.account_relationship?.filter((val) => ["Property Owner", "Sales Broker", "Vendor", "Service Provider"].includes(val?.value))
                break
            default:
                relationship = res?.account_relationship?.filter((val) => ["Walk In Account", "Customer"].includes(val?.value))
                break
        }

        setEnumValue({
            non_filtered_relationship: res?.account_relationship,
            account_relationship: relationship,
            bank_routing_type: res?.bank_routing_type,
            bank_account_type: res?.bank_account_type,
            tax_code: res?.tax_code,
            rera_type: res?.rera_type,
            company_registration: res?.company_registration,
            payment_classification: res?.payment_classification?.filter((val) => val?.value !== "cash_and_bank")
        })
    }

    const getAccountDetails = () => {
        get_account_details(acc_id)
    }

    const getAccount = () => {
        if (acc_id) {
            getAccountDetails()
        } else {
            setData({
                ...data,
                company: state,
                currency: state?.currency,
                relation_ship: reltype === "AllAccount" ? "" : reltype === "cashBank" ? { label: "Cash", value: "Cash" } : { label: reltype, value: reltype },
                account_type_ap_ar: reltype === "AllAccount" ? "AR" : ((reltype === "Vendor" || reltype === "Property Owner") ? "AP" : reltype === "cashBank" ? "cash_and_bank" : "AP")
            })
        }
    }

    const goBack = () => {
        history.goBack(-1)
    }

    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }

        let companyID = data?.company?.value;

        switch (type) {
            case 'tax_group':
                if (!companyID) {
                    setLoading(null);
                    return { options: [] }
                }
                query = {
                    url: "/queries/vat_group_master/list",
                    payload: {
                        offset, limit: 10, search, company_id: companyID
                    }
                }
                result = await networkCallBack(query);
                return {
                    options: [...result?.vat_group_master],
                    hasMore: (array?.length + result?.vat_group_master?.length) < result?.count
                }
            case 'currency':
                query = {
                    url: "/queries/currency_master/list",
                    payload: {
                        offset, limit: 10, search
                    }
                }
                result = await networkCallBack(query);
                return {
                    options: [...result?.currency_master],
                    hasMore: (array?.length + result?.currency_master?.length) < result?.count
                }
            case "country":
                query = {
                    url: "/queries/opportunity/country_master",
                    payload: {
                        offset, limit: 10, search
                    }
                }
                result = await networkCallBack(query);
                return {
                    options: [...result?.country_master],
                    hasMore: (array?.length + result?.country_master?.length) < result?.count
                }
            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (query, variable = {}) => {
        let payload = query.payload;

        const options = await NetworkCall(
            `${config.api_url}${query.url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);
                return null
            });

        return options
    }

    const validateAccount = () => {
        let isValid = true;
        let error = data.error;
        if (data?.acc_name?.length === 0) {
            isValid = false;
            error.acc_name = t("Account Name  is Required");
        }
        if (data?.relation_ship?.label === null) {
            isValid = false;
            error.relation_ship = t("Relationship  is Required");
        }
        if (data?.relation_ship?.value === "Property Owner" && data?.contact?.length === 0) {
            isValid = false;
            error.contact = "Contact is Required";
        }
        if (!Boolean(data?.primary_contact?.mobile) || data?.primary_contact?.mobile?.length === 0) {
            isValid = false;
            error.primary_contact = t("Bussiness Phone is Required");
        }
        if (!Boolean(data?.primary_telephone?.mobile) || data?.primary_telephone?.mobile?.length === 0) {
            isValid = false;
            error.primary_telephone = t("Mobile Number is Required");
        }
        if (data?.primary_email?.length === 0) {
            isValid = false;
            error.primary_email = t("Email is Invalid");
        } else if (data?.primary_email?.length > 0) {

            if (!ValidateEmail(data?.primary_email)) {
                isValid = false;
                error.primary_email = t("Email is Invalid");
            }
        }
        if (reltype !== "cashBank") {
            if (data?.account_group === "") {
                error.account_group = data?.account_type_ap_ar === "AP" ? t("Vendor Group is Required") : t("Customer Group is required")
            }
        }


        if (data?.bussiness_type === "") {
            error.bussiness_type = t("Business Type is Required")
        }

        if (data?.account_type_ap_ar?.length === 0) {
            error.account_type_ap_ar = t("Account Type is Required")
        }

        if (data?.registered_company === true) {
            if (data?.cr_number?.length === 0) {
                error.cr_number = t("CR Number is Required")
            }
        }

        if (data?.tax_registered === true) {
            if (data?.tax_number?.length === 0) {
                error.tax_number = t("Tax Number is Required")
            }
        }
        if (data?.alt_acc_no === "") {
            isValid = false;
            error.alt_acc_no = t("Alternative Account Number is Required");
        }
        setData({ ...data, error });
        return isValid;
    }

    const createAccount = () => {
        setDisable(true)
        const payload = returnPayloadAccount(data)


        NetworkCall(
            `${config.api_url}/account/upsert`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `Account Successfully ${acc_id ? t("Updated") : t("Created")}`,
            });
            setDisable(false)
            history.push({
                pathname: reltype?.length > 0 ? typeSelect?.[reltype] : Routes.accounts,
                state: {
                    ...data?.company,
                    currency: data?.currency
                }
            })
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
            setDisable(false)
        })

    }

    const checkSave = () => {
        if (data?.acc_id) {
            if (permission.update) {
                next()
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                    severity: AlertProps.severity.error,
                })
            }
        } else if (permission.create) {
            next()
        } else {
            alert.setSnack({
                ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                severity: AlertProps.severity.error,
            })
        }
    }


    const next = () => {
        if (value === 1) {
            if (validateAccount()) {
                checkAlternativeAccount()
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please fill all mandatory field"),
                });
            }
        } else {
            if (isEmptyObject(data?.proofFile, alert)) {
                createAccount()
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please fill all Proof field"),
                });
            }

        }

    }

    const previous = () => {
        setValue(1)
    }

    const checkAlternativeAccount = async () => {
        const payload = {
            account_no: data?.alt_acc_no,
            id: state?.viewtype === "edit" ? data?.acc_id : undefined
        }
        NetworkCall(
            `${config.api_url}/account/check_account_no`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            if (res?.data?.data) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Alternative Account already exist`,
                });
            }
            if (state?.viewtype === "edit" && !res?.data?.data) {
                setValue(2)
            } else if (!res?.data?.data) {
                setValue(2)
            }
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        })
    }


    const get_account_details = (id) => {
        const payload = {
            id: id
        }
        NetworkCall(
            `${config.api_url}/account/get_detail`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            const result = returnEditPayloadAccount(res?.data?.data?.[0], acc_id, reltype)
            setData(result)
            setFetch(false)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        })
    }

    const render = () => {
        return <>
            <Subheader
                title={`${acc_id ? `${t("Update Account")}${data?.contact_account_no ? ` - ${data?.contact_account_no}` : ``}` : t("Create New Account")}`}
                goBack={goBack}
                previousAndNextBtn={true}
                previousBtn={previous}
                nextBtn={checkSave}
                previousBtnDisabled={value <= 1 && true}
                nextBtnDisabled={value === 2 ? true : false || disable}
            />
            {
                fetch ?
                    <LoadingSection bottom={"45vh"} message="Fetching Data..." />
                    :
                    <Box>
                        <PropertyTabs value={value} padding={"8px 16px 8px 8px"}
                            t={t}
                            tab1={(
                                <Box className={classes.root}>
                                    <Profile updateState={updateState} data={data} enumValue={enumValue} loadOptions={loadOptions} loading={loading} setData={setData} reltype={reltype} acc_id={acc_id} t={t} state={state} />
                                    <RegisteredDetails updateState={updateState} data={data} t={t} />
                                    <Address mapLoad={acc_id ? false : true} updateState={updateState} setData={setData} data={data} loadOptions={loadOptions} loading={loading} t={t} />
                                    <Contact updateState={updateState} data={data} loadOptions={loadOptions} loading={loading} t={t} />
                                    <BankDetails updateState={updateState} data={data} enumValue={enumValue} loadOptions={loadOptions} loading={loading} t={t} />
                                    <IdDetails updateState={updateState} data={data} t={t} />
                                </Box>
                            )}
                            tab2={(
                                <Box className={classes.root}>
                                    <Proof t={t} data={data?.proofFile} update={(data) => updateState("proofFile", data)} />
                                </Box>
                            )}
                            tabTittle={tabTittle}
                            showBtns={true}
                            isSaveDisable={value === 2 ? disable ? disable : false : true || disable === true ? true : false}
                            saveBtnText={acc_id ? t("Update") : t("Create")}
                            goBack={goBack}
                            save={checkSave}
                        />
                    </Box>
            }
        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>

    )

}
export default withNamespaces("createAccount")(CreateAccount)
