import * as React from "react"
const ActiveInternalProjectIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={26} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.997}
        x2={-0.015}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0068b8" />
        <stop offset={1} stopColor="#004d94" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.014}
        x2={1.024}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#28afea" />
        <stop offset={1} stopColor="#0b88da" />
      </linearGradient>
    </defs>
    <g data-name="Group 121811">
      <g
        data-name="Group 118072"
        style={{
          mixBlendMode: "darken",
          isolation: "isolate",
        }}
      >
        <g data-name="icons8-project (4)">
          <path
            fill="url(#a)"
            d="M4 10h22.8a1.2 1.2 0 0 1 1.2 1.2v15.6a1.2 1.2 0 0 1-1.2 1.2H8.2A4.2 4.2 0 0 1 4 23.8Z"
            data-name="Path 102132"
            transform="translate(-3.5 -4.4)"
          />
          <path
            fill="url(#b)"
            d="M8.2 17.2h1.2a1.2 1.2 0 0 0 1.2-1.2V5.2A1.2 1.2 0 0 0 9.4 4H8.2A4.2 4.2 0 0 0 4 8.2v13.2a4.2 4.2 0 0 1 4.2-4.2Z"
            data-name="Path 102133"
            transform="translate(-3.5 -2)"
          />
          <path
            fill="#35c1f1"
            d="M9.2 18.2h11.4a.3.3 0 0 1 .3.3v.6a.3.3 0 0 1-.3.3H9.2a.3.3 0 0 1-.3-.3v-.6a.3.3 0 0 1 .3-.3Z"
            data-name="Path 102134"
          />
          <path
            fill="#35c1f1"
            d="m9.712 16.012-.424-.424a.3.3 0 0 1 0-.424l3.9-3.9a.3.3 0 0 1 .424 0l2.188 2.187 3.176-3.176.848.848-3.812 3.812a.3.3 0 0 1-.424 0L13.4 12.748l-3.263 3.263a.3.3 0 0 1-.425.001Z"
            data-name="Path 102135"
          />
          <path
            fill="#35c1f1"
            d="m17.817 9.712 2.576 2.576a.3.3 0 0 0 .512-.212V9.5a.3.3 0 0 0-.3-.3h-2.581a.3.3 0 0 0-.207.512Z"
            data-name="Path 102136"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default ActiveInternalProjectIcon
