import React from "react"

export const PersonImage = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_139862" data-name="Group 139862" transform="translate(-156 -225.5)">
                <g id="Rectangle_58797" data-name="Rectangle 58797" transform="translate(156 225.5)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="18" height="18" stroke="none" />
                    <rect x="0.5" y="0.5" width="17" height="17" fill="none" />
                </g>
                <path id="icons8-user" d="M14.879,4a4.163,4.163,0,0,0-4.3,4.013,4.163,4.163,0,0,0,4.3,4.013,4.163,4.163,0,0,0,4.3-4.013A4.163,4.163,0,0,0,14.879,4Zm5.168,9.63H9.711A1.659,1.659,0,0,0,8,15.227v.61a3.683,3.683,0,0,0,2.282,3.235,10.266,10.266,0,0,0,4.6.978c3.313,0,6.879-1.319,6.879-4.213v-.61A1.659,1.659,0,0,0,20.047,13.63Z" transform="translate(150.121 222.475)" fill="#a9afbc" />
            </g>
        </svg>

    )
}