import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { CustomToggleButtonComponent, DatePickerNew, SelectBox, TextBox, Label } from "../../../components";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { proofComponentStyles } from "../styles";
import { ProofView } from "./proofView";
import UploadFile from "./uploadBtn";

export const From = ({ data = {}, updateState = () => false, id = "", key = "", onDeleteProof = () => false, company = false, t = () => false, isNotRequired = false }) => {
    const classes = proofComponentStyles()
    // const [loading, setLoading] = React.useState(false);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState("");
    const [currentImageType, setCurrentImageType] = React.useState("");

    //openImageViewer
    const openImageViewer = (index) => {
        setCurrentImage(index?.[0]);
        setCurrentImageType(index?.[1]);
        setIsViewerOpen(true);
    };
    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage("");
        setCurrentImageType("");
        setIsViewerOpen(false);
    };

    const mandatry_verify = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },
    ]

    return (
        <Box>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.titleType}>{data?.type?.label}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        isrequired
                        label={t("Name As In Proof")}
                        placeholder={t("Enter Name As In Proof")}
                        onChange={(e) => updateState("name", e.target.value, id)}
                        value={data?.name}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label={t("ID Proof Number")}
                        placeholder={t("Enter ID Proof Number")}
                        isrequired={isNotRequired ? false : true}
                        value={data?.id_no}
                        onChange={(e) => updateState("id_no", e.target.value, id)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <DatePickerNew
                        label={t("Valid From")}
                        isrequired={isNotRequired ? false : true}
                        placeholder={t("Valid From")}
                        value={data?.valid_from ?? null}
                        handleChange={(value) => updateState("valid_from", value, id)}
                        padding={"10px 4px"}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <DatePickerNew
                        label={t("Valid To")}
                        isrequired={isNotRequired ? false : true}
                        placeholder={t("Valid To")}
                        value={data?.valid_to ?? null}
                        minDate={data?.valid_from ?? null}
                        handleChange={(value) => updateState("valid_to", value, id)}
                        padding={"10px 4px"}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <SelectBox
                        label={t("Issuing Country")}
                        placeholder={t("Select Issuing Country")}
                        isRequired={isNotRequired ? false : true}
                        value={data?.issuing_country}
                        isPaginate
                        debounceTimeout={800}
                        // loading={loading === "country"}
                        // loadOptions={(search, array) => loadOptionData(search, array, "country")}
                        loadOptions={
                            (search, array, handleLoading) =>
                                loadOptionsApis(
                                    "queries/opportunity/country_master",
                                    {},
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                )
                        }
                        onChange={(value) => updateState("issuing_country", value, id)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Label label={t("Mandatory Verify")} isRequired={isNotRequired ? false : true} />
                    <CustomToggleButtonComponent
                        t={t}
                        value={data?.mandatry_verify}
                        onChange={(e) => updateState("mandatry_verify", e, id)}
                        height="46px"
                        options={mandatry_verify}
                        isMulti
                    />
                </Grid>
            </Grid>
            <Box height="8px" />

            <Grid item xs={3} md={3} lg={2}>
                <Typography className={classes.label}>{t("Documents")}
                    {!company && <Typography color="error" variant="caption">
                        &nbsp;
                    </Typography>
                    }
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={3} md={2}>
                    <UploadFile
                        label={t("Upload Documents Here")}
                        data={data?.file}
                        handleChange={(value) => updateState("file", value, id)}
                        id={id}
                    />

                </Grid>
                {
                    data?.file?.map((val, index) => {
                        return (
                            <>
                                <Grid item xs={3} md={2}>
                                    <ProofView
                                        name={val?.name}
                                        src={val?.src}
                                        fileType={val?.type}
                                        onDelete={() => onDeleteProof(id, index)}
                                        closeImageViewer={closeImageViewer}
                                        openImageViewer={openImageViewer}
                                        isViewerOpen={isViewerOpen}
                                        currentImage={currentImage}
                                        currentImageType={currentImageType}
                                    />
                                </Grid>


                            </>

                        )
                    })
                }
            </Grid>
            <Box height="8px" />
        </Box>
    )
} 