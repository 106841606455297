import React from "react";
import { withNavBars } from "../../HOCs";
import ProjectCategory from "./projectCategory";

class ProjectCategoryParent extends React.Component {
    render() {
        return <ProjectCategory />
    }
}

const props = {
    boxShadow: false
}

export default withNavBars(ProjectCategoryParent, props);
