import { Box } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";
import { QuoteUnitsdataType, getQuoteUnitHeading, QuoteUnitsPath } from "../../../utils/agreementUtils";
import { PaymentScheduleAccordion } from "../components";
import { timeZoneConverter } from "../../../utils";

const QuotationInformation = ({ details = {}, t = () => false }) => {
    const unitHeading = getQuoteUnitHeading(t)
    const card1 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("quotationNumber"),
            subtitle: `${details?.quotation_no}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "date",
            heading: t("date"),
            subtitle: `${details?.quote_start_date}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("purpose"),
            subtitle: `${details?.unit_usage}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("paymentMethod"),
            subtitle: `${details?.payment_mode}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "date",
            heading: t("quotationValidTill"),
            subtitle: `${details?.quotation_expiry_date}`
        }

    ]



    return (
        <Box p={2}>
            <DetailsCardCoponent components={card1} />
            {/* <Box height="12px" /> */}
            <PaymentScheduleAccordion
                row={details?.quotation_units?.map((val) => {
                    return {
                        id: val?.id,
                        image: val?.unit?.logo,
                        name: val?.unit?.name,
                        type: val?.unit?.type?.name ?? "-",
                        unit_no: val?.unit?.unit_no,
                        planned_hand_over_date:val?.unit?.planned_hand_over_date ? timeZoneConverter(val?.unit?.planned_hand_over_date) : "-",
                        property_name:val?.unit?.property?.name ?? "-",
                        total_area:`${val?.unit?.total_area} ${val?.unit?.uom?.name}`,
                        category:val?.unit?.category?.name ?? "-"
                        
                    }
                })}
                heading={unitHeading}
                path={QuoteUnitsPath}
                type={QuoteUnitsdataType}
                title={`${t("noOfUnits")} (${details?.quotation_units?.length})`} />
        </Box>
    )
}
export default withNamespaces("agreement")(QuotationInformation); 