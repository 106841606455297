import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, remCalc } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: "16px",
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "0.875rem"
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    title: {
        textTransform: "uppercase",
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    countText: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    valueName: {
        fontSize: remCalc(14),
        color: theme.typography.color.secondary,
        fontFamily: SemiBold
    },
    card: {
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "8px",
        cursor: "pointer"
    },
    tabBox: {
        padding: "12px !important",
        alignItems: "start !important"
    },
    publishtext: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: Bold,
        cursor:"pointer"
    },
    selectedPublishText: {
        fontSize: remCalc(14),
        color: "#5078E1",
        fontFamily: Bold,
        cursor:"pointer"
    }
}))