import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import {
      PropertyTabs, Subheader
} from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getCustomBlockName, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { Assets } from './blockView/assets';
import { BlockDetailsViewing } from './blockView/blockDetailsViewing';
import { OtherInfoViewing } from './blockView/otherInfoViewing';
import { blockStylesParent } from "./style";
import { withNamespaces } from "react-i18next";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";


const BlockView = ({
      t = () => false
}) => {
      const classes = blockStylesParent();
      const history = useHistory()
      const [data, setData] = React.useState([])
      const search = useLocation().search;
      const alert = React.useContext(AlertContext);
      const [value, setValue] = React.useState(1);
      const blockId = new URLSearchParams(search).get("blockID");
      const backdrop = React.useContext(BackdropContext);
      const auth = React.useContext(AuthContext)
      const [isViewerOpen, setIsViewerOpen] = React.useState(false);
      const [currentImage, setCurrentImage] = React.useState("");
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: true,
                              message: "",
                        });
                        getBlockById()
                  }
            }
            // eslint-disable-next-line
      }, [auth]);

      //openImageViewer
      const openImageViewer = React.useCallback((index) => {
            setCurrentImage(index);
            setIsViewerOpen(true);
      }, []);
      //closeImageViewer
      const closeImageViewer = () => {
            setCurrentImage(0);
            setIsViewerOpen(false);
      };

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };
      const tabTittle = [
            {
                  label: getCustomBlockName(data?.block?.[0]?.property?.property_hierarchyByID) + t("Details"),
                  imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: t("Attachments"),
                  imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: t("Related"),
                  imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                  value: 3,
                  className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            }
      ]

      const getBlockById = () => {
            const payload = { blockId };

            NetworkCall(
                  `${config?.api_url}/queries/block/get`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then((res) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
                  setData(res?.data?.data)
            }).catch((err) => {
                  console.log(err)
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });
            });
      };

      const goBack = () => {
            history.goBack(1)
      }
      const headerpage = [
            {
                  id: "1",
                  label: data?.block?.[0]?.name,
                  type: "name"
            },
      ]
      const handleedit = () => {
            history.push({
                  pathname: Routes.createBlockNew,
                  state: {
                        main: {
                              blockID: blockId,
                              company: data?.block?.[0]?.company,
                        }
                  }
            })
      }

      const render = () => {
            return <>
                  <Subheader title={data?.block?.[0]?.property?.name} pageoptions={headerpage} goBack={goBack} />
                  <div>
                        <PropertyTabs
                              showBtns={true}
                              t={t}
                              isEdit={permission?.update}
                              handleedit={handleedit}
                              value={value} handleChange={handleChange}
                              tab1={<BlockDetailsViewing data={data?.block?.[0]}
                                    assets={data?.assets}
                                    closeImageViewer={closeImageViewer}
                                    openImageViewer={openImageViewer}
                                    isViewerOpen={isViewerOpen}
                                    currentImage={currentImage}
                                    t={t}
                              />}
                              tab2={<Assets t={t} asset={data?.assets} />}
                              tab3={<OtherInfoViewing t={t} data={data} />}
                              tabTittle={tabTittle}
                        />
                  </div>
            </>
      }
      return (
            <div >
                  {accessCheckRender(render, permission)}
            </div >
      )
}
export default withNamespaces("blockView")(BlockView)
