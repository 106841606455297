import { Avatar, Stack, Typography } from "@mui/material"
import { concat_string, stringAvatar, timeZoneConverter } from "../../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const ProjectCard = ({
    classes = {},
    data = {},
    onClick = () => false,
}) => {
    return <Stack direction={"row"} columnGap={"16px"} p={"8px"}
        justifyContent={"space-between"} alignItems={"center"}
        sx={{ cursor: "pointer" }} onClick={() => onClick(data)}>
        <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}>
            <Avatar className={classes.optionAvatar}
                {...stringAvatar(data?.project_no)}></Avatar>
            <Stack>
                <Stack direction={"row"} columnGap={"8px"} divider={<Typography className={classes.optionSubTitle}>{", "}</Typography>}>
                    <Typography className={classes.optionHeader}>
                        {data?.project_no ?? "-"}
                    </Typography>
                    <Typography className={classes.optionSubTitle}>
                        {data?.type ?? "-"}
                    </Typography>
                </Stack>
                <Stack direction={"row"} columnGap={"8px"} divider={<Typography className={classes.optionSubTitle}>{", "}</Typography>}>
                    <Typography className={classes.optionSubTitle}>
                        {data?.title ?? "-"}
                    </Typography>
                    <Typography className={classes.optionSubTitle}>
                        {concat_string(
                            {
                                planned_start_date: data?.planned_start_date ? timeZoneConverter(data?.planned_start_date) : "",
                                planned_end_date: data?.planned_end_date ? timeZoneConverter(data?.planned_end_date) : "",
                            },
                            ["planned_start_date", "planned_end_date"], "-", "-")}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        {data?.isSelected
            ? <CheckCircleIcon htmlColor="#5078E1" />
            : <CheckCircleOutlineIcon htmlColor="#98A0AC" />}
    </Stack>
}