import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";

export const CustomLoadOptionsApis = async (
    endpoint,
    queryVariables,
    search = "",
    array = [],
    handleLoading = null,
    extractLabel,
    mappingVariable = {},
    manualResponse,
    functionParams={}
) => {

    let result,
        offset = 0;

    if (search && !Boolean(array?.length)) {
        offset = 0;
    } else {
        offset = array?.length;
    }
    result = await networkCallBackApi(endpoint, { ...queryVariables, search }, handleLoading, offset);
    //Select options should be label,value pairs like ({label:'',value:''})

    //manually construct array
    if (manualResponse) {
        if (extractLabel === "list") {
            result["data"] = manualResponse(result);
        }
        else {
            result[extractLabel] = manualResponse(result[extractLabel]);
        }
    }
    return {
        options: functionParams?.all ?
        (offset === 0 ?
          [{ value: functionParams?.all_value ?? [], label: functionParams?.all_label ?? "All" }, ...result?.data?.[extractLabel]] :
          [...result?.data?.[extractLabel]])
        : [...result?.data?.[extractLabel]],
        hasMore:
            array?.length + result?.data?.[extractLabel]?.length < (result?.data?.count?.[0]?.count ?? result?.data?.count),
    };
};

const networkCallBackApi = async (endpoint, variable = {}, handleLoading, offset) => {

    let payload = {
        ...variable,
        offset,
        limit: 10
    };

    const options = await NetworkCall(
        `${config.api_url}/${endpoint}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
            let main = response.data;
            handleLoading && handleLoading();
            return main;
        })
        .catch((error) => {
            handleLoading && handleLoading();
            return null;
        });

    return options;
};